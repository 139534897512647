<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-title>Seleccionar fecha de pago</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="cerrar">
          <i class="icon ion-ios-close-circle"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" v-if="cargando"></ion-progress-bar>
  </ion-header>
  <ion-content>
    <ion-item>
      <ion-label text-wrap>
        <h2>Seleccionar fecha</h2>
        <p>Clic aquí para cambiar fuera de calendario de pagos</p>
      </ion-label>
      <ion-input :value="fechaPago" v-on:ionChange="fechaPago=$event.detail.value" type="date" placeholder="Clic para especificar fecha"></ion-input>
      <ion-note v-if="esMovil" slot="start" color="medium">
        <i class="icon ion-ios-calendar"></i>
      </ion-note>
    </ion-item>
    <ion-list>
      <ion-item v-for="f in fechasPago" :key="f._id" @click="seleccionarFecha(f.fechaPago)" :color="f.fechaPago==fechaPago?'medium':''">
        <ion-note slot="start" color="medium">
          <i class="icon ion-ios-arrow-forward"></i>
        </ion-note>
        <ion-label>{{f.fechaPago}}</ion-label>
      </ion-item>
    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('primary')" @click="terminar" :disabled="cargando">
        <i class=" icon icon-lg ion-ios-checkmark"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
  <ion-footer v-if="empresa.diaMaximoAprobacionPagos">
    <ion-item lines="none">
      <ion-note slot="start" color="medium">
        <i class="icon ion-ios-information-circle-outline"></i>
      </ion-note>
      <ion-label>
        <p>La fecha máxima de aprobación de pagos es el {{empresa.diaMaximoAprobacionPagos | moment('MMM DD, YYYY')}}</p>
      </ion-label>
    </ion-item>
  </ion-footer>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
import Empresa from '@/models/Empresa'
import Emailer from '@/models/Emailer'
import FechasPago from '@/models/FechasPago'
export default {
  name: 'ModalSeleccionarFechaPago',
  props: {
    etapa: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      empresa: Api.obtenerLocal('elite.empresa') || {},
      cargando: false,
      fechasPago: [],
      fechaPago: null,
      esMovil: window.innerWidth < window.innerHeight
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    seleccionarFecha(f) {
      this.fechaPago = f
      this.$forceUpdate()
    },
    terminar() {
      Api.$emit('fechaPagoSeleccionada', this.fechaPago)
    },
    cerrar() {
      Api.$emit('fechaPagoCancelada')
    }
  },
  async mounted() {
    let fechaActual = new Date().getTime()
    const empresa = this.empresa
    this.fechasPago = (await Api.get('fechaspago'))
      .filter(f => fechaActual < new Date(f.fechaPago).getTime())
      .sort((a, b) => a.fechaPago > b.fechaPago ? 1 : -1)
      .filter(f => {
        if (empresa.diaMaximoAprobacionPagos && empresa.diaMaximoAprobacionPagos != '')
          return f.fechaPago <= empresa.diaMaximoAprobacionPagos
        else return true
      })
    console.log("ModalSeleccionarFechaPago", this.fechasPago, this.fechaPago, this.empresa)
  }
}
</script>
