<script>
import Api from '@/components/Api'
import Empresa from '@/models/Empresa'
import Vue from 'vue'
let Tarificador = new Vue({
  name: 'Tarificador',
  data() {
    return {
      rolTarificable: 'asociado'
    }
  },
  methods: {
    async saldo(idCliente, fecha) {
      // Encuentra el saldo del cliente a la fecha indicada
      idCliente = idCliente || ''
      fecha = fecha || new Date().getTime()
      let saldo = 0
      return Promise.resolve(saldo)
    },
    saldoFacturable(idCliente, fecha) {
      return saldo(idCliente, fecha)
    },
    async saldoFacturado(idCliente, fecha) {
      let saldo = await this.saldo(idCliente, fecha)
      // Encontrar las facturas realizadas
      let facturado = 0
      // Encontrar los pagos
      let cobrado = 0
      // Sumar y devolver
      return Promise.resolve(facturado - cobrado)
    },
    async horasDisponiblesParaFacturar(idCliente, idAsunto) {
      idCliente = idCliente || ''
      idAsunto = idAsunto || ''
      if (idCliente == '' || idAsunto == '')
        return Promise.reject(true)
      let actividades, filtros
      console.log("horasDisponiblesParaFacturar", idCliente, idAsunto)
      let saldo = this.saldo(idCliente)
      let horasDisponibles = 0
      // Encontrar la última factura realizada. Si no la hay, tomar en cuenta todo el historial
      let ultimaFacturaRealizada = {
        fechaFin: new Date().getTime() - (1000 * 60 * 60 * 24 * 365)
      }
      try {
        let u = await Api.find('prefactura', [
          'idCliente,eq,' + idCliente
        ], {
          sort: 'fechaFin,-1'
        })
        if (u && u[0])
          ultimaFacturaRealizada = u[0]
        console.log("Ultima factura realizada?", ultimaFacturaRealizada)
      } catch (e) {
        console.error("No fue encontrada una ultima factura")
      }
      // Encontrar el asunto y el acuerdo asociados
      let asunto = await Api.get('asunto', idAsunto)
      let acuerdo = (await Api.find('acuerdo', ['idAsunto,eq,' + idAsunto]))[0] || {}
      console.log("Encontrado asunto y acuerdo?", asunto, acuerdo)
      // Calcular caso Por asunto
      if (acuerdo.tipo == 'Asunto') {
        let fechaInicioBusqueda = ultimaFacturaRealizada.fechaFin
        filtros = [
          'idAsunto,eq,' + idAsunto,
          'fechaProgramada,gte,' + fechaInicioBusqueda
        ]
        console.log("Buscando tarifa por Asunto", filtros)
        actividades = await Api.find('actividad', filtros)
        let horasPosibles = parseFloat(acuerdo.tarifa) / Empresa.tarifa(this.rolTarificable)
        let horasUsadas = 0
        for (let act of actividades) {
          console.log("Restando", (act.minutos / 60), act.descripcion || act.nombre)
          horasUsadas += parseFloat(act.minutos) / 60
        }
        horasDisponibles = horasPosibles - horasUsadas
      }
      // Calcular caso por iguala
      else if (acuerdo.tipo == 'Iguala') {
        let diaDeCorte = new Date(ultimaFacturaRealizada.fechaFin).getDate()
        let fechaInicioActividades = ultimaFacturaRealizada.fechaFin
        let fechaActual = new Date()
        let fechaFinActividades = new Date()
        if (fechaActual.getTime() - fechaInicioActividades < (1000 * 60 * 60 * 24 * 35))
          fechaFinActividades = new Date(new Date(fechaInicioActividades).getTime() + (1000 * 60 * 60 * 24 * 30))
        fechaFinActividades.setDate(diaDeCorte + 1)
        fechaFinActividades = fechaFinActividades.getTime()
        filtros = [
          'idCliente,eq,' + idCliente,
          'fechaProgramada,gte,' + fechaInicioActividades,
          'fechaProgramada,lt,' + fechaFinActividades
        ]
        console.log("Buscando tarifa por iguala", new Date(fechaInicioActividades), new Date(fechaFinActividades), filtros)
        actividades = await Api.find('actividad', filtros)
        actividades = actividades.filter(a => {
          return acuerdo.idAsunto.indexOf(a.idAsunto) >= 0
        })
        let horasPosibles = parseFloat(acuerdo.tarifa) / Empresa.tarifa(this.rolTarificable)
        let horasUsadas = 0
        for (let act of actividades) {
          console.log("Restando", act.descripcion || act.nombre, act.minutos / 60)
          horasUsadas += parseFloat(act.minutos) / 60
        }
        horasDisponibles = horasPosibles - horasUsadas
      }
      console.log("Tarificador actividades?", filtros, ultimaFacturaRealizada, actividades)
      console.log("Horas disponibles?", acuerdo.tipo, idAsunto, idCliente, acuerdo.tarifa, Empresa.tarifa(this.rolTarificable), horasDisponibles, acuerdo)
      return Promise.resolve(horasDisponibles)
    },
    async asuntoTieneAcuerdoCaduco(idAsunto) {
      let caduco = false
      let acuerdo = (await Api.find('acuerdo', ['idAsunto,eq,' + idAsunto]))
      if (acuerdo && acuerdo[0])
        return this.acuerdoEstaCaduco(acuerdo[0]._id)
    },
    async acuerdoEstaCaduco(idAcuerdo) {
      let caduco = false
      try {
        let acuerdo = await Api.get('acuerdo', idAcuerdo)
        let fechaCaducidadAcuerdo = new Date(acuerdo.fechaCaducidad).getTime()
        let ahora = new Date().getTime()
        caduco = fechaCaducidadAcuerdo < ahora
        return Promise.resolve({
          acuerdo,
          caduco
        })
      } catch (e) {}
      return Promise.resolve(caduco)
    }
  },
  created() {
    console.log('Tarificador created', Empresa)
  }
})
export default Tarificador
</script>
