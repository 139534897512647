<template>
  <div class="dashboardPorClienteWrapper">
    <table class="dashboardPorCliente">
      <thead>
        <tr>
          <th colspan="1">&nbsp;</th>
          <th colspan="12" class="ion-text-center">
            Horas de actividades por mes
          </th>
        </tr>
        <tr>
          <th>Abogado</th>
          <th class="ion-text-right">Acts.</th>
          <th v-for="i in meses" :key="i" class="ion-text-right">
            {{ new Date(year + "-" + i + "-15") | moment("MMM") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="c in usuarios" :key="c._id">
          <td>{{ c.nombre }}</td>
          <td class="ion-text-right">
            <span v-if="c.actividades.length > 0">{{
              c.actividades.length
            }}</span>
          </td>
          <td v-for="mes in meses" :key="mes" class="ion-text-right">
            <span v-if="horasDeMes(c, mes) > 0">{{
              horasDeMes(c, mes) | currency("")
            }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>
.dashboardPorClienteWrapper {
  overflow-y: auto;
  width: 100%;
}
.dashboardPorCliente {
  width: 100%;
}
.dashboardPorCliente th,
.dashboardPorCliente td {
  text-align: left;
  padding: 8px;
  min-width: 40px;
  text-transform: uppercase;
}
</style>
<script>
import Api from "@/components/Api";
export default {
  name: "DashboardPorCliente",
  data() {
    return {
      usuarios: [],
      meses: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
    };
  },
  props: { year: { type: String, default: new Date().getFullYear() } },
  mounted() {
    console.log("dashboard por cliente", this.year);
    this.obtener();
  },
  methods: {
    async obtener() {
      this.usuarios = (await Api.get("usuario"))
        .filter((u) => !u.desactivado)
        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
      const year = this.year;
      const actividades = (await Api.get("actividad"))
        .map((a) => {
          a.fechaProgramada = new Date(a.fechaProgramada)
            .toISOString()
            .substring(0, 10);
          return a;
        })
        .filter((a) => a.fechaProgramada.startsWith(year));
      console.log("Datos?", { actividades });
      console.log("Act", actividades[100]);
      for (let i in this.usuarios) {
        const idAbogado = this.usuarios[i]._id;
        this.usuarios[i].actividades = actividades.filter(
          (a) => a.idAutor == idAbogado || a.idAbogado == idAbogado
        );
        console.log(
          "acts",
          idAbogado,
          actividades.length,
          this.usuarios[i].actividades.length
        );
      }
      console.log("Abogados?", this.usuarios);
      this.usuarios = this.usuarios.sort((a, b) =>
        a.actividades.length < b.actividades.length ? 1 : -1
      );
      this.$forceUpdate();
    },
    horasDeMes(usuario = {}, mes = "") {
      const fecha = this.year + "-" + mes;
      return (
        (usuario.actividades || [])
          .filter((a) => a.fechaProgramada.startsWith(fecha))
          .reduce(
            (acum, obj) => acum + parseFloat(obj.minutos || obj.minutosCliente),
            0
          ) / 60
      );
    },
  },
};
</script>
