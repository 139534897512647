<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Solicitudes de factura</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="obtener">
          <i class="icon ion-ios-refresh"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-item>
          <ion-label position="floating">Ver</ion-label>
          <ion-select :value="modo" v-on:ionChange="setModo($event.detail.value)">
            <ion-select-option>Pendientes</ion-select-option>
            <ion-select-option>Completadas</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
      <ion-col class="ion-no-padding">
        <ion-item>
          <ion-label position="floating">Programada desde fecha</ion-label>
          <ion-input type="date" debounce="1000" :value="fechaInicio" v-on:ionChange="fechaInicio=$event.detail.value"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col class="ion-no-padding">
        <ion-item>
          <ion-label position="floating">Hasta fecha</ion-label>
          <ion-input type="date" debounce="1000" :value="fechaFin" v-on:ionChange="fechaFin=$event.detail.value"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-list>
      <ion-item-sliding v-for="s in solicitudes" :key="s._id">
        <ion-item @click="ver(s)">
          <ion-label text-wrap>
            <h2 v-if="s.cliente">{{s.cliente.nombre}}</h2>
            <p>{{s.concepto}}</p>
            <p>{{s.fecha | moment('MMMM DD')}}, programada para {{s.fechaParaTimbrar | moment('MMM DD')}}</p>
            <!--p v-if="s.autor && s.idAutor!=yo._id">Por {{s.autor.nombre}}</p-->
            <p v-if="s.autor">{{s.autor.nombre}}</p>
          </ion-label>
          <ion-note slot="end" v-if="s.idSolicitudOrigen" color="medium">
            <i class="icon ion-ios-refresh"></i>
          </ion-note>
        </ion-item>
        <ion-item-options slot="end" v-if="permiso('BorrarSolicitudesDeFacturas')">
          <ion-item-option color @click="eliminar(s)">
            <i class="icon ion-ios-trash"></i>&nbsp;Eliminar
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="agregar">
        <i class="icon icon-lg ion-ios-add"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
export default {
  name: 'SolicitudesFactura',
  props: {
    modoprop: {
      type: String,
      default: 'pendientes'
    }
  },
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      modo: 'Pendientes',
      solicitudes: [],
      fechaInicio: null,
      fechaFin: null
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    setModo(val) {
      this.modo = val
      this.obtener()
    },
    async obtener() {
      let fecha = new Date().getTime() - (1000 * 60 * 60 * 24 * 30)
      let filtros = ['fecha,gte,' + fecha]
      filtros = ['fechaParaTimbrar,gte,' + this.fechaInicio,
        'fechaParaTimbrar,lt,' + this.fechaFin
      ]
      let subset = [{
        coleccion: 'cliente',
        query: '_id,eq,',
        sustituir: 'idCliente',
        unico: true
      }, {
        coleccion: 'directorio',
        alias: 'autor',
        query: '_id,eq,',
        sustituir: 'idAutor',
        unico: true
      }, {
        coleccion: 'asunto',
        query: '_id,eq,',
        sustituir: 'idAsunto',
        unico: true
      }]
      let solicitudes = await Api.find('solicitudfactura', filtros, {
        subset
      })
      let modo = this.modo
      this.solicitudes = solicitudes
        .sort((a, b) => {
          a.fecha > b.fecha ? 1 : -1
        })
        .map(u => {
          u.concepto = u.concepto
            .replace(/%20/g, ' ')
            .replace(/Ã¡/g, 'á')
            .replace(/É/g, "é")
            .replace(/Ã©/g, "é")
            .replace(/Ã­/g, 'í')
            .replace(/Ã³/g, 'ó')
            .replace(/%2C/g, ',')
            .replace(/%24/g, "\$")
            .replace(/%28/g, "(")
            .replace(/%29/g, ")")
            .replace(/Ã±/g, 'ñ')
          return u
        })
        .filter(s => modo == 'Completadas' ? s.completada : !s.completada)
      /*
      if (false)
        for (let s in this.solicitudes)
          try {
            this.solicitudes[s].cliente = await Api.get('cliente', this.solicitudes[s].idCliente)
            if (this.solicitudes[s].idAsunto)
              this.solicitudes[s].asunto = await Api.get('asunto', this.solicitudes[s].idAsunto)
            if (this.solicitudes[s].idAutor)
              this.solicitudes[s].autor = await Api.get('usuario', this.solicitudes[s].idAutor)
          } catch (e) {
            console.error(e)
          }//*/
      this.$forceUpdate()
      console.log("Solicitudes", this.solicitudes.length, this.solicitudes[0])
    },
    ver(s) {
      console.log("Ver?", s._id)
      this.$router.push('/solicitudfactura/' + (s._id || '0'))
    },
    agregar() {
      this.ver({})
    },
    async eliminar(s) {
      if (true == confirm('Desea eliminar esta solicitud de factura?')) {
        let vm = this
        await Api.delete('solicitudfactura', s._id)
        alert("Eliminada")
        await Api.save('bitacora', {
          fecha: new Date().getTime(),
          mensaje: 'Solicitud de factura eliminada, para cliente ' + s.cliente.nombre + ', por ' + vm.$options.filters.currency(s.cantidad),
          idAutor: vm.yo._id
        })
        let solicitudesRecurrentes = await Api.find('solicitudfactura', ['idSolicitudOrigen,eq,' + s._id])
        if (solicitudesRecurrentes.length > 0 && true == confirm('También desea eliminar las ' + solicitudesRecurrentes.length + ' recurrencias?')) {
          for (let u of solicitudesRecurrentes)
            await Api.delete('solicitudfactura', u._id)
          alert("Eliminadas recurrencias")
        }
        if (s.idSolicitudOrigen &&
          s.idSolicitudOrigen != '' &&
          true == confirm('Desea eliminar las otras instancias de esta solicitud recurrente?')) {
          let instancias = await Api.find('solicitudfactura', ['idSolicitudOrigen,eq,' + s.idSolicitudOrigen])
          for (let i of instancias)
            await Api.delete('solicitudfactura', i._id)
          alert("Eliminadas instancias: " + instancias.length)
        }
        await Api.wait(500)
        this.obtener()
      }
    }
  },
  watch: {
    modoprop: function(a) {
      console.log("$watch modoprop", a)
      this.modo = (a == 'pendientes') ? 'Pendientes' : 'Completadas'
      this.obtener()
    },
    fechaInicio: function(a) {
      console.log("solicitudFacturaFechaInicio", a)
      Api.guardarLocal('elite.solicitudFacturaFechaInicio', a)
      this.obtener()
    },
    fechaFin: function(a) {
      console.log("solicitudFacturaFechaFin", a)
      Api.guardarLocal('elite.solicitudFacturaFechaFin', a)
      this.obtener()
    }
  },
  mounted() {
    console.log("SolicitudFactura mounted")
    this.fechaInicio = Api.obtenerLocal('elite.solicitudFacturaFechaInicio') || this.$options.filters.moment(new Date().getTime() - (1000 * 60 * 60 * 24 * 15), 'YYYY-MM-DD')
    this.fechaFin = Api.obtenerLocal('elite.solicitudFacturaFechaFin') || this.$options.filters.moment(new Date().getTime() + (1000 * 60 * 60 * 24 * 15), 'YYYY-MM-DD')
    console.log("fechaInicio,fechaFin", this.fechaInicio, this.fechaFin)
    this.obtener()
  }
}
</script>
