<script>
/*
Alberto Herrera Olvera
Portación de API Facturama a VueJS + Axios
Diciembre de 2018
*/

// Esto es Esparta
import axios from 'axios'

// Variables de la API
var valuesFacturama = {
  token: "cHJ1ZWJhczpwcnVlYmFzMjAxMQ==",
  url: "https://apisandbox.facturama.mx/",
  usuario: 'liebemichblutengel', //'pruebas',
  password: 'pass!word290' //'pruebas2011'
}
// Configurar el tipo de subida de datos que acepta el servidor
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// Autentificar al usuario, y tener como fallback el ambiente de pruebas
let headers = {
  auth: {
    username: valuesFacturama.usuario,
    password: valuesFacturama.password
  }
}
// Esto ya estaba aquí cuando llegué
var settings = {
  url: valuesFacturama.url
}

// Redefinir las funciones jQuery como Axios
function retrieve(path, id) {
  return axios.get(settings.url + path + '/' + id, headers)
}

function list(path) {
  return axios.get(settings.url + path, headers)
}

function listWithParam(path, param) {
  return axios.get(settings.url + path + param, headers)
}

function postSyncWithParam(path, param) {
  return axios.post(settings.url + path + param, headers)
}

function postSyncWithData(path, data) {
  return axios.post(settings.url + path, data, headers)
}

function putSyncWithData(path, data) {
  return axios.put(settings.url + path, data, headers)
}

function deleteSyncWithParam(path, param) {
  return axios.delete(settings.url + path + '/' + param, headers)
}

export default {

  Clients: {
    Get: function(id) {
      return retrieve('client', id);
    },
    List: function() {
      return list('client');
    },
    Create: function(data) {
      return postSyncWithData('client', data);
    },
    Remove: function(id) {
      return deleteSyncWithParam('client/', id);
    },
    Update: function(id, data) {
      return putSyncWithData('client/' + id, data);
    }
  },
  Products: {
    Get: function(id) {
      return retrieve('product', id);
    },
    List: function() {
      return list('product');
    },
    Create: function(data) {
      return postSyncWithData('product', data);
    },
    Remove: function(id) {
      return deleteSyncWithParam('product/', id);
    },
    Update: function(id, data) {
      return putSyncWithData('product/' + id, data);
    }
  },
  BranchOffice: {
    Get: function(id) {
      return retrieve('branchOffice', id);
    },
    List: function() {
      return list('branchOffice');
    },
    Create: function(data) {
      return postSyncWithData('branchOffice', data);
    },
    Remove: function(id) {
      return deleteSyncWithParam('branchOffice', id);
    },
    Update: function(id, data) {
      return putSyncWithData('branchOffice/' + id, data);
    }
  },
  Series: {
    List: function(id) {
      return retrieve('serie', id);
    },
    Create: function(idBranch, data) {
      return postSyncWithData('serie/' + idBranch, data);
    },
    Remove: function(idBranch, name) {
      return deleteSyncWithParam('serie/' + idBranch, name);
    },
    Update: function(idBranch, name, data) {
      return putSyncWithData('serie/' + idBranch + '/' + name, data);
    }
  },
  Cfdi: {
    Get: function(id) {
      return retrieve('cfdi', id);
    },
    List: function(param) {
      return listWithParam('cfdi', param);
    },
    Create: function(data) {
      return postSyncWithData('2/cfdis', data);
    },
    Send: function(param) {
      return postSyncWithParam('cfdi', param);
    },
    Cancel: function(params) {
      return deleteSyncWithParam('cfdi', params);
    },
    Download: function(format, type, id) {
      return retrieve('cfdi/' + format + '/' + type, id);
    },
    Acuse: function(format, type, id) {
      return retrieve('acuse/' + format + '/' + type, id);
    }
  },
  TaxEntity: {
    Get: function(callback) {
      return list('taxEntity');
    },
    Update: function(data) {
      return putSyncWithData('taxEntity', data);
    },
    UploadCsd: function(data) {
      return putSyncWithData('taxEntity/UploadCsd', data);
    },
    UploadImage: function(data) {
      return putSyncWithData('taxEntity/UploadLogo', data);
    }
  },
  Catalogs: {
    States: function(country) {
      return list('catalogs/municipalities?countryCode' + country);
    },
    Municipalities: function(state) {
      return list('catalogs/municipalities?stateCode' + state);
    },
    Localities: function(state) {
      return list('catalogs/localities?stateCode' + state);
    },
    Neighborhoods: function(postalCode) {
      return list('catalogs/neighborhoods?postalCode' + postalCode);
    },
    ProductsOrServices: function(keyword) {
      return list('catalogs/ProductsOrServices?keyword=' + keyword);
    },
    PostalCodes: function(keyword) {
      return list('catalogs/PostalCodes?keyword=' + keyword);
    },
    Units: function(callback) {
      return list('catalogs/Units');
    },
    Currencies: function(callback) {
      return list('catalogs/Currencies');
    },
    Countries: function(callback) {
      return list('catalogs/Countries');
    },
    PaymentForms: function(callback) {
      return list('catalogs/PaymentForms');
    },
    PaymentMethods: function(callback) {
      return list('catalogs/PaymentMethods');
    },
    FederalTaxes: function(callback) {
      return list('catalogs/FederalTaxes');
    },
    FiscalRegimens: function(callback) {
      return list('catalogs/FiscalRegimens');
    },
    CfdiTypes: function(callback) {
      return list('catalogs/CfdiTypes');
    },
    RelationTypes: function(callback) {
      return list('catalogs/RelationTypes');
    },
    CfdiUses: function(keyword) {
      let url = 'catalogs/CfdiUses'
      if (keyword && keyword != '') url += '?keyword=' + keyword
      return list(url)
    }
  },
  getToken: function() {
    return "Basic " + valuesFacturama.token
  },
  getBaseUri: function() {
    return settings.url
  }
}
</script>
