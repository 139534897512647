<template>
<ion-list id="actividad" v-if="mostrarActividad">
  <ion-progress-bar v-if="cargando" type="indeterminate" color="secondary"></ion-progress-bar>
  <ion-item-divider @click="clog">Actividad {{actividad._id || 'Nueva'}} ({{actividad.fecha | moment('HH:mm:ss')}})</ion-item-divider>
  <ion-item>
    <ion-note slot="start" :color="colors('success')">
      <i class="icon ion-ios-calendar" v-if="actividad.fechaProgramada>fechaValida"></i>
      <i class="icon ion-ios-minus-circle" v-else></i>
    </ion-note>
    <ion-label :color="actividad.fechaProgramada>fechaValida?'success':'danger'" :position="esMovil?'stacked':''">Fecha <span v-if="!modoProduccion" :color="colors('light')">{{actividad.fechaProgramada}}</span></ion-label>
    <ion-input v-if="actividad.fechaProgramada && actividad.fechaProgramada>0" class="ion-text-right" :color="actividad.fechaProgramada?'':'danger'" type="date" debounce="500" :value="actividad.fechaProgramada | moment('YYYY-MM-DD')"
      v-on:ionChange="fechaActividad($event.detail.value)"></ion-input>
  </ion-item>
  <div vv-if="mostrarSelectorActividad">
    <SelectorCliente :idCliente="actividad.idCliente" />
    <SelectorAsunto :idCliente="actividad.idCliente" :idAsunto="actividad.idAsunto" v-if="actividad.idCliente && actividad.idCliente!=''" />
  </div>
  <ion-item v-if="permiso('EditarActividades') && usuarios.length>0">
    <ion-label :color="colors('success')" :position="esMovil?'stacked':''">Abogado asignado</ion-label>
    <ion-note slot="start" color="medium">
      <i class="icon ion-ios-person"></i>
    </ion-note>
    <ion-select :value="actividad.idAbogado" v-on:ionChange="cambiarAbogado($event.detail.value)">
      <ion-select-option v-for="u in usuarios" :key="u._id" :value="u._id">{{u.nombre}}</ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item :color="colors('light')">
    <ion-label :color="colors('success')" :position="esMovil?'stacked':''">Descripción</ion-label>
    <ion-note slot="start" :color="colors('success')">
      <i class="icon ion-ios-create"></i>
    </ion-note>
    <ion-textarea class="ion-text-right" rows="3" placeholder="Descripción de la actividad" :value="actividad.descripcion" v-on:ionChange="actividad.descripcion=$event.detail.value"></ion-textarea>
  </ion-item>
  <ion-item v-if="false">
    <ion-label :color="colors('success')" :position="esMovil?'stacked':''">Tipo de actividad</ion-label>
    <ion-note slot="start" :color="colors('success')">
      <i class="icon ion-ios-more"></i>
    </ion-note>
    <ion-select :value="actividad.tipo" v-on:ionChange="actividad.tipo=$event.detail.value">
      <ion-select-option :value="'horario'">Por horario/Honorarios</ion-select-option>
      <ion-select-option :value="'iguala'">Iguala</ion-select-option>
    </ion-select>
  </ion-item>
  <!--ion-item v-if="(actividad.tipo && actividad.tipo=='iguala') || permiso('CostearActividades')">
    <ion-label :color="colors('success')" :position="esMovil?'stacked':''">Costo (MXN)</ion-label>
    <ion-note slot="start" :color="colors('success')">
      <i class="icon ion-ios-cash"></i>
    </ion-note>
    <ion-input type="number" step="1" min="0" :value="actividad.monto" v-on:ionChange="actividad.monto=parseFloat($event.detail.value)"></ion-input>
  </ion-item-->
  <ion-item>
    <ion-label :color="colors('success')" :position="esMovil?'stacked':''" text-wrap>Tiempo efectivo (min)</ion-label>
    <ion-note slot="start" :color="colors('success')">
      <i class="icon ion-ios-time"></i>
    </ion-note>
    <ion-input type="number" step="1" :value="actividad.minutos" v-on:ionChange="actividad.minutos=$event.detail.value;actividad.minutosCliente=$event.detail.value" v-on:keyup.enter="guardar()"></ion-input>
  </ion-item>
  <ion-item v-if="empresa.usarTiempoRepercutidoAlCliente">
    <ion-label :color="colors('success')" :position="esMovil?'stacked':''" text-wrap>Tiempo repercutido al cliente (min)</ion-label>
    <ion-note slot="start" :color="colors('success')">
      <i class="icon ion-ios-time"></i>
    </ion-note>
    <ion-input type="number" step="1" :value="actividad.minutosCliente" v-on:ionChange="actividad.minutosCliente=$event.detail.value" v-on:keyup.enter="guardar()"></ion-input>
  </ion-item>
  <ion-row>
    <ion-col size="3">
      <ion-button fill="outline" @click="nuevaActividad" expand="block" :color="colors('danger')">
        Nueva</ion-button>
    </ion-col>
    <ion-col>
      <ion-button expand="block" @click="guardar" v-if="permiso('EditarActividades') || actividad.idAbogado==yo._id" :disabled="cargando">
        <ion-icon name="save"></ion-icon>
        Guardar actividad
      </ion-button>
    </ion-col>
  </ion-row>
</ion-list>
</template>
<style>
#actividad ion-input,
#actividad ion-select {
  text-align: right;
}
</style>
<script>
import Api from '@/components/Api'
import Usuario from '@/models/Usuario'
import Cliente from '@/models/Cliente'
import SelectorCliente from '@/components/SelectorCliente'
import SelectorAsunto from '@/components/SelectorAsunto'
export default {
  name: 'Actividad',
  props: ['idAsunto', 'idActividad', 'mostrarSelectorActividad'],
  components: {
    SelectorCliente,
    SelectorAsunto
  },
  data() {
    return {
      cargando: false,
      yo: Api.obtenerLocal('elite.yo') || {},
      empresa: Api.obtenerLocal('elite.empresa') || {},
      mostrarActividad: true,
      actividad: {
        idAbogado: (Api.obtenerLocal('elite.yo') || {})._id,
        monto: 0,
        minutos: 0,
        minutosCliente: 0
      },
      usuarios: [],
      usuario: {},
      areas: [],
      asuntos: [],
      clientes: [],
      esMovil: Api.esMovil(),
      fechaValida: new Date(2018, 1, 1).getTime(),
      modoProduccion: Api.modoProduccion
    }
  },
  methods: {
    configurarCliente(cl) {
      console.log("$on clienteSeleccionado", cl)
      this.actividad.idCliente = cl
    },
    clog() {
      console.log("Actividad", this.actividad)
    },
    async obtener() {
      this.cargando = true
      this.mostrarActividad = false
      if (this.mostrarSelectorActividad) {
        this.clientes = await Cliente.lista()
      }
      if (!this.idAsunto && !this.mostrarSelectorActividad)
        alert("No fue especificado un asunto para esta actividad")
      if (this.idActividad && this.idActividad != '' && this.idActividad.length == 24) {
        this.actividad = await Api.get('actividad', this.idActividad)
        /*for (let f of ['fecha', 'fechaProgramada', 'fechaEjecucion'])
          if (this.actividad[f])
            this.actividad[f] = new Date(this.actividad[f])
            */
        if (this.actividad.idCliente) {
          console.log("obtener actividad seleccionando cliente", this.actividad.idCliente, this.actividad.idAsunto)
          this.seleccionarCliente(this.actividad.idCliente, (this.actividad.idAsunto && this.actividad.idAsunto != ''))
        }
        //this.actividad.fechaProgramada = this.$options.filters.moment(this.actividad.fechaProgramada, 'YYYY-MM-DD')
        console.log("Recuperada actividad", this.actividad)
      } else {
        console.log("Recuperando asunto", this.idAsunto)
        let asunto = {}
        if (this.idAsunto && this.idAsunto != '')
          asunto = (await Api.get('asunto', this.idAsunto)) || {}
        else
          console.log("No recuperado asunto (nuevo?)")
        let cliente = {}
        if (asunto && asunto._id)
          //cliente = (await Api.get('cliente', asunto.idCliente))
          cliente = (await Cliente.obtener(asunto.idCliente)) || {}
        this.actividad = {
          idAutor: this.yo._id,
          idAbogado: this.yo._id,
          fecha: new Date().getTime(),
          //fechaProgramada: this.$options.filters.moment(new Date(), 'YYYY-MM-DD'),
          fechaProgramada: new Date().getTime(),
          fechaEjecucion: null,
          idAsunto: this.idAsunto,
          idCliente: cliente._id,
          minutos: 0,
          minutosCliente: 0,
          tipo: cliente.tipoDefault || 'hora'
        }
        console.log("Nueva actividad", this.actividad)
        this.cambiarAbogado(this.yo._id)
      }
      this.cargando = false
      this.mostrarActividad = true
    },
    fechaActividad(val) {
      let val2 = new Date(val).getTime() + (1000 * 60 * 60 * 6)
      this.actividad.fechaProgramada = val2
      console.log("Configurando fecha actividad", this.actividad.fechaProgramada, new Date(this.actividad.fechaProgramada))
    },
    async cambiarAbogado(id) {
      this.actividad.idAbogado = id
      if (id == this.yo._id) {
        this.usuario = this.yo
      } else
        //this.usuario = await Api.get('usuario', id)
        this.usuario = await Usuario.obtener(id)
      if (this.usuario && this.usuario._id && this.usuario.areaDefault)
        this.actividad.area = this.usuario.areaDefault
      console.log("Cambiada área?", this.actividad.area, this.usuario.areaDefault)
      this.$forceUpdate()
    },
    async cambiarAsunto(id) {
      console.log("cambiarAsunto", id)
      this.actividad.idAsunto = id
      console.log("Cambiado asunto. Buscando área", this.actividad.idAsunto)
      try {
        let asunto = await Api.get('asunto', id)
        this.actividad.area = asunto.area || ''
        console.log("Recuperada área del asunto", this.actividad.area, asunto.nombre, asunto)
      } catch (e) {
        console.error("No se pudo recuperar el asunto", e)
      }
    },
    async guardar() {
      this.cargando = true
      if (!(this.actividad.fechaProgramada && !isNaN(this.actividad.fechaProgramada) && this.actividad.fechaProgramada > this.fechaValida)) {
        console.log("No hago nada no hago nada fechaProgramada", this.actividad.fechaProgramadao)
        alert("La fecha no es correcta", {
          color: 'warning'
        })
      } else
      if (!(this.actividad.idCliente && this.actividad.idCliente != '')) {
        console.log("No hago nada no hago nada idCliente", this.actividad.idCliente)
        alert("No hay cliente seleccionado", {
          color: 'warning'
        })
      } else
      if (!(this.actividad.idAsunto && this.actividad.idAsunto != '')) {
        console.log("No hago nada no hago nada idAsunto", this.actividad.idAsunto)
        alert("No hay asunto seleccionado", {
          color: 'warning'
        })
      } else
      if (!(this.actividad.minutos && !isNaN(this.actividad.minutos) && this.actividad.minutos > 0)) {
        console.log("No hago nada no hago nada minutos", this.actividad.minutos)
        alert("El tiempo de trabajo no es válido", {
          color: 'warning'
        })
      } else
      if (!this.actividad.descripcion || this.actividad.descripcion == '')
        alert("Falta descripción de la actividad", {
          color: 'warning'
        })
      else {
        console.log("Fecha programada?", new Date(this.actividad.fechaProgramada))
        if (this.actividad.fechaEjecucion)
          this.actividad.fechaEjecucion = new Date(this.actividad.fechaEjecucion).getTime()
        if (!this.actividad.idAbogado || this.actividad.idAbogado == '')
          this.actividad.idAbogado = this.yo._id
        let a = await Api.save('actividad', this.actividad)
        alert("Guardado")
        //if (!this.actividad._id && a._id) this.actividad._id = a._id
        console.log("Ruta", this.$route.path)
        //if (this.$route.path == '/actividad2') {
        if (this.$route.path.indexOf('/actividad2') >= 0) {
          console.log("Clonando actividad...", this.actividad)
          delete this.actividad._id
          this.actividad.fecha = new Date().getTime()
          //this.actividad.fechaProgramada = fP
          this.actividad.descripcion = ''
          this.$router.push('/actividad2')
          console.log("Actividad clonada", this.actividad)
          this.$forceUpdate()
        }
      }
      this.cargando = false
    },
    cancelar() {
      Api.$emit('ActividadGuardada')
      this.inicializar()
    },
    // Cuando se muestra el selector de actividad, capacidad de filtrar asuntos
    async filtrarAsuntos(idCliente) {
      console.log("Filtrando por ", idCliente)
      this.actividad.idCliente = idCliente
      this.asuntos = []
      this.asuntos = await Api.find('asunto', ['idCliente,eq,' + idCliente])
      this.asuntos = this.asuntos
        .sort((a, b) => {
          return a.fecha > b.fecha ? 1 : -1
        })
        .sort((a, b) => {
          return a.nombre > b.nombre ? 1 : -1
        })
        .sort((a, b) => {
          return a.area > b.area ? 1 : -1
        })
        .filter(a => {
          return !(a.terminado && a.terminado == 1)
        })
      //console.table(this.asuntos)
    },
    async seleccionarCliente(cl, noReiniciarAsunto) {
      noReiniciarAsunto = noReiniciarAsunto && true
      this.actividad.idCliente = ''
      await Api.wait(20)
      this.actividad.idCliente = cl
      if (this.actividad.idCliente != '')
        this.filtrarAsuntos(this.actividad.idCliente)
      else
        this.asuntos = []
      if (!noReiniciarAsunto)
        this.actividad.idAsunto = ''
      console.log("seleccionarCliente", cl, noReiniciarAsunto, this.actividad.idAsunto)
      this.$forceUpdate()
    },
    seleccionarAsunto(idAsunto) {
      this.actividad.idAsunto = idAsunto
      console.log("seleccionarAsunto", idAsunto)
    },
    async inicializar() {
      this.mostrarActividad = false
      let asunto = {}
      if (this.idAsunto && this.idAsunto != '')
        asunto = (await Api.get('asunto', this.idAsunto)) || {}
      else
        console.log("No recuperado asunto (nuevo?)")
      let cliente = {}
      if (asunto && asunto._id)
        //cliente = (await Api.get('cliente', asunto.idCliente))
        cliente = (await Cliente.obtener(asunto.idCliente)) || {}
      else await new Promise((resolve) => {
        setTimeout(_ => {
          resolve(true)
        }, 50)
      })
      this.actividad = {
        idAutor: this.yo._id,
        idAbogado: this.yo._id,
        fecha: new Date().getTime(),
        //fechaProgramada: this.$options.filters.moment(new Date(), 'YYYY-MM-DD'),
        fechaProgramada: new Date().getTime(),
        fechaEjecucion: null,
        idAsunto: this.idAsunto,
        idCliente: cliente._id,
        minutos: 0,
        tipo: cliente.tipoDefault || 'horario'
      }
      this.mostrarActividad = true
      this.$forceUpdate()
    },
    nuevaActividad() {
      this.inicializar()
      this.$router.push('/actividad2')
    }
  },
  watch: {
    '$route': function(a, b) {
      console.log("watch $route", this.idActividad, a, b)
      this.obtener(this.idActividad)
    }
  },
  async mounted() {
    console.log("Actividad", this.yo.nombre)
    //if (this.yo.delegaActividades)
    //this.usuarios = await Usuario.lista()
    this.usuarios = (await Api.get('usuario'))
      .sort((a, b) => a.nombre > b.nombre ? 1 : -1)
      .filter(a => a.rol != 'administrador')
    let areas = await Api.get('area')
    if (areas && areas.length > 0)
      this.areas = areas.map(a => {
        return a.nombre
      })
    console.log("Actividad catalogos", this.usuarios, areas)
    this.inicializar()
    setTimeout(this.obtener, 500)
    Api.$on('clienteSeleccionado', this.seleccionarCliente)
    Api.$on('asuntoSeleccionado', this.seleccionarAsunto)
  },
  beforeDestroy() {
    Api.$off('clienteSeleccionado')
  }
}
</script>
