<template>
<ion-app main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-buttons slot="end">
        <ion-button>
          <ion-icon name="more" :color="colors('secondary')"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Reporte {{modo}}</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" v-if="cargando"></ion-progress-bar>
  </ion-header>
  <ion-content class="content">
    <ion-row>
      <ion-col>
        <ion-card v-if="asunto._id">
          <ion-item lines="none">
            <ion-note slot="start" color="medium">
              <i class="icon ion-ios-document"></i>
            </ion-note>
            <ion-label text-wrap>
              <h2>{{asunto.nombre}}</h2>
              <p>{{asunto.folio}} {{asunto.folio}}</p>
            </ion-label>
          </ion-item>
        </ion-card>
        <ion-card v-if="arbol.acuerdo._id">
          <ion-item lines="none">
            <ion-label text-wrap>
              <h2>{{acuerdo.nombre}}</h2>
              <p>{{acuerdo.desdeFecha}} {{acuerdo.hastaFecha}}</p>
            </ion-label>
          </ion-item>
        </ion-card>
        <ion-list>
          <ion-item lines="none">
            <ion-segment :value="filtros.modo" v-on:ionChange="setFiltro('modo',$event.detail.value)">
              <ion-segment-button :value="'mes'">Mes</ion-segment-button>
              <ion-segment-button :value="'todo'">Todo</ion-segment-button>
            </ion-segment>
          </ion-item>
          <ion-item v-if="filtros.modo=='mes'">
            <ion-label position="floating">Ver mes de</ion-label>
            <ion-input type="month" debounce="1000" :value="filtros.mes" v-on:ionChange="setFiltro('mes',$event.detail.value)" />
          </ion-item>
          <SelectorCliente :labelPosition="'floating'" :idCliente="filtros.idCliente" />
          <ion-item>
            <ion-checkbox debounce="1000" slot="end" :checked="filtros.desglosarActividades" v-on:ionChange="setFiltro('desglosarActividades',$event.detail.checked==true)"></ion-checkbox>
            <ion-label>Desglosar actividades</ion-label>
          </ion-item>
          <ion-item v-if="permiso('CosteoActividades')">
            <ion-checkbox debounce="1000" slot="end" :checked="filtros.incluirMontos" v-on:ionChange="setFiltro('incluirMontos',$event.detail.checked==true)"></ion-checkbox>
            <ion-label>Incluir montos</ion-label>
          </ion-item>
          <ion-row v-if="filtros.incluirMontos">
            <ion-col class="ion-no-padding" size="12">
              <ion-item>
                <ion-label>Tipo de montos</ion-label>
                <ion-select :value="filtros.modoMontos" v-on:ionChange="setFiltro('modoMontos',$event.detail.value)">
                  <ion-select-option :value="'costeo'">Costeo</ion-select-option>
                  <ion-select-option :value="'cobranza'">Cobranza</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <ion-col class="ion-no-padding">
              <ion-item>
                <ion-label position="floating">Moneda</ion-label>
                <ion-input type="text" debounce="1000" :value="filtros.moneda" v-on:ionChange="setFiltro('moneda',$event.detail.value)" />
              </ion-item>
            </ion-col>
            <ion-col class="ion-no-padding" v-if="filtros.moneda!='MXN'">
              <ion-item>
                <ion-label position="floating">Tipo de cambio</ion-label>
                <ion-input type="number" step="any" debounce="1000" :value="filtros.tipoCambio" v-on:ionChange="setFiltro('tipoCambio',$event.detail.value)" />
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-list>
      </ion-col>
      <ion-col v-if="pdf!=''" size="12" size-md="8" size-sm="7">
        <embed type="application/pdf" :src="pdf" class="pdf" />
      </ion-col>
      <ion-col v-else size="12" size-md="8" size-sm="7">
        <ion-item lines="none">
          <ion-label class="ion-text-center">
            <h1><i class="icon icon-xxl ion-ios-list"></i></h1>
            <p>Seleccione parámetros para ver PDF</p>
          </ion-label>
        </ion-item>
        <pre v-if="!modoProduccion" class="ion-text-left">{{arbol}}</pre>
      </ion-col>
    </ion-row>
  </ion-content>
</ion-app>
</template>
<script>
import Api from '@/components/Api'
import SelectorCliente from '@/components/SelectorCliente'
import CobranzaModel from '@/models/Cobranza'
import Pdfgen from '@/models/Pdfgen'
const yo = Api.obtenerLocal('elite.yo') || {}
const empresa = Api.obtenerLocal('elite.empresa') || {}
export default {
  name: 'Facturable',
  components: {
    SelectorCliente
  },
  data() {
    return {
      cargando: false,
      yo,
      empresa,
      modoProduccion: Api.modoProduccion,
      filtros: {
        modo: ['mes', 'todo'][0],
        mes: new Date().toISOString().substring(0, 7),
        idCliente: '',
        area: '',
        idCorporativo: '',
        idAsunto: '',
        idAcuerdo: '',
        desglosarActividades: true,
        modoMontos: 'cobranza',
        incluirMontos: yo.rol == 'administrador',
        moneda: 'MXN',
        tipoCambio: 1,
      },
      clientesEnConsulta: [],
      arbol: {
        clientes: [],
        actividadesTodas: [],
        corporativo: {},
        acuerdo: {}
      },
      asunto: {},
      corporativo: {},
      pdf: ''
    }
  },
  props: {
    modo: {
      type: String,
      default: ['cliente', 'asunto', 'acuerdo', 'corporativo'][0]
    },
    id: {
      type: String,
      default: ''
    }
  },
  mounted() {
    console.log("Facturable", yo.nombre, empresa.nombre)
    if (this.modo == 'cliente' && this.id != '') {
      this.filtros.modo = 'todo'
      this.setFiltro('idCliente', this.id)
    }
    if (this.modo == 'acuerdo' && this.id != '') {
      this.setFiltro('idAcuerdo', this.id)
    }
    if (this.modo == 'asunto') {
      this.setFiltro('idAsunto', this.id)
    }
    Api.$on('clienteSeleccionado', this.clienteSeleccionado)
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    setFiltro(campo, valor) {
      this.filtros[campo] = valor
      console.log("setFiltro", campo, valor)
      this.obtener()
    },
    clienteSeleccionado(val) {
      this.setFiltro('idCliente', val)
    },
    async obtener() {
      if (!this.cargando) try {
        this.cargando = true
        console.log("Cargando", this.modo, this.id)
        // Clientes y corporativo
        if (this.modo == 'cliente') {
          this.arbol.corporativo = {}
          this.arbol.clientes = [(await Api.get('cliente', this.filtros.idCliente))]
        }
        if (this.modo == 'corporativo') {
          this.arbol.clientes = []
          this.arbol.corporativo = await Api.get('corporativo', this.filtros.idCorporativo)
          for (let id in this.arbol.corporativo.miembros)
            this.arbol.clientes.push(await Api.get('cliente', id))
        }
        if (this.modo == 'asunto') {
          let asunto = await Api.get('asunto', this.id)
          this.asunto = asunto
          this.arbol.clientes = [(await Api.get('cliente', asunto.idCliente))]
          this.clienteSeleccionado(asunto.idCliente)
        }
        // Asuntos y cobranza
        for (let indiceCliente in this.arbol.clientes) {
          const cl = this.arbol.clientes[indiceCliente]
          console.log("Buscando asuntos de", cl.nombre, this.modo)
          this.arbol.clientes[indiceCliente].asuntos = []
          if (this.modo == 'cliente') {
            this.arbol.clientes[indiceCliente].asuntos = (await Api.find('asunto', [
              'idCliente,eq,' + this.arbol.clientes[indiceCliente]._id
            ])) || []
            alert("Encontrados asuntos del cliente: " + this.arbol.clientes[indiceCliente].asuntos.length)
          } else if (this.modo == 'asunto') {
            console.log("recuperado unico asunto", this.id)
            this.arbol.clientes[indiceCliente].asuntos.push(await Api.get('asunto', this.id))
          } else if (this.modo == 'acuerdo') {
            this.arbol.acuerdo = await Api.get('acuerdocobranza', this.id)
            for (let idAsunto of tis.arbol.acuerdo.asuntos)
              this.arbol.clientes[indiceCliente].asuntos.push(await Api.get('asunto', idAsunto))
          } else { // Hacer un compendio de todas las actividades
            this.arbol.clientes[indiceCliente].asuntos = [{
              nombre: '.Actividades ' + (this.filtros.modo == 'mes' ? ('del mes ' + this.filtros.mes) : 'del asunto')
            }]
          }
        }
        // Actividades
        this.arbol.actividadesTodas = []
        this.arbol.total = {
          tiempo: 0,
          tiempoRepercutido: 0,
          costo: 0,
          cobranza: 0
        }
        const mes = this.filtros.mes
        const modo = this.filtros.modo
        console.log("Arbol pre", JSON.parse(JSON.stringify(this.arbol.clientes)))
        for (let indiceCliente in this.arbol.clientes) {
          for (let indiceAsunto in this.arbol.clientes[indiceCliente].asuntos) {
            const asunto = this.arbol.clientes[indiceCliente].asuntos[indiceAsunto]
            const idAsunto = asunto._id
            console.log("Procesando asunto", indiceAsunto, idAsunto, asunto.nombre)
            if (this.filtros.incluirMontos == true) {
              const guardar = true
              let cobranzaAsunto = await CobranzaModel.calcularParaAsunto(asunto, guardar)
              console.log("cobranzaAsunto?", asunto.area, asunto.nombre, cobranzaAsunto)
              await Api.wait(1000)
            }
            let actividades = (await Api.find('actividad', ['idAsunto,eq,' + idAsunto], {
                subset: [{
                  coleccion: 'usuario',
                  alias: 'abogado',
                  query: '_id,eq,',
                  sustituir: 'idAutor',
                  unico: true
                }]
              }))
              .map(a => {
                a.fechaNatural = new Date(a.fechaProgramada).toISOString()
                console.log("u", a.tipo, a.fechaNatural,
                  a.precioUnitario, a.minutos, a.minutosCliente,
                  a.monto, a.importe)
                return a
              })
              .sort((a, b) => a.descripcion < b.descripcion ? 1 : -1)
              .sort((a, b) => a.fechaNatural > b.fechaNatural ? 1 : -1)
            this.arbol.actividadesTodas = this.arbol.actividadesTodas.concat(actividades)
            const filtros = this.filtros
            actividades = actividades
              .filter(a => a.fechaNatural.startsWith(mes) || modo == 'todo')
              .filter(a => a.area == filtros.area || filtros.area == '')
            // Caso especial: Si el asunto no tiene actividades y tiene igualas dentro, hay que arrojar una pseudoactividad
            for (let h of asunto.histogramaCobranza || []) {
              if (h.tipo == 'iguala' && actividades.length == 0 && this.filtros.modo == 'mes') {
                actividades = [{
                  tipo: 'iguala',
                  monto: h.monto,
                  importe: h.monto,
                  precioUnitario: h.monto,
                  nombre: 'Actividades del mes',
                  descripcion: 'Actividades del mes',
                  abogado: {
                    nombre: this.empresa.nombre,
                    iniciales: '-'
                  },
                  fechaProgramada: this.filtros.mes + '-01',
                  fechaNatural: this.filtros.mes + '-01'
                }]
              }
            }
            for (let a of actividades) {
              this.arbol.total.tiempo += parseFloat(a.minutos || 0)
              this.arbol.total.tiempoRepercutido += parseFloat(a.minutosCliente || a.minutos)
              this.arbol.total.costo += parseFloat(a.minutos * a.precioUnitario)
              this.arbol.total.cobranza += parseFloat((a.minutosCliente || a.minutos) * (a.precioUnitario || 0))
              console.log("Total?", a.tipo, this.arbol.total.tiempoRepercutido, this.arbol.total.cobranza)
            }
            this.arbol.clientes[indiceCliente].asuntos[indiceAsunto].actividades = actividades
          }
        }
        // Done
        console.log("obtener => arbol", this.arbol)
        await Api.wait(200)
      } catch (e) {
        alert("No se pudo generar el reporte")
        console.error("EREPORTE", e)
      }
      this.cargando = false
      this.obtenerPDF()
    },
    async obtenerPDF() {
      this.cargando = true
      const styles = {
        header: {
          fontSize: 14,
          bold: true
        },
        subheader: {
          fontSize: 11,
          bold: true
        },
        quote: {
          italics: true
        },
        small: {
          fontSize: 8
        },
        right: {
          alignment: 'right'
        },
        center: {
          alignment: 'center'
        }
      }
      const defaultStyle = {
        fontSize: 10
      }
      const moneda = this.filtros.moneda
      const tipoCambio = this.filtros.tipoCambio
      let objeto = {
        styles,
        defaultStyle,
        content: [{
          layout: 'noBorders',
          table: {
            widths: [70, '*'],
            body: [
              [{
                  image: this.empresa.logo,
                  //height: 55,
                  width: 55
                },
                [{
                  text: (this.empresa.nombre).toUpperCase(),
                  style: 'header'
                }, {
                  text: (this.arbol.corporativo.nombre ? 'Corporativo: ' + this.arbol.corporativo.nombre : ''),
                  style: 'header'
                }, {
                  text: 'Reporte de actividades',
                  style: 'subheader'
                }, {
                  text: 'Consulta: ' + this.filtros.modo + ' ' + (this.filtros.modo == 'mes' ?
                    this.$options.filters.moment(this.filtros.mes + '-01', 'MMM YYYY') : '').toUpperCase(),
                  style: 'subheader'
                }]
              ]
            ]
          }
        }],
      }
      for (let cliente of this.arbol.clientes) {
        objeto.content.push(' ')
        objeto.content.push(' ')
        objeto.content.push({
          text: 'Cliente: ' + cliente.nombre.toUpperCase() + ' ' + (cliente.rfc || ''),
          style: 'subheader'
        })
        for (let asunto of cliente.asuntos) {
          if ((asunto.actividades || []).length > 0) {
            objeto.content.push('Asunto: ' + asunto.nombre || asunto.descripcion)
            if ((asunto.actor || '') != '')
              objeto.content.push('Actor: ' + asunto.actor)
            if ((asunto.demandado || '') != '')
              objeto.content.push('Demandado: ' + asunto.demandado)
            if ((asunto.folio || '') != '')
              objeto.content.push('Folio: ' + asunto.expediente + ', ' + asunto.juzgado)
            objeto.content.push(' ')
            const widths = this.filtros.incluirMontos ? [60, 45, '*', 55, 55] : [60, 45, '*', 55]
            let tablaAsunto = {
              layout: 'lightHorizontalLines',
              table: {
                widths,
                body: []
              }
            }
            let tableHeader = ['Fecha', 'Abogado', 'Descripción', {
              text: 'Mins',
              style: 'right'
            }]
            if (this.filtros.incluirMontos)
              tableHeader.push({
                text: 'Importe',
                style: 'right'
              })
            tablaAsunto.table.body.push(tableHeader)
            let renglonTotales = [{
              text: '',
              border: [false, true, false, false]
            }, {
              text: '',
              border: [false, true, false, false]
            }, {
              text: 'TOTAL ' + (moneda != 'MXN' ? moneda : ''),
              border: [false, true, false, false]
            }, {
              text: 0, // Minutos
              border: [false, true, false, false],
              style: 'right'
            }]
            if (this.filtros.incluirMontos)
              renglonTotales.push({
                text: 0, // Importe
                border: [false, true, false, false],
                style: 'right'
              })
            for (let a of asunto.actividades) {
              let renglonActividad = [{
                text: a.fechaNatural.substring(0, 10) + ' ' + a.tipo.substring(0, 1)
              }, {
                text: (a.abogado || {}).iniciales || (a.abogado || {}).nombre.split(' ')[0] || ''
              }, {
                text: a.descripcion || a.nombre || ''
              }, {
                text: a.minutosCliente || a.minutos || 0,
                style: 'right'
              }]
              if (this.filtros.incluirMontos)
                renglonActividad.push({
                  text: this.$options.filters.currency((a.monto || 0) / tipoCambio, ''),
                  style: 'right'
                })
              renglonTotales[3].text += parseFloat(a.minutosCliente || a.minutos || 0)
              if (renglonTotales[4])
                renglonTotales[4].text += parseFloat((a.monto || 0) / tipoCambio)
              tablaAsunto.table.body.push(renglonActividad)
            }
            if (renglonTotales[4])
              renglonTotales[4].text = this.$options.filters.currency(renglonTotales[4].text, '')
            tablaAsunto.table.body.push(renglonTotales)
            objeto.content.push(tablaAsunto)
          }
        }
      }
      this.pdf = await Pdfgen.generarDesdeObjeto(objeto)
      console.log("pdf?", this.pdf.length)
      this.cargando = false
    }
  }
}
</script>
