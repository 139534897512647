<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title v-if="yo.rol!='administrador'">Mis solicitudes</ion-title>
      <ion-title v-else>Solicitudes de los empleados</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-item>
      <ion-label>Ver</ion-label>
      <ion-select :value="modo" v-on:ionChange="setModo($event.detail.value)">
        <ion-select-option>Todas</ion-select-option>
        <ion-select-option>Aprobadas</ion-select-option>
        <ion-select-option>Pendientes</ion-select-option>
        <ion-select-option>Aprobables por socio</ion-select-option>
        <ion-select-option>Rechazadas</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-list>
      <ion-item-sliding v-for="p in propuestas" :key="p._id" v-if="filtrarEstaPropuesta(p)">
        <ion-item @click="verPropuesta(p)">
          <ion-note slot="start" :color="p.estado=='aprobada'?'success':'medium'">
            <i class="icon ion-ios-checkmark-circle" v-if="p.estado=='aprobada'"></i>
            <i class="icon ion-ios-remove-circle" v-if="p.estado=='rechazada'"></i>
            <i class="icon ion-ios-options" v-if="p.estado=='pendiente'"></i>
          </ion-note>
          <ion-label text-wrap>
            <h2>{{p.nombre}}</h2>
            <p v-if="p.presupuesto && p.presupuesto._id">{{p.presupuesto.nombre}} &gt; {{p.rubro}}</p>
            <p v-if="(yo._id!=p._id) && p.autor">Por {{p.autor.nombre}}</p>
            <p v-if="p.fechaLimite">Considerar antes de {{p.fechaLimite}}</p>
          </ion-label>
          <ion-note slot="end" :color="parseFloat(p.cantidadCotizada)>parseFloat(p.limiteGasto)?'warning':'dark'">{{p.cantidadCotizada | currency}}</ion-note>
        </ion-item>
        <ion-progress-bar v-if="yo.rol=='administrador' && p.presupuesto && p.presupuesto._id" :value="p.cantidadCotizada/p.presupuesto.cantidadAsignada"></ion-progress-bar>
        <ion-item-options slot="end">
          <ion-item-option v-if="yo.rol=='administrador' || yo._id==p._id" :color="colors('danger')" @click="eliminar(p)">Eliminar</ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button href="#/presupuestos/propuestas/0">
        <i class="icon icon-lg ion-ios-add"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
export default {
  name: 'PresupuestosPropuestas',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      propuestas: [],
      modo: 'Pendientes'
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    setModo(val) {
      this.modo = val
      console.log("setModo", val)
      this.obtener()
    },
    async obtener() {
      let filtros = []
      if (this.yo.rol != 'administrador')
        filtros.push('idAutor,eq,' + this.yo._id)
      this.propuestas = await Api.find('propuesta', filtros)
      for (let id in this.propuestas) {
        try {
          this.propuestas[id].autor = await Api.get('usuario', this.propuestas[id].idAutor)
          this.propuestas[id].presupuesto = await Api.get('presupuesto', this.propuestas[id].idPresupuesto)
        } catch (e) {
          console.error('EPROPUESTAAUTOR', e)
          this.propuestas[id].autor = {}
          this.propuestas[id].presupuesto = {}
        }
      }
      this.$forceUpdate()
      console.log("Propuestas", this.propuestas)
    },
    verPropuesta(p) {
      console.log("Ver propuesta", p._id, p.estado)
      if (p.estado != 'aprobada')
        this.$router.push('/presupuestos/propuestas/' + p._id)
      else
        this.$router.push('/presupuestos/gastos/' + p._id)
    },
    async eliminar(p) {
      if (true == confirm('Desea eliminar esta propuesta?')) {
        await Api.delete('propuesta', p._id)
        alert("Eliminada")
        await Api.wait(1000)
        this.obtener()
      }
    },
    filtrarEstaPropuesta(p) {
      console.log("filtrarEstaPropuesta?", p.nombre)
      let mostrar = true
      switch (this.modo) {
        case 'Pendientes':
          mostrar = (p.estado == 'pendiente')
          break
        case 'Rechazadas':
          mostrar = (p.estado == 'rechazada')
          break
        case 'Aprobadas':
          mostrar = (p.estado == 'aprobada')
          break
        case 'Aprobables por socio':
          mostrar = (p.limiteGasto < p.cantidadCotizada && p.estado == 'pendiente')
          break
        case 'Todas':
        default:
          mostrar = true
      }
      return mostrar
    }
  },
  mounted() {
    console.log("PresupuestosPartidas mounted")
    if (this.yo.rol != 'administrador')
      this.modo = 'Todas'
    if (this.yo.rol == 'socio')
      this.modo = 'Aprobables por socio'
    this.obtener()
  }
}
</script>
