<script>
import Api from '@/components/Api'
import Vue from 'vue'
let FechasPagoModel = new Vue({
  name: 'FechasPagoModel',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      spanMinimo: ((Api.obtenerLocal('elite.empresa') || {}).diasPostergarPago) || 90 // Dias
    }
  },
  methods: {
    async encontrarFechaDePagoMasCercana(fecha = 0 /*Debe ser en segundos*/ , proveedor) {
      proveedor = proveedor || {}
      // Encontrar si el proveedor tiene una regla de días de pago
      let spanMinimo = parseInt(this.spanMinimo)
      if (proveedor && proveedor.diasPago && parseInt(proveedor.diasPago || 0) > 0)
        spanMinimo = parseInt(proveedor.diasPago)
      fecha += (spanMinimo * 1000 * 60 * 60 * 24)
      //
      console.log("Buscar la fecha más cercana a", spanMinimo, new Date(fecha), proveedor.nombre)
      let distancias = {}
      let fechasPago = await Api.get('fechaspago')
      console.log("Fechas de pago?", fecha, fechasPago.map(u => u.fechaPago))
      for (let fechaPago of fechasPago) {
        let fechaGuardada = new Date(fechaPago.fechaPago).getTime()
        console.log("F", fechaGuardada, fecha)
        distancias[fechaPago.fechaPago] = Math.abs(fechaGuardada - fecha)
      }
      //
      let fechaTentativa = fecha
      let distanciaMasCorta = 100000000000
      for (let indice in distancias)
        if (distancias[indice] < distanciaMasCorta) {
          distanciaMasCorta = distancias[indice]
          fechaTentativa = indice
        }
      return new Promise(resolve => {
        let dias = new Date(fechaTentativa)
        let fechaActual = new Date()
        console.log("D?", dias, fechaActual)
        dias = ((fechaActual.getTime() + (1000 * 60 * 60 * 24)) - dias.getTime()) /
          (1000 * 60 * 60 * 24)
        let pagoDias = Math.floor(dias) + 2
        let respuesta = {
          fechaProgramado: fechaTentativa,
          pagoDias
        }
        console.log("FechasPago.encontrarFechaDePagoMasCercana", fecha, respuesta, dias, distancias)
        resolve(respuesta)
      })
    }
  }
})
export default FechasPagoModel
</script>
