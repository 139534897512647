<template>
  <ion-app main>
    <ion-header>
      <ion-toolbar :color="colors('secondary')">
        <ion-title>Publicaciones del blog</ion-title>
        <ion-buttons slot="start">
          <ion-button @click="openEnd">
            <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
            <i class="icon ion-ios-menu"></i>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item-sliding v-for="e in posts" :key="e._id">
          <ion-item :href="'#/posts/' + e._id">
            <ion-note slot="start" :color="e.borrador ? 'medium' : 'success'">
              <i class="icon ion-ios-checkmark-circle" v-if="!e.borrador"></i>
              <i class="icon ion-ios-create" v-else></i>
            </ion-note>
            <ion-label>
              <h2>{{ e.titulo }}</h2>
              <p>{{ e.contenido.substring(0, 150) }}</p>
              <p>{{ e.nombreAutor }}</p>
            </ion-label>
            <ion-thumbnail slot="end" v-if="e.imagen != ''">
              <ion-img :src="cdn + e.imagen" />
            </ion-thumbnail>
          </ion-item>
          <ion-item-options side="start">
            <ion-item-option color="danger" @click="eliminar(e)">
              <i class="icon ion-ios-trash"></i>
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </ion-list>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button :href="'#/posts/0'">
          <i class="icon ion-ios-add icon-lg"></i>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-app>
</template>
<style scoped>
.content {
  /*background-image: url('http://alherrera.tk/demos/elite.vue/dist/public/fondo_azul2.png');*/
  background-position: center;
}
</style>
<script>
import Api from "@/components/Api";
export default {
  name: "App",
  data() {
    return {
      yo: Api.obtenerLocal("elite.yo") || {},
      cdn: Api.cdn,
      posts: [],
    };
  },
  mounted() {
    this.obtener();
  },
  methods: {
    openEnd() {
      Api.$emit("open-menu");
    },
    async obtener() {
      this.posts = await Api.find("postpublic", [], {
        sort: ["fechaPublicado,-1"],
      });
      console.log("Eventos", this.eventos);
      return Promise.resolve(true);
    },
    async eliminar(p) {
      const msg = "" + p.nombre + "\n\nDesea eliminar?";
      if (true == confirm(msg)) {
        await Api.delete("postpublic", p._id);
        await Api.wait(2000);
        this.obtener();
      }
    },
  },
};
</script>
