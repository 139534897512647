<template>
<div>
  <ion-item :color="colors('light')" lines="none">
    <ion-label>Mis actividades <span v-if="actividades.length>0">&nbsp;({{actividades.length}})</span></ion-label>
    <ion-button slot="end" fill="clear" @click="copiarTabla">
      <i class="icon ion-ios-copy"></i>
    </ion-button>
  </ion-item>
  <table id="tabla">
    <thead>
      <tr>
        <th>Fecha</th>
        <th v-if="!unSoloAbogado">Abogado</th>
        <th>Área</th>
        <th>Asunto</th>
        <th>Descr.</th>
        <th class="ion-text-right">Tiempo (min)</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="a in actividades" v-if="actividades.length>0" :key="a._id">
        <td>{{a.fechaProgramada | moment('DD-MM-YYYY')}}</td>
        <td v-if="!unSoloAbogado">{{(a.abogado && a.abogado._id) ? (a.abogado.iniciales || a.abogado.nombre) : a.nombreAbogado}}</td>
        <td v-if="a.area">{{a.area}}</td>
        <td v-else>{{a.asunto ? a.asunto.area : a.idAsunto}}</td>
        <td>{{(a.asunto && a.asunto._id) ? a.asunto.nombre : ''}} {{(a.asunto && a.asunto._id) ? a.asunto.descripcion : a.nombreAsunto}}
          <span v-if="!a.nombreAsunto || a.nombreAsunto==''">[{{a.nombreCliente}}]</span>
        </td>
        <td><a :href="'#/actividad2/'+a._id" target="_blank">{{a.descripcion}}</a></td>
        <td class="ion-text-right">{{a.minutos}}</td>
      </tr>
      <tr v-else>
        <td :colspan="!unSoloAbogado?6:5">No hay resultados que mostrar</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td></td>
        <th>Total</th>
        <td></td>
        <td></td>
        <td v-if="!unSoloAbogado"></td>
        <th class="ion-text-right">{{totalTiempo}}</th>
      </tr>
    </tfoot>
  </table>
</div>
</template>
<script>
import Api from '@/components/Api'
import Reporteable from '@/models/Reporteable'
export default {
  name: 'ReporteMisActividades',
  props: ['filtros', 'abogados', 'clientes', 'areas', 'unSoloAbogado'],
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo'),
      actividades: [],
      asuntos: {},
      totalTiempo: 0,
      modoProduccion: Api.modoProduccion
    }
  },
  methods: {
    async obtener() {
      console.log("Obteniendo", this.filtros)
      let fI = new Date(this.filtros.fechaInicio).getTime()
      let fF = new Date(this.filtros.fechaFin).getTime()
      let filtros = [
        'fechaProgramada,gte,' + (fI + 10).toString(),
        'fechaProgramada,lt,' + (fF + 10).toString()
      ]
      if (this.unSoloAbogado && this.unSoloAbogado == 'true')
        filtros.push('idAbogado,eq,' + this.yo._id)
      if (this.filtros.idAbogado && this.filtros.idAbogado.length == 24)
        filtros.push('idAbogado,eq,' + this.filtros.idAbogado)
      if (this.filtros.abogados && this.filtros.abogados.length == 1 && this.filtros.abogados[0].length == 24)
        filtros.push('idAbogado,eq,' + this.filtros.abogados[0])
      let acts = await Api.find('actividad', filtros)
      console.log("Actividades", filtros, this.unSoloAbogado, typeof this.unSoloAbogado, acts.length, acts[0])
      console.log("fI", new Date(fI))
      console.log("fF", new Date(fF))
      acts = acts.sort((a, b) => {
        return a.fechaProgramada > b.fechaProgramada ? 1 : -1
      })
      let asuntos2 = await Api.get('asunto') // TODO si se estan obteniendo todos los asuntos no tiene sentido recuperar individualmente en :125
      let asuntos = {}
      for (let a of asuntos2)
        asuntos[a._id] = a
      console.log("Recuperados asuntos", asuntos)
      for (let i in acts) {
        if (!this.asuntos[acts[i].idAsunto] && acts[i].idAsunto) try {
          //this.asuntos[acts[i].idAsunto] = await Api.get('asunto', acts[i].idAsunto)
          this.asuntos[acts[i].idAsunto] = asuntos[acts[i].idAsunto]
          if (!asuntos[acts[i].idAsunto])
            //  this.asuntos[acts[i].idAsunto] = await Api.get('asunto', acts[i].idAsunto)
            throw new Error('Asunto no en cache')
        } catch (e) {
          console.error("EASUNTO", e)
          this.asuntos[acts[i].idAsunto] = {}
        }
        acts[i].asunto = this.asuntos[acts[i].idAsunto]
        if (!this.modoProduccion)
          acts[i].nombreAsunto = (this.asuntos[acts[i].idAsunto] || '').nombre
        acts[i].cliente = {}
        acts[i].abogado = {}
        acts[i].fechaProgramada2 = this.$options.filters.moment(acts[i].fechaProgramada, 'MMM-DD HH:mm')
        for (let c of this.clientes)
          if (c._id == acts[i].idCliente) {
            acts[i].cliente = c
            if (!this.modoProduccion)
              acts[i].nombreCliente = c.nombre
          }
        for (let a of this.abogados)
          if (a._id == acts[i].idAbogado) {
            acts[i].abogado = a
            if (!this.modoProduccion)
              acts[i].nombreAbogado = a.nombre
            /*for (let a of this.areas)
              if (a._id == acts[i].idArea)
                acts[i].area = a*/
          }
      }
      /*
      if (true)
        this.actividades = acts.filter(a => {
          //console.log("Mostrable?", a)
          return a &&
            a.asunto && a.asunto._id &&
            a.abogado && a.abogado._id &&
            a.cliente && a.cliente._id
        })
      else //*/
      const filtrosf = this.filtros
      this.actividades = acts
      //  .filter(a => filtrosf.area == '' || filtrosf.area == a.area)
      console.log("Acts obtener?")
      console.table(JSON.parse(JSON.stringify(this.actividades.sort((a, b) => {
        return a.minutos < b.minutos ? 1 : -1
      }))))
      let totalTiempo = 0
      for (let act of this.actividades)
        totalTiempo += parseFloat(act.minutos)
      this.totalTiempo = totalTiempo
      this.actividades = this.actividades.map(a => {
        a.descripcion = a.descripcion
          .replace(/%20/g, ' ').replace(/%E1/g, 'á').replace(/%F3/g, 'ó').replace(/%ED/g, 'í').replace(/%F3/g, 'ó').replace(/%2C/g, ',').replace(/%24/g, "\$")
        return a
      })
      console.log("Actividades!", this.actividades.length, this.actividades)
      return Promise.resolve(true)
      this.$forceUpdate()
    },
    copiarTabla() {
      function selectElementContents(el) {
        var body = document.body,
          range, sel;
        if (document.createRange && window.getSelection) {
          range = document.createRange();
          sel = window.getSelection();
          sel.removeAllRanges();
          try {
            range.selectNodeContents(el);
            sel.addRange(range);
          } catch (e) {
            range.selectNode(el);
            sel.addRange(range);
          }
        } else if (body.createTextRange) {
          range = body.createTextRange();
          range.moveToElementText(el);
          range.select();
        }
      }
      let tabla = document.querySelector('#tabla')
      selectElementContents(tabla)
      document.execCommand('copy')
      console.log("Copiado?", tabla.value)
      alert("Copiado al portapapees")
    },
    async obtener2() {
      let fE = []
      if (this.unSoloAbogado && this.unSoloAbogado == 'true') {
        if (this.filtros.idAbogado && this.filtros.idAbogado.length == 24)
          fE.push('idAbogado,eq,' + this.filtros.idAbogado)
        else
          fE.push('idAbogado,eq,' + this.yo._id)
      } else if (this.filtros.abogados && this.filtros.abogados[0]) {
        fE.push('idAbogado,eq,' + this.filtros.abogados[0])
      }
      let noFiltrar = true
      let resumen = await Reporteable.obtenerResumenActividades(this.filtros.fechaInicio, this.filtros.fechaFin, null, 'dia', fE, noFiltrar)
      const f2 = this.filtros
      console.log("Resumen?", this.filtros, this.unSoloAbogado, fE, resumen.actividades)
      this.actividades = resumen.actividades
        .filter(a => a.area == f2.area || f2.area == '' || !f2.area)
      this.totalTiempo = this.actividades.reduce((a, b) => a + parseFloat(b.minutos), 0)
      return Promise.resolve()
    }
  },
  watch: {
    filtros: function() {
      //this.obtener()
      this.obtener2()
    }
  },
  async mounted() {
    console.log("ReporteMisActividades")
    //await this.obtener()
    await this.obtener2()
    /*console.table(resumen.actividades.sort((a, b) => {
      return a.minutos < b.minutos ? 1 : -1
    }))
    let u = JSON.parse(JSON.stringify(this.actividades.filter(a => resumen.actividades.map(b => b._id).indexOf(a._id) == -1)))
    console.table(u)
    console.log(u)*/
  }
}
</script>
