<script>
import Api from '@/components/Api'
import Vue from 'vue'
let UsuarioModel = new Vue({
  name: 'UsuarioModel',
  methods: {
    lista(rol, forzar, incluirTodos) {
      rol = rol || ''
      forzar = forzar && true
      incluirTodos = incluirTodos && true
      forzar = true // Pos bueno
      return new Promise(async (resolve, reject) => {
        let usuarios
        try {
          if (forzar == true)
            throw new Error('Lista de usuarios descarga forzada')
          usuarios = []
          for (let indice in localStorage)
            if (indice.startsWith('elite.usuario'))
              usuarios.push(Api.obtenerLocal(indice))
          console.log("Usuarios recuperados de cache?", usuarios.length)
          if (usuarios.length == 0)
            throw new Error('Lista de usuarios no en cache')
        } catch (e) {
          if (typeof e == 'string')
            console.log(e)
          else
            console.info(e)
          usuarios = (await Api.find('usuario', ['rol,ne,proveedor']))
            .filter(u => {
              if (incluirTodos)
                return true
              else
                return !(u.fechaBaja && !isNaN(u.fechaBaja))
            })
          // guardar en cache
          for (let u of usuarios) {
            Api.guardarLocal('elite.usuario.' + u._id, u)
          }
        }
        usuarios = usuarios
          .filter(u => {
            //return u.rol != 'administrador'
            return rol == '' || u.rol == rol || !Api.modoProduccion
          })
        //.filter(u => u.rol != 'proveedor')
        console.log("Usuarios filtrados", rol, usuarios.length)
        usuarios = usuarios.sort((a, b) => {
          return (a.nombre || '').toLowerCase() > (b.nombre || '').toLowerCase() ? 1 : -1
        })
        resolve(usuarios)
      })
    },
    async activosALaFecha(fecha) {
      fecha = new Date(fecha).getTime()
      let usuarios = await this.lista(null, null, true)
      console.log("activosALaFecha filtrables?", usuarios.length)
      usuarios = usuarios
        .map(u => {
          if (!u.fechaAlta || u.fechaAlta > fecha)
            u.fechaAlta = new Date().getTime() - (1000 * 60 * 60 * 24 * 365)
          if (!u.fechaBaja)
            u.fechaBaja = new Date().getTime() + (1000 * 60 * 60 * 24 * 365 * 10)
          return u
        })
        .filter(u => {
          let activo = u.fechaAlta < fecha && fecha < u.fechaBaja
          /*
          console.log("Activo?", activo, u.nombre, {
            fechaAlta: u.fechaAlta || 'sinfechaalta',
            fechaBaja: u.fechaBaja || 'sinfechabaja',
            fechaAltaNatural: new Date(u.fechaAlta),
            fechaBajaNatural: new Date(u.fechaBaja)
          })//*/
          return activo
        })
      console.log("activosALaFecha filtrados", usuarios.length)
      usuarios = usuarios.sort((a, b) => {
        return a.nombre < b.nombre ? 1 : -1
      })
      return Promise.resolve(usuarios)
    },
    obtener(id, obtenerPassword) {
      obtenerPassword = obtenerPassword && true
      return new Promise(async (resolve, reject) => {
        try {
          let usuario = Api.obtenerLocal('elite.usuario.' + id)
          if (!(usuario && usuario._id))
            throw new Error('Cliente no en caché: ' + id)
          else {
            if (!obtenerPassword) {
              if (usuario.password) delete usuario.password
              if (usuario.password2) delete usuario.password2
            }
            resolve(usuario)
          }
        } catch (e) {
          let usuario = await Api.get('usuario', id)
          if (usuario.password)
            delete usuario.password
          if (usuario.password2)
            delete usuario.password2
          resolve(usuario)
        }
      })
    }
  },
  mounted() {
    console.log("UsuarioModel", Api.modoProduccion)
  }
})
export default UsuarioModel
</script>
