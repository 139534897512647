<template>
<div>
  <ion-item>
    <ion-label position="floating">Desde fecha (guardado)</ion-label>
    <ion-input debounce="1000" cclass="ion-text-right" :color="colors('primary')" type="date" :value="fechaInicio | moment('YYYY-MM-DD')" v-on:ionChange="cambiarFecha($event.detail.value)" />
  </ion-item>
  <ion-searchbar debounce="500" placeholder="Filtrar por cliente, área o descripción" v-on:ionChange="filtrar($event.detail.value)" />
  <ion-list v-if="actividades.length>0 ">
    <ion-item-sliding v-for="a in actividades" :key="a._id">
      <ion-item v-on:click="ver(a)">
        <ion-label text-wrap>
          <h2>{{a.descripcion}}</h2>
          <p v-if="a.cliente && a.cliente._id">{{a.cliente.nombre}} <span v-if="a.minutos">({{a.minutos}}min)</span></p>
          <p v-if="a.asunto && a.asunto._id">{{(a.asunto.area||'').substring(0,3).toUpperCase()}} {{a.asunto.nombre}}</p>
        </ion-label>
        <ion-note side="end">{{a.fechaProgramada | moment('MMM DD')}} <span v-if="new Date(a.fechaProgramda).getYear()!=new Date().getYear()">{{a.fechaProgramda | moment('YYYY')}}</span></ion-note>
      </ion-item>
      <ion-item-options slot="end">
        <ion-item-option :color="colors('primary')" v-on:click="verAsunto(a.idAsunto)">Ir a Asunto</ion-item-option>
        <ion-item-option :color="colors('danger')" v-on:click="eliminar(a)" v-if="permiso('BorrarActividades')||a.idAbogado==yo._id">
          Eliminar
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-list>
  <ion-item v-if="actividades.length==0 && !cargando">
    <ion-label :color="colors('danger')" text-center text-wrap>
      <h2>No hay actividades registradas en este lapso de tiempo</h2>
    </ion-label>
  </ion-item>
  <ion-item v-if="cargando">
    <ion-label :color="colors('primary')" text-center>Cargando...</ion-label>
  </ion-item>
</div>
</template>
<script>
import Api from '@/components/Api'
import Cliente from '@/models/Cliente'
export default {
  name: 'MisActividadesHandler',
  props: {
    ordenarPorFechaGuardado: {
      type: Boolean,
      default: false
    },
    usarHandlerSecundario: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      actividades: [],
      actividadesTodas: [],
      clientes: [],
      fechaInicio: new Date().getTime() - (1000 * 60 * 60 * 24),
      intervalObtener: null,
      filtro: ''
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    ver(a) {
      if (this.usarHandlerSecundario)
        this.$router.push('/actividad2/' + a._id)
      else
        this.$router.push('/actividad/' + a._id)
    },
    async eliminar(a) {
      if (true == confirm('Desea eliminar esta actividad? Esto no se puede deshacer')) {
        let yo = this.yo
        await Api.delete('actividad', a._id)
        try {
          await Api.save('bitacora', {
            mensaje: 'Actividad eliminada de cliente ' + (a.cliente || {}).nombre,
            idAutor: yo._id,
            fecha: new Date().getTime()
          })
        } catch (e) {
          console.error(e)
        }
        this.obtener()
        alert("Actividad eliminada")
      }
    },
    verAsunto(id) {
      this.$router.push('/asunto/' + id)
    },
    async obtener(fechaInicio) {
      fechaInicio = fechaInicio || this.fechaInicio
      this.cargando = true
      let filtros = [
        'idAbogado,eq,' + this.yo._id
      ]
      if (this.ordenarPorFechaGuardado)
        filtros.push('fecha,gte,' + fechaInicio)
      else
        filtros.push('fechaProgramada,gte,' + fechaInicio)
      let actividades = await Api.find('actividad', filtros)
      console.log("Obteniendo", filtros, new Date(fechaInicio), actividades)
      for (let i in actividades) try {
        if (actividades[i].idCliente)
          //actividades[i].cliente = await Api.get('cliente', actividades[i].idCliente)
          for (let cl of this.clientes)
            if (cl._id == actividades[i].idCliente)
              actividades[i].cliente = cl
        if (actividades[i].idAsunto)
          actividades[i].asunto = await Api.get('asunto', actividades[i].idAsunto)
        if (actividades[i].idArea)
          actividades[i].area = await Api.get('area', actividades[i].idArea)
      } catch (e) {
        console.log("No se pudieron obtener todos los datos")
      }
      let ordenarPorFechaGuardado = this.ordenarPorFechaGuardado
      this.actividades = actividades.sort((a, b) => {
        if (ordenarPorFechaGuardado)
          return a.fecha < b.fecha ? 1 : -1
        else
          return a.fechaProgramada < b.fechaProgramada ? 1 : -1
      })
      this.actividadesTodas = JSON.parse(JSON.stringify(this.actividades))
      this.cargando = false
      if (this.filtro != '')
        this.filtrar(this.filtro)
    },
    filtrar(val) {
      let mostrar = false
      val = (val || '').toLowerCase().trim()
      this.filtro = val
      if (val == '')
        this.actividades = JSON.parse(JSON.stringify(this.actividadesTodas))
      else {
        this.actividades = this.actividadesTodas.filter(a => {
          let mostrar = false
          if (a.descripcion.toLowerCase().indexOf(val) >= 0)
            mostrar = true
          if (typeof a.area == 'string' && (a.area || '').toLowerCase().indexOf(val) >= 0)
            mostrar = true
          if (typeof a.area == 'object' && (a.area.nombre || '').toLowerCase().indexOf(val) >= 0)
            mostrar = true
          if (a.cliente && a.cliente.nombre && (a.cliente.nombre || '').toLowerCase().indexOf(val) >= 0)
            mostrar = true
          return mostrar
        })
      }
      console.log("Filtradas", this.actividades.length, this.actividadesTodas.length, val)
      this.$forceUpdate()
    },
    cambiarFecha(val) {
      let offset = (new Date().getTimezoneOffset() / 60) + 1
      let fechaInicio = new Date(new Date(val).getTime() + (offset * 1000 * 60 * 60))
      fechaInicio.setMilliseconds(0)
      fechaInicio.setSeconds(0)
      fechaInicio.setMinutes(0)
      fechaInicio.setHours(0)
      console.log("Fecha", val, offset)
      this.fechaInicio = fechaInicio.getTime()
      this.obtener()
    }
  },
  async mounted() {
    this.clientes = await Cliente.lista()
    this.cambiarFecha(this.$options.filters.moment(new Date().getTime() - (1000 * 60 * 60 * 24), 'YYYY-MM-DD'))
    this.obtener()
    if (this.usarHandlerSecundario)
      this.intervalObtener = setInterval(this.obtener, 5000)
    Api.$on('MisActividadesHandler.obtener', this.obtener)
  },
  beforeDestroy() {
    Api.$off('MisActividadesHandler.obtener')
    clearInterval(this.intervalObtener)
  }
}
</script>
