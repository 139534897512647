<script>
import Api from '../components/Api'
export default {
  guardar: function(msg) {
    let mensaje = null
    if (typeof msg == 'string')
      mensaje = {
        mensaje: msg
      }
    else mensaje = msg
    mensaje.fecha = new Date().getTime()
    return Api.save('bitacora', mensaje)
  }
}
</script>
