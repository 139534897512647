<template>
<div>
  <ion-item>
    <ion-label position="floating">Lapso de tiempo</ion-label>
    <ion-select :value="filtros2.porRango" v-on:ionChange="setVal('porRango',$event.detail.value)" interface="popover">
      <ion-select-option value="mes">Por mes</ion-select-option>
      <ion-select-option value="dia">Por días específicos</ion-select-option>
    </ion-select>
  </ion-item>
  <div v-if="filtros2.porRango=='dia'">
    <ion-item v-if="filtros.fechaInicio && filtroMostrable('fechaInicio')">
      <ion-label text-wrap :position="mostrarResultados?'stacked':''">Desde fecha</ion-label>
      <!--ion-icon :color="colors('primary')" slot="start" name="calendar"></ion-icon-->
      <ion-input class="ion-text-right" type="date" debounce="500" :value="filtros.fechaInicio" v-on:ionChange="filtroFecha('fechaInicio',$event.detail.value)"></ion-input>
    </ion-item>
    <ion-item v-if="filtros.fechaFin && filtroMostrable('fechaFin')">
      <ion-label text-wrap :position="mostrarResultados?'stacked':''">Hasta fecha</ion-label>
      <!--ion-icon :color="colors('primary')" slot="start" name="calendar"></ion-icon-->
      <ion-input class="ion-text-right" type="date" debounce="500" :value="filtros.fechaFin" v-on:ionChange="filtroFecha('fechaFin',$event.detail.value)"></ion-input>
    </ion-item>
  </div>
  <div v-if="filtros2.porRango=='mes'">
    <ion-item v-if="filtroMostrable('fechaInicio')">
      <ion-label position="floating">Ver mes de</ion-label>
      <ion-input type="month" debounce="500" :value="filtros.fechaInicio" v-on:ionChange="filtroFecha('fechaInicio',$event.detail.value,true)"></ion-input>
    </ion-item>
  </div>
  <div>
    <ion-item>
      <ion-label position="floating">Ver área</ion-label>
      <ion-select :value="filtros2.area" v-on:ionChange="setVal('area',$event.detail.value)">
        <ion-select-option :value="''">Todas</ion-select-option>
        <ion-select-option v-for="a in areas" :key="a" :value="a">{{a}}</ion-select-option>
      </ion-select>
    </ion-item>
  </div>
  <ion-item v-if="abogados && abogados.length>1 && filtroMostrable('idAbogado')">
    <ion-label text-wrap :position="mostrarResultados?'stacked':''">Incluir abogado </ion-label>
    <ion-note :color="colors('primary')" slot="start">
      <i class="icon ion-ios-briefcase"></i>
    </ion-note>
    <ion-select class="ion-text-right" :value="filtros.abogados" v-on:ionChange="seleccionarAbogados($event.detail.value)">
      <ion-select-option :value="''">Todos</ion-select-option>
      <ion-select-option v-for="a in abogados" :key="a._id" :value="a._id">{{a.nombre}}</ion-select-option>
    </ion-select>
  </ion-item>
  <SelectorCliente :color="'dark'" :value="filtros.clientes" v-if="filtroMostrable('idCliente')" />
  <ion-item v-if="filtroMostrable('idCorporativo')">
    <ion-label text-wrap :position="mostrarResultados?'stacked':''">Incluir corporativo</ion-label>
    <ion-note :color="colors('primary')" slot="start">
      <i class="icon ion-ios-contacts"></i>
    </ion-note>
    <ion-select class="ion-text-right" :value="filtros.corporativos" v-on:ionChange="seleccionarCorporativos($event.detail.value)">
      <ion-select-option :value="''">Todos</ion-select-option>
      <ion-select-option v-for="a in corporativos" :key="a._id" :value="a._id">{{a.nombre}}</ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item v-if="permiso('ReporteActividadesPorClienteConMontos') && empresa.moduloTarifas">
    <ion-label text-wrap :position="mostrarResultados?'stacked':''">Ver montos estándar</ion-label>
    <ion-toggle :checked="filtros.verMontos" v-on:ionChange="toggleMontos($event.detail.checked)"></ion-toggle>
  </ion-item>
</div>
</template>
<script>
import Api from '@/components/Api'
import SelectorCliente from '@/components/SelectorCliente'
import Cliente from '@/models/Cliente'
export default {
  name: 'FormFiltros',
  components: {
    SelectorCliente
  },
  props: {
    filtros: {
      type: Object,
      default: function() {
        return {}
      }
    },
    stackLabels: {
      type: Boolean,
      default: false
    },
    filtrosMostrables: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      empresa: Api.obtenerLocal('elite.empresa') || {},
      mostrarResultados: false,
      clientes: [],
      areas: [],
      abogados: [],
      clientesTodos: [],
      abogadosTodos: [],
      filtros2: {
        area: ''
      },
      esMovil: Api.esMovil()
    }
  },
  /*
  watch: {
    filtros2: function(a) {
      console.log("Filtros cambiados", a)
      Api.$emit('filtrosCambiados', a)
    }
  },*/
  methods: {
    filtroMostrable(campo) {
      return this.filtrosMostrables == [] || this.filtrosMostrables.indexOf(campo) >= 0
    },
    filtroFecha(t, val, porMes) {
      porMes = porMes && true
      this.filtros2[t] = val
      console.log("Config filtro", t, val, porMes)
      if (porMes) {
        this.filtros2.fechaInicio = val
        let fechaFin = new Date(new Date(this.filtros2.fechaInicio).getTime() + (1000 * 60 * 60 * 24 * 35))
        fechaFin.setDate(0)
        fechaFin.setHours(23)
        fechaFin.setMinutes(59)
        fechaFin.setSeconds(59)
        fechaFin.setMilliseconds(999)
        this.filtros2.fechaFin = this.$options.filters.moment(fechaFin, 'YYYY-MM-DD')
        console.log("Filtro por mes", this.filtros2.fechaInicio, this.filtros2.fechaFin)
        Api.$emit('filtrosCambiados', this.filtros2)
      }
      if (
        this.filtros2.clientes.length > 0 &&
        !(this.filtros2.clientesCorporativo && this.filtros2.clientesCorporativo.length && this.filtros2.clientesCorporativo.length > 0)
      )
        this.seleccionarClientes(this.filtros2.clientes)
      console.log("Corp?", this.filtros.idCorporativo, "u", this.filtros2.idCorporativo)
    },
    toggleMontos(val) {
      this.filtros2.verMontos = (val == true)
      Api.$emit('filtrosCambiados', this.filtros2)
      this.$forceUpdate()
    },
    setVal(campo, valor) {
      this.filtros2[campo] = valor
      console.log("setVal", campo, this.filtros2[campo])
      Api.$emit('filtrosCambiados', this.filtros2)
      this.$forceUpdate()
    },
    seleccionarAbogados(a) {
      console.log("Abogados?", a)
      this.filtros2.abogados = typeof a == 'string' ? a.split(',') : a
      if (a.indexOf('Todos') >= 0) {
        let abogadosTodos = []
        for (let a of this.abogados)
          abogadosTodos.push(a._id)
        console.log("Abogados Todos", abogadosTodos)
        this.filtros2.abogados = abogadosTodos
      } else {
        console.log("Abogados seleccionados", this.filtros2.abogados)
      }
      Api.$emit('filtrosCambiados', this.filtros2)
    },
    async seleccionarClientes(clientes) {
      this.filtros2.idCorporativo = ''
      this.filtros2.clientesCorporativo = null
      this.filtros2.clientes = typeof clientes == 'string' ? clientes.split(',') : clientes
      console.log("Clientes seleccionados", this.filtros2.clientes.length)
      if (clientes.indexOf('Todos') >= 0) {
        let clientesTodos = []
        for (let a of this.clientes)
          clientesTodos.push(a._id)
        console.log("Clientes Todos", clientesTodos)
        this.filtros2.clientes = clientesTodos
      } else {
        console.log("Clientes seleccionados", this.filtros2.clientes.length)
      }
      if (this.filtros2.clientes.length == 1)
        this.filtros2.idCliente = this.filtros2.clientes[0]
      Api.$emit('filtrosCambiados', this.filtros2)
    },
    async seleccionarCorporativos(corp) {
      this.filtros2.idCliente = ''
      this.filtros2.idCorporativo = corp
      this.filtros2.clientesCorporativo = []
      let corporativo = await Api.get('corporativo', corp) || {}
      for (let miembro in corporativo.miembros)
        this.filtros2.clientesCorporativo.push(miembro)
      console.log("seleccionado corporativo", corporativo.nombre, corporativo, this.filtros2.idCorporativo, this.filtros2.clientesCorporativo)
      Api.$emit('filtrosCambiados', this.filtros2)
      this.$forceUpdate()
    }
  },
  async mounted() {
    console.log("FormFiltros mounted")
    this.areas = (await Api.get('area'))
      .sort((a, b) => a.nombre > b.nombre ? 1 : -1)
      .map(a => a.nombre)
    this.clientes = (await Cliente.lista(true)).sort((a, b) => {
      return a.nombre > b.nombre ? 1 : -1
    })
    this.corporativos = (await Api.get('corporativo')).sort((a, b) => {
      return a.nombre > b.nombre ? 1 : -1
    })
    console.log("Corporativos?", this.corporativos)
    this.abogados = (await Api.get('usuario')).sort((a, b) => {
      return a.nombre > b.nombre ? 1 : -1
    })
    if (this.filtros)
      //this.filtros2 = JSON.parse(JSON.stringify(this.filtros))
      this.filtros2 = this.filtros
    // Hacer que la fecha del mes se configure completa sola
    let porMes = true
    this.filtroFecha('fechaInicio', this.$options.filters.moment(new Date(), 'YYYY-MM'), true)
    console.log("Fechas configuradas?", this.filtros.fechaInicio, this.filtros.fechaFin)
    /*
    if (false) {
      let vm = this
      setInterval(_ => {
        console.log("Filtros", this.filtros2)
      }, 2000)
    }//*/
    Api.$on('clienteSeleccionado', this.seleccionarClientes)
  },
  beforeDestroy() {
    Api.$off('clienteSeleccionado')
  }
}
</script>
