<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-title>Proveedor {{usuario.nombre || 'nuevo'}}</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-list mode="ios" class="ion-padding">
      <ion-item-divider>General</ion-item-divider>
      <ion-item v-for="f in formUsuario" :key="f.name" v-if="f.name!='rol'||!modoProduccion">
        <ion-label text-wrap position="">{{f.label}}</ion-label>
        <ion-input v-if="!f.type || f.type!='select'" debounce="100" :value="usuario[f.name]" :color="colors('primary')" class="ion-text-right" v-on:ionChange="setU(f.name,$event.detail.value,f.type)" :type="f.type||'text'"></ion-input>
        <ion-select v-if="f.type && f.type=='select'" :color="colors('primary')" v-on:ionChange="setU(f.name,$event.detail.value,f.type)" :value="usuario[f.name]" class="ion-text-right">
          <ion-select-option v-for="o in f.options" :key="o" text-capitalize :value="o">{{o | uppercase}}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label>Usuario activo</ion-label>
        <ion-toggle :checked="!usuario.desactivado" v-on:ionChange="usuario.desactivado=($event.detail.checked==false)"></ion-toggle>
      </ion-item>
      <ion-item>
        <ion-label>Fecha de alta </ion-label>
        <ion-input type="date" readonly class="ion-text-right" debounce="1000" :value="usuario.fechaAlta" />
      </ion-item>
      <ion-item lines="none">&nbsp;</ion-item>
    </ion-list>
    <ion-card>
      <label for="opinionDeCumplimiento">
        <input type="file" class="hidden" name="opinionDeCumplimiento" id="opinionDeCumplimiento" v-on:change="subirOpinionDeCumplimiento()" />
        <ion-item>
          <ion-note slot="start" :color="colors('danger')">
            <i class="icon ion-ios-information-circle-outline icon-lg" v-if="!opinionDeCumplimientoVigente"></i>
            <i class="icon ion-ios-checkmark-circle-outline icon-lg" v-else></i>
          </ion-note>
          <ion-label text-wrap>
            <h2>Opinión de cumplimiento</h2>
            <p v-if="opinionDeCumplimientoVigente">Subida el {{usuario.opinionDeCumplimientoFecha | moment('MMM DD')}}: {{usuario.opinionDeCumplimiento.split('.')[0]}}</p>
            <p v-else>Su opinión de cumplimiento está desactualizada. Haga clic aquí para subirla.</p>
          </ion-label>
        </ion-item>
      </label>
    </ion-card>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('primary')" @click="guardar" v-if="permiso('EditarProveedores') || (permiso('CrearProveedores') && !usuario._id)">
        <i class="icon ion-ios-save icon-lg"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
import Proveedor from '@/models/Proveedor'
export default {
  name: 'CatalogoProveedor',
  props: ['id', 'esModal'],
  filters: {
    uppercase: function(u) {
      return (u || '').toUpperCase()
    }
  },
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      usuario: {
        rol: 'proveedor',
        fechaAlta: new Date().toISOString().substring(0, 10),
        permisos: this.permisos()
      },
      opinionDeCumplimientoVigente: false,
      modoProduccion: Api.modoProduccion,
      socios: [],
      formUsuario: [{
        label: 'Nombre',
        name: 'nombre',
        required: true
      }, {
        label: 'RFC',
        name: 'rfc',
        required: true
      }, {
        label: 'Email',
        name: 'email',
        required: true
      }, {
        label: 'Email alternativo',
        name: 'emailAlternativo',
        required: false
      }, {
        label: 'Contraseña',
        name: 'password',
        type: 'password',
        required: true
      }, {
        label: 'Nombre del banco',
        name: 'bancoNombre'
      }, {
        label: 'Número de cuenta o tarjeta',
        name: 'bancoCuenta'
      }, {
        label: 'CLABE',
        name: 'bancoClabe'
      }, {
        label: 'Dirección (fiscal)',
        name: 'direccionFiscal'
      }, {
        label: 'Dias de pago (opcional)',
        name: 'diasPago',
        type: 'number'
      }],
      rutasDefault: [{
        ruta: '/asuntos',
        label: 'Asuntos'
      }, {
        ruta: '/actividades',
        label: 'Actividades'
      }, {
        ruta: '/actividad',
        label: 'Nueva actividad'
      }],
      mostrarPermisos: this.permiso('editarUsuarios') || !Api.modoProduccion
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    setU(campo, valor, tipo) {
      this.usuario[campo] = valor
      tipo = tipo || 'text'
      if (campo == 'tipo') {
        this.usuario.rol = valor
        console.log("Cambiando tipo de usuario y rol", campo, valor)
      }
      if (tipo == 'number')
        this.usuario[campo] = parseFloat(this.usuario[campo])
      if (campo == 'tipo') {
        this.usuario.permisos = JSON.parse(JSON.stringify(this.permisosDefault(valor)))
        console.log("Cambiando permisos", valor, this.usuario.permisos)
        this.$forceUpdate()
      }
      console.log("setU", campo, this.usuario[campo], tipo)
    },
    calcularOpinionDeCumplimientoVigente() {
      let vigente = false
      let fechaMasAntiguaPermitida = new Date().getTime() - (1000 * 60 * 60 * 24 * 30)
      if (!this.usuario.opinionDeCumplimiento ||
        !this.usuario.opinionDeCumplimientoFecha ||
        this.usuario.opinionDeCumplimientoFecha < fechaMasAntiguaPermitida)
        vigente = false
      else vigente = true
      this.opinionDeCumplimientoVigente = vigente
    },
    setPermiso(campo, valor) {
      if (!this.usuario.permisos)
        this.usuario.permisos = JSON.parse(JSON.stringify(this.permisosDefault(this.usuario.tipo)))
      this.usuario.permisos[campo] = valor
      console.log("Configurado", campo, valor, this.usuario.rol)
    },
    async subirOpinionDeCumplimiento() {
      console.log("subirOpinionDeCumplimiento?")
      try {
        this.usuario.opinionDeCumplimiento = await Api.upload('opinionDeCumplimiento')
        this.usuario.opinionDeCumplimientoFecha = new Date().getTime()
        this.opinionDeCumplimientoVigente = true
        this.$forceUpdate()
        alert("Opinión de cumplimiento subida. Se guardará el proveedor.")
        await Api.wait(2000)
        this.guardar()
      } catch (e) {
        console.error("ECUMPLIMIENTO", e)
        alert("No se pudo subir la opinión de cumplimiento")
      }
    },
    async guardar() {
      let guardable = true
      for (let c of this.formUsuario) {
        if (c.required && !this.usuario[c.name]) {
          alert('Faltan datos: ' + c.name)
          guardable = false
        }
      }
      // Duplicados
      let duplicado = false
      if (!this.usuario._id) {
        let usuarios = await Proveedor.lista()
        for (let u of usuarios) {
          if (u.nombre == this.usuario.nombre ||
            u.email == this.usuario.email ||
            u.rfc == this.usuario.rfc) {
            console.error("Usuario duplicado", this.usuario, u)
            duplicado = true
          }
        }
      }
      // Forzar el rol
      this.usuario.rol = 'proveedor'
      //
      if (duplicado) {
        alert("Usuario duplicado")
      } else if (guardable) {
        let usuario = await Api.save('usuario', this.usuario)
        if (!this.usuario._id && usuario._id)
          this.usuario._id = usuario._id
        //this.usuario = usuario
        Api.guardarLocal('elite.usuario.' + usuario._id, this.usuario)
        alert("Guardado")
        let vm = this
        if (!this.esModal)
          setTimeout(_ => {
            //vm.$router.push('/catalogos/proveedores')
            window.history.go(-1)
          }, 1000)
        else {
          Api.$emit('modalProveedorDismiss', this.usuario._id)
        }
      }
    }
  },
  async mounted() {
    //
    let areas = await Api.get('area')
    areas = areas.map(a => {
      return a.nombre
    })
    //
    console.log("Catalogo proveedor", this.id)
    if (this.id && this.id.length == 24) {
      let obtenerPassword = true
      try {
        this.usuario = await Api.get('usuario', this.id)
      } catch (e) {
        this.usuario = await Proveedor.obtener(this.id, obtenerPassword)
      }
      this.calcularOpinionDeCumplimientoVigente()
      console.log("Usuario recuperado", this.usuario)
      this.$forceUpdate()
    }
    console.log("Permisos?", this.permisos())
  }
}
</script>
