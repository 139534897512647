<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-title>Reporte de cobranza</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <ion-icon name="menu" :color="colors('light')"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar v-if="cargando" type="indeterminate"></ion-progress-bar>
  </ion-header>
  <ion-content class="content">
    <ion-row>
      <ion-col size="12" :size-md="pdf!=''?4:12" :size-sm="pdf!=''?5:12">
        <ion-row>
          <ion-col>
            <ion-item>
              <ion-label>Desglosar actividades</ion-label>
              <ion-toggle :checked="desglosarActividades" v-on:ionChange="modover='incluir';desglosarActividades=($event.detail.checked==true)" slot="end"></ion-toggle>
            </ion-item>
          </ion-col>
          <ion-col v-if="modo=='mes'">
            <ion-item>
              <ion-label>Mes de</ion-label>
              <ion-input color="primary" class="ion-text-right" :value="mes" type="month" v-on:ionChange="mes=$event.detail.value"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="8">
            <ion-searchbar :placeholder="'Buscar por cliente o folio ('+acuerdos.length+' acuerdos)'" :value="filtro" v-on:ionChange="filtro=($event.detail.value).toUpperCase().trim()" debounce="500"></ion-searchbar>
          </ion-col>
          <ion-col size-xs="12" size-sm="6" size-md="3" size-lg="2">
            <ion-button expand="block" :disabled="cargando || acuerdosIncluidos.length==0" @click="generar">{{cargando?'Cargando':'Generar'}}</ion-button>
          </ion-col>
          <ion-col size-xs="12" size-sm="6" size-md="3" size-lg="2">
            <ion-button expand="block" :disabled="cargando" @click="seleccionarTodos">Sel. Todos</ion-button>
          </ion-col>
        </ion-row>
        <ion-item lines="none" vv-if="acuerdosIncluidos.length>0">
          <ion-segment :disabled="acuerdosIncluidos.length==0" color="light" :value="modover" v-on:ionChange="modover=$event.detail.value">
            <ion-segment-button :value="'incluir'">Incluir</ion-segment-button>
            <ion-segment-button :value="'incluidos'">Incluidos</ion-segment-button>
          </ion-segment>
        </ion-item>
        <ion-list v-if="modover=='incluir'">
          <ion-item v-for="a in filtrarAcuerdos()" :key="a._id">
            <ion-checkbox slot="start" :checked="acuerdosIncluidos.indexOf(a._id)>=0" v-on:ionChange="incluirAcuerdo(a,$event.detail.checked==true)" />
            <ion-label text-wrap>
              <h2>{{a.nombre}}</h2>
              <h2>{{(a.cliente||{}).nombre}}</h2>
              <p>Desde {{a.desdeFecha | moment('MMM YYYY')}}</p>
            </ion-label>
            <ion-note slot="end" class="ion-text-capitalize">{{a.tipo}}, {{a.asuntos.length}} asunto{{a.asuntos.length>1?'s':''}}</ion-note>
          </ion-item>
        </ion-list>
        <ion-list v-if="modover=='incluidos'">
          <ion-item v-for="a in arbol.acuerdos" :key="a._id">
            <ion-checkbox slot="start" :checked="acuerdosIncluidos.indexOf(a._id)>=0" v-on:ionChange="incluirAcuerdo(a,$event.detail.checked==true)" />
            <ion-label text-wrap>
              <h2>{{a.nombre}}</h2>
              <h2>{{(a.cliente||{}).nombre}}</h2>
              <p>Desde {{a.desdeFecha | moment('MMM YYYY')}}</p>
            </ion-label>
            <ion-note slot="end" class="ion-text-capitalize">{{a.tipo}}, {{a.asuntos.length}} asunto{{a.asuntos.length>1?'s':''}}</ion-note>
          </ion-item>
        </ion-list>
      </ion-col>
      <ion-col v-if="pdf!=''" size="12" size-md="8" size-sm="7">
        <embed type="application/pdf" :src="pdf" class="pdf" />
      </ion-col>
    </ion-row>
    <!--ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('secondary')" @click="generar">
        <i class="icon ion-ios-document icon-lg"></i>
      </ion-fab-button>
    </ion-fab-->
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
const yo = Api.obtenerLocal('elite.yo') || {}
const empresa = Api.obtenerLocal('elite.empresa') || {}
import CobranzaModel from '@/models/Cobranza'
import Pdfgen from '@/models/Pdfgen'
export default {
  name: 'Acuerdos',
  data() {
    return {
      yo,
      empresa,
      areas: [],
      area: {},
      modover: 'incluir',
      modo: ['mes', 'todo'][0],
      mes: new Date().toISOString().substring(0, 7),
      acuerdos: [],
      acuerdosIncluidos: [],
      filtro: '',
      moneda: 'MXN',
      tipoCambio: 1,
      cargando: false,
      arbol: {
        acuerdos: [],
        actividades: [],
        actividadesTodas: [],
        total: {}
      },
      desglosarActividades: (yo.permisos || {}).CosteoActividades == true || yo.rol == 'administrador',
      pdf: ''
    }
  },
  mounted() {
    this.obtener()
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async obtener() {
      const subset = [{
        coleccion: 'cliente',
        alias: 'cliente',
        query: '_id,eq,',
        sustituir: 'idCliente',
        unico: true
      }]
      this.acuerdos = (await Api.get('acuerdocobranza', null, subset))
        .sort((a, b) => a.fecha > b.fecha ? 1 : -1)
      console.log("Acuerdos?", this.acuerdos.length)
    },
    filtrarAcuerdos() {
      const filtro = this.filtro || ''
      if (filtro.length < 3)
        return []
      else {
        return this.acuerdos.filter(a => {
          let strbusqueda = ''
          for (let prop of ['nombre', 'descripcion', 'folio', 'actor', 'demandado', 'juzgado']) {
            strbusqueda += (a[prop] || '')
            if (a.cliente)
              strbusqueda += (a.cliente[prop] || '')
          }
          return strbusqueda.toUpperCase().indexOf(filtro) >= 0
        })
      }
    },
    async seleccionarTodos() {
      this.cargando = true
      for (let a of this.acuerdos)
        await this.incluirAcuerdo(a, true)
      this.cargando = false
      this.generar()
      this.$forceUpdate()
      alert("Seleccionados todos")
    },
    async incluirAcuerdo(acuerdo, toggle = false) {
      this.cargando = true
      if (toggle == true) {
        this.acuerdosIncluidos.push(acuerdo._id)
        if (!acuerdo.clientes) {
          acuerdo.clientes = [{
            nombre: acuerdo.actor || '(Sin cliente)'
          }]
          console.log("Cliente faltante", acuerdo.clientes.map(c => c.nombre))
        }
        this.arbol.acuerdos.push(acuerdo)
      } else {
        this.acuerdosIncluidos = this.acuerdosIncluidos.filter(a => a != acuerdo._id)
        this.arbol.acuerdos = this.arbol.acuerdos.filter(a => a._id != acuerdo._id)
      }
      this.cargando = false
      this.$forceUpdate()
      console.log("incluirAcuerdo?", toggle, acuerdo.nombre, this.acuerdosIncluidos)
      return true
    },
    async generar() {
      alert("Generando")
      this.cargando = true
      const mes = this.mes
      const modo = this.modo
      this.arbol.total = {
        tiempo: 0,
        tiempoRepercutido: 0,
        costo: 0,
        cobranza: 0
      }
      const styles = {
        header: {
          fontSize: 14,
          bold: true
        },
        subheader: {
          fontSize: 11,
          bold: true
        },
        quote: {
          italics: true
        },
        small: {
          fontSize: 8
        },
        right: {
          alignment: 'right'
        },
        center: {
          alignment: 'center'
        }
      }
      const defaultStyle = {
        fontSize: 10
      }
      const moneda = this.moneda
      const tipoCambio = this.tipoCambio
      let objeto = {
        styles,
        defaultStyle,
        content: [{
          layout: 'noBorders',
          table: {
            widths: [70, '*'],
            body: [
              [{
                  image: this.empresa.logo,
                  width: 55
                },
                [{
                  text: (this.empresa.nombre).toUpperCase(),
                  style: 'header'
                }, {
                  text: 'Reporte de actividades',
                  style: 'subheader'
                }, {
                  text: 'Consulta: ' + this.modo + ' ' + (this.modo == 'mes' ?
                    this.$options.filters.moment(this.mes + '-01', 'MMM YYYY') : '').toUpperCase(),
                  style: 'subheader'
                }]
              ]
            ]
          }
        }]
      }
      // Acuerdos
      const tipos = ['iguala', 'asunto', 'hora', 'probono', 'gratuito']
      this.arbol.acuerdos = this.arbol.acuerdos
        .sort((a, b) => a.idCliente > b.idCliente ? 1 : -1)
        .sort((a, b) => a.desdeFecha > b.desdeFecha ? 1 : -1)
        // Ordenar por: iguala asunto, asunto por hora, probono y gratuito
        .sort((a, b) => tipos.indexOf(a.tipo) > tipos.indexOf(b.tipo) ? 1 : -1)
      //
      let widths = ['*', '*', 40, 60, 60]
      let tablaAcuerdos = {
        layout: 'lightHorizontalLines',
        table: {
          widths,
          body: [
            ['Nombre del acuerdo', 'Cliente', 'Tipo', 'Comienzo', {
              text: 'Importe',
              style: 'right'
            }]
          ]
        }
      }
      let renglonTotalAcuerdos = ['TOTAL ' + this.moneda, '', '', '', {
        text: 0,
        style: 'right'
      }]
      for (let acuerdo of this.arbol.acuerdos) {
        if (acuerdo.tipo == 'hora') {
          acuerdo.total = 0
          // Obtener la cobranza de cada asunto anexado y sumarlas para obtener su total
          for (let asuntoEnAcuerdoHora of acuerdo.asuntos) {
            let cobranza = await CobranzaModel.calcularParaAsunto(asuntoEnAcuerdoHora)
            console.log("Cobranza?", cobranza, acuerdo.nombre, asuntoEnAcuerdoHora)
            for (let act of cobranza.actividades) {
              let fechaNatural = new Date(act.fechaProgramada).toISOString().substring(0, 7)
              if (modo == 'todo' || fechaNatural == mes)
                acuerdo.total += parseFloat(act.monto)
            }
          }
        }
        let renglonAcuerdo = [
          acuerdo.nombre,
          //(acuerdo.cliente || {}).nombre || '',
          acuerdo.clientes.map(c => c.nombre).join(', '),
          acuerdo.tipo,
          acuerdo.desdeFecha,
          {
            text: this.$options.filters.currency(acuerdo.total || acuerdo.monto, ''),
            style: 'right'
          }
        ]
        tablaAcuerdos.table.body.push(renglonAcuerdo)
      }
      renglonTotalAcuerdos[4].text = this.arbol.acuerdos
        .map(a => parseFloat(a.total || a.monto || 0))
        .reduce((acum, obj) => acum + obj, 0)
      renglonTotalAcuerdos[4].text = this.$options.filters.currency(renglonTotalAcuerdos[4].text, '')
      tablaAcuerdos.table.body.push(renglonTotalAcuerdos)
      objeto.content.push(' ')
      objeto.content.push(' ')
      objeto.content.push({
        text: 'Resumen de acuerdos de cobranza',
        style: 'header'
      })
      objeto.content.push(' ')
      objeto.content.push(tablaAcuerdos)
      // Asuntos y actividades
      let asuntos = [],
        mapaAsuntos = [],
        indicesAcuerdos = {}
      for (let acuerdo of this.arbol.acuerdos) {
        indicesAcuerdos[acuerdo._id] = acuerdo
        for (let idAsunto of acuerdo.asuntos) {
          if (mapaAsuntos.indexOf(idAsunto) < 0) {
            mapaAsuntos.push(idAsunto)
            let asunto = await Api.get('asunto', idAsunto)
            asunto.tipoCobranza = acuerdo.tipo
            asuntos.push(asunto)
          }
        }
      }
      console.log("Asuntos incluidos?", asuntos.length)
      for (let indiceAsunto in asuntos) {
        let asunto = asuntos[indiceAsunto]
        if ((asunto.histogramaCobranza || []).length > 0)
          for (let h of asunto.histogramaCobranza) {
            asunto.acuerdo = h
            asunto.tipo = h.tipo
          }
        const idAsunto = asunto._id
        console.log("Procesando asunto", idAsunto, asunto.nombre)
        if (this.incluirMontos == true) {
          const guardar = true
          let cobranzaAsunto = await CobranzaModel.calcularParaAsunto(asunto, guardar)
          console.log("cobranzaAsunto?", asunto.area, asunto.nombre, cobranzaAsunto)
          await Api.wait(1000)
        } //*/
        let actividades = (await Api.find('actividad', ['idAsunto,eq,' + idAsunto], {
            subset: [{
              coleccion: 'usuario',
              alias: 'abogado',
              query: '_id,eq,',
              sustituir: 'idAutor',
              unico: true
            }]
          }))
          .map(a => {
            a.fechaNatural = new Date(a.fechaProgramada).toISOString()
            console.log("u", a.tipo, a.fechaNatural,
              a.precioUnitario, a.minutos, a.minutosCliente,
              a.monto, a.importe)
            return a
          })
          .sort((a, b) => a.descripcion < b.descripcion ? 1 : -1)
          .sort((a, b) => a.fechaNatural > b.fechaNatural ? 1 : -1)
        this.arbol.actividadesTodas = this.arbol.actividadesTodas.concat(actividades)
        actividades = actividades
          .filter(a => a.fechaNatural.startsWith(mes) || modo == 'todo')
        asuntos[indiceAsunto].actividades = actividades
        // Caso especial: Si el asunto no tiene actividades y tiene igualas dentro, hay que arrojar una pseudoactividad
        for (let h of asunto.histogramaCobranza || []) {
          if (h.tipo == 'iguala' && actividades.length == 0 && this.modo == 'mes') {
            actividades = [{
              tipo: 'iguala',
              monto: h.monto,
              importe: h.monto,
              precioUnitario: h.monto,
              nombre: 'Actividades del mes',
              descripcion: 'Actividades del mes',
              abogado: {
                nombre: this.empresa.nombre,
                iniciales: '-'
              },
              fechaProgramada: this.mes + '-01',
              fechaNatural: this.mes + '-01'
            }]
          }
        }
        for (let a of actividades) {
          this.arbol.total.tiempo += parseFloat(a.minutos || 0)
          this.arbol.total.tiempoRepercutido += parseFloat(a.minutosCliente || a.minutos)
          this.arbol.total.costo += parseFloat(a.minutos * a.precioUnitario)
          this.arbol.total.cobranza += parseFloat((a.minutosCliente || a.minutos) * (a.precioUnitario || 0))
          console.log("Total?", a.tipo, this.arbol.total.tiempoRepercutido, this.arbol.total.cobranza)
        }

      }
      this.arbol.asuntos = asuntos
      console.log("Asuntos?", asuntos)
      let tablaAsuntos = {
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*', 70],
          body: [
            [
              'Acuerdo', 'Asunto', {
                text: 'Fecha inicio',
                style: 'right'
              }
            ]
          ]
        }
      }
      for (let asunto of this.arbol.asuntos) {
        let renglonAsunto = [
          (asunto.histogramaCobranza || []).splice(0, 1).map(a => a.nombre + ' ' + (a.folio || '') + ' ' + (a.juzgado || '')),
          asunto.nombre + ' (' + (asunto.folio || '') + ' ' + (asunto.juzgado || '') + ')',
          {
            text: this.$options.filters.moment(asunto.fecha, 'YYYY-MM-DD'),
            style: 'right'
          }
        ]
        tablaAsuntos.table.body.push(renglonAsunto)
      }
      objeto.content.push(' ')
      objeto.content.push(' ')
      objeto.content.push({
        text: 'Detalle de asuntos',
        style: 'header'
      })
      objeto.content.push(' ')
      objeto.content.push(tablaAsuntos)
      // Asuntos sin cobranza de los clientes involucrados
      let asuntosSinCobranzaIndices = [],
        asuntosSinCobranza = [],
        asuntosTodos = [],
        asuntosConCobranza = []
      let clientesInvolucrados = []
      for (let acuerdo of this.arbol.acuerdos)
        clientesInvolucrados = clientesInvolucrados.concat((acuerdo.clientes || []).map(c => c._id))
      clientesInvolucrados = [...new Set(clientesInvolucrados)]
      console.log("clientesInvolucrados?", clientesInvolucrados)
      for (let idCliente of clientesInvolucrados)
        asuntosTodos = asuntosTodos.concat(await Api.find('asunto', ['idCliente,eq,' + idCliente]))
      asuntosTodos = [...new Set(asuntosTodos.map(a => a._id))]
      console.log("asuntosTodos?", asuntosTodos)
      for (let acuerdo of this.acuerdosIncluidos) {
        let ac = await Api.get('acuerdocobranza', acuerdo)
        asuntosConCobranza = asuntosConCobranza.concat(ac.asuntos)
      }
      asuntosConCobranza = [...new Set(asuntosConCobranza)]
      console.log("asuntosConCobranza?", asuntosConCobranza)
      for (let a of asuntosTodos)
        if (asuntosConCobranza.indexOf(a) < 0)
          asuntosSinCobranzaIndices.push(a)
      console.log("asuntosSinCobranzaIndices?", asuntosSinCobranzaIndices)
      for (let a of asuntosSinCobranzaIndices) {
        let asunto = await Api.get('asunto', a)
        asunto.actividades = await Api.find('actividad', ['idAsunto,eq,' + asunto._id])
        if (!asunto.cliente && asunto.idCliente)
          asunto.cliente = await Api.get('cliente', asunto.idCliente)
        asunto.clientes = [asunto.cliente]
        asuntosSinCobranza = asuntosSinCobranza.concat(asunto)
      }
      console.log("asuntosSinCobranza", asuntosSinCobranza)
      if (asuntosSinCobranza.length > 0) {
        let tablaAsuntosSinCobranza = {
          layout: 'lightHorizontalLines',
          table: {
            widths: ['*', '*', 60, 60],
            body: [
              ['Asunto', 'Cliente', 'Fecha', {
                text: 'Actividades',
                style: 'right'
              }]
            ]
          }
        }
        for (let asunto of asuntosSinCobranza) {
          let clientes = (asunto.clientes || []).map(c => c.nombre).join(', ')
          if (clientes.trim().length == 0 && asunto.actor)
            clientes = asunto.actor
          tablaAsuntosSinCobranza.table.body.push([
            asunto.nombre + ' ' + (asunto.juzgado || '') + ' ' + (asunto.expediente || ''),
            clientes,
            this.$options.filters.moment(asunto.fecha, 'MMM DD, YYYY').toUpperCase(),
            {
              text: (asunto.actividades || []).length,
              style: 'right'
            }
          ])
        }
        objeto.content.push(' ')
        objeto.content.push({
          text: 'Asuntos sin acuerdo de cobranza',
          style: 'header'
        })
        objeto.content.push(tablaAsuntosSinCobranza)
      }
      // Desglose de actividades
      if (this.desglosarActividades == true) {
        for (let asunto of this.arbol.asuntos)
          if ((asunto.actividades || []).length > 1) {
            let widths = (asunto.acuerdo || {}).tipo == 'hora' ? [60, 45, '*', 55, 55] : [60, 45, '*', 55]
            let tableHeader = ['Fecha', 'Abogado', 'Descripción', {
              text: 'Mins',
              style: 'right'
            }]
            if (asunto.tipo == 'hora')
              tableHeader.push({
                text: 'Importe',
                style: 'right'
              })
            let tablaActividades = {
              layout: 'lightHorizontalLines',
              table: {
                widths,
                body: [tableHeader]
              }
            }
            let renglonTotales = [{
              text: '',
              border: [false, true, false, false]
            }, {
              text: '',
              border: [false, true, false, false]
            }, {
              text: 'TOTAL ' + (moneda != 'MXN' ? moneda : ''),
              border: [false, true, false, false]
            }, {
              text: 0, // Minutos
              border: [false, true, false, false],
              style: 'right'
            }]
            if (asunto.tipo == 'hora')
              renglonTotales.push({
                text: 0, // Importe
                border: [false, true, false, false],
                style: 'right'
              })
            for (let a of asunto.actividades) {
              let renglonActividad = [{
                text: a.fechaNatural.substring(0, 10) + ' ' + a.tipo.substring(0, 1)
              }, {
                text: (a.abogado || {}).iniciales || (a.abogado || {}).nombre.split(' ')[0] || ''
              }, {
                text: a.descripcion || a.nombre || ''
              }, {
                text: a.minutosCliente || a.minutos || 0,
                style: 'right'
              }]
              if (asunto.tipo == 'hora')
                renglonActividad.push({
                  text: this.$options.filters.currency((a.monto || 0) / tipoCambio, ''),
                  style: 'right'
                })
              renglonTotales[3].text += parseFloat(a.minutosCliente || a.minutos || 0)
              if (renglonTotales[4])
                renglonTotales[4].text += parseFloat((a.monto || 0) / tipoCambio)
              tablaActividades.table.body.push(renglonActividad)
            }
            if (renglonTotales[4])
              renglonTotales[4].text = this.$options.filters.currency(renglonTotales[4].text, '')
            tablaActividades.table.body.push(renglonTotales)
            objeto.content.push({
              text: ' ',
              pageBreak: 'before'
            })
            objeto.content.push(' ')
            objeto.content.push({
              text: 'Desglose de actividades de asunto',
              style: 'subheader'
            })
            objeto.content.push({
              text: asunto.nombre,
              style: 'header'
            })
            if (asunto.folio || asunto.juzgado)
              objeto.content.push({
                text: 'Folio: ' + asunto.folio + ' ' + asunto.juzgado,
                style: 'subheader'
              })
            if ((asunto.actor || '') != '')
              objeto.content.push({
                text: 'Actor: ' + asunto.actor,
                style: 'subheader'
              })
            if ((asunto.demandado || '') != '')
              objeto.content.push({
                text: 'Demandado: ' + asunto.demandado,
                style: 'subheader'
              })
            objeto.content.push(' ')
            objeto.content.push(tablaActividades)
          }
      }
      // PDF
      this.pdf = await Pdfgen.generarDesdeObjeto(objeto)
      this.cargando = false
    }
  }
}
</script>
