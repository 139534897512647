<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Mis ventas</ion-title>
      <ion-buttons slot="end">
        <!--ion-button @click="obtener">
          <i class="icon ion-ios-refresh"></i>
        </ion-button-->
        <ion-button href="#/proveedor/subirsingasto" fill="outline">
          <i class="icon ion-ios-cloud-upload"></i>&nbsp;Subir factura
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" v-if="cargando"></ion-progress-bar>
  </ion-header>
  <ion-content>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item-divider>En proceso</ion-item-divider>
          <ion-item v-for="v in ventasEnProceso" :key="v._id" @click="verVenta(v)" :color="venta._id==v._id?'medium':''">
            <ion-label text-wrap>
              <h2>{{(v.metadatos||{}).folio}} {{v.nombre}}
              </h2>
              <p>Subida el {{v.fecha | moment('MMM DD')}}</p>
              <p>{{v.cantidadCotizada | currency}}</p>
              <p :color="colors('danger')" v-if="faltaComplementoDePago(v)">
                Falta complemento de pago
              </p>
              <p v-if="v.fechaProgramado && !deberiaHaberSidoPagada(v.fechaProgramado)">Programada para {{v.fechaProgramado | moment('MMM DD')}}</p>
            </ion-label>
            <ion-note slot="end" :color="colors('danger')" v-if="faltaComplementoDePago(v)">
              <i class="icon ion-ios-information-circle-outline icon-lg"></i>
            </ion-note>
            <ion-note slot="end" :color="colors('primary')" v-if="liquidado(v) && !faltaComplementoDePago(v)">
              <i class="icon ion-ios-checkmark-circle-outline icon-lg"></i>
            </ion-note>
            <ion-note slot="end" v-if="!liquidado(v) && v.fechaProgramado" color="medium">
              <i class="icon ion-ios-checkmark-circle-outline icon-lg"></i>
            </ion-note>
          </ion-item>
        </ion-list>
        <ion-list>
          <ion-item-divider>Pagadas</ion-item-divider>
          <ion-item v-for="v in ventasPagadas" :key="v._id" @click="verVenta(v)" :color="venta._id==v._id?'medium':''">
            <ion-label text-wrap>
              <h2>{{(v.metadatos||{}).folio}} {{v.nombre}}
              </h2>
              <p>Subida el {{v.fecha | moment('MMM DD')}}</p>
              <p>{{v.cantidadCotizada | currency}}</p>
              <p color="danger" v-if="faltaComplementoDePago(v)">
                Falta complemento de pago
              </p>
              <p v-if="v.fechaProgramado && !deberiaHaberSidoPagada(v.fechaProgramado)">Programada para {{v.fechaProgramado | moment('MMM DD')}}</p>
            </ion-label>
            <ion-note slot="end" color="danger" v-if="faltaComplementoDePago(v)">
              <i class="icon ion-ios-information-circle-outline icon-lg"></i>
            </ion-note>
            <ion-note slot="end" color="primary" v-if="liquidado(v) && !faltaComplementoDePago(v)">
              <i class="icon ion-ios-checkmark-circle-outline icon-lg"></i>
            </ion-note>
            <ion-note slot="end" v-if="!liquidado(v) && v.fechaProgramado" color="medium">
              <i class="icon ion-ios-checkmark-circle-outline icon-lg"></i>
            </ion-note>
          </ion-item>
        </ion-list>
        <ion-list id="facturasPorAprobar">
          <ion-item-divider>Por aprobar</ion-item-divider>
          <ion-item-sliding v-for="f in facturassingasto" :key="f._id">
            <ion-item @click="verVenta(f)" :color="f._id==venta._id?'light':''">
              <ion-label text-wrap>
                <h2>Folio {{f.metadatos.folio}}</h2>
                <p>{{f.metadatos.concepto}}</p>
                <p>Subida el {{f.fecha | moment('MMM DD, HH:mm')}}</p>
                <p>{{f.metadatos.cantidad | currency}}</p>
              </ion-label>
            </ion-item>
            <ion-item-options slot="end">
              <ion-item-option color="danger" @click="cancelarFacturaSinGasto(f)">
                <i class="icon ion-ios-trash"></i>&nbsp;Cancelar
              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </ion-list>
      </ion-col>
      <ion-col v-if="venta && venta._id" size-xs="12" size-sm="6" size-md="8">
        <ion-list>
          <ion-item-divider>Venta</ion-item-divider>
          <!--pre class="ion-text-left">{{venta}}</pre-->
          <ion-item>
            <ion-label>
              <h2>{{(venta.metadatos||{}).folio}} {{venta.nombre || (venta.metadatos||'').folio}}</h2>
              <p>{{(venta.metadatos||'').concepto}}</p>
              <p>Subido el {{venta.fecha | moment('MMM DD')}}</p>
              <p v-if="venta.fechaProgramado">Fecha de pago: {{venta.fechaProgramado | moment('MMM DD')}}</p>
            </ion-label>
            <!--ion-label text-wrap>{{venta}}</ion-label-->
            <ion-note slot="end">{{(venta.cantidadCotizada || venta.metadatos.cantidad) | currency}}</ion-note>
          </ion-item>
          <ion-card v-if="venta.sinGasto">
            <ion-list>
              <!--ion-item-divider>Archivos subidos</ion-item-divider-->
              <ion-item v-if="venta.archivoAdjuntoComprobante && venta.archivoAdjuntoComprobante.length>0" :href="cdn+venta.archivoAdjuntoComprobante" target="_blank">
                <ion-note slot="start" color="medium">
                  <i class="icon ion-ios-document"></i>
                </ion-note>
                <ion-label>
                  <h2>COMPROBANTE de pago</h2>
                  <p>{{venta.archivoAdjuntoComprobante}}</p>
                </ion-label>
              </ion-item>
              <ion-item v-if="venta.archivoAdjuntoJustificacion && venta.archivoAdjuntoJustificacion.length>0" :href="cdn+venta.archivoAdjuntoJustificacion" target="_blank">
                <ion-note slot="start" color="medium">
                  <i class="icon ion-ios-document"></i>
                </ion-note>
                <ion-label>
                  <h2>Justificación del gasto</h2>
                  <p>{{venta.archivoAdjuntoJustificacion}}</p>
                </ion-label>
              </ion-item>
              <ion-item v-if="(venta.archivoAdjunto||'').length>0" :href="cdn+venta.archivoAdjunto" target="_blank">
                <ion-note slot="start" color="medium">
                  <i class="icon ion-ios-document"></i>
                </ion-note>
                <ion-label>
                  <h2>Factura o comprobante</h2>
                  <p>{{venta.archivoAdjunto}}</p>
                </ion-label>
              </ion-item>
              <ion-item v-if="(venta.archivoAdjuntoXml||'').length>0" :href="cdn+venta.archivoAdjuntoXml" target="_blank">
                <ion-note slot="start" color="medium">
                  <i class="icon ion-ios-document"></i>
                </ion-note>
                <ion-label>
                  <h2>CFDI en XML</h2>
                  <p>{{venta.archivoAdjuntoXml}}</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card>
          <ion-card v-for="(e,i) in venta.etapas" :key="i">
            <ion-list>
              <ion-item>
                <ion-note slot="start" :color="colors('primary')">
                  <i class="icon ion-ios-checkmark-circle icon-lg" v-if="e.fechaTerminada && e.fechaTerminada>0"></i>
                  <i class="icon ion-ios-information-circle-outline icon-lg" v-else></i>
                </ion-note>
                <ion-label text-wrap>
                  <h2>{{e.nombre}}</h2>
                  <p>Creado el {{e.fecha | moment('MMM DD')}}</p>
                  <p v-if="e.fechaSocioAprueba">Aprobada a pago el {{e.fechaSocioAprueba | moment('MMM DD')}}</p>
                  <p v-if="e.fechaTerminada && e.archivoAdjuntoPagado">Pagada {{e.fechaTerminada | moment('MMM DD')}}</p>
                </ion-label>
                <ion-button slot="end" v-if="e.archivoAdjuntoPagado" :href="cdn+e.archivoAdjuntoPagado" target="_blank" color="medium">
                  <i class="icon ion-ios-cloud-download"></i> &nbsp;Comprobante
                </ion-button>
                <ion-note slot="end">
                  {{e.cantidadErogable | currency}}
                </ion-note>
              </ion-item>
              <!--ion-item-sliding-->
              <ion-row>
                <ion-col class="ion-no-padding">
                  <ion-item>
                    <ion-note slot="start" :color="colors('primary')">
                      <i class="icon icon-lg ion-ios-document"></i>
                    </ion-note>
                    <label :for="'archivoAdjunto'+e._id">
                      <input type="file" class="hidden" :name="'archivoAdjunto'+e._id" :id="'archivoAdjunto'+e._id" v-on:change="adjuntar(e._id,i)" />
                      <ion-label v-if="e.archivoAdjunto" text-wrap>
                        <h2>Factura/PDF</h2>
                        <p>{{e.archivoAdjunto}}</p>
                        <p v-if="e.archivoAdjuntoFecha">Subido el {{e.archivoAdjuntoFecha | moment('MMM DD')}}</p>
                      </ion-label>
                      <ion-label :color="colors('primary')" v-else>
                        <h2>Clic para subir su factura o PDF</h2>
                        <p>Requerida</p>
                      </ion-label>
                    </label>
                    <ion-button slot="end" :color="colors('primary')" fill="clear" v-if="e.archivoAdjunto" :href="cdn+'/'+e.archivoAdjunto" target="_blank">
                      <i class="icon icon-lg ion-ios-cloud-download"></i>
                    </ion-button>
                  </ion-item>
                </ion-col>
                <ion-col class="ion-no-padding">
                  <ion-item>
                    <ion-note slot="start" :color="colors('primary')">
                      <i class="icon icon-lg ion-ios-code-working"></i>
                    </ion-note>
                    <label :for="'archivoAdjuntoXml'+e._id">
                      <input type="file" class="hidden" :name="'archivoAdjuntoXml'+e._id" :id="'archivoAdjuntoXml'+e._id" v-on:change="adjuntarXml(e._id,i)" />
                      <ion-label v-if="e.archivoAdjuntoXml" text-wrap>
                        <h2>XML</h2>
                        <p>{{e.archivoAdjuntoXml}}</p>
                        <p v-if="e.archivoAdjuntoXmlFecha">Subido el {{e.archivoAdjuntoXmlFecha | moment('MMM DD')}}</p>
                      </ion-label>
                      <ion-label :color="colors('secondary')" v-else>
                        <h2>Clic para subir su XML</h2>
                        <p>Opcional</p>
                      </ion-label>
                    </label>
                    <ion-button slot="end" :color="colors('primary')" fill="clear" v-if="e.archivoAdjuntoXml" :href="cdn+'/'+e.archivoAdjuntoXml" target="_blank">
                      <i class="icon icon-lg ion-ios-cloud-download"></i>
                    </ion-button>
                  </ion-item>
                </ion-col>
                <!-- Complemento de pago  -->
                <ion-col class="ion-no-padding" v-if="e.fechaTerminada && e.fechaTerminada>0">
                  <ion-item>
                    <ion-note slot="start" :color="colors('primary')">
                      <i class="icon icon-lg ion-ios-code-working"></i>
                    </ion-note>
                    <label :for="'complemento'+e._id">
                      <input type="file" class="hidden" :name="'complemento'+e._id" :id="'complemento'+e._id" v-on:change="adjuntarComplemento(e._id,i)" />
                      <ion-label v-if="e.complemento" text-wrap>
                        <h2>XML complemento de pago</h2>
                        <p>{{e.complemento}}</p>
                        <p v-if="e.complementoFecha">Subido el {{e.complementoFecha | moment('MMM DD')}}</p>
                      </ion-label>
                      <ion-label :color="colors('secondary')" v-else>
                        <h2>Clic para subir su XML de complemento</h2>
                      </ion-label>
                    </label>
                    <ion-button slot="end" :color="colors('primary')" fill="clear" v-if="e.complemento" :href="cdn+'/'+e.complemento" target="_blank">
                      <i class="icon icon-lg ion-ios-cloud-download"></i>
                    </ion-button>
                  </ion-item>
                </ion-col>
              </ion-row>
              <!--ion-item-options slot="end" v-if="e.archivoAdjunto && e.archivoAdjunto.length>0">
                  <ion-item-option :color="colors('danger')" @click="eliminarAdjunto(e,i)"><i class="icon ion-ios-trash icon-lg"></i></ion-item-option>
                </ion-item-options>
              </ion-item-sliding-->
            </ion-list>
          </ion-card>
        </ion-list>
      </ion-col>
    </ion-row>
    <!--ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('danger')" href="#/proveedores/subirsingasto">
        <i class="icon ion-ios-cloud-upload icon-lg"></i>
      </ion-fab-button>
    </ion-fab-->
  </ion-content>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
import Emailer from '@/models/Emailer'
import Xml from '@/models/Xml'
import FechasPagoModel from '@/models/FechasPago'
import axios from 'axios'
export default {
  name: 'ProveedoresMisVentas',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      ventas: [],
      ventasEnProceso: [],
      ventasPagadas: [],
      venta: {},
      facturassingasto: [],
      cargando: false,
      modoProduccion: Api.modoProduccion,
      cdn: Api.cdn + '/',
      enviarSoloSiPdfYXmlFueronSubidos: true,
      calcularFechaDePagoSinXml: true
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async obtener() {
      this.cargando = true
      //let ventas = await Api.get('presupuestogasto')
      let ventas = (await Api.find('presupuestogasto', ['idProveedor,eq,' + this.yo._id]))
        .sort((a, b) => a.fecha < b.fecha ? 1 : -1)
      if (this.modoProduccion) ventas = ventas.filter(v => v.estado == 'aprobado')
      console.log("Obteniendo", ventas)
      for (let id in ventas) {
        ventas[id].etapas = await Api.find('gastoetapa', ['idGasto,eq,' + ventas[id]._id])
        if (ventas[id].etapas[0] && ventas[id].etapas[0].archivoAdjuntoComprobante)
          ventas[id].archivoAdjuntoComprobante = ventas[id].etapas[0].archivoAdjuntoComprobante
        try {
          const facturasingasto = await Api.get('facturasingasto', ventas[id]._id)
          console.log("facturasingasto", ventas[id].facturasingasto)
          for (let prop in facturasingasto)
            ventas[id][prop] = facturasingasto[prop]
        } catch (e) {
          console.error("EFACTURASINGASTO", id, ventas[id].nombre, e)
        }
      }
      this.ventas = ventas
      //if(ventas[0]) ventas[0].fechaProgramado = new Date().getTime()
      //
      const etapasw = await Api.get('gastoetapa')
      this.facturassingasto = (await Api.find('facturasingasto', ['idProveedor,eq,' + this.yo._id]))
        .sort((a, b) => a.fecha < b.fecha ? 1 : -1)
        .map(e => {
          e.etapas = etapasw.filter(u => u._id == e._id)
          e.tienePago = (e.etapas[0] && e.etapas[0].archivoAdjuntoComprobante)
          return e
        })
        .filter(f => !(f.cancelada && f.cancelada != ''))
        .filter(f => !f.tienePago)
        // Si tiene etapas, es que ya fue aprobada
        .filter(e => (e.etapas || []).length == 0)
        .map(u => {
          if (!u.metadatos)
            u.metadatos = {
              folio: '- Sin folio - ',
              cantidad: 0,
              concepto: '- Sin concepto -'
            }
          return u
        })
      //
      this.cargando = false
      let vm = this
      this.ventasEnProceso = this.ventas
        .filter(u => {
          return !(u.archivoAdjuntoComprobante)
        })
      this.ventasPagadas = this.ventas
        .filter(u => {
          return (u.archivoAdjuntoComprobante)
        })
      this.$forceUpdate()
    },
    verVenta(v) {
      //v.etapas = []
      v.sinGasto = true
      this.venta = v
      console.log("Venta?", v)
    },
    faltaComplementoDePago(venta) {
      let faltaComplemento = false
      for (let etapa of venta.etapas || [])
        if (etapa.archivoAdjuntoXml &&
          etapa.fechaTerminada &&
          etapa.fechaTerminada > 0 &&
          !etapa.complemento)
          faltaComplemento = true
      return faltaComplemento
    },
    liquidado(v) {
      let liq = true
      if ((v.etapas || []).length == 0) {
        liq = false
        console.log("liquidado?", v.nombre, v.etapas, liq)
      } else
        for (let etapa of v.etapas || []) {
          if (!(etapa.fechaTerminada && etapa.fechaTerminada > 0)) {
            liq = false
            console.log("liquidado?", v.nombre, etapa.nombre, v.etapas, liq)
          }
        }
      console.log("liquidado?", v.nombre, v.cantidadCotizada, liq, v.etapas)
      return liq
    },
    deberiaHaberSidoPagada(fecha) {
      fecha = new Date(fecha).getTime()
      let fechaActual = new Date().getTime()
      console.log("deberiaHaberSidoPagada?", fecha, fecha > fechaActual)
      return fecha > fechaActual
    },
    async adjuntar(id, indice) {
      this.venta.etapas[indice].archivoAdjunto = await Api.upload('archivoAdjunto' + id)
      this.venta.etapas[indice].archivoAdjuntoFecha = new Date().getTime()
      try {
        let destinatarios = []
        destinatarios.push(this.yo.email)
        let socioAprueba = await Api.get('usuario', this.venta.etapas[indice].idAutor)
        destinatarios.push(socioAprueba.email)
        let presupuesto = await Api.get('presupuesto', this.venta.idPresupuesto)
        let usuarioConPresupuestoAsignado = await Api.get('usuario', presupuesto.idUsuarioAsignado)
        destinatarios.push(usuarioConPresupuestoAsignado.email)
        destinatarios = [...new Set(destinatarios)]
        let usuariosContabilidad = (await Api.find('usuario', ['esquemaPermisos,eq,Contabilidad']))
        destinatarios = destinatarios.concat(usuariosContabilidad.map(u => u.email))
        for (let destinatario of destinatarios) {
          console.log("Enviando a", destinatario)
          if (this.enviarSoloSiPdfYXmlFueronSubidos &&
            this.venta.etapas[indice].archivoAdjunto &&
            this.venta.etapas[indice].archivoAdjuntoXml)
            await Emailer.enviar({
              titulo: 'Factura para procesar de ' + this.yo.nombre,
              destino: destinatario,
              cuerpo1: 'El proveedor ' + this.yo.nombre + ' ha subido una factura para procesar a pago,que ya está disponible para ser procesada por Contabilidad.',
              cuerpo2: this.venta.etapas[indice].nombre +
                ' (' + this.$options.filters.moment(this.venta.etapas[indice].fechaSocioAprueba, 'MMMM DD YYYY') +
                '), por ' + this.$options.filters.currency(this.venta.etapas[indice].cantidadErogable)
            })
        }
        //
        if (this.calcularFechaDePagoSinXml) try {
          let fechaDePago = await FechasPagoModel.encontrarFechaDePagoMasCercana(this.venta.etapas[indice].archivoAdjuntoFecha)
          console.log("fechaDePago", fechaDePago)
          alertLegacy("Fecha de pago tentativa: " + fechaDePago.fechaProgramado)
          this.venta.etapas[indice].pagoDias = fechaDePago.pagoDias
          this.venta.etapas[indice].fechaProgramado = fechaDePago.fechaProgramado
        } catch (e) {
          console.error(e)
        }
        //
        await Api.save('gastoetapa', this.venta.etapas[indice])
        alert("Factura subida. La app recargará.")
        await Api.wait(2500)
        window.location.reload()
      } catch (e) {
        console.error(e);
        alert("Deberás llamar por teléfono al despacho para avisar de tu factura")
      }
      console.log("archivoAdjunto?", this.venta.etapas[indice].archivoAdjunto)
      this.$forceUpdate()
    },
    async comprobarXml(archivoAdjuntoXml) {
      archivoAdjuntoXml = archivoAdjuntoXml || ''
      let xmlRaw
      if (archivoAdjuntoXml.length > 50)
        xmlRaw = archivoAdjuntoXml
      else
        xmlRaw = (await axios.get(Api.cdn + '/' + archivoAdjuntoXml)).data
      console.log("xmlRaw?", xmlRaw)
      let xmlConvertido = await Xml.aObjeto(xmlRaw)
      let permitirAdjuntarXml = true
      console.log("xmlConvertido?", xmlConvertido)
      let rfcOrigen = xmlConvertido['cfdi:Comprobante']['cfdi:Emisor']['@Rfc']
      let rfcDestino = xmlConvertido['cfdi:Comprobante']['cfdi:Receptor']['@Rfc']
      let rfcOrigenEsperado = this.yo.rfc
      let rfcDestinoEsperado = (Api.obtenerLocal('elite.empresa') || {}).rfc
      if (rfcOrigen != rfcOrigenEsperado)
        permitirAdjuntarXml = confirm('El RFC del proveedor del XML (' + rfcOrigen + ') es distinto al esperado (' + rfcOrigenEsperado + '). ¿Desea continuar?')
      if (rfcDestino != rfcDestinoEsperado)
        permitirAdjuntarXml = confirm('El RFC del receptor del XML (' + rfcDestino + ') es distinto al esperado (' + rfcDestinoEsperado + '). ¿Desea continuar?')
      if (permitirAdjuntarXml)
        return Promise.resolve(permitirAdjuntarXml)
      else
        return Promise.reject(permitirAdjuntarXml)
    },
    async adjuntarXml(id, indice) {
      let archivoAdjuntoXml = await Api.upload('archivoAdjuntoXml' + id)
      let xmlComprobable = await Api.upload('archivoAdjuntoXml' + id, 'binario')
      let permitirAdjuntarXml = true
      try {
        permitirAdjuntarXml = await this.comprobarXml(xmlComprobable)
        if (!permitirAdjuntarXml)
          throw new Error('XML rechazado por el usuario')
      } catch (e) {
        console.error(e)
        alert("No se pudo validar el XML")
      }
      if (permitirAdjuntarXml) {
        this.venta.etapas[indice].archivoAdjuntoXml = archivoAdjuntoXml
        this.venta.etapas[indice].archivoAdjuntoXmlFecha = new Date().getTime()
        this.venta.etapas[indice].aprobadaPorSocio = true
        try {
          let destinatarios = []
          destinatarios.push(this.yo.email)
          let socioAprueba = await Api.get('usuario', this.venta.etapas[indice].idAutor)
          destinatarios.push(socioAprueba.email)
          let presupuesto = await Api.get('presupuesto', this.venta.idPresupuesto)
          let usuarioConPresupuestoAsignado = await Api.get('usuario', presupuesto.idUsuarioAsignado)
          destinatarios.push(usuarioConPresupuestoAsignado.email)
          let usuariosContabilidad = (await Api.find('usuario', ['esquemaPermisos,eq,Contabilidad']))
          destinatarios = destinatarios.concat(usuariosContabilidad.map(u => u.email))
          destinatarios = [...new Set(destinatarios)]
          for (let destinatario of destinatarios) {
            console.log("Enviando a", destinatario)
            let correo = {
              titulo: 'CFDI para procesar de ' + this.yo.nombre,
              destino: destinatario,
              cuerpo1: 'El proveedor ' + this.yo.nombre + ' ha subido el XML de una factura para procesar a pago, que ya está disponible para ser procesada por Contabilidad.',
              cuerpo2: this.venta.etapas[indice].nombre +
                ' (' + this.$options.filters.moment(this.venta.etapas[indice].fechaSocioAprueba, 'MMMM DD YYYY') +
                '), por ' + this.$options.filters.currency(this.venta.etapas[indice].cantidadErogable)
            }
            if (this.enviarSoloSiPdfYXmlFueronSubidos &&
              this.venta.etapas[indice].archivoAdjunto &&
              this.venta.etapas[indice].archivoAdjuntoXml)
              await Emailer.enviar(correo)
          }
          let fechaDePago = await FechasPagoModel.encontrarFechaDePagoMasCercana(this.venta.etapas[indice].archivoAdjuntoXmlFecha)
          console.log("fechaDePago", fechaDePago)
          alertLegacy("Fecha de pago tentativa: " + fechaDePago.fechaProgramado)
          this.venta.etapas[indice].pagoDias = fechaDePago.pagoDias
          this.venta.etapas[indice].fechaProgramado = fechaDePago.fechaProgramado
          await Api.save('gastoetapa', this.venta.etapas[indice])
          alert("XML subido. La app recargará.")
          await Api.wait(2500)
          window.location.reload()
        } catch (e) {
          console.error(e);
          alert("Deberás llamar por teléfono al despacho para avisar de tu factura")
        }
      }
      console.log("archivoAdjunto?", this.venta.etapas[indice].archivoAdjuntoXml)
      this.$forceUpdate()
    },
    async adjuntarComplemento(id, indice) {
      this.venta.etapas[indice].complemento = await Api.upload('complemento' + id)
      let xmlComprobable = await Api.upload('complemento' + id, 'binario')
      this.venta.etapas[indice].complementoFecha = new Date().getTime()
      this.$forceUpdate()
      try {
        let destinatarios = []
        destinatarios.push(this.yo.email)
        let socioAprueba = await Api.get('usuario', this.venta.etapas[indice].idAutor)
        destinatarios.push(socioAprueba.email)
        let presupuesto = await Api.get('presupuesto', this.venta.idPresupuesto)
        let usuarioConPresupuestoAsignado = await Api.get('usuario', presupuesto.idUsuarioAsignado)
        destinatarios.push(usuarioConPresupuestoAsignado.email)
        destinatarios = [...new Set(destinatarios)]
        for (let destinatario of destinatarios) {
          console.log("Enviando a", destinatario)
          await Emailer.enviar({
            titulo: 'Complemento de pago recibido, de ' + this.yo.nombre,
            destino: destinatario,
            cuerpo1: 'El proveedor ' + this.yo.nombre + ' ha subido el XML de un Complemento de Pago.',
            cuerpo2: this.venta.etapas[indice].nombre +
              ' (' + this.$options.filters.moment(this.venta.etapas[indice].fechaSocioAprueba, 'MMMM DD YYYY') +
              '), por ' + this.$options.filters.currency(this.venta.etapas[indice].cantidadErogable)
          })
        }
        await Api.save('gastoetapa', this.venta.etapas[indice])
        alert("XML subido. Puede confirmar en su correo.")
      } catch (e) {
        console.error(e);
        alert("Correo no enviado. Deberás llamar por teléfono al despacho para avisar de tu factura")
      }
      console.log("archivoAdjunto?", this.venta.etapas[indice].archivoAdjuntoXml)
      this.$forceUpdate()
    },
    async eliminarAdjunto(etapa, indice) {
      this.venta.etapas[indice].archivoAdjunto = null
      this.venta.etapas[indice].archivoAdjuntoFecha = null
      await Api.save('gastoetapa', this.venta.etapas[indice])
      alert("Adjunto eliminado")
      this.obtener()
    },
    async cancelarFacturaSinGasto(f) {
      if (true == confirm('¿Desea cancelar el proceso de esta factura?\nPuede volver a subir su factura posteriormente')) {
        f.cancelada = new Date().getTime()
        f.canceladaPorUsuario = true
        await Api.save('facturasingasto', f)
        alert("Factura eliminada")
        await Api.wait(2000)
        this.obtener()
      }
      try {
        document.querySelector('#facturasPorAprobar').closeSlidingItems()
      } catch (e) {
        console.error(e)
      }
    }
  },
  mounted() {
    console.log("ProveedoresMisVentas mounted")
    this.obtener()
  }
}
</script>
