<template>
<ion-app main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-buttons slot="end">
        <ion-button>
          <ion-icon name="more" :color="colors('secondary')"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Inicio</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content" padding :color="colors('light')">
    <ion-row>
      <ion-col></ion-col>
      <ion-col size="6">
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <img :src="branding('logo2')" alt="logotipo" />
      </ion-col>
      <ion-col></ion-col>
    </ion-row>
  </ion-content>
</ion-app>
</template>
<style scoped>
.content {
  /*background-image: url('http://alherrera.tk/demos/elite.vue/dist/public/fondo_azul2.png');*/
  background-position: center;
}
</style>
<script>
import Api from '@/components/Api'
export default {
  name: 'App',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo'),
      modoProduccion: Api.modoProduccion
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    pos() {
      this.$router.push('pos')
    }
  },
  mounted() {
    console.log("Yo?", this.yo)
    if (!(this.yo && this.yo._id))
      console.warn("No has iniciado sesión")
    setTimeout(this.openEnd, 1000)
  }
}
</script>
