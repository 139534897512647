<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('danger')">
      <ion-title>Lista de cobranza {{totalGeneral | currency}} / {{totalSugerido | currency}}</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button @click="obtener">
          <i class="icon ion-ios-search"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-row>
      <ion-col no-padding size="6">
        <ion-item>
          <ion-label position="stacked">Desde fecha</ion-label>
          <ion-input debounce="1000" type="date" :value="fechaInicio | moment('YYYY-MM-DD')" v-on:ionChange="setFecha('fechaInicio',$event.detail.value)"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col no-padding size="6">
        <ion-item>
          <ion-label position="stacked">Hasta fecha</ion-label>
          <ion-input debounce="1000" type="date" :value="fechaFin | moment('YYYY-MM-DD')" v-on:ionChange="setFecha('fechaFin',$event.detail.value)"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col :size="columnSize(1)" no-padding>
        <ion-list>
          <ion-item @click="seleccionarCliente(null)" lines="none" :color="colors('light')">
            <ion-label>
              <h2>Modo</h2>
            </ion-label>
            <ion-select :value="modoCliente" v-on:ionChange="switchModoCliente($event.detail.value)" debounce="500">
              <ion-select-option>Clientes</ion-select-option>
              <ion-select-option>Corporativos</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item @click="seleccionarTodosClientes">
            <ion-note slot="start" :color="colors('danger')">
              <i class="icon ion-ios-list icon-lg"></i>
            </ion-note>
            <ion-label>
              <h2>TODOS los clientes</h2>
            </ion-label>
          </ion-item>
          <ion-item v-for="(cliente,idCliente) in arbol" :key="idCliente" :color="cliente._id==(clienteSeleccionado||{})._id?'light':''">
            <ion-note slot="start" :color="colors('warning')" v-if="cliente.total<cliente.totalSugerido">
              <i class="icon ion-ios-arrow-down icon-lg"></i>
            </ion-note>
            <ion-note slot="start" :color="colors('warning')" v-if="cliente.total>cliente.totalSugerido">
              <i class="icon ion-ios-arrow-up icon-lg"></i>
            </ion-note>
            <ion-note slot="start" :color="colors('warning')" v-if="cliente.total==cliente.totalSugerido">
              <i class="icon ion-ios-checkmark-circle icon-lg"></i>
            </ion-note>
            <ion-label @click="seleccionarCliente(cliente)" text-wrap>
              <h2>{{cliente.nombre}}</h2>
              <p>Earnings:{{cliente.total | currency}}/{{cliente.totalSugerido | currency}}</p>
            </ion-label>
            <!--ion-checkbox slot="end"></ion-checkbox-->
          </ion-item>
        </ion-list>
      </ion-col>
      <ion-col v-if="clienteSeleccionado && clienteSeleccionado._id" :size="columnSize(2)" no-padding>
        <ion-item lines="none" :color="colors('light')">
          <ion-label text-wrap>
            <h2>Acuerdos de {{clienteSeleccionado.nombre}}</h2>
          </ion-label>
        </ion-item>
        <ion-list v-if="Object.keys(clienteSeleccionado.acuerdos||{}).length>0">
          <ion-item v-for="(acuerdo,indice) in clienteSeleccionado.acuerdos" :key="indice" :color="(acuerdoSeleccionado||{})._id==acuerdo._id?'light':''" @click="seleccionarAcuerdo(acuerdo)">
            <ion-note slot="start" :color="colors('warning')">
              <i class="icon ion-ios-information-circle icon-lg"></i>
            </ion-note>
            <ion-label text-wrap text-left>
              <h2>{{acuerdo.fecha | moment('YYYY-MM-DD')}} {{acuerdo.tipo}} <span v-if="['Rol','Abogado','Probono','Gratuito'].indexOf(acuerdo.tipo)==-1">por {{acuerdo.tarifa | currency}}</span></h2>
              <p v-if="acuerdo.fechaCaducidad<fechaFin">Acuerdo caducado</p>
              <p v-else>Caduca el {{acuerdo.fechaCaducidad | moment('YYYY-MM-DD')}} {{acuerdo._id.substring(19,24)}}</p>
            </ion-label>
            <ion-button slot="end" fill="clear" :color="colors('primary')">
              <i class="icon icon-lg ion-ios-arrow-forward"></i>
            </ion-button>
          </ion-item>
        </ion-list>
        <ion-item v-else>
          <ion-label :color="colors('danger')">Esta lista está vacía</ion-label>
        </ion-item>
      </ion-col>
      <ion-col v-if="clienteSeleccionado && clienteSeleccionado._id" :size="columnSize(3)" no-padding>
        <ion-list>
          <ion-item @click="seleccionarAsunto(null)" lines="none" :color="colors('light')">
            <ion-label>
              <h2>{{clienteSeleccionado.nombre}}</h2>
            </ion-label>
          </ion-item>
          <ion-item v-for="(asunto,indice) in clienteSeleccionado.asuntos" :key="indice" @click="seleccionarAsunto(asunto)" :color="(asuntoSeleccionado||{})._id==asunto._id?'light':''"
            v-if="((acuerdoSeleccionado||{}).idAsunto||[]).indexOf(asunto._id)>=0">
            <ion-note slot="start" :color="colors('warning')" v-if="asunto.total<asunto.totalSugerido">
              <i class="icon ion-ios-information-circle icon-lg"></i>
            </ion-note>
            <ion-label text-wrap text-left>
              <h2>{{asunto.nombre}}</h2>
              <p v-if="asunto.acuerdo && asunto.acuerdo.nombre">{{asunto.acuerdo.nombre}}</p>
              <p v-if="asunto.nombreCliente">{{asunto.nombreCliente}}</p>
              <p v-if="asunto.total">Earnings: {{asunto.total | currency}}/{{asunto.totalSugerido | currency}}</p>
              <p v-if="asunto.acuerdo && asunto.acuerdo._id">
                Acuerdo {{asunto.acuerdo.tipo}}
                <span v-if="['Abogado','Rol','Probono','Gratuito'].indexOf('asunto.acuerdo.tipo')==-1">{{asunto.acuerdo.tarifa | currency}}</span>
                <span v-if="asunto.terminado!=1 && asunto.acuerdo.tipo=='Asunto'">, sin cobrar por no estar terminado</span>
              </p>
            </ion-label>
            <ion-button slot="end" fill="clear" :color="colors('primary')" :href="'#/asunto/'+asunto._id">
              <i class="icon icon-lg ion-ios-arrow-forward"></i>
            </ion-button>
          </ion-item>
        </ion-list>
      </ion-col>
      <ion-col v-if="asuntoSeleccionado && asuntoSeleccionado._id" :size="columnSize(4)" no-padding>
        <ion-list>
          <ion-item @click="seleccionarAsunto(null)" lines="none" :color="colors('light')">
            <ion-label>
              <h2>Actividades del asunto</h2>
            </ion-label>
          </ion-item>
          <ion-item v-for="a in asuntoSeleccionado.actividades" :key="a._id" :color="a.cobrada?'light':''">
            <ion-checkbox slot="start" :checked="a.incluir" v-on:ionChange="toggleIncluirActividad(a.idCliente,a.idAsunto,a._id,$event.detail.checked==true)"></ion-checkbox>
            <ion-label text-wrap>
              <h2>{{a.descripcion}}</h2>
              <p>{{a.minutos | currency('')}}h, {{a.fechaProgramada | moment('MMM DD')}}</p>
            </ion-label>
            <ion-label slot="end" v-if="a.costoSugerido" :color="colors('secondary')">{{a.costoReal | currency}}/{{a.costoSugerido | currency}}</ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed" v-if="Object.keys(arbol).length>0">
      <ion-fab-button :color="colors('warning')" @click="generarPrefacturas">
        <i class="icon ion-ios-cloud-upload icon-lg"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
  <Empresa />
</div>
</template>

<script>
import Api from './Api'
import Cliente from '@/models/Cliente'
import Empresa from '@/models/Empresa'
import Usuario from '@/models/Usuario'
import Tarificador from '@/models/Tarificador'
export default {
  name: 'ListaCobranza',
  components: {
    Empresa
  },
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo'),
      fechaInicio: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime(),
      fechaFin: new Date().getTime(),
      arbol: {},
      todosLosAcuerdos: [],
      clienteSeleccionado: null,
      asuntoSeleccionado: null,
      acuerdoSeleccionado: null,
      validarSoloAsuntosConcretados: false,
      totalesPorCliente: {},
      totalGeneral: 0,
      abogados: [],
      roles: [],
      modoCliente: 'Clientes'
    }
  },
  filters: {
    quitarHash(str) {
      let separadores = str.split('.')
      let nuevosElem = separadores.slice(0, separadores.length - 1)
      return nuevosElem.join('.')
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    columnSize(columnNumber) {
      let hayAsuntoSeleccionado = (this.asuntoSeleccionado && this.asuntoSeleccionado._id)
      let hayClienteSeleccionado = (this.clienteSeleccionado && this.clienteSeleccionado._id)
      let hayAcuerdoSeleccionado = (this.acuerdoSeleccionado && this.acuerdoSeleccionado._id)
      let size
      switch (columnNumber) {
        case 1:
          if (hayAsuntoSeleccionado)
            size = 3
          else if (hayClienteSeleccionado)
            size = 4
          else
            size = 12
          break
        case 2:
          if (hayAsuntoSeleccionado)
            size = 3
          else if (hayClienteSeleccionado)
            size = 4
          else
            size = 6
          break
        case 3:
          if (hayAcuerdoSeleccionado)
            size = 3
          else
            size = 4
          break
        case 4:
          size = 3
          break
        default:
          size = 12
      }
      return size
    },
    seleccionarCliente(cl) {
      if (typeof cl == 'string')
        this.clienteSeleccionado = this.arbol[cl]
      else {
        this.clienteSeleccionado = cl
        this.asuntoSeleccionado = null
      }
      console.log("Seleccionado cliente", typeof cl, cl, this.asuntoSeleccionado, this.clienteSeleccionado)
    },
    seleccionarAsunto(a) {
      console.log("Seleccionado asunto", a)
      this.asuntoSeleccionado = a
    },
    seleccionarAcuerdo(a) {
      console.log("Seleccionado cacuerdo", a)
      this.acuerdoSeleccionado = a
      console.log("Seleccionando cliente?", a.idCliente)
      this.seleccionarCliente(a.idCliente)
      this.asuntoSeleccionado = null
    },
    switchModoCliente(val) {
      this.modoCliente = val
      this.$forceUpdate()
      console.log("switchModoCliente", val)
    },
    seleccionarTodosClientes() {
      this.clienteSeleccionado = {
        _id: 'ABCDEF',
        nombre: 'Asuntos de Todos los clientes',
        cliente: {
          nombre: 'Todos',
          _id: 'ABCDEF'
        },
        total: 0,
        totalSugerido: 0,
        totalGeneral: 0,
        asuntos: {},
        acuerdos: {},
        cobrarIgualasSinActividades: false
      }
      for (let cl in this.arbol) {
        console.log("Creando pseudocliente con", cl, this.arbol[cl])
        for (let asunto in this.arbol[cl].asuntos) {
          console.log("Insertando asunto", asunto)
          let asuntoMapeado = this.arbol[cl].asuntos[asunto]
          asuntoMapeado.nombreCliente = this.arbol[cl].nombre
          asuntoMapeado.total = 0
          asuntoMapeado.totalSugerido = 0
          this.clienteSeleccionado.asuntos[asuntoMapeado._id] = asuntoMapeado
        }
        for (let acuerdo of this.todosLosAcuerdos)
          this.clienteSeleccionado.acuerdos[acuerdo._id] = acuerdo
      }
      console.log("Viendo los asuntos de todos los clientes", this.clienteSeleccionado)
      this.arbol[this.clienteSeleccionado._id] = this.clienteSeleccionado
    },
    setFecha(campo, val) {
      let valNuevo = new Date(new Date(val).getTime() + (1000 * 60 * 60 * 6))
      valNuevo.setHours(0)
      valNuevo.setMinutes(0)
      valNuevo.setSeconds(0)
      valNuevo.setMilliseconds(0)
      this[campo] = valNuevo.getTime() + (1000 * 60 * 60 * 6)
      console.log("Configurado", campo, val, valNuevo.getDate())
      if ((valNuevo.getDate() == 1) && (campo == 'fechaInicio')) {
        let valNuevoFechaFin = new Date(new Date(val).getTime() + (1000 * 60 * 60 * 24 * 35))
        valNuevoFechaFin.setHours(0)
        valNuevoFechaFin.setMinutes(0)
        valNuevoFechaFin.setSeconds(0)
        valNuevoFechaFin.setMilliseconds(0)
        valNuevoFechaFin.setDate(0)
        this.fechaFin = valNuevoFechaFin.getTime() + (1000 * 60 * 60 * 6)
        console.log("Configurando fecha fin automatica para el mes", this.fechaFin)
      }
    },
    async obtener() {
      console.log("Obteniendo")
      this.seleccionarCliente(null)
      this.seleccionarAsunto(null)
      //
      const loadingController = document.querySelector('ion-loading-controller')
      console.log("loadingController?", loadingController)
      await loadingController.componentOnReady()
      const loadingElement = await loadingController.create({
        message: 'Un momento ...',
        spinner: 'crescent',
      })
      loadingElement.present()
      try {
        // Obtener todas las actividades generadas
        let filtros = [
          'fechaProgramada,gte,' + this.fechaInicio,
          'fechaProgramada,lt,' + this.fechaFin
        ]
        let actividades = await Api.find('actividad', filtros, {
          sort: ['fechaProgramada,1']
        })
        console.log("Actividades", actividades[0], actividades.length)
        // Obtener una lista de acuerdos para mapear despues por rendimiento
        this.todosLosAcuerdos = (await Api.get('acuerdo'))
          .filter(a => {
            return a.fechaCaducidad &&
              (a.idCliente || '').length == 24 &&
              (a.idAsunto || []).length > 0
          })
        console.log("Acuerdos?", this.todosLosAcuerdos[0], this.todosLosAcuerdos.length)
        // Obtener si la actividad fue incluida
        let actividadesIncluidas = await Api.find('prefacturarelacion', filtros, {
          sort: ['fechaProgramada,1']
        })
        let indiceActividadesIncluidas = {}
        for (let a of actividadesIncluidas) {
          indiceActividadesIncluidas[a.idActividad] = !a.cobrada
        }
        // Mapear las actividades para que indiquen si están incluídas
        actividades = actividades.map(a => {
          let incluir = true
          if (indiceActividadesIncluidas[a._id] && indiceActividadesIncluidas[a._id].cobrada)
            incluir = false
          a.incluir = incluir
          a.costoSugerido = 0
          a.costoReal = 0
          a.minutos = parseFloat(a.minutos) / 60 // De una vez pasarlo a horas
          return a
        })
        // Luego obtener una lista de los asuntos de los que provienen, y de los clientes que abarcan
        let listaAsuntos = actividades
          .map(a => {
            return a.idAsunto
          }).filter(a => {
            return a && typeof a != 'undefined'
          })
        listaAsuntos = [...new Set(listaAsuntos)]
        let listaClientes = actividades
          .map(a => {
            return a.idCliente
          }).filter(a => {
            return a && typeof a != 'undefined'
          })
        listaClientes = [...new Set(listaClientes)]
        console.log("Todo?", listaAsuntos, listaClientes)
        // Encontrar los asuntos y los acuerdos
        let asuntos = []
        for (let id of listaAsuntos) try {
          let asunto = await Api.get('asunto', id)
          //console.log("Asunto recuperado", asunto._id)
          asuntos.push(asunto)
          // Asignar los acuerdos solo para hacerlos visibles
        } catch (e) {
          console.error(e)
        }
        console.log("Asuntos recuperados", asuntos[0], asuntos.length)
        // Obtener a los clientes
        let clientes = (await Cliente.lista())
          .filter(c => {
            let tieneAsuntosEnEsteIntervaloDeTiempo = false
            for (let a of actividades)
              if (a.idCliente == c._id)
                tieneAsuntosEnEsteIntervaloDeTiempo = true
            return tieneAsuntosEnEsteIntervaloDeTiempo
          })
        //if (!Api.modoProduccion)
        //clientes = clientes.slice(0, 20)
        console.log("Clientes recuperados", clientes)
        // Encontrar los acuerdos y asociarlos a los asuntos
        for (let id in asuntos) {
          let acuerdos = await Api.find('acuerdo', [
            'idAsunto,eq,' + asuntos[id]._id
          ], {
            limit: 1
          })
          if (acuerdos && acuerdos[0]) {
            asuntos[id].acuerdo = acuerdos[0]
            console.log("Acuerdo recuperado", asuntos[id].acuerdo._id)
          }
        }
        if (this.validarSoloAsuntosConcretados)
          asuntos = asuntos.filter(a => {
            return a.acuerdo && a.acuerdo._id
          })
        console.log("Asuntos filtrados", asuntos)
        // Desplegar agrupados por cliente. Construir el arbol.
        let arbol = {}
        for (let cl of clientes) {
          arbol[cl._id] = cl
          arbol[cl._id].asuntos = {}
          arbol[cl._id].acuerdos = {}
          for (let asunto of asuntos) {
            if (asunto.idCliente == cl._id) {
              arbol[cl._id].asuntos[asunto._id] = asunto
              arbol[cl._id].asuntos[asunto._id].actividades = {}
              // Actividades
              for (let actividad of actividades)
                if (actividad.idAsunto == asunto._id) {
                  arbol[cl._id].asuntos[asunto._id].actividades[actividad._id] = actividad
                }
              // Acuerdos
              console.log("A?signando acuerdos", this.todosLosAcuerdos)
              for (let acuerdo of this.todosLosAcuerdos) {
                console.log("a?", cl._id, acuerdo.idCliente)
                if (cl._id == acuerdo.idCliente) {
                  arbol[cl._id].acuerdos[acuerdo._id] = acuerdo
                }
                // Encontrar acuerdos por tarifa e insertarlos igualmente
                else if (
                  acuerdo.tipo == 'Iguala' &&
                  this.cobrarIgualasSinActividades
                  //&& acuerdo.idCliente == cl._id
                ) {
                  let fechaFin = new Date(acuerdo.fechaCaducidad).getTime()
                  let fechaActual = new Date().getTime()
                  console.log("Insertando iguala", (fechaFin > fechaActual), acuerdo)
                  if (fechaFin > fechaActual) {
                    arbol[cl._id].acuerdos[acuerdo._id] = acuerdo
                    if (!arbol[cl._id].fechaAcuerdoMasAntigua || arbol[cl._id].fechaAcuerdoMasAntigua > acuerdo.fechaCaducidad)
                      arbol[cl._id].fechaAcuerdoMasAntigua = acuerdo.fechaCaducidad
                    arbol[cl._id].asuntos['ABCDEF'] = {
                      _id: 'ABCDEF',
                      nombre: 'Cobranza del mes',
                      descripcion: 'Cobranza del mes',
                      actividades: {
                        'ABCDEF': {
                          _id: 'ABCDEF',
                          minutos: 1
                        }
                      }
                    }
                    console.log("Insertado pseudoasunto con pseudoactividad")
                  }
                }
              }
            }
          }
        }
        // Finalmente, tarificar
        this.arbol = arbol
        //
        this.encontrarTarificacion()
        console.log("Arbol?", this.arbol, JSON.stringify(this.arbol).length)
        this.$forceUpdate()
      } catch (e) {
        alert("No se pudo procesar la información de cobranza")
        console.error(e)
      }
      loadingElement.dismiss()
      return Promise.resolve(true)
    },
    toggleIncluirActividad(idCliente, idAsunto, idActividad, valor) {
      console.log("Toggling", idCliente, idAsunto, idActividad, valor)
      this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].incluir = valor
      console.log("Toggled", valor)
      this.$forceUpdate()
      this.encontrarTarificacion()
    },
    async encontrarTarificacion() {
      // Determinar la tarifa base para cada abogado, pero sólo de acuerdo a su rol
      let empresa = Api.obtenerLocal('elite.empresa')
      let tarifaPorAbogado = {}
      for (let a of this.abogados) {
        tarifaPorAbogado[a._id] = 0
        if (a.tipo && empresa.tarifasPorRol[a.tipo])
          tarifaPorAbogado[a._id] = empresa.tarifasPorRol[a.tipo]
      }
      console.log("Tarifa por rol obtenida", tarifaPorAbogado)
      // Resetear el total general
      this.totalGeneral = 0
      this.totalSugerido = 0
      // Para cada cliente:
      for (let idCliente in this.arbol) {
        console.log("Analizando cliente", idCliente, this.arbol[idCliente].nombre)
        // - Resetear su subtotal
        this.arbol[idCliente].total = 0
        this.arbol[idCliente].totalSugerido = 0
        // - Para cada asunto:
        for (let idAsunto in this.arbol[idCliente].asuntos) {
          console.log("Analizando asunto", this.arbol[idCliente].asuntos[idAsunto].nombre)
          let tarifaPorHora = 0
          let tarifaSugerida = 0
          // - - Encontrar la fecha de acuerdo mas antigua
          this.arbol[idCliente].provieneDeAcuerdoCaduco = null
          let esteAsuntoEstaCaduco = await Tarificador.asuntoTieneAcuerdoCaduco(idAsunto)
          if (!this.arbol[idCliente].provieneDeAcuerdoCaduco && esteAsuntoEstaCaduco && esteAsuntoEstaCaduco.caduco)
            this.arbol[idCliente].provieneDeAcuerdoCaduco = esteAsuntoEstaCaduco
          // - - Resetear su subtotal
          this.arbol[idCliente].asuntos[idAsunto].total = 0
          this.arbol[idCliente].asuntos[idAsunto].totalSugerido = 0
          // - - Determinar por separado el tipo de cobranza (rol, actividad, probono, etc)
          let tipoCobranza = 'Rol'
          if (this.arbol[idCliente].asuntos[idAsunto].acuerdo && this.arbol[idCliente].asuntos[idAsunto].acuerdo.tipo)
            tipoCobranza = this.arbol[idCliente].asuntos[idAsunto].acuerdo.tipo
          console.log("Tipo de cobranza del asunto", idAsunto, tipoCobranza)
          // - - Obtener la tarifa sugerida, en base a rol, y guardarla.
          for (let idActividad in this.arbol[idCliente].asuntos[idAsunto].actividades) {
            let idAbogado = this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].idAbogado
            tarifaPorHora = tarifaPorAbogado[idAbogado]
            let minutos = this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].minutos
            console.log("Encontrada tarifa de abogado estándar", idAbogado, tarifaPorHora, minutos)
            this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].tarifaSugerida = tarifaPorHora
            this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].costoSugerido = tarifaPorHora * minutos
            this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].tarifa = tarifaPorHora
            this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].costoReal = tarifaPorHora * minutos
          }
          tarifaSugerida = tarifaPorHora
          // - - Si es por asunto, sólo cobrar si el asunto está terminado. Obtener el resto de las actividades. Dividir proporcionalmente
          if (tipoCobranza == 'Asunto') {
            if (this.arbol[idCliente].asuntos[idAsunto].terminado && this.arbol[idCliente].asuntos[idAsunto].acuerdo._id) {
              let costoAsunto = parseFloat(this.arbol[idCliente].asuntos[idAsunto].acuerdo.tarifa)
              let actividades = await Api.find('actividad', ['idAsunto,eq,' + idAsunto])
              for (let act of actividades) {
                this.arbol[idCliente].asuntos[idAsunto].actividades[act._id] = act
                this.arbol[idCliente].asuntos[idAsunto].actividades[act._id].minutos /= 60
                let minutos = this.arbol[idCliente].asuntos[idAsunto].actividades[act._id].minutos
                this.arbol[idCliente].asuntos[idAsunto].actividades[act._id].costoReal = costoAsunto / actividades.length
                this.arbol[idCliente].asuntos[idAsunto].actividades[act._id].costoSugerido = tarifaSugerida * minutos
                this.arbol[idCliente].asuntos[idAsunto].actividades[act._id].tarifa = tarifaPorHora
                this.arbol[idCliente].asuntos[idAsunto].actividades[act._id].tarifaSugerida = tarifaSugerida
                this.arbol[idCliente].asuntos[idAsunto].actividades[act._id].incluir = true
              }
              console.log("Encontrada cobranza por asunto", costoAsunto, actividades.length)
            } else {
              tarifaPorHora = 0
              for (let idActividad in this.arbol[idCliente].asuntos[idAsunto].actividades) {
                this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].costoReal = 0
                this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].incluir = false
              }
              console.log("Omitiendo cobranza de asunto no terminado")
            }
          }
          // - - Si es por iguala, dividir proporcionalmente el costo en base al número de actividades
          else if (tipoCobranza == 'Iguala') {
            for (let idActividad in this.arbol[idCliente].asuntos[idAsunto].actividades) {
              this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].costoReal = costoIguala / numActividades
              console.log("Actualizada tarifa a match por iguala", numActividades, costoIguala, this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].descripcion)
            }
          }
          // - - Si es por abogado, cambiar la tarifa sólo para este loop determinar la tarifa para cada actividad
          else if (tipoCobranza == 'Abogado') {
            let tarifasAbogados = {}
            for (let a of this.arbol[idCliente].asuntos[idAsunto].acuerdo.abogados) {
              tarifasAbogados[a.idAbogado] = a.tarifa
            }
            for (let idActividad in this.arbol[idCliente].asuntos[idAsunto].actividades)
              for (let idAbogadoConTarifaNueva in tarifasAbogados) {
                let minutos = this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].minutos
                if (this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].idAbogado == idAbogadoConTarifaNueva) {
                  this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].tarifa = tarifasAbogados[idAbogadoConTarifaNueva]
                  this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].costoReal = tarifasAbogados[idAbogadoConTarifaNueva] * minutos
                  console.log("Actualizada tarifa a abogado especifico", idAbogadoConTarifaNueva, minutos)
                }
              }
          }
          // Si es por cliente, asignar todas las actividades a la misma tarifa
          else if (tipoCobranza == 'Cliente') {
            let tarifa = parseFloat(this.arbol[idCliente].asuntos[idAsunto].acuerdo.tarifa)
            for (let idActividad in this.arbol[idCliente].asuntos[idAsunto].actividades) {
              let minutos = this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].minutos
              console.log("Sumando tarifa cliente", tipoCobranza, idActividad, minutos, tarifa)
              this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].costoReal = tarifa * minutos
              this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].tarifa = tarifa
            }
          }
          // - - Si es por rol (default), igualar a la tarifa sugerida.
          else if (tipoCobranza == 'Rol') {
            console.log("LOL no hay nada que hacer aqui")
          }
          // - - Si es por probono o gratuito, rellenar con ceros
          else if (tipoCobranza == 'Gratuito' || tipoCobranza == 'Probono') {
            for (let idActividad in this.arbol[idCliente].asuntos[idAsunto].actividades) {
              this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].tarifa = 0
              this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].tarifaSugerida = 0
              this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].costoReal = 0
              this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].costoSugerido = 0
            }
          }
          //
          console.log("Terminada la tarificacion. Sumarizando.")
          // Para  cada actividad:
          for (let idActividad in this.arbol[idCliente].asuntos[idAsunto].actividades) {
            if (this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].incluir) {
              let costoReal = this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].costoReal || 0
              let costoSugerido = this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].costoSugerido || 0
              console.log('Costos', idActividad, costoReal, costoSugerido)
              // - - Sumar la tarifa parcial al total del asunto
              this.arbol[idCliente].asuntos[idAsunto].total += costoReal
              this.arbol[idCliente].asuntos[idAsunto].totalSugerido += costoSugerido
              // - - Sumar la tarifa parcial al total del cliente
              this.arbol[idCliente].total += costoReal
              this.arbol[idCliente].totalSugerido += costoSugerido
              // - - Sumar la tarifa parcial al total general
              this.totalGeneral += costoReal
              this.totalSugerido += costoSugerido
            } else {
              console.log("Excluyendo", idActividad)
            }
          }
        }
      }
      console.log("Totales", this.totalGeneral, this.totalSugerido, tarifaPorAbogado)
      this.$forceUpdate()
    },
    async generarPrefacturas() {
      const loadingController = document.querySelector('ion-loading-controller')
      console.log("loadingController?", loadingController)
      await loadingController.componentOnReady()
      const loadingElement = await loadingController.create({
        message: 'Un momento ...',
        spinner: 'crescent',
      })
      loadingElement.present()
      // Para cada cliente:
      for (let idCliente in this.arbol) {
        console.log("Procesando cliente", this.arbol[idCliente].nombre)
        // - Guardar la prefactura, su pedazo de árbol, tal cual se generó en el módulo
        let prefactura = {
          idAutor: this.yo._id,
          fecha: new Date().getTime(),
          fechaInicio: this.fechaInicio,
          fechaFin: this.fechaFin,
          idCliente: idCliente,
          arbol: this.arbol[idCliente],
          total: this.arbol[idCliente].total,
          totalSugerido: this.arbol[idCliente].totalSugerido,
          fechaAcuerdoMasAntigua: this.arbol[idCliente].fechaAcuerdoMasAntigua,
          partidaUnitaria: true
        }
        if (prefactura.arbol._id)
          delete prefactura.arbol._id
        prefactura = await Api.save('prefactura', prefactura)
        // - Para cada asunto:
        for (let idAsunto in this.arbol[idCliente].asuntos) {
          // - - Para cada actividad:
          for (let idActividad in this.arbol[idCliente].asuntos[idAsunto].actividades) {
            // - - - Guardar una relación simple de cobrado:
            // - - - idActividad,idAsunto,idPrefactura,idCliente,fecha(de la actividad),idAutor,cobrada(toggleIncluirActividad),fechaCobrada
            let relacion = {
              idPrefactura: prefactura._id,
              idActividad: idActividad,
              idAsunto: idAsunto,
              idCliente: idCliente,
              fechaProgramada: this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].fechaProgramada,
              idAutor: this.yo._id,
              cobrada: this.arbol[idCliente].asuntos[idAsunto].actividades[idActividad].incluir,
              fecha: new Date().getTime(),
            }
            relacion = await Api.save('prefacturarelacion', relacion)
          }
        }
      }
      // Avisar de terminado
      alert("Prefacturas terminadas")
      loadingElement.dismiss()
      // Redirigir a la hoja de prefacturas: /prefactura/fechaInicio/fechaFin
      this.$router.push('/prefacturas/' + this.fechaInicio + '/' + this.fechaFin)
    },
    descargarArchivo(val) {
      let lnk = Api.cdn + '/' + val
      window.open(lnk, '_blank')
    },
  },
  async mounted() {
    console.log("ListaCobranza mounted")
    this.abogados = await Usuario.lista()
    this.roles = await Api.get('area')
    this.obtener()
  },
  beforeDestroy() {}
}
</script>
