<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-title>{{conceptosat.nombre || 'Concepto facturable'}}</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-list>
      <!--ion-item v-if="yo.rol=='administrador'">
        <ion-label text-wrap position="">ID</ion-label>
        <ion-input class="ion-text-right" :value="conceptosat._id" v-on:ionChange="conceptosat._id=($event.detail.value||'').toLowerCase()">
        </ion-input>
      </ion-item-->
      <ion-item>
        <ion-label text-wrap position="">Nombre del concepto *</ion-label>
        <ion-input class="ion-text-right" :color="colors('primary')" :value="conceptosat.nombre" v-on:ionChange="conceptosat.nombre=$event.detail.value">
        </ion-input>
      </ion-item>
      <ion-item>
        <ion-label text-wrap position="">Clave del producto SAT</ion-label>
        <ion-input class="ion-text-right" :color="colors('primary')" :value="conceptosat.claveProductoSat" v-on:ionChange="conceptosat.claveProductoSat=$event.detail.value">
        </ion-input>
      </ion-item>
      <ion-item>
        <ion-label text-wrap position="">Clave de unidad SAT</ion-label>
        <ion-input class="ion-text-right" :color="colors('primary')" :value="conceptosat.claveUnidadSat" v-on:ionChange="conceptosat.claveUnidadSat=$event.detail.value">
        </ion-input>
      </ion-item>


    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed" v-if="permiso('EditarConceptosFacturables') || (permiso('CrearConceptosFacturables') && !conceptosat._id)">
      <ion-fab-button :color="colors('secondary')" v-on:click="guardar()">
        <i class="icon ion-ios-save icon-lg"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
export default {
  name: 'CatalogoConceptosat',
  props: ['id'],
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      conceptosat: {
        nombre: '',
        claveProductoSat: '',
        claveUnidadSat: ''
      }
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async guardar() {
      if (!(this.conceptosat.nombre && this.conceptosat.nombre != ''))
        alert("Falta nombre")
      else {
        let u = await Api.save('conceptosat', this.conceptosat)
        alert("Guardado")
      }
    }
  },
  async mounted() {
    console.log("Catalogo cliente", this.id)
    if (this.id && this.id.length == 24) {
      this.conceptosat = await Api.get('conceptosat', this.id)
    }
  }
}
</script>
