<template>
  <div class="ion-page" main>
    <ion-header>
      <ion-toolbar :color="colors('secondary')">
        <ion-title>Asuntos</ion-title>
        <ion-buttons slot="start">
          <ion-button @click="openEnd">
            <i class="icon ion-ios-menu"></i>
          </ion-button>
        </ion-buttons>
        <ion-buttons
          slot="end"
          v-if="yo.rol == 'administrador' && this.cliente && this.cliente._id"
        >
          <ion-button size="small" fill="outline" @click="exportar">
            <i class="icon ion-ios-cloud-download icon-sm"></i>&nbsp;Exportar
          </ion-button>
          <label for="ul">
            <input
              name="ul"
              id="ul"
              v-on:change="importar()"
              class="hidden"
              type="file"
              multiple="multiple"
            />
            <ion-button slot="end" size="small" fill="outline">
              <i class="icon ion-ios-cloud-upload icon-sm"></i>&nbsp;Importar
            </ion-button>
          </label>
        </ion-buttons>
        <ion-buttons slot="end" v-if="!this.cliente._id">
          <ion-button @click="misAsuntos" :disabled="mostrandoMisAsuntos">
            <i class="icon ion-ios-contact"></i>&nbsp;Mis asuntos
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-progress-bar
        type="indeterminate"
        v-if="cargando || spinner"
      ></ion-progress-bar>
    </ion-header>
    <ion-content class="content">
      <SelectorCliente />
      <ion-item
        lines="none"
        v-if="permiso('CrearAcuerdos') || permiso('EditarAcuerdos')"
      >
        <ion-segment
          :value="modoAcuerdos"
          v-on:ionChange="setModoAcuerdos($event.detail.value)"
          :color="colors('secondary')"
        >
          <ion-segment-button :value="'todos'">TODOS</ion-segment-button>
          <ion-segment-button :value="'conAcuerdos'"
            >Con acuerdos de cobranza</ion-segment-button
          >
          <ion-segment-button :value="'sinAcuerdos'"
            >Sin acuerdos</ion-segment-button
          >
        </ion-segment>
      </ion-item>
      <!--ion-searchbar v-if="idCliente!=''" placeholder="Buscar por nombre o área"></ion-searchbar-->
      <ion-list v-if="(asuntos || []).length > 0">
        <ion-item-sliding v-for="a in asuntos" :key="a._id">
          <ion-item @click="ver(a._id)">
            <ion-note
              slot="start"
              v-if="a.histogramaCobranza && a.histogramaCobranza.length > 0"
            >
              <i class="icon ion-logo-ionic icon-lg"></i>
            </ion-note>
            <ion-note slot="start" color="warning" v-else>
              <i class="icon ion-ios-information-circle"></i>
            </ion-note>
            <ion-label
              text-wrap
              :color="a.terminado && a.terminado == 1 ? 'light' : 'primary'"
            >
              <h2>{{ a.nombre }}</h2>
              <p>
                {{ a.area }} {{ a.folio || a.numeroExpediente
                }}<span v-if="a.terminado && a.terminado == 1"
                  >, terminado</span
                >
              </p>
              <p>
                {{ (a.fecha + 1000 * 60 * 60 * 6) | moment("MMM DD YYYY") }}
                <span
                  v-if="
                    !(a.histogramaCobranza && a.histogramaCobranza.length > 0)
                  "
                >
                  - Sin plan de cobranza</span
                >
              </p>
            </ion-label>
            <ion-note slot="end"
              >{{ (a.actividades || []).length }} acts</ion-note
            >
          </ion-item>
          <ion-item-options slot="end">
            <ion-item-option
              :color="colors('danger')"
              v-if="eliminable(a) && permiso('BorrarAsuntos')"
              @click="eliminar(a._id)"
            >
              <i class="icon ion-ios-trash"></i>&nbsp;Eliminar
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </ion-list>
      <ion-item v-else lines="none">
        <ion-label class="ion-text-center" :color="colors('danger')">
          <p>No hay asuntos que mostrar</p>
        </ion-label>
      </ion-item>
      <ion-fab
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        v-if="permiso('CrearAsuntos')"
      >
        <ion-fab-button :color="colors('secondary')" v-on:click="ver(0)">
          <i class="icon ion-ios-add icon-lg"></i>
        </ion-fab-button>
      </ion-fab>
      <label
        for="ul"
        v-if="
          this.cliente && this.cliente._id && this.yo.rol == 'administrador'
        "
      >
        <input
          name="ul"
          id="ul"
          v-on:change="importar()"
          class="hidden"
          type="file"
          multiple="multiple"
        />
        <ion-fab vertical="bottom" horizontal="start" slot="fixed">
          <ion-fab-button :color="colors('secondary')">
            <i class="icon ion-ios-cloud-upload icon-lg"></i>
          </ion-fab-button>
        </ion-fab>
      </label>
    </ion-content>
  </div>
</template>

<script>
import Api from "@/components/Api";
import Cliente from "@/models/Cliente";
import Usuario from "@/models/Usuario";
import SelectorCliente from "@/components/SelectorCliente";
export default {
  name: "Asuntos",
  components: {
    SelectorCliente,
  },
  props: {
    modoRuta: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      yo: Api.obtenerLocal("elite.yo") || {},
      mostrandoMisAsuntos: false,
      asuntos: [],
      asuntosTodos: [],
      actividadesTodas: [],
      fechaInicio: null,
      fechaFin: this.$options.filters.moment(new Date(), "YYYY-MM-DD"),
      filtro: "",
      modoAcuerdos: "todos",
      modoCliente: true,
      modoSeleccion: false,
      idCliente: "",
      cliente: {},
      clientes: [],
      clientesTodos: [],
      seleccionadosParaReporte: {},
      spinner: false,
      paramsGuardables: {
        fechaInicio: null,
        fechaFin: null,
        cliente: "",
      },
    };
  },
  methods: {
    openEnd() {
      Api.$emit("open-menu");
    },
    pos() {
      this.$router.push("pos");
    },
    buscable(dato, valor) {
      console.log("Configurando busqueda?", dato, valor);
      this[dato] = this.$options.filters.moment(
        new Date(valor).getTime() + 1000 * 60 * 60 * 6,
        "YYYY-MM-DD"
      );
      this.actividadesTodas = [];
      this.obtener();
      if ((dato || "").startsWith("fecha")) {
        this.paramsGuardables[dato] = this[dato];
        Api.guardarLocal("elite.filtrosAsuntos", this.paramsGuardables);
      }
    },
    eliminable(asunto) {
      return (asunto.actividades || []).length == 0;
    },
    filtrarAsuntos(val) {
      this.filtro = (val || "").toUpperCase();
    },
    mostrable(cliente) {
      //console.log("Mostrable cliente?", cliente, this.filtro)
      if (this.clientes.length <= 20 && this.filtro == "") return true;
      else if (this.filtro == "") return false;
      else {
        return (cliente.nombre || "").toUpperCase().indexOf(this.filtro) >= 0;
      }
    },
    async obtener(todos = false) {
      this.cargando = true;
      this.mostrandoMisAsuntos = false;
      if (this.idCliente == "" && !todos) alert("Debe seleccionar un cliente");
      else if (!todos) {
        let asuntos = await Api.find(
          "asunto",
          ["idCliente,eq," + this.idCliente],
          {
            sort: ["fecha,-1"],
          }
        );
        this.asuntos = asuntos.filter((a) => a.nombre && a.nombre != "");
      } else if (todos) {
        this.asuntos = (
          await Api.find("asunto", [], {
            sort: ["fecha,-1"],
            limit: 100,
          })
        ).filter((a) => a.nombre && a.nombre != "");
        console.log("Obteniendo todos los asuntos. Buscando por acuerdos?");
      }
      this.$forceUpdate();
      for (let id in this.asuntos) {
        this.asuntos[id].actividades = await Api.find("actividad", [
          "idAsunto,eq," + this.asuntos[id]._id,
        ]);
        console.log("Actividades del asunto", this.asuntos[id].actividades);
        this.$forceUpdate();
      }
      this.cargando = false;
      return true;
    },
    async setModoAcuerdos(val) {
      this.modoAcuerdos = val;
      //window.location.assign('#/asuntos/' + this.modoAcuerdos)
      let todos = true;
      await this.obtener(todos);
      this.asuntos = this.asuntos.filter((a) => {
        if (val == "todos") return true;
        else if (val == "conAcuerdos")
          return a.histogramaCobranza && a.histogramaCobranza.length > 0;
        else if (val == "sinAcuerdos")
          return !(a.histogramaCobranza && a.histogramaCobranza.length > 0);
      });
      console.log("setModoAcuerdos", val, this.asuntos.length);
      //this.$router.push('/asuntos/' + this.modoRuta)
      this.$forceUpdate();
    },
    async misAsuntos() {
      this.cargando = true;
      this.mostrandoMisAsuntos = true;
      let actividades = await Api.find("actividad", [
        "idAutor,eq," + this.yo._id,
      ]);
      let indicesAsuntos = actividades.map((a) => a.idAsunto);
      indicesAsuntos = [...new Set(indicesAsuntos)];
      let asuntos = [];
      for (let i of indicesAsuntos) {
        let asunto = await Api.get("asunto", i);
        asunto.actividades = actividades.filter(
          (act) => act.idAsunto == asunto._id
        );
        asuntos.push(asunto);
      }
      console.log("asuntos?", asuntos);
      this.asuntos = asuntos;
      await Api.wait(500);
      this.cargando = false;
      this.$forceUpdate();
    },
    seleccionarParaReporte(id, agregar) {
      console.log("Agregando", id, agregar);
      this.seleccionadosParaReporte[id] = agregar;
    },
    async marcarTerminados() {
      if (
        true ==
        confirm(
          "¿Desea marcar estos elementos como terminados? \n\nSi acepta y desea deshacer esto, un administrador deberá volver a habilitar cada asunto manualmente. "
        )
      ) {
        let seleccionados = [];
        console.log("Obteniendo terminables", this.seleccionadosParaReporte);
        for (let i in this.seleccionadosParaReporte) {
          console.log("i?", i);
          if (this.seleccionadosParaReporte[i]) seleccionados.push(i);
        }
        console.log("Seleccionados", seleccionados);
        for (let idAsunto of seleccionados) {
          let asunto = await Api.get("asunto", idAsunto);
          asunto.terminado = 1;
          await Api.save("asunto", asunto);
        }
      }
      alert("Asuntos guardados");
      this.obtener();
    },
    generarMultiReporte() {
      let seleccionados = [];
      console.log("Generando", this.seleccionadosParaReporte);
      for (let i in this.seleccionadosParaReporte) {
        console.log("i?", i);
        if (this.seleccionadosParaReporte[i]) seleccionados.push(i);
      }
      console.log("Seleccionados", seleccionados);
      if (seleccionados.length == 0) alert("No fue seleccionado ningún asunto");
      else
        this.$router.push({
          name: "Facturable",
          params: {
            id: seleccionados[0],
            ids: seleccionados,
            fechaInicio: this.fechaInicio,
            fechaFin: this.fechaFin,
          },
        });
    },
    filtrarPorCliente(c) {
      c = c || "";
      this.cliente = c;
      this.paramsGuardables.cliente = c;
      Api.guardarLocal("elite.filtrosAsuntos", this.paramsGuardables);
      console.log("Filtrando", c);
      this.asuntos = this.asuntosTodos.filter((a) => {
        return !c || c == "" || c == a.idCliente;
      });
      this.modoCliente = false;
      console.log(
        "Asuntos filtrados",
        this.asuntos.length,
        this.asuntosTodos.length
      );
    },
    seleccionarTodo() {
      for (let i in this.asuntos) this.asuntos[i].seleccionado = true;
      this.seleccionadosParaReporte = {};
      for (let a of this.asuntos) this.seleccionadosParaReporte[a._id] = true;
      console.log("Seleccionados para reporte?", this.seleccionadosParaReporte);
      this.$forceUpdate();
    },
    ver(id) {
      this.$router.push({
        name: "Asunto",
        params: {
          id: id,
          idCliente: this.cliente,
          fechaInicio: this.fechaInicio,
          fechaFin: this.fechaFin,
        },
      });
    },
    async eliminar(id) {
      let vm = this;
      if (
        this.yo.rol == "administrador" &&
        true ==
          confirm("Desea eliminar este elemento? Esto no se puede deshacer")
      ) {
        let asunto = await Api.get("asunto", id);
        let cliente = await Api.get("cliente", asunto.idCliente);
        await Api.delete("asunto", id); //.then(async _ => {
        let actividades = await Api.find("actividad", ["idAsunto,eq," + id]);
        actividades = actividades.map((a) => {
          return a._id;
        });
        let bitacora = {
          idAutor: vm.yo._id,
          fecha: new Date().getTime(),
          mensaje:
            "Asunto eliminado: " + asunto.nombre + " de " + cliente.nombre,
        };
        await Api.save("bitacora", bitacora);
        await Api.deleteMulti("actividad", actividades);
        alert("Eliminado asunto");
        vm.obtener();
        //})
      } else {
        alert("No se puede borrar este asunto. ¿Eres administrador?");
      }
    },
    reporte(a) {
      console.log("Generando reporte", a);
      this.$router.push("/facturable/" + a._id);
    },
    seleccionarCliente(id) {
      this.idCliente = id;
      this.cliente = {};
      for (let c of this.clientesTodos)
        if (this.idCliente == c._id) this.cliente = c;
      this.obtener();
    },
    exportar() {
      let nombreCliente = this.cliente.nombre;
      let nombreArchivo =
        nombreCliente +
        this.$options.filters.moment(new Date(), "YYYYMMDD") +
        ".json";
      let contenido = JSON.stringify(this.asuntos);

      function download(filename, text) {
        var element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/json;charset=utf-8," + encodeURIComponent(text)
        );
        element.setAttribute("download", filename);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
      download(nombreArchivo, contenido);
    },
    async importar() {
      let archivo = await Api.upload("ul", "binario");
      archivo = JSON.parse(archivo);
      console.log("Archivo?", archivo);
      for (let a of archivo)
        try {
          let existe = await Api.get("asunto", a._id);
          let conf = false;
          if (existe && existe._id)
            conf =
              true ==
              confirm(
                "El asunto " +
                  a.nombre +
                  " ya existe como " +
                  existe.nombre +
                  ". Desea reeemplazarlo?"
              );
          if (conf == true) await Api.save("asunto", a);
          else if (!(existe && existe._id)) {
            alert("Guardando asunto " + a.nombre);
            await Api.save("asunto", a);
          }
        } catch (e) {
          console.error(e);
        }
    },
  },
  async beforeMount() {
    this.clientes = await Cliente.lista();
    this.clientesTodos = await Cliente.lista();
  },
  async mounted() {
    if (this.modoRuta != "") {
      this.setModoAcuerdos(this.modoRuta);
    } else {
      if (this.idCliente != "") this.obtener();
    }
    Api.$on("clienteSeleccionado", this.seleccionarCliente);
  },
  beforeDestroy() {
    Api.$off("clienteSeleccionado");
  },
};
</script>
