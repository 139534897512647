<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Solicitud</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list>
      <ion-item>
        <ion-label text-wrap position="">Nombre de la propuesta</ion-label>
        <ion-input class="ion-text-right" :value="propuesta.nombre" v-on:ionChange="propuesta.nombre=$event.detail.value"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label text-wrap>Presupuesto origen</ion-label>
        <ion-select class="ion-text-right" :value="propuesta.idPresupuesto" v-on:ionChange="propuesta.idPresupuesto=$event.detail.value">
          <ion-select-option v-for="i in presupuestos" :key="i._id" :value="i._id">{{i.nombre}}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label text-wrap>Rubro</ion-label>
        <ion-select class="ion-text-right" :value="propuesta.rubro" v-on:ionChange="propuesta.rubro=$event.detail.value">
          <ion-select-option v-for="r in rubros" :key="r.nombr">{{r.nombre}}</ion-select-option>
        </ion-select>
      </ion-item>
      <div>
        <label for="archivoAdjunto">
          <input type="file" class="hidden" name="archivoAdjunto" id="archivoAdjunto" v-on:change="adjuntar()" />
          <ion-item v-if="!propuesta.archivoAdjunto">
            <ion-note slot="end" :color="colors('secondary')">
              <i class="icon ion-ios-cloud-upload"></i>
            </ion-note>
            <ion-label text-wrap position="">Haga clic para adjuntar un archivo</ion-label>
          </ion-item>
          <ion-item-sliding v-else>
            <ion-item>
              <ion-note slot="end" :color="colors('primary')">
                <i class="icon ion-ios-attach icon-lg"></i>
              </ion-note>
              <ion-label>{{propuesta.archivoAdjunto}}</ion-label>
            </ion-item>
            <ion-item-options slot="end">
              <ion-item-option @click="descargarArchivoAdjunto">Descargar </ion-item-option>
              <ion-item-option :color="colors('danger')" @click="propuesta.archivoAdjunto=null"><i class="icon ion-ios-trash"></i></ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </label>
      </div>
      <ion-item>
        <ion-label text-wrap position="">Descripción detallada</ion-label>
        <ion-textarea class="ion-text-right" rows="5" :value="propuesta.descripcion" v-on:ionChange="propuesta.descripcion=$event.detail.value"></ion-textarea>
      </ion-item>
      <ion-item>
        <ion-label text-wrap position="">Cantidad solicitada</ion-label>
        <ion-input class="ion-text-right" type="number" step="any" :value="propuesta.cantidadCotizada" v-on:ionChange="propuesta.cantidadCotizada=parseFloat($event.detail.value)"></ion-input>
      </ion-item>
      <ion-item v-if="propuesta.cantidadCotizada>propuesta.limiteGasto" lines="none">
        <ion-note slot="start" :color="colors('danger')">
          <i class="icon ion-ios-information-circle-outline icon-lg"></i>
        </ion-note>
        <ion-label text-wrap :color="colors('danger')">
          <p>Debe aprobarse por socio director</p>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label text-wrap position="">Fecha límite para considerar</ion-label>
        <ion-input class="ion-text-right" type="date" :value="propuesta.fechaLimite" v-on:ionChange="propuesta.fechaLimite=$event.detail.value"></ion-input>
      </ion-item>
      <ion-item v-if="(yo.rol=='administrador' || yo.rol=='socio')">
        <ion-label>Aprobada por socio director</ion-label>
        <ion-checkbox slot="end" v-on:ionChange="toggleAprobarPorSocio($event.detail.checked)" :checked="propuesta.limiteGasto>=propuesta.cantidadCotizada" />
      </ion-item>
      <ion-item v-if="yo.rol=='administrador' || permiso('aprobarGastos') || yo._id==encargadoPresupuesto">
        <ion-label>Estado</ion-label>
        <ion-select v-on:ionChange="toggleAprobarPropuesta($event.detail.value)" :value="propuesta.estado">
          <ion-select-option v-for="estado in ['aprobada','rechazada','pendiente']" :key="estado">{{estado}}</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('danger')" @click="guardar">
        <i class="icon icon-lg ion-ios-save"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
export default {
  name: 'PresupuestosPropuesta',
  props: ['id'],
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      propuesta: {
        nombre: '',
        idAutor: (Api.obtenerLocal('elite.yo') || {})._id,
        idUsuarioAprueba: '',
        idPresupuesto: '',
        fechaCreado: new Date().getTime(),
        estado: 'pendiente',
        requiereAprobacionSocio: true,
        requiereEvidenciaAdjunta: true
      },
      autor: {},
      presupuestos: [],
      presupuesto: {},
      encargadoPresupuesto: '',
      permitirWatcherIdPresupuesto: true,
      limiteGasto: 9999999999,
      rubros: [],
      cdn: Api.cdn + '/'
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async adjuntar() {
      this.propuesta.archivoAdjunto = await Api.upload('archivoAdjunto')
      console.log("archivoAdjunto?", this.propuesta.archivoAdjunto)
      this.$forceUpdate()
    },
    descargarArchivoAdjunto() {
      let url = this.cdn + this.propuesta.archivoAdjunto
      window.open(url, '_blank')
    },
    async guardar() {
      if (this.propuesta.nombre == '')
        alert("Falta nombre")
      else if (this.propuesta.idPresupuesto == '')
        alert("Asigne el presupuesto indicado")
      else if (this.propuesta.estado == 'aprobada' && this.propuesta.limiteGasto < this.propuesta.cantidadCotizada)
        alert("Un socio debe aprobar este gasto")
      else {
        await Api.save('propuesta', this.propuesta)
        if (this.propuesta.estado == 'aprobada') {
          // Guardar su gasto
          await Api.save('presupuestogasto', this.propuesta)
          alert("Propuesta guardada como gasto")
        }
        if (this.propuesta.aprobada)
          alert("Propuesta guardada")
        await Api.wait(1000)
        window.history.go(-1)
      }
    },
    toggleAprobarPropuesta(val) {
      this.propuesta.estado = val
      if (val == true) {
        this.propuesta.idUsuarioAprueba = this.yo._id
        this.propuesta.fechaAprobada = new Date().getTime()
      } else {
        this.propuesta.idUsuarioAprueba = ''
        this.propuesta.fechaAprobada = 0
      }
      console.log("toggleAprobarPropuesta", val)
    },
    toggleAprobarPorSocio(val) {
      if (val == true) {
        this.propuesta.limiteGasto = parseFloat(this.propuesta.cantidadCotizada)
      } else {
        this.propuesta.limiteGasto = parseFloat(this.limiteGasto)
      }
      console.log("Aprobado?", val, this.propuesta.limiteGasto, this.propuesta.cantidadCotizada)
      this.$forceUpdate()
    }
  },
  watch: {
    'propuesta.idPresupuesto': function(a) {
      for (let p of this.presupuestos)
        if (p._id == a) {
          this.presupuesto = p
          this.rubros = p.rubros
          if (this.permitirWatcherIdPresupuesto) {
            this.limiteGasto = parseFloat(p.retenerGastos)
            this.propuesta.limiteGasto = parseFloat(this.limiteGasto)
            console.log("Presupuesto seleccionado", this.propuesta.limiteGasto, this.presupuesto)
          } else {
            console.log("permitirWatcherIdPresupuesto bloqueado por primer montaje del módulo")
          }
        }
    }
  },
  async mounted() {
    this.presupuestos = await Api.get('presupuesto')
    console.log("Propuesta mounted", this.id, this.presupuestos.length)
    if (this.id && this.id != '' && this.id != '0') {
      await Api.wait(500)
      this.propuesta = await Api.get('propuesta', this.id)
      await Api.wait(100)
      this.permitirWatcherIdPresupuesto = true
      console.log("Recuperada propuesta", this.id, this.propuesta, this.autor.nombre)
    }
  }
}
</script>
