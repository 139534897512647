<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-title>Pago directo <span v-if="!gastoetapa2._id">- Nuevo</span><span v-else>- Editar</span> {{gastoetapa2.fechaProgramado | moment('MMM DD')}}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="cerrar">
          <i class="icon ion-ios-close-circle-outline"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" v-if="cargando"></ion-progress-bar>
  </ion-header>
  <ion-content>
    <ion-list>
      <ion-item>
        <ion-label>Presupuesto</ion-label>
        <ion-select class="ion-text-right" :value="gastoetapa2.idPresupuesto" v-on:ionChange="gastoetapa2.idPresupuesto=$event.detail.value">
          <ion-select-option v-for="p in presupuestos" :key="p._id" :value="p._id">{{p.nombre}}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label>Rubro</ion-label>
        <ion-select class="ion-text-right" :value="gastoetapa2.rubro" v-on:ionChange="gastoetapa2.rubro=$event.detail.value" :disabled="gastoetapa2.idPresupuesto==''">
          <ion-select-option>Pagos directos</ion-select-option>
          <ion-select-option v-for="r in rubros" :key="r.nombre">{{r.nombre}}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label>Proveedor</ion-label>
        <ion-select class="ion-text-right" :value="gastoetapa2.idProveedor" v-on:ionChange="gastoetapa2.idProveedor=$event.detail.value">
          <ion-select-option v-for="p in proveedores" :key="p._id" :value="p._id">{{p.nombre}}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label>Nombre del gasto</ion-label>
        <ion-textarea rows="3" class="ion-text-right" :value="gastoetapa2.nombre" v-on:ionChange="gastoetapa2.nombre=$event.detail.value"></ion-textarea>
      </ion-item>
      <ion-item>
        <ion-label>Cantidad erogada</ion-label>
        <ion-input class="ion-text-right" type="number" step="any" :value="gastoetapa2.cantidadErogable" v-on:ionChange="gastoetapa2.cantidadErogable=parseFloat($event.detail.value)"></ion-input>
      </ion-item>
      <ion-row>
        <ion-col size="6" class="ion-no-paddingg">
          <label for="archivoAdjuntoXml">
            <input class="hidden" name="archivoAdjuntoXml" id="archivoAdjuntoXml" v-on:change="subir('archivoAdjuntoXml')" type="file" />
            <ion-item :color="gastoetapa2.archivoAdjuntoXml?'secondary':''" lines="none">
              <ion-label class="ion-text-center" text-wrap v-if="!gastoetapa2.archivoAdjuntoXml">
                <h1>
                  <i class="icon icon-lg ion-ios-cloud-upload"></i>
                </h1>
                <h2>Click aqui para subir el XML</h2>
              </ion-label>
              <ion-label class="ion-text-center" v-else>
                <h1> <i class="icon icon-lg ion-ios-document"></i>
                </h1>
                <h2>{{gastoetapa2.archivoAdjuntoXml}}</h2>
              </ion-label>
            </ion-item>
          </label>
        </ion-col>
        <ion-col size="6" class="ion-no-paddingg">
          <label for="archivoAdjunto">
            <input class="hidden" name="archivoAdjunto" id="archivoAdjunto" v-on:change="subir('archivoAdjunto')" type="file" />
            <ion-item :color="gastoetapa2.archivoAdjunto?'secondary':''" lines="none">
              <ion-note slot="start">
              </ion-note>
              <ion-label class="ion-text-center" text-wrap v-if="!gastoetapa2.archivoAdjunto">
                <h1>
                  <i class="icon icon-lg ion-ios-cloud-upload"></i>
                </h1>
                <h2>Click aqui para subir un archivo</h2>
              </ion-label>
              <ion-label class="ion-text-center" v-else>
                <h1>
                  <i class="icon icon-lg ion-ios-document"></i>
                </h1>
                <h2>{{gastoetapa2.archivoAdjunto}}</h2>
              </ion-label>
            </ion-item>
          </label>
        </ion-col>
        <ion-col size="12">
          <ion-item v-if="esImagen(gastoetapa2.archivoAdjunto)">
            <ion-img :src="cdn+gastoetapa2.archivoAdjunto"></ion-img>
          </ion-item>
          <embed v-if="esPdf(gastoetapa2.archivoAdjunto)" :src="cdn+gastoetapa2.archivoAdjunto" type="application/pdf" style="width:100%;min-height:50vh;" />
        </ion-col>
        <ion-col size="12" v-if="!modoProduccion">
          <pre class="ion-text-left">{{gastoetapa2}}</pre>
        </ion-col>
      </ion-row>
    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="guardar" :disabled="bloquearGuardar()">
        <i class="icon icon-lg ion-ios-save"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
import Empresa from '@/models/Empresa'
import Emailer from '@/models/Emailer'
import axios from 'axios'
import Xml from '@/models/Xml'
export default {
  name: 'ModalPagoDirecto',
  props: {
    gastoetapa: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      cdn: Api.cdn + '/',
      modoProduccion: Api.modoProduccion,
      presupuestos: [],
      proveedores: [],
      cargando: true,
      gastoetapa2: {
        nombre: '',
        idAutor: (Api.obtenerLocal('elite.yo') || {})._id,
        directo: 1,
        fecha: new Date().getTime(),
        idProveedor: '',
        idPresupuesto: '',
        idGasto: '',
        fechaProgramado: new Date().toISOString().substring(0, 10),
        fechaLimite: new Date().toISOString().substring(0, 10),
        aprobadaPorSocio: true,
        idSocioAprueba: 'XXXXXXXXXXXXXXXXXXXXXXXX',
        terminada: true,
        fechaTerminada: new Date().getTime(),
        requiereEvidenciaAdjunta: true,
        requiereAprobacionSocio: true,
        cantidadErogable: 0,
        archivoAdjuntoFecha: null,
        archivoAdjunto: null,
        archivoAdjuntoXmlFecha: null,
        archivoAdjuntoXml: null,
        pagoDias: 0,
      },
      rubros: []
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    obtener() {},
    esImagen(img) {
      return (img || '').toLowerCase().indexOf('.jpg') >= 0 ||
        (img || '').toLowerCase().indexOf('.jpeg') >= 0 ||
        (img || '').toLowerCase().indexOf('.png') >= 0
    },
    esPdf(img) {
      return (img || '').toLowerCase().indexOf('.pdf') >= 0
    },
    async guardar() {
      // Si no hay un gasto, guardarlo
      if (this.gastoetapa2.idGasto == '') {
        let presupuestogasto = await Api.save('presupuestogasto', {
          idAutor: this.yo._id,
          idPresupuesto: this.gastoetapa2.idPresupuesto,
          idProveedor: this.gastoetapa2.idProveedor,
          nombre: this.gastoetapa2.nombre,
          fecha: new Date().getTime(),
          estado: 'aprobado',
          cantidadCotizada: this.gastoetapa2.cantidadErogable,
          limiteGasto: this.gastoetapa2.cantidadErogable,
          rubro: this.gastoetapa2.rubro || 'Pagos directos',
          aprobado: 1
        })
        this.gastoetapa2.idGasto = presupuestogasto._id
      }
      // Guardar
      this.$forceUpdate()
      if (!this.bloquearGuardar()) {
        let ge = await Api.save('gastoetapa', this.gastoetapa2)
        console.log("gastoetapa", ge)
        alert("Pago terminado")
        await Api.wait(500)
        this.cerrar()
      } else {
        alert("No se ha guardado el pago")
      }
    },
    bloquearGuardar() {
      let vars = ['nombre', 'idPresupuesto', 'cantidadErogable']
      let bloquear = false
      for (let var1 of vars)
        if (!(this.gastoetapa2[var1] && this.gastoetapa2[var1] != ''))
          bloquear = true
      console.log("bloquearGuardar?", bloquear)
      return bloquear
    },
    async enviarCorreos() {
      try {
        let destinatarios = []
        destinatarios.push(this.yo.email)
        await Emailer.enviar({
          titulo,
          destino: destinatarios.join(','),
          cuerpo1: 'El pago ha sido realizado',
          cuerpo2: '',
        })
        alert("Correos enviados")
      } catch (e) {
        console.error(e)
      }
      return Promise.resolve(true)
    },
    async subir(id) {
      let u = await Api.upload(id)
      this.gastoetapa2[id] = u
      if (id == 'archivoAdjuntoXml') try {
        alert("Procesando XML")
        let f = await Api.upload(id, 'binario')
        let obj = Xml.aObjeto(f)
        console.log("obj?", obj)
        this.gastoetapa2.cantidadErogable = parseFloat(obj['cfdi:Comprobante']['@Total'])
        let nombre = ''
        let conceptos = (obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'].length) ?
          obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'] : [obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto']]
        console.log("conceptos?", conceptos)
        nombre = conceptos
          .map(u => u['@Descripcion'])
          .join('; ')
          .replace(/%20/g, ' ')
          .replace(/Ã¡/g, 'á')
          .replace(/É/g, "é")
          .replace(/Ã©/g, "é")
          .replace(/Ã­/g, 'í')
          .replace(/Ã³/g, 'ó')
          .replace(/%2C/g, ',')
          .replace(/%24/g, "\$")
          .replace(/%28/g, "(")
          .replace(/%29/g, ")")
          .replace(/Ã±/g, 'ñ')
        //.toUpperCase()
        this.gastoetapa2.nombre = nombre
        this.gastoetapa2.fechaProgramado = new Date(obj['cfdi:Comprobante']['@Fecha']).getTime()
        this.gastoetapa2.fechaEjecutada = new Date(obj['cfdi:Comprobante']['@Fecha']).getTime()
        this.gastoetapa2.idProveedor = await this.comprobarProveedor(obj['cfdi:Comprobante']['cfdi:Emisor'])
        this.gastoetapa2.cfdiUuid = obj['cfdi:Comprobante']['cfdi:Complemento']['tfd:TimbreFiscalDigital']['@UUID'].toUpperCase().replace(/-/g, '')
        console.log("Gasto nuevo", this.gastoetapa2)
      } catch (e) {
        alert("No se pudo procesar el XML");
        console.error("EXML", e)
      }
      alert("Archivo subido: " + u)
    },
    async comprobarProveedor(prov) {
      console.log("comprobarProveedor", prov)
      let rfcProveedor = prov['@Rfc'].trim().toUpperCase()
      let idProveedor = ''
      for (let pr of this.proveedores)
        if (rfcProveedor == pr.rfc)
          idProveedor = pr._id
      if (idProveedor != '')
        return Promise.resolve(idProveedor)
      else {
        let pr = {
          nombre: prov['@Nombre'],
          rfc: prov['@Rfc'],
          email: (prov['@Rfc']).toLowerCase() + '@local',
          password: Math.random(),
          rol: 'proveedor',
          fechaAlta: new Date().getTime()
        }
        if (true == confirm('¿Desea dar de alta al proveedor ' + pr.nombre + '?')) {
          pr = await Api.save('usuario', pr)
          await Api.wait(1000)
          this.proveedores = await Api.find('usuario', ['rol,eq,proveedor'])
          return Promise.resolve(pr._id)
        } else
          return Promise.resolve('')
      }
    },
    async configurarRubros(idPresupuesto) {
      this.rubros = []
      if (idPresupuesto && idPresupuesto.length)
        try {
          console.log("Obteniendo rubros presupuesto", idPresupuesto)
          let presupuesto = await Api.get('presupuesto', idPresupuesto)
          this.rubros = presupuesto.rubros
          this.$forceUpdate()
        } catch (e) {
          console.error("ERUBROS", e)
          alert("No se pudieron obtener rubros del presupuesto")
        }
    },
    cerrar() {
      Api.$emit('cerrarModalPagoDirecto')
    }
  },
  watch: {
    'gastoetapa2.idPresupuesto': function(a, b) {
      this.configurarRubros(a)
    }
  },
  async mounted() {
    this.presupuestos = await Api.get('presupuesto')
    this.proveedores = await Api.find('usuario', ['rol,eq,proveedor'])
    //if (this.id.length == 24) this.obtener()
    console.log("ModalPagoDirecto pre gastoetapa", this.gastoetapa)
    if (this.gastoetapa && this.gastoetapa._id) {
      this.gastoetapa2 = this.gastoetapa
    } else {
      alert("Puede subir el XML directamente", 3000)
    }
    // Buscar el ultimo presupuesto usado en un pago directo para asignarlo automaticamente
    if (!(this.gastoetapa2.idPresupuesto && this.gastoetapa2.idPresupuesto != '')) {
      console.log("Buscando el último presupuesto")
      let ultimogasto = await Api.find('gastoetapa', ['fecha,gte,' + new Date().getTime() - (1000 * 60 * 60 * 24 * 30)], {
        sort: ['fecha,-1'],
        limit: 1
      })
      if (ultimogasto && ultimogasto[0] && this.presupuestos.map(u => u._id).indexOf(ultimogasto[0]._id) >= 0) {
        this.gastoetapa2.idPresupuesto = ultimogasto[0].idPresupuesto
      } else {
        this.gastoetapa2.idPresupuesto = this.presupuestos[0]._id
        console.log("Asignando el primer presupuesto disponible", this.gastoetapa2.idPresupuesto)
      }
    }
    this.cargando = false
    this.$forceUpdate()
    console.log("ModalPagoDirecto", this.gastoetapa2)
  }
}
</script>
