<template>
  <div class="ion-page" main>
    <ion-header>
      <ion-toolbar :color="colors('danger')">
        <ion-title>Catalogos - Usuarios ({{ usuariosTodos.length }})</ion-title>
        <ion-buttons slot="start">
          <ion-button @click="openEnd">
            <i class="icon ion-ios-menu"></i>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click="cerrarTodasLasSesiones">
            <i class="icon ion-ios-lock"></i>
          </ion-button>
          <ion-button @click="csv" v-if="yo.rol == 'administrador'">
            <i class="icon ion-ios-download"></i>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-progress-bar
        :value="cargados / cargables"
        v-if="cargables > 0"
      ></ion-progress-bar>
    </ion-header>
    <ion-content class="content">
      <ion-searchbar
        placeholder="Filtrar por nombre o correo"
        debounce="500"
        :value="filtro"
        v-on:ionChange="filtrar($event.detail.value)"
      ></ion-searchbar>
      <ion-list id="lista">
        <ion-item-sliding v-for="u in usuarios" :key="u._id">
          <ion-item v-on:click="ver(u._id)">
            <ion-label text-wrap :color="u.desactivado ? 'warning' : ''">
              <h2>{{ u.nombre }}</h2>
              <p text-capitalize>{{ u.tipo }} {{ u.areaDefault }}</p>
            </ion-label>
            <ion-note slot="end">{{ u.email }}</ion-note>
          </ion-item>
          <ion-item-options slot="end">
            <ion-item-option
              :color="colors('light')"
              v-on:click="entrarComo(u)"
              v-if="yo.rol == 'administrador'"
            >
              <i class="icon icon-ios-log-in"></i>&nbsp;Entrar como
            </ion-item-option>
            <ion-item-option
              :color="colors('light')"
              v-on:click="cerrarSesion(u)"
              v-if="yo.rol == 'administrador'"
            >
              <i class="icon icon-ios-log-out"></i>&nbsp;Cerrar sesión
            </ion-item-option>
            <ion-item-option
              :color="colors('danger')"
              v-on:click="eliminar(u._id)"
              v-if="permiso('BorrarUsuarios')"
            >
              <i class="icon icon-ios-trash"></i> Eliminar
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </ion-list>
      <ion-fab
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        v-if="permiso('CrearUsuarios')"
      >
        <ion-fab-button :color="colors('danger')" v-on:click="ver(0)">
          <i class="icon icon-lg ion-ios-add"></i>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </div>
</template>

<script>
import Api from "@/components/Api";
import Usuario from "@/models/Usuario";
export default {
  name: "CatalogoUsuarios",
  data() {
    return {
      yo: Api.obtenerLocal("elite.yo") || {},
      usuarios: [],
      usuariosTodos: [],
      filtro: "",
      cargados: 0,
      cargables: 0,
    };
  },
  methods: {
    openEnd() {
      Api.$emit("open-menu");
    },
    pos() {
      this.$router.push("pos");
    },
    async obtener() {
      this.usuariosTodos = await Usuario.lista(null, true, true);
      console.log("Objetos", this.usuariosTodos.length);
      /*
      if (true) {
        this.usuariosTodos = this.usuariosTodos
          .map(u => {
            u.nombre = u.nombre.substring(0, u.nombre.indexOf(' ') + 2)
            if (u.email.endsWith('elite.mx'))
              u.email = u.email.substring(0, u.email.indexOf('.') + 2) + '@ittoni.xyz'
            return u
          })
        for (let u of this.usuariosTodos)
          await Api.save('usuario', u)
        alert("Terminado")
      }
      //*/
      this.filtrar(this.filtro);
    },
    filtrar(val) {
      console.log("Filtrando", val);
      val = (val || "").toLowerCase();
      let vm = this;
      this.filtro = val;
      if (val == "") {
        console.log(
          "Devolviendo todos los usuarios: ",
          this.usuariosTodos.length
        );
        this.usuarios = JSON.parse(JSON.stringify(this.usuariosTodos));
      } else {
        this.usuarios = this.usuariosTodos.filter((cl) => {
          return (
            (cl.nombre || "").toLowerCase().indexOf(val) >= 0 ||
            (cl.email || "").toLowerCase().indexOf(val) >= 0 ||
            vm.tienePermiso(cl, val)
          );
        });
      }
      if (val != "") console.table(JSON.parse(JSON.stringify(this.usuarios)));
    },
    tienePermiso(cliente, permiso) {
      if (cliente.rol == "administrador") {
        console.log("Es administrador", cliente.nonbre);
        return true;
      } else {
        let clienteTienePermiso = false;
        let permisosPosibles = [];
        for (let prop in cliente.permisos || {})
          if (typeof cliente.permisos[prop] == "boolean")
            permisosPosibles.push(prop);
        console.log("Permisos posibles?", cliente.nombre, permisosPosibles);
        for (let permisoPosible of permisosPosibles)
          if (
            permisoPosible.toLowerCase().indexOf(permiso.toLowerCase()) >= 0 &&
            (cliente.permisos || {})[permisoPosible] == true
          ) {
            console.log("Permiso encontrado", cliente.nombre, permisoPosible);
            clienteTienePermiso = true;
          }
        return clienteTienePermiso;
      }
    },
    ver(id) {
      this.$router.push("/catalogos/usuario/" + id);
    },
    async eliminar(id) {
      if (
        true ==
        confirm("Desea eliminar este elemento? Esto no se puede deshacer")
      ) {
        let u = await Api.delete("usuario", id);
        Api.borrarLocal("elite.usuario." + id);
        this.obtener();
        let lista = document.querySelector("#lista");
        if (lista) await lista.closeSlidingItems();
      }
    },
    entrarComo(u) {
      if (true == confirm("¿Desea entrar como " + u.nombre + "?")) {
        Api.guardarLocal("elite.yo", u);
        this.$router.push("/");
        window.location.reload();
      }
    },
    cerrarSesion(usuario, noPreguntar) {
      let vm = this;
      noPreguntar = noPreguntar && true;
      return new Promise(async (resolve, reject) => {
        if (!noPreguntar)
          noPreguntar =
            true ==
            confirm("Desea cerrar la sesión de " + usuario.nombre + "?");
        if (noPreguntar) {
          // Procede
          let peticionesAnteriores = await Api.find("peticioncerrarsesion", [
            "idUsuario,eq," + usuario._id,
          ]);
          for (let peticion of peticionesAnteriores)
            await Api.delete("peticioncerrarsesion", peticion._id);
          console.log("Eliminadas peticiones anteriores ", usuario.nombre);
          await Api.save("peticioncerrarsesion", {
            idUsuario: usuario._id,
            fechaSolicitada: new Date().getTime(),
            idSolicita: vm.yo._id,
            fechaEjecutada: 0,
          });
          if (!noPreguntar) alert("Sesión de " + usuario.nombre + " cerrada");
          resolve(true);
        }
      });
    },
    async cerrarTodasLasSesiones() {
      if (true == confirm("Desea cerrar todas las sesiones?")) {
        let noPreguntar = true;
        this.cargados = 0;
        this.cargables = this.usuarios.length;
        for (let usuario of this.usuarios)
          if (usuario._id != this.yo._id) {
            await this.cerrarSesion(usuario, noPreguntar);
            this.cargados++;
          }
        this.cargables = 0;
        alert(
          "Todas las " +
            this.usuarios.length +
            " sesiones menos ésta han sido cerradas"
        );
      }
    },
    csv() {
      let txt = "";
      for (let u of this.usuarios) {
        let datos = [
          u.nombre,
          u.email,
          u.password,
          u.fechaAlta
            ? this.$options.filters.moment(new Date(u.fechaAlta), "YYYY-MM-DD")
            : "",
          u.fechaBaja
            ? this.$options.filters.moment(new Date(u.fechaBaja), "YYYY-MM-DD")
            : "",
        ];
        //console.log("D?", datos)
        let renglon = datos.join(",") + "\n\r";
        txt += renglon;
      }
      console.log("Txt?", txt.length);
      Api.downloadForce(txt, "elite.usuarios.csv");
    },
  },
  mounted() {
    this.obtener();
    let vm = this;
    setTimeout((_) => {
      vm.obtener();
    }, 1000);
  },
};
</script>

<style></style>
