<template>
<div>
  <ion-item>
    <ion-note slot="start" :color="color" v-if="!ocultarIcono && labelPosition!='floating'">
      <i class="icon ion-ios-contact"></i>
    </ion-note>
    <ion-label :color="color" :position="labelPosition" text-wrap>
      {{label}}
    </ion-label>
    <ion-input type="text" placeholder="Buscar por nombre" :class="labelPosition=='floating'?'':'ion-text-right'" :value="nombre" :debounce="debounce" v-on:ionChange="buscarCliente($event.detail.value)" :clearInput="true" />
  </ion-item>
  <ion-item v-for="cl in clientesFiltrados" :key="cl._id" v-on:click="buscarCliente(cl.nombre)" v-if="!devolverTodos">
    <ion-label>
      <h2>{{cl.nombre}}</h2>
    </ion-label>
  </ion-item>
</div>
</template>
<script>
import Api from '@/components/Api'
import Cliente from '@/models/Cliente'
export default {
  name: 'SelectorCliente',
  props: {
    idCliente: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Cliente'
    },
    color: {
      type: String,
      default: 'tertiary'
    },
    labelPosition: {
      type: String,
      default: ''
    },
    ocultarIcono: {
      type: Boolean,
      default: false
    },
    devolverTodos: {
      type: Boolean,
      default: false
    },
    debounce: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo'),
      clientes: [],
      clientesFiltrados: [],
      nombre: '',
      cliente: {}
    }
  },
  methods: {
    configurarCliente(cl) {
      console.log("$emit clienteSeleccionado", cl)
      Api.$emit('clienteSeleccionado', cl)
    },
    buscarCliente(nombre) {
      console.log("buscarCliente", nombre)
      nombre = (nombre || '').toUpperCase()
      if (nombre == '') {
        this.nombre = ''
        this.configurarCliente('')
      } else {
        let hayCoincidenciaExacta = false
        this.clientesFiltrados = this.clientes.filter(c => {
          if ((c.nombre || '').toUpperCase() == nombre)
            hayCoincidenciaExacta = true
          return (c.nombre || '').toUpperCase().indexOf(nombre) >= 0
        })
        if (hayCoincidenciaExacta) {
          console.log("Potencialmente hay una coincidencia exacta. Comprobando.")
          this.clientesFiltrados = this.clientes.filter(c => {
            return (c.nombre || '').toUpperCase() == nombre
          })
        }
        console.log("Clientes encontrados", this.clientesFiltrados)
        if (this.clientesFiltrados.length == 1) {
          if (nombre != this.nombre) {
            this.nombre = (this.clientesFiltrados[0].nombre || '').toUpperCase()
            this.cliente = this.clientesFiltrados[0]
            console.log("Cambiando campo 'nombre'", this.nombre)
          }
          if (this.idCliente != this.clientesFiltrados[0]._id)
            this.configurarCliente(this.clientesFiltrados[0]._id)
          console.log("Cliente unico asignado", this.nombre, nombre)
          this.$forceUpdate()
          this.clientesFiltrados = []
        } else if (true == this.devolverTodos)
          this.configurarCliente(this.clientesFiltrados)
      }
    },
    obtenerNombreInicialDeCliente() {
      console.log("obtenerNombreInicialDeCliente", this.idCliente)
      if (this.idCliente && this.idCliente != '') {
        for (let cliente of this.clientes)
          if (cliente._id == this.idCliente) {
            this.nombre = cliente.nombre
            this.cliente = cliente
            console.log("Convertido ID a nombre", this.idCliente, this.nombre)
            this.$forceUpdate()
          }
      }
    }
  },
  watch: {
    idCliente: function(a) {
      let vm = this
      setTimeout(_ => {
        if (vm.nombre == '' && vm.idCliente != '')
          vm.obtenerNombreInicialDeCliente()
      }, 500)
    }
  },
  async mounted() {
    console.log("SelectorCliente mounted", this.idCliente)
    this.clientes = await Cliente.lista()
    this.obtenerNombreInicialDeCliente()
    this.clientesFiltrados = []
  }
}
</script>
