<template>
<div class="reporteHolder">
  <ion-progress-bar v-if="cargando" type="indeterminate" />
  <ion-searchbar debounce="500" placeholder="Filtrar por cliente, folio, área..." :value="filtro" @ionChange="filtrar($event.detail.value)"></ion-searchbar>
  <ion-item lines="none">
    <ion-segment :value="modo" @ionChange="modo=$event.detail.value" :color="colors('secondary')">
      <ion-segment-button value="tabla">Tabla</ion-segment-button>
      <ion-segment-button value="pdf">PDF</ion-segment-button>
    </ion-segment>
  </ion-item>
  <div v-if="modo=='tabla'">
    <ion-item :color="colors('light')" lines="none">
      <ion-label>Asuntos {{asuntos.length}}</ion-label>
      <ion-button slot="end" fill="clear" @click="copiarTabla">
        <i class="icon ion-ios-copy"></i>
      </ion-button>
    </ion-item>
    <div class="tableHolder">
      <table id="tabla">
        <thead>
          <tr>
            <th>Fecha alta</th>
            <th>Área</th>
            <th>Asunto</th>
            <th>Actor</th>
            <th>Demandado</th>
            <th>Abogado</th>
            <th>Folio</th>
            <th>Juzgado</th>
            <th>Estado</th>
            <th>Minutos</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="a in asuntos" :key="a._id">
            <td class="text-uppercase">{{a.fecha | moment('MMM DD, YYYY')}}</td>
            <td>{{a.area}}</td>
            <td><a target="_blank" :href="'#/asunto/'+a._id">{{a.nombre||a.descripcion}}</a></td>
            <td>{{a.actor}}</td>
            <td>{{a.demandado}}</td>
            <td>{{(a.autor||{}).nombre}}</td>
            <td>{{a.folio}}</td>
            <td>{{a.juzgado}}</td>
            <td>{{a.estado||a.status}}</td>
            <td>{{a.minutos||0}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-if="modo=='pdf'" class="pdf">
    <!--ion-item lines="none">
      <ion-label class="ion-text-center" color="danger">
        <h2>Por implementar</h2>
      </ion-label>
    </ion-item-->
    <embed type="application/pdf" :src="pdf" />
  </div>
</div>
</template>
<style>
.reporteHolder {
  width: 100%;
}

.tableHolder {
  width: 100%;
  display: block;
  float: left;
  overflow-x: scroll;
}

.tableHolder>table {
  width: 100%;
}

.pdf embed {
  width: 100%;
  height: calc(100% - 90px);
}
</style>
<script>
import Api from '@/components/Api'
import Reporteable from '@/models/Reporteable'
import Pdfgen from '@/models/Pdfgen'
const yo = Api.obtenerLocal('elite.yo') || {}
export default {
  name: 'ReporteAsuntos',
  props: ['filtros', 'abogados', 'clientes', 'areas', 'unSoloAbogado'],
  data() {
    return {
      yo,
      modo: ['tabla', 'pdf'][0],
      cargando: false,
      asuntos: [],
      asuntosTodos: [],
      totalTiempo: 0,
      modoProduccion: Api.modoProduccion,
      filtro: '',
      pdf: 'https://tetra4d.com/wp-content/uploads/2017/02/Template_DesignChangeNotification_Template_Final.pdf'
    }
  },
  methods: {
    async obtener() {
      console.log("Obteniendo", this.filtros)
      this.cargando = true
      const usuarios = await Api.get('usuario')
      const clientes = await Api.get('cliente')
      let fI = new Date(this.filtros.fechaInicio).getTime()
      let fF = new Date(this.filtros.fechaFin).getTime()
      let filtros = [
        'fecha,gte,' + (fI + 10).toString(),
        'fecha,lt,' + (fF + 10).toString()
      ]
      if (yo.rol != 'administrador')
        filtros.push('idAutor,eq,' + yo._id)
      if (this.filtros.area && this.filtros.area != '')
        filtros.push('area,eq,' + this.filtros.area)
      const actividades = (await Api.find('actividad', filtros))
      let asuntos = (await Api.find('asunto', filtros))
        .sort((a, b) => a.fecha > b.fecha ? 1 : -1)
        .map(as => {
          as.autor = usuarios.filter(u => u._id == as.idAutor)[0] || {}
          as.cliente = clientes.filter(c => c.idCliente == as.idCliente) || [0] || {}
          as.actividades = actividades.filter(a => a.idAsunto == as._id)
          as.minutos = as.actividades.reduce((acum, obj) => acum + (obj.minutos || obj.minutosCliente), 0)
          return as
        })
        .filter(a => a.terminado != 1)
      console.log("asuntos?", asuntos.length, asuntos[0])
      this.asuntosTodos = asuntos
      this.filtrar('')
      this.cargando = false
      this.$forceUpdate()
    },
    filtrar(val = '') {
      val = val.toUpperCase()
      this.filtro = val
      console.log("filtrar", val, this.asuntosTodos.length)
      this.asuntos = this.asuntosTodos
        .filter(a => {
          if (val == '')
            return true
          let strBusqueda = ''
          const props = ['nombre', 'folio', 'juzgado', 'actor', 'demandado', 'status', 'area']
          strBusqueda += ((a.autor || {}).nombre) || ''
          strBusqueda += ((a.cliente || {}).nombre) || ''
          for (let p of props)
            strBusqueda += (a[p] || '')
          strBusqueda = strBusqueda.toUpperCase()
          return strBusqueda.indexOf(val.trim()) >= 0
        })
      this.generarPDF()
    },
    async generarPDF() {
      const modo = this.modo
      this.modo = 'tabla'
      const tabla = document.querySelector('#tabla')
      const base64 = true
      const opciones = {
        titulo: 'Reporte de asuntos',
        pageOrientation: 'landscape'
      }
      await Api.wait(100)
      try {
        const pdf = await Pdfgen.convertirTablaPdf(tabla, 'Asuntos', base64, opciones)
        console.log("generarPDF pdf?", pdf.length, pdf.substring(0, 50))
        this.pdf = 'data:application/pdf;base64,' + pdf
      } catch (e) {
        console.error(e)
        alert("No se pudo generar el PDF")
      }
    },
    copiarTabla() {
      function selectElementContents(el) {
        var body = document.body,
          range, sel;
        if (document.createRange && window.getSelection) {
          range = document.createRange();
          sel = window.getSelection();
          sel.removeAllRanges();
          try {
            range.selectNodeContents(el);
            sel.addRange(range);
          } catch (e) {
            range.selectNode(el);
            sel.addRange(range);
          }
        } else if (body.createTextRange) {
          range = body.createTextRange();
          range.moveToElementText(el);
          range.select();
        }
      }
      let tabla = document.querySelector('#tabla')
      selectElementContents(tabla)
      document.execCommand('copy')
      console.log("Copiado?", tabla.value)
      alert("Copiado al portapapees")
    }
  },
  async mounted() {
    console.log("ReporteAsuntos mounted")
    await this.obtener()
  }
}
</script>
