<template>
<ion-item v-if="modoSimple">
  <ion-label>
    Forma de pago
  </ion-label>
  <ion-select class="ion-text-right" :value="formaPago" v-on:ionChange="setFormaPago($event.detail.value)" interface="popover">
    <ion-select-option v-for="a in ['iguala','asunto','horas','probono','gratuito']" :key="a" text-capitalize>{{a}}</ion-select-option>
  </ion-select>
</ion-item>
<ion-card v-else>
  <ion-row>
    <ion-col no-padding>
      <ion-item :color="colors('light')" lines="none">
        <ion-label @click="descargar">
          <h2>Acuerdo de cobranza</h2>
          <p v-if="acuerdo.nombreArchivo && acuerdo.nombreArchivo!=''">{{acuerdo.nombreArchivo | quitarHash}}</p>
        </ion-label>
        <ion-select :value="acuerdo._id" v-on:ionChange="seleccionarAcuerdo($event.detail.value)">
          <ion-select-option v-for="(a,i) in acuerdos" :key="i" :value="a._id">{{a.fecha | moment('YYYY-MM-DD')}} {{i!=0?a.tipo:'NUEVO'}}</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
    <ion-col no-padding size="2">
      <ion-item :color="colors('light')" lines="none"> <label for="documento">
          <ion-button slot="end" :color="colors('success')" fill="outline">
            <i class="icon ion-ios-cloud-upload"></i>
          </ion-button>
          <input class="hidden" type="file" name="documento" id="documento" v-on:change="subirArchivo()" />
        </label>
        <label>
          <ion-button slot="end" :color="colors('danger')" v-if="yo.rol=='administrador'" fill="outline">
            <i class="icon ion-ios-trash"></i>
          </ion-button>
        </label>
      </ion-item>
    </ion-col>
  </ion-row>
  <div v-if="idAcuerdo=='' && acuerdo && acuerdo._id">
    <ion-item>
      <ion-label>Caduca el </ion-label>
      <ion-input class="ion-text-right" :color="colors('primary')" type="date" :value="acuerdo.fechaCaducidad" v-on:ionChange="acuerdo.fechaCaducidad=$event.detail.value"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Cobrar en base a</ion-label>
      <ion-select :value="acuerdo.tipo" v-on:ionChange="acuerdo.tipo=$event.detail.value">
        <ion-select-option v-for="i in ['Rol','Iguala','Abogado','Cliente','Asunto','Probono','Gratuito']" :key="i" :value="i" text-capitalize>{{i}}</ion-select-option>
      </ion-select>
    </ion-item>
    <div v-if="acuerdo.tipo=='Abogado'">
      <ion-list>
        <ion-item :color="colors('light')">
          <ion-label>
            <h2>Abogados incluídos</h2>
            <p>Los abogados no incluídos cobrarán su tarifa estándar</p>
          </ion-label>
          <ion-button slot="end" @click="agregarAbogado" size="large" fill="clear">
            <i class="icon ion-ios-add-circle"></i>
          </ion-button>
        </ion-item>
        <ion-row v-for="a in acuerdo.abogados" :key="a._id">
          <ion-col no-padding>
            <ion-item>
              <ion-label position="floating">Abogado</ion-label>
              <ion-select :value="a.idAbogado" v-on:ionChange="a.idAbogado=$event.detail.value">
                <ion-select-option v-for="a in abogados" :key="a._id" :value="a._id">{{a.nombre}}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col no-padding>
            <ion-item>
              <ion-label position="floating">Tarifa</ion-label>
              <ion-input class="ion-text-right" :value="a.tarifa" type="number" step="any" v-on:ionChange="a.tarifa=parseFloat($event.detail.value)"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-list>
    </div>
    <ion-item v-if="acuerdo.tipo=='Cliente'">
      <ion-label>Tarifa por hora</ion-label>
      <ion-input class="ion-text-right" :value="acuerdo.tarifa" type="number" step="any" v-on:ionChange="acuerdo.tarifa=parseFloat($event.detail.value)"></ion-input>
    </ion-item>
    <ion-item v-if="acuerdo.tipo=='Iguala'">
      <ion-label>Tarifa mensual</ion-label>
      <ion-input class="ion-text-right" :value="acuerdo.tarifa" type="number" step="any" v-on:ionChange="acuerdo.tarifa=parseFloat($event.detail.value)"></ion-input>
    </ion-item>
    <ion-item v-if="acuerdo.tipo=='Asunto'">
      <ion-label>Tarifa del asunto</ion-label>
      <ion-input class="ion-text-right" :value="acuerdo.tarifa" type="number" step="any" v-on:ionChange="acuerdo.tarifa=parseFloat($event.detail.value)"></ion-input>
    </ion-item>
  </div>
</ion-card>
</template>
<script>
import Api from '@/components/Api'
import Usuario from '@/models/Usuario'
export default {
  name: 'FormaPagoHandler',
  props: {
    idAsunto: {
      default: '',
      type: String
    },
    idCliente: {
      default: '',
      type: String
    },
    formaPago2: {
      default: '',
      type: String
    },
    otroModuloDebeGuardarAcuerdo: {
      default: false,
      type: Boolean
    },
    otroModuloAcuerdo: {
      default: function() {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo'),
      formaPago: '',
      modoSimple: false,
      idAcuerdo: '',
      acuerdos: [],
      asunto: {},
      acuerdo: {
        noGuardado: true,
        _id: Api.uuid(),
        tipo: 'Rol',
        idAsunto: [],
        fecha: new Date().getTime(),
        fechaCaducidad: this.$options.filters.moment(new Date().getTime() + (1000 * 60 * 60 * 24 * 365), 'YYYY-MM-DD'),
        abogados: []
      },
      abogados: [],
      intervalId: null
    }
  },
  filters: {
    quitarHash(str) {
      let separadores = str.split('.')
      let nuevosElem = separadores.slice(0, separadores.length - 1)
      return nuevosElem.join('.')
    }
  },
  methods: {
    async obtenerAcuerdos() {
      let acuerdos = (await Api.find('acuerdo', ['idCliente,eq,' + this.idCliente]))
      this.acuerdos = acuerdos.filter(a => {
        return a.idAsunto && a.idAsunto.length > 0 && typeof a.idAsunto != 'string' && a.idCliente && a.idCliente != ''
      })
      if (this.acuerdo.noGuardado)
        this.acuerdos.push(this.acuerdo)
      return Promise.resolve(true)
    },
    async obtener() {
      if (this.idAsunto) try {
        this.asunto = await Api.get('asunto', this.idAsunto)
        console.log("Asunto? (desde FormaPagoHandler)", this.asunto)
        this.acuerdo.idCliente = this.asunto.idCliente
      } catch (e) {
        console.error("No se pudo obtener el asunto", this.idAsunto)
        console.error(e)
      }
      await this.obtenerAcuerdos()
      console.log("Obteniendo", this.idAsunto)
      this.acuerdos = this.acuerdos.sort((a, b) => {
        return a.fecha < b.fecha ? 1 : -1
      })
      for (let acuerdo of this.acuerdos) {
        if (acuerdo.idAsunto.indexOf(this.idAsunto) >= 0) {
          console.log("Encontrado acuerdo en BD", acuerdo.tipo, acuerdo)
          this.acuerdo = acuerdo
        }
      }
      this.$forceUpdate()
      /*
      if (false)
        if (this.idAsunto && this.idAsunto != '') {
          let acuerdos = await Api.find('acuerdo',
            ['idAsunto,eq,' + this.idAsunto], {
              limit: 1
            })
          console.log("Obtenidos acuerdos candidatos", acuerdos)
          if (acuerdos && acuerdos[0]) {
            this.acuerdo = acuerdos[0]
            console.log("Obtenido acuerdo", this.acuerdo._id, this.acuerdo)
            this.$forceUpdate()
          }
        } //*/
      return Promise.resolve(true)
    },
    async seleccionarAcuerdo(id) {
      console.log("Seleccionando acuerdo", id)
      for (let acuerdo of this.acuerdos) {
        if (acuerdo._id == id) {
          this.acuerdo = acuerdo
          this.$forceUpdate()
        }
      }
      this.acuerdo.idAsunto.push(this.idAsunto)
      this.acuerdo.idAsunto = [...new Set(this.acuerdo.idAsunto)].filter(f => {
        return f.length == 24
      })
      console.log("Seleccionado acuerdo", this.acuerdo, this.acuerdo.idAsunto)
      // Remover el asunto del resto de los acuerdos
      for (let acuerdo of this.acuerdos) {
        console.log("Removiendo asunto de acuerdos", this.acuerdo._id, this.acuerdo.idAsunto)
        if (this.acuerdo._id != acuerdo._id) {
          if (acuerdo.idAsunto.indexOf(this.idAsunto)) {
            let asuntosIncluidos = []
            for (let asunto of acuerdo.idAsunto)
              if (asunto != id)
                asuntosIncluidos.push(asunto)
            console.log("Actualizando acuerdo", acuerdo.idAsunto)
            await Api.save('acuerdo', acuerdo)
          }
        }
      }
      await this.obtener()
    },
    setFormaPago(val) {
      this.formaPago = val
      Api.$emit('formaPagoCambiada', val)
      console.log("setFormaPago", val)
    },
    agregarAbogado() {
      this.acuerdo.abogados.push({
        idAbogado: '',
        tarifa: 0
      })
    },
    async subirArchivo() {
      let u = await Api.upload('documento')
      this.acuerdo.nombreArchivo = u
      this.$forceUpdate()
      console.log("subirArchivo", u)
      this.guardar()
    },
    async guardar() {
      console.log("Guardando acuerdo", this.acuerdo._id)
      if (this.acuerdo.noGuardado)
        delete this.acuerdo.noGuardado
      if (!this.acuerdo.idAsunto && this.idAsunto)
        this.acuerdo.idAsunto = this.idAsunto
      if (!this.acuerdo.idCliente && this.asunto && this.asunto.idCliente)
        this.acuerdo.idCliente = this.asunto.idCliente
      console.log("save?", this.acuerdo.idAsunto, this.idAsunto, this.acuerdo.nombreArchivo, this.acuerdo)
      if (this.acuerdo.idAsunto && this.acuerdo.idAsunto.length >= 0) {
        console.log("Guardable?", this.acuerdo)
        let u = null
        if (this.otroModuloAcuerdo && this.otroModuloAcuerdo._id)
          u = await Api.save('acuedo', this.otroModuloAcuerdo)
        else
          u = await Api.save('acuerdo', this.acuerdo)
      } else {
        console.log("Save error", this.acuerdo)
      }
    },
    acuerdoEnLista(id) {
      let enLista = false
      for (let a of this.acuerdos) {
        //console.log("Acuerdos?", a._id, a.tipo, a)
        if (a._id == id)
          enLista = true
      }
      return enLista
    },
    descargar() {
      let lnk = Api.cdn + '/' + this.acuerdo.nombreArchivo
      window.open(lnk, '_blank')
    }
  },
  watch: {
    formaPago2: function(a) {
      console.log("watch formaPago2", a)
      this.formaPago = a
    },
    idAsunto: function(a, b) {
      console.log("watch idAsunto", a, b)
      this.acuerdo.idAsunto = a
    }
  },
  async mounted() {
    console.log("FormaPagoHandler created", this.idAsunto, this.otroModuloDebeGuardarAcuerdo)
    await this.obtener()
    if (this.acuerdo && this.acuerdo._id)
      this.seleccionarAcuerdo(this.acuerdo._id)
    this.abogados = await Usuario.lista()
    if (!this.otroModuloDebeGuardarAcuerdo)
      this.intervalId = setInterval(this.guardar, 2500)
    else
      Api.$on('guardarFormaPago', this.guardar)
  },
  beforeDestroy() {
    Api.$off('guardarFormaPago')
    if (this.intervalId)
      clearInterval(this.intervalId)
  }
}
</script>
