<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Aprobaciones ({{filtro}}: {{suma | currency}})</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="obtener">
          <i class="icon ion-ios-refresh"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-item>
      <ion-label>Mostrar </ion-label>
      <ion-select :value="filtro" v-on:ionChange="cambiarFiltro($event.detail.value)">
        <ion-select-option>Todos</ion-select-option>
        <ion-select-option>Pendientes</ion-select-option>
        <ion-select-option>Pendientes con factura</ion-select-option>
        <ion-select-option>Aprobados</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-list>
      <ion-item-sliding v-for="p in aprobaciones" v-if="p && p.nombre" :key="p._id">
        <ion-item @click="aprobar(p)">
          <ion-note slot="start" :color="colors('success')">
            <i class="icon icon-lg ion-ios-checkmark-circle" v-if="p.aprobadaPorSocio"></i>
            <i class="icon icon-lg ion-ios-radio-button-off" v-else></i>
          </ion-note>
          <ion-label text-wrap v-if="p.nombre">
            <h2>{{p.nombre}}</h2>
            <p v-if="p.gasto">{{p.gasto.nombre}}</p>
            <p v-if="p.presupuesto">{{p.presupuesto.nombre}} &nbsp;
              <i class="icon ion-ios-attach" v-if="p.archivoAdjunto && p.archivoAdjunto!=''"></i>
              <i class="icon ion-ios-attach" v-if="p.archivoAdjuntoXml && p.archivoAdjuntoXml!=''"></i>
            </p>
            <p v-if="p.fechaSocioAprueba && p.socioAprueba">Aprobada el {{p.fechaSocioAprueba | moment('MMM DD')}} por {{p.socioAprueba.nombre}}</p>
            <!--pre>{{p}}</pre-->
          </ion-label>
          <ion-label v-else text-wrap class="ion-text-left">
            <pre>{{p}}</pre>
          </ion-label>
          <ion-note slot="end">{{p.cantidadErogable | currency}}</ion-note>
        </ion-item>
        <ion-item-options slot="end">
          <ion-item-option @click="verEsquemaOrigen(p)">Ir al esquema de pagos</ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
    <!--ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button href="#/presupuestos/propuestas/0">
        <i class="icon icon-lg ion-ios-add"></i>
      </ion-fab-button>
    </ion-fab-->
  </ion-content>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
import ModalGastoAprobacion from '@/components/Presupuestos/ModalGastoAprobacion'
export default {
  name: 'PresupuestosAprobaciones',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      aprobaciones: [],
      filtro: 'Pendientes con factura',
      suma: 0,
      modal: null
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    cambiarFiltro(val) {
      this.filtro = val
      this.obtener()
    },
    async obtener() {
      this.suma = 0
      let etapas = await Api.get('gastoetapa')
      let filtro = this.filtro
      etapas = etapas
        .filter(e => {
          switch (filtro) {
            case 'Todos':
            case 'Pendientes':
              return !e.aprobadaPorSocio
              break
            case 'Pendientes con factura':
              return !e.aprobadaPorSocio && e.archivoAdjunto && e.archivoAdjuntoFecha
              break
            case 'Aprobados':
              return e.aprobadaPorSocio //&& e.idSocioAprueba
              break
            default:
              return true
              break
          }
        })
        .sort((a, b) => {
          return a.fechaLimite > b.fechaLimite ? 1 : -1
        })
        .sort((a, b) => {
          return a.fecha > b.fecha ? 1 : -1
        })
      for (let e of etapas)
        this.suma += parseFloat(e.cantidadErogable)
      let gastos = await Api.get('presupuestogasto')
      let presupuestos = await Api.get('presupuesto')
      let usuarios = await Api.get('directorio')
      for (let id in etapas) {
        for (let g of gastos) {
          for (let p of presupuestos) {
            if (p._id == g.idPresupuesto && g._id == etapas[id].idGasto) {
              etapas[id].gasto = g
              etapas[id].presupuesto = p
              if (etapas[id].idUsuarioAprueba)
                for (let u of usuarios) {
                  if (etapas[id].idUsuarioAprueba == u._id)
                    etapas[id].socioAprueba = u
                }
            }
          }
        }
      }
      this.aprobaciones = etapas
        .filter(e => e.presupuesto && e.gasto && e.gasto.estado.toLowerCase().indexOf('aprobad') >= 0)
      //.filter(e => e.presupuesto && e.gasto)
      this.$forceUpdate()
      console.log("aprobaciones", this.aprobaciones, gastos, presupuestos, this.aprobaciones.map(e => e.gasto))
    },
    verEsquemaOrigen(p) {
      console.log("verEsquemaOrigen?", p)
      this.$router.push('/presupuestos/gastos/' + p.idGasto)
    },
    async aprobar(gasto) {
      let vm = this
      console.log("modalEtapaGasto", this.etapa)
      return this.$ionic.modalController
        .create({
          component: ModalGastoAprobacion,
          swipeToClose: true,
          componentProps: {
            parent: vm,
            propsData: {
              etapa: gasto
            }
          }
        })
        .then(m => {
          vm.modal = m
          console.log("modalEtapaGasto", vm.modal)
          vm.modal.present()
          vm.modal.onDidDismiss(_ => {
            console.log("Modal cerrado")
            vm.obtener()
          })
        })
    }
  },
  mounted() {
    console.log("Presupuestosaprobaciones mounted")
    this.obtener()
    let vm = this
    Api.$on('cerrarModalGastoEtapa', _ => {
      vm.modal.dismiss()
      vm.obtener()
    })
  },
  beforeDestroy() {
    Api.$off('cerrarModalGastoEtapa')
  }
}
</script>
