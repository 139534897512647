<template>
  <div class="ion-page" main>
    <ion-header>
      <ion-toolbar :color="colors('secondary')">
        <!--ion-buttons slot="end">
        <ion-button @click="obtener(true)">
          <i class="icon ion-ios-information-circle-outline"></i>&nbsp;Modo demo
        </ion-button>
      </ion-buttons-->
        <ion-title>Resumen</ion-title>
        <ion-buttons slot="start">
          <ion-button @click="openEnd">
            <i class="icon ion-ios-menu"></i>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="content">
      <ion-row>
        <ion-col class="ion-no-padding">
          <ion-item>
            <ion-label>Ver mes de</ion-label>
            <ion-input
              type="month"
              :color="colors('primary')"
              class="ion-text-right"
              debounce="1000"
              :value="filtros.fechaInicio"
              v-on:ionChange="configurarFechaInicio($event.detail.value)"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col class="ion-no-padding">
          <ion-item>
            <ion-label>Mostrar área</ion-label>
            <ion-select
              :value="filtros.area"
              v-on:ionChange="
                filtros.area = $event.detail.value;
                configurarFechaInicio(filtros.fechaInicio);
              "
            >
              <ion-select-option :value="''">TODAS</ion-select-option>
              <ion-select-option
                v-for="a in areas"
                :value="a.nombre"
                :key="a.nombre"
                >{{ a.nombre }}</ion-select-option
              >
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-segment :value="modo" @ionChange="modo = $event.detail.value">
              <ion-segment-button :value="'general'"
                >Vista general</ion-segment-button
              >
              <ion-segment-button :value="'porCliente'"
                >Actividades por cliente</ion-segment-button
              >
              <ion-segment-button :value="'porUsuario'"
                >Actividades por usuarios</ion-segment-button
              >
            </ion-segment>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-xs="12" size-lg="2" size-xl="3"></ion-col>
        <ion-col size-xs="12" size-lg="8" size-xl="6" v-if="modo == 'general'">
          <ion-row no-padding>
            <ion-col
              no-padding
              v-if="mostrarResultados"
              size-sm="12"
              size-md="6"
              size-lg="6"
            >
              <div class="bloqueGrafico" no-padding>
                <HorasEfectivas
                  :filtros="filtros"
                  :clientes="clientes"
                  :abogados="abogados"
                  :areas="areas"
                  :modoGrafico="true"
                  :numClientes="15"
                />
              </div>
            </ion-col>
            <ion-col
              no-padding
              v-if="
                (mostrarIndiceReporteo || yo.rol == 'administrador') &&
                mostrarResultados
              "
              size-sm="12"
              size-md="6"
              size-lg="6"
            >
              <div class="bloqueGrafico" no-padding>
                <IndiceReporteo
                  :filtros="filtros"
                  :clientes="clientes"
                  :abogados="abogados"
                  :areas="areas"
                />
              </div>
            </ion-col>
            <!--ion-col
              no-padding
              v-if="mostrarResultados"
              size-sm="12"
              size-md="6"
              size-lg="6"
            >
              <div class="bloqueGrafico" no-padding>
                <HorasEfectivas
                  :filtros="filtros"
                  :clientes="clientes"
                  :abogados="abogados"
                  :areas="areas"
                  :modoGrafico="true"
                  :menosEfectivos="true"
                />
              </div>
            </ion-col-->
            <ion-col
              no-padding
              v-if="mostrarResultados && empresa.sucursalMexico"
              size-sm="12"
              size-md="6"
              size-lg="6"
            >
              <div class="bloqueGrafico" no-padding>
                <IndiceReporteo
                  :filtros="filtros"
                  :clientes="clientes"
                  :abogados="abogados"
                  :areas="areas"
                  :adscritoMexico="true"
                />
              </div>
            </ion-col>
            <ion-col
              no-padding
              v-if="mostrarResultados"
              size-sm="12"
              size-md="6"
              size-lg="6"
            >
              <div class="bloqueGrafico" no-padding>
                <HorasPorCliente
                  :filtros="filtros"
                  :clientes="clientes"
                  :abogados="abogados"
                  :areas="areas"
                  :modoGrafico="true"
                  :numClientes="15"
                />
              </div>
            </ion-col>
            <ion-col
              no-padding
              v-if="mostrarResultados && filtros.area == ''"
              size-sm="12"
              size-md="6"
              size-lg="6"
            >
              <div class="bloqueGrafico" no-padding>
                <ChartActividadesPorArea
                  :filtros="filtros"
                  :clientes="clientes"
                  :abogados="abogados"
                  :areas="areas"
                  :modoGrafico="true"
                />
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-col
          size-xs="12"
          ssize-lg="8"
          ssize-xl="3"
          v-if="modo == 'porCliente'"
        >
          <DashboardPorCliente :year="filtros.fechaInicio.substring(0, 4)" />
        </ion-col>
        <ion-col
          size-xs="12"
          ssize-lg="8"
          ssize-xl="3"
          v-if="modo == 'porUsuario'"
        >
          <DashboardPorUsuario :year="filtros.fechaInicio.substring(0, 4)" />
        </ion-col>
      </ion-row>
    </ion-content>
  </div>
</template>
<style>
table {
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 5px;
}
</style>
<style>
.pdf {
  width: 100%;
  height: 90vh;
}

table {
  width: 100%;
}

th,
td {
  padding: 5px;
}

.bloqueGrafico {
  /*max-height: 40vh;*/
}
</style>
<script>
import Api from "@/components/Api";
import Usuario from "@/models/Usuario";
import Cliente from "@/models/Cliente";
import HorasPorCliente from "@/components/Reportes/HorasPorCliente";
import HorasEfectivas from "@/components/Reportes/HorasEfectivas";
import IndiceReporteo from "@/components/Reportes/IndiceReporteo";
import ChartActividadesPorArea from "@/components/Reportes/ChartActividadesPorArea";
import DashboardPorCliente from "./DashboardPorCliente";
import DashboardPorUsuario from "./DashboardPorUsuario";
export default {
  name: "Dashboard",
  components: {
    HorasPorCliente,
    HorasEfectivas,
    IndiceReporteo,
    ChartActividadesPorArea,
    DashboardPorCliente,
    DashboardPorUsuario,
  },
  data() {
    return {
      yo: Api.obtenerLocal("elite.yo") || {},
      empresa: Api.obtenerLocal("elite.empresa") || {},
      modo: Api.obtenerLocal("elite.dashboardModo") || "general",
      abogados: [],
      clientes: [],
      corporativos: [],
      areas: [],
      filtros: {
        abogados: "Todos",
        clientes: "Todos",
        graficarPor: "cliente",
        porRango: "mes",
        asuntos: [],
        //fechaInicio: this.$options.filters.moment(new Date().getFullYear() + '-01-01', 'YYYY-MM-DD'),
        fechaInicio: this.$options.filters.moment(
          new Date().getTime() - 1000 * 60 * 60 * 24 * 7,
          "YYYY-MM"
        ),
        fechaFin: this.$options.filters.moment(
          new Date().getTime(),
          "YYYY-MM-DD"
        ),
        area: "",
      },
      filtrosMostrables: [],
      asuntos: [],
      actividades: [],
      actividadesTodas: [],
      totalMinutos: 0,
      totalMonto: 0,
      actividadPromedio: 0,
      asuntoPromedio: 0,
      tipoReporte: "misactividades",
      tiposReportes: [
        {
          name: "misactividades",
          label: "Mis actividades",
          filtrosMostrables: ["fechaInicio", "fechaFin"],
        },
        {
          name: "cliente",
          label: "Actividades por cliente",
          filtrosMostrables: ["fechaInicio", "fechaFin", "idCliente"],
        },
        {
          name: "corporativo",
          label: "Actividades por corporativo",
          filtrosMostrables: ["fechaInicio", "fechaFin", "idCorporativo"],
        },
        {
          name: "usuario",
          label: "Actividades por usuario",
          filtrosMostrables: ["fechaInicio", "fechaFin", "idAbogado"],
        },
        {
          name: "clientehoras",
          label: "Horas por cliente",
          filtrosMostrables: ["fechaInicio", "fechaFin", "idAbogado"],
        },
        {
          name: "usuariohoras",
          label: "Horas por usuario",
          filtrosMostrables: ["fechaInicio", "fechaFin"],
        },
        {
          name: "usuariohorasefectivas",
          label: "Horas efectivas por usuario",
          filtrosMostrables: ["fechaInicio", "fechaFin"],
        },
        {
          name: "reporteo",
          label: "Reporteo",
          filtrosMostrables: [],
        },
        {
          name: "actividadesporarea",
          label: "Actividades por área",
          filtrosMostrables: ["fechaInicio", "fechaFin"],
        },
      ],
      mostrarResultados: false,
      mostrarIndiceReporteo: false,
      esMovil: Api.esMovil(),
    };
  },
  watch: {
    modo: function (a) {
      Api.guardarLocal("elite.dashboardModo", a);
    },
  },
  methods: {
    openEnd() {
      Api.$emit("open-menu");
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    configurarFechaInicio(val, noObtener) {
      noObtener = noObtener && true;
      let porMes = true;
      this.filtros.fechaInicio = val;
      this.mostrarResultados = false;
      console.log("Config filtro", val, porMes);
      if (porMes) {
        this.filtros.fechaInicio = val;
        let fechaFin = new Date(
          new Date(this.filtros.fechaInicio).getTime() +
            1000 * 60 * 60 * 24 * 35
        );
        fechaFin.setDate(0);
        fechaFin.setHours(23);
        fechaFin.setMinutes(59);
        fechaFin.setSeconds(59);
        fechaFin.setMilliseconds(999);
        this.filtros.fechaFin = this.$options.filters.moment(
          fechaFin,
          "YYYY-MM-DD"
        );
        console.log(
          "Filtro por mes",
          this.filtros.fechaInicio,
          this.filtros.fechaFin
        );
        Api.$emit("filtrosCambiados", this.filtros);
        Api.guardarLocal("elite.dashboardFechaInicio", val);
        let vm = this;
        if (!noObtener)
          setTimeout((_) => {
            vm.mostrarResultados = true;
            vm.obtener();
          }, 1000);
      }
    },
    configurarFiltros(f) {
      this.filtros = f;
      console.log("$on configurarFiltros", this.tipoReporte, f);
    },
    seleccionarTipoReporte(tipo) {
      for (let t of this.tiposReportes) {
        if (t.name == tipo) {
          this.tipoReporte = t.name;
          this.filtrosMostrables = t.filtrosMostrables;
          console.log("Seleccionado", t.name, t);
        }
      }
    },
    async obtener(modoDemo = false) {
      console.log("Obteniendo", this.tipoReporte);
      this.mostrarResultados = false;
      if (modoDemo == true) alert("Obteniendo datos en modo demo");
      // Catalogos
      if (this.abogados.length == 0) this.abogados = await Usuario.lista();
      if (this.clientes.length == 0) this.clientes = await Cliente.lista();
      if (this.areas.length == 0) this.areas = await Api.get("area");
      if (this.corporativos.length == 0)
        this.corporativos = await Api.get("corporativo");
      //
      if (this.tipo == "dashboard") {
        let filtros = [
          "fechaProgramada,gte," + new Date(this.filtros.fechaInicio).getTime(),
          "fechaProgramada,lte," +
            (new Date(this.filtros.fechaFin).getTime() + 1000 * 60 * 60 * 23),
        ];
        console.log("Buscando", filtros, this.filtros);
        let actividades = await Api.find("actividad", filtros);
        //let actividades = await Api.get('actividad')
        console.log("Actividades filtrables", actividades);
        actividades = actividades
          .sort((a, b) => {
            return a.fechaProgramada > b.fechaProgramada ? 1 : -1;
          })
          //*
          .filter((a) => {
            return (
              this.filtros.abogados.indexOf(a.idAbogado) >= 0 ||
              this.filtros.abogados.indexOf("Todos") >= 0
            );
          }) //*/
          //*
          .filter((a) => {
            return (
              this.filtros.clientes.indexOf(a.idCliente) >= 0 ||
              this.filtros.clientes.indexOf("Todos") >= 0
            );
          }) //*/
          .map((act) => {
            act.monto = 0;
            act.tarifa = 0;
            for (let ab of this.abogados)
              if (act.idAbogado == ab._id) act.abogado = ab;
            if (act.abogado && act.abogado._id)
              for (let c of this.clientes) {
                if (act.idCliente == c._id && c["tarifa_" + act.abogado.tipo])
                  act.tarifa = c["tarifa_" + act.abogado.tipo];
                if (act.idCliente == c._id) act.cliente = c;
              }
            act.monto = (parseFloat(act.minutos) / 60) * act.tarifa;
            return act;
          });
        this.actividades = actividades;
        console.log("Actividades encontrados", this.actividades);
        // Asuntos
        let asuntosIndices = [];
        for (let act of this.actividades)
          if (asuntosIndices.indexOf(act.idAsunto) == -1)
            asuntosIndices.push(act.idAsunto);
        this.asuntos = [];
        asuntosIndices = [...new Set(asuntosIndices)];
        console.log("Buscando asuntos", asuntosIndices);
        let asuntosTodos = await Api.get("asunto");
        let asuntosTodos2 = {};
        for (let a of asuntosTodos) asuntosTodos2[a._id] = a;
        for (let id of asuntosIndices)
          if (asuntosTodos2[id]) this.asuntos.push(asuntosTodos2[id]);
        this.asuntos = this.asuntos
          .sort((a, b) => {
            return a.descripcion > b.descripcion ? 1 : -1;
          })
          .map((a) => {
            a.incluido = true;
            return a;
          });
        console.log("Asuntos encontrados", this.asuntos);
        //
        this.actividades = this.actividades.map((act) => {
          act.asunto = asuntosTodos2[act.idAsunto] || {};
          return act;
        });
        //
        this.calcularTotales();
        this.$forceUpdate();
      }
      //
      let vm = this;
      setTimeout((_) => {
        vm.mostrarResultados = true;
      }, 1000);
    },
    incluirAsunto(id, incluir) {
      for (let i in this.asuntos)
        if (this.asuntos[i]._id == id) {
          this.asuntos[i].incluido = incluir;
          console.log("Incluyendo asunto?", this.asuntos[i]._id, incluir);
        }
      this.generarGrafico();
    },
    calcularTotales() {
      this.totalMonto = 0;
      this.totalMinutos = 0;
      for (let act of this.actividades) {
        //console.log("Sumando", act.monto, act.minutos)
        this.totalMonto += parseFloat(act.monto || 0);
        this.totalMinutos += parseFloat(act.minutos || 0);
      }
      this.actividadPromedio = this.totalMinutos / this.actividades.length;
      this.asuntoPromedio = this.totalMinutos / this.asuntos.length;
      this.$forceUpdate();
      this.generarGrafico();
    },
    randomColor() {
      var color = "#" + Math.floor(Math.random() * 16777216).toString(16);
      console.log("Unacceptaboooool!", color);
      if (color.length == 7) return color;
      else return this.randomColor();
    },
    generarGrafico() {
      this.chart.data.labels = [];
      console.log("Generando grafico por", this.filtros.graficarPor);
      this.mostrarResultados = false;
      if (this.filtros.graficarPor == "abogado") {
        this.chart.data.datasets = this.abogados.map((a) => {
          let b = {
            label: a.nombre,
            backgroundColor: this.randomColor(),
            data: [],
            id: a._id,
          };
          return b;
        });
        for (let act of this.actividades) {
          let f = act.fechaProgramada;
          f = this.$options.filters.moment(f, "MMM-DD");
          for (let ab in this.chart.data.datasets) {
            if (this.chart.data.datasets[ab].id == act.idAbogado) {
              this.chart.data.datasets[ab].data.push(act.minutos || 0);
              this.chart.data.labels.push(f);
            } else {
              this.chart.data.datasets[ab].data.push(0);
              this.chart.data.labels.push(f);
            }
          }
        }
        console.log("Chart!", this.chart.data.datasets[3], this.chart.data);
      } else {
        this.chart.data.datasets = this.clientes.map((c) => {
          let b = {
            label: c.nombre,
            backgroundColor: this.randomColor(),
            data: [],
            id: c._id,
          };
          return b;
        });
        for (let act of this.actividades) {
          let f = act.fechaProgramada;
          f = this.$options.filters.moment(f, "MMM-DD");
          for (let cl in this.chart.data.datasets) {
            if (this.chart.data.datasets[cl].id == act.idCliente) {
              this.chart.data.datasets[cl].data.push(act.minutos || 0);
              this.chart.data.labels.push(f);
            } else {
              this.chart.data.datasets[cl].data.push(0);
              this.chart.data.labels.push(f);
            }
          }
        }
      }
      let vm = this;
      setTimeout((_) => {
        vm.mostrarResultados = true;
        vm.$forceUpdate();
        console.log("Mostrando");
      }, 200);
    },
  },
  async mounted() {
    if (this.yo.reporta && this.yo.rol == "administrador")
      this.abogados = await Api.get("usuario");
    else {
      this.abogados = [this.yo];
      this.filtros.abogados = [this.yo._id];
    }
    this.clientes = await Api.get("cliente");
    let vm = this;
    setTimeout((_) => {
      vm.seleccionarTipoReporte("misactividades");
    }, 500);
    // Fecha de busqueda guardada en el dispositivo
    let fechaInicio = Api.obtenerLocal("elite.dashboardFechaInicio");
    if (fechaInicio) this.configurarFechaInicio(fechaInicio);
    // Trigger secuencia de busqueda
    this.configurarFechaInicio(this.filtros.fechaInicio);
    console.log("Reportes montado", this.tipoReporte);
    Api.$on("filtrosCambiados", this.configurarFiltros);
  },
  beforeDestroy() {
    Api.$off("filtrosCambiados");
  },
};
</script>

<style></style>
