<template>
<ion-row>
  <ion-col size="12" size-md="12" no-padding>
    <ion-list v-if="asuntos && asuntos.length>0">
      <ion-item-divider>Incluir asuntos</ion-item-divider>
      <ion-item v-for="a in asuntos" :key="a._id">
        <ion-checkbox mode="ios" :checked="a.incluido" v-on:ionChange="incluirAsunto(a._id,$event.detail.checked==true)" :color="colors('primary')" slot="start"></ion-checkbox>
        <ion-label text-wrap>
          <h2>{{a.descripcion}}</h2>
        </ion-label>
        <!--ion-note slot="end">{{a.fecha | moment('MMM DD')}}</ion-note-->
      </ion-item>
    </ion-list>
  </ion-col>
  <ion-col size="12" size-md="8" no-padding>
    <ion-row>
      <ion-col v-if="yo && yo.reportaCostos">
        <ion-item :color="colors('success')">
          <ion-label>
            <h1>{{totalMonto | currency}}</h1>
            <p>
              Ingreso generado
            </p>
          </ion-label>
        </ion-item>
      </ion-col>
      <ion-col>
        <ion-item :color="colors('primary')">
          <ion-label>
            <h1>{{totalMinutos/60 | currency('')}}</h1>
            <p>
              Horas productivas
            </p>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item :color="colors('secondary')">
          <ion-label>
            <h1>{{asuntoPromedio/60 | currency('')}}h</h1>
            <p>
              Asunto promedio
            </p>
            <p>
              {{asuntos.length}} asuntos
            </p>
          </ion-label>
        </ion-item>
      </ion-col>
      <ion-col>
        <ion-item :color="colors('tertiary')">
          <ion-label>
            <h1>{{actividadPromedio/60 | currency('')}}h</h1>
            <p>
              Actividad promedio
            </p>
            <p>
              {{actividades.length}} actividades
            </p>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <!--ion-card-->
    <ion-item-divider>Histograma de abogados</ion-item-divider>
    <chart-linea :chartdata="chart.data" :chartoptions="chart.options"></chart-linea>
    <!--/ion-card-->
    <!--ion-card-->
    <ion-item-divider>Detalles</ion-item-divider>
    <table>
      <thead>
        <tr>
          <th>
            Fecha
          </th>
          <th v-if="filtros.abogados.length>1">
            Abogado
          </th>
          <th v-if="filtros.clientes.length>1">
            Cliente
          </th>
          <th>Asunto</th>
          <th>
            Descripción
          </th>
          <th class="ion-text-right">
            Mins
          </th>
          <th class="ion-text-right" v-if="yo && yo.reportaCostos">
            Cant
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="t in actividades" :key="t._id">
          <td>
            {{t.fechaProgramada | moment('MMM DD YYYY')}}
          </td>
          <td v-if="filtros.abogados.length>1">
            <span v-if="t.abogado">
              {{t.abogado.nombre}}
            </span>
          </td>
          <td v-if="filtros.clientes.length>1">
            <span v-if="t.cliente">
              {{t.cliente.nombre}}
            </span>
          </td>
          <td>
            <span v-if="t.asunto">{{t.asunto.nombre}} {{t.asunto.descripcion}}</span>
          </td>
          <td>
            {{t.descripcion}}
          </td>
          <td class="ion-text-right">
            {{t.minutos/60 | currency('')}}
          </td>
          <td class="ion-text-right" v-if="yo && yo.reportaCostos">
            {{(t.monto) | currency}}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>TOTAL</td>
          <td v-if="filtros.abogados.length>1"></td>
          <td v-if="filtros.clientes.length>1"></td>
          <td></td>
          <td class="ion-text-right">
            {{totalMinutos/60 | currency('')}}
          </td>
          <td class="ion-text-right" v-if="yo && yo.reportaCostos">
            {{totalMonto | currency}}
          </td>
        </tr>
      </tfoot>
    </table>
    <!--/ion-card-->
  </ion-col>
</ion-row>
</template>
<script>
import Api from '@/components/Api'
import Colores from '@/models/Colores'
export default {
  name: 'ReporteDashboard',
  props: ['totalMinutos', 'totalMonto', 'actividades', 'chart', 'asuntos', 'yo'],
  mounted() {
    console.log("ReporteDashboard")
  }
}
</script>
