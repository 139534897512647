<template>
  <div class="ion-page" main>
    <ion-header>
      <ion-toolbar :color="colors('secondary')">
        <ion-title>Catalogos - Areas</ion-title>
        <ion-buttons slot="start">
          <ion-button @click="openEnd">
            <ion-icon name="menu" :color="colors('light')"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="content">
      <ion-item-divider
        >Areas de práctica
        <ion-icon slot="end" name="refresh" v-on:click="obtener()"></ion-icon>
      </ion-item-divider>
      <ion-card v-if="area && area.nombre">
        <ion-row>
          <ion-col class="ion-no-padding">
            <ion-item>
              <ion-label>Nombre</ion-label>
              <ion-input
                :color="colors('primary')"
                class="ion-text-right"
                :value="area.nombre"
                v-on:ionChange="area.nombre = $event.detail.value"
              ></ion-input>
            </ion-item>
            <ion-item v-if="area._id">
              <ion-label>Mostrar en el sitio Web</ion-label>
              <ion-toggle
                slot="end"
                :color="colors('primary')"
                :checked="area.mostrarEnWeb"
                @ionChange="
                  area.mostrarEnWeb = $event.detail.checked == true;
                  $forceUpdate();
                "
              />
            </ion-item>
            <ion-item v-if="area.mostrarEnWeb == true">
              <ion-label>Descripción</ion-label>
              <ion-textarea
                rows="6"
                :color="colors('primary')"
                :value="area.descripcion"
                @ionChange="area.descripcion = $event.detail.value"
              />
            </ion-item>
            <ion-item v-if="area.mostrarEnWeb == true">
              <ion-label>Ícono (Font Awesome)</ion-label>
              <ion-textarea
                :color="colors('primary')"
                type="url"
                :value="area.icono"
                @ionChange="area.icono = $event.detail.value"
              />
            </ion-item>
            <label for="imagen" v-if="area.mostrarEnWeb == true">
              <input
                class="hidden"
                name="imagen"
                id="imagen"
                type="file"
                @change="subirImagen"
              />
              <ion-item>
                <ion-note slot="start" v-if="!area.imagen">
                  <i class="icon ion-ios-photo"></i>
                </ion-note>
                <ion-thumbnail slot="start" v-else>
                  <ion-img :src="cdn + area.imagen" />
                </ion-thumbnail>
                <ion-label
                  ><h2>Imagen</h2>
                  <p>Clic para subir o cambiar</p></ion-label
                >
              </ion-item>
            </label>
          </ion-col>
          <ion-col
            class="ion-no-padding"
            size="4"
            v-if="empresa.moduloPagosProveedores"
          >
            <ion-item lines="none">
              <ion-label text-wrap>Participa en gastos</ion-label>
              <ion-checkbox
                slot="end"
                :checked="area.participaEnGastos"
                v-on:ionChange="
                  area.participaEnGastos = $event.detail.checked == true
                "
              ></ion-checkbox>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6">
            <ion-button
              expand="block"
              :color="colors('secondary')"
              @click="guardar"
              size="small"
              v-if="
                permiso('EditarAreasDePractica') ||
                (permiso('CrearAreasDePractica') && !area._id)
              "
              >Guardar</ion-button
            >
          </ion-col>
          <ion-col>
            <ion-button
              expand="block"
              :color="colors('danger')"
              fill="outline"
              v-on:click="ver({})"
              size="small"
              >Cancelar</ion-button
            >
          </ion-col>
          <ion-col v-if="area._id" size="2">
            <ion-button
              expand="block"
              :color="colors('danger')"
              fill="outline"
              v-on:click="eliminar(area._id)"
              size="small"
              v-if="permiso('BorrarAreasDePractica')"
            >
              <i class="icon ion-ios-trash"></i>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-card>
      <ion-list v-if="areas && areas.length > 0">
        <ion-item v-for="a in areas" :key="a._id" v-on:click="ver(a)">
          <ion-label>
            <h2>{{ a.nombre }}</h2>
            <p>{{ a.icono }}</p>
          </ion-label>
          <ion-note color="medium" slot="end" v-if="a.participaEnGastos">
            Gastos</ion-note
          >
          <ion-note slot="end" v-if="a.icono">
            <i :class="a.icono"></i>
          </ion-note>
        </ion-item>
      </ion-list>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button
          :color="colors('secondary')"
          v-on:click="ver({ nombre: ' ' })"
        >
          <i class="icon ion-ios-add icon-lg"></i>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </div>
</template>

<script>
import Api from "@/components/Api";
export default {
  name: "CatalogoAreas",
  data() {
    return {
      yo: {},
      cdn: Api.cdn + "/",
      empresa: Api.obtenerLocal("elite.empresa") || {},
      areas: [],
      area: {},
    };
  },
  methods: {
    openEnd() {
      Api.$emit("open-menu");
    },
    async obtener() {
      this.areas = await Api.get("area");
      this.areas = await Api.get("area");
      console.log("Objetos", this.areas);
    },
    ver(area) {
      this.area = area;
      console.log("Ver", this.area);
      setTimeout(this.$forceUpdate, 500);
    },
    async guardar() {
      this.area.nombre = this.area.nombre.trim();
      if (!this.area.nombre || this.area.nombre == "") alert("Faltan datos");
      else {
        await Api.save("area", this.area);
        alert("Guardado");
        if (this.area.mostrarEnWeb == true)
          await Api.save("areapublic", this.area);
        else await this.eliminarAreaWeb();
        this.area = {};
        setTimeout(this.obtener, 1500);
      }
    },
    eliminar(id) {
      if (
        true ==
        confirm("Desea eliminar este elemento? Esto no se puede deshacer")
      ) {
        Api.delete("area", id).then(() => {
          this.area = {};
          this.eliminarAreaWeb();
          setTimeout(this.obtener, 1000);
        });
      }
    },
    async eliminarAreaWeb() {
      console.error("Eliminando area Web", this.area._id);
      if (this.area._id)
        try {
          await Api.delete("areapublic", this.area._id);
        } catch (e) {
          console.error(e);
        }
      return true;
    },
    async subirImagen() {
      let img = await Api.upload("imagen");
      this.area.imagen = img;
      console.log("IMG", img);
    },
  },
  mounted() {
    this.yo = Api.obtenerLocal("wst.yo");
    if (!(this.yo && this.yo._id)) console.warn("No has iniciado sesión");
    this.obtener();
  },
};
</script>

<style></style>
