<template>
<div v-if="chartdata && chartdata.datasets && modoGrafico">
  <ion-item :color="colors('light')" lines="none">
    <ion-label v-if="!menosEfectivos">Horas efectivas por usuario </ion-label>
    <ion-label v-else>Usuarios con reporteo menor a 50%</ion-label>
    <ion-note slot="end">
      Ordenar {{modoProduccion?'ELITE':'despacho'}}
    </ion-note>
    <ion-checkbox :checked="ordenarELITE" v-on:ionChange="toggleOrdenarELITE($event.detail.checked==true)" slot="end"></ion-checkbox>
  </ion-item>
  <ChartBarras :chartdata="chartdata" :chart-data="chartdata" :options="chartoptions"></ChartBarras>
</div>
<div v-else>
  <ion-item :color="colors('light')">
    <ion-label>Horas efectivas por usuario </ion-label>
    <ion-note slot="end">
      Ordenar {{modoProduccion?'ELITE':'despacho'}}
    </ion-note>
    <ion-checkbox :checked="ordenarELITE" v-on:ionChange="toggleOrdenarELITE($event.detail.checked==true)" slot="end"></ion-checkbox>
  </ion-item>
  <ion-item :color="colors('light')">
    <ion-label>({{filtros.fechaInicio | moment('DD-MM-YYYY')}} @ {{filtros.fechaFin | moment('DD-MM-YYYY')}})</ion-label>
    <ion-button slot="end" :color="colors('dark')" fill="outline" @click="copiarTabla">
      <i class="icon ion-ios-copy"></i>
    </ion-button>
  </ion-item>
  <div class="scrollable">
    <table id="tabla">
      <thead>
        <tr>
          <th>Abogado</th>
          <th class="ion-text-right">Efectivas</th>
          <th class="ion-text-right">ELITE</th>
          <th class="ion-text-right">Total</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="a in abogadosTodos" :key="a._id" vv-if="a.horasTotal&&a.horasTotal>0">
          <td>{{a.nombre}}</td>
          <td class="ion-text-right">{{a.horasEfectivas}}</td>
          <td class="ion-text-right">{{a.horasELITE}}</td>
          <td class="ion-text-right">{{a.horasTotal}}</td>
          <td class="ion-text-right">{{a.horasTotal | filtroTiempo}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>
<style>
.scrollable {
  width: 100%;
  display: block;
  overflow-x: scroll;
}

table td {
  border: 1px solid #eee;
}

table td,
table th {
  font-size: 14px;
}

.divisor {
  background-color: #eee;
}
</style>
<script>
import Api from '@/components/Api'
//import ChartLinea from '@/components/ChartLinea'
import ChartBarras from '@/components/ChartBarras'
import Cliente from '@/models/Cliente'
import Usuario from '@/models/Usuario'
import Colores from '@/models/Colores'
import Reporteable from '@/models/Reporteable'
const yo = Api.obtenerLocal('elite.yo') || {}
export default {
  name: 'ReporteHorasPorUsuario',
  props: ['filtros', 'abogados', 'clientes', 'areas', 'corporativos',
    'unSoloAbogado', 'unSoloCliente', 'modoGrafico', 'numClientes', 'menosEfectivos'
  ],
  components: {
    //ChartLinea,
    ChartBarras
  },
  filters: {
    filtroTiempo: function(cant) {
      cant = parseFloat(cant)
      let parteEntera = Math.floor(cant / 60)
      let parteDecimal = (cant % 60).toFixed(0)
      if (parteDecimal.length == 1)
        parteDecimal = '0' + parteDecimal
      return parteEntera + ':' + parteDecimal
    }
  },
  data() {
    return {
      yo,
      modoProduccion: Api.modoProduccion,
      actividades: [],
      asuntos: {},
      abogados2: {},
      mapaDiasClientes: {},
      mapaDiasAreas: {},
      clientes2: {},
      clientesTodos: [],
      abogadosTodos: [],
      incluirELITE: true,
      ordenarELITE: false,
      clientesELITE: [],
      etiquetasClientes: [],
      etiquetas: [],
      chartdata: {},
      chartoptions: { // El unico cambio con el modulo ChartBarras es el que no se apilan las barras
        showLines: true,
        cubicInterpolationMode: 'monotone',
        fill: true,
        lineTension: 0,
        scales: {
          xAxes: [{
            stacked: false,
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90
            }
          }],
          yAxes: [{
            stacked: false
          }]
        }
      },
      //
      resumen: {}
    }
  },
  async mounted() {
    console.log("ReporteMisActividades", this.abogados)
    this.abogadosTodos = (await Usuario.activosALaFecha(this.filtros.fechaInicio))
      //this.abogadosTodos = (await Usuario.lista())
      .map(a => {
        a.horasEfectivas = 0
        a.horasELITE = 0
        a.horasTotal = 0
        return a
      })
      .filter(a => {
        //console.log("A?", a.tipo, a.nombre)
        return a.tipo != 'administrativo' || !this.modoProduccion
      })
    console.log("abogadosTodos?", this.abogadosTodos.map(a => a.nombre))
    this.clientesTodos = await Cliente.lista()
    console.log("clientesTodos?", this.clientesTodos.map(a => a.nombre))
    this.toggleIncluirELITE(false)
    //
    console.log("Filtros?", this.filtros.fechaInicio, this.filtros.fechaFin)
    /*
    let u = await Reporteable.obtenerResumenActividades(this.filtros.fechaInicio, this.filtros.fechaFin, 'usuario', 'dia')
    console.log("Reporteable?", u)*/
  },
  methods: {
    toggleIncluirELITE(val) {
      this.incluirELITE = val
      this.clientesELITE = []
      if (!this.incluirELITE)
        for (let c of this.clientesTodos) {
          if (c.nombre.toLowerCase().indexOf('elite') >= 0 ||
            (c.omitirDeReportes && c.omitirDeReportes == true)) {
            console.log("Omitiendo", c.nombre)
            this.clienteselite.push(c._id)
          }
        }
      console.log("Consultando clientes. Omitiendo?", this.clientesELITE)
      this.obtener()
    },
    toggleOrdenarELITE(val) {
      this.ordenarELITE = val
      this.obtener()
    },
    async obtener() {
      console.log("Obteniendo", this.filtros)
      this.chartdata = null
      let fI = new Date(this.filtros.fechaInicio).getTime()
      let fF = new Date(this.filtros.fechaFin).getTime()
      let filtros = [
        'fechaProgramada,gte,' + fI,
        'fechaProgramada,lt,' + fF
      ]
      filtros = []
      if (this.filtros.idCliente && this.filtros.idCliente.length == 24)
        filtros.push('idCliente,eq,' + this.filtros.idCliente)
      //let actividades = await Api.find('actividad', filtros)
      let resumen = await Reporteable.obtenerResumenActividades(this.filtros.fechaInicio, this.filtros.fechaFin, null, 'dia', filtros)
      this.resumen = resumen
      let actividades = resumen.actividades
      console.log("Obtenidas actividades?", this.filtros.fechaInicio, this.filtros.fechaFin, actividades.length, filtros)
      if (!Api.modoProduccion)
        console.table(actividades)
      let usuariosIndices = this.abogadosTodos.map(u => u._id)
      let clientesIndices = this.clientesTodos.map(c => c._id)
      const filtrosf = this.filtros
      console.log("filtrosf", filtrosf)
      actividades = actividades
        .filter(a => !filtrosf.area || filtrosf.area == '' || filtrosf.area == a.area)
      console.log("Acts?", actividades.length, this.clientesELITE)
      for (let i in this.abogadosTodos) {
        for (let act of actividades) {
          if (this.abogadosTodos[i]._id == act.idAbogado) {
            //console.log("vinculando actividad a abogado", this.abogadosTodos[i].nombre)
            this.abogadosTodos[i].horasTotal += parseFloat(act.minutos)
            if ((this.clienteselite || []).indexOf(act.idCliente) == -1) {
              this.abogadosTodos[i].horasEfectivas += parseFloat(act.minutos)
              //console.log("vinculado a horas efectivas", act.minutos, i)
            } else
              this.abogadosTodos[i].horasELITE += parseFloat(act.minutos)
          }
        }
      }
      const ordenarELITE = this.ordenarELITE
      this.abogadosTodos = this.abogadosTodos.sort((a, b) => {
          if (!ordenarELITE)
            return (a.horasTotal || 0) < (b.horasTotal || 0) ? 1 : -1
          else
            return (a.horasELITE || 0) < (b.horasELITE || 0) ? 1 : -1
        })
        .filter(a => {
          //console.log("A?", a.tipo, a.nombre)
          return a.tipo != 'administrativo' || !this.modoProduccion
        })
      console.log("abogadosTodos?", this.abogadosTodos.length,
        this.abogadosTodos.map(a => a.horasTotal),
        this.abogadosTodos.map(a => a.horasEfectivas),
        this.abogadosTodos.map(a => a.horasELITE)
      )
      // Obtener cuántos días laborales (y horas esperadas) hay en el mes
      var getDaysInMonth = function(month, year) {
        let f = new Date(year, month + 1, 0)
        console.log("Fecha para dias del mes?", f)
        return f.getDate()
      }
      let fechaInicio = new Date(this.filtros.fechaInicio)
      let yearInicio = fechaInicio.getFullYear()
      let monthInicio = fechaInicio.getMonth() + 1
      let diasEnMes = getDaysInMonth(monthInicio, yearInicio)
      let dd = diasEnMes
      for (let i = 1; i <= dd; i++) {
        let fechaInicio2 = fechaInicio.setDate(i)
        fechaInicio2 = new Date(fechaInicio2).setMonth(monthInicio)
        fechaInicio2 = new Date(fechaInicio2)
        let diaDeLaSemana = fechaInicio2.getDay()
        if (diaDeLaSemana == 0 || diaDeLaSemana == 6) {
          //console.log("Dia?", i, diaDeLaSemana, fechaInicio2)
          diasEnMes--
        }
      }
      //let horasLaborablesEsperadas = 8 * diasEnMes
      let horasLaborablesEsperadas = Reporteable.horasLaborablesEsperadas(fechaInicio)
      console.log("Dias y horas esperadas?", diasEnMes, horasLaborablesEsperadas)
      // Construir grafico
      const marca = 'despacho'
      let chartdata = {
        labels: [],
        datasets: [{
          label: this.menosEfectivos ? 'Porcentaje efectivo' : 'Horas efectivas',
          backgroundColor: Colores.get(0),
          borderColor: Colores.get(2),
          data: []
        }, {
          label: this.menosEfectivos ? 'Porcentaje ' + marca : 'Horas ' + marca,
          backgroundColor: Colores.get(1),
          borderColor: Colores.get(3),
          data: []
        }]
      }
      if (!this.menosEfectivos) {
        chartdata.datasets.push({
          label: 'Horas esperadas',
          backgroundColor: Colores.get(3),
          borderColor: Colores.get(3),
          data: [],
          type: 'line',
          fill: false
        })
      }
      let abogadosSplice
      let factorConversionParaMenosEfectivos = 1
      if (!this.menosEfectivos) {
        abogadosSplice = this.abogadosTodos.slice(0, this.numClientes || 15)
        console.log("mostrando los mas proficientes abogados", abogadosSplice.map(a => a.nombre))
      } else {
        // Extraer todos los abogados con menos de 50% de participacion
        abogadosSplice = this.abogadosTodos
          .filter(a => {
            return !(a.desactivado && a.desactivado == true)
          })
          .filter(a => {
            let horasTotal = a.horasTotal / 60
            let horasRequeridas = horasLaborablesEsperadas / 2
            return horasTotal < horasRequeridas
          })
        factorConversionParaMenosEfectivos = 100 / horasLaborablesEsperadas
        console.log("Menos efectivos?", horasLaborablesEsperadas / 2, abogadosSplice)
      }
      console.log("abogados para gráfica?", this.menosEfectivos, abogadosSplice.map(a => a.horasTotal))
      for (let a of abogadosSplice) {
        chartdata.labels.push(a.nombre || '')
        chartdata.datasets[0].data.push(((a.horasTotal || 0) - (a.horasELITE || 0)) / 60 * factorConversionParaMenosEfectivos)
        chartdata.datasets[1].data.push((a.horasELITE || 0) / 60 * factorConversionParaMenosEfectivos)
        if (!this.menosEfectivos)
          chartdata.datasets[2].data.push(horasLaborablesEsperadas)
      }
      this.chartdata = chartdata
      console.log("chartData?", chartdata)
      //
      this.$forceUpdate()
    },
    copiarTabla() {
      function selectElementContents(el) {
        var body = document.body,
          range, sel;
        if (document.createRange && window.getSelection) {
          range = document.createRange();
          sel = window.getSelection();
          sel.removeAllRanges();
          try {
            range.selectNodeContents(el);
            sel.addRange(range);
          } catch (e) {
            range.selectNode(el);
            sel.addRange(range);
          }
        } else if (body.createTextRange) {
          range = body.createTextRange();
          range.moveToElementText(el);
          range.select();
        }
      }
      let tabla = document.querySelector('#tabla')
      selectElementContents(tabla)
      document.execCommand('copy')
      console.log("Copiado?", tabla.value)
      alert("Copiado al portapapees")
    }
  },
  watch: {
    filtros: function() {
      this.obtener()
    }
  }
}
</script>
