<template>
<div>
  <ion-progress-bar v-if="cargando" type="indeterminate"></ion-progress-bar>
  <ion-list v-if="resultados.length>0">
    <ion-item-sliding v-for="(r,indice) in resultados" :key="indice">
      <ion-item :href="'#/'+r.tipo+'/'+r._id" @click="closeEnd">
        <ion-note slot="start">
          <i class="icon ion-ios-contact" v-if="r.tipo=='cliente'"></i>
          <i class="icon ion-ios-create" v-if="r.tipo=='asunto'"></i>
        </ion-note>
        <ion-label text-wrap>
          <h2>{{r.nombre}}</h2>
          <p>{{r.tipo}} {{r.folio}}</p>
          <p v-if="r.tipo=='cliente'">{{r.asuntos.length}} asunto(s)</p>
          <p v-if="r.tipo=='asunto'">{{r.cliente.nombre}}</p>
          <p v-if="r.tipo=='asunto'">{{r.status}}</p>
        </ion-label>
      </ion-item>
      <ion-item-options side="end">
      </ion-item-options>
    </ion-item-sliding>
  </ion-list>
  <ion-item v-else lines="none">
    <ion-label class="ion-text-center" color="danger">
      <p>No hay resultados que mostrar</p>
    </ion-label>
  </ion-item>
</div>
</template>
<script>
import Api from '@/components/Api'

export default {
  name: 'Buscador',
  props: {
    query: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      cargando: false,
      yo: Api.obtenerLocal('elite.yo') || {},
      resultados: [],
      clientes: [],
      asuntos: []
    }
  },
  methods: {
    async inicializarCatalogos() {
      if (this.asuntos.length == 0) {
        this.cargando = true
        const asuntos = (await Api.get('asunto'))
        const clientes = (await Api.get('cliente'))
        this.asuntos = JSON.parse(JSON.stringify(asuntos))
          .map(a => {
            let queryBuscable = ''
            for (let prop of ['nombre', 'folio', 'status', 'expediente', 'actor', 'demandado', 'juzgado'])
              queryBuscable += (a[prop] || '').trim()
            a.queryBuscable = queryBuscable.toUpperCase()
            a.tipo = 'asunto'
            for (let cl of clientes)
              if (cl._id == a.idCliente)
                a.cliente = cl
            return a
          })
        this.clientes = JSON.parse(JSON.stringify(clientes))
          .map(a => {
            let queryBuscable = ''
            for (let prop of ['nombre', 'rfc'])
              queryBuscable += (a[prop] || '').trim()
            a.queryBuscable = queryBuscable.toUpperCase()
            a.tipo = 'cliente'
            a.asuntos = asuntos
              .filter(b => b.idCliente == a._id)
            return a
          })
        console.log("Catalogos?", this.asuntos, this.clientes)
        this.cargando = false
      }
      return true
    },
    async buscar() {
      await this.inicializarCatalogos()
      console.log("Buscando " + this.query)
      if (this.query.length == 40 || this.query.length == 24) {
        // Es un asunto, ve directo
        const q = (this.query).toLowerCase().trim()
        this.$router.push('/asunto/' + q)
        Api.$emit('close-menu')
      } else {
        this.resultados = this.asuntos
          .concat(this.clientes)
          .filter(r => r.queryBuscable.indexOf(this.query) >= 0)
        console.log("Resultados?", this.query, this.resultados)
      }
    },
    closeEnd() {
      Api.$emit('close-menu')
    }
  },
  mounted() {
    console.log("Buscador", this.query)
    this.buscar()
  },
  watch: {
    'query': function() {
      this.buscar()
    }
  }
}
</script>
