<template>
<ion-app main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-buttons slot="end">
        <ion-button>
          <ion-icon name="more" :color="colors('secondary')"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Costeo de actividades</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-item>
      <ion-label>Desde fecha</ion-label>
      <ion-input type="date" :value="fechaInicio" v-on:ionChange="fechaInicio=$event.detail.value"></ion-input>
    </ion-item>
    <ion-list>
      <ion-item v-for="a in actividades" :key="a._id">
        <ion-label text-wrap>
          <h2>{{a.descripcion}} <span v-if="a.minutos>0">, {{a.minutos}}min</span></h2>
          <p>{{(a.asunto||{}).nombre}}, {{(a.asunto||{}).area}}</p>
          <p>{{(a.cliente||{}).nombre}} {{(a.asunto||{}).rfc}}</p>
        </ion-label>
        <ion-input class="ion-text-right" :value="a.monto" type="number" v-on:ionChange="cambiarMonto(a,$event.detail.value)" debounce="2000" />
      </ion-item>
    </ion-list>
  </ion-content>
</ion-app>
</template>
<style scoped>
.content {
  /*background-image: url('http://alherrera.tk/demos/elite.vue/dist/public/fondo_azul2.png');*/
  background-position: center;
}
</style>
<script>
import Api from '@/components/Api'
export default {
  name: 'CosteoActividades',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      empresa: Api.obtenerLocal('elite.empresa') || {},
      modoProduccion: Api.modoProduccion,
      fechaInicio: new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toISOString().substring(0, 10),
      actividades: []
    }
  },
  watch: {
    'fechaInicio': function(a) {
      this.obtener()
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async obtener() {
      let fechaInicio = new Date(this.fechaInicio).getTime()
      let subset = [{
        coleccion: 'usuario',
        alias: 'abogado',
        query: '_id,eq,',
        sustituir: 'idAbogado',
        unico: true
      }, {
        coleccion: 'asunto',
        alias: 'asunto',
        query: '_id,eq,',
        sustituir: 'idAsunto',
        unico: true
      }, {
        coleccion: 'cliente',
        alias: 'cliente',
        query: '_id,eq,',
        sustituir: 'idCliente',
        unico: true
      }]
      this.actividades = await Api.find('actividad', [
        'fecha,gte,' + fechaInicio
      ], {
        subset,
        sort: ['fecha,-1']
      })
      console.log("Actividades", this.actividades)
    },
    async cambiarMonto(act, val) {
      act.monto = parseFloat(val) || 0
      await Api.save('actividad', act)
      alert("Guardado")
    }
  },
  mounted() {
    this.obtener()
  }
}
</script>
