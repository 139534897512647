<template>
<ChartBarras :chartdata="chartdata" v-if="chartdata && chartdata.datasets && modoGrafico" :options="chartoptions"></ChartBarras>
<div v-else>
  <ion-item :color="colors('light')">
    <ion-label>Horas por usuario </ion-label>
    <ion-note slot="end">
      Mostrar despacho
    </ion-note>
    <ion-checkbox :checked="incluirBDLG" v-on:ionChange="toggleIncluirBDLG($event.detail.checked==true)" slot="end"></ion-checkbox>
  </ion-item>
  <ion-item :color="colors('light')">
    <ion-label>({{filtros.fechaInicio | moment('DD-MM-YYYY')}} @ {{filtros.fechaFin | moment('DD-MM-YYYY')}})</ion-label>
    <ion-button slot="end" :color="colors('dark')" fill="outline" @click="copiarTabla">
      <i class="icon ion-ios-copy"></i>
    </ion-button>
  </ion-item>
  <div class="scrollable">
    <table id="tabla">
      <thead>
        <tr>
          <th>Abogado</th>
          <th v-for="(dia,indice) in mapaDiasAbogados" :key="indice">
            {{parseFloat(indice)+(1000*60*60*24) | moment(strPan)}}
            <!--span vv-if="!modoProduccion">{{parseFloat(indice) | moment(strPan)}}</span-->
          </th>
          <th class="ion-text-right">Total</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <!--tr v-for="a in abogados2" :class="{'divisor':a.pseudo}" v-if="a.total && a.total>0"-->
        <tr v-for="a in abogados2" :key="a._id" :class="{'divisor':a.pseudo}">
          <td>{{a.nombre}} <span v-if="!modoProduccion">{{a._id}}</span></td>
          <td v-for="(dia,indice) in mapaDiasAbogados" :key="indice" class="ion-text-right">{{mapaDiasAbogados[indice][a._id]}}</td>
          <td class="ion-text-right">{{(a.total || 0)}}</td>
          <td class="ion-text-right">{{(a.total || 0) | filtroTiempo}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>
<style>
.scrollable {
  width: 100%;
  display: block;
  overflow-x: scroll;
}

table td {
  border: 1px solid #eee;
}

table td,
table th {
  font-size: 14px;
}

.divisor {
  background-color: #eee;
}
</style>
<script>
import Api from '@/components/Api'
import ChartBarras from '@/components/ChartBarras'
import Cliente from '@/models/Cliente'
import Usuario from '@/models/Usuario'
//import Colores from '@/models/Colores'
import Reporteable from '@/models/Reporteable'
export default {
  name: 'ReporteHorasPorUsuario',
  props: ['filtros', 'abogados', 'clientes', 'corporativos', 'unSoloAbogado', 'unSoloCliente', 'modoGrafico'],
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo'),
      tsoffset: (new Date().getTimezoneOffset() / 60),
      areas: [],
      actividades: [],
      asuntos: {},
      mapaDiasAbogados: {},
      mapaDiasAreas: {},
      //abogados: [],
      abogados2: [],
      strPan: 'MM-DD',
      incluirBDLG: true,
      clientesBDLG: [],
      clientesTodos: [],
      chartdata: null,
      chartoptions: { // El unico cambio con el modulo ChartBarras es el que no se apilan las barras
        showLines: true,
        cubicInterpolationMode: 'monotone',
        fill: true,
        lineTension: 0,
        scales: {
          xAxes: [{
            stacked: false,
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90
            }
          }],
          yAxes: [{
            stacked: false
          }]
        }
      },
      modoProduccion: Api.modoProduccion
    }
  },
  components: {
    ChartBarras
  },
  filters: {
    filtroTiempo: function(cant) {
      cant = parseFloat(cant)
      let parteEntera = Math.floor(cant / 60)
      let parteDecimal = (cant % 60).toFixed(0)
      if (parteDecimal.length == 1)
        parteDecimal = '0' + parteDecimal
      return parteEntera + ':' + parteDecimal
    }
  },
  methods: {
    toggleIncluirBDLG(val) {
      this.incluirBDLG = val
      this.clientesBDLG = []
      if (!this.incluirBDLG)
        for (let c of this.clientesTodos) {
          if (c.nombre.toLowerCase().indexOf('bufete de la garza') >= 0 ||
            (c.omitirDeReportes && c.omitirDeReportes == true))
            this.clienteselite.push(c._id)
        }
      console.log("Consultando clientes. Omitiendo?", this.clientesBDLG)
      this.obtener()
    },
    async obtener() {
      console.log("Obteniendo", this.filtros)
      const loadingController = document.querySelector('ion-loading-controller')
      console.log("loadingController?", loadingController)
      await loadingController.componentOnReady()
      const loadingElement = await loadingController.create({
        message: 'Cargando',
        spinner: 'crescent',
      })
      try {
        loadingElement.present()
        let fI = new Date(this.filtros.fechaInicio).getTime()
        let fF = new Date(this.filtros.fechaFin).getTime()
        let tsoffset = (new Date().getTimezoneOffset() / 60)
        this.strPan = 'MMM-DD'
        if (fF - fI > (1000 * 60 * 60 * 24 * 35)) {
          this.strPan = 'YYYY-MM'
        }
        let filtros = []
        let acts = []
        if (this.filtros.idCliente && this.filtros.idCliente != '')
          filtros.push('idCliente,eq,' + this.filtros.idCliente)
        /*
        if (true) { // true=legacy, false=reporteable
          filtros = filtros.concat([
            'fechaProgramada,gte,' + (fI).toString(),
            'fechaProgramada,lt,' + (fF + (1000 * 60 * 60 * 24)).toString() // TODO este offset
          ])
          acts = await Api.find('actividad', filtros)
        } else {
        //*/
        let resumen = await Reporteable.obtenerResumenActividades(this.filtros.fechaInicio, this.filtros.fechaFin, null, 'dia', filtros)
        console.log("Resumen=", resumen)
        acts = resumen.actividades
        const filtrosf = this.filtros
        acts = acts
          .filter(a => !filtrosf.area || filtrosf.area == '' || filtrosf.area == a.area)
        //}
        console.log("Actividades", tsoffset, filtros, this.unSoloCliente, typeof this.unSoloCliente, acts)
        console.log("u fI", new Date(fI))
        console.log("u fF", new Date(fF))

        acts = acts
          .sort((a, b) => {
            return a.fechaProgramada > b.fechaProgramada ? 1 : -1
          })
        // Obtener catalogos necesarios
        let abogados = {}
        for (let a of this.abogados2)
          abogados[a._id] = a
        let asuntos = {}
        let asuntos2 = await Api.get('asunto')
        for (let a of asuntos2)
          asuntos[a._id] = a
        let areas = {}
        let areas2 = await Api.get('area')
        for (let a of areas2)
          areas[a._id] = a
        // Mapear las actividades para que incluyan area y abogado
        let totalGeneral = 0
        //console.log("Mapeando actividades?", acts.length, tsoffset, new Date().getTimezoneOffset())
        /*
        if (false)
          acts = acts
          .filter(a => {
            return a.idAsunto && a.idAbogado && a.idCliente
          })
          .map(a => {
            if (!a.area) {
              //console.log("A?", a.area, a.idAsunto, asuntos[a.idAsunto])
              a.area = (asuntos[a.idAsunto] || {}).area
            }
            a.abogado = abogados[a.idAbogado].nombre
            //console.log("Mapeando act", new Date(a.fechaProgramada), tsoffset)
            a.fechaProgramada -= ((1000 * 60 * tsoffset) || 0)
            return a
          })//*/
        for (let a of acts)
          totalGeneral += parseFloat(a.minutos)
        console.log("Acts mapeadas?", totalGeneral, acts)
        // Crear un mapa de las marcas de tiempo
        console.log("Offset?", this.tsoffset, this.filtros)
        let ffI = this.filtros.fechaInicio
        if (ffI.length < 10)
          ffI += '-01'
        let ffF = this.filtros.fechaFin
        if (ffF.length < 10)
          ffF += '-01'
        let marcaInicio = new Date(ffI)
        //marcaInicio.setMilliseconds(0)
        //marcaInicio.setSeconds(0)
        //marcaInicio.setMinutes(0)
        //marcaInicio.setHours(12)
        marcaInicio = marcaInicio.getTime() + (1000 * 60 * 60 * (this.tsoffset + 1))
        let marcaFin = new Date(ffF)
        //marcaFin.setMilliseconds(0)
        //marcaFin.setSeconds(0)
        //marcaFin.setMinutes(0)
        //marcaFin.setHours(12)
        marcaFin = marcaFin.getTime() + (1000 * 60 * 60 * this.tsoffset)
        //
        if (this.filtros.porRango == 'mes') { // hacer setDate(0) garantiza el último día del mes
          marcaFin = marcaInicio + (1000 * 60 * 60 * 24 * 35)
          marcaFin = new Date(marcaFin)
          marcaFin.setMilliseconds(999)
          marcaFin.setSeconds(59)
          marcaFin.setMinutes(59)
          marcaFin.setHours(23)
          marcaFin.setDate(0)
          marcaFin = marcaFin.getTime()
        }
        console.log("Fechas?", this.filtros.fechaInicio, ffI, new Date(marcaInicio), this.filtros.fechaFin, ffF, new Date(marcaFin))
        // Crear un mapa por dias
        let intervalo = (1000 * 60 * 60 * 24)
        let etiqueta = 'YYYY-MM-DD'
        if ((marcaFin - marcaInicio) > (1000 * 60 * 60 * 24 * 35)) {
          intervalo *= 30
          etiqueta = etiqueta.replace('-DD', '')
        }
        let mapaDias = []
        for (let marca = marcaInicio; marca <= marcaFin; marca += intervalo)
          mapaDias.push(this.$options.filters.moment(marca, etiqueta))
        console.log("mapaDias?", mapaDias)
        // Asignar el mapa de abogados
        this.mapaDiasAbogados = {}
        for (let dia of mapaDias)
          this.mapaDiasAbogados[new Date(dia).getTime()] = {}
        for (let act of acts) {
          {
            for (let dia of mapaDias) {
              let dia2 = new Date(dia).getTime()
              let etiquetaAct = this.$options.filters.moment(act.fechaProgramada, etiqueta)
              if (etiquetaAct == dia) {
                this.mapaDiasAbogados[dia2][act.idAbogado] = parseFloat((this.mapaDiasAbogados[dia2][act.idAbogado]) || 0) + parseFloat(act.minutos)
                // Asignar al abogado su tiempo para sumatoria
                for (let i in this.abogados2) {
                  if (this.abogados2[i]._id == act.idAbogado) {
                    console.log("abogado", this.abogados2[i]._id, act.idAbogado, new Date(act.fechaProgramada), act.fechaProgramada)
                    this.abogados2[i].total = parseFloat(this.abogados2[i].total || 0) + parseFloat(act.minutos)
                  }
                }
              }
            }
          }
        }
        console.log("mapaDiasAbogados?", this.mapaDiasAbogados, this.abogados2)
        // Generar grafico
        let chartdata = {
          labels: [],
          datasets: [{
            label: 'Actividades por usuario (Horas a clientes)',
            backgroundColor: 'rgb(255, 99, 200)',
            borderColor: 'rgb(255, 99, 200)',
            data: []
          }]
        }
        let abogadosGraficables = this.abogados2
          .sort((a, b) => {
            return (a.total || 0) < (b.total || 0) ? 1 : -1
          })
        //.slice(0, 15)
        for (let a of abogadosGraficables) {
          //console.log("Abogao?", a.nombre, a.total)
          chartdata.labels.push(a.nombre)
          chartdata.datasets[0].data.push(a.total || 0)
        }
        this.chartdata = chartdata
        //
      } catch (e) {
        console.error(e)
        loadingElement.dismiss()
      }
      loadingElement.dismiss()
      this.$forceUpdate()
    },
    copiarTabla() {
      function selectElementContents(el) {
        var body = document.body,
          range, sel;
        if (document.createRange && window.getSelection) {
          range = document.createRange();
          sel = window.getSelection();
          sel.removeAllRanges();
          try {
            range.selectNodeContents(el);
            sel.addRange(range);
          } catch (e) {
            range.selectNode(el);
            sel.addRange(range);
          }
        } else if (body.createTextRange) {
          range = body.createTextRange();
          range.moveToElementText(el);
          range.select();
        }
      }
      let tabla = document.querySelector('#tabla')
      selectElementContents(tabla)
      document.execCommand('copy')
      console.log("Copiado?", tabla.value)
      alert("Copiado al portapapees")
    }
  },
  watch: {
    filtros: function() {
      this.obtener()
    }
  },
  async mounted() {
    console.log("ReporteMisActividades")
    //this.abogados2 = await Usuario.lista()
    this.abogados2 = await Usuario.activosALaFecha(this.filtros.fechaInicio)
    this.areas = await Api.get('area')
    this.clientesTodos = await Cliente.lista()
    this.obtener()
    let u = await Reporteable.obtenerResumenActividades(this.filtros.fechaInicio, this.filtros.fechaFin, 'usuario', 'dia')
    console.log("Reporteable?", u)
  }
}
</script>
