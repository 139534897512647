<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-title>{{cliente.nombre || 'Cliente'}}</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button fill="outline" :href="'#/facturable/cliente/'+cliente._id" v-if="cliente._id">
          <i class="icon ion-ios-document"></i>PDF
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-list>
      <ion-item v-if="yo.rol=='administrador'">
        <ion-label>ID</ion-label>
        <ion-input class="ion-text-right" :value="cliente._id" v-on:ionChange="cliente._id=($event.detail.value||'').toLowerCase()"></ion-input>
      </ion-item>
      <ion-item v-for="f in formcliente" :key="f.label">
        <ion-label position="floating">{{f.label}}</ion-label>
        <ion-select v-if="(f.type && f.type=='select')" v-on:ionChange="cliente[f.field]=$event.detail.value" :value="cliente[f.field]" interface="popover">
          <ion-select-option v-for="o in f.options" :key="o">{{o}}</ion-select-option>
        </ion-select>
        <ion-input v-else :value="cliente[f.field]" :type="f.type||'text'" v-on:ionChange="cliente[f.field]=$event.detail.value"></ion-input>
      </ion-item>
      <!--ion-item>
        <ion-label>Incluir en reportes</ion-label>
        <ion-toggle slot="end" :checked="!(cliente.omitirDeReportes)" v-on:ionChange="cliente.omitirDeReportes=!($event.detail.checked==true)"></ion-toggle>
      </ion-item>
      <ion-item>
        <ion-label text-wrap>Obligatoria orden de compra al solicitar factura</ion-label>
        <ion-toggle slot="end" :checked="cliente.ordenCompraObligatoria" v-on:ionChange="cliente.ordenCompraObligatoria=($event.detail.checked==true)"></ion-toggle>
      </ion-item-->
      <ion-item>
        <ion-label>Desactivado <span v-if="cliente.fechaBaja && cliente.fechaBaja>0">(el {{cliente.fechaBaja | moment('YYYY-MM-DD')}})</span></ion-label>
        <!--ion-toggle slot="end" debounce="250" :checked="(cliente.fechaBaja && cliente.fechaBaja>0)" v-on:ionChange="toggleFechaBaja($event.detail.checked==true)"></ion-toggle-->
        <ion-select :value="(cliente.fechaBaja && cliente.fechaBaja>0)?'Si':'No'" v-on:ionChange="toggleFechaBaja($event.detail.value)" interface="popover">
          <ion-select-option>Si</ion-select-option>
          <ion-select-option>No</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed" v-if="permiso('EditarClientes') || (permiso('CrearClientes') && !cliente._id)">
      <ion-fab-button :color="colors('secondary')" v-on:click="guardar()">
        <i class="icon ion-ios-save icon-lg"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
import Cliente from '@/models/Cliente'
export default {
  name: 'CatalogoCliente',
  props: ['id'],
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      cliente: {},
      formcliente: []
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    toggleFechaBaja(val) {
      this.cliente.fechaBaja = (val == 'Si') ? (new Date().getTime()) : 0
      console.log("toggleFechaBaja", val, this.cliente.fechaBaja)
      this.$forceUpdate()
    },
    async guardar() {
      let permitirGuardar = true
      let clienteRepetido = false
      let paramsRequeridos = ['nombre']
      for (let o of paramsRequeridos)
        if (!this.cliente[o] || this.cliente[o] == '')
          permitirGuardar = false
      if (!this.cliente._id || this.cliente._id == '0') {
        let clientes = await Cliente.lista()
        for (let cl of clientes) {
          if (
            (this.cliente.nombre || '').toLowerCase() == (cl.nombre || '').toLowerCase()
            //||(this.cliente.rfc && (this.cliente.rfc || '').toLowerCase() == (cl.rfc || '').toLowerCase())
          ) {
            permitirGuardar = false
            clienteRepetido = true
            console.log("Repetido?", cl)
          }
        }
      }
      console.log("Guardando", this.cliente)
      if (permitirGuardar) {
        let u = await Api.save('cliente', this.cliente)
        let bitacora = {
          idAutor: this.yo._id,
          mensaje: 'Cliente modificado: ' + this.cliente.nombre,
          fecha: new Date().getTime()
        }
        let v = await Api.save('bitacora', bitacora)
        //this.cliente = u
        if (u._id)
          this.cliente._id = u._id
        Api.guardarLocal('elite.cliente.' + this.cliente._id, this.cliente)
        alert("Guardado")
        setTimeout(_ => {
          this.$router.push('/catalogos/clientes')
        }, 1000)
      } else {
        alert("Faltan datos: " + paramsRequeridos.join(','))
        if (clienteRepetido)
          alert("Cliente repetido (Nombre o RFC)")
      }
    }
  },
  async mounted() {
    console.log("Catalogo cliente", this.id)
    this.formcliente = Cliente.formulario()
    let vm = this
    console.log("Verificando permisos", this.yo)
    this.formcliente = this.formcliente.filter(f => {
      if (typeof f.requiere == 'undefined')
        return true
      else return this.yo[f.requiere] == true
    })
    if (this.id && this.id.length == 24) {
      this.cliente = await Api.get('cliente', this.id)
    }
  }
}
</script>
