<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-title>Acuerdo - {{acuerdo.nombre || 'NUEVO'}}</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <ion-icon name="menu" :color="colors('light')"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button v-if="acuerdo._id" fill="outline" :href="'#/facturable/acuerdo/'+acuerdo._id">Reporte</ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" v-if="cargando"></ion-progress-bar>
  </ion-header>
  <ion-content class="content">
    <ion-row>
      <ion-col class="ion-no-padding" size-sm="6" size-md="4" size-lg="3">
        <ion-item lines="none">
          <ion-label color="medium">
            <h1>Configuración</h1>
          </ion-label>
        </ion-item>
        <ion-list>
          <ion-item-divider>General</ion-item-divider>
          <!--SelectorCliente :labelPosition="'floating'" :idCliente="acuerdo.idCliente" /-->
          <ion-item>
            <ion-label position="floating">Nombre</ion-label>
            <ion-textarea rows="2" type="text" :value="acuerdo.nombre" v-on:ionChange="acuerdo.nombre=$event.detail.value"></ion-textarea>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Folio de contrato</ion-label>
            <ion-input type="text" :value="acuerdo.contrato" v-on:ionChange="acuerdo.contrato=$event.detail.value"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Fecha de inicio de cobranza</ion-label>
            <ion-input type="date" :value="acuerdo.desdeFecha" v-on:ionChange="acuerdo.desdeFecha=$event.detail.value"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Fecha de fin de cobranza</ion-label>
            <ion-input type="date" :value="acuerdo.hastaFecha" v-on:ionChange="acuerdo.hastaFecha=$event.detail.value"></ion-input>
          </ion-item>
          <ion-item lines="none">
            <label for="archivo">
              <ion-label>
                <h2>Contrato adjunto</h2>
                <p>Clic para {{acuerdo.archivo!=''?'cambiar':'subir'}}</p>
              </ion-label>
              <input class="hidden" type="file" name="archivo" id="archivo" v-on:change="subir()" />
            </label>
            <ion-button slot="end" @click="descargar" target="_blank" v-if="archivo!=''" fill="outline" color="medium">
              Ver &nbsp;<i class="icon icon-sm ion-ios-document"></i>
            </ion-button>
          </ion-item>
        </ion-list>
        <ion-list>
          <ion-item-divider>Cobranza</ion-item-divider>
          <ion-list>
            <ion-item>
              <ion-label position="floating">Tipo de cobranza</ion-label>
              <ion-select :value="acuerdo.tipo" v-on:ionChange="acuerdo.tipo=$event.detail.value;comprobarTarifaHoras()">
                <ion-select-option v-for="i in ['iguala','asunto','hora','gratuito','probono']" :key="i" :value="i">{{i.toUpperCase()}}</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item v-if="acuerdo.tipo=='iguala'">
              <!--ion-label text-wrap position="floating">Valor de la iguala (devengado último mes: {{calcularDevengadoIguala() | currency}})</ion-label-->
              <ion-label>Valor de la iguala</ion-label>
              <ion-input :value="acuerdo.monto" v-on:ionChange="acuerdo.monto=parseFloat($event.detail.value)" type="number"></ion-input>
            </ion-item>
            <ion-item v-if="acuerdo.tipo=='asunto'">
              <!--ion-label text-wrap position="floating">Valor del asunto (devengado: {{calcularDevengadoAsunto() | currency}})</ion-label-->
              <ion-label>Valor del asunto</ion-label>
              <ion-input :value="acuerdo.monto" v-on:ionChange="acuerdo.monto=parseFloat($event.detail.value)" type="number"></ion-input>
            </ion-item>
            <ion-list v-if="acuerdo.tipo=='hora'">
              <ion-item v-for="(b,a) in empresa.tarifas" :key="a">
                <ion-label position="floating" text-wrap class="ion-text-capitalize">Tarifa {{a}}</ion-label>
                <ion-input class="ion-text-left" color="primary" type="number" :value="acuerdo.horas[a]" v-on:ionChange="acuerdo.horas[a]=parseFloat($event.detail.value)" />
              </ion-item>
            </ion-list>
          </ion-list>
        </ion-list>
        <!--pre class="ion-text-left" v-if="!modoProduccion">{{acuerdo}}</pre-->
      </ion-col>
      <ion-col class="ion-no-padding" v-if="acuerdo.idCliente!=''" size-sm="6" size-md="8" size-lg="9">
        <ion-item lines="none">
          <ion-label color="medium">
            <h1>Asuntos que cubre</h1>
          </ion-label>
        </ion-item>
        <ion-searchbar placeholder="Buscar por cliente, folio, etc" :value="filtro" debounce="500" v-on:ionChange="filtrarAsuntos($event.detail.value)"></ion-searchbar>
        <ion-list v-if="acuerdo.idCliente!=''">
          <ion-item v-for="a in asuntosFiltrados" :key="a._id">
            <ion-checkbox slot="start" :ddisabled="(a.histogramaCobranza||[]).length>0" :checked="acuerdo.asuntos.indexOf(a._id)>=0" v-on:ionChange="toggleAsunto(a,$event.detail.checked==true)"></ion-checkbox>
            <ion-label text-wrap>
              <h2>{{a.nombre}}</h2>
              <h2>{{(a.cliente||{}).nombre}}</h2>
              <p>{{a.area}} - {{a.folio}} - Desde {{a.fecha | moment('MMM DD, YYYY')}}</p>
              <p>{{(a.cliente||{}).nombre}}</p>
              <p v-if="(a.actor!='' || a.demandado!='')">{{a.actor}} - {{a.demandado}}</p>
              <p v-if="otrosAcuerdos(a).length>0">Otros acuerdos: {{otrosAcuerdos(a).map(a=>a.nombre+' desde '+a.desdeFecha).join(', ')}}</p>
            </ion-label>
          </ion-item>
        </ion-list>
        <ion-item lines="none" v-else>
          <ion-label class="ion-text-left" color="danger">
            <h2>Seleccione un cliente para buscar asuntos</h2>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('secondary')" @click="guardar(true)" :disabled="cargando">
        <i class="icon ion-ios-save icon-lg"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
//import SelectorCliente from '@/components/SelectorCliente'
export default {
  name: 'Acuerdo',
  props: {
    id: {
      type: String,
      default: '0'
    }
  },
  components: {
    //SelectorCliente
  },
  data() {
    return {
      modoProduccion: Api.modoProduccion,
      cdn: Api.cdn + '/',
      cargando: false,
      yo: Api.obtenerLocal('elite.yo') || {},
      empresa: Api.obtenerLocal('elite.empresa') || {},
      acuerdo: {
        nombre: 'Nuevo acuerdo',
        tipo: 'iguala',
        asuntos: [],
        archivo: '',
        clientes: [],
        idCorporativo: '',
        desdeFecha: new Date().toISOString().substring(0, 10),
        hastaFecha: new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 365 * 10)).toISOString().substring(0, 10),
        horas: {}
      },
      archivo: '',
      asuntos: [],
      asuntosFiltrados: [],
      filtro: ''
    }
  },
  async mounted() {
    this.cargando = true
    await this.obtenerAsuntos()
    this.obtener()
    Api.$on('clienteSeleccionado', this.configurarCliente)
  },
  beforeDestroy() {
    Api.$off('clienteSeleccionado')
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async obtenerAsuntos() {
      let mapaClientes = {}
      for (let cl of (await Api.get('cliente')))
        mapaClientes[cl._id] = cl
      this.asuntos = (await Api.get('asunto'))
        .sort((a, b) => a.fecha < b.fecha ? 1 : -1)
        .map(a => {
          a.actor = a.actor || ''
          a.demandado = a.demandado || ''
          a.cliente = mapaClientes[a.idCliente] || {}
          return a
        })
        .filter(a => a.idCliente && a.idCliente != '')
      console.log("Asuntos?", this.asuntos)
      this.filtrarAsuntos('')
      return true
    },
    async configurarCliente(cl) {
      this.acuerdo.idCliente = cl
      let cliente = await Api.get('cliente', cl)
      delete cliente._id
      this.acuerdo.cliente = cliente
      console.log("configurarCliente?", this.acuerdo.cliente.nombre)
      this.filtrarAsuntos()
    },
    async obtener() {
      this.cargando = true
      if (this.id != '0') {
        this.acuerdo = await Api.get('acuerdocobranza', this.id)
        console.log("Acuerdo?", this.acuerdo)
        this.filtrarAsuntos()
        if (this.acuerdo.archivo != '')
          this.archivo = (await Api.get('contratocobranza', this.acuerdo._id)).data
      }
      this.cargando = false
    },
    toggleAsunto(asunto, val) {
      if (val == true) {
        this.acuerdo.asuntos.push(asunto._id)
        this.acuerdo.asuntos = [...new Set(this.acuerdo.asuntos)]
      } else {
        this.acuerdo.asuntos = this.acuerdo.asuntos
          .filter(a => a != asunto._id)
      }
      console.log("Incluir asunto?", asunto.nombre, val, asunto)
      this.$forceUpdate()
    },
    async subir() {
      this.cargando = true
      let data = await Api.upload('archivo', 'base64')
      if (!this.acuerdo._id) {
        await this.guardar(false)
        await Api.wait(200)
      }
      let u = await Api.save('contratocobranza', {
        _id: this.acuerdo._id,
        data
      })
      console.log("Subido?", u)
      this.acuerdo.archivo = u._id
      this.archivo = data
      this.cargando = false
    },
    descargar() {
      console.log("Descargar", this.archivo)
      var a = document.createElement("a"); //Create <a>
      a.href = this.archivo; //Image Base64 Goes here
      a.download = 'contrato_' + this.acuerdo._id; //File name Here
      a.click(); //Downloaded file
    },
    async guardar(redirigir = true) {
      try {
        this.cargando = true
        // Guardar los clientes directamente
        this.acuerdo.clientes = []
        console.log("Guardando", this.acuerdo)
        for (let idAsunto of this.acuerdo.asuntos) {
          let asunto = {}
          for (let a of this.asuntos)
            if (a._id == idAsunto)
              asunto = a
          if (
            (this.acuerdo.clientes || [])
            .map(cl => cl._id)
            .indexOf(asunto.idCliente) < 0
          )
            this.acuerdo.clientes.push(asunto.cliente)
        }
        let k = await Api.save('acuerdocobranza', this.acuerdo)
        if (!this.acuerdo._id)
          this.acuerdo._id = k._id
        // Guardar el acuerdo como histograma en cada asunto relacionado
        let acuerdoEnHistograma = JSON.parse(JSON.stringify(this.acuerdo))
        acuerdoEnHistograma.idAcuerdocobranza = acuerdoEnHistograma._id
        delete acuerdoEnHistograma._id
        for (let idAsunto of this.acuerdo.asuntos) {
          // Volverlo a jalar para tener la versión más fresca
          let asunto = await Api.get('asunto', idAsunto)
          // Quitar la versión existente
          asunto.histogramaCobranza = (asunto.histogramaCobranza || [])
            .filter(h => !h.idAcuerdocobranza)
          let histogramaPorIndices = {}
          for (let h of asunto.histogramaCobranza)
            histogramaPorIndices[h.idAcuerdocobranza] = h
          histogramaPorIndices[acuerdoEnHistograma._id] = acuerdoEnHistograma
          console.log('histogramaPorIndices?', histogramaPorIndices)
          asunto.histogramaCobranza = []
          for (let i in histogramaPorIndices)
            asunto.histogramaCobranza.push(histogramaPorIndices[i])
          // Colocar la nueva versión
          asunto.histogramaCobranza.push(acuerdoEnHistograma)
          console.log("Guardando asunto", asunto)
          await Api.save('asunto', asunto)
        }
        //
        alert("Guardado")
        this.$router.push('/acuerdos/' + this.acuerdo._id)
        await Api.wait(1000)
        if (redirigir == true)
          this.$router.push('/acuerdos')
      } catch (e) {
        alert("No se pudo guardar el acuerdo")
        console.error(e)
        this.cargando = false
        return true
      }
    },
    otrosAcuerdos(asunto) {
      let esteAcuerdo = this.acuerdo._id
      let acs = (asunto.histogramaCobranza || [])
        .filter(h => h.idAcuerdocobranza != esteAcuerdo)
      console.log("otrosAcuerdos?", acs.map(h => h.idAcuerdocobranza), esteAcuerdo, acs.length)
      return [acs[0] || {}]
    },
    comprobarTarifaHoras() {
      if (this.acuerdo.tipo == 'hora' &&
        !(this.acuerdo.horas && this.acuerdo.horas.global)) {
        this.acuerdo.horas = {}
        for (let i in (this.empresa.tarifas || {}))
          this.acuerdo.horas[i] = this.empresa.tarifas[i]
        this.$forceUpdate()
      }
      console.log("comprobarTarifaHoras", this.acuerdo.horas)
    },
    filtrarAsuntos(val = '') {
      this.filtro = val.toUpperCase()
      let asuntosEnAcuerdo = this.acuerdo.asuntos
      this.asuntosFiltrados = this.asuntos
        .filter(a => {
          if (asuntosEnAcuerdo.indexOf(a._id) >= 0)
            return true
          if (val == '')
            return false
          let props = ['nombre', 'folio', 'actor', 'demandado', 'juzgado']
          let strBusqueda = ''
          for (let prop of props) {
            strBusqueda += (a[prop] || '')
            if (a.cliente)
              strBusqueda += (a.cliente[prop] || '')
          }
          return strBusqueda.toUpperCase().indexOf(val) >= 0
        })
        .sort((a, b) => asuntosEnAcuerdo.indexOf(a._id) ? 1 : -1)
    }
  }
}
</script>
