<template>
  <ion-app main>
    <ion-header>
      <ion-toolbar :color="colors('secondary')">
        <ion-buttons slot="end">
          <ion-button>
            <ion-icon name="more" :color="colors('secondary')"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title
          >Facturas
          <span v-if="prefacturas.length > 0"
            >({{ prefacturas.length }})</span
          ></ion-title
        >
        <ion-buttons slot="start">
          <ion-button @click="openEnd">
            <i class="icon ion-ios-menu"></i>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button
            @click="eliminarTodo"
            v-if="yo.rol == 'administrador'"
            title="Eliminar todas"
          >
            <i class="icon ion-ios-trash"></i>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-progress-bar
        v-if="procesando"
        type="indeterminate"
      ></ion-progress-bar>
    </ion-header>
    <ion-content>
      <ion-row>
        <ion-col no-padding>
          <ion-item>
            <ion-label :position="esMovil ? 'stacked' : ''">Desde</ion-label>
            <ion-input
              class="ion-text-right"
              type="date"
              v-on:keyup.enter="obtener()"
              :value="parseInt(fechaInicio) | moment('YYYY-MM-DD')"
              debounce="500"
              v-on:ionChange="setFecha('fechaInicio', $event.detail.value)"
              :readonly="bloquearFechaPrefacturas"
            >
            </ion-input>
            <ion-button
              slot="end"
              @click="obtener"
              :color="colors('light')"
              fill="clear"
            >
              &nbsp;<i class="icon ion-ios-searchh"></i>&nbsp;
            </ion-button>
          </ion-item>
        </ion-col>
        <ion-col no-padding>
          <ion-item>
            <ion-label :position="esMovil ? 'stacked' : ''">Hasta</ion-label>
            <ion-input
              class="ion-text-right"
              type="date"
              v-on:keyup.enter="obtener()"
              :value="parseInt(fechaFin) | moment('YYYY-MM-DD')"
              debounce="500"
              v-on:ionChange="setFecha('fechaInicio', $event.detail.value)"
              :readonly="bloquearFechaPrefacturas"
            >
            </ion-input>
            <ion-button
              slot="end"
              @click="obtener"
              :color="colors('primary')"
              fill="outline"
            >
              &nbsp;<i class="icon ion-ios-search"></i>&nbsp;
            </ion-button>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-item lines="none">
        <ion-segment
          :value="this.tipoPrefacturas"
          :color="colors('secondary')"
          v-if="prefacturas.length > 0"
        >
          <ion-segment-button
            v-for="e in ['timbrables', 'problemas', 'timbradas']"
            :key="e"
            :value="e"
            v-on:click="setTipoPrefacturas(e)"
            text-capitalize
          >
            {{ e }}
          </ion-segment-button>
        </ion-segment>
      </ion-item>
      <ion-searchbar
        :value="filtro"
        v-on:ionChange="setFiltro($event.detail.value)"
        placeholder="Buscar por cliente"
      ></ion-searchbar>
      <div v-if="facturasTimbrables > 0">
        <ion-item lines="none">
          <ion-label
            >Timbradas {{ facturasTimbradas }}/{{
              facturasTimbrables
            }}</ion-label
          >
        </ion-item>
        <ion-progress-bar
          :value="facturasTimbradas / facturasTimbrables"
        ></ion-progress-bar>
      </div>
      <div v-if="facturasEnviables > 0">
        <ion-item lines="none">
          <ion-label
            >Enviadas {{ facturasEnviadas }}/{{ facturasEnviables }}</ion-label
          >
        </ion-item>
        <ion-progress-bar
          :value="facturasEnviadas / facturasEnviables"
        ></ion-progress-bar>
      </div>
      <table>
        <tbody>
          <tr v-for="(p, indice) in prefacturas" :key="indice">
            <td>
              <ion-item lines="none">
                <ion-note
                  slot="start"
                  :color="colors('primary')"
                  v-if="p.timbrada || p.enviada"
                >
                  <i
                    class="icon ion-ios-code-working icon-lg"
                    v-if="p.timbrada"
                  />
                  <i class="icon ion-ios-mail icon-lg" v-if="p.enviada" />
                </ion-note>
                <ion-label color="medium" class="ion-text-center">
                  <i
                    class="icon ion-ios-checkmark-circle-outline icon-lg"
                    v-if="p.total >= p.totalSugerido"
                  />
                  <i class="icon ion-ios-information-circle icon-lg" v-else />
                </ion-label>
              </ion-item>
            </td>
            <td>
              {{ p.arbol.nombre }}
              <p v-if="p.error">
                {{ p.error }} {{ p.arbol.rfc }} {{ p.arbol.email }}
              </p>
            </td>
            <td class="ion-text-right">
              <span :color="colors('light')" v-if="p.totalSugerido != p.total"
                >{{ p.totalSugerido | currency }}/</span
              >
              {{ p.total | currency }}
            </td>
            <td v-if="prefacturasSeleccionadas.length > 0">
              <ion-item
                lines="none"
                v-if="
                  prefacturasSeleccionadas.indexOf(p._id) >= 0 &&
                  (p.facturas || []).length == 0
                "
              >
                <ion-label position="floating">Metodo</ion-label>
                <ion-select :value="p.metodoPago">
                  <ion-select-option
                    v-for="c in catalogosSAT.metodos"
                    :key="c.Value"
                    :value="c.Value"
                    >{{ c.Value }}</ion-select-option
                  >
                </ion-select>
              </ion-item>
            </td>
            <td v-if="prefacturasSeleccionadas.length > 0">
              <ion-item
                lines=" none"
                v-if="
                  prefacturasSeleccionadas.indexOf(p._id) >= 0 &&
                  (p.facturas || []).length == 0
                "
              >
                <ion-label position="floating">Forma</ion-label>
                <ion-select :value="p.formaPago">
                  <ion-select-option
                    v-for="c in catalogosSAT.formas"
                    :key="c.Value"
                    :value="c.Value"
                    >{{ c.Name }}</ion-select-option
                  >
                </ion-select>
              </ion-item>
            </td>
            <td v-if="prefacturasSeleccionadas.length > 0">
              <ion-item
                lines="none"
                v-if="prefacturasSeleccionadas.indexOf(p._id) >= 0"
              >
                <ion-label position="floating">Serie</ion-label>
                <ion-input
                  :value="p.serie"
                  v-on:ionChange="configurarSerie(indice, $event.detail.value)"
                />
              </ion-item>
            </td>
            <td v-if="prefacturasSeleccionadas.length > 0">
              <ion-item
                lines="none"
                v-if="prefacturasSeleccionadas.indexOf(p._id) >= 0"
              >
                <ion-label position="floating">Folio</ion-label>
                <ion-input
                  :value="p.folio"
                  v-on:ionChange="configurarFolio(indice, $event.detail.value)"
                />
              </ion-item>
            </td>
            <td v-if="prefacturasSeleccionadas.length > 0">
              <ion-item
                lines="none"
                v-if="prefacturasSeleccionadas.indexOf(p._id) >= 0"
              >
                <ion-label position="stacked">Enviar</ion-label>
                <ion-checkbox
                  :checked="p.enviarEmail == true"
                  v-on:ionChange="p.enviarEmail = $event.detail.checked == true"
                ></ion-checkbox>
              </ion-item>
            </td>
            <td>
              <ion-button
                v-on:click="mostrarFacturable(p)"
                color="medium"
                fill="solid"
                size="small"
                title="Mostrar esta prefactura"
              >
                <i class="icon icon-lg ion-ios-code-working"></i>
              </ion-button>
            </td>
            <td class="ion-text-right">
              <span v-if="p.facturas && p.facturas.length > 0"
                >&nbsp;{{ p.facturas.length }}</span
              >
              <ion-button
                v-if="esPagable(p)"
                v-on:click="
                  seleccionarPrefactura(
                    p._id,
                    prefacturasSeleccionadas.indexOf(p._id) >= 0 ? false : true
                  )
                "
                fill="clear"
                :color="esFacturable(p) ? 'primary' : 'medium'"
                size="small"
                title="Seleccionar para timbrado en lote"
              >
                <i
                  class="icon icon-lg ion-ios-radio-button-on"
                  v-if="prefacturasSeleccionadas.indexOf(p._id) >= 0"
                ></i>
                <i class="icon icon-lg ion-ios-radio-button-off" v-else></i>
              </ion-button>
            </td>
          </tr>
        </tbody>
      </table>
    </ion-content>
    <ion-footer v-if="prefacturas.length > 0">
      <ion-progress-bar
        :value="facturasTimbradas / facturasTimbrables"
        v-if="facturasTimbrables > 0"
      ></ion-progress-bar>
      <ion-toolbar :color="colors('primary')">
        <ion-progress-bar
          v-if="guardables > 0"
          :value="guardadas / guardables"
          :color="colors('success')"
        ></ion-progress-bar>
        <ion-buttons slot="end">
          <ion-button
            :color="colors('light')"
            fill="outline"
            @click="timbrarTodas"
            v-if="prefacturasSeleccionadas.length > 0"
          >
            Timbrar
            {{ prefacturasSeleccionadas.length }} seleccionadas</ion-button
          >
          <ion-button
            :color="colors('light')"
            fill="outline"
            @click="guardarTodo"
          >
            <i class="icon ion-ios-save"></i>&nbsp;Guardar todas
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-app>
</template>
<style>
iframe.hummingbird,
.hummingbird {
  display: block;
  float: left;
  width: 100%;
  height: calc(100vh - 10px);
  border: 0px;
  margin: -3px 0px 0px 0px;
  padding: 0px;
}

table {
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 4px;
}

td {
  border-bottom: 2px solid #ccc;
}
</style>
<script>
import Api from "@/components/Api";
//import FacturaLite from '@/components/FacturaLite'
//import Facturama from '@/models/Facturama'
import Cliente from "@/models/Cliente";
//import Emailer from '@/models/Emailer'
export default {
  name: "Prefacturas",
  components: {
    //FacturaLite
  },
  data() {
    return {
      procesando: false,
      yo: Api.obtenerLocal("elite.yo") || {},
      empresa: Api.obtenerLocal("elite.empresadb") || {},
      modoProduccion: Api.modoProduccion && true,
      clientes: [],
      prefacturas: [],
      prefacturasTodas: [],
      esMovil: Api.esMovil(),
      fechaActual: new Date().getTime(),
      partidasUnitariasDefault: true,
      bloquearFechaPrefacturas: false,
      guardables: 0,
      guardadas: 0,
      tipoPrefacturas: "timbrables",
      facturaSeleccionada: {},
      ///servidorHummingbird: ((Api.modoProduccion && true) ? 'https://app.haakon.cc' : 'http://localhost:8081' + '/#'),
      servidorHummingbird: "https://app.haakon.cc",
      idProyecto: "",
      mostrarFacturador: false,
      usarPanelLateral: false,
      filtro: "",
      m: null,
      prefacturasSeleccionadas: [],
      //
      catalogosSAT: {},
      //
      serieDefaultFactura: "A",
      folioInicialFactura: 1,
      serieDefaultPago: "P",
      folioInicialPago: 1,
      //
      facturasTimbrables: 0,
      facturasTimbradas: 0,
      facturasEnviables: 0,
      facturasEnviadas: 0,
      //
    };
  },
  props: {
    fechaInicio: {
      type: Number,
      default: new Date().getTime() - 1000 * 60 * 60 * 24 * 30,
    },
    fechaFin: {
      type: Number,
      default: new Date().getTime(),
    },
  },
  methods: {
    openEnd() {
      Api.$emit("open-menu");
    },
    alert(val) {
      alert(val);
    },
    pos() {
      this.$router.push("pos");
    },
    setFecha(campo, valor) {
      this[campo] =
        new Date(valor).getTime() +
        (new Date().getTimezoneOffset() / 60) * 1000 * 60 * 60;
      console.log("Set?", campo, new Date(this[campo]));
    },
    setTipoPrefacturas(val) {
      this.tipoPrefacturas = val;
      this.filtrarPrefacturas();
    },
    filtrarPrefacturas() {
      console.log("Por implementar");
    },
    async seleccionarPrefactura(id, val) {
      let indice = 0;
      for (let p in this.prefacturas) {
        if (this.prefacturas[p]._id == id) indice = p;
      }
      if (val == true && this.esFacturable(this.prefacturas[indice])) {
        let serie = this.serieDefaultFactura;
        try {
          if (
            this.prefacturas[indice].facturas.length > 0 &&
            this.prefacturas[indice].facturas[0].PaymentMethod.startsWith("PPD")
          ) {
            serie = this.serieDefaultPago;
          }
        } catch (e) {}
        this.prefacturasSeleccionadas.push(id);
        await this.configurarSerie(indice, serie);
      } else {
        this.prefacturasSeleccionadas = this.prefacturasSeleccionadas.filter(
          (u) => u != id
        );
      }
      console.log("Prefactura seleccionada?", val, this.prefacturas[indice]);
      this.$forceUpdate();
    },
    esFacturable(prefactura) {
      prefactura.arbol = prefactura.arbol || {};
      //console.log("Es facturable?", prefactura.arbol.nombre, prefactura.arbol.rfc, prefactura.arbol.email)
      return (
        prefactura.arbol &&
        prefactura.arbol.nombre &&
        prefactura.arbol.rfc &&
        prefactura.arbol.email
      );
    },
    esPagable(prefactura) {
      let pagable = true;
      let saldo = 0;
      for (let timbre of prefactura.facturas || []) {
        if (timbre.Total && !isNaN(timbre.Total)) {
          saldo += parseFloat(timbre.Total);
          //console.log("Saldo++", saldo, saldo)
        } else
          try {
            let pagado = parseFloat(timbre.Complement.Payments[0].Amount);
            console.log("Saldo--", pagado, saldo);
            saldo -= pagado;
          } catch (e) {
            //console.error("Saldo?", e, timbre)
          }
      }
      if (saldo <= 0 && (prefactura.facturas || []).length > 0) pagable = false;
      console.log("Saldo?", prefactura.arbol.nombre, saldo, pagable);
      return pagable;
    },
    async obtener() {
      this.procesando = true;
      this.facturaSeleccionada = {};
      this.facturasTimbrables = 0;
      this.facturasEnviables = 0;
      this.prefacturasSeleccionadas = [];
      let filtros = [
        "fechaInicio,gte," + this.fechaInicio,
        "fechaInicio,lt," + this.fechaFin,
      ];
      console.log("Filtros", filtros);
      let prefacturas = await Api.find("prefactura", filtros);
      //prefacturas = await Api.get('prefactura')
      let vm = this;
      let clientes = await Cliente.lista();
      let clientesIndices = {};
      for (let cl of clientes) clientesIndices[cl._id] = cl;
      // Obtener las facturas ya timbradas
      for (let id in prefacturas) {
        let uid = prefacturas[id]._id;
        let facturas = (
          await Api.find("Facturama", ["idPrefactura,eq," + uid])
        ).concat(await Api.find("Facturama", ["idProyecto,eq," + uid]));
        //let facturas = await Api.get('Facturama')
        if (facturas.length > 0) {
          prefacturas[id].facturas = facturas;
          console.log(
            "Encontradas prefacturas",
            facturas.length,
            prefacturas[id].arbol.nombre,
            facturas
          );
          console.table(facturas);
        }
      }
      // Mapear
      let indicePrefactura = 0;
      prefacturas = prefacturas.map((p) => {
        if (!p.partidas)
          p.partidas = vm.generarPartidas(p.arbol, vm.partidasUnitariasDefault);
        if (p.arbol.acuerdos) {
          for (let idAcuerdo in p.arbol.acuerdos) {
            let acuerdo = p.arbol.acuerdos[idAcuerdo];
            if (acuerdo.fechaCaducidad) {
              let fechaCaducidad = new Date(acuerdo.fechaCaducidad).getTime();
              console.log(
                "Fecha caducidad?",
                p.idCliente,
                p._id,
                fechaCaducidad,
                p.fechaCaducidadMasAntigua
              );
              if (
                !p.fechaCaducidadMasAntigua ||
                fechaCaducidad > p.fechaCaducidadMasAntigua
              ) {
                p.fechaCaducidadMasAntigua = fechaCaducidad;
              }
            }
          }
        }
        p.prefacturaBloqueada =
          this.fechaActual > p.fechaCaducidadMasAntigua ||
          p.fechaCaducidadMasAntigua
            ? 1
            : 0;
        if (p.tipo != "P") p.tipo = "I";
        p.formaPago = "99";
        p.metodoPago = "PPD";
        try {
          let cliente = clientesIndices[p.idCliente] || {};
          //console.log("Cliente?", cliente)
          p.arbol.rfc = cliente.rfc;
          p.arbol.email = cliente.email;
        } catch (e) {
          console.error("ECLIENTEPREFACTURA", e);
        }
        p.enviarEmail = true;
        indicePrefactura++;
        return p;
      });
      this.prefacturas = prefacturas;
      this.prefacturasTodas = JSON.parse(JSON.stringify(prefacturas));
      this.procesando = false;
      this.$forceUpdate();
      console.log("Prefacturas", prefacturas.length, prefacturas[0]);
      return Promise.resolve(true);
    },
    setFiltro(val) {
      this.filtro = (val || "").toUpperCase();
      if (val == "")
        this.prefacturas = JSON.parse(JSON.stringify(this.prefacturasTodas));
      else
        this.prefacturas = this.prefacturasTodas.filter((p) => {
          let nombreCliente = (p.arbol.nombre || "").toUpperCase();
          return nombreCliente.indexOf(val) >= 0;
        });
    },
    logPrefactura(p) {
      console.log("Prefactura", p);
    },
    async subirArchivo(id) {
      try {
        let u = await Api.upload("archivo" + id);
        console.log("u?", Api.cdn, u);
        for (let i in this.prefacturas) {
          if (this.prefacturas[i]._id == id.replace("archivo", "")) {
            this.prefacturas[i].adjunto = Api.cdn + "/" + u;
            this.$forceUpdate();
            alert("Documento adjunto asignado");
          }
        }
      } catch (e) {
        console.error(e);
        alert("No se pudo adjuntar el archivo");
      }
    },
    generarPartidas(arbol, unitaria) {
      arbol = arbol || {};
      unitaria = unitaria && true;
      let partidas = [
        {
          descripcion: "",
          precio: arbol.total,
        },
      ];
      if (unitaria) {
        partidas[0].descripcion =
          "Cobranza del " +
          this.$options.filters.moment(
            parseInt(this.fechaInicio),
            "YYYY-MM-DD"
          ) +
          " al " +
          this.$options.filters.moment(parseInt(this.fechaFin), "YYYY-MM-DD");
        partidas[0].nombreAlternativo =
          "Cobranza del " +
          this.$options.filters.moment(
            parseInt(this.fechaInicio),
            "YYYY-MM-DD"
          ) +
          " al " +
          this.$options.filters.moment(parseInt(this.fechaFin), "YYYY-MM-DD");
        partidas[0].cantidad = 1;
        partidas[0].costo = 0;
        partidas[0].precio = arbol.total;
        partidas[0].producto = {
          nombre: "Servicio de consultoria legal",
          clave: "SCL001",
          costo: 0,
          precio: arbol.total,
          iva: 16,
        };
      } else {
        partidas = [];
        for (let idAsunto in arbol.asuntos)
          for (let idActividad in arbol.asuntos[idAsunto].actividades) {
            let partida = {
              descripcion:
                arbol.asuntos[idAsunto].actividades[idActividad].descripcion,
              precio:
                arbol.asuntos[idAsunto].actividades[idActividad].costoReal,
            };
            partida.nombreAlternativo = partida.descripcion;
            partida.producto = {
              nombre: partida.descripcion,
              precio: partida.precio,
              costo: 0,
              iva: 16,
            };
            partidas.push(partida);
          }
      }
      return partidas;
    },
    async eliminar(pref) {
      if (true == confirm("Desea eliminar esta prefactura?")) {
        await Api.delete("prefactura", pref._id);
        let relaciones = await Api.find("prefacturarelacion", [
          "idPrefactura,eq," + pref._id,
        ]);
        for (let r of relaciones) await Api.delete("prefacturarelacion", r._id);
        alert("Eliminada");
        this.obtener();
      }
    },
    async guardarTodo() {
      this.guardadas = 0;
      this.guardables = this.prefacturas.length;
      for (let p of this.prefacturas)
        try {
          console.log("Guardables?", p.total, p.totalSugerido, p.partidas);
          await Api.save("prefactura", p);
          this.guardadas++;
        } catch (e) {
          console.error(e);
        }
      alert("Prefacturas guardadas");
      this.guardables = 0;
      this.guardadas = 0;
    },
    recalcular() {
      for (let indice in this.prefacturas) {
        this.prefacturas[indice].total = 0;
        //  for (let idAsunto in this.prefacturas[indice].arbol.asuntos)
        for (let partida of this.prefacturas[indice].partidas)
          this.prefacturas[indice].total += partida.precio;
      }
      this.$forceUpdate();
    },
    async eliminarPrefactura(p) {
      if (true == confirm("¿Desea eliminar esta prefactura?")) {
        await Api.delete("prefactura", p._id);
        let relaciones = await Api.find("prefacturarelacion", [
          "idPrefactura,eq," + p._id,
        ]);
        for (let r of relaciones) await Api.delete("prefacturarelacion", r._id);
        alert("Prefactura eliminada");
        this.obtener();
      }
    },
    async eliminarTodo() {
      if (true == confirm("¿Desea limpiar la base de datos?")) {
        let pref = await Api.get("prefactura");
        await Api.deleteMulti(
          "prefactura",
          pref.map((m) => {
            return m._id;
          })
        );
        let rel = await Api.get("prefacturarelacion");
        await Api.deleteMulti(
          "prefacturarelacion",
          rel.map((r) => {
            return r._id;
          })
        );
        console.log("BD eliminada");
      }
    },
    desbloquearFactura(indice, val) {
      this.prefacturas[indice].prefacturaBloqueada = val;
      if (this.prefacturas[indice].prefacturaBloqueada == 0) {
        delete this.prefacturas[indice].arbol.asuntos;
      }
      console.log("desbloquearFactura", indice, val);
      this.$forceUpdate();
    },
    mostrable(f) {
      let esMostrable = true;
      if (!f.fechaCaducidadMasAntigua)
        f.fechaCaducidadMasAntigua = new Date().getTime();
      //console.log("Mostrable?", f.timbrada, f.fechaCaducidadMasAntigua, this.fechaActual)
      switch (this.tipoPrefacturas) {
        case "timbrables":
          esMostrable =
            this.fechaActual <= f.fechaCaducidadMasAntigua && !f.timbrada;
          break;
        case "problemas":
          esMostrable =
            this.fechaActual > f.fechaCaducidadMasAntigua && !f.timbrada;
          break;
        case "timbradas":
          esMostrable = f.timbrada;
      }
      return esMostrable;
    },
    mapearFacturable(f) {
      return Facturama.Custom.mapearFacturable(f);
    },
    async mostrarFacturable(f) {
      let cfdi = await this.mapearFacturable(f);
      console.log("Convirtiendo a facturable", f, cfdi);
      if (cfdi.PaymentForm == "99")
        try {
          cfdi.Complemento =
            await Facturama.Custom.complementoDePagoDesdeFactura(f);
        } catch (e) {
          console.error(e);
        }
      this.cfdiSeleccionado = cfdi;
      let m = await this.$ionic.modalController.create({
        component: FacturaLite,
        componentProps: {
          parent: this,
          props: {
            cfdiImportado: cfdi,
          },
        },
      });
      console.log("Factura seleccionada", f, cfdi);
      await m.present();
      this.m = m;
    },
    cerrarFacturable() {
      this.m.dismiss();
    },
    async obtenerCatalogosSat() {
      console.log("Descargando catalogos");
      this.procesando = true;
      try {
        this.catalogosSAT = Api.obtenerLocal("facturalite.catalogosSAT");
        if (!(this.catalogosSAT.usos && this.catalogosSAT.usos.length > 0))
          throw new Error(
            "No se pudieron recuperar los catálogos guardados localmente."
          );
        if (
          this.catalogosSAT.fecha <
          new Date().getTime() - 1000 * 60 * 60 * 24
        )
          throw new Error(
            "Los catálogos en memoria son muy antiguos. Recuperando nuevos."
          );
      } catch (e) {
        try {
          this.catalogosSAT = {};
          this.catalogosSAT.usos = (await Facturama.Catalogs.CfdiUses()).data;
          this.catalogosSAT.formas = (
            await Facturama.Catalogs.PaymentForms()
          ).data;
          this.catalogosSAT.metodos = (
            await Facturama.Catalogs.PaymentMethods()
          ).data;
          this.catalogosSAT.relaciones = (
            await Facturama.Catalogs.RelationTypes()
          ).data;
          this.catalogosSAT.tiposComprobantes = (
            await Facturama.Catalogs.CfdiTypes()
          ).data;
          this.catalogosSAT.regimenesFiscales = (
            await Facturama.Catalogs.FiscalRegimens()
          ).data;
          this.catalogosSAT.usosCFDI = (
            await Facturama.Catalogs.CfdiUses()
          ).data;
          this.catalogosSAT.fecha = new Date().getTime();
          console.log("SAT recuperados de Facturama", this.catalogosSAT);
          Api.guardarLocal("facturalite.catalogosSAT", this.catalogosSAT);
        } catch (e) {
          console.error("EFACTURAMA", e);
          alert(
            "El servicio de Facturas no está disponible. Puede deberse a su conexión a Internet."
          );
        }
      }
      this.procesando = false;
      console.log("catalogosSAT", this.catalogosSAT);
      this.$forceUpdate();
      return Promise.resolve(true);
    },
    async timbrarTodas() {
      alert("Intentando timbrar las facturas...");
      this.facturasTimbradas = 0;
      this.facturasEnviadas = 0;
      this.facturasTimbrables = 0;
      this.facturasEnviables = 0;
      for (let id of this.prefacturasSeleccionadas) {
        let factura = {};
        let indicePrefactura;
        let enviable = false;
        let cliente = {};
        let uu = 0;
        for (let p of this.prefacturas) {
          if (id == p._id) {
            factura = p;
            this.facturasTimbrables++;
            indicePrefactura = uu;
            factura = await this.mapearFacturable(factura);
            enviable = p.enviarEmail;
            if (enviable == true) this.facturasEnviables++;
            cliente = p.arbol;
            console.log(
              "Intentando timbrar",
              p._id,
              (p.Receiver || {}).Name,
              p
            );
          }
          uu++;
        }
        this.$forceUpdate();
        try {
          // Timbrar
          let facturaTimbrada = await Facturama.Custom.timbrar(
            factura,
            enviable
          );
          this.prefacturas[indicePrefactura].timbrada =
            facturaTimbrada.timbrada;
          this.prefacturas[indicePrefactura].enviada = facturaTimbrada.enviada;
          alert("Factura timbrada correctamente");
          console.log("Factura exitosa", facturaTimbrada);
          this.facturasTimbradas++;
        } catch (e) {
          console.error(e);
          alert("No se pudo timbrar la factura");
          for (let i in this.prefacturas) {
            //console.log("EFACT?", this.prefacturas[i]._id, id)
            if (id == this.prefacturas[i]._id) {
              this.prefacturas[i].error = e;
            }
          }
        }
        this.$forceUpdate();
      }
      alert("Todas las facturas fueron procesadas");
      if (this.facturasTimbradas == this.facturasTimbrables)
        this.prefacturasSeleccionadas = [];
      return Promise.resolve(true);
    },
    async configurarSerie(indice, val) {
      this.prefacturas[indice].serie = val;
      let id = this.prefacturas[indice]._id;
      this.prefacturas[indice].folio =
        await Facturama.Custom.obtenerProximoFolio(
          val,
          this.prefacturasSeleccionadas.indexOf(id)
        );
      this.$forceUpdate();
      console.log(
        "Configurando serie y folio",
        this.prefacturas[indice].folio,
        indice,
        id
      );
      if (val == "P") {
        let complementoPago =
          await Facturama.Custom.complementoDePagoDesdeFactura(
            this.prefacturas[indice]
          );
        this.prefacturas[indice].Complemento = complementoPago;
        console.log(
          "Asignado complemento de pago",
          this.prefacturas[indice].Complemento
        );
      }
      return Promise.resolve(true);
    },
    configurarFolio(indice, val) {
      this.prefacturas[indice].folio = val;
      console.log("Configurando folio", this.prefacturas[indice].folio);
    },
  },
  async mounted() {
    if (!this.modoProduccion) {
      this.fechaInicio = new Date(
        this.$options.filters.moment("2020-01")
      ).getTime();
    }
    await this.obtenerCatalogosSat();
    await this.obtener();
    // Encontrar los folios para facturar
    this.folioInicialFactura = await Facturama.Custom.obtenerProximoFolio(
      this.serieDefaultFactura
    );
    this.folioInicialPago = await Facturama.Custom.obtenerProximoFolio(
      this.serieDefaultPago
    );
    //
    if (this.esMovil) alert("No podrá facturar en un dispositivo móvil");
    //
    this.$on("close", this.cerrarFacturable);
    let vm = this;
    Api.$on("Facturama.CorreoEnviado", (_) => {
      vm.facturasEnviadas++;
    });
  },
  beforeDestroy() {
    Api.$off("Facturama.CorreoEnviado");
    this.$off("close");
  },
};
</script>
