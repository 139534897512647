<template>
<div>
  <ion-item :color="idAsunto==''?'light':''" v-on:click="seleccionarAsunto('')">
    <ion-label :color="colors('success')" :position="esMovil?'stacked':''">Asunto</ion-label>
    <ion-note slot="start" :color="colors('success')">
      <i class="icon ion-ios-list"></i>
    </ion-note>
    <!--ion-note slot="end" :color="colors('primary')" v-if="etiqueta!=''">{{etiqueta}}</ion-note-->
    <ion-textarea rows="2" class="ion-text-right" :value="etiqueta" v-on:ionChange="filtrarPorEtiqueta($event.detail.value)" debounce="500" />
  </ion-item>
  <ion-list v-if="mostrarLista==true">
    <ion-item v-for="a in asuntos" :key="a._id" :value="a._id" v-on:click="seleccionarAsunto(a._id)">
      <ion-note slot="start" :color="colors('primary')">
        <i class="icon ion-ios-checkmark-circle" v-if="idAsunto==a._id"></i>
        <i class="icon ion-ios-arrow-forward" v-else></i>
      </ion-note>
      <ion-label text-wrap>
        <!--h2 :color="colors('secondary')">
          {{(a.area || '').substring(0,3).toUpperCase()}} {{a.nombre}}
        </h2-->
        <h2 :color="colors('secondary')">
          {{a.nombre}}, {{a.expediente}} {{a.juzgado}}
        </h2>
        <p>{{a.area}} {{a.folio}}</p>
      </ion-label>
    </ion-item>
  </ion-list>
</div>
</template>
<script>
import Api from '@/components/Api'
export default {
  name: 'SelectorAsunto',
  props: {
    idCliente: {
      type: String,
      default: ''
    },
    idAsunto: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo'),
      asuntos: [],
      asuntosTodos: [],
      mostrarLista: false,
      etiqueta: ''
    }
  },
  methods: {
    async obtenerAsuntos() {
      if (this.idCliente != '') {
        this.asuntos = await Api.find('asunto', ['idCliente,eq,' + this.idCliente])
        this.asuntos = this.asuntos.filter(a => {
          return !(a.terminado && parseInt(a.terminado) == 1)
        })
        this.asuntosTodos = JSON.parse(JSON.stringify(this.asuntos))
      }
      if (this.idAsunto == '')
        this.mostrarLista = true
      console.log("obtenerAsuntos()", this.mostrarLista, this.idCliente, this.idAsunto)
    },
    seleccionarAsunto(val) {
      console.log("seleccionarAsunto", val)
      this.idAsunto = val
      if (val == '' && this.idCliente != '') {
        console.log("Mostrando todos los asuntos")
        this.obtenerAsuntos()
      }
      Api.$emit('asuntoSeleccionado', val)
    },
    async configurarEtiqueta() {
      if (this.idAsunto != '') {
        let asunto = (await Api.get('asunto', this.idAsunto)) || ''
        this.etiqueta = (asunto.area || '').substring(0, 3).toUpperCase() + ' ' + (asunto.nombre || '') + ' ' + (asunto.folio || '') + ' ' + (asunto.juzgado || '')
        this.$forceUpdate()
        console.log("Etiqueta?", this.etiqueta)
      }
    },
    filtrarPorEtiqueta(val) {
      this.etiqueta = (val || '')
      this.asuntos = this.asuntosTodos.filter(a => {
        let buscable = (val || '').toUpperCase()
        return (a.area || '').toUpperCase().indexOf(buscable) >= 0 ||
          (a.nombre || '').toUpperCase().indexOf(buscable) >= 0
      })
      console.log("Filtrando", val, this.asuntos)
      if (this.asuntos.length == 1)
        this.seleccionarAsunto(this.asuntos[0]._id)
    }
  },
  watch: {
    idCliente: function(a) {
      console.log("watch idCliente?", a)
      this.obtenerAsuntos()
      this.mostrarLista = true
    },
    idAsunto: function(a) {
      console.log("watch idAsunto?", a)
      if (a != '')
        this.mostrarLista = false
      this.configurarEtiqueta()
    }
  },
  mounted() {
    console.log("SelectorAsunto mounted", this.idCliente, this.idAsunto)
    if (this.idAsunto != '')
      this.configurarEtiqueta()
    this.obtenerAsuntos()
  }
}
</script>
