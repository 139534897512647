<template>
<div>
  <div v-if="permiso('ReporteActividadesPorCliente')">
    <div v-if="chartdata && chartdata.datasets && (modoGrafico || modoPruebas)">
      <ion-item :color="colors('light')" lines="none">
        <ion-label>Horas por cliente </ion-label>
        <ion-note slot="end">
          Incluir despacho
        </ion-note>
        <ion-checkbox :checked="incluirBDLG" v-on:ionChange="toggleIncluirBDLG($event.detail.checked==true)" slot="end"></ion-checkbox>
      </ion-item>
      <ChartBarras :chart-data="chartdata" v-if="chartdata.labels" :options="chartoptions" />
      <pre v-if="modoPruebas" class="ion-text-left">{{chartdata}}</pre>
    </div>
    <div v-else>
      <ion-item :color="colors('light')">
        <ion-label>Horas por cliente ({{filtros.fechaInicio | moment('DD-MM-YYYY')}} @ {{filtros.fechaFin | moment('DD-MM-YYYY')}})</ion-label>
        <ion-note slot="end">
          Incluir despacho
        </ion-note>
        <ion-checkbox :checked="incluirBDLG" v-on:ionChange="toggleIncluirBDLG($event.detail.checked==true)" slot="end"></ion-checkbox>
      </ion-item>
      <ion-item :color="colors('light')">
        <ion-label>({{filtros.fechaInicio | moment('DD-MM-YYYY')}} @ {{filtros.fechaFin | moment('DD-MM-YYYY')}})</ion-label>
        <ion-button slot="end" :color="colors('dark')" fill="outline" @click="copiarTabla">
          <i class="icon ion-ios-copy"></i>
        </ion-button>
      </ion-item>
      <div class="scrollable">
        <table id="tabla">
          <thead>
            <tr>
              <th>Cliente</th>
              <th class="ion-text-right">Total (horas)</th>
              <th class="ion-text-right">Mins</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="a in clientes2" :key="a._id">
              <td>{{a.nombre}}</td>
              <td class="ion-text-right">{{a.horasTotal | currency('')}}</td>
              <td class="ion-text-right">{{a.minutosTotal}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div v-else>
    <p>No tiene permisos para este contenido</p>
  </div>
</div>
</template>
<style>
.scrollable {
  width: 100%;
  display: block;
  overflow-x: scroll;
}

table td {
  border: 1px solid #eee;
}

table td,
table th {
  font-size: 14px;
}

.divisor {
  background-color: #eee;
}
</style>
<script>
import Api from '@/components/Api'
import ChartBarras from '@/components/ChartBarras'
import Cliente from '@/models/Cliente'
import Colores from '@/models/Colores'
import Usuario from '@/models/Usuario'
import Reporteable from '@/models/Reporteable'
export default {
  name: 'ReporteHorasPorCliente',
  props: ['filtros', 'abogados', 'clientes', 'areas', 'corporativos', 'unSoloAbogado', 'unSoloCliente', 'modoGrafico', 'numClientes'],
  components: {
    ChartBarras
  },
  filters: {
    filtroTiempo: function(cant) {
      cant = parseFloat(cant)
      //let parteEntera = Math.floor(cant / 60)
      let parteEntera = Math.floor(cant)
      let parteDecimal = (cant % 60).toFixed(0)
      if (parteDecimal.length == 1)
        parteDecimal = '0' + parteDecimal
      return parteEntera + ':' + parteDecimal
    }
  },
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo'),
      actividades: [],
      asuntos: {},
      mapaDiasClientes: {},
      mapaDiasAreas: {},
      clientes2: {},
      clientesTodos: [],
      abogadosTodos: [],
      incluirBDLG: true,
      clienteselite: [],
      etiquetas: [],
      etiquetasClientes: [],
      chartdata: {},
      chartoptions: { // El unico cambio con el modulo ChartBarras es el que no se apilan las barras
        showLines: true,
        cubicInterpolationMode: 'monotone',
        fill: true,
        lineTension: 0,
        scales: {
          xAxes: [{
            stacked: false,
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90
            }
          }],
          yAxes: [{
            stacked: false
          }]
        }
      },
      modoPruebas: !Api.modoProduccion && !Api.obtenerLocal('elite.forzarModoProduccion'),
    }
  },
  methods: {
    toggleIncluirBDLG(val) {
      this.incluirBDLG = val
      this.chartdata = null
      this.clienteselite = []
      if (!this.incluirBDLG)
        for (let c of this.clientesTodos) {
          console.log("Omitible?", c.omitirDeReportes, c.nombre, c)
          if (c.nombre.toLowerCase().indexOf('bufete de la garza') >= 0 ||
            (c.omitirDeReportes && c.omitirDeReportes == true))
            this.clienteselite.push(c._id)
        }
      console.log("Consultando clientes. Omitiendo?", this.clienteselite)
      this.obtener()
    },
    async obtener() {
      console.log("Obteniendo", this.filtros)
      let usuariosIndices = this.abogadosTodos.map(u => u._id)
      let clientesIndices = this.clientesTodos.map(c => c._id)
      let filtros = []
      if (this.filtros.idCliente && this.filtros.idCliente.length == 24)
        filtros.push('idCliente,eq,' + this.filtros.idCliente)
      let resumen = await Reporteable.obtenerResumenActividades(this.filtros.fechaInicio, this.filtros.fechaFin, null, 'dia', filtros)
      let actividades = resumen.actividades
      const filtrosf = this.filtros
      actividades = actividades
        .filter(a => !filtrosf.area || filtrosf.area == '' || filtrosf.area == a.area)
      let clienteselite = this.clienteselite
      actividades = actividades.map(a => {
          a.minutos = parseFloat(a.minutos)
          return a
        })
        .filter(a => {
          return a.idCliente && a.idCliente.length == 24
        })
        .filter(a => {
          return clienteselite.indexOf(a.idCliente) == -1
        })
        .filter(a => {
          return a.idAsunto && a.idAbogado && a.idCliente &&
            clientesIndices.indexOf(a.idCliente) >= 0 &&
            usuariosIndices.indexOf(a.idAbogado) >= 0
        })
      console.log("Actividades", actividades)
      // Mapear a clientes
      this.clientes2 = {}
      this.etiquetas = []
      this.etiquetasClientes = []
      for (let act of actividades) {
        if (!this.clientes2[act.idCliente]) {
          this.etiquetasClientes.push(act.idCliente)
          this.clientes2[act.idCliente] = {
            nombre: '',
            horas: {},
            horasTotal: 0,
            minutosTotal: 0
          }
          //let cl = await Api.get('cliente', act.idCliente)
          let cl = await Cliente.obtener(act.idCliente)
          this.clientes2[act.idCliente].nombre = cl.nombre
        }
        this.clientes2[act.idCliente].horasTotal += parseFloat(act.minutos) / 60
        this.clientes2[act.idCliente].minutosTotal += parseFloat(act.minutos)
        let etiquetaMes = this.$options.filters.moment(act.fechaProgramada, 'YYYY-MM')
        this.etiquetas.push(etiquetaMes)
        if (!this.clientes2[act.idCliente].horas[etiquetaMes])
          this.clientes2[act.idCliente].horas[etiquetaMes] = 0
        this.clientes2[act.idCliente].horas[etiquetaMes] += parseFloat(act.minutos) / 60
      }
      console.log("Clientes2", this.clientes2)
      let clientesOrdenados = []
      for (let idC in this.clientes2)
        clientesOrdenados.push(this.clientes2[idC])
      clientesOrdenados = clientesOrdenados.sort((a, b) => {
        return a.horasTotal < b.horasTotal ? 1 : -1
      })
      this.clientes2 = clientesOrdenados
      // Graficar
      this.etiquetas = [...new Set(this.etiquetas)]
      this.etiquetasClientes = [...new Set(this.etiquetasClientes)]
      this.etiquetas = this.etiquetas.sort((a, b) => {
        return a > b ? 1 : -1
      })
      // Generar grafico
      let chartdata = {
        labels: [],
        datasets: [{
          label: 'Horas por cliente',
          backgroundColor: Colores.get(0),
          borderColor: Colores.get(0),
          data: []
        }]
      }
      clientesOrdenados = clientesOrdenados.slice(0, this.numClientes || 15)
      for (let cliente of clientesOrdenados) {
        //console.log("Agregando cliente a la gráfica", cliente.nombre, cliente.horasTotal)
        chartdata.labels.push((cliente.nombre || '').substring(0, 30))
        chartdata.datasets[0].data.push(cliente.horasTotal || 0)
      }
      this.chartdata = chartdata
      console.log("chartdata", this.chartdata, 'o')
      //
      this.$forceUpdate()
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    copiarTabla() {
      function selectElementContents(el) {
        var body = document.body,
          range, sel;
        if (document.createRange && window.getSelection) {
          range = document.createRange();
          sel = window.getSelection();
          sel.removeAllRanges();
          try {
            range.selectNodeContents(el);
            sel.addRange(range);
          } catch (e) {
            range.selectNode(el);
            sel.addRange(range);
          }
        } else if (body.createTextRange) {
          range = body.createTextRange();
          range.moveToElementText(el);
          range.select();
        }
      }
      let tabla = document.querySelector('#tabla')
      selectElementContents(tabla)
      document.execCommand('copy')
      console.log("Copiado?", tabla.value)
      alert("Copiado al portapapees")
    }
  },
  watch: {
    filtros: function() {
      this.obtener()
    }
  },
  async mounted() {
    console.log("ReporteMisActividades")
    this.clientesTodos = await Cliente.lista()
    this.abogadosTodos = await Usuario.lista()
    this.obtener()
  }
}
</script>
