<template>
<ion-content class="fondo-login" color="">
  <div main>
    <ion-grid>
      <ion-row>
        <ion-col></ion-col>
        <ion-col size="12" size-sm="6" size-md="4">
          <ion-item lines="none" :color="colors('light')">
            <img :src="branding('logo2')" class="logo" style="padding:50px;" />
          </ion-item>
          <ion-item lines="none" :color="colors('light')">
            <ion-label class="ion-text-center" :color="colors('primary')">{{modoAcceso}}</ion-label>
          </ion-item>
          <ion-list>
            <ion-item :color="colors('light')">
              <ion-note slot="start">
                <i class="icon ion-ios-person"></i>
              </ion-note>
              <ion-label>Usuario</ion-label>
              <ion-input class="ion-text-right" color="tertiary" type="email" autocomplete="on" autocorrect="on" autofocus="true" inputmode="email" :value="usuario" v-on:ionChange="set('usuario',$event)"></ion-input>
            </ion-item>
            <ion-item :color="colors('light')">
              <ion-note slot="start">
                <i class="icon ion-ios-lock"></i>
              </ion-note>
              <ion-label>Password</ion-label>
              <ion-input class="ion-text-right" color="tertiary" type="password" inputmode="password" :value="password" v-on:keyup.enter="login" v-on:ionChange="set('password',$event)"></ion-input>
            </ion-item>
            <ion-item :color="colors('light')" lines="none" v-if="!modoProduccionReal">
              <ion-note slot="start" :color="colors('primary')">
                <i class="icon ion-ios-information-circle-outline"></i>
              </ion-note>
              <ion-label>Producción</ion-label>
              <ion-toggle :checked="forzarModoProduccion" slot="end" v-on:ionChange="toggleModoProduccion($event.detail.checked==true)"></ion-toggle>
            </ion-item>
            <ion-button @click="login" :disabled="botonLogin!='Entrar'" expand="block" :color="botonLogin=='Entrar'?'primary':'danger'">
              <i class="icon ion-ios-log-in"></i>&nbsp;{{botonLogin}}
            </ion-button>
          </ion-list>
          <ion-list v-if="permitirRegistroProveedor">
            <ion-row>
              <ion-col>
                <ion-button fill="outline" expand="block" @click="registrar">No estoy registrado</ion-button>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-button fill="clear" :color="colors('secondary')" expand="block" href="./public/manual_proveedores.pdf" target="_blank">Manual de instrucciones</ion-button>
              </ion-col>
            </ion-row>
          </ion-list>
        </ion-col>
        <ion-col @click="togglePermitirRegistroProveedor">
          <ion-item lines="none">
            <ion-label class="ion-text-center">
              <p>&nbsp;</p>
              <p v-if="!modoProduccion">Registro proveedor</p>
            </ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
</template>
<style >
.logo {
  width: 100%;
}

.fondo-login {
  background-image: url('../../public/white.jpg');
  background-color: #224;
  background-size: cover;
  background-position: center;
}

ion-list {
  //background-color: rgba(255, 255, 255, 0.8)
}
</style>
<script>
import Api from '@/components/Api'
import Bitacora from '@/models/Bitacora'
import Usuario from '@/models/Usuario'
import Cliente from '@/models/Cliente'
import Empresa from '@/models/Empresa'
import ModalRegistrarProveedor from '@/components/Proveedores/ModalRegistrar'
import sha1 from 'sha1'
export default {
  name: 'Login',
  data() {
    return {
      usuario: '',
      password: '',
      pin: '',
      botonLogin: 'Entrar',
      forzarModoProduccion: false,
      modoProduccion: Api.modoProduccion,
      modoProduccionReal: window.location.href.indexOf('elite-abogados.mx') >= 0,
      modoAcceso: 'Intranet',
      permitirRegistroProveedor: false,
      modal: null
    }
  },
  methods: {
    set(campo, evento) {
      this[campo] = evento.detail.value
    },
    toggleModoProduccion(val) {
      if (val == true)
        Api.guardarLocal('elite.forzarModoProduccion', 1)
      else
        Api.borrarLocal('elite.forzarModoProduccion')
      console.log("toggleModoProduccion?", val)
      //this.forzarModoProduccion = val
      window.location.reload()
    },
    async login() {
      let vm = this
      vm.botonLogin = 'Entrando...'
      let coincidencias
      try {
        if (this.usuario.indexOf('@') == -1) {
          this.usuario += '@elite-abogados.mx'
          console.log("Sumado el dominio para el usuario", this.usuario)
          this.$forceUpdate()
        }
        this.password = this.password.replace('#', "%23")
        console.log("Buscando", this.usuario, this.password)
        coincidencias = await Api.find('usuario', [
          'password,eq,' + this.password,
          'email,eq,' + this.usuario
        ])
      } catch (e) {
        console.log("No encontrado en 'usuario'. Buscando en 'directorio'.")
        coincidencias = await Api.login(this.usuario, this.password)
        if (coincidencias && coincidencias._id)
          coincidencias = [coincidencias]
        console.log("Buscando por login normal", coincidencias)
      }
      console.log("Usuarios", coincidencias)
      if (coincidencias && coincidencias[0]) {
        console.log("Coincidencias?", coincidencias[0])
        if (!coincidencias[0].desactivado) {
          coincidencias[0].tipo = coincidencias[0].tipo || 'administrador'
          Api.guardarLocal('elite.yo', coincidencias[0])
          Api.save('bitacora', {
            idAutor: coincidencias[0]._id,
            fecha: new Date().getTime(),
            mensaje: 'LOGIN ' + coincidencias[0].email
          })
          Api.$emit('logged-in', coincidencias[0])
          // Poblar los catálogos de clientes y usuarios en la memoria del dispositivo
          let usuarios = await Usuario.lista()
          let clientes = await Cliente.lista()
          let empresa = Empresa.obtenerDatos()
          Api.guardarLocal('elite.empresa', empresa)
          window.location.reload()
        } else {
          alert("Usuario desactivado")
          console.log("Usuario desactivado", coincidencias[0])
        }
      } else {
        this.botonLogin = 'Credenciales incorrectas'
        this.password = ''
      }
      setTimeout(function() {
        vm.botonLogin = 'Entrar'
        vm.$forceUpdate()
      }, 1500)
    },
    togglePermitirRegistroProveedor() {
      if (!Api.modoProduccion)
        this.permitirRegistroProveedor = !this.permitirRegistroProveedor
    },
    registrar() {
      let vm = this
      return this.$ionic.modalController
        .create({
          component: ModalRegistrarProveedor,
          swipeToClose: true,
          componentProps: {
            parent: vm,
            /*propsData: {
              esModal: true
            }//*/
          }
        })
        .then(m => {
          vm.modal = m
          vm.modal.present()
          /*vm.modal.onDidDismiss(async _ => {
            alert("Descargando proveedores")
          })*/
          Api.$on('cerrarModalRegistrarProveedor', _ => {
            vm.modal.dismiss()
          })
        })
    }
  },
  beforeMount() {
    let yo = Api.obtenerLocal('elite.yo') || {}
    let forzarModoProduccion = Api.obtenerLocal('elite.forzarModoProduccion')
    if (forzarModoProduccion && forzarModoProduccion == 1)
      this.forzarModoProduccion = true
    if (yo && yo._id) {
      console.log("Redirigiendo")
      //Api.$emit('logged-in', yo)
      this.$router.push('/hola')
    }
    /*/ Averiguar si venimos del subdominio proveedores
    let location = window.location.hostname
    let subdominio = location.split('.')[0]
    if (['app', '0', 'localhost'].indexOf(subdominio) >= 0)
      subdominio = 'Intranet'
    console.log("Averiguando subdominio", subdominio)
    this.modoAcceso = subdominio.toUpperCase()*/
    this.modoAcceso = (window.location.href.indexOf('proveedores') >= 0) ? 'Proveedores' : 'Intranet'
    if (this.modoAcceso == 'Proveedores')
      this.permitirRegistroProveedor = true
    this.$forceUpdate()
  }
}
</script>
