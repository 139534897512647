<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title @click="clog(facturasingasto)">Subir factura directamente</ion-title>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" v-if="cargando"></ion-progress-bar>
  </ion-header>
  <ion-content v-if="permitirSubirGastos">
    <!--ion-item lines="none" v-if="facturassingasto.length>0">
      <ion-segment :value="modo" v-on:ionChange="modo=$event.detail.value" :color="colors('secondary')">
        <ion-segment-button :value="'subir'">Subir</ion-segment-button>
        <ion-segment-button :value="'anteriores'">Anteriores</ion-segment-button>
      </ion-segment>
    </ion-item-->
    <ion-row v-if="modo=='subir'">
      <ion-col class="ion-no-padding" size-xs="12" size-sm="4">
        <ion-card>
          <ion-item lines="none" :color="colors('light')">
            <label :for="'archivoAdjunto' +facturasingasto._id" class="blok">
              <input type="file" accept="application/pdf" class="hidden" :name="'archivoAdjunto'+facturasingasto._id" :id="'archivoAdjunto'+facturasingasto._id" v-on:change="adjuntar(facturasingasto._id)" />
              <ion-label v-if="facturasingasto.archivoAdjunto" text-wrap class="ion-text-center" :color="colors('primary')">
                <h1>
                  <i class="icon icon-xl ion-ios-document"></i>
                </h1>
                <h2>Factura/PDF</h2>
                <p>{{nombre(facturasingasto.archivoAdjunto)}}</p>
                <p v-if="facturasingasto.archivoAdjuntoFecha">Subido el {{facturasingasto.archivoAdjuntoFecha | moment('MMM DD HH:mm')}}</p>
              </ion-label>
              <ion-label :color="colors('danger')" v-else class="ion-text-center" text-wrap>
                <h1>
                  <i class="icon icon-xl ion-ios-document"></i>
                </h1>
                <h2>Clic para subir su factura o PDF</h2>
                <p>Requerida</p>
              </ion-label>
            </label>
          </ion-item>
        </ion-card>
      </ion-col>
      <ion-col class="ion-no-padding" size-xs="12" size-sm="4">
        <ion-card>
          <ion-item lines="none" :color="colors('light')">
            <label :for="'archivoAdjuntoXml'+facturasingasto._id" class="blok">
              <input type="file" accept="application/xml,text/xml" class="hidden" :name="'archivoAdjuntoXml'+facturasingasto._id" :id="'archivoAdjuntoXml'+facturasingasto._id" v-on:change="adjuntarXml(facturasingasto._id,i)" />
              <ion-label v-if="facturasingasto.archivoAdjuntoXml" text-wrap class="ion-text-center" :color="colors('primary')">
                <h1>
                  <i class="icon icon-xl ion-ios-code-working"></i>
                </h1>
                <h2>XML</h2>
                <p>{{nombre(facturasingasto.archivoAdjuntoXml)}}</p>
                <p v-if="facturasingasto.archivoAdjuntoXmlFecha">Subido el {{facturasingasto.archivoAdjuntoXmlFecha | moment('MMM DD HH:mm')}}</p>
              </ion-label>
              <ion-label :color="colors('danger')" v-else class="ion-text-center" text-wrap>
                <h1>
                  <i class="icon icon-xl ion-ios-code-working"></i>
                </h1>
                <h2>Clic para subir su XML</h2>
                <p>Opcional</p>
              </ion-label>
            </label>
          </ion-item>
        </ion-card>
      </ion-col>
      <ion-col class="ion-no-padding" size-xs="12" size-sm="4">
        <ion-card>
          <ion-item lines="none" :color="colors('light')">
            <label :for="'archivoAdjuntoJustificacion' +facturasingasto._id" class="blok">
              <input type="file" class="hidden" accept="application/pdf" :name="'archivoAdjuntoJustificacion'+facturasingasto._id" :id="'archivoAdjuntoJustificacion'+facturasingasto._id" v-on:change="adjuntarJustificacion(facturasingasto._id,i)" />
              <ion-label v-if="facturasingasto.archivoAdjuntoJustificacion" text-wrap class="ion-text-center" :color="colors('primary')">
                <h1>
                  <i class="icon icon-xl ion-ios-document"></i>
                </h1>
                <h2>Justificación o cotización</h2>
                <p>{{nombre(facturasingasto.archivoAdjuntoJustificacion)}}</p>
                <p v-if="facturasingasto.archivoAdjuntoJustificacionFecha">Subido el {{facturasingasto.archivoAdjuntoJustificacionFecha | moment('MMM DD HH:mm')}}</p>
              </ion-label>
              <ion-label :color="colors('danger')" v-else class="ion-text-center" text-wrap>
                <h1>
                  <i class="icon icon-xl ion-ios-journal"></i>
                </h1>
                <h2>Clic para subir la cotización o requerimiento</h2>
                <p>Requerida</p>
              </ion-label>
            </label>
          </ion-item>
        </ion-card>
      </ion-col>
      <ion-col size="12" v-if="facturasingasto.metadatos">
        <ion-card>
          <ion-item lines="none" color="medium">
            <ion-label>
              <h2>Detalles de este pago</h2>
              <p v-if="!facturasingasto.archivoAdjuntoXml">Suba el XML para completar automáticamente</p>
            </ion-label>
          </ion-item>
          <ion-item v-for="c in camposMetadatos" :key="c.label">
            <ion-label text-wrap>{{c.label}}</ion-label>
            <ion-input class="ion-text-right" :color="colors('primary')" :type="c.tipo || 'text'" :step="c.tipo=='number'?'any':null" :value="facturasingasto.metadatos[c.nombre]"
              v-on:ionChange="facturasingasto.metadatos[c.nombre]=$event.detail.value" />
          </ion-item>
          <ion-item v-if="!modoProduccion">
            <ion-label text-wrap>Fecha de pago tentativa</ion-label>
            <ion-input class="ion-text-right" color="primary" type="date" :value="facturasingasto.fechaProgramadoTentativo" readonly></ion-input>
          </ion-item>
          <ion-item lines="none">
            <ion-label text-wrap>
              &nbsp; </ion-label>
          </ion-item>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-list v-if="modo=='anteriores'">
      <ion-item-sliding v-for="f in facturassingasto" :key="f._id">
        <ion-item>
          <ion-label text-wrap>
            <h2>{{f.metadatos.concepto}}</h2>
            <p>{{f.metadatos.folio}} por {{f.metadatos.cantidad | currency}}</p>
            <p>Subida el {{f.fecha | moment('MMM DD HH:mm')}}, timbrada el {{f.metadatos.fechaFactura | moment('MMM DD HH:mm')}}</p>
          </ion-label>
        </ion-item>
        <ion-item-options slot="end" v-if="!modoProduccion">
          <ion-item-option @click="eliminar(f)" :color="colors('danger')">
            <i class="icon ion-ios-trash"></i>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
    <pre class="ion-text-left" v-if="!modoProduccion">{{facturasingasto}}</pre>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('primary')" @click="guardar" :disabled="cargando || !permitirGuardar()">
        <i class="icon icon-lg ion-ios-save"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
  <ion-content v-else>
    <ion-item lines="none">
      <ion-label class="ion-text-center">
        <h1>
          <i class="icon icon-xl ion-ios-remove-circle-outline"></i>
        </h1>
        <p>Lo sentimos, pero se ha alcanzado el límite de recepción de facturas ({{empresa.diaMaximoRecepcionPagos}})</p>
      </ion-label>
    </ion-item>
  </ion-content>
  <ion-footer v-if="mostrarRecordatorioDiaMaximoRecepcionGastos">
    <ion-item lines="none">
      <ion-note slot="start" color="medium">
        <i class="icon ion-ios-information-circle-outline"></i>
      </ion-note>
      <ion-label text-wrap>
        <p>Le recordamos que la fecha límite de recepción de facturas es el {{empresa.diaMaximoRecepcionPagos | moment('MMM DD, YYYY')}}</p>
      </ion-label>
    </ion-item>
  </ion-footer>
</ion-page>
</template>
<style>
label.blok {
  width: 100%;
  display: block;
  padding: 1em;
}

.icon-xl {
  font-size: 6em;
}
</style>
<script>
import Api from '@/components/Api'
import Emailer from '@/models/Emailer'
import Xml from '@/models/Xml'
import FechasPagoModel from '@/models/FechasPago'
import axios from 'axios'
export default {
  name: 'ProveedoresMisDatos',
  data() {
    return {
      cargando: false,
      modoProduccion: Api.modoProduccion,
      yo: Api.obtenerLocal('elite.yo') || {},
      empresa: Api.obtenerLocal('elite.empresa') || {},
      facturasingasto: {
        idAutor: (Api.obtenerLocal('elite.yo') || {})._id,
        idProveedor: (Api.obtenerLocal('elite.yo') || {})._id,
        fecha: new Date().getTime(),
        metadatos: {
          fechaFactura: new Date().toISOString().substring(0, 10)
        }
      },
      camposMetadatos: [{
          label: 'Folio',
          nombre: 'folio',
          requerido: false
        },
        {
          label: 'Concepto',
          nombre: 'concepto',
          requerido: true
        }, {
          label: 'Cantidad',
          nombre: 'cantidad',
          tipo: "number",
          requerido: true
        }, {
          label: 'Fecha de factura',
          nombre: 'fechaFactura',
          tipo: "date",
          requerido: true
        }, {
          label: 'Nombre de quien solicita',
          nombre: 'solicitante',
          tipo: "text",
          requerido: true
        }
      ],
      facturassingasto: [],
      usuarioConPresupuestoDefault: {},
      calcularFechaDePagoSinXml: true,
      modo: 'subir',
      sugerirGuardarPreguntado: false,
      enviarSoloSiPdfYXmlFueronSubidos: true,
      //
      permitirSubirGastos: true,
      mostrarRecordatorioDiaMaximoRecepcionGastos: false
    }
  },
  async mounted() {
    console.log("ProveedoresSubirSinGasto")
    let vm = this
    this.obtenerFacturasAnteriores()
    //
    try {
      let empresa = await Api.get('empresa')
      empresa = empresa[0]
      if (empresa.idUsuarioPresupuestoDefault)
        this.usuarioConPresupuestoDefault = await Api.get('usuario', empresa.idUsuarioPresupuestoDefault)
    } catch (e) {
      console.error("EEMPRESA", e)
    }
    //
    setInterval(_ => {
      if (
        vm.permitirGuardar() &&
        !this.sugerirGuardarPreguntado) {
        vm.sugerirGuardarPreguntado = true
        vm.sugerirGuardar()
      }
    }, 2500)
    this.buscarFechaPago()
    // Recepcion de gastos
    const fechaActual = new Date().toISOString().substring(0, 10)
    let diaMaximoRecepcionPagos = this.empresa.diaMaximoRecepcionPagos
    //if (true) diaMaximoRecepcionPagos = new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 10))
    console.log("recepcion de gastos", fechaActual, diaMaximoRecepcionPagos)
    if (diaMaximoRecepcionPagos) {
      this.permitirSubirGastos = (fechaActual <= this.empresa.diaMaximoRecepcionPagos)
      const duracionMes = (1000 * 60 * 60 * 24 * 30)
      let nDiaMaximo = new Date(diaMaximoRecepcionPagos).getTime() / duracionMes
      let nDiaActual = new Date(fechaActual).getTime() / duracionMes
      if (Math.abs(nDiaMaximo - nDiaActual) < duracionMes)
        this.mostrarRecordatorioDiaMaximoRecepcionGastos = true
      console.log({
        nDiaMaximo,
        nDiaActual,
        permitirSubirGastos: this.permitirSubirGastos,
        diaMaximoRecepcionPagos: this.empresa.diaMaximoRecepcionPagos
      })
    }
  },
  watch: {
    'facturasingasto.metadatos.fechaFactura': function(a) {
      console.log("$watch fechaFactura", a)
      this.buscarFechaPago()
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    calcularOpinionDeCumplimientoVigente() {
      let vigente
      let fechaMasAntiguaPermitida = new Date().getTime() - (1000 * 60 * 60 * 24 * 30)
      if (!this.usuario.opinionDeCumplimiento ||
        !this.usuario.opinionDeCumplimientoFecha ||
        this.usuario.opinionDeCumplimientoFecha < fechaMasAntiguaPermitida)
        vigente = false
      else vigente = true
      this.opinionDeCumplimientoVigente = vigente
    },
    async subirOpinionDeCumplimiento() {
      console.log("subirOpinionDeCumplimiento?")
      this.cargando = true
      try {
        this.usuario.opinionDeCumplimiento = await Api.upload('opinionDeCumplimiento')
        this.usuario.opinionDeCumplimientoFecha = new Date().getTime()
        this.opinionDeCumplimientoVigente = true
        this.$forceUpdate()
        alert("Opinión de cumplimiento subida")
        let noRecargar = true
        this.guardar(noRecargar)
        await Api.wait(1000)
      } catch (e) {
        console.error("ECUMPLIMIENTO", e)
        alert("No se pudo subir la opinión de cumplimiento")
      }
      this.cargando = false
    },
    nombre(str) {
      str = str || ''
      return str.substring(0, str.length - 41)
    },
    async adjuntar(id) {
      this.facturasingasto.archivoAdjunto = await Api.upload(
        'archivoAdjunto' + id,
        null,
        ('factura.' + this.$options.filters.moment(new Date(), 'YYYYMMDD') + '.pdf')
      )
      this.facturasingasto.archivoAdjuntoFecha = new Date().getTime()
      console.log("archivoAdjunto?", this.facturasingasto.archivoAdjunto)
      this.$forceUpdate()
    },
    async adjuntarJustificacion(id) {
      this.facturasingasto.archivoAdjuntoJustificacion = await Api.upload(
        'archivoAdjuntoJustificacion' + id,
        null,
        ('justificacion.' + this.$options.filters.moment(new Date(), 'YYYYMMDD') + '.pdf')
      )
      this.facturasingasto.archivoAdjuntoJustificacionFecha = new Date().getTime()
      console.log("archivoAdjuntoJustificacion?", this.facturasingasto.archivoAdjuntoJustificacion)
      this.$forceUpdate()
    },
    async adjuntarXml(id) {
      let archivoAdjuntoXml = await Api.upload('archivoAdjuntoXml' + id,
        null,
        ('factura.' + this.$options.filters.moment(new Date(), 'YYYYMMDD') + '.xml')
      )
      let xmlComprobable = await Api.upload('archivoAdjuntoXml' + id, 'binario')
      let permitirAdjuntarXml = true
      try {
        permitirAdjuntarXml = await this.comprobarXml(xmlComprobable)
        if (!permitirAdjuntarXml)
          throw new Error('XML rechazado por el usuario')
      } catch (e) {
        console.error(e)
        alert("No se pudo validar el XML")
      }
      if (permitirAdjuntarXml) {
        this.facturasingasto.archivoAdjuntoXml = archivoAdjuntoXml
        this.facturasingasto.archivoAdjuntoXmlFecha = new Date().getTime()
        this.facturasingasto.aprobadaPorSocio = true
      }
      console.log("archivoAdjunto?", this.facturasingasto.archivoAdjuntoXml)
      this.$forceUpdate()
    },
    async comprobarXml(archivoAdjuntoXml) {
      archivoAdjuntoXml = archivoAdjuntoXml || ''
      let xmlRaw
      if (archivoAdjuntoXml.length > 50)
        xmlRaw = archivoAdjuntoXml
      else
        xmlRaw = (await axios.get(Api.cdn + '/' + archivoAdjuntoXml)).data
      console.log("xmlRaw?", xmlRaw)
      let xmlConvertido = await Xml.aObjeto(xmlRaw)
      let permitirAdjuntarXml = true
      console.log("xmlConvertido?", xmlConvertido)
      let rfcOrigen = xmlConvertido['cfdi:Comprobante']['cfdi:Emisor']['@Rfc']
      let rfcDestino = xmlConvertido['cfdi:Comprobante']['cfdi:Receptor']['@Rfc']
      let rfcOrigenEsperado = this.yo.rfc
      let rfcDestinoEsperado = (Api.obtenerLocal('elite.empresa') || {}).rfc
      if (rfcOrigen != rfcOrigenEsperado)
        permitirAdjuntarXml = confirm('El RFC del proveedor del XML (' + rfcOrigen + ') es distinto al esperado (' + rfcOrigenEsperado + '). ¿Desea continuar?')
      if (rfcDestino != rfcDestinoEsperado)
        permitirAdjuntarXml = confirm('El RFC del receptor del XML (' + rfcDestino + ') es distinto al esperado (' + rfcDestinoEsperado + '). ¿Desea continuar?')
      if (permitirAdjuntarXml) {
        try {
          this.facturasingasto.metadatos = {
            concepto: '',
            cantidad: parseFloat(xmlConvertido['cfdi:Comprobante']['@Total']),
            fechaFactura: new Date(xmlConvertido['cfdi:Comprobante']['@Fecha']).toISOString().substring(0, 10),
            folio: (
                (xmlConvertido['cfdi:Comprobante']['@Serie'] || '') +
                ' ' +
                (xmlConvertido['cfdi:Comprobante']['@Folio'] || '')
              )
              .trim()
          }
          let conceptos = (xmlConvertido['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'].length) ?
            xmlConvertido['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'] : [xmlConvertido['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto']]
          this.facturasingasto.metadatos.concepto = conceptos.map(c => c['@Cantidad'] + ' ' + c['@Descripcion']).join('; ')
        } catch (e) {
          console.error("EASSOC", e)
        }
        return Promise.resolve(permitirAdjuntarXml)
      } else
        return Promise.reject(permitirAdjuntarXml)
    },
    async guardar() {
      //Verificar datos faltantes
      console.log("guardando", this.facturasingasto)
      let camposFaltantes = []
      for (let campo of this.camposMetadatos)
        if (campo.requerido && !(
            this.facturasingasto.metadatos[campo.nombre] &&
            this.facturasingasto.metadatos[campo.nombre] != '')) {
          console.log("Falta campo: ", campo.nombre, this.facturasingasto.metadatos[campo.nombre])
          camposFaltantes.push(campo.nombre)
        }
      else
        this.facturasingasto.metadatos[campo.nombre] = (this.facturasingasto.metadatos[campo.nombre] || '')
        .replace(/%20/g, ' ')
        .replace(/Ã¡/g, 'á')
        .replace(/É/g, "é")
        .replace(/Ã©/g, "é")
        .replace(/Ã­/g, 'í')
        .replace(/Ã³/g, 'ó')
        .replace(/%2C/g, ',')
        .replace(/%24/g, "\$")
        .replace(/%28/g, "(")
        .replace(/%29/g, ")")
        .replace(/Ã±/g, 'ñ')
      // Buscar el folio duplicado
      let folioFacturaExistente = (await Api.get('facturasingasto'))
        .filter(u => !(u.cancelada && u.cancelada > 0))
        .filter(
          u => u.metadatos.folio == this.facturasingasto.metadatos.folio
        )
      if (folioFacturaExistente.length > 0) {
        camposFaltantes.push('folioRepetido')
        alert("¿Ya había subido esta factura antes?")
      }
      if (camposFaltantes.length == 0) {
        // Primero obtener el folio
        console.log("FOLIO averiguando")
        let folioActual = (await Api.find('facturasingasto', [], {
          sort: ['folio,-1'],
          limit: 1
        }))[0] || {}
        console.log("FOLIO actual?", folioActual)
        folioActual = parseInt(folioActual.folio || 0)
        let folioNuevo = folioActual + 1
        this.facturasingasto.folio = folioNuevo
        console.log("FOLIO NUEVO", folioNuevo)
        // Luego guardarla
        this.cargando = true
        // Luego averiguar la fecha de pago más próxima
        const permitirGuardar = true //Api.modoProduccion
        if (permitirGuardar) {
          this.facturasingasto = await Api.save('facturasingasto', this.facturasingasto)
          alert("Factura subida. Enviando correos.")
        }
        // Finalmente los correos
        if (permitirGuardar)
          try {
            alertLegacy("Fecha de pago tentativa: " + this.facturasingasto.fechaProgramadoTentativo)
            /*
            let destinatarios = [this.yo.email, this.usuarioConPresupuestoDefault._id]
            let usuariosContabilidad = (await Api.find('usuario', ['esquemaPermisos,eq,Contabilidad']))
            destinatarios = destinatarios.concat(usuariosContabilidad.map(u => u.email))
            destinatarios = [...new Set(destinatarios)]
            */
            const destinatarios = [this.yo.email]
            let attachments = []
            if (this.facturasingasto.archivoAdjunto) {
              attachments.push({
                filename: 'imprimible.pdf',
                href: Api.cdn + '/' + this.facturasingasto.archivoAdjunto,
                contentType: 'application/pdf'
              })
            }
            if (this.facturasingasto.archivoAdjuntoXml) {
              attachments.push({
                filename: 'cfdi.xml',
                href: Api.cdn + '/' + this.facturasingasto.archivoAdjuntoXml,
                contentType: 'text/xml'
              })
            }
            if (this.facturasingasto.archivoAdjuntoJustificacion) {
              attachments.push({
                filename: 'justificacion.pdf',
                href: Api.cdn + '/' + this.facturasingasto.archivoAdjuntoJustificacion,
                contentType: 'application/pdf'
              })
            }
            let cuerpo1 = 'Se recibio la factura ' +
              (this.facturasingasto.metadatos.folio || 'Sin folio') +
              ', de fecha , ' +
              this.$options.filters.moment(this.facturasingasto.metadatos.fechaFactura, 'MMM DD, YYYY') +
              ', por un importe de ' +
              this.$options.filters.currency(this.facturasingasto.metadatos.cantidad) +
              ', para su pago el dia ' +
              this.$options.filters.moment(this.facturasingasto.fechaProgramadoTentativo, 'MMM DD, YYYY') +
              '(sujeto a autorización). Número de contrarecibo: ' +
              this.facturasingasto.folio +
              '.'
            for (let destino of destinatarios)
              if (destino) {
                let correo = {
                  titulo: 'CFDI para procesar de ' + this.yo.nombre,
                  destino,
                  //cuerpo1: 'El proveedor ' + this.yo.nombre + ' ha subido el una factura para procesar, por ' + this.$options.filters.currency(this.facturasingasto.metadatos.cantidad) + '. Folio de contrarecibo: ' + this.facturasingasto.folio,
                  cuerpo1,
                  cuerpo2: ' ',
                  attachments
                }
                console.log("Enviando a", destino, correo,
                  this.facturasingasto.archivoAdjunto,
                  this.facturasingasto.archivoAdjuntoXml,
                  this.enviarSoloSiPdfYXmlFueronSubidos)
                if ( //this.enviarSoloSiPdfYXmlFueronSubidos &&
                  (this.facturasingasto.archivoAdjunto ||
                    this.facturasingasto.archivoAdjuntoJustificacion)) {
                  await Emailer.enviar(correo)
                }
              }
          } catch (e) {
            console.error(e);
            alert("Deberás llamar por teléfono al despacho para avisar de tu factura")
          }
        this.cargando = false
        //this.obtenerFacturasAnteriores()
        await Api.wait(5000)
        //window.location.reload()
        this.$router.push('/proveedor/misventas')
      } else
        alert("Faltan datos: " + camposFaltantes.join(', '))
    },
    sugerirGuardar() {
      if (this.facturasingasto.archivoAdjuntoXml &&
        true == confirm('Los archivos están completos. ¿Desea guardar su factura ahora?')) {
        this.guardar()
      }
    },
    async obtenerFacturasAnteriores() {
      this.cargando = true
      this.facturassingasto = (await Api.find('facturasingasto', ['idProveedor,eq,' + this.yo._id]))
        .sort((a, b) => a.fecha < b.fecha ? 1 : -1)
      this.cargando = false
      if (this.facturassingasto.length == 0)
        this.modo = 'subir'
    },
    clog(obj) {
      console.log("CLOG", obj)
    },
    async buscarFechaPago() {
      let fechaFactura = new Date().getTime() // Siempre la fecha actual
      //if (!this.modoProduccion) fechaFactura = new Date(this.facturasingasto.metadatos.fechaFactura)
      let fechaDePago = await FechasPagoModel.encontrarFechaDePagoMasCercana(fechaFactura, this.yo)
      this.facturasingasto.pagoDias = fechaDePago.pagoDias
      this.facturasingasto.fechaProgramadoTentativo = fechaDePago.fechaProgramado
      console.log("fechaDePago", this.facturasingasto.metadatos.fechaProgramadoTentativo, fechaFactura, fechaFactura)
      this.$forceUpdate()
    },
    permitirGuardar() {
      return this.facturasingasto &&
        this.facturasingasto.archivoAdjuntoJustificacion &&
        (this.facturasingasto.archivoAdjunto ||
          this.facturasingasto.archivoAdjuntoXml) &&
        !this.facturasingasto._id
    },
    async eliminar(f) {
      if (true == confirm('¿Está seguro?')) {
        await Api.delete('facturasingasto', f._id)
        alert("Factura eliminada. Puede volverla a subir si es necesario.")
        this.obtenerFacturasAnteriores()
      }
    }
  }
}
</script>
