<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Partidas presupuestales</ion-title>
    </ion-toolbar>
    <ion-progress-bar v-if="cargando" type="indeterminate"></ion-progress-bar>
  </ion-header>
  <ion-content>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item>
            <ion-label>
              <h2>Presupuestos</h2>
            </ion-label>
          </ion-item>
          <ion-item-sliding v-for="i in presupuestos" :key="i._id">
            <ion-item @click="seleccionarPresupuesto(i)">
              <ion-label text-wrap v-if="i && i.nombre" :color="presupuestoSeleccionado._id==i._id?'medium':''">
                <h2>{{i.nombre}}</h2>
                <p>Creado {{i.fechaCreado | moment('MMM DD')}}</p>
                <p v-if="mapaUsuarios[i.idUsuarioAsignado]">Designado a {{mapaUsuarios[i.idUsuarioAsignado].nombre}}</p>
              </ion-label>
              <ion-label v-else>{{i}}</ion-label>
              <ion-note slot="end">
                <!--span v-if="i.recibeFacturasSingasto"><i class="icon ion-ios-star"></i></span-->
                {{i.cantidadAsignada | currency()}}
              </ion-note>
            </ion-item>
            <ion-item-options slot="end">
              <ion-item-option :color="colors('danger')" v-if="yo.rol=='administrador'" @click="eliminar(i)">
                Eliminar</ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </ion-list>
      </ion-col>
      <ion-col v-if="presupuestoSeleccionado._id" class="ion-no-padding">
        <ion-card>
          <ion-list>
            <ion-item-divider>Uso de Presupuesto {{presupuestoSeleccionado.nombre}}</ion-item-divider>
            <ion-item :color="colors('secondary')">
              <ion-label>Ejercido</ion-label>
              <ion-note slot="end">{{resumen.erogado | currency}}</ion-note>
            </ion-item>
            <ion-item :color="colors('secondary')">
              <ion-label>Aprobado para gasto</ion-label>
              <ion-note slot="end">{{resumen.cotizado | currency}}</ion-note>
            </ion-item>
            <ion-item :color="colors('secondary')">
              <ion-label>Aprobado para pago</ion-label>
              <ion-note slot="end">{{resumen.previsto | currency}}</ion-note>
            </ion-item>
            <ion-item :color="colors('secondary')">
              <ion-label>Total</ion-label>
              <ion-note slot="end">{{presupuestoSeleccionado.cantidadAsignada | currency}}</ion-note>
            </ion-item>
            <ion-item :color="colors('secondary')">
              <ion-label>Saldo real</ion-label>
              <ion-note slot="end">{{presupuestoSeleccionado.cantidadAsignada-resumen.erogado | currency}}</ion-note>
            </ion-item>
            <ion-item :color="colors('secondary')">
              <ion-label>Saldo sin comprometer</ion-label>
              <ion-note slot="end">{{presupuestoSeleccionado.cantidadAsignada-resumen.cotizado | currency}}</ion-note>
            </ion-item>
            <ion-item :color="colors('secondary')">
              <ion-label>Porcentaje completo</ion-label>
              <ion-note slot="end">{{resumen.erogado/resumen.total*100 | currency('')}}</ion-note>
            </ion-item>
            <ion-row>
              <ion-col v-for="(cantidad,rubro) in resumen.rubros" :key="rubro" v-if="cantidad!=0" class="ion-no-padding">
                <ion-item :color="colors('tertiary')">
                  <ion-label class="ion-text-capitalize">{{rubro}}</ion-label>
                  <ion-note slot="end">{{cantidad | currency}}</ion-note>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-list>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed" v-if="permiso('EditarPresupuestos')">
      <ion-fab-button :color="colors('danger')" href="#/presupuestos/partidas/0">
        <i class="icon icon-lg ion-ios-add"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
export default {
  name: 'PresupuestosPartidas',
  data() {
    return {
      presupuestos: [],
      yo: Api.obtenerLocal('elite.yo') || {},
      modoProduccion: Api.modoProduccion,
      mapaUsuarios: {},
      cargando: false,
      presupuestoSeleccionado: {},
      gastos: {},
      resumen: {
        erogado: 0,
        previsto: 0,
        cotizado: 0,
        total: 0
      }
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async obtener() {
      this.cargando = true
      this.presupuestos = (await Api.get('presupuesto'))
        .sort((a, b) => (a.fechaCreado < b.fechaCreado) ? 1 : -1)
        .filter(p => !p.recibeFacturasSingasto || !Api.modoProduccion)
      this.cargando = false
      this.$forceUpdate()
    },
    async seleccionarPresupuesto(p) {
      console.log("seleccionarPresupuesto", p._id, this.presupuestoSeleccionado._id)
      if (p._id == this.presupuestoSeleccionado._id)
        this.$router.push('/presupuestos/partidas/' + p._id)
      else {
        this.presupuestoSeleccionado = p
        this.gastos = (await Api.find('presupuestogasto', ['idPresupuesto,eq,' + this.presupuestoSeleccionado._id]))
          .filter(g => g.nombre && g.nombre != '')
        console.log("Gastos?", this.gastos)
        this.resumen = {
          erogado: 0,
          previsto: 0,
          cotizado: 0,
          total: this.presupuestoSeleccionado.cantidadAsignada,
          rubros: {}
        }
        for (let g of this.gastos) {
          console.log("Evaluando gasto", g.rubro)
          if (!this.resumen.rubros[g.rubro])
            this.resumen.rubros[g.rubro] = 0
          let etapas = await Api.find('gastoetapa', ['idGasto,eq,' + g._id])
          this.resumen.cotizado += parseFloat(g.cantidadCotizada)
          for (let e of etapas) {
            console.log("Evaluando etapa?", e)
            if (e.idSocioAprueba && e.idSocioAprueba != '')
              this.resumen.previsto += parseFloat(e.cantidadErogable)
            if (!e.requiereAprobacionSocio || e.terminada) {
              this.resumen.erogado += parseFloat(e.cantidadErogable)
              this.resumen.rubros[g.rubro] += parseFloat(e.cantidadErogable)
            }
          }
        }
      }
    },
    async eliminar(p) {
      if (true == confirm('¿Deseas eliminar el presupuesto ' + p.nombre + '? Se borrarán también los gastos asociados.')) {
        await Api.delete('presupuesto', p._id)
        alert("Presupuesto eliminado. Recargue la app para limpiar los gastos huérfanos.")
        await Api.wait(1000)
        this.obtener()
      }
    },
    async limpiar() {
      console.log("Limpiando...")
      this.cargando = true
      let eliminables = {
        presupuestogasto: [],
        gastoetapa: []
      }
      let existentes = {
        presupuesto: await Api.get('presupuesto'),
        presupuestogasto: await Api.get('presupuestogasto'),
        gastoetapa: await Api.get('gastoetapa')
      }
      // Indizar etapas que no pertenezcan a ningún gasto
      for (let gasto of existentes.presupuestogasto)
        if (existentes.presupuesto.map(u => u._id).indexOf(gasto.idPresupuesto) == -1)
          eliminables.presupuestogasto.push(gasto._id)
      // Volver a obtener los gastos para mapear las etapas
      existentes.presupuestogasto = await Api.get('presupuestogasto')
      // Indizar gastos que no pertenezcan a ningún presupuesto
      for (let etapa of existentes.gastoetapa)
        if (existentes.presupuestogasto.map(u => u._id).indexOf(etapa.idGasto) == -1)
          eliminables.gastoetapa.push(etapa._id)
      console.log("Existentes", existentes)
      console.log("Eliminables", eliminables)
      // Ejecutar
      let eliminados = 0
      for (let coleccion in eliminables) {
        for (let elem of eliminables[coleccion]) {
          //if (false)
          await Api.delete(coleccion, elem)
          eliminados++
        }
      }
      if (eliminados > 0)
        alert("Eliminados " + eliminados + " elementos huérfanos")
      this.cargando = false
      this.$forceUpdate()
    }
  },
  async mounted() {
    console.log("PresupuestosPartidas mounted")
    let usuarios = await Api.get('usuario')
    for (let u of usuarios)
      this.mapaUsuarios[u._id] = u
    this.obtener()
    if (this.yo.rol == 'administrador')
      this.limpiar()
  }
}
</script>
