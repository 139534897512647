<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('danger')">
      <ion-title>Catalogos - Conceptos facturables ({{conceptossatTodos.length}})</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button @click="csv" v-if="yo.rol=='administrador'">
          <i class="icon ion-ios-download"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar :value="cargados/cargables" v-if="cargables>0"></ion-progress-bar>
  </ion-header>
  <ion-content class="content">
    <ion-searchbar placeholder="Filtrar por nombre" debounce="500" :value="filtro" v-on:ionChange="filtrar($event.detail.value)"></ion-searchbar>
    <ion-list id="lista">
      <ion-item color="medium">
        <ion-label>Proveedores</ion-label>
        <label for="inputcscv" v-if="yo.rol=='administrador'">
          <ion-note :color="colors('light')" slot="end">
            <input class="hidden" type="file" name="inputcscv" id="inputcscv" v-on:change="subirCsv()" />
            <i class="icon ion-ios-cloud-upload"></i>
          </ion-note>
        </label>
      </ion-item>
      <ion-item-sliding v-for="u in conceptossat" :key="u._id">
        <ion-item v-on:click="ver(u._id)">
          <ion-label text-wrap :color="u.desactivado?'warning':''">
            <h2>{{u.nombre}}</h2>
            <p text-capitalize>
              {{u.claveUnidadSat}} {{u.claveProductoSat}}
            </p>
          </ion-label>
          <ion-note slot="end">{{u.email}}</ion-note>
        </ion-item>
        <ion-item-options slot="end">
          <ion-item-option :color="colors('danger')" v-on:click="eliminar(u._id)" v-if="permiso('BorrarConceptosFacturables')">
            <ion-icon name="trash"></ion-icon> Eliminar
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed" v-if="permiso('CrearConceptosFacturables')">
      <ion-fab-button :color="colors('danger')" v-on:click="ver(0)">
        <i class="icon icon-lg ion-ios-add"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
export default {
  name: 'CatalogoConceptossat',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      conceptossat: [],
      conceptossatTodos: [],
      filtro: '',
      cargados: 0,
      cargables: 0
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    pos() {
      this.$router.push('pos')
    },
    async obtener() {
      this.conceptossatTodos = await Api.get('conceptosat')
      let conceptosOriginales = JSON.parse(JSON.stringify(this.conceptossatTodos))
      this.conceptossatTodos = this.conceptossatTodos
        .map(u => {
          u.nombre = u.nombre
            .replace(/%20/g, ' ')
            .replace(/Ã¡/g, 'á')
            .replace(/É/g, "é")
            .replace(/Ã©/g, "é")
            .replace(/Ã­/g, 'í')
            .replace(/Ã³/g, 'ó')
            .replace(/%2C/g, ',')
            .replace(/%24/g, "\$")
            .replace(/%28/g, "(")
            .replace(/%29/g, ")")
            .replace(/Ã±/g, 'ñ')
          return u
        })
      console.log("Objetos", this.conceptossatTodos.length)
      //
      for (let conceptoOriginal of conceptosOriginales)
        for (let conceptoNuevo of this.conceptossatTodos)
          if (conceptoNuevo.claveProductoSat == conceptoOriginal.claveProductoSat && conceptoNuevo.nombre != conceptoOriginal.nombre)
            await Api.save('conceptosat', conceptoNuevo)
      //
      this.filtrar(this.filtro)
      return Promise.resolve(true)
    },
    filtrar(val) {
      console.log("Filtrando", this.conceptossatTodos.length, val, typeof val)
      val = (val || '').toLowerCase()
      let vm = this
      this.filtro = val
      if (val == '') {
        this.conceptossat = JSON.parse(JSON.stringify(this.conceptossatTodos))
        console.log("Mostrando todos los conceptossat", this.conceptossat.length, this.conceptossatTodos.length)
      } else {
        this.conceptossat = this.conceptossatTodos.filter(cl => {
          return (cl.nombre || '').toLowerCase().indexOf(val) >= 0 ||
            (cl.claveProductoSat || '').toLowerCase().indexOf(val) >= 0 ||
            (cl.claveUnidadSat || '').toLowerCase().indexOf(val) >= 0
          // || vm.tienePermiso(cl, val)
        })
      }
      console.log("Filtrados", val, this.conceptossat.length)
    },
    ver(id) {
      this.$router.push('/catalogos/conceptossat/' + id)
    },
    async eliminar(id) {
      if (true == confirm('Desea eliminar este elemento? Esto no se puede deshacer')) {
        let u = await Api.delete('conceptosat', id)
        Api.borrarLocal('elite.conceptosat.' + id)
        this.obtener()
        let lista = document.querySelector('#lista')
        if (lista)
          await lista.closeSlidingItems()
      }
    },
    csv() {
      let txt = ''
      for (let u of this.conceptossat) {
        let datos = [u.nombre, u.email, u.password, u.fechaAlta ? this.$options.filters.moment(new Date(u.fechaAlta), 'YYYY-MM-DD') : '', u.fechaBaja ? this.$options.filters.moment(new Date(u.fechaBaja), 'YYYY-MM-DD') : '']
        //console.log("D?", datos)
        let renglon = datos.join(',') + '\n\r'
        txt += renglon
      }
      console.log("Txt?", txt.length)
      Api.downloadForce(txt, 'elite.proveedores.csv')
    },
    async subirCsv() {
      let archivo = await Api.upload('inputcscv', 'binario')
      this.cargados = 0
      this.cargables = 0
      archivo = archivo.split('\n')
      this.cargables = archivo.length
      console.log("archivo?", archivo)
      let existentes = JSON.parse(JSON.stringify(this.conceptossat))
      for (let concepto of archivo) {
        concepto = concepto.split(',')
        let claveProductoSat = concepto[0]
        let nombre = concepto[1]
        if (nombre && nombre != '' && claveProductoSat && claveProductoSat != '') {
          let guardable = {
            nombre,
            claveProductoSat,
            claveUnidadSat: 'ACT' // E48
          }
          for (let existente of existentes)
            if (existente.claveProductoSat == guardable.claveProductoSat)
              guardable._id = existente._id
          await Api.save('conceptosat', guardable)
          this.cargados++
        }
      }
      alert("Conceptos cargados")
      await Api.wait(1000)
      this.cargados = 0
      this.cargables = 0
      this.obtener()
    }
  },
  mounted() {
    this.obtener()
  }
}
</script>
