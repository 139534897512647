<script>
import Vue from 'vue'
import Api from '../components/Api'
let rfcMostrador = 'XAXX010101000'
let correoMostrador = 'mostrador@mail.tk'
let nombreMostrador = 'Publico en general'
export default new Vue({
  name: 'Cliente',
  data() {
    return {
      form: [{
          label: 'Nombre',
          field: 'nombre',
          size: 4,
          required: true
        }, {
          label: 'RFC',
          field: 'rfc',
          size: 12,
          required: false
        },
        /*{
               label: 'Teléfono',
               field: 'telefono',
               size: 4,
               required: false
             }, {
               label: 'Correo',
               field: 'email',
               size: 5,
               required: false
             }, {
               label: 'Dirección',
               field: 'direccion',
               size: 8,
               required: false
             }, {
               label: 'Tipo de cobranza default',
               field: 'tipoDefault',
               size: 12,
               required: false,
               type: 'select',
               options: ['horario', 'iguala'],
               requiere: 'reportaCostos'
             }, {
               label: 'Tarifa Socio',
               field: 'tarifa_socio',
               type: 'number',
               size: 8,
               required: false,
               requiere: 'reportaCostos'
             }, {
               label: 'Tarifa Abogado',
               field: 'tarifa_abogado',
               type: 'number',
               size: 8,
               required: false,
               requiere: 'reportaCostos',
             }, {
               label: 'Tarifa Pasante',
               field: 'tarifa_pasante',
               type: 'number',
               size: 8,
               required: false,
               requiere: 'reportaCostos'
             }, {
               label: 'Tarifa administrativo',
               field: 'tarifa_administrativo',
               type: 'number',
               size: 8,
               required: false,
               requiere: 'reportaCostos'
             }
           */
      ]
    }
  },
  methods: {
    formulario() {
      return this.form
    },
    mostrador() {
      return new Promise(async (resolve, reject) => {
        let clienteMostrador = await Api.find('cliente', ['rfc,eq,' + rfcMostrador])
        if (clienteMostrador && clienteMostrador.length > 0) {
          resolve(clienteMostrador[0])
        } else {
          alert("No ha sido instalado el cliente de mostrador. Haciendolo ahora. ")
          let cl = {
            nombre: nombreMostrador,
            rfc: rfcMostrador,
            email: correoMostrador,
            rol: 'cliente',
            fechaAlta: new Date().getTime()
          }
          let s = Api.save('cliente', cl)
          console.log("Guardado cliente mostrador", s._id)
          resolve(s)
        }
      })
    },
    lista(forzarDescarga) {
      forzarDescarga = forzarDescarga && true
      forzarDescarga = true // Pos bueno 
      return new Promise(async (resolve, reject) => {
        try {
          let clientes
          try {
            clientes = []
            for (let indice in localStorage) {
              if (indice.startsWith('elite.cliente')) {
                let cl = Api.obtenerLocal(indice)
                clientes.push(cl)
                //console.log("elite.cliente", indice,cl)
              }
            }
            console.log("Clientes recuperados de cache?", clientes.length)
            if (clientes.length == 0 || forzarDescarga == true) {
              throw new Error('Lista de usuarios no en cache')
            }
          } catch (e) {
            console.warn(e)
            clientes = await Api.get('cliente')
            for (let cliente of clientes)
              Api.guardarLocal('elite.cliente.' + cliente._id, cliente)
          }
          clientes = clientes
            .sort((a, b) => {
              return (a.nombre || '').toLowerCase() > (b.nombre || '').toLowerCase() ? 1 : -1
            })
            .filter(c => {
              if (c.fechaBaja)
                return c.fechaBaja && c.fechaBaja != 0
              else
                return true
            })
          console.log("Clientes encontrados", clientes.length)
          resolve(clientes)
        } catch (e) {
          console.error("ELISTA", e)
          resolve([])
        }
      })
    },
    async obtener(id) {
      id = id || ''
      if (id.length == 24) {
        try {
          let cliente = Api.obtenerLocal('elite.cliente.' + id)
          if (!(cliente && cliente._id)) {
            throw new Error('Cliente no en caché')
          } else return new Promise((resolve) => {
            resolve(cliente)
          })
        } catch (e) {
          return Api.get('cliente', id)
        }
      } else { // Buscar por RFC
        let clientes = await Api.find('cliente', ['rfc,eq,' + id], {
          limit: 1
        })
        if (clientes && clientes[0] && clientes[0]._id)
          return new Promise((resolve) => {
            resolve(clientes[0])
          })
        else
          throw new Error('Cliente no encontrado (' + id + ')')
      }
    },
    guardar(c) {
      return Api.save('cliente', c)
    },
    eliminar(c) {
      this.obtener(typeof c == 'string' ? c : c._id)
        .then(cl => {
          cl.idAutorElimina = (Api.obtenerLocal('elite.yo') || {})._id
          cl.fechaElimina = new Date().getTime()
          Api.save('clienteEliminado', cl)
          Api.save('bitacora', {
            objeto: cl,
            mensaje: 'Cliente eliminado ' + cl.nombre,
            idAutor: cl.idAutorElimina,
            fecha: cl.fechaElimina
          })
        })
      if (typeof c == 'string')
        return Api.delete('cliente', c)
      else if (c._id)
        return Api.delete('cliente', c._id)
    }
  }
})
</script>
