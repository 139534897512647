<template>
  <div class="ion-page" main>
    <ion-header>
      <ion-toolbar :color="colors('secondary')">
        <ion-title>Abogado {{ usuario.nombre || "nuevo" }}</ion-title>
        <ion-buttons slot="start">
          <ion-button @click="openEnd">
            <i class="icon ion-ios-menu"></i>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="content">
      <ion-item>
        <ion-segment :value="modo" @ionChange="modo = $event.detail.value">
          <ion-segment-button :value="'general'">General</ion-segment-button>
          <ion-segment-button :value="'permisos'">Permisos</ion-segment-button>
          <ion-segment-button :value="'web'">Web</ion-segment-button>
        </ion-segment>
      </ion-item>
      <ion-list mode="ios" v-if="modo == 'general'">
        <ion-item-divider>General</ion-item-divider>
        <ion-item
          v-for="f in formUsuario"
          v-if="f.name != 'rol' || !modoProduccion"
          :key="f.name"
        >
          <ion-label position="">{{ f.label }}</ion-label>
          <ion-input
            v-if="!f.type || f.type != 'select'"
            debounce="100"
            :value="usuario[f.name]"
            :color="colors('primary')"
            class="ion-text-right"
            v-on:ionChange="setU(f.name, $event.detail.value)"
            :type="f.type || 'text'"
          ></ion-input>
          <ion-select
            v-if="f.type && f.type == 'select'"
            :color="colors('primary')"
            v-on:ionChange="setU(f.name, $event.detail.value)"
            :value="usuario[f.name]"
            class="ion-text-right"
          >
            <ion-select-option
              v-for="o in f.options"
              :key="o"
              text-capitalize
              :value="o"
              >{{ o | uppercase }}</ion-select-option
            >
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label position="">Módulo de inicio por defecto</ion-label>
          <ion-select
            :value="usuario.rutaDefault"
            :color="colors('primary')"
            class="ion-text-right"
            v-on:ionChange="usuario.rutaDefault = $event.detail.value"
          >
            <ion-select-option
              v-for="r in rutasDefault"
              :key="r.ruta"
              :value="r.ruta"
              >{{ r.label }}</ion-select-option
            >
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label position="">Socio responsable</ion-label>
          <ion-select
            :value="usuario.idSocioResponsable"
            :color="colors('primary')"
            class="ion-text-right"
            v-on:ionChange="usuario.idSocioResponsable = $event.detail.value"
          >
            <ion-select-option
              v-for="r in socios"
              :key="r._id"
              :value="r._id"
              >{{ r.nombre }}</ion-select-option
            >
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label>Usuario activo</ion-label>
          <ion-toggle
            :checked="!usuario.desactivado"
            v-on:ionChange="
              usuario.desactivado = $event.detail.checked == false
            "
          ></ion-toggle>
        </ion-item>
        <!--ion-item>
            <ion-label>Usuario adscrito a México</ion-label>
            <ion-toggle :checked="usuario.mexico" v-on:ionChange="usuario.mexico=($event.detail.checked==true)"></ion-toggle>
          </ion-item-->
        <ion-item>
          <ion-label>Fecha de alta </ion-label>
          <ion-input
            type="date"
            class="ion-text-right"
            debounce="1000"
            :value="usuario.fechaAlta | moment('YYYY-MM-DD')"
            v-on:ionChange="
              usuario.fechaAlta =
                new Date($event.detail.value).getTime() + 1000 * 60 * 60 * 6
            "
            :readonly="yo.rol != 'administrador'"
          />
        </ion-item>
        <ion-item>
          <ion-label>Fecha de baja </ion-label>
          <ion-input
            type="date"
            class="ion-text-right"
            debounce="1000"
            :value="usuario.fechaBaja | moment('YYYY-MM-DD')"
            v-on:ionChange="
              usuario.fechaBaja =
                new Date($event.detail.value).getTime() + 1000 * 60 * 60 * 6
            "
          />
        </ion-item>
        <ion-item lines="none">&nbsp;</ion-item>
      </ion-list>
      <ion-list
        mode="ios"
        v-if="
          modo == 'permisos' && usuario && usuario.permisos && mostrarPermisos
        "
      >
        <ion-item-divider>Permisos</ion-item-divider>
        <ion-item v-for="(p, indice) in usuario.permisos" :key="indice">
          <ion-label>
            <h2>{{ indice }}</h2>
          </ion-label>
          <ion-checkbox
            mode="ios"
            :checked="usuario.permisos[indice]"
            :color="colors('primary')"
            class="ion-text-right"
            v-on:ionChange="setPermiso(indice, $event.detail.checked == true)"
            slot="end"
          ></ion-checkbox>
        </ion-item>
      </ion-list>
      <div v-if="modo == 'web'">
        <ion-item>
          <ion-label>Mostrar perfil en sitio Web</ion-label>
          <ion-toggle
            :checked="usuariopublic.mostrarEnWeb"
            @ionChange="toggleMostrarEnWeb($event.detail.checked == true)"
          />
        </ion-item>
        <ion-list v-if="usuariopublic.mostrarEnWeb">
          <ion-item>
            <ion-label>Nombre para mostrar</ion-label>
            <ion-input
              class="ion-text-right"
              :value="usuariopublic.nombre"
              @ionChange="usuariopublic.nombre = $event.detail.value"
            />
          </ion-item>
          <ion-item>
            <ion-label>Título profesional</ion-label>
            <ion-input
              class="ion-text-right"
              :value="usuariopublic.titulo"
              @ionChange="usuariopublic.titulo = $event.detail.value"
            />
          </ion-item>
          <ion-item>
            <ion-label>Puesto</ion-label>
            <ion-input
              class="ion-text-right"
              :value="usuariopublic.puesto"
              @ionChange="usuariopublic.puesto = $event.detail.value"
            />
          </ion-item>
          <ion-item>
            <ion-label>Cardinalidad</ion-label>
            <ion-input
              class="ion-text-right"
              :value="usuariopublic.cardinalidad"
              type="number"
              step="1"
              min="1"
              @ionChange="usuariopublic.cardinalidad = $event.detail.value"
            />
          </ion-item>
          <ion-item>
            <ion-label position="">Descripción</ion-label>
            <ion-select
              :value="lang"
              @ionChange="lang = $event.detail.value"
              interface="popover"
            >
              <ion-select-option value="es">español</ion-select-option>
              <ion-select-option value="en">ingles</ion-select-option>
            </ion-select>
            <!--ion-textarea
              :value="usuariopublic.descripcion"
              @ionChange="usuariopublic.descripcion = $event.detail.value"
            /-->
          </ion-item>
          <div v-if="mostrarDescripcion">
            <ckeditor
              v-if="lang == 'es'"
              v-model="usuariopublic.descripcion_es"
            ></ckeditor>
            <ckeditor
              v-if="lang == 'en'"
              v-model="usuariopublic.descripcion_en"
            ></ckeditor>
          </div>
          <ion-item>
            <ion-label>Email (vacío para ocultar)</ion-label>
            <ion-input
              class="ion-text-right"
              :value="usuariopublic.email"
              @ionChange="usuariopublic.email = $event.detail.value"
            />
          </ion-item>
          <ion-item>
            <ion-label>Teléfono (vacío para ocultar)</ion-label>
            <ion-input
              class="ion-text-right"
              :value="usuariopublic.telefono"
              @ionChange="usuariopublic.telefono = $event.detail.value"
            />
          </ion-item>
          <label for="vcard">
            <input
              accept="text/*"
              type="file"
              class="hidden"
              name="vcard"
              id="vcard"
              @change="subir('vcard')"
            />
            <ion-item>
              <ion-label
                ><h2>Vcard</h2>
                <p>Clic para subir/cambiar</p></ion-label
              ></ion-item
            >
          </label>
          <label for="imagen">
            <input
              type="file"
              accept="image/*"
              class="hidden"
              name="imagen"
              id="imagen"
              @change="subir('imagen')"
            />
            <ion-item>
              <ion-label
                ><h2>Fotografía</h2>
                <p>Clic para subir/cambiar</p>
                <p>{{ usuariopublic.imagen }}</p>
              </ion-label>
              <ion-thumbnail
                v-if="usuariopublic.imagen && usuariopublic.imagen != ''"
              >
                <ion-img :src="cdn + usuariopublic.imagen"></ion-img>
              </ion-thumbnail>
            </ion-item>
          </label>
          <pre v-if="!modoProduccion">{{ usuariopublic }}</pre>
        </ion-list>
      </div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ion-fab
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        v-if="
          permiso('EditarUsuarios') ||
          (permiso('CrearUsuarios') && !usuario._id)
        "
      >
        <ion-fab-button :color="colors('primary')" @click="guardar">
          <i class="icon ion-ios-save icon-lg"></i>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </div>
</template>

<script>
import Api from "@/components/Api";
import Usuario from "@/models/Usuario";
import Empresa from "@/models/Empresa";
//import { thumbsDown } from "../../../public/ionicons/icons";
import CKEditor from "ckeditor4-vue/dist/ckeditor";
export default {
  name: "CatalogoUsuario",
  props: ["id"],
  components: { ckeditor: CKEditor.component },
  filters: {
    uppercase: function (u) {
      return (u || "").toUpperCase();
    },
  },
  data() {
    return {
      yo: Api.obtenerLocal("elite.yo") || {},
      cdn: Api.cdn,
      modo: "general",
      usuario: {
        rol: "usuario",
        fechaAlta: new Date().getTime(),
        permisos: this.permisos(),
      },
      usuariopublic: {},
      permisosAcl: {},
      empresa: {},
      modoProduccion: Api.modoProduccion,
      socios: [],
      formUsuario: [
        {
          label: "Nombre",
          name: "nombre",
          required: true,
        },
        {
          label: "Iniciales",
          name: "iniciales",
          required: true,
        },
        {
          label: "Email",
          name: "email",
          required: true,
        },
        {
          label: "Contraseña",
          name: "password",
          type: "password",
          required: true,
        },
        /*{
          label: 'Rol',
          name: 'rol',
          required: true,
          type: 'select',
          options: ['administrador', 'abogado', 'supervisor', 'cliente']
        },*/
        {
          label: "Tipo de abogado",
          name: "tipo",
          required: true,
          type: "select",
          options: [
            "director",
            "socio",
            "asociado",
            "abogado jr",
            "administrativo",
            "pasante",
          ],
        },
        {
          label: "Esquema de permisos",
          name: "esquemaPermisos",
          required: true,
          type: "select",
          options: [],
        },
      ],
      rutasDefault: [
        {
          ruta: "/asuntos",
          label: "Asuntos",
        },
        {
          ruta: "/actividades",
          label: "Actividades",
        },
        {
          ruta: "/actividad",
          label: "Nueva actividad",
        },
      ],
      mostrarPermisos: this.permiso("editarUsuarios") || !Api.modoProduccion,
      lang: "es",
      mostrarDescripcion: true,
    };
  },
  watch: {
    lang: async function () {
      this.mostrarDescripcion = false;
      await Api.wait(100);
      this.mostrarDescripcion = true;
    },
  },
  methods: {
    openEnd() {
      Api.$emit("open-menu");
    },
    setU(campo, valor) {
      this.usuario[campo] = valor;
      if (campo == "tipo") {
        //this.usuario.rol = valor
        console.log("Cambiando tipo de usuario", campo, valor);
      }
      console.log("Configurado", campo, valor);
      if (campo == "esquemaPermisos") {
        this.usuario.permisos = JSON.parse(
          JSON.stringify(this.permisosAcl[valor])
        );
        this.usuario.rol =
          valor == "Administrador" || valor == "administrador"
            ? "administrador"
            : "usuario";
        console.log("Cambiando permisos", valor, this.usuario.permisos);
        this.$forceUpdate();
      }
    },
    toggleMostrarEnWeb(val = false) {
      this.usuariopublic.mostrarEnWeb = val;
      if (val == true) {
        for (let prop of ["_id", "nombre", "email", "telefono"])
          this.usuariopublic[prop] = this.usuario[prop];
        this.$forceUpdate();
      }
    },
    setPermiso(campo, valor) {
      if (!this.usuario.permisos)
        try {
          this.usuario.permisos = JSON.parse(
            JSON.stringify(this.permisosDefault(this.usuario.esquemaPermisos))
          );
        } catch (e) {
          this.usuario.permisos = {};
          console.error(e);
        }
      this.usuario.permisos[campo] = valor;
      console.log("Configurado", campo, valor, this.usuario.rol);
    },
    async subir(identificador = "") {
      const param =
        identificador == "imagen"
          ? await Api.upload(identificador, "", this.usuario._id, true)
          : await Api.upload(identificador);
      this.usuariopublic[identificador] = param;
      console.log("Subido", identificador, param);
      this.$forceUpdate();
    },
    async guardar() {
      let guardable = true;
      for (let c of this.formUsuario) {
        if (c.required && !this.usuario[c.name]) {
          alert("Faltan datos: " + c.name);
          guardable = false;
        }
      }
      // Duplicados
      let duplicado = false;
      if (!this.usuario._id) {
        let usuarios = await Usuario.lista();
        for (let u of usuarios) {
          if (
            u.nombre == this.usuario.nombre ||
            u.email == this.usuario.email
          ) {
            console.error("Usuario duplicado", this.usuario, u);
            duplicado = true;
          }
        }
      }
      //
      if (duplicado) {
        alert("Usuario duplicado");
      } else if (guardable) {
        let usuario = await Api.save("usuario", this.usuario);
        if (!this.usuario._id && usuario._id) this.usuario._id = usuario._id;
        //this.usuario = usuario
        Api.guardarLocal("elite.usuario." + usuario._id, this.usuario);
        alert("Guardado");
        if (this.usuariopublic.mostrarEnWeb)
          Api.save("abogadopublic", this.usuariopublic);
        //if (this.usuariopublic._id)
        else Api.delete("abogadopublic", this.usuariopublic._id);
        await Api.wait(1000);
        this.$router.push("/catalogos/usuarios");
      }
    },
  },
  async mounted() {
    //
    //await Api.deleteMulti(   "abogadopublic", (await Api.get("abogadopublic")).map((u) => u._id) );
    let areas = await Api.get("area");
    areas = areas.map((a) => {
      return a.nombre;
    });
    let campoArea = {
      label: "Área predeterminada",
      name: "areaDefault",
      required: false,
      type: "select",
      options: areas,
    };
    this.formUsuario.push(campoArea);
    //
    console.log("Catalogo usuario", this.id);
    if (this.id && this.id.length == 24) {
      let obtenerPassword = true;
      try {
        this.usuario = await Api.get("usuario", this.id);
      } catch (e) {
        this.usuario = await Usuario.obtener(this.id, obtenerPassword);
      }
      try {
        let usuariopublic = await Api.get("abogadopublic", this.id);
        this.usuariopublic = usuariopublic;
      } catch (e) {
        console.error(e);
      }
      console.log("Usuario recuperado", this.usuario);
      this.$forceUpdate();
    }
    this.permisosAcl = await Empresa.obtenerPermisosDesdeAcl();
    this.formUsuario[5].options = Object.keys(this.permisosAcl);
    this.socios = (await Usuario.lista()).filter((s) => {
      return (
        s.rol == "socio" ||
        s.rol == "director" ||
        s.tipo == "socio" ||
        s.rol == "director"
      );
    });
    if (this.yo.rol == "administrador")
      this.formUsuario[4].options = ["administrador"].concat(
        this.formUsuario[4].options
      );
    console.log("Socios?", this.socios, this.yo.rol);
    console.log("Permisos?", this.permisos());
    if (this.usuario._id)
      try {
        let usuariopublic = await Api.get("abogadopublic", this.usuario._id);
        if (usuariopublic._id) {
          this.usuariopublic = usuariopublic;
          if (
            !this.usuariopublic.descripcion_es &&
            this.usuariopublic.descripcion
          )
            this.usuariopublic.descripcion_es = this.usuariopublic.descripcion;
        }
      } catch (e) {
        console.error("EUSUARIOPUBLIC", e);
      }
  },
};
</script>
