<script>
import Vue from 'vue'
let ColoresModel = new Vue({
  name: 'Colores',
  data() {
    return {
      colores: [
        'adb16b', '975a76', '8ba564', '6b4a77',
        '8c9049', '642342', '6c8644', '421e4f',
        'd0d49a', 'b39299', 'b0c68f', '84698e'
      ]
    }
  },
  methods: {
    get(indice) {
      indice = indice || 0
      let color = '#'
      if (indice < this.colores.length)
        color += this.colores[indice]
      else
        color += this.colores[this.colores.length % indice]
      return color
    },
    random() {
      var color = "#" + Math.floor(Math.random() * 16777216).toString(16)
      console.log("Unacceptaboooool!", color)
      if (color.length == 7)
        return color
      else
        return this.random()
    }
  }
})
export default ColoresModel
</script>
