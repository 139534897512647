<script>
import Vue from 'vue'
import Api from '@/components/Api'
import axios from 'axios'
let Empresa = new Vue({
  name: 'Empresa',
  data() {
    return {
      datosEmpresa: {
        nombre: 'Despacho legal',
        nombreCorto: 'legal',
        tarifasPorRol: {
          'director': 3500,
          'socio': 3000,
          'asociado': 2500,
          'administrativo': 2000,
          'pasante': 2000
        },
        permisosDefault: {
          administrador: {
            crearClientes: true,
            editarClientes: true,
            crearUsuarios: true,
            editarUsuarios: true,
            crearActividades: true,
            editarActividades: true,
            borrarActividades: true,
            crearAsuntos: true,
            editarAsuntos: true,
            borrarAsuntos: true,
            verTarifas: true,
            editarTarifas: true,
            reporteDashboard: true,
            gestionarPresupuestos: true,
            reporteActividadesPorCliente: true,
            reporteHorasPorCliente: true,
            reporteHorasPorArea: true,
            reportePorcentajeDeCaptura: true,
            reporteHorasMexico: true,
            reporteActividadesPorAbogado: true,
            reporteActividadesPorClienteConMontos: true,
            facturar: true,
            consultarFacturas: true
          },
          director: {
            crearClientes: true,
            editarClientes: true,
            crearUsuarios: true,
            editarUsuarios: true,
            crearActividades: true,
            editarActividades: true,
            borrarActividades: true,
            crearAsuntos: true,
            editarAsuntos: true,
            borrarAsuntos: true,
            verTarifas: true,
            editarTarifas: true,
            reporteDashboard: true,
            gestionarPresupuestos: false,
            reporteActividadesPorCliente: true,
            reporteHorasPorCliente: true,
            reporteHorasPorArea: true,
            reportePorcentajeDeCaptura: true,
            reporteHorasMexico: true,
            reporteActividadesPorAbogado: true,
            reporteActividadesPorClienteConMontos: true,
            facturar: true,
            consultarFacturas: true
          },
          socio: {
            crearClientes: true,
            editarClientes: true,
            crearUsuarios: true,
            editarUsuarios: true,
            crearActividades: true,
            editarActividades: true,
            borrarActividades: true,
            crearAsuntos: true,
            editarAsuntos: true,
            borrarAsuntos: true,
            verTarifas: true,
            editarTarifas: true,
            gestionarPresupuestos: true,
            reporteActividadesPorCliente: true,
            reporteHorasPorCliente: true,
            reporteHorasPorArea: true,
            reportePorcentajeDeCaptura: true,
            reporteHorasMexico: true,
            reporteActividadesPorAbogado: true,
            reporteActividadesPorClienteConMontos: true,
            facturar: true,
            consultarFacturas: true
          },
          asociado: {
            crearClientes: true,
            editarClientes: false,
            crearUsuarios: true,
            editarUsuarios: false,
            crearActividades: true,
            editarActividades: true,
            borrarActividades: false,
            crearAsuntos: true,
            editarAsuntos: true,
            borrarAsuntos: false,
            verTarifas: false,
            editarTarifas: false,
            gestionarPresupuestos: false,
            reporteActividadesPorCliente: true,
            reporteHorasPorCliente: false,
            reporteHorasPorArea: false,
            reportePorcentajeDeCaptura: false,
            reporteHorasMexico: false,
            reporteActividadesPorAbogado: false,
            reporteActividadesPorClienteConMontos: false,
            facturar: false,
            consultarFacturas: false
          },
          administrativo: {
            crearClientes: true,
            editarClientes: true,
            crearUsuarios: true,
            editarUsuarios: false,
            crearActividades: true,
            editarActividades: true,
            borrarActividades: false,
            crearAsuntos: true,
            editarAsuntos: true,
            borrarAsuntos: false,
            verTarifas: true,
            editarTarifas: true,
            gestionarPresupuestos: false,
            reporteActividadesPorCliente: true,
            reporteHorasPorCliente: true,
            reporteHorasPorArea: true,
            reportePorcentajeDeCaptura: true,
            reporteHorasMexico: true,
            reporteActividadesPorAbogado: true,
            reporteActividadesPorClienteConMontos: true,
            facturar: true,
            consultarFacturas: true
          },
          pasante: {
            crearClientes: false,
            editarClientes: false,
            crearUsuarios: false,
            editarUsuarios: false,
            crearActividades: true,
            editarActividades: false,
            borrarActividades: false,
            crearAsuntos: false,
            editarAsuntos: false,
            borrarAsuntos: false,
            verTarifas: false,
            editarTarifas: false,
            gestionarPresupuestos: false,
            reporteActividadesPorCliente: true,
            reporteHorasPorCliente: false,
            reporteHorasPorArea: false,
            reportePorcentajeDeCaptura: false,
            reporteHorasMexico: false,
            reporteActividadesPorAbogado: false,
            reporteActividadesPorClienteConMontos: false,
            facturar: false,
            consultarFacturas: false,
          },
          cliente: {
            crearClientes: false,
            editarClientes: false,
            crearUsuarios: false,
            editarUsuarios: false,
            crearActividades: false,
            editarActividades: false,
            borrarActividades: false,
            crearAsuntos: false,
            editarAsuntos: false,
            borrarAsuntos: false,
            verTarifas: false,
            editarTarifas: false,
            gestionarPresupuestos: false,
            reporteActividadesPorCliente: true,
            reporteHorasPorCliente: false,
            reporteHorasPorArea: false,
            reportePorcentajeDeCaptura: false,
            reporteHorasMexico: false,
            reporteActividadesPorAbogado: false,
            reporteActividadesPorClienteConMontos: true,
            facturar: false,
            consultarFacturas: true
          }
        },
        // SAT
        rfc: 'AAA010101AAA',
        calle: 'Siempre viva',
        numeroExterior: '123',
        numeroInterior: 'A',
        codigoPostal: '78000',
        colonia: 'Centro',
        ciudad: 'SLP',
        estado: 'SLP',
        pais: 'MX',
        regimenFiscal: '601',
        satCodigoProductoDefault: '01010101',
        satCodigoMedidaDefault: 'H87',
        iva: 16,
        isr: 0,
        ieps: 0,
        isrret: 10,
        ivaret: 10.3333,
        iepsret: 0
      }
    }
  },
  methods: {
    obtenerDatos() {
      return this.datosEmpresa
    },
    tarifa(val) {
      return this.datosEmpresa.tarifasPorRol[val]
    },
    async obtenerPermisosDesdeAcl() {
      let permisosDefault = {}
      // Cargar los permisos de la ACL
      let acl = (await axios.get('./acl.csv')).data
      acl = acl.split('\n')
      console.log("ACL?", acl.length)
      let columnas = [],
        indicePerfiles = {},
        todasLasAcciones = []
      for (let renglon in acl) {
        if (renglon == 0) {
          columnas = acl[0].split(',')
          for (let columna in columnas)
            if (!columnas[columna].startsWith('_')) {
              permisosDefault[columnas[columna]] = {}
              indicePerfiles[columna] = columnas[columna]
            }
          console.log("Indices de perfiles", indicePerfiles)
        } else {
          renglon = acl[renglon].split(',')
          let nombreAccion = renglon[1]
          todasLasAcciones.push(nombreAccion)
          for (let columna in renglon) {
            let perfilPorComprobar = indicePerfiles[columna] || indicePerfiles[parseInt(columna)]
            if (perfilPorComprobar) {
              if (!permisosDefault[perfilPorComprobar][nombreAccion])
                permisosDefault[perfilPorComprobar][nombreAccion] = false
              if (renglon[columna].toLowerCase() == 'x')
                permisosDefault[perfilPorComprobar][nombreAccion] = true
            }
          }
        }
      }
      permisosDefault.Administrador = {}
      for (let accion of todasLasAcciones)
        permisosDefault.Administrador[accion] = true
      console.log("permisosDefault desde ACL", permisosDefault)
      this.permisosDefault = permisosDefault
      this.datosEmpresa.permisosDefault = permisosDefault
      return Promise.resolve(permisosDefault)
    }
  },
  async created() {
    let empresa = await Api.get('empresa')
    if (empresa && empresa[0]) {
      this.datosEmpresa = empresa[0]
    }
    Api.guardarLocal('elite.empresa', this.datosEmpresa)
    console.log("Empresa guardada localmente", this.datosEmpresa)
    await this.obtenerPermisosDesdeAcl()
  }
})
export default Empresa
</script>
