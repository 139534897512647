<template>
  <ion-app main>
    <ion-header>
      <ion-toolbar :color="colors('secondary')">
        <ion-buttons slot="end">
          <ion-button @click="guardar">
            <i class="icon ion-ios-checkmark-circle"></i>
          </ion-button>
        </ion-buttons>
        <ion-title>Post {{ post.titulo }}</ion-title>
        <ion-buttons slot="start">
          <ion-button @click="openEnd">
            <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
            <i class="icon ion-ios-menu"></i>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="content" padding>
      <ion-list>
        <ion-item>
          <ion-label position="floating"> Título </ion-label>
          <ion-input
            :value="post.titulo"
            @ionChange="post.titulo = $event.detail.value"
          />
        </ion-item>
        <ion-item>
          <ion-label position="floating">Slug</ion-label>
          <ion-input
            :value="post.slug"
            @ionChange="post.slug = $event.detail.value"
          />
        </ion-item>
        <ion-item>
          <ion-label position=""> Contenido </ion-label>
        </ion-item>
        <ckeditor v-model="post.contenido" class="ckeditorpost" />
        <ion-item>
          <ion-label> Publicado </ion-label>
          <ion-toggle
            slot="end"
            :checked="!post.borrador"
            @ionChange="post.borrador = !$event.detail.checked"
          />
        </ion-item>
        <label for="imagen">
          <input
            type="file"
            accept="image/*"
            class="hidden"
            name="imagen"
            id="imagen"
            @change="subirImagen"
          />
          <ion-item>
            <ion-label
              ><h2>Imagen</h2>
              <p>Clic para subir/cambiar. De preferencia WEBP.</p>
              <p>{{ post.imagen }}</p>
            </ion-label>
            <ion-thumbnail v-if="post.imagen && post.imagen != ''">
              <ion-img :src="cdn + post.imagen"></ion-img>
            </ion-thumbnail>
          </ion-item>
        </label>
      </ion-list>
      <!--pre v-if="!modoProduccion">{{ post }}</pre-->
    </ion-content>
  </ion-app>
</template>
<style scoped>
.content {
  /*background-image: url('http://alherrera.tk/demos/elite.vue/dist/public/fondo_azul2.png');*/
  background-position: center;
}
.ckeditorpost {
  border: 2px solid #dddddd;
  display: block;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  height: 40vh;
  min-height: 280px;
}
</style>
<script>
import Api from "@/components/Api";
const yo = Api.obtenerLocal("elite.yo") || {};
//import Vue from "vue";
import CKEditor from "ckeditor4-vue/dist/ckeditor";
//Vue.use(CKEditor);
export default {
  name: "Post",
  components: { ckeditor: CKEditor.component },
  data() {
    return {
      yo,
      modoProduccion: Api.modoProduccion,
      cdn: Api.cdn,
      post: {
        titulo: "",
        slug: "",
        contenido: "",
        nombreAutor: yo.nombre,
        idAutor: yo._id,
        imagen: "",
        borrador: true,
        fechaPublicado: new Date().getTime(),
      },
      //editorConfig:
    };
  },
  watch: {
    "post.titulo": function (a) {
      this.post.slug = a
        .replace(/[.*+¿?¡!^${}()|[\]\\áéíóú]/g, "")
        .trim()
        .replace(/ /g, "-")
        .toLowerCase();
    },
  },
  props: {
    id: {
      type: String,
      default: "0",
    },
  },
  async mounted() {
    this.obtener();
  },
  methods: {
    openEnd() {
      Api.$emit("open-menu");
    },
    async obtener() {
      if (this.id != "0") this.post = await Api.get("postpublic", this.id);
    },
    async subirImagen() {
      this.post.imagen = await Api.upload("imagen");
      alert("Foto subida");
    },
    async guardar() {
      await Api.save("postpublic", this.post);
      alert("guardado");
      this.$router.go(-1);
    },
  },
};
</script>
