<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('danger')">
      <ion-title>Catalogos - Productos</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <ion-icon name="menu" :color="colors('light')"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-list>
      <ion-item-divider>Productos
        <ion-icon slot="end" name="refresh" v-on:click="obtener()"></ion-icon>
      </ion-item-divider>
      <ion-item-sliding v-for="u in productos" :key="u._id" v-on:click="ver(u._id)">
        <ion-item>
          {{u.nombre}}
          <ion-icon slot="end" :color="colors('warning')" v-if="u.unidadMedidaSat && u.productoSat" name="switch"></ion-icon>
          <ion-note slot="end" :color="colors('dark')">{{u.precio1 | currency}}</ion-note>
        </ion-item>
        <ion-item-options slot="end">
          <ion-item-option :color="colors('danger')" v-on:click="eliminar(u._id)">
            <ion-icon name="trash"></ion-icon>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('danger')" v-on:click="ver(0)">
        <ion-icon name="add"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
import Producto from '@/models/Producto'
export default {
  name: 'CatalogoProductos',
  data() {
    return {
      yo: {},
      productos: [],
      familias: []
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    pos() {
      this.$router.push('pos')
    },
    async obtener() {
      this.productos = await Producto.lista()
      console.log("Objetos", this.productos)
    },
    ver(id) {
      this.$router.push('/catalogos/producto/' + id)
    },
    eliminar(id) {
      if (true == confirm('Desea eliminar este elemento? Esto no se puede deshacer')) {
        Api.delete('producto', id).then(_ => {
          this.obtener()
        })
      }
    }
  },
  mounted() {
    this.yo = Api.obtenerLocal('wst.yo')
    if (!(this.yo && this.yo._id))
      console.warn("No has iniciado sesión")
    Api.get('familia').then(function(f) {
      this.familias = f
    })
    this.obtener()
  }
}
</script>

<style>

</style>
