<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Pagos ({{filtro}}: {{suma | currency}})</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="reporteCsv" :color="colors('secondary')" fill="solid">
          <i class="icon ion-ios-download"></i>&nbsp;Reporte
        </ion-button>
        <ion-button @click="obtener">
          <i class="icon ion-ios-refresh"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label>Hasta fecha de pago </ion-label>
          <ion-select class="ion-text-right" :value="fechaBusquedaFin" v-on:ionChange="cambiarFechaBusquedaFin($event.detail.value)">
            <ion-select-option v-for="f in fechasPago" :key="f">{{f}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
      <ion-col>
        <ion-item>
          <ion-label>Mostrar</ion-label>
          <ion-select :value="filtro" v-on:ionChange="cambiarFiltro($event.detail.value)">
            <ion-select-option v-for="u in ['Todos','Pendientes','Terminados']" :key="u" :value="u">{{u}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
      <ion-col>
        <ion-item>
          <ion-label>Para proveedor</ion-label>
          <ion-select class="ion-text-right" :value="idProveedor" v-on:ionChange="idProveedor=$event.detail.value;cambiarFiltro(filtro)">
            <ion-select-option :value="''">Todos</ion-select-option>
            <ion-select-option v-for="f in proveedores" :value="f._id" :key="f._id">{{f.nombre}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col :size-md="(pago._id)?4:12">
        <ion-list>
          <ion-item-sliding v-for="p in pagos" :key="p._id">
            <ion-item :color="p._id==pago._id?'light':''">
              <!--ion-checkbox slot="start" :checked="(p.fechaTerminada && p.fechaTerminada>0) || (filtro=='Pendientes'&&seleccionadoParaPagar(p))" @click="seleccionarParaPagar(p)"></ion-checkbox-->
              <ion-note slot="start" @click="seleccionarParaPagar(p)">
                <i class="icon icon-lg ion-ios-checkmark-circle" v-if="(p.fechaTerminada && p.fechaTerminada>0) || (filtro=='Pendientes'&&seleccionadoParaPagar(p))"></i>
                <i class="icon icon-lg ion-ios-checkmark-circle-outline" v-else></i>
              </ion-note>
              <ion-label @click="ver(p)" text-wrap>
                <h2 class="ion-text-uppercase">{{p.gasto.nombre}}</h2>
                <p>{{p.proveedor.nombre}} {{p.nombre}}</p>
                <p>Programada para {{p.fechaProgramado | moment('MMM DD')}}</p>
              </ion-label>
              <ion-note slot="end" @click="ver(p)">{{(p.cantidadErogable || p.cantidad) | currency}}</ion-note>
            </ion-item>
          </ion-item-sliding>
          <ion-item lines="none">
            <ion-label>&nbsp;</ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-label>&nbsp;</ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
      <ion-col v-if="pago && pago._id" size-md="8" class="detallePago">
        <ion-item>
          <ion-segment v-on:ionChange="verDetalleDePago=$event.detail.value" :value="verDetalleDePago">
            <ion-segment-button :value="'general'">General</ion-segment-button>
            <ion-segment-button :value="'adjunto'" v-if="pago.archivoAdjunto">Factura</ion-segment-button>
            <ion-segment-button :value="'justificacion'" v-if="pago.archivoAdjuntoJustificacion">Justificación</ion-segment-button>
            <ion-segment-button :value="'xml'" v-if="pago.archivoAdjuntoXml">XML</ion-segment-button>
          </ion-segment>
        </ion-item>
        <div v-if="verDetalleDePago=='general'">
          <ion-card v-if="idProveedor==''">
            <ion-list>
              <ion-item>
                <ion-note slot="start">
                  <i class="icon ion-ios-contact icon-lg"></i>
                </ion-note>
                <ion-label>
                  <h2>{{pago.proveedor.nombre}}</h2>
                  <p>{{pago.proveedor.rfc || 'Sin RFC'}}</p>
                  <p v-if="calcularOpinionDeCumplimientoVigente(pago.proveedor)">Opinión de cumplimiento vigente</p>
                  <p v-else><i class="icon ion-ios-information-circle"></i>&nbsp;Subió su opinión de cumplimiento el {{pago.proveedor.opinionDeCumplimientoFecha | moment('MMM DD')}}</p>
                </ion-label>
              </ion-item>
              <ion-row>
                <ion-col>
                  <ion-button expand="block" :href="'mailto:'+pago.proveedor.email" color="warning">
                    <i class="icon ion-ios-mail"></i>&nbsp;
                    {{pago.proveedor.email}}
                  </ion-button>
                </ion-col>
                <ion-col v-if="pago.proveedor.emailAlternativo">
                  <ion-button expand="block" :href="'mailto:'+pago.proveedor.emailAlternativo" color="warning">
                    <i class="icon ion-ios-mail"></i>&nbsp;
                    {{pago.proveedor.emailAlternativo}}
                  </ion-button>
                </ion-col>
                <ion-col v-if="(pago.proveedor.telefonos || pago.proveedor.telefono)">
                  <ion-button expand="block" :href="'tel:'+(pago.proveedor.email||pago.proveedor.telefonos)" color="success">
                    <i class="icon ion-ios-call"></i>&nbsp;
                    {{pago.proveedor.telefono || pago.proveedor.telefonos}}
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-list>
          </ion-card>
          <ion-card>
            <ion-list>
              <ion-item>
                <ion-note slot="start">
                  <i class="icon ion-ios-cash icon-lg"></i>
                </ion-note>
                <ion-label>Información del gasto</ion-label>
              </ion-item>
              <ion-item>
                <ion-label text-wrap>
                  <h2>Gasto: {{pago.gasto.nombre}}</h2>
                  <p>Parcialidad: {{pago.nombre}}</p>
                </ion-label>
                <ion-note slot="end" color="dark">{{pago.cantidadErogable | currency}}</ion-note>
              </ion-item>
              <ion-item>
                <ion-note slot="start">
                  <i class="icon ion-ios-log-in icon-lg"></i>
                </ion-note>
                <ion-label>
                  <h2>{{pago.gasto.fecha | moment('MMM DD YYYY')}}</h2>
                  <p>Fecha de recepción</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-note slot="start">
                  <i class="icon ion-ios-log-out icon-lg"></i>
                </ion-note>
                <ion-label>
                  <h2>{{pago.fechaProgramado | moment('MMM DD YYYY')}}</h2>
                  <p>Fecha de programación de pago</p>
                </ion-label>
                <ion-button slot="end" target="_blank" v-if="pago.archivoAdjuntoComprobante" :href="cdn+pago.archivoAdjuntoComprobante">
                  <i class="icon ion-ios-cloud-download"></i>
                </ion-button>
              </ion-item>
              <ion-item v-if="pago.fechaTerminada">
                <ion-note slot="start">
                  <i class="icon ion-ios-cash icon-lg"></i>
                </ion-note>
                <ion-label>
                  <h2>{{pago.fechaTerminada | moment('MMM DD YYYY')}}</h2>
                  <p>Fecha de pago</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card>
          <ion-card v-if="idProveedor==''">
            <ion-list>
              <ion-item>
                <ion-note slot="start">
                  <i class="icon ion-ios-wallet icon-lg"></i>
                </ion-note>
                <ion-label>
                  <h2>Información bancaria</h2>
                </ion-label>
                <!--ion-label text-wrap>{{pago.proveedor}}</ion-label-->
              </ion-item>
              <ion-item>
                <ion-label>Banco</ion-label>
                <ion-input readonly :value="pago.proveedor.bancoNombre" color="primary" class="ion-text-right"></ion-input>
              </ion-item>
              <ion-item>
                <ion-label>Número de cuenta</ion-label>
                <ion-input readonly :value="pago.proveedor.bancoCuenta" color="primary" class="ion-text-right"></ion-input>
              </ion-item>
              <ion-item>
                <ion-label>CLABE</ion-label>
                <ion-input readonly :value="pago.proveedor.bancoClabe" color="primary" class="ion-text-right"></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>Dirección fiscal</ion-label>
                <ion-input readonly :value="pago.proveedor.direccionFiscal" color="primary" class="ion-text-right"></ion-input>
              </ion-item>
            </ion-list>
          </ion-card>
          <pre class="ion-text-left" v-if="!modoProduccion">{{pago}}</pre>
        </div>
        <div v-if="verDetalleDePago=='adjunto'">
          <ion-item target="_blank" :href="cdn+pago.archivoAdjunto">
            <ion-label>
              <h2>{{pago.archivoAdjunto}}</h2>
              <p v-if="pago.archivoAdjuntoFecha">Subido el {{pago.archivoAdjuntoFecha | moment('MMM DD YYYY, HH:mm')}}</p>
            </ion-label>
          </ion-item>
          <embed :src="cdn+pago.archivoAdjunto" type="application/pdf" />
        </div>
        <div v-if="verDetalleDePago=='justificacion'">
          <ion-item target="_blank" :href="cdn+pago.archivoAdjuntoJustificacion">
            <ion-label>
              <h2>{{pago.archivoAdjuntoJustificacion}}</h2>
              <p v-if="pago.archivoAdjuntoFecha">Subido el {{pago.archivoAdjuntoFecha | moment('MMM DD YYYY, HH:mm')}}</p>
            </ion-label>
          </ion-item>
          <embed :src="cdn+pago.archivoAdjuntoJustificacion" type="application/pdf" />
        </div>
        <div v-if="verDetalleDePago=='xml'">
          <ion-item target="_blank" :href="cdn+pago.archivoAdjuntoXml">
            <ion-label>
              <h2>{{pago.archivoAdjuntoXml}}</h2>
              <p v-if="pago.archivoAdjuntoFecha">Subido el {{pago.archivoAdjuntoFecha | moment('MMM DD YYYY, HH:mm')}}</p>
            </ion-label>
          </ion-item>
          <pre>{{xml}}</pre>
        </div>
        <ion-item lines="none">
          <ion-label>&nbsp;</ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label>&nbsp;</ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-content>
  <ion-footer v-if="seleccionadosParaPagar.length>0">
    <ion-row>
      <ion-col size-xs="12" size-md="6" class="ion-no-padding">
        <ion-list>
          <ion-item color="light">
            <ion-note slot="start">
              <i class="icon ion-ios-contact icon-lg"></i>
            </ion-note>
            <ion-label>
              <h2>{{proveedor.nombre}}</h2>
              <p>{{proveedor.rfc || 'Sin RFC'}}</p>
              <p v-if="calcularOpinionDeCumplimientoVigente(proveedor)">Opinión de cumplimiento vigente</p>
              <p v-else><i class="icon ion-ios-information-circle"></i>&nbsp;Subió su opinión de cumplimiento el {{proveedor.opinionDeCumplimientoFecha | moment('MMM DD')}}</p>
            </ion-label>
          </ion-item>
          <ion-row>
            <ion-col>
              <ion-button expand="block" :href="'mailto:'+proveedor.email" color="warning">
                <i class="icon ion-ios-mail"></i>&nbsp;
                {{proveedor.email}}
              </ion-button>
            </ion-col>
            <ion-col v-if="proveedor.emailAlternativo">
              <ion-button expand="block" :href="'mailto:'+proveedor.emailAlternativo" color="warning">
                <i class="icon ion-ios-mail"></i>&nbsp;
                {{proveedor.emailAlternativo}}
              </ion-button>
            </ion-col>
            <ion-col v-if="(proveedor.telefonos || proveedor.telefono)">
              <ion-button expand="block" :href="'tel:'+(proveedor.email||proveedor.telefonos)" color="success">
                <i class="icon ion-ios-call"></i>&nbsp;
                {{proveedor.telefono || proveedor.telefonos}}
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-list>
      </ion-col>
      <ion-col size-xs="12" size-md="6" class="ion-no-padding">
        <ion-list>
          <ion-item color="light">
            <ion-note slot="start">
              <i class="icon ion-ios-wallet icon-lg"></i>
            </ion-note>
            <ion-label>
              <h2>Información bancaria</h2>
            </ion-label>
            <!--ion-label text-wrap>{{pago.proveedor}}</ion-label-->
          </ion-item>
          <ion-item>
            <ion-label>Banco</ion-label>
            <ion-input readonly :value="proveedor.bancoNombre" color="primary" class="ion-text-right"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Número de cuenta</ion-label>
            <ion-input readonly :value="proveedor.bancoCuenta" color="primary" class="ion-text-right"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>CLABE</ion-label>
            <ion-input readonly :value="proveedor.bancoClabe" color="primary" class="ion-text-right"></ion-input>
          </ion-item>
          <ion-item lines="none">
            <ion-label>Dirección fiscal</ion-label>
            <ion-input readonly :value="proveedor.direccionFiscal" color="primary" class="ion-text-right"></ion-input>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-item color="primary">
          <ion-note slot="start"><i class="icon ion-ios-log-out icon-lg"></i></ion-note>
          <ion-label text-wrap>
            <h2>{{comprobantePago.cantidad | currency}}</h2>
            <p>{{seleccionadosParaPagar.length}} pagos seleccionados</p>
            <p v-if="!comprobantePago.adjunto">Clic en el botón para subir comprobante de pago</p>
            <p v-else>Subido comprobante: {{comprobantePago.adjunto}}</p>
          </ion-label>
          <label for="comprobantePago">
            <input name="comprobantePago" id="comprobantePago" type="file" class="hidden" v-on:change="subirAdjunto()" />
            <div class="ion-button" slot="end" size="large" color="secondary">
              <i class="icon ion-ios-cloud-upload icon-lg"></i>
            </div>
          </label>
          <ion-button slot="end" size="large" color="secondary" @click="guardar" :disabled="!comprobantePago.adjunto">
            <i class="icon ion-ios-save icon-lg"></i>
          </ion-button>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-footer>
</ion-page>
</template>
<style>
.detallePago embed {
  width: 100%;
  height: calc(100vh - 210px);
}

div.ion-button {
  padding: 10px;
}
</style>
<script>
import Api from '@/components/Api'
import Empresa from '@/models/Empresa'
import axios from 'axios'
import Emailer from '@/models/Emailer'
//import ModalGastoEtapa from '@/components/Presupuestos/ModalGastoEtapa'
export default {
  name: 'PresupuestosPagos',
  props: {
    tipo: {
      type: String,
      default: 'normal'
    }
  },
  data() {
    return {
      cdn: Api.cdn + '/',
      esMovil: Api.esMovil(),
      yo: Api.obtenerLocal('elite.yo') || {},
      modoProduccion: Api.modoProduccion,
      directo: 1,
      pagos: [],
      proveedores: [],
      fechaBusquedaInicio: null,
      fechaBusquedaFin: null,
      filtro: 'Pendientes',
      idProveedor: '',
      suma: 0,
      modal: null,
      empresa: Empresa.obtenerDatos(),
      fechasPago: [],
      seleccionadosParaPagar: [],
      pago: {},
      xml: '',
      verDetalleDePago: 'general',
      comprobantePago: {},
      proveedor: {}
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async cambiarFiltro(val) {
      this.filtro = val
      console.log("cambiarFiltro", this.filtro)
      return this.obtener()
    },
    cambiarFechaBusquedaInicio(val) {
      this.fechaBusquedaInicio = val
      console.log("cambiarFechaBusquedaInicio", val)
      this.obtener()
    },
    cambiarFechaBusquedaFin(val) {
      this.fechaBusquedaFin = val
      console.log("cambiarFechaBusquedaFin", val)
      this.obtener()
    },
    inicializarComprobanteDePago() {
      this.seleccionadosParaPagar = []
      this.comprobantePago = {
        idAutor: this.yo._id,
        fecha: new Date().getTime(),
        cantidad: 0,
        notas: ''
      }
    },
    async obtener() {
      this.pago = {}
      this.inicializarComprobanteDePago()
      let etapas = await Api.get('gastoetapa')
      etapas = etapas.map(e => {
        e.proveedor = {}
        e.gasto = {}
        e.coleccion = 'gastoetapa'
        if (typeof e.fechaProgramado == 'object' && e.fechaProgramado.fechaProgramado) {
          console.log("Mapeando fecha " + e.nombre, e.fechaProgramado)
          e.fechaProgramado = e.fechaProgramado.fechaProgramado
        }
        return e
      })
      let gastos = await Api.get('presupuestogasto')
      for (let id in etapas) {
        for (let g of gastos)
          if (g._id == etapas[id].idGasto)
            etapas[id].gasto = g
        for (let p of this.proveedores)
          if (etapas[id].gasto.idProveedor == p._id)
            etapas[id].proveedor = p
      }
      console.log("Etapas pre", etapas)
      const filtro = this.filtro
      const idProveedor = this.idProveedor
      const momnt = this.$options.filters.moment
      etapas = etapas
        .sort((a, b) => a.fechaProgramado > b.fechaProgramado ? 1 : -1)
        .filter(e => {
          let fechaProgramado = new Date(e.fechaProgramado).getTime()
          let fechaPago = new Date(this.fechaBusquedaFin).getTime()
          console.log("e?", e.cantidadErogable, fechaProgramado, fechaPago)
          return fechaProgramado <= fechaPago
        })
        .filter(e => {
          console.log("f filtro", e.fechaTerminada)
          switch (filtro) {
            case 'Todos':
            default:
              return true
              break
            case 'Pendientes':
              return !(e.fechaTerminada && e.fechaTerminada > 0)
              break
            case 'Terminados':
              return (e.fechaTerminada && e.fechaTerminada > 0)
              break
          }
        })
        .filter(e => {
          console.log("f idProveedor", idProveedor)
          if (idProveedor == '')
            return true
          else
            return idProveedor == e.gasto.idProveedor
        })
      console.log("Etapas post", etapas)
      this.pagos = etapas
      this.suma = this.pagos.reduce((acum, obj) => acum + parseFloat(obj.cantidadErogable), 0)
    },
    async ver(pago) {
      console.log("Ver pago", pago)
      this.pago = pago
      try {
        this.xml = (await axios.get(this.cdn + pago.archivoAdjuntoXml)).data
      } catch (e) {
        //if (!this.modoProduccion) alert("No se pudo recuperar el XML")
        console.error(e)
      }
    },
    calcularOpinionDeCumplimientoVigente(proveedor) {
      let vigente = true
      proveedor = proveedor || {}
      let fechaMasAntiguaPermitida = new Date().getTime() - (1000 * 60 * 60 * 24 * 30)
      if (!proveedor.opinionDeCumplimiento ||
        !proveedor.opinionDeCumplimientoFecha ||
        proveedor.opinionDeCumplimientoFecha < fechaMasAntiguaPermitida)
        vigente = false
      console.log("calcularOpinionDeCumplimientoVigente?", proveedor.nombre, vigente)
      return vigente
    },
    //
    seleccionarParaPagar(pago) {
      console.log("seleccionarParaPagar?", pago.terminada, pago.fechaTerminada, pago)
      this.pago = {}
      if (this.idProveedor == '')
        alert("Seleccione un proveedor primero")
      else if (this.filtro != 'Pendientes')
        alert("Seleccione 'Mostrar Pendientes' primero")
      else {
        this.pago = {}
        if (this.seleccionadosParaPagar.indexOf(pago._id) >= 0)
          this.seleccionadosParaPagar = this.seleccionadosParaPagar
          .filter(u => u != pago._id)
        else
          this.seleccionadosParaPagar.push(pago._id)
      }
      this.sumaSeleccionados = this.pagos
        .filter(u => this.seleccionadoParaPagar(u))
        .reduce((acum, obj) => acum + parseFloat(obj.cantidadErogable), 0)
      this.comprobantePago.cantidad = this.sumaSeleccionados
      this.comprobantePago.adjunto = null
      console.log("seleccionarParaPagar", pago.cantidadErogable, this.seleccionadosParaPagar.indexOf(pago._id))
    },
    seleccionadoParaPagar(pago) {
      return this.seleccionadosParaPagar.indexOf(pago._id) >= 0
    },
    async subirAdjunto() {
      let u = await Api.upload('comprobantePago')
      this.comprobantePago.fecha = new Date().getTime()
      this.comprobantePago.adjunto = u
      this.$forceUpdate()
      console.log('subirAdjunto?', u, this.comprobantePago)
    },
    async guardar() {
      if (true == confirm('Desea guardar este pago?')) {
        let etapas = this.pagos
          .filter(g => this.seleccionadoParaPagar(g))
        console.log("etapas?", etapas)
        let proveedor = etapas[0].proveedor
        console.log("proveedor?", proveedor.nombre)
        let gastos = {}
        for (let e of etapas)
          gastos[e.idGasto] = e.gasto
        console.log("gastos?", gastos)
        for (let id in etapas) try {
          etapas[id].facturasingasto = await Api.get('facturasingasto', etapas[id]._id)
          console.log("facturasingasto?", etapas[id].facturasingasto)
        } catch (e) {
          alert("Gasto " + etapas[id].nombre + " no tiene factura sin gasto")
        }
        /*
        if (true)
        try {
          this.comprobantePago.seleccionadosParaPagar = this.seleccionadosParaPagar
          let pagoproveedor = await Api.save('pagoproveedor', this.comprobantePago)
          for (let etapa of etapas) {
            etapa.terminada = true
            etapa.fechaTerminada = new Date().getTime()
            etapa.archivoAdjuntoComprobante = this.comprobantePago.adjunto
            if (this.seleccionadosParaPagar.length > 1)
              etapa.pagoMultiple = true
            etapa.idPagoproveedor = pagoproveedor._id
            await Api.save('gastoetapa', etapa)
          }
          for (let idGasto in gastos) {
            let gasto = gastos[idGasto]
            gasto.idPagoproveedor = pagoproveedor._id
            await Api.save('presupuestogasto', gasto)
          }
          alert('Pago guardado')
          try {
            let cuerpo2 = []
            for (let etapa of etapas)
              if (etapa.facturasingasto && etapa.facturasingasto.metadatos) {
                const f = etapa.facturasingasto.metadatos
                cuerpo2.push('' + f.folio + '\t' + f.fechaFactura + '\t' + this.$options.filters.currency(f.cantidad))
              }
            else if (etapa.gasto) {
              const f = etapa.gasto
              cuerpo2.push('' + f.nombre + '\t' + this.$options.filters.moment(f.fecha, 'YYYY-MM-DD') + '\t' + this.$options.filters.currency(f.cantidadCotizada))
            }
            if (cuerpo2.length > 0)
              cuerpo2 = 'Facturas pagadas:<br />' + cuerpo2.join('<br />')
            else
              cuerpo2 = ''
            let attachments = [{
              filename: 'comprobante_pago.pdf',
              href: Api.cdn + '/' + this.comprobantePago.adjunto,
              contentType: 'application/pdf'
            }]
            for (let ext of ['.jpg', '.jpeg', '.png', 'webp'])
              if (this.comprobantePago.adjunto.indexOf(ext) >= 0)
                attachments = [{
                  filename: 'comprobante_pago.' + ext,
                  href: Api.cdn + '/' + this.comprobantePago.adjunto,
                  contentType: 'image/' + ext
                }]
            let enviable = {
              titulo: 'Pago de facturas realizado',
              destino: Api.modoProduccion ? proveedor.email : 'liebemichblutengel@gmail.com',
              cuerpo1: proveedor.nombre + ': Se ha realizado el pago de ' + (this.seleccionadosParaPagar.length) + ' factura(s), por ' +
                (this.$options.filters.currency(pagoproveedor.cantidad)) + '. Para más información, puede entrar a la <a href="https://proveedores.elite.mx">plataforma</a>. ',
              cuerpo2,
              attachments
            }
            await Emailer.enviar(enviable)
            alert("Correo enviado al proveedor")
            this.obtener()
          } catch (e) {
            alert("No se pudo enviar el correo al proveedor")
            console.error(e)
          }
          await Api.wait(2000)
          this.obtener()
        } catch (e) {
          console.error(e)
          alert("No se pudo guardar el pago")
        }//*/
      }
    },
    //
    async reporteCsv() {
      alert("Descargando CSV")
      await this.cambiarFiltro('Pendientes')
      console.log("Reporteables?", this.pagos)
      let txtHeader = [
        'Presupuesto',
        //'Encargado',
        //'Solicitante',
        'Nombre del gasto',
        'Nombre del pago',
        'Fecha creada',
        'Fecha pagada',
        'Fecha límite'
      ]
      let txt = txtHeader.join(',') + '\n'
      for (let p of this.pagos) {
        if (p.nombre) {
          console.log("P?", p)
          let renglon = []
          renglon.push(((p.presupuesto || {}).nombre) || '')
          //renglon.push(((p.encargadoPresupuesto || {}).nombre) || '')
          //renglon.push(((p.solicitante || {}).nombre) || '')
          renglon.push(((p.gasto || {}).nombre) || '')
          renglon.push(p.nombre || '')
          renglon.push(this.$options.filters.moment(p.fecha, 'YYYY-MM-DD') || '')
          renglon.push(this.$options.filters.moment(p.fechaTerminada, 'YYYY-MM-DD') || '')
          renglon.push(this.$options.filters.moment(p.fechaLimite, 'YYYY-MM-DD') || '')
          let renglonTxt = renglon.join(',')
          txt += renglonTxt + '\n'
        }
      }
      console.log("txt?", txt)
      Api.downloadForce(txt, this.filtro + '.csv')
    },

  },
  watch: {
    'idProveedor': function(a) {
      for (let p of this.proveedores)
        if (p._id == a)
          this.proveedor = p
      console.log("Seleccionado proveedor", this.proveedor.nombre)
    }
  },
  async mounted() {
    console.log("PresupuestosPagos mounted")
    this.proveedores = (await Api.find('usuario', ['rol,eq,proveedor'], {
      sort: ['nombre,1'],
      subset: [{
        coleccion: 'factura'
      }]
    }))
    this.fechaBusquedaInicio = this.$options.filters.moment(new Date().getTime() - (1000 * 60 * 60 * 24 * 60), 'YYYY-MM-DD')
    this.fechasPago = (await Api.get('fechaspago'))
      .map(f => f.fechaPago)
      .sort((a, b) => a > b ? 1 : -1)
      .filter(f => f > (new Date().toISOString().substring(0, 10)))
    console.log("fechasPago", this.fechasPago)
    this.cambiarFechaBusquedaFin(this.fechasPago[0])
  }
}
</script>
