<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('tertiary')">
      <ion-title>Herramienta Merge</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <ion-icon name="menu" :color="colors('light')"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <div v-if="esMovil">
      <ion-item>
        <ion-icon name="information-circle" slot="start"></ion-icon>
        <ion-label :color="colors('danger')" text-wrap>
          <h2>La base de datos es demasiado grande</h2>
          <p>Para usar este módulo, use un equipo de sobremesa</p>
        </ion-label>
      </ion-item>
    </div>
    <ion-row v-else>
      <ion-col no-padding :size="asuntos.length>0?4:12">
        <ion-button expand="block" fill="outline" v-if="clientesSeleccionados.length>1" @click="mergeClientes">Unificar</ion-button>
        <SelectorCliente :devolverTodos="true" :debounce="1000" />
        <ion-item v-for="a in clientesFiltrados" :key="a._id">
          <ion-label text-wrap>
            <h2>{{a.nombre}}</h2>
          </ion-label>
          <ion-checkbox :checked="clienteEstaSeleccionado(a._id)" v-on:ionChange="toggleSeleccionarCliente(a._id)" />
        </ion-item>
      </ion-col>
      <ion-col no-padding>
        <ion-row>
          <ion-col no-padding v-if="asuntosSeleccionados.length>1">
            <ion-button expand="block" fill="outline" @click="mergeAsuntos">Unificar</ion-button>
          </ion-col>
          <ion-col no-padding v-if="asuntos.length>0">
            <ion-item lines="none">
              <ion-label :color="colors('tertiary')">Mostrar terminados</ion-label>
              <ion-checkbox :checked="mostrarCerrados==true" @ionChange="toggleMostrarCerrados"></ion-checkbox>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-list>
          <ion-item v-for="(a,indice) in asuntos" :key="indice">
            <ion-label text-wrap>
              <h2>{{a.nombre}}</h2>
              <p>{{a.fecha | moment('MMM DD, YYYY')}}</p>
            </ion-label>
            <ion-button slot="end" v-on:click="toggleTerminarAsunto(a,indice)" :fill="(a.terminado && a.terminado==1)?'solid':'outline'">{{(a.terminado && a.terminado==1)?'Terminado':'Abierto'}}</ion-button>
            <!--ion-checkbox slot="end" :checked="asuntoEstaSeleccionado(a._id)" v-on:ionChange="toggleSeleccionarAsunto(a._id)" /-->
            <ion-note slot="end" v-on:click="toggleSeleccionarAsunto(a._id,indice)" :color="colors('primary')">
              <i class="icon icon-lg" :class="{'ion-ios-checkmark-circle':asuntoEstaSeleccionado(a._id),'ion-ios-radio-button-off':!asuntoEstaSeleccionado(a._id)}"></i>
            </ion-note>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-content>
</div>
</template>
<style>
.hidden {
  display: none;
}
</style>
<script>
import Api from '@/components/Api'
import Cliente from '@/models/Cliente'
import SelectorCliente from '@/components/SelectorCliente'
export default {
  name: 'HerramientaMerge',
  components: {
    SelectorCliente
  },
  data() {
    return {
      yo: Api.obtenerLocal('wst.yo'),
      esMovil: Api.esMovil(),
      clientes: [],
      asuntos: [],
      clientesSeleccionados: [],
      asuntosSeleccionados: [],
      mostrarCerrados: false
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async obtenerClientes() {
      this.clientes = await Cliente.lista()
      console.log("Clientes obtenidos", this.clientes)
      this.asuntos = []
      this.clientesSeleccionados = []
      this.asuntosSeleccionados = []
      this.$forceUpdate()
    },
    async obtenerAsuntos() {
      console.log("Obteniendo asuntos")
      this.asuntos = []
      const loadingController = document.querySelector('ion-loading-controller')
      console.log("loadingController?", loadingController)
      await loadingController.componentOnReady()
      const loadingElement = await loadingController.create({
        spinner: 'crescent'
      })
      await loadingElement.present()
      let asuntos = []
      for (let cl of this.clientesSeleccionados) {
        if (typeof cl == 'object')
          cl = cl._id
        console.log("Obteniendo asuntos de", cl)
        let asuntos2 = await Api.find('asunto', ['idCliente,eq,' + cl])
        asuntos = asuntos.concat(asuntos2)
      }
      this.asuntos = asuntos.sort((a, b) => {
        return a.fecha < b.fecha ? 1 : -1
      })
      if (!this.mostrarCerrados) {
        console.log("Filtrando sólo abiertos")
        let cantTodos = this.asuntos.length
        this.asuntos = this.asuntos.filter(a => {
          return !(a.terminado && a.terminado == 1)
        })
        console.log("Mostrando filtrados (abiertos)", this.asuntos.length, cantTodos)
      }
      this.$forceUpdate()
      await loadingElement.dismiss()
      console.log("Asuntos obtenidos", this.asuntos)
    },
    toggleMostrarCerrados() {
      this.mostrarCerrados = !this.mostrarCerrados
      console.log("Mostrando cerrados?", this.mostrarCerrados)
      this.obtenerAsuntos()
    },
    toggleSeleccionarCliente(cl) {
      let posicion = this.clientesSeleccionados.indexOf(cl)
      if (posicion >= 0) {
        delete this.clientesSeleccionados[posicion]
        this.clientesSeleccionados = [...new Set(this.clientesSeleccionados)]
      } else {
        this.clientesSeleccionados.push(cl)
      }
      console.log("Clientes seleccionados?", cl, posicion, this.clientesSeleccionados)
      this.obtenerAsuntos()
    },
    clienteEstaSeleccionado(cl) {
      return this.clientesSeleccionados.indexOf(cl) >= 0
    },
    toggleSeleccionarAsunto(cl, indice) {
      let posicion = this.asuntosSeleccionados.indexOf(cl)
      if (posicion >= 0) {
        delete this.asuntosSeleccionados[posicion]
      } else {
        this.asuntosSeleccionados.push(cl)
      }
      this.asuntosSeleccionados = this.asuntosSeleccionados.filter(a => {
        return a && a.length
      })
      this.$forceUpdate()
      let estaSeleccionado = this.asuntoEstaSeleccionado(cl)
      console.log("Seleccionado?", cl, estaSeleccionado, this.asuntosSeleccionados)
      this.$forceUpdate()
      return estaSeleccionado
    },
    async toggleTerminarAsunto(a, indice) {
      a.terminado = (a.terminado && a.terminado == 1) ? 0 : 1
      alert("Terminado? " + (a.terminado == 1 ? 'Si' : 'No'))
      //if (false)
      try {
        await Api.save('asunto', a)
        this.asuntos[indice].terminado = a.terminado
        this.$forceUpdate()
      } catch (e) {
        alert("No se pudo terminar el asunto")
      }
    },
    asuntoEstaSeleccionado(cl) {
      return this.asuntosSeleccionados.indexOf(cl) >= 0
    },
    async mergeClientes() {
      alert("Uniendo clientes")
      let nombreDefault = ''
      for (let cl of this.clientesFiltrados)
        if (cl._id == this.clientesSeleccionados[0])
          nombreDefault = cl.nombre
      let nuevoNombre = prompt('Está a punto de unir ' + this.clientesSeleccionados.length + ' clientes en uno solo. ESTA ACCIÓN NO SE PUEDE DESHACER. \n\nPara continuar, establezca el nombre del cliente.', nombreDefault)
      alert("Nombre: " + nuevoNombre)
      if (nuevoNombre && nuevoNombre != '') {
        console.log("Clientes seleccionados?", this.clientesSeleccionados)
        let nuevoId = this.clientesSeleccionados[0]
        // Cambiar el nombre al cliente
        let clienteViejo = await Api.get('cliente', nuevoId)
        clienteViejo.nombre = nuevoNombre
        await Api.save('cliente', clienteViejo)
        for (let id of this.clientesSeleccionados)
          if (id) {
            // Reasignar las actividades
            let actividades = await Api.find('actividad', ['idCliente,eq,' + id])
            for (let act of actividades) {
              act.idCliente = nuevoId
              try {
                await Api.save('actividad', act)
              } catch (e) {}
            }
            // Reasignar los asuntos
            let asuntos = await Api.find('asunto', ['idCliente,eq,' + id])
            for (let asunto of asuntos) {
              asunto.idCliente = nuevoId
              try {
                await Api.save('asunto', asunto)
              } catch (e) {}
            }
            // Eliminar los otros clientes
            if (id != nuevoId)
              try {
                //await Api.delete('cliente', id)
                await Cliente.eliminar(id)
              } catch (e) {}
            alert("Cliente " + id + " procesado")
          }
        for (let key in localStorage)
          if (key.startsWith('elite.cliente.'))
            localStorage.removeItem(key)
        let u = await Cliente.lista()
        // Feedback
        alert("Proceso terminado. La aplicación se reiniciará. ")
        setTimeout(_ => {
          window.location.reload()
        }, 2000)
      }
    },
    async mergeAsuntos() {
      alert("Uniendo asuntos")
      // Obtener el nombre, el idCliente y el ID del primer asunto
      let nuevoId = this.asuntosSeleccionados[0]
      let asuntoViejo = await Api.get('asunto', nuevoId)
      let nombreDefault = asuntoViejo.nombre
      let idCliente = asuntoViejo.idCliente
      // Confirmar el nombre del asunto
      let nuevoNombre = prompt('Está a punto de unir ' + this.asuntosSeleccionados.length + ' asuntos en uno solo. ESTA ACCIÓN NO SE PUEDE DESHACER. \n\nPara continuar, establezca el nombre del asunto.', nombreDefault)
      alert("Nuevo nombre: " + nuevoNombre)
      if (nuevoNombre && nuevoNombre != '') {
        // Tomar el primer asunto y cambiarle el nombre
        asuntoViejo.nombre = nuevoNombre
        await Api.save('asunto', asuntoViejo)
        for (let id of this.asuntosSeleccionados)
          if (id != nuevoId) {
            // Obtener las actividades y cambiarles la identidad
            let actividades = await Api.find('actividad', ['idAsunto,eq,' + id])
            for (let act of actividades) {
              act.idCliente = idCliente
              act.idAsunto = nuevoId
              await Api.save('actividad', act)
            }
            // Eliminar el asunto antiguo
            try {
              await Api.delete('asunto', id)
              let bit = {
                mensaje: 'Asunto eliminado por herramienta de fusion: ' + id,
                fecha: new Date().getTime()
              }
            } catch (e) {}
          }
        // Feedback
        alert("Actividades reasignadas y asuntos sobrantes eliminados")
        setTimeout(_ => {
          window.location.reload()
        }, 2000)
      }
    }
  },
  mounted() {
    console.log("HerramientaMerge mounted")
    //if (!this.esMovil) this.obtenerClientes()
    let vm = this
    Api.$on('clienteSeleccionado', clientes => {
      vm.clientesFiltrados = clientes
      console.log("$on clienteSeleccionado", vm.clientesFiltrados)
      vm.$forceUpdate()
    })
  },
  beforeDestroy() {}
}
</script>
<style>
.lista-izquierda {
  height: calc(100vh - 100px);
  display: block;
}

.icon-lg {
  font-size: 28px;
}
</style>
