<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-title>Catalogos - Corporativos</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <!--ion-icon name="menu" :color="colors('light')"></ion-icon-->
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-row>
      <ion-col no-padding>
        <ion-list>
          <ion-item-divider>Corporativos
            <ion-icon slot="end" name="refresh" v-on:click="obtener()"></ion-icon>
          </ion-item-divider>
          <ion-item-sliding v-for="u in corporativos" :key="u.nombre">
            <ion-item v-on:click="ver(u)">
              <ion-label text-wrap>
                <h2>{{u.nombre}} </h2>
              </ion-label>
              <ion-note slot="end">
                {{(Object.keys(u.miembros)||[]).length}}
              </ion-note>
            </ion-item>
            <ion-item-options slot="end">
              <ion-item-option :color="colors('danger')" v-on:click="eliminar(u._id)" v-if="permiso('BorrarCorporativos')">
                <ion-icon name="trash"></ion-icon> Eliminar
              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </ion-list>
      </ion-col>
      <ion-col no-padding v-if="corporativo && typeof corporativo.nombre != 'undefined'">
        <ion-list>
          <ion-item>
            <ion-label position="floating">Nombre del corporativo</ion-label>
            <ion-input :value="corporativo.nombre" v-on:ionChange="corporativo.nombre=$event.detail.value"></ion-input>
            <ion-button slot="end" @click="guardar" :color="colors('secondary')" fill="outline" size="medium" v-if="permiso('EditarCorporativos') || (permiso('CrearCorporativos') && !corporativo._id)">
              <i class="icon ion-ios-save "></i>
            </ion-button>
          </ion-item>
          <ion-item-divider>Miembros de {{corporativo.nombre}}</ion-item-divider>
          <ion-item v-for="c in clientes" :key="c._id">
            <ion-checkbox :checked="esMiembro(c._id)" v-on:ionChange="toggleMiembro(c._id,$event.detail.checked==true)"></ion-checkbox>
            <ion-label>{{c.nombre}}</ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('secondary')" v-on:click="ver({})">
        <i class="icon ion-ios-add icon-lg"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
export default {
  name: 'CatalogoCorporativos',
  data() {
    return {
      yo: {},
      corporativos: [],
      corporativo: null,
      clientes: []
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    pos() {
      this.$router.push('pos')
    },
    async obtener() {
      let corporativos = await Api.get('corporativo')
      corporativos = corporativos
        .filter(c => {
          return c.nombre && c.nombre.length > 0
        })
        .sort((a, b) => {
          return a.nombre < b.nombre ? 1 : -1
        })
        .sort((a, b) => {
          return a.apellidoPaterno < b.apellidoPaterno ? 1 : -1
        })
      this.corporativos = corporativos
      let clientes = await Api.get('cliente')
      this.clientes = clientes.sort((a, b) => {
        return a.nombre > b.nombre ? 1 : -1
      })
      console.log("objetos", this.corporativos, this.clientes)
    },
    esMiembro(idCliente) {
      return this.corporativo &&
        this.corporativo.miembros &&
        this.corporativo.miembros[idCliente] &&
        this.corporativo.miembros[idCliente] == true
    },
    toggleMiembro(idCliente, valor) {
      valor = valor && true
      if (!this.corporativo.miembros)
        this.corporativo.miembros = {}
      this.corporativo.miembros[idCliente] = valor
      if (false == this.corporativo.miembros[idCliente]) {
        console.log("Eliminado miembro", idCliente)
        delete this.corporativo.miembros[idCliente]
      }
      console.log("toggleMiembro", idCliente, this.corporativo.miembros[idCliente])
    },
    ver(corporativo) {
      //this.$router.push('/catalogos/cliente/' + id)
      this.corporativo = corporativo
      if (!this.corporativo.nombre)
        this.corporativo.nombre = ''
      if (!this.corporativo.miembros)
        this.corporativo.miembros = {}
      console.log("Corporativo", this.corporativo)
    },
    async guardar() {
      if (this.corporativo && this.corporativo.nombre && this.corporativo.nombre != '') {
        await Api.save('corporativo', this.corporativo)
        let bitacora = {
          idAutor: this.yo._id,
          mensaje: 'Corporativo modificado: ' + (this.corporativo || {}).nombre,
          fecha: new Date().getTime()
        }
        let v = await Api.save('bitacora', bitacora)
        alert("Guardado")
        this.obtener()
      } else {
        alert("Faltan datos")
      }
    },
    eliminar(id) {
      if (true == confirm('Desea eliminar este elemento? Esto no se puede deshacer')) {
        Api.delete('corporativo', id).then(_ => {
          this.obtener()
        })
      }
    }
  },
  mounted() {
    this.yo = Api.obtenerLocal('wst.yo')
    if (!(this.yo && this.yo._id))
      console.warn("No has iniciado sesión")
    setTimeout(this.obtener, 1000)
  }
}
</script>

<style>

</style>
