<template>
<div v-if="permiso('ReporteActividadesPorCliente')">
  <ion-item lines="none">
    <ion-segment :value="modo" v-on:ionChange="modo=$event.detail.value">
      <ion-segment-button :value="'pdf'">PDF</ion-segment-button>
      <ion-segment-button :value="'tabla'">Tabla</ion-segment-button>
    </ion-segment>
  </ion-item>
  <div v-if="modo=='tabla'">
    <ion-item :color="colors('light')" lines="none">
      <ion-label>Actividades por cliente</ion-label>
      <ion-button slot="end" fill="clear" @click="copiarTabla">
        <i class="icon ion-ios-copy"></i>
      </ion-button>
    </ion-item>
    <div id="tabla">
      <div>
        <table v-for="(cliente,indiceCliente) in actividades2" :key="indiceCliente">
          <tbody v-for="(asunto,indiceAsunto) in cliente.asuntos" :key="indiceAsunto">
            <tr v-if="esPrimerAsunto(indiceCliente,indiceAsunto)">
              <th :colspan="filtros.verMontos?5:4" text-center>Cliente: {{cliente.cliente.nombre}}</th>
            </tr>
            <tr>
              <th :colspan="filtros.verMontos?5:4" text-center>Asunto: {{(asunto.asunto.area||'').substring(0,3).toUpperCase()}} - {{asunto.asunto.nombre}}</th>
            </tr>
            <tr v-for="actividad in asunto.actividades" :key="actividad._id">
              <td>{{actividad.fechaProgramada | moment('DD-MM-YYYY')}}</td>
              <!--pre class="ion-text-left">{{actividad}}</pre-->
              <td>{{actividad.abogado.iniciales || actividad.abogado.nombre}}</td>
              <td>{{actividad.descripcion}}</td>
              <td class="ion-text-right">{{actividad.minutos}}</td>
              <td class="ion-text-right" v-if="filtros.verMontos">{{actividad.costoEstandar | currency('')}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <embed type="application/pdf" v-if="modo=='pdf' && pdf!=''" :src="pdf" class="pdf" />
  <ion-item v-if="error.error">
    <ion-label text-center :color="colors('danger')">
      <h2>{{error.error}}</h2>
      <p>{{error.raw}}</p>
    </ion-label>
  </ion-item>
</div>
<div v-else>
  <p>No tiene permisos para este contenido</p>
</div>
</template>
<script>
import Api from '@/components/Api'
import Pdfgen from '@/models/Pdfgen'
import Cliente from '@/models/Cliente'
import Usuario from '@/models/Usuario'
import Reporteable from '@/models/Reporteable'
export default {
  name: 'ReporteMisActividadesPorCliente',
  props: ['filtros', 'abogados', 'clientes', 'areas', 'corporativos', 'unSoloAbogado', 'unSoloCliente'],
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo'),
      actividades: [],
      asuntos: {},
      cliente: {},
      corporativo: {},
      modoSimple: false,
      //modoPdf: true,
      modo: 'pdf',
      //!Api.esMovil(),
      total: 0,
      pdf: '',
      error: {}
    }
  },
  methods: {
    async obtener() {
      this.error = {}
      console.log("Obteniendo", this.filtros)
      this.total = 0
      let fE = [] // TODO: Agregar filtro de múltiples clientes para poder reportear corporativos
      let resumen = await Reporteable.obtenerResumenActividades(this.filtros.fechaInicio, this.filtros.fechaFin, null, 'dia', fE)
      let idCliente = this.filtros.idCliente
      if (!idCliente)
        alert("Debe seleccionar un cliente primero")
      console.log("Reportear de cliente?", (idCliente || ':Sin cliente:'), resumen)
      let corporativo = {}
      if (this.filtros.idCorporativo && this.filtros.idCorporativo != '') {
        console.log("Reportar de corporativo", this.filtros.idCorporativo)
        try {
          corporativo = await Api.get('corporativo', this.filtros.idCorporativo)
          this.corporativo = corporativo
          console.log("Corporativo encontrado", this.filtros.idCorporativo, corporativo.nombre)
          if (!(corporativo && corporativo._id))
            throw new Error('Corporativo no encontrado: ' + this.filtros.idCorporativo)
        } catch (e) {
          console.error("No corporativo, cool?", e)
        }
      } else {
        //this.cliente = await Cliente.obtener(this.filtros.idCliente)
        console.log("Recuperando cliente?", this.filtros.idCliente)
        this.cliente = await Api.get('cliente', this.filtros.idCliente)
      }
      if (this.yo.rol == 'administrador' && !(idCliente && idCliente != '') && !(this.filtros.idCorporativo && this.filtros.idCorporativo != '')) {
        corporativo = {
          nombre: 'Todos los clientes',
          miembros: (await Cliente.lista()).map(c => c._id)
        }
      }
      const filtros = this.filtros
      this.actividades = resumen.actividades
        .filter(a => {
          if (corporativo && corporativo._id) {
            console.log("Filtrando por corporativo", a.idCliente, corporativo.miembros[a.idCliente])
            return (
              corporativo.miembros[a.idCliente] && corporativo.miembros[a.idCliente] == true ||
              (corporativo.miembrosArray || []).indexOf(a.idCliente) >= 0
            )
          } else
          if (idCliente && idCliente != '') {
            return a.idCliente == idCliente
          } else
          if (this.yo.rol == 'administrador' && !Api.modoProduccion)
            return true
        })
        .filter(a => a.area == filtros.area || filtros.area == '')
      // Permitir desplegar el pseudocorporativo si no fue especificado un cliente
      if (this.yo.rol == 'administrador') {
        if (!(corporativo && corporativo._id) && !(idCliente && idCliente.length > 0)) {
          corporativo = corporativo || {}
          corporativo._id = '0101010101010101'
          this.corporativo = corporativo
        }
      }
      //
      console.log("Actividades!", this.actividades.length, this.actividades, corporativo)
      // Organizar actividades por cliente
      this.actividades2 = {} // cliente => asunto => actividad
      let total = 0
      for (let act of this.actividades) {
        if (act) {
          //console.log("Mapeando actividad", act.idCliente, act.idAsunto, act.idCliente)
          if (act.idAsunto && act.idCliente) {
            if (!isNaN(act.minutos))
              total += parseFloat(act.minutos || 0)
            //console.log("Sumado?", parseFloat(act.minutos), act.minutos)
            if (!this.actividades2[act.idCliente]) {
              this.actividades2[act.idCliente] = {
                cliente: act.cliente || {
                  nombre: act.nombreCliente
                },
                asuntos: {}
              }
            }
            if (!this.actividades2[act.idCliente].asuntos[act.idAsunto])
              this.actividades2[act.idCliente].asuntos[act.idAsunto] = {
                asunto: act.asunto || {
                  nombre: act.nombreAsunto,
                  area: act.area || ''
                },
                actividades: []
              }
            let act2 = JSON.parse(JSON.stringify(act))
            for (let prop of ['idCliente', 'cliente', 'idAsunto', 'asunto'])
              if (act2[prop])
                delete act2[prop]
            this.actividades2[act.idCliente].asuntos[act.idAsunto].actividades.push(act2)
          }
        }
      }
      this.total = total
      console.log("Actividades2", this.actividades2)
      this.$forceUpdate()
      this.generarPDF()
    },
    esPrimerAsunto(indiceCliente, indiceAsunto) {
      console.log("esPrimerAsunto?", indiceCliente, indiceAsunto, this.asuntos2)
      let indicesAsuntos = Object.keys(this.actividades2[indiceCliente].asuntos)
      console.log("indicesAsuntos", indicesAsuntos)
      return indicesAsuntos.indexOf(indiceAsunto) == 0
    },
    async generarPDF() {
      let opciones = {
        fechaInicio: this.filtros.fechaInicio,
        fechaFin: this.filtros.fechaFin,
        titulo: 'Reporte de actividades\n',
        omitirFooter: true
      }
      if (this.corporativo._id)
        opciones.titulo += this.corporativo.nombre
      else if (this.cliente._id)
        opciones.titulo += this.cliente.nombre
      let pdf = Pdfgen.inicializar(opciones)
      console.log("Inicializado", pdf)
      let tiempoTotalGlobal = 0
      let costoTotalGlobal = 0
      let headerTabla = [{
        text: 'Fecha',
        style: 'small'
      }, {
        text: 'Abogado',
        style: 'small'
      }, {
        text: 'Descripción',
        style: 'small'
      }, {
        text: 'Tiempo',
        style: ['right', 'small']
      }]
      let headerWidths = [70, 100, '*', 90]
      if (this.filtros.verMontos) {
        headerTabla.push({
          text: 'Cantidad',
          style: ['right', 'small']
        })
        headerWidths.push(60)
      }
      let tablaCliente = {
        layout: 'lightHorizontalLines',
        table: {
          widths: headerWidths,
          body: [
            headerTabla
          ],
          headerRows: 1
        }
      }
      let headersMostrados = false
      console.log("Clientes por mostrar?", Object.keys(this.actividades2), this.filtros.idCorporativo)
      for (let idCliente in this.actividades2) {
        let cliente = this.actividades2[idCliente]
        //if (Object.keys(this.actividades2).length > 1) {
        if (
          (this.filtros.idCorporativo && this.filtros.idCorporativo != '') ||
          (!this.filtros.idCorporativo && this.yo.rol == 'administrador')
        ) {
          pdf.content.push({
            text: cliente.cliente.nombre,
            style: ['subheader']
          })
          pdf.content.push({
            text: '\n'
          })
        }
        console.log("Cliente?", cliente.cliente.nombre, Object.keys(cliente.asuntos).length)
        for (let idAsunto in cliente.asuntos) {
          let asunto = cliente.asuntos[idAsunto]
          pdf.content.push({
            text: 'Asunto: ' + (asunto.asunto.area || '').substring(0, 3).toUpperCase() + ' ' + asunto.asunto.nombre + '\n',
            style: ['subheader2']
          })
          pdf.content.push({
            text: '\n'
          })
          let tablaAsunto = JSON.parse(JSON.stringify(tablaCliente))
          // Solo mostrar la primera vez los headers de la tabla
          if (headersMostrados) {
            tablaAsunto.table.body = [
              ['', '', '', '']
            ]
            if (this.filtros.verMontos)
              tablaAsunto.table.body = [
                ['', '', '', '', '']
              ]
            delete tablaAsunto.headerRows
          }
          headersMostrados = true
          console.log("headersMostrados?", headersMostrados)
          //
          let tiempoTotal = 0
          let costoTotal = 0
          for (let actividad of asunto.actividades) {
            // volver a obtner el abogado
            if (!(actividad.abogado && actividad.abogado._id))
              actividad.abogado = await Usuario.obtener(actividad.idAbogado)
            //console.log("Actividad?", actividad.idCliente, actividad.fechaProgramada, actividad)
            let rowActividad = [{
                style: 'small',
                text: this.$options.filters.moment(actividad.fechaProgramada, 'DD MMM YYYY')
              },
              {
                style: 'small',
                text: (actividad.abogado.iniciales || actividad.abogado.nombre || '')
              },
              {
                style: 'small',
                text: actividad.descripcion || actividad.nombre
              },
              {
                style: ['right', 'small'],
                text: this.filtroTiempo(actividad.minutosCliente || actividad.minutos || actividad.importe)
              }
            ]
            if (this.filtros.verMontos)
              rowActividad.push({
                style: ['right', 'small'],
                text: this.$options.filters.currency(actividad.costoEstandar)
              })
            tablaAsunto.table.body.push(rowActividad)
            //console.log("Sumando", actividad.minutos, actividad.descripcion)
            tiempoTotal += parseFloat(actividad.minutosCliente || actividad.minutos || actividad.importe || 0)
            costoTotal += parseFloat(actividad.costoEstandar || 0)
          }
          /*
          if (false)
            tablaAsunto.table.body.push([
              '', 'Total', '', {
                text: this.filtroTiempo(tiempoTotal),
                style: 'right'
              }
            ]) //*/
          pdf.content.push(tablaAsunto)
          pdf.content.push({
            text: '\n\n'
          })
          tiempoTotalGlobal += tiempoTotal
          costoTotalGlobal += costoTotal
        }
        pdf.content.push({
          text: '\n'
        })
        //
      }
      let tablaSuperTotal = JSON.parse(JSON.stringify(tablaCliente))
      let rowFootPadding = ['', '', '', '']
      let rowFoot = ['Total general', '', '', {
        text: this.filtroTiempo(tiempoTotalGlobal),
        style: 'right'
      }]
      if (this.filtros.verMontos) {
        rowFootPadding.push('')
        rowFoot.push({
          style: 'right',
          text: this.$options.filters.currency(costoTotalGlobal)
        })
      }
      tablaSuperTotal.table.body = [
        rowFootPadding,
        rowFoot
      ]
      delete tablaSuperTotal.headerRows
      pdf.content.push(tablaSuperTotal)
      try {
        this.pdf = await Pdfgen.generarDesdeObjeto(pdf)
        console.log("PDF generado", this.pdf.length)
      } catch (e) {
        this.error = e
      }
    },
    filtroTiempo(cant) {
      cant = parseFloat(cant) || 0
      let parteEntera = Math.floor(cant / 60)
      let parteDecimal = (cant % 60).toFixed(0)
      if (parteDecimal.length == 1)
        parteDecimal = '0' + parteDecimal
      return parteEntera + ':' + parteDecimal
    },
    copiarTabla() {
      function selectElementContents(el) {
        var body = document.body,
          range, sel;
        if (document.createRange && window.getSelection) {
          range = document.createRange();
          sel = window.getSelection();
          sel.removeAllRanges();
          try {
            range.selectNodeContents(el);
            sel.addRange(range);
          } catch (e) {
            range.selectNode(el);
            sel.addRange(range);
          }
        } else if (body.createTextRange) {
          range = body.createTextRange();
          range.moveToElementText(el);
          range.select();
        }
      }
      let tabla = document.querySelector('#tabla')
      selectElementContents(tabla)
      document.execCommand('copy')
      console.log("Copiado?", tabla.value)
      alert("Copiado al portapapees")
    }
  },
  watch: {
    filtros: function() {
      this.obtener()
    }
  },
  mounted() {
    console.log("ReporteMisActividades")
    //this.yo.rol = 'perritou'
    this.obtener()
  }
}
</script>
<style>
.pdf {
  width: 100%;
  height: calc(100vh - 50px);
}
</style>
