<script>
const plantilla =
  `
<div style="tex-align:justify;font-family:Calibri,Ubuntu,Verdana;">
  <p style="text-align:center">
    <img src="https://www.elite.mx/wp-content/uploads/2018/11/logo.png" style="width:200px;text-align:center;" />
  </p>
  <h1 style="text-align:center;">XXtituloXX</h1>
  <div style="width:50%;min-width:320px;display:block;margin-left:auto;margin-right:auto;">
  <p style="font-family:Calibri,Ubuntu,Verdana;text-align:justify;">
    XXcuerpo1XX
  </p>
  <p style="font-family:Calibri,Ubuntu,Verdana;text-align:justify;">
    XXcuerpo2XX
  </p>
  <br />
  </div>
</div>
`
const mailChimpAkiKey = '7186d1c1da7bd9783d8f24f69c4362cc-us20'
const mailChimpLista = '66fe635a83'
import axios from 'axios'
import Vue from 'vue'
import Api from '@/components/Api'
let parametrosRequeridos = ['destino', 'cuerpo1', 'cuerpo2', 'titulo']
let empresa = Api.obtenerLocal('elite.empresa')
let emailOrigen = (empresa || {}).email || 'sistema@elite.mx'
let nombreOrigen = '' + (empresa || {}).nombre || 'BDLG'

let Emailer = new Vue({
  name: 'Emailer',
  data() {
    return {
      prueba: !Api.modoProduccion
    }
  },
  methods: {
    registrarMailChimp: function(usuario) {
      usuario = usuario || {}
      console.log("Registrando", usuario.rol, usuario.nombre, usuario.email)
      let dc = mailChimpAkiKey.split('-')[1]
      let url = 'https://' + dc + '.api.mailchimp.com/3.0/'
      //
      url += 'Lists'
      axios.get(url, {}, {
        headers: {
          Authorization: 'apikey ' + mailChimpAkiKey
        }
      }).then(o => {
        console.log("MC!", o)
      }).catch(e => {
        console.error("Error MC", e)
      })
    },
    enviar: function(params) {
      let vm = this
      console.log("Emailer.enviar", params)
      if (true == this.prueba) {
        /*return new Promise(resolve => {
          resolve(true)
        })*/
        params.cuerpo2 += '<br />' + params.destino
        params.destino = 'alherrera42@gmail.com'
      }
      //else
      return new Promise((resolve, reject) => {
        let cumpleParametros = true
        parametrosRequeridos.forEach(param => {
          if (!params[param])
            cumpleParametros = false
        })
        if (typeof params != 'object')
          reject({
            error: 'Debo recibir un objeto con parámetros'
          })
        else if (!cumpleParametros)
          reject({
            error: 'Debes incluir todos los parámetros',
            parametros: parametrosRequeridos
          })
        else {
          let mensaje = '' + plantilla
          for (let i of parametrosRequeridos)
            if (i != 'attachments')
              mensaje = mensaje.replace('XX' + i + 'XX', params[i])
          try {
            let objEmail = {
              emailDestino: params.destino,
              emailOrigen: emailOrigen,
              nombreOrigen: nombreOrigen,
              mensaje: mensaje,
              titulo: params.titulo || null
            }
            if (params.mailconfig && typeof params.mailconfig == 'object')
              objEmail.mailconfig = params.mailconfig
            if (params.attachments && params.attachments.length > 0) {
              objEmail.attachments = params.attachments
            }
            Api.email(objEmail).then(s => {
              resolve(s)
            }, e => {
              reject(e)
              console.error("No se pudo completar el envío del correo")
            })
          } catch (e) {
            console.error("No se pudo enviar el correo")
            reject({
              error: "No se pudo enviar el correo",
              detalles: e
            })
          }
        }
      })
    }
  },
  created() {
    console.log("Emailer montado. Modo pruebas:", this.prueba)
  }
})
export default Emailer
</script>
