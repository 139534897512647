<script>
import Api from "@/components/Api";
import Vue from "vue";
import Empresa from "@/models/Empresa";
import NumeroALetras from "../assets/numletras.js";
var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
import levenshteinDistance from "@/models/levenshteinDistance";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
let modoPruebas = !Api.modoProduccion;

function clog() {
  //return console.log(arguments)
  return true;
}

String.prototype.EncodeXMLEscapeChars = function () {
  var OutPut = this;
  if (this.trim(OutPut) != "") {
    OutPut = OutPut
      //.replace(/</g, "&lt;").replace(/>/g, "&gt;")
      //.replace(/"/g, "&quot;").replace(/'/g, "&#39;")
      .replace(/&(?!(amp;)|(lt;)|(gt;)|(quot;)|(#39;)|(apos;))/g, "&amp;")
      .replace(/\(/g, "")
      .replace(/\)/g, "")
      .replace(/\n/g, "")
      .replace(/\r/g, "")
      .replace(/([^\\])((\\\\)*)\\(?![\\/{])/g, "$1\\\\$2"); //replaces odd backslash(\\) with even.
  } else {
    OutPut = "";
  }
  return OutPut;
};

let Xml = new Vue({
  name: "XmlParser",
  methods: {
    leerDesdeInputFile: function (f) {
      return new Promise((resolve, reject) => {
        console.log("Leyendo desde Input File", f);
        var reader = new FileReader();
        reader.onloadend = () => {
          let archivo = reader.result;
          resolve(archivo);
        };
        reader.readAsDataURL(f);
      });
    },

    calcularImpuesto: (uuidFactura, impuesto, importe) => {
      impuesto = impuesto || "iva";
      importe = importe || 0;
      return new Promise(async (resolve, reject) => {
        if (!uuidFactura || uuidFactura == "")
          reject({
            error: "No fue especificada una factura",
          });
        let factura = await Api.find("Factura", ["uuid,eq," + uuidFactura]);
        factura = factura[0];
        if (importe == 0 || !importe) importe = parseFloat(factura.total);
        let impuestoNum = 0;
        console.log(
          "Factura?",
          factura.total,
          factura.xmlOriginal["cfdi:Impuestos"]
        );
        if (factura.xmlOriginal["cfdi:Impuestos"]["cfdi:Traslados"].length != 0)
          factura.xmlOriginal["cfdi:Impuestos"]["cfdi:Traslados"] = [
            factura.xmlOriginal["cfdi:Impuestos"]["cfdi:Traslados"],
          ];
        for (let traslado of factura.xmlOriginal["cfdi:Impuestos"][
          "cfdi:Traslados"
        ]) {
          let t = traslado["cfdi:Traslado"];
          console.log("Traslado", t);
          if (t["@Impuesto"] == "002" && impuesto == "iva") {
            impuestoNum +=
              (importe / parseFloat(factura.total)) * parseFloat(t["@Importe"]);
            console.log(
              "Proporcion",
              importe,
              factura.total,
              importe / parseFloat(factura.total)
            );
          }
          if (t["@Impuesto"] == "003" && impuesto == "ieps") {
            impuestoNum +=
              (importe / parseFloat(factura.total)) * parseFloat(t["@Importe"]);
            console.log(
              "Proporcion",
              importe,
              factura.total,
              importe / parseFloat(factura.total)
            );
          }
        }
        console.log(
          "Impuesto resuelto",
          uuidFactura,
          importe,
          impuesto,
          impuestoNum
        );
        resolve(impuestoNum);
      });
    },

    aObjeto: function (str) {
      function xml2json(text, tab) {
        let xmlDoc, parser;
        if (window.DOMParser) {
          // code for modern browsers
          parser = new DOMParser();
          if (text.indexOf("<") > 0) {
            // El texto no comienza con <?, hay que brincarnos el contenido
            let reemplazable = text.substring(0, text.indexOf("<"));
            text = text.replace(reemplazable, "");
            console.log(
              "Limpiada entrada al conversor XML",
              text.substring(0, 100)
            );
          }
          xmlDoc = parser.parseFromString(text, "text/xml");
        } else {
          // code for old IE browsers
          xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
          xmlDoc.async = false;
          xmlDoc.loadXML(text);
        }
        let xml = xmlDoc;

        var X = {
          toObj: function (xml) {
            var o = {};
            if (xml.nodeType == 1) {
              // element node ..
              if (xml.attributes.length)
                // element with attributes  ..
                for (var i = 0; i < xml.attributes.length; i++)
                  o["@" + xml.attributes[i].nodeName] = (
                    xml.attributes[i].nodeValue || ""
                  ).toString();
              if (xml.firstChild) {
                // element has child nodes ..
                var textChild = 0,
                  cdataChild = 0,
                  hasElementChild = false;
                for (var n = xml.firstChild; n; n = n.nextSibling) {
                  if (n.nodeType == 1) hasElementChild = true;
                  else if (
                    n.nodeType == 3 &&
                    n.nodeValue.match(/[^ \f\n\r\t\v]/)
                  )
                    textChild++;
                  // non-whitespace text
                  else if (n.nodeType == 4) cdataChild++; // cdata section node
                }
                if (hasElementChild) {
                  if (textChild < 2 && cdataChild < 2) {
                    // structured element with evtl. a single text or/and cdata node ..
                    X.removeWhite(xml);
                    for (var n = xml.firstChild; n; n = n.nextSibling) {
                      if (n.nodeType == 3)
                        // text node
                        o["#text"] = X.escape(n.nodeValue);
                      else if (n.nodeType == 4)
                        // cdata node
                        o["#cdata"] = X.escape(n.nodeValue);
                      else if (o[n.nodeName]) {
                        // multiple occurence of element ..
                        console.log("Multiple ocurrencia?", n.nodeName);
                        if (o[n.nodeName] instanceof Array) {
                          o[n.nodeName][o[n.nodeName].length] = X.toObj(n);
                        } else {
                          o[n.nodeName] = [o[n.nodeName], X.toObj(n)];
                        }
                      } // first occurence of element..
                      else o[n.nodeName] = X.toObj(n);
                    }
                  } else {
                    // mixed content
                    if (!xml.attributes.length) o = X.escape(X.innerXml(xml));
                    else o["#text"] = X.escape(X.innerXml(xml));
                  }
                } else if (textChild) {
                  // pure text
                  if (!xml.attributes.length) o = X.escape(X.innerXml(xml));
                  else o["#text"] = X.escape(X.innerXml(xml));
                } else if (cdataChild) {
                  // cdata
                  if (cdataChild > 1) o = X.escape(X.innerXml(xml));
                  else
                    for (var n = xml.firstChild; n; n = n.nextSibling)
                      o["#cdata"] = X.escape(n.nodeValue);
                }
              }
              if (!xml.attributes.length && !xml.firstChild) o = null;
            } else if (xml.nodeType == 9) {
              // document.node
              o = X.toObj(xml.documentElement);
            } else alert("unhandled node type: " + xml.nodeType);
            return o;
          },
          toJson: function (o, name, ind) {
            var json = name ? '"' + name + '"' : "";
            if (o instanceof Array) {
              for (var i = 0, n = o.length; i < n; i++)
                o[i] = X.toJson(o[i], "", ind + "\t");
              json +=
                (name ? ":[" : "[") +
                (o.length > 1
                  ? "\n" + ind + "\t" + o.join(",\n" + ind + "\t") + "\n" + ind
                  : o.join("")) +
                "]";
            } else if (o == null) json += (name && ":") + "null";
            else if (typeof o == "object") {
              var arr = [];
              for (var m in o) arr[arr.length] = X.toJson(o[m], m, ind + "\t");
              json +=
                (name ? ":{" : "{") +
                (arr.length > 1
                  ? "\n" +
                    ind +
                    "\t" +
                    arr.join(",\n" + ind + "\t") +
                    "\n" +
                    ind
                  : arr.join("")) +
                "}";
            } else if (typeof o == "string")
              json += (name && ":") + '"' + o.toString() + '"';
            else json += (name && ":") + o.toString();
            return json;
          },
          innerXml: function (node) {
            var s = "";
            if ("innerHTML" in node) s = node.innerHTML;
            else {
              var asXml = function (n) {
                var s = "";
                if (n.nodeType == 1) {
                  s += "<" + n.nodeName;
                  for (var i = 0; i < n.attributes.length; i++)
                    s +=
                      " " +
                      n.attributes[i].nodeName +
                      '="' +
                      (n.attributes[i].nodeValue || "").toString() +
                      '"';
                  if (n.firstChild) {
                    s += ">";
                    for (var c = n.firstChild; c; c = c.nextSibling)
                      s += asXml(c);
                    s += "</" + n.nodeName + ">";
                  } else s += "/>";
                } else if (n.nodeType == 3) s += n.nodeValue;
                else if (n.nodeType == 4)
                  s += "<![CDATA[" + n.nodeValue + "]]>";
                return s;
              };
              for (var c = node.firstChild; c; c = c.nextSibling) s += asXml(c);
            }
            return s;
          },
          escape: function (txt) {
            return txt
              .replace(/[\\]/g, "\\\\")
              .replace(/[\"]/g, '\\"')
              .replace(/[\n]/g, "\\n")
              .replace(/[\r]/g, "\\r");
          },
          removeWhite: function (e) {
            e.normalize();
            for (var n = e.firstChild; n; ) {
              if (n.nodeType == 3) {
                // text node
                if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) {
                  // pure whitespace text node
                  var nxt = n.nextSibling;
                  e.removeChild(n);
                  n = nxt;
                } else n = n.nextSibling;
              } else if (n.nodeType == 1) {
                // element node
                X.removeWhite(n);
                n = n.nextSibling;
              } // any other node
              else n = n.nextSibling;
            }
            return e;
          },
        };
        if (xml.nodeType == 9)
          // document node
          xml = xml.documentElement;
        var json = X.toJson(X.toObj(X.removeWhite(xml)), xml.nodeName, "\t");
        return (
          "{\n" +
          tab +
          (tab ? json.replace(/\t/g, tab) : json.replace(/\t|\n/g, "")) +
          "\n}"
        );
      }

      try {
        if (str.indexOf("base64") >= 0) str = atob(str.split(",")[1]);
        str = str.EncodeXMLEscapeChars();
        //str = this.limpiar(str)
        console.log("Convirtiendo", str.length, str.substring(0, 100));
        return JSON.parse(xml2json(str, ""));
      } catch (e) {
        let error = "No se pudo procesar el XML: " + e;
        console.error(error);
        try {
          let io = error.indexOf("position ") + 9;
          let cad = parseFloat(error.substring(io, error.length));
          console.error("STR " + str.substring(cad - 3, cad + 3), cad);
        } catch (e) {}
        return error;
      }
    },

    limpiar(str) {
      str = str || "";
      let str2 = "";
      try {
        str2 = str
          .replace(/\(/g, "")
          .replace(/\)/g, "")
          .replace(/\n/g, "")
          .replace(/\r/g, "");
      } catch (e) {
        console.error("No se pudo limpiar la cadena");
        console.error(e);
        str2 = str;
      }
      if (str2 != str) console.log("Cadena limpiada", str2);
      else console.log("Cadena conservada", str);
      return str2;
    },

    totalVeinticincoCaracteres(monto) {
      try {
        let arr = monto.split(".");
        let centavos = arr[1];
        centavos = centavos.padEnd(6, "0");
        arr[1] = centavos;
        monto = arr.join(".");
        return monto;
      } catch (e) {
        console.error("totalVeinticincoCaracteres", monto, e);
        return 0;
      }
    },

    async aPDF(xml, plantilla) {
      return new Promise(async (resolve, reject) => {
        let pdfUrl = "";
        let pdfErr = null;
        try {
          let cfdi = this.aObjeto(xml)["cfdi:Comprobante"];
          console.log("Por convertir...", plantilla, cfdi);
          // Procesar
          // Emisor
          let emisor = cfdi["cfdi:Emisor"];
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "empresa",
            emisor["@Nombre"]
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "slogan",
            emisor["@Rfc"]
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "erfc",
            emisor["@Rfc"]
          );
          let empresa = Empresa.obtenerDatos();
          if (empresa.rfc != emisor["@Rfc"] && !modoPruebas) {
            empresa = {};
          }
          if (empresa) {
            console.log("Encontrada empresa", empresa);
            plantilla.content = this.sustituirEnPlantilla(
              plantilla.content,
              "ecalle",
              empresa.calle || ""
            );
            plantilla.content = this.sustituirEnPlantilla(
              plantilla.content,
              "enumeroexterior",
              empresa.numeroExterior || ""
            );
            plantilla.content = this.sustituirEnPlantilla(
              plantilla.content,
              "enumerointerior",
              empresa.numeroInterior || ""
            );
            plantilla.content = this.sustituirEnPlantilla(
              plantilla.content,
              "ecodigopostal",
              empresa.codigoPostal || ""
            );
            plantilla.content = this.sustituirEnPlantilla(
              plantilla.content,
              "ecolonia",
              empresa.colonia || ""
            );
            plantilla.content = this.sustituirEnPlantilla(
              plantilla.content,
              "eestado",
              empresa.estado || ""
            );
            plantilla.content = this.sustituirEnPlantilla(
              plantilla.content,
              "etelefono",
              empresa.telefono || ""
            );
            plantilla.content = this.sustituirEnPlantilla(
              plantilla.content,
              "eemail",
              empresa.email || ""
            );
          }
          // Receptor
          let receptor = cfdi["cfdi:Receptor"];
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "rcliente",
            receptor["@Nombre"]
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "rrfc",
            receptor["@Rfc"]
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "usocfdi",
            receptor["@UsoCFDI"]
          );
          let direccion = " - ";
          if (Api) {
            console.log("Buscando cliente...", receptor["@Rfc"]);
            let b = await Api.find("cliente", ["rfc,eq," + receptor["@Rfc"]]);
            console.log("Coincidencias cliente", b);
            let cliente = {};
            if (b && b[0] && b[0]._id) {
              cliente = b[0];
            }
            // Buscar por distancia de Levenshtein. El nombre más similar es el que se tomará en cuenta
            if (b && b.length > 1)
              try {
                let lD = 100;
                let nombreReceptor = (receptor["@Nombre"] || "").toUpperCase();
                for (let candidato of b) {
                  let nombreCandidato = (candidato.nombre || "").toUpperCase();
                  if (
                    lD < levenshteinDistance(nombreReceptor, nombreCandidato)
                  ) {
                    lD = levenshteinDistance(nombreReceptor, nombreCandidato);
                    cliente = candidato;
                  }
                }
              } catch (e) {
                console.error(
                  "No se pudo encontrar un similar por Levenshtein",
                  e
                );
              }
            let direccionItems = [];
            for (let campo of [
              "calle",
              "numeroExterior",
              "numeroInterior",
              "colonia",
              "estado",
              "codigoPostal",
              "email",
            ])
              direccionItems.push(cliente[campo] || "");
            direccion = direccionItems.join(" ");
          }
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "rdireccion",
            direccion
          );
          // Complemento fiscal
          let comp =
            cfdi["cfdi:Complemento"]["tfd:TimbreFiscalDigital"] ||
            cfdi["cfdi:Complemento"]["tfdi:TimbreFiscalDigital"];
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "uuid",
            comp["@UUID"]
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "folio",
            (cfdi["@Serie"] || "") + (cfdi["@Folio"] || "")
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "csd",
            cfdi["@NoCertificado"]
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "fecha",
            cfdi["@Fecha"]
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "expedicion",
            cfdi["@LugarExpedicion"]
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "formapago",
            cfdi["@FormaPago"]
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "cuenta",
            cfdi["@NumeroCuenta"]
          ); // TODO
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "regimenfiscal",
            emisor["@RegimenFiscal"]
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "moneda",
            cfdi["@Moneda"]
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "tipocambio",
            cfdi["@TipoCambio"]
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "metodopago",
            cfdi["@MetodoPago"]
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "sellocfdi",
            comp["@SelloCFD"].match(/.{1,20}/g).join(" ")
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "sellosat",
            comp["@SelloSAT"].match(/.{1,20}/g).join(" ")
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "seriecertificado",
            comp["@NoCertificadoSAT"]
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "fechacertificado",
            comp["@FechaTimbrado"]
          );
          // Totales
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "subtotal",
            this.$options.filters.currency(parseFloat(cfdi["@SubTotal"]), "")
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "total",
            this.$options.filters.currency(cfdi["@Total"], "")
          );
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "NUMALETRAS",
            NumeroALetras(
              this.$options.filters.currency(cfdi["@Total"], ""),
              cfdi["@Moneda"]
            )
          );
          // CFDI relacionados
          {
            let hayCfdiRelacionados =
              cfdi["cfdi:Complemento"] &&
              cfdi["cfdi:Complemento"]["pago10:Pagos"] &&
              cfdi["cfdi:Complemento"]["pago10:Pagos"]["pago10:Pago"] &&
              cfdi["cfdi:Complemento"]["pago10:Pagos"]["pago10:Pago"][
                "pago10:DoctoRelacionado"
              ]
                ? true
                : false;
            console.log(
              "Complemento de pago?",
              cfdi["cfdi:Complemento"]["pago10:Pagos"],
              hayCfdiRelacionados
            );
            let indiceBloqueCfdiRelacionados = null;
            let bloqueCfdiRelacionados = null;
            let bloqueCfdiRelacionado = null;
            for (let ind in plantilla.content) {
              let bloque = plantilla.content[ind];
              console.log("Bloque relacionado", bloque.type, bloque);
              if (
                bloque.table &&
                bloque.table.type &&
                bloque.table.type == "cfdirelacionados"
              ) {
                indiceBloqueCfdiRelacionados = ind;
                bloqueCfdiRelacionados = bloque;
                bloqueCfdiRelacionado = JSON.parse(
                  JSON.stringify(bloque.table.body[1])
                );
                console.log(
                  "CFDI relacionados configurables",
                  bloqueCfdiRelacionado,
                  bloqueCfdiRelacionados
                );
                delete bloqueCfdiRelacionados.table.body[1];
              }
            }
            console.log(
              "Cfdi relacionables?",
              indiceBloqueCfdiRelacionados,
              bloqueCfdiRelacionados,
              bloqueCfdiRelacionado
            );
            if (bloqueCfdiRelacionado && hayCfdiRelacionados) {
              let idCfdi = 1;
              let cfdisRelacionados = cfdi["cfdi:Complemento"]["pago10:Pagos"][
                "pago10:Pago"
              ]["pago10:DoctoRelacionado"].length
                ? cfdi["cfdi:Complemento"]["pago10:Pagos"]["pago10:Pago"][
                    "pago10:DoctoRelacionado"
                  ]
                : [
                    cfdi["cfdi:Complemento"]["pago10:Pagos"]["pago10:Pago"][
                      "pago10:DoctoRelacionado"
                    ],
                  ];
              console.log("Cfdi relacionados", cfdisRelacionados);
              for (let c of cfdisRelacionados) {
                let esteCFDI = JSON.parse(
                  JSON.stringify(bloqueCfdiRelacionado)
                );
                let monedadr = c["@MonedaDR"];
                if (monedadr != "MXN")
                  monedadr += " @ " + parseFloat(c["@TipoCambioDR"]).toFixed(2);
                esteCFDI = this.sustituirEnPlantilla(
                  esteCFDI,
                  "uuidrel",
                  c["@IdDocumento"]
                );
                esteCFDI = this.sustituirEnPlantilla(
                  esteCFDI,
                  "valorrel",
                  parseFloat(c["@ImpPagado"]).toFixed(2)
                );
                esteCFDI = this.sustituirEnPlantilla(
                  esteCFDI,
                  "saldorel",
                  parseFloat(c["@ImpSaldoInsoluto"]).toFixed(2)
                );
                esteCFDI = this.sustituirEnPlantilla(
                  esteCFDI,
                  "monedarel",
                  monedadr
                );
                bloqueCfdiRelacionados.table.body[idCfdi] = esteCFDI;
                idCfdi++;
              }
              let esteCFDITotal = JSON.parse(
                JSON.stringify(bloqueCfdiRelacionado)
              );
              esteCFDITotal = this.sustituirEnPlantilla(
                esteCFDITotal,
                "uuidrel",
                "TOTAL"
              );
              esteCFDITotal = this.sustituirEnPlantilla(
                esteCFDITotal,
                "valorrel",
                ""
              );
              esteCFDITotal = this.sustituirEnPlantilla(
                esteCFDITotal,
                "saldorel",
                parseFloat(
                  cfdi["cfdi:Complemento"]["pago10:Pagos"]["pago10:Pago"][
                    "@Monto"
                  ]
                ).toFixed(2)
              );
              esteCFDITotal = this.sustituirEnPlantilla(
                esteCFDITotal,
                "monedarel",
                cfdi["cfdi:Complemento"]["pago10:Pagos"]["pago10:Pago"][
                  "@MonedaP"
                ]
              );
              bloqueCfdiRelacionados.table.body[idCfdi] = esteCFDITotal;
              // Totales
              plantilla.content[indiceBloqueCfdiRelacionados] =
                bloqueCfdiRelacionados;
              console.log("Cfdi Relacionados en tabla", bloqueCfdiRelacionados);
            } else {
              // Desaparecer el espacio
              plantilla.content[indiceBloqueCfdiRelacionados] = {
                text: "",
              };
            }
          }
          // Partidas
          try {
            let indiceBloquePartidas = null;
            let bloquePartidas = null;
            let bloquePartida = null;
            for (let ind in plantilla.content) {
              let bloque = plantilla.content[ind];
              console.log("Explorando partidas en plantilla", bloque.table);
              if (
                bloque.table &&
                bloque.table.type &&
                bloque.table.type == "partidas"
              ) {
                indiceBloquePartidas = ind;
                bloquePartidas = bloque;
                bloquePartida = JSON.parse(
                  JSON.stringify(bloque.table.body[1])
                );
                console.log(
                  "Partidas configurables",
                  bloquePartida,
                  indiceBloquePartidas
                );
                delete bloquePartidas.table.body[1];
              }
            }
            if (bloquePartida) {
              let conceptos = cfdi["cfdi:Conceptos"]["cfdi:Concepto"].length
                ? cfdi["cfdi:Conceptos"]["cfdi:Concepto"]
                : [cfdi["cfdi:Conceptos"]["cfdi:Concepto"]];
              console.log("Conceptos", conceptos);
              let idConcepto = 1;
              for (let o of conceptos) {
                let c = o;
                if (!o["@Cantidad"] && o["cfdi:Concepto"])
                  c = o["cfdi:Concepto"];
                console.log("Concepto", c);
                let estaPartida = JSON.parse(JSON.stringify(bloquePartida));
                estaPartida = this.sustituirEnPlantilla(
                  estaPartida,
                  "cantidad",
                  c["@Cantidad"]
                );
                estaPartida = this.sustituirEnPlantilla(
                  estaPartida,
                  "unidad",
                  c["@Unidad"]
                );
                estaPartida = this.sustituirEnPlantilla(
                  estaPartida,
                  "unidadsat",
                  c["@ClaveUnidad"]
                );
                estaPartida = this.sustituirEnPlantilla(
                  estaPartida,
                  "productonombre",
                  c["@Descripcion"]
                );
                estaPartida = this.sustituirEnPlantilla(
                  estaPartida,
                  "punitario",
                  this.$options.filters.currency(
                    parseFloat(c["@ValorUnitario"]),
                    ""
                  )
                );
                estaPartida = this.sustituirEnPlantilla(
                  estaPartida,
                  "importe",
                  this.$options.filters.currency(parseFloat(c["@Importe"]), "")
                );
                estaPartida = this.sustituirEnPlantilla(
                  estaPartida,
                  "clavesat",
                  c["@ClaveProdServ"]
                );
                estaPartida = this.sustituirEnPlantilla(
                  estaPartida,
                  "claveidentificacion",
                  c["@ClaveProdServ"]
                );
                try {
                  let impuestosPartida = [];
                  if (c["cfdi:Impuestos"]) {
                    if (c["cfdi:Impuestos"]["cfdi:Traslados"]) {
                      let traslados = c["cfdi:Impuestos"]["cfdi:Traslados"];
                      if (!traslados.length) traslados = [traslados];
                      for (let traslado of traslados) {
                        traslado["cfdi:Traslado"].tipo = "traslado";
                        impuestosPartida.push(traslado["cfdi:Traslado"]);
                      }
                    }
                    if (c["cfdi:Impuestos"]["cfdi:Retenciones"]) {
                      let retenciones = c["cfdi:Impuestos"]["cfdi:Retenciones"];
                      if (!retenciones.length) retenciones = [retenciones];
                      for (let retencion of retenciones) {
                        retencion["cfdi:Retencion"].tipo = "retencion";
                        impuestosPartida.push(retencion["cfdi:Retencion"]);
                      }
                    }
                  }
                  console.log(
                    "Preparando impuestos de partida",
                    impuestosPartida,
                    estaPartida[2][2].table.body[0][0].text
                  );
                  let numImpuesto = 0;
                  let renglonPartida = JSON.parse(
                    JSON.stringify(estaPartida[2][2].table.body[0][0])
                  );
                  for (let i of impuestosPartida) {
                    let estaPartidaImpuestos = JSON.parse(
                      JSON.stringify(renglonPartida)
                    );
                    estaPartidaImpuestos = this.sustituirEnPlantilla(
                      estaPartidaImpuestos,
                      "impuestonumcatalogo",
                      i["@Impuesto"] + " " + i.tipo
                    );
                    estaPartidaImpuestos = this.sustituirEnPlantilla(
                      estaPartidaImpuestos,
                      "impuestotipo",
                      i["@TipoFactor"]
                    );
                    estaPartidaImpuestos = this.sustituirEnPlantilla(
                      estaPartidaImpuestos,
                      "impuestotasa",
                      i["@TasaOCuota"]
                    );
                    estaPartidaImpuestos = this.sustituirEnPlantilla(
                      estaPartidaImpuestos,
                      "impuestoimporte",
                      i["@Importe"]
                    );
                    estaPartida[2][2].table.body[0][numImpuesto] =
                      estaPartidaImpuestos;
                    console.log(
                      "Preparando impuesto",
                      numImpuesto,
                      i,
                      estaPartidaImpuestos
                    );
                    numImpuesto++;
                  }
                } catch (e) {
                  console.error(
                    "No se pudo procesar el impuesto de la partida",
                    e
                  );
                }
                bloquePartidas.table.body[idConcepto] = estaPartida;
                idConcepto++;
              }
              plantilla.content[indiceBloquePartidas] = bloquePartidas;
            }
          } catch (e) {
            alert("No se pudieron reconstruir las lartidas");
            console.error(e);
          }
          // Impuestos
          let indiceBloqueImpuestosTotales = null,
            bloqueImpuestosTotales = null,
            bloqueImpuesto = null,
            bloqueTotal = null;
          try {
            let impuestosTabulables = [];
            let cfdiImpuestos = cfdi["cfdi:Impuestos"];
            if (
              cfdiImpuestos &&
              cfdiImpuestos["cfdi:Traslados"] &&
              cfdiImpuestos["cfdi:Traslados"]["cfdi:Traslado"]
            ) {
              let traslados = cfdiImpuestos["cfdi:Traslados"]["cfdi:Traslado"];
              if (!traslados.length) traslados = [traslados];
              for (let t of traslados) {
                t.tipo = "traslado";
                impuestosTabulables.push(t);
              }
            }
            if (
              cfdiImpuestos &&
              cfdiImpuestos["cfdi:Retenciones"] &&
              cfdiImpuestos["cfdi:Retenciones"]["cfdi:Retencion"]
            ) {
              let retenciones =
                cfdiImpuestos["cfdi:Retenciones"]["cfdi:Retencion"];
              if (!retenciones.length) retenciones = [retenciones];
              for (let t of retenciones) {
                t.tipo = "retencion";
                console.log("Encontrada retencion", t);
                impuestosTabulables.push(t);
              }
            }
            console.log("impuestosTabulables", impuestosTabulables);
            for (let i in plantilla.content) {
              let bloque = plantilla.content[i];
              console.log("B", bloque.type, bloque);
              if (bloque.type == "impuestos") {
                bloqueImpuestosTotales = bloque;
                indiceBloqueImpuestosTotales = i;
                bloqueImpuesto = JSON.parse(
                  JSON.stringify(bloque.table.body[1])
                );
                bloqueTotal = JSON.parse(JSON.stringify(bloque.table.body[2]));
                bloqueImpuestosTotales.table.body = [
                  bloqueImpuestosTotales.table.body[0],
                ];
                console.log(
                  "Encontrado bloque impuestos",
                  bloqueImpuestosTotales,
                  bloqueImpuesto,
                  bloqueTotal
                );
              }
            }
            if (bloqueImpuesto && bloqueTotal) {
              let indiceImpuesto = 1;
              for (let imp of impuestosTabulables) {
                let esteImpuesto = JSON.parse(JSON.stringify(bloqueImpuesto));
                console.log("esteImpuesto", esteImpuesto, imp);
                esteImpuesto = this.sustituirEnPlantilla(
                  esteImpuesto,
                  "impuesto",
                  imp["@TipoFactor"] +
                    " " +
                    imp.tipo +
                    " " +
                    imp["@Impuesto"]
                      .replace("002", "002 IVA")
                      .replace("003", "003 IEPS") +
                    " " +
                    parseFloat(imp["@TasaOCuota"])
                );
                esteImpuesto = this.sustituirEnPlantilla(
                  esteImpuesto,
                  "importei",
                  this.$options.filters.currency(
                    parseFloat(imp["@Importe"]),
                    ""
                  )
                );
                indiceImpuesto++;
                bloqueImpuestosTotales.table.body.push(esteImpuesto);
              }
              bloqueImpuestosTotales.table.body.push(bloqueTotal);
            }
          } catch (e) {
            alert("No se pudieron reconstruir los impuestos ");
            console.error(e);
            console.error(
              "indiceBloqueImpuestosTotales",
              indiceBloqueImpuestosTotales,
              bloqueImpuestosTotales,
              bloqueImpuesto,
              bloqueTotal,
              plantilla
            );
          }
          // Cadena original y link de verificación
          let plantillaCadenaOriginal =
            "||VERSION|UUID|FECHACERTIFICADO|RFCPROVEEDORCERTIFICACION|SELLOSAT|CSDSAT||";
          let cadenaoriginal = plantillaCadenaOriginal;
          if (cfdi["@OriginalString"]) cadenaoriginal = cfdi["@OriginalString"];
          else {
            cadenaoriginal = cadenaoriginal.replace(
              "VERSION",
              comp["@Version"]
            );
            cadenaoriginal = cadenaoriginal.replace("UUID", comp["@UUID"]);
            cadenaoriginal = cadenaoriginal.replace(
              "FECHACERTIFICADO",
              comp["@FechaTimbrado"]
            );
            cadenaoriginal = cadenaoriginal.replace(
              "RFCPROVEEDORCERTIFICACION",
              comp["@RfcProvCertif"]
            );
            cadenaoriginal = cadenaoriginal.replace(
              "SELLOSAT",
              comp["@SelloCFD"]
            );
            cadenaoriginal = cadenaoriginal.replace(
              "CSDSAT",
              comp["@NoCertificadoSAT"]
            );
          }
          plantilla.content = this.sustituirEnPlantilla(
            plantilla.content,
            "cadenaxslt",
            cadenaoriginal.match(/.{1,20}/g).join(" ")
          );
          let linkQR =
            "https://verificacfdi.facturaelectronica.sat.gob.mx/default.aspx";
          linkQR += "?id=" + comp["@UUID"];
          linkQR += "&re=" + emisor["@Rfc"];
          linkQR += "&rr=" + receptor["@Rfc"];
          linkQR += "&tt=" + this.totalVeinticincoCaracteres(cfdi["@Total"]);
          linkQR +=
            "&fe=" +
            comp["@SelloCFD"].substring(
              comp["@SelloCFD"].length - 8,
              comp["@SelloCFD"].length
            );
          // Imagenes
          {
            let logo, qr;
            let qrResponse = await Api.qrcode(linkQR);
            console.log("qrResponse", qrResponse.data);
            if (qrResponse && qrResponse.data && qrResponse.data.qr)
              qr = qrResponse.data.qr;
            if (empresa && empresa.logo) logo = empresa.logo;
            else logo = (await Api.qrcode("https://app.haakon.cc")).data.qr;
            //logo = qr
            plantilla.images = this.sustituirEnPlantilla(
              plantilla.images,
              "logo",
              logo
            );
            plantilla.images = this.sustituirEnPlantilla(
              plantilla.images,
              "qr",
              qr
            );
          }
          //
          let buff = pdfMake.createPdf(plantilla);
          console.log("aPDF plantilla", plantilla);
          console.log("aPDF buffer", buff);
          buff.getBase64((data) => {
            console.log("Yay! we have pdf", data.length);
            setTimeout((_) => {
              resolve(data);
            }, 1000);
          });
        } catch (e) {
          pdfErr = e;
          console.error("EPDF", pdfErr, plantilla);
          reject(pdfErr);
        }
      });
    },

    sustituirEnPlantilla(plantilla, campo, valor, img) {
      plantilla = plantilla || {};
      campo = campo || "";
      img = img && true;
      if (!campo.startsWith("\$")) {
        campo = "\$" + campo.toUpperCase() + "\$";
        //clog("Convertido campo", campo)
      }
      valor = valor || "";
      if (typeof plantilla == "object") {
        for (let index in plantilla) {
          //clog("sustituirEnPlantilla comprobando", index, typeof plantilla[index])
          if (
            typeof plantilla[index] == "string" &&
            plantilla[index].indexOf(campo) >= 0
          ) {
            //clog("sustituirEnPlantilla encontrado string", plantilla[index])
            plantilla[index] = plantilla[index].replace(campo, valor);
          } else if (
            plantilla[index] &&
            plantilla[index].text &&
            typeof plantilla[index].text == "string" &&
            plantilla[index].text.indexOf(campo) >= 0
          ) {
            //clog("sustituirEnPlantilla encontrado .text", plantilla[index].text)
            plantilla[index].text = plantilla[index].text.replace(campo, valor);
          } else if (typeof plantilla[index] != "function") {
            //clog("sustituirEnPlantilla next", plantilla[index])
            plantilla[index] = this.sustituirEnPlantilla(
              plantilla[index],
              campo,
              valor
            );
          }
        }
      } else if (typeof plantilla == "string")
        plantilla = plantilla.replace(campo, valor);
      //clog("sustituirEnPlantilla done", typeof plantilla, campo, valor)
      return plantilla;
    },
  },
});
export default Xml;
</script>
