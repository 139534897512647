<script>
import {
  Bar,
  mixins
} from 'vue-chartjs'
const {
  reactiveProp
} = mixins
export default {
  name: 'ChartBarras',
  extends: Bar,
  mixins: [reactiveProp],
  components: {
    Bar
  },
  props: {
    id: {
      type: String,
      default: 'chart'
    },
    options: {
      type: Object,
      default: function() {
        return {
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true,
              display: true,
              ticks: {
                beginAtZero: true,
                suggestedMin: 0,
              }
            }]
          },
        }
      }
    }
  },
  mounted() {
    console.log("Dibujando BarChart", new Date().getTime())
    this.renderChart(this.chartData, this.options)
  }
}
</script>
