<template>
<div v-if="permiso('VerDashboard') || permiso('ReporteActividadesPorUsuario')">
  <div v-if="modoGrafico">
    <ion-item :color="colors('light')" lines="none">
      <ion-label>Actividades por área</ion-label>
    </ion-item>
    <canvas id="chartdonut" />
  </div>
  <div v-else>
    <ion-item :color="colors('light')">
      <ion-label>Reporteo</ion-label>
      <!--ion-note slot="end">
      Mostrar todos
    </ion-note>
    <ion-checkbox :checked="incluirBDLG" v-on:ionChange="toggleIncluirBDLG($event.detail.checked==true)" slot="end"></ion-checkbox-->
    </ion-item>
    <ion-item :color="colors('light')">
      <ion-label>({{filtros.fechaInicio | moment('DD-MM-YYYY')}} @ {{filtros.fechaFin | moment('DD-MM-YYYY')}})</ion-label>
      <ion-button slot="end" :color="colors('dark')" fill="outline" @click="copiarTabla">
        <i class="icon ion-ios-copy"></i>
      </ion-button>
    </ion-item>
    <div class="scrollable">
      <canvas id="chartdonut" />
      <table id="tabla">
        <thead>
          <tr>
            <th>Area</th>
            <th class="ion-text-right">Horas</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(a,i) in tablaAreas" :key="i">
            <td>{{i}}</td>
            <td class="ion-text-right">{{a | currency('')}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <th class="ion-text-right">{{totalHoras | currency('')}}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
<div v-else>
  <p>No tiene permisos para este contenido</p>
</div>
</template>
<style>
.scrollable {
  width: 100%;
  display: block;
  overflow-x: scroll;
}

table td {
  border: 1px solid #eee;
}

table td,
table th {
  font-size: 14px;
}

.divisor {
  background-color: #eee;
}
</style>
<script>
import chartjs from 'chart.js'
import Api from '@/components/Api'
//import ChartLinea from '@/components/ChartLinea'
import Cliente from '@/models/Cliente'
import Usuario from '@/models/Usuario'
import Colores from '@/models/Colores'
import Reporteable from '@/models/Reporteable'
export default {
  name: 'ChartActividadesPorArea',
  props: ['filtros', 'abogados', 'clientes', 'areas', 'corporativos', 'unSoloAbogado', 'unSoloCliente', 'modoGrafico'],
  components: {
    //ChartLinea
  },
  filters: {
    filtroTiempo: function(cant) {
      cant = parseFloat(cant)
      let parteEntera = Math.floor(cant / 60)
      let parteDecimal = (cant % 60).toFixed(0)
      if (parteDecimal.length == 1)
        parteDecimal = '0' + parteDecimal
      return parteEntera + ':' + parteDecimal
    }
  },
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo'),
      chartdata: {},
      chartoptions: {},
      incluirBDLG: true,
      clientesBDLG: [],
      clientesTodos: [],
      abogadosTodos: [],
      mostrarGrafico: false,
      tiempoReportable: (60 * 8 * 5),
      anosAtras: 2,
      tablaAreas: {},
      totalHoras: 0,
      areasOmitibles: ['Informatica', 'General', 'Administracion', 'Informática', 'Administración']
    }
  },
  methods: {
    toggleIncluirBDLG(val) {
      this.incluirBDLG = val
      this.clientesBDLG = []
      if (!this.incluirBDLG)
        for (let c of this.clientesTodos) {
          if (c.nombre.toLowerCase().indexOf('bufete de la garza') >= 0) {
            console.log("Omitiendo", c.nombre)
            this.clienteselite.push(c._id)
          }
        }
      console.log("Consultando clientes. Omitiendo?", this.clientesBDLG)
      this.obtener()
    },
    randomColor() {
      var color = "#" + Math.floor(Math.random() * 16777216).toString(16)
      console.log("Unacceptaboooool!", color)
      if (color.length == 7)
        return color
      else return this.randomColor()
    },
    async obtener() {
      console.log("Obteniendo", this.filtros)
      this.mostrarGrafico = false
      let fI = new Date(this.filtros.fechaInicio)
      let fF = new Date(this.filtros.fechaFin)
      //let fF = new Date()
      //let fI = new Date(new Date().getFullYear() - this.anosAtras, 0, 1)
      console.log("fI", fI)
      console.log("fF", fF)
      let filtros = [
        'fechaProgramada,gte,' + fI.getTime().toString(),
        'fechaProgramada,lt,' + fF.getTime().toString()
      ]
      if (this.filtros.idCliente && this.filtros.idCliente.length == 24)
        filtros.push('idCliente,eq,' + this.filtros.idCliente)
      //let actividades = await Api.find('actividad', filtros)
      let resumen = await Reporteable.obtenerResumenActividades(fI, fF, null, 'dia', filtros)
      let actividades = resumen.actividades
      let usuariosIndices = this.abogadosTodos.map(u => u._id)
      let clientesIndices = this.clientesTodos.map(c => c._id)
      /*
      actividades = actividades
        .filter(a => {
          return a.idAsunto && a.idAbogado && a.idCliente &&
            clientesIndices.indexOf(a.idCliente) >= 0 &&
            usuariosIndices.indexOf(a.idAbogado) >= 0
        })
        .sort((a, b) => {
          return a.fechaProgramada < b.fechaProgramada ? 1 : -1
        })
      let asuntos = await Api.get('asunto')
      actividades = actividades.map(a => {
        if (!a.area) {
          for (let asunto of asuntos)
            if (asunto._id == a.idAsunto)
              a.area = asunto.area
        }
        return a
      })
      */
      if (this.areasOmitibles && this.areasOmitibles.length > 0) {
        actividades = actividades.filter(a => {
          return this.areasOmitibles.indexOf(a.area) == -1
        })
      }
      console.log("Acts?", actividades.length, this.clientesBDLG)
      //
      this.chartoptions = {
        responsive: true,
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Actividades por área'
        },
        animation: {
          animateScale: true,
          animateRotate: true
        }
      }
      this.chartdata = {
        type: 'doughnut',
        data: {
          datasets: [{
            label: 'Actividades por área',
            backgroundColor: [],
            data: []
          }],
          labels: []
        }
      }
      this.chartdata.options = this.chartoptions
      let areas = {}
      let totalHoras = 0
      for (let a of actividades) {
        let existeArea = a.area && a.area != undefined && typeof a.area != 'undefined'
        if (!areas[a.area] && existeArea)
          areas[a.area] = 0
        if (existeArea) {
          areas[a.area] += parseFloat(a.minutos) / 60
          totalHoras += parseFloat(a.minutos) / 60
        }
      }
      this.tablaAreas = areas
      this.totalHoras = totalHoras
      console.log("Areas?", areas)
      let indice = 0
      for (let a in areas) {
        this.chartdata.data.labels.push(a)
        //let tiempo = areas[a] / totalHoras * 100
        let tiempo = areas[a]
        tiempo = tiempo.toFixed(2)
        this.chartdata.data.datasets[0].data.push(tiempo)
        this.chartdata.data.datasets[0].backgroundColor.push(Colores.get(indice))
        indice++
      }
      // Generar grafica?
      console.log("Graficable?", this.chartdata)
      let chartdonut = document.querySelector('#chartdonut')
      console.log("chartdonut", chartdonut)
      let ctx = chartdonut.getContext('2d')
      window.myDoughnut = new Chart(ctx, this.chartdata)
      this.$forceUpdate()
    },
    copiarTabla() {
      function selectElementContents(el) {
        var body = document.body,
          range, sel;
        if (document.createRange && window.getSelection) {
          range = document.createRange();
          sel = window.getSelection();
          sel.removeAllRanges();
          try {
            range.selectNodeContents(el);
            sel.addRange(range);
          } catch (e) {
            range.selectNode(el);
            sel.addRange(range);
          }
        } else if (body.createTextRange) {
          range = body.createTextRange();
          range.moveToElementText(el);
          range.select();
        }
      }
      let tabla = document.querySelector('#tabla')
      selectElementContents(tabla)
      document.execCommand('copy')
      console.log("Copiado?", tabla.value)
      alert("Copiado al portapapees")
    }
  },
  watch: {
    filtros: function() {
      this.obtener()
    }
  },
  async mounted() {
    console.log("ReporteIndiceReporteo mounted")
    this.clientesTodos = await Cliente.lista()
    this.abogadosTodos = await Usuario.lista()
    this.toggleIncluirBDLG(false)
  }
}
</script>
