<script>
import Api from '@/components/Api'
import Vue from 'vue'
let reporteable = new Vue({
  name: 'Reporteable',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      empresa: Api.obtenerLocal('elite.empresa') || {},
      usuarios: [],
      clientes: [],
      corporativos: [],
      asuntos: [],
      asuntosMapa: {},
      modoProduccion: Api.modoProduccion,
      limiteInclusion: 200
    }
  },
  methods: {
    async obtenerResumenActividades(rangoInicio, rangoFin, agruparPorEntidad, agruparPorFecha, filtrosExternos, noFiltrar) {
      noFiltrar = noFiltrar && true
      rangoInicio = new Date(rangoInicio) || new Date().getTime() - (1000 * 60 * 60 * 24 * 30)
      rangoFin = new Date(rangoFin) || new Date().getTime()
      agruparPorEntidad = agruparPorEntidad || 'usuario'
      agruparPorFecha = agruparPorFecha || 'mes'
      filtrosExternos = filtrosExternos || []
      let regexpFecha = (agruparPorFecha == 'mes') ? 'YYYY-MM' : 'YYYY-MM-DD'
      let stepFecha = (agruparPorFecha == 'mes') ? 30 : 1
      let tsoffset = (new Date().getTimezoneOffset() / 60)
      let resultados = {}
      try {
        let fI = (rangoInicio).getTime() //+(1000*60*60*tsoffset)
        let fF = (rangoFin).getTime() + (1000 * 60 * 60 * (tsoffset + 10))
        let filtros = [
          'fechaProgramada,gte,' + (fI).toString(),
          'fechaProgramada,lt,' + (fF).toString()
        ]
        for (let f of filtrosExternos)
          if (typeof f == 'string')
            filtros.push(f)
        // Obtener las actividades
        console.log("Obteniendo actividades", filtros)
        let actividades = await Api.find('actividad', filtros)
        // Filtrar las actividades
        let asuntosIndices = this.asuntos.map(a => a._id)
        let clientesIndices = this.clientes.map(c => c._id)
        let usuariosIndices = this.usuarios.map(u => u._id)
        actividades = actividades
          .map(a => {
            if (typeof a.fechaProgramada == 'string')
              a.fechaProgramada = new Date(a.fechaProgramada).getTime()
            if (!(a.idAbogado && a.idAbogado != '') && a.idAutor)
              a.idAbogado = a.idAutor
            a.minutos = parseInt(a.minutos || 0)
            if (this.asuntosMapa[a.idAsunto]) {
              let asunto = this.asuntosMapa[a.idAsunto]
              a.area = asunto.area
            }
            if (this.asuntosMapa[a.idAsunto] && this.asuntosMapa[a.idAsunto]._id) {
              a.nombreAsunto = this.asuntosMapa[a.idAsunto].nombre
            }
            for (let c of this.clientes) {
              if (c._id == a.idCliente) {
                a.nombreCliente = c.nombre
              }
            }
            for (let lawyer of this.usuarios)
              if (lawyer._id == a.idAbogado) {
                a.nombreAbogado = lawyer.nombre
                try {
                  let tipoAbogado = lawyer.tipo || 'pasante'
                  a.tarifaEstandar = this.empresa.tarifas[tipoAbogado] ||
                    this.empresa.tarifas['socio'] ||
                    0
                  a.costoEstandar = a.tarifaEstandar * a.minutos / 60
                } catch (e) {
                  console.error("No se puede recuperar la tarifa del abogado", e, this.empresa.tarifas)
                }
              }
            /*
            2020-02-21
            TODO Ocurre que cuando se convierten entrada de tipo YYYY-MM-DD a timestamp,
            Javascript omite el offset del huso horario. Así que hay que sumarle a todas
            las actividades el offset para que las fechas se interpreten correctamente.
            Cambiar el formato de la fecha de integer a YYYY-MM-DD resolvería el problema,
            pero el motor de base de datos no compara ni ordena correctamente los campos
            por cadenas, y no hay en realidad otro "formato universal" para las fechas.
            Así que se mapean desde aquí.
            */
            //if (a.fechaProgramada % 1000 == 0)
            a.fechaProgramada += (1000 * 60 * 60 * tsoffset)
            //
            return a
          })
          .sort((a, b) => {
            return a.fechaProgramada > b.fechaProgramada ? 1 : -1
          }) //*
          .filter(a => {
            if (noFiltrar)
              return true
            else
              return (
                a.idAsunto &&
                clientesIndices.indexOf(a.idCliente) >= 0 &&
                usuariosIndices.indexOf(a.idAbogado) >= 0 &&
                asuntosIndices.indexOf(a.idAsunto) >= 0
              )
          })
        //*/
        console.log("Actividades filtradas", actividades.length)
        //if (!Api.modoProduccion) console.table(actividades)
        // Resumen por cliente
        let resumenPorCliente = {}
        for (let cl of this.clientes) {
          resumenPorCliente[cl._id] = {
            total: 0,
            nombre: cl.nombre,
            fechas: {},
            actividades: []
          }
          for (let act of actividades)
            if (cl._id == act.idCliente) {
              let minutos = act.minutos
              let fecha = this.$options.filters.moment(act.fechaProgramada, regexpFecha)
              resumenPorCliente[cl._id].fechas[fecha] = (resumenPorCliente[cl._id].fechas[fecha] || 0) + minutos
              resumenPorCliente[cl._id].total += minutos
              resumenPorCliente[cl._id].actividades.push(act._id)
            }
        }
        // Resumen por usuario
        let resumenPorUsuario = {}
        for (let cl of this.usuarios) {
          resumenPorUsuario[cl._id] = {
            total: 0,
            nombre: cl.nombre,
            fechas: {},
            strs: [],
            actividades: []
          }
          for (let act of actividades)
            if (cl._id == act.idAbogado || cl._id == act.idAutor) {
              let minutos = act.minutos
              let fecha = this.$options.filters.moment(act.fechaProgramada, regexpFecha)
              resumenPorUsuario[cl._id].fechas[fecha] = (resumenPorUsuario[cl._id].fechas[fecha] || 0) + minutos
              resumenPorUsuario[cl._id].total += minutos
              resumenPorUsuario[cl._id].actividades.push(act._id)
            }
        }
        // Resumen por area
        let resumenPorArea = {
          areas: {},
          total: 0
        }
        for (let act of actividades) {
          if (act.area && act.area != '') {
            let minutos = parseFloat(act.minutos)
            if (!resumenPorArea.areas[act.area]) {
              console.log("Configurando area?", act.area)
              resumenPorArea.areas[act.area] = {
                total: 0,
                nombre: act.area
              }
            }
            resumenPorArea.total += minutos
            resumenPorArea.areas[act.area].total += minutos
          }
        }
        // Devolver
        resultados = {
          actividades,
          resumenPorCliente,
          resumenPorUsuario,
          resumenPorArea
        }
        return Promise.resolve(resultados)
      } catch (e) {
        console.error(e)
        return Promise.reject(e)
      }
    },
    async obtenerCatalogos() {
      this.usuarios = await Api.get('usuario')
      this.clientes = await Api.get('cliente')
      this.corporativos = await Api.get('corporativo')
      this.asuntos = await Api.get('asunto')
      this.asuntosMapa = {}
      for (let a of this.asuntos)
        this.asuntosMapa[a._id] = a
      console.log("Catalogos", {
        usuarios: this.usuarios.length,
        clientes: this.clientes.length,
        corporativos: this.corporativos.length,
        asuntos: this.asuntos.length,
        //asuntosMapa: this.asuntosMapa
      })
      return Promise.resolve(true)
    },
    getDaysInMonth(month, year) {
      let f = new Date(year, month + 1, 0)
      console.log("Fecha para dias del mes?", f)
      return f.getDate()
    },
    horasLaborablesEsperadas(fechaMes) {
      fechaMes = fechaMes || new Date().getTime()
      let fechaInicio = new Date(fechaMes)
      let yearInicio = fechaInicio.getFullYear()
      let monthInicio = fechaInicio.getMonth() + 1
      let diasEnMes = this.getDaysInMonth(monthInicio, yearInicio)
      let dd = diasEnMes
      for (let i = 1; i <= dd; i++) {
        let fechaInicio2 = fechaInicio.setDate(i)
        fechaInicio2 = new Date(fechaInicio2).setMonth(monthInicio)
        fechaInicio2 = new Date(fechaInicio2)
        let diaDeLaSemana = fechaInicio2.getDay()
        if (diaDeLaSemana == 0 || diaDeLaSemana == 6) {
          //console.log("Dia?", i, diaDeLaSemana, fechaInicio2)
          diasEnMes--
        }
      }
      let horasLaborablesEsperadas = 8 * diasEnMes
      console.log("horasLaborablesEsperadas?", fechaMes, horasLaborablesEsperadas)
      return horasLaborablesEsperadas
    }
  },
  async created() {
    await this.obtenerCatalogos()
    console.log("Reporteable listo", this.empresa)
  }
})
export default reporteable
</script>
