<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-title>Acuerdos de cobranza ({{acuerdos.length}})</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <ion-icon name="menu" :color="colors('light')"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-list>
      <ion-item-sliding v-for="a in acuerdos" :key="a._id">
        <ion-item @click="ver(a)">
          <ion-label text-wrap>
            <h2>{{a.nombre}}</h2>
            <p>Clientes: {{(a.clientes).map(cl=>cl.nombre).join(', ')}}</p>
            <p>De {{a.desdeFecha | moment('MMM YYYY')}} a {{a.hastaFecha | moment('MMM YYYY')}}</p>
          </ion-label>
          <ion-note slot="end" class="ion-text-capitalize">{{a.tipo}}, {{a.asuntos.length}} asunto{{a.asuntos.length>1?'s':''}}</ion-note>
        </ion-item>
        <ion-item-options side="end">
          <ion-item-option color="danger" @click="eliminar(a)">
            <i class="icon ion-ios-trash"></i>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('secondary')" v-on:click="ver({_id:'0'})">
        <i class="icon ion-ios-add icon-lg"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
export default {
  name: 'Acuerdos',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      empresa: Api.obtenerLocal('elite.empresa') || {},
      areas: [],
      area: {},
      acuerdos: []
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async obtener() {
      this.acuerdos = (await Api.get('acuerdocobranza'))
        .sort((a, b) => a.fecha > b.fecha ? 1 : -1)
      console.log("Acuerdos?", this.acuerdos.length)
    },
    ver(acuerdo) {
      this.$router.push('/acuerdos/' + acuerdo._id)
    },
    async eliminar(acuerdo) {
      console.log("eliminar", acuerdo.nombre, acuerdo._id)
      if (true == confirm('Desea eliminar este elemento? Esto no se puede deshacer')) {
        // Primero actualizar los asuntos
        let asuntos = (await Api.get('asunto'))
          .filter(a => {
            return (a.histogramaCobranza || [])
              .filter(h => {
                console.log("histogramaCobranza", h.idAcuerdocobranza, acuerdo._id, h.nombre)
                return h.idAcuerdocobranza == acuerdo._id
              })
              .length > 0
          })
        for (let a of asuntos) {
          a.histogramaCobranza = (a.histogramaCobranza || [])
            .filter(h => h.idAcuerdocobranza != acuerdo._id)
          await Api.save('asunto', a)
        }
        console.log("Asuntos involucrados?", asuntos.length, asuntos.map(a => ((a.histogramaCobranza || []).map(h => h.nombre))))
        // Luego el acuerdo en sí
        await Api.delete('acuerdocobranza', acuerdo._id)
        alert("Eliminado acuerdo")
        await Api.wait(1000)
        // TODO Eliminar acuerdo de cada asunto involucrado
        this.obtener()
      }
    }
  },
  mounted() {
    this.obtener()
  }
}
</script>

<style>

</style>
