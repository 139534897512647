<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-title>Catalogos - Clientes ({{clientesTodos.length}})</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button @click="csv" v-if="yo.rol=='administrador'">
          <i class="icon ion-ios-download"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-searchbar placeholder="Filtrar por nombre o RFC" debounce="500" :value="filtro" v-on:ionChange="filtrar($event.detail.value)"></ion-searchbar>
    <ion-list id="listaClientes">
      <ion-item-divider>Clientes
        <ion-icon slot="end" name="refresh" v-on:click="obtener()"></ion-icon>
      </ion-item-divider>
      <ion-item-sliding v-for="u in clientes" :key="u._id">
        <ion-item v-on:click="ver(u._id)">
          <ion-label text-wrap>
            <h2>{{u.nombre}} {{u.apellidoPaterno}}</h2>
          </ion-label>
          <ion-note slot="end" color="medium" v-if="u.rfc">{{u.rfc}}</ion-note>
        </ion-item>
        <ion-item-options slot="end" v-if="yo.rol=='administrador'">
          <ion-item-option :color="colors('danger')" v-on:click="eliminar(u)" v-if="permiso('BorrarClientes')">
            <ion-icon name="trash"></ion-icon> Eliminar
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed" v-if="permiso('CrearClientes')">
      <ion-fab-button :color="colors('secondary')" v-on:click="ver(0)">
        <i class="icon icon-lg ion-ios-add"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
import Cliente from '@/models/Cliente'
export default {
  name: 'CatalogoClientes',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      clientes: [],
      clientesTodos: [],
      filtro: ''
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    pos() {
      this.$router.push('pos')
    },
    async obtener() {
      //let clientes = await Api.get('cliente')
      let clientes = await Cliente.lista()
      clientes = clientes
        .filter(c => {
          return c.nombre && c.nombre.length > 0
        })
      /*
        .map(c => {
          c.nombre = 'Cliente ' + c._id.substring(20, 24).toUpperCase()
          return c
        })
      if (true) {
        for (let cl of clientes)
          await Api.save('cliente', cl)
        alert("Clientes actualizados")
      }*/
      this.clientesTodos = clientes
      this.filtrar(this.filtro)
      console.log("Objetos", this.clientes.length)
    },
    filtrar(val) {
      val = (val || '').toLowerCase()
      this.filtro = val
      if (val == '')
        this.clientes = JSON.parse(JSON.stringify(this.clientesTodos))
      else {
        this.clientes = this.clientesTodos.filter(cl => {
          return (cl.nombre || '').toLowerCase().indexOf(val) >= 0 ||
            (cl.rfc || '').toLowerCase().indexOf(val) >= 0 ||
            (cl.email || '').toLowerCase().indexOf(val) >= 0
        })
      }
    },
    ver(id) {
      this.$router.push('/catalogos/cliente/' + id)
    },
    async eliminar(cliente) {
      let id = cliente._id
      let vm = this
      let asuntos = await Api.find('asunto', ['idCliente,eq,' + id])
      let actividades = await Api.find('actividad', ['idCliente,eq,' + id])
      console.log(actividades, asuntos)
      if (true == confirm('Desea eliminar este elemento? Esto no se puede deshacer. \n\nTambién se borrarán ' + actividades.length + ' actividades en ' + asuntos.length + ' asuntos.')) {
        //Api.delete('cliente', id).then(async _ => {
        Cliente.eliminar(id).then(async _ => {
          Api.borrarLocal('elite.cliente.' + id)
          Api.save('bitacora', {
            mensaje: 'Cliente eliminado, con asuntos y actividades: ' + id,
            objeto: cliente,
            fecha: new Date().getTime(),
            idAutor: vm.yo._id
          })
          // Borrar datos
          for (let a of asuntos) {
            await Api.delete('asunto', a._id)
          }
          for (let a of actividades)
            await Api.delete('actividad', a._id)
          alert("Cliente, asuntos y actividades eliminadas. Espero que sepa lo que acaba de hacer.")
          this.obtener()
          // Cerrar la lista
          try {
            let lista = document.querySelector('#listaClientes')
            if (lista)
              await lista.closeSlidingItems()
          } catch (e) {}
        })
      }
    },
    csv() {
      let txt = ''
      for (let u of this.clientes) {
        let datos = [u.nombre, u.email, u.telefono, u.rfc]
        //console.log("D?", datos)
        let renglon = datos.join(',') + '\n\r'
        txt += renglon
      }
      console.log("Txt?", txt.length)
      Api.downloadForce(txt, 'elite.clientes.csv')
    }
  },
  mounted() {
    this.obtener()
    let vm = this
    setTimeout(_ => {
      vm.obtener()
    }, 1000)
  }
}
</script>

<style>

</style>
