<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Mis actividades</ion-title>
      <!--ion-buttons slot="end">
        <ion-button @click="obtener">
          <ion-icon name="refresh" :color="colors('light')"></ion-icon>
        </ion-button>
      </ion-buttons-->
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" v-if="cargando"></ion-progress-bar>
  </ion-header>
  <ion-content class="content">
    <ion-row>
      <ion-col class="ion-no-padding" size-md="6">
        <ion-item>
          <ion-label position="">Desde fecha (actividad)</ion-label>
          <ion-input debounce="500" class="ion-text-right" :color="colors('primary')" type="date" :value="fechaInicioNatural" v-on:ionChange="cambiarFechaInicio($event.detail.value)" @keyup.enter="obtener" />
        </ion-item>
      </ion-col>
      <ion-col class="ion-no-padding" size-md="6">
        <ion-item>
          <ion-label position="">Hasta fecha</ion-label>
          <ion-input debounce="500" class="ion-text-right" :color="colors('primary')" type="date" :value="fechaFinNatural" v-on:ionChange="cambiarFechaFin($event.detail.value)" @keyup.enter="obtener" />
          <ion-button slot="end" :color="colors('primary')" @click="obtener" fill="outline">
            <i class="icon ion-ios-search"></i>
          </ion-button>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-searchbar debounce="500" placeholder="Filtrar por cliente, área o descripción" v-on:ionChange="filtrar($event.detail.value)" />
    <ion-list v-if="actividades.length>0 ">
      <ion-item-sliding v-for="a in actividades" :key="a._id">
        <ion-item v-on:click="ver(a)">
          <ion-label text-wrap>
            <h2>{{a.descripcion}}</h2>
            <p v-if="a.cliente && a.cliente._id">{{a.cliente.nombre}} <span v-if="a.minutos">({{a.minutos}}min)</span></p>
            <p v-if="a.asunto && a.asunto._id">Asunto: {{a.area}}, {{a.asunto.nombre}}</p>
          </ion-label>
          <ion-note side="end">{{a.fechaProgramada | moment('MMM DD')}} <span v-if="new Date(a.fechaProgramda).getYear()!=new Date().getYear()">{{a.fechaProgramda | moment('YYYY')}}</span></ion-note>
        </ion-item>
        <ion-item-options slot="end">
          <ion-item-option :color="colors('primary')" v-on:click="verAsunto(a.idAsunto)">Ir a Asunto</ion-item-option>
          <ion-item-option :color="colors('danger')" v-on:click="eliminar(a)" v-if="permiso('BorrarActividades') || a.idAbogado==yo._id">
            Eliminar
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
    <ion-item v-if="actividades.length==0 && !cargando" lines="none">
      <ion-label :color="colors('danger')" class="ion-text-center">No hay actividades registradas en este lapso de tiempo</ion-label>
    </ion-item>
    <ion-item v-if="cargando">
      <ion-label :color="colors('primary')" text-center>Cargando...</ion-label>
    </ion-item>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed" v-if="permiso('CrearActividades')">
      <ion-fab-button :color="colors('secondary')" href="#/actividad2">
        <i class="icon ion-ios-add icon-lg"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</div>
</template>
<script>
import Api from '@/components/Api'
import Cliente from '@/models/Cliente'
export default {
  name: 'MisActividades',
  data() {
    return {
      cargando: false,
      yo: Api.obtenerLocal('elite.yo') || {},
      actividades: [],
      actividadesTodas: [],
      clientes: [],
      fechaInicio: new Date().getTime() - (1000 * 60 * 60 * 24),
      fechaInicioNatural: null,
      fechaFin: new Date().getTime() + (1000 * 60 * 60 * 24),
      fechaFinNatural: null,
      obteniendo: false
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    ver(a) {
      this.$router.push('/actividad2/' + a._id)
    },
    async eliminar(a) {
      if (true == confirm('Desea eliminar esta actividad? Esto no se puede deshacer')) {
        await Api.delete('actividad', a._id)
        this.obtener()
        alert("Actividad eliminada")
      }
    },
    verAsunto(id) {
      this.$router.push('/asunto/' + id)
    },
    async obtener() {
      this.cargando = true
      this.actividades = []
      let filtros = [
        'idAbogado,eq,' + this.yo._id,
        'fechaProgramada,gte,' + this.fechaInicio,
        'fechaProgramada,lt,' + this.fechaFin
      ]
      let actividades = await Api.find('actividad', filtros)
      console.log("Obteniendo", filtros, new Date(this.fechaInicio), actividades)
      for (let i in actividades) try {
        if (actividades[i].idCliente)
          //actividades[i].cliente = await Api.get('cliente', actividades[i].idCliente)
          for (let cl of this.clientes)
            if (cl._id == actividades[i].idCliente)
              actividades[i].cliente = cl
        if (actividades[i].idAsunto)
          actividades[i].asunto = await Api.get('asunto', actividades[i].idAsunto)
        if (actividades[i].idArea)
          actividades[i].area = await Api.get('area', actividades[i].idArea)
      } catch (e) {
        console.log("No se pudieron obtener todos los datos")
      }
      this.actividades = actividades.sort((a, b) => {
        return a.fechaProgramada < b.fechaProgramada ? 1 : -1
      })
      this.actividadesTodas = JSON.parse(JSON.stringify(this.actividades))
      this.cargando = false
    },
    filtrar(val) {
      let mostrar = false
      val = (val || '').toLowerCase().trim()
      if (val == '')
        this.actividades = JSON.parse(JSON.stringify(this.actividadesTodas))
      else {
        this.actividades = this.actividadesTodas.filter(a => {
          let mostrar = false
          if (a.descripcion.toLowerCase().indexOf(val) >= 0)
            mostrar = true
          if (typeof a.area == 'string' && (a.area || '').toLowerCase().indexOf(val) >= 0)
            mostrar = true
          if (typeof a.area == 'object' && (a.area.nombre || '').toLowerCase().indexOf(val) >= 0)
            mostrar = true
          if (a.cliente && a.cliente.nombre && (a.cliente.nombre || '').toLowerCase().indexOf(val) >= 0)
            mostrar = true
          return mostrar
        })
      }
      console.log("Filtradas", this.actividades.length, this.actividadesTodas.length, val)
      this.$forceUpdate()
    },
    cambiarFechaInicio(val) {
      let offset = new Date().getTimezoneOffset() / 60
      let comprobable = new Date(val)
      console.log("Cambiando fecha de inicio", val)
      if (typeof comprobable == 'object' && typeof val == 'string' && val.length > 8) {
        this.fechaInicioNatural = val
        let fechaInicio = new Date(new Date(val).getTime() + (offset * 1000 * 60 * 60))
        fechaInicio.setMilliseconds(0)
        fechaInicio.setSeconds(0)
        fechaInicio.setMinutes(0)
        fechaInicio.setHours(0)
        fechaInicio = fechaInicio.getTime()
        if (fechaInicio < 0)
          throw new Error('Fecha anterior a 1970')
        this.fechaInicio = fechaInicio
        console.log("Cambiada fecha de inicio", this.fechaInicio)
        //    this.obtener()
      } else {
        console.error('Fecha invalida', val)
      }
    },
    cambiarFechaFin(val) {
      let offset = new Date().getTimezoneOffset() / 60
      let comprobable = new Date(val)
      console.log("Cambiando fecha de fin", val)
      if (typeof comprobable == 'object' && typeof val == 'string' && val.length > 8) {
        this.fechaFinNatural = val
        let fechaFin = new Date(new Date(val).getTime() + (offset * 1000 * 60 * 60))
        fechaFin.setMilliseconds(999)
        fechaFin.setSeconds(59)
        fechaFin.setMinutes(59)
        fechaFin.setHours(23)
        fechaFin = fechaFin.getTime()
        if (fechaFin < 0)
          throw new Error('Fecha anterior a 1970')
        this.fechaFin = fechaFin
        console.log("Cambiada fecha de fin", this.fechaFin)
        //    this.obtener()
      } else {
        console.error('Fecha invalida fin', val)
      }
    }
  },
  async mounted() {
    this.clientes = await Cliente.lista()
    this.cambiarFechaInicio(this.$options.filters.moment(new Date().getTime() - (1000 * 60 * 60 * 24), 'YYYY-MM-DD'))
    this.cambiarFechaFin(this.$options.filters.moment(new Date().getTime() + (1000 * 60 * 60 * 1), 'YYYY-MM-DD'))
    await Api.wait(100)
    this.obtener()
  }
}
</script>
