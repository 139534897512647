<script>
import Api from '@/components/Api'
import Usuario from '@/models/Usuario'
import Vue from 'vue'
let Tarificador = new Vue({
  name: 'Notificador',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      fechaInicio: new Date().getTime() - (1000 * 60 * 60 * 7)
    }
  },
  methods: {
    async encontrar() {
      let notificaciones = []
      if (this.yo && this.yo._id) {
        //console.log("Obteniendo notificaciones")
        let filtros = ['fecha,gte,' + this.fechaInicio]
        if (this.yo.rol != 'administrador')
          filtros.push('idDestino,eq,' + this.yo._id)
        let notificaciones = await Api.find('notificable', filtros)
        notificaciones = notificaciones.sort((a, b) => {
          return a.fecha < b.fecha ? 1 : -1
        })
        if ((notificaciones || []).length > 0)
          console.log("Encontradas notificaciones", filtros, notificaciones.length)
      }
      return Promise.resolve(notificaciones)
    },
    async avisar(texto, datosExtra, idDestino, omitirPush, omitirEmail) {
      texto = texto || ''
      datosExtra = datosExtra || {}
      idDestino = idDestino || this.yo._id
      omitirPush = omitirPush && true ? true : false
      omitirEmail = omitirEmail && true ? true : false
      let notificable = {
        mensaje: texto,
        datos: datosExtra,
        fecha: new Date().getTime(),
        idAutor: this.yo._id,
        idDestino: idDestino
      }
      console.log("Notificando en BD")
      await Api.save('notificable', notificable)
      try {
        // Obtener email y OneSignalUserIds del usuario
        let user = await Usuario.obtener(idDestino)
        console.log("Onesignal? Email?", !omitirPush, !omitirEmail, user.OneSignalUserIds || [], user.email)
        if (user.OneSignalUserIds && user.OneSignalUserIds.length > 0 && !omitirPush) {
          let k = await OneSignalNotification.enviar(destinos, texto)
          console.log("Enviada OneSignal?", k)
        }
        if (!omitirEmail) {
          let mailObj = {
            emailOrigen: 'sistema@elite.mx',
            nombreOrigen: 'Robot de elite.app',
            emailDestino: user.email,
            mensaje: texto
          }
          console.log("Enviando correo", mailObj)
          let m = await Api.email(mailObj)
          console.log("Enviado correo?", m)
        }
      } catch (e) {
        console.error(e)
        return Promise.reject(e)
      }
      return Promise.resolve(true)
    }
  }
})
export default Tarificador
</script>
