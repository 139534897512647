<template>
<div><video class="QrCamara"></video>
  <ion-item lines="none">
    <ion-label class="ion-text-center" text-wrap color="medium">
      <p>Escanee el QR con la cámara de su dispositivo</p>
    </ion-label>
  </ion-item>
</div>
</template>
<style scoped>
.QrCamara {
  background-color: #223;
  width: 90%;
  height: 50vh;
  margin: 5px;
  border: 1px solid #999999;
  border-radius: 16px;
}
</style>
<script type="module">
import Api from '@/components/Api'
import QrScanner from 'qr-scanner'
import QrScannerWorkerPath from '!!file-loader!../../node_modules/qr-scanner/qr-scanner-worker.min.js'
QrScanner.WORKER_PATH = QrScannerWorkerPath
export default {
  name: 'QrCamara',
  async mounted() {
    await Api.wait(500)
    console.log("QrCamara mounted")
    this.inicializarCamara()
  },
  methods: {
    inicializarCamara() {
      let videoElem = document.querySelector('video.QrCamara')
      console.log("videoElem", videoElem)
      const qrScanner = new QrScanner(videoElem,
        result => {
          console.log('decoded qr code:', result)
          Api.$emit('setQuery', result)
        }
      )
    }
  }
}
</script>
