<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Solicitudes de factura</ion-title>
      <ion-buttons slot="end">
        <ion-button onclick="window.history.go(-1)">
          <i class="icon ion-ios-arrow-back"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-row>
      <ion-col class="ion-no-padding" size-sm="6" size-xs="12">
        <ion-card>
          <ion-list>
            <ion-item-divider>General</ion-item-divider>
            <ion-item>
              <ion-label>Fecha de solicitud</ion-label>
              <ion-note slot="end">{{solicitud.fecha | moment('MMM DD HH:mm')}}</ion-note>
            </ion-item>
            <ion-item v-if="solicitud.autor">
              <ion-label text-wrap v-if="solicitud.autor">
                <h2>Por {{solicitud.autor.nombre}}</h2>
                <!--p>Solicitud {{solicitud.fecha | moment('MMMM DD HH:mm')}}</p-->
              </ion-label>
              <ion-note slot="end" :color="colors('primary')">
                <i class="icon ion-ios-person icon-lg"></i>
              </ion-note>
            </ion-item>
            <ion-item>
              <ion-label>Fecha para timbrar</ion-label>
              <ion-input class="ion-text-right" type="date" :value="solicitud.fechaParaTimbrar" v-on:ionChange="set('fechaParaTimbrar',$event.detail.value)"></ion-input>
            </ion-item>
            <!--ion-item>
              <ion-label>Cliente</ion-label>
              <ion-select :value="solicitud.idCliente" v-on:ionChange="set('idCliente',$event.detail.value)">
                <ion-select-option v-for="c in clientes" :value="c._id">{{c.nombre}}</ion-select-option>
              </ion-select>
            </ion-item-->
            <SelectorCliente :color="colors('primary')" :ocultarIcono="true" :idCliente="solicitud.idCliente" />
            <ion-item v-if="asuntos.length>0">
              <ion-label>Asociar a asunto</ion-label>
              <ion-select :value="solicitud.idAsunto" v-on:ionChange="set('idAsunto',$event.detail.value)">
                <ion-select-option :value="''">-- Sin asunto --</ion-select-option>
                <ion-select-option v-for="c in asuntos" :key="c._id" :value="c._id">{{c.nombre}}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>
        </ion-card>
        <ion-card>
          <ion-list>
            <ion-item-divider>Recurrencia</ion-item-divider>
            <ion-item>
              <ion-label>Esta solicitud es recurrente</ion-label>
              <ion-checkbox slot="end" :checked="solicitud.recurrente" v-on:ionChange="toggleSolicitudRecurrente($event.detail.checked==true)"></ion-checkbox>
            </ion-item>
            <ion-item v-if="solicitud.recurrente==true">
              <ion-label>Cada</ion-label>
              <ion-select :value="solicitud.recurrenteIntervalo" v-on:ionChange="solicitud.recurrenteIntervalo=$event.detail.value">
                <ion-select-option value="7">Semana</ion-select-option>
                <ion-select-option value="14">Quincena</ion-select-option>
                <ion-select-option value="30">Mes</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item v-if="solicitud.recurrente==true">
              <ion-label>Meses a configurar</ion-label>
              <ion-input class="ion-text-right" type="number" min="1" debounce="100" :value="solicitud.recurrenteMeses" v-on:ionChange="solicitud.recurrenteMeses=parseInt($event.detail.value);$forceUpdate()"></ion-input>
            </ion-item>
            <ion-item v-if="solicitud.recurrente==true" lines="none">
              <ion-label>
                <p><i class="icon ion-ios-information-circle-outline"></i>&nbsp;Hasta el {{(new Date().getTime()+(1000*60*60*24*30*solicitud.recurrenteMeses)) | moment('MMM YYYY')}}</p>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-card>
      </ion-col>
      <ion-col class="ion-no-padding" size-sm="6" size-xs="12">
        <ion-card>
          <ion-list>
            <ion-item-divider>Motivo de la factura</ion-item-divider>
            <ion-item>
              <ion-label text-wrap>Cantidad a facturar <span v-if="solicitud.cantidad && solicitud.cantidad!=0" class="watermark">{{solicitud.cantidad | currency}}</span></ion-label>
              <ion-input class="ion-text-right" type="number" step="any" :value="solicitud.cantidad" v-on:ionChange="set('cantidad',parseFloat($event.detail.value))"></ion-input>
            </ion-item>
            <ion-item v-if="solicitud.cantidad!=0">
              <ion-label>Incluye impuestos</ion-label>
              <ion-checkbox slot="end" :checked="solicitud.incluyeImpuestos" v-on:ionChange="set('incluyeImpuestos',$event.detail.checked==true)"></ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-label>Código SAT</ion-label>
              <!--ion-textarea debounce="250" :value="solicitud.concepto" v-on:ionChange="set('concepto',$event.detail.value)" class="ion-text-right"></ion-textarea-->
              <!--ion-select class="solicitudfactura" :value="solicitud.concepto" v-on:ionChange="set('concepto',$event.detail.value)">
                <ion-select-option v-for="concepto in conceptossat">
                  <span v-if="yo.rol=='administrador' && false">{{concepto.claveProductoSat}}&nbsp;</span>{{concepto.nombre}}
                </ion-select-option>
              </ion-select-->
              <select v-model="solicitud.concepto" class="selectConceptoSat">
                <option v-for="concepto in conceptossat" :key="concepto.nombre">{{concepto.claveProductoSat}} {{concepto.nombre}}</option>
              </select>
            </ion-item>
            <ion-item v-if="solicitud.concepto!=''">
              <ion-label text-wrap>
                <p>{{solicitud.concepto}}</p>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>Nota para factura</ion-label>
              <ion-textarea debounce="250" :value="solicitud.notaParaFactura" v-on:ionChange="set('notaParaFactura',$event.detail.value)" class="ion-text-right"></ion-textarea>
            </ion-item>
            <ion-item>
              <ion-label>Observaciones</ion-label>
              <ion-textarea debounce="250" :value="solicitud.observaciones" v-on:ionChange="set('observaciones',$event.detail.value)" class="ion-text-right"></ion-textarea>
            </ion-item>
            <ion-item lines="none">
              <ion-note slot="start" :color="solicitud.ordenCompraObligatoria?'primary':'medium'" v-if="!solicitud.adjunto">
                <i class="icon ion-ios-cloud-upload"></i>.
              </ion-note>
              <label for="adjunto">
                <ion-label text-wrap v-if="!(solicitud.adjunto && solicitud.adjunto!='')">
                  <h2>Clic para adjuntar un archivo</h2>
                  <p v-if="solicitud.ordenCompraObligatoria">Orden de compra obligatoria</p>
                </ion-label>
                <ion-label text-wrap v-if="solicitud.adjunto && solicitud.adjunto!=''">
                  <h2>{{solicitud.adjunto}}</h2>
                </ion-label>
                <input class="hidden" type="file" name="adjunto" id="adjunto" v-on:change="adjuntar()" />
              </label>
              <ion-button slot="end" :href="cdn+'/'+solicitud.adjunto" v-if="solicitud.adjunto" target="_blank">
                <i class="icon ion-ios-cloud-download"></i>
              </ion-button>
            </ion-item>
            <embed type="application/pdf" :src="cdn+'/'+solicitud.adjunto" style="width:100%;height:500px;" v-if="solicitud.adjunto" />
          </ion-list>
        </ion-card>
      </ion-col>
      <ion-col class="ion-no-padding" size-sm="6" v-if="solicitud.ordenCompraObligatoria">
        <ion-card>
          <ion-item lines="none" :color="solicitud.adjuntoOrdenCompra?'light':''">
            <ion-note slot="start" color="medium" v-if="!solicitud.adjuntoOrdenCompra">
              <i class="icon ion-ios-cloud-upload"></i>
            </ion-note>
            <label for="adjuntoOrdenCompra">
              <ion-label text-wrap v-if="!solicitud.adjuntoOrdenCompra">
                <h2>Clic para adjuntar la Orden de Compra</h2>
                <p>Adjunte la OC para poder marcar como completada</p>
              </ion-label>
              <ion-label v-else>
                <h2>{{solicitud.adjuntoOrdenCompra}}</h2>
                <p>Orden de compra</p>
              </ion-label>
              <input class="hidden" type="file" name="adjuntoOrdenCompra" id="adjuntoOrdenCompra" v-on:change="adjuntarOrdenCompra()" />
            </label>
            <ion-button slot="end" :href="cdn+'/'+solicitud.adjuntoOrdenCompra" v-if="solicitud.adjuntoOrdenCompra">
              <i class="icon ion-ios-cloud-download"></i>
            </ion-button>
          </ion-item>
        </ion-card>
      </ion-col>
      <ion-col v-if="areasRepartibles.length>0 && solicitud.areasRepartibles" size="12">
        <ion-card>
          <ion-item-divider>Repartición de áreas</ion-item-divider>
          <ion-row>
            <ion-col v-for="a in areasRepartibles" :key="a._id" class="ion-no-padding" size-xs="4">
              <ion-item>
                <ion-label text-wrap>{{a.nombre}}</ion-label>
                <ion-input class="ion-text-right" type="number" step="1" min="0" max="100" debounce="100" :value="solicitud.areasRepartibles[a.nombre] || 0" v-on:ionChange="setAreaRepartible(a.nombre,parseInt($event.detail.value))"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>
      <ion-col class="ion-no-padding" v-if="permiso('EmitirFacturas')" :size="esPDF(solicitud.adjuntoFactura)?6:12">
        <ion-card>
          <ion-list>
            <ion-item-divider>Finalizar</ion-item-divider>
            <ion-row>
              <!--ion-col class="ion-no-padding" :size-sm="solicitud.ordenCompraObligatoria?4:6"-->
              <ion-col class="ion-no-padding" size-sm="6">
                <ion-card>
                  <ion-item lines="none" :color="solicitud.adjuntoFacturaXML?'light':''">
                    <ion-note slot="start" color="medium" v-if="!solicitud.adjuntoFacturaXML">
                      <i class="icon ion-ios-cloud-upload"></i>
                    </ion-note>
                    <label for="adjuntoFacturaXML">
                      <ion-label text-wrap v-if="!solicitud.adjuntoFacturaXML">
                        <h2>Clic para adjuntar el XML realizado</h2>
                        <p>Adjunte XML para poder marcar como completada</p>
                      </ion-label>
                      <ion-label v-else>
                        <h2>{{solicitud.adjuntoFacturaXML}}</h2>
                        <p>XML</p>
                      </ion-label>
                      <input class="hidden" type="file" name="adjuntoFacturaXML" id="adjuntoFacturaXML" v-on:change="adjuntarFacturaXml()" />
                    </label>
                    <ion-button slot="end" :href="cdn+'/'+solicitud.adjuntoFacturaXML" v-if="solicitud.adjuntoFacturaXML">
                      <i class="icon ion-ios-cloud-download"></i>
                    </ion-button>
                  </ion-item>
                </ion-card>
              </ion-col>
              <!--ion-col class="ion-no-padding" :size-sm="solicitud.ordenCompraObligatoria?4:6"-->
              <ion-col class="ion-no-padding" size-sm="6">
                <ion-card>
                  <ion-item lines="none" :color="solicitud.adjuntoFactura?'light':''">
                    <ion-note slot="start" color="medium" v-if="!solicitud.adjuntoFactura">
                      <i class="icon ion-ios-cloud-upload"></i>
                    </ion-note>
                    <label for="adjuntoFactura">
                      <ion-label text-wrap v-if="!solicitud.adjuntoFactura">
                        <h2>Clic para adjuntar la factura realizada</h2>
                        <p>Adjunte factura para poder marcar como completada</p>
                      </ion-label>
                      <ion-label v-else>
                        <h2>{{solicitud.adjuntoFactura}}</h2>
                        <p>Factura/PDF</p>
                      </ion-label>
                      <input class="hidden" type="file" name="adjuntoFactura" id="adjuntoFactura" v-on:change="adjuntarFactura()" />
                    </label>
                    <ion-button slot="end" :href="cdn+'/'+solicitud.adjuntoFactura" v-if="solicitud.adjuntoFactura">
                      <i class="icon ion-ios-cloud-download"></i>
                    </ion-button>
                  </ion-item>
                </ion-card>
              </ion-col>
            </ion-row>
            <ion-item>
              <ion-label>Completada {{solicitud.fechaCompletada | moment('MMM DD HH:mm')}}</ion-label>
              <ion-checkbox :disabled="deshabilitarSolicitudFactura()" slot="end" :checked="solicitud.completada" v-on:ionChange="set('completada',$event.detail.checked==true)">
              </ion-checkbox>
            </ion-item>
          </ion-list>
        </ion-card>
      </ion-col>
      <ion-col v-if="solicitud.adjuntoFactura && esPDF(solicitud.adjuntoFactura)" size-sm="6">
        <ion-card>
          <ion-item-divider>Vista previa</ion-item-divider>
          <embed class="solicitudFacturaPdf" :src="cdn+'/'+solicitud.adjuntoFactura" type="application/pdf" />
        </ion-card>
      </ion-col>
    </ion-row>
    <pre v-if="!modoProduccion">{{solicitud}}</pre>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed" v-if="!solicitud._id && (permiso('SolicitarFacturas') || solicitud.idAutor==yo._id)">
      <ion-fab-button @click="guardar">
        <i class="icon icon-lg ion-ios-save"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</ion-page>
</template>
<style>
.solicitudFacturaPdf {
  width: 100%;
  min-height: 400px;
  height: 80vh;
}

.watermark {
  color: #cccccc;
}
</style>
<script>
import Api from '@/components/Api'
import Cliente from '@/models/Cliente'
import Usuario from '@/models/Usuario'
import Emailer from '@/models/Emailer'
import SelectorCliente from '@/components/SelectorCliente'
export default {
  name: 'SolicitudFactura',
  props: {
    id: {
      type: String,
      default: '0'
    }
  },
  components: {
    SelectorCliente
  },
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      modoProduccion: Api.modoProduccion,
      solicitud: {
        idAutor: (Api.obtenerLocal('elite.yo') || {})._id,
        idCliente: '',
        idAsunto: '',
        fecha: new Date().getTime(),
        fechaParaTimbrar: new Date().toISOString().substring(0, 10),
        cantidad: 0,
        concepto: '',
        incluyeImpuestos: true,
        aprobada: false,
        completada: false,
        areasRepartibles: {}
      },
      clientes: [],
      cliente: null,
      asuntos: [],
      conceptossat: [],
      cdn: Api.cdn,
      permitirCambiarEstado: false,
      //
      intervaloDefaultEnDias: 30,
      //
      areasRepartibles: []
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async obtener() {
      let solicitud = await Api.get('solicitudfactura', this.id)
      if (solicitud.idCliente) // Recuperar sus asuntos
        this.set('idCliente', this.solicitud.idCliente)
      if (!solicitud.areasRepartibles)
        solicitud.areasRepartibles = {}
      if (this.yo.areaDefault && this.yo.areaDefault != '' && !solicitud.areasRepartibles[this.yo.areaDefault])
        solicitud.areasRepartibles[this.yo.areaDefault] = 100
      this.solicitud = solicitud
      try {
        let autor = await Usuario.obtener(this.solicitud.idAutor)
        console.log("Encontrado autor", autor)
        this.solicitud.autor = autor
      } catch (e) {
        console.error(e)
      }
      this.$forceUpdate()
      console.log("Solicitud recuperada", this.solicitud)
    },
    esPDF(str) {
      return (str || '').toLowerCase().indexOf('pdf') >= 0
    },
    async set(campo, valor) {
      this.solicitud[campo] = valor
      console.log("set", campo, valor)
      if (campo == 'idCliente' && valor != '') {
        this.cliente = this.clientes
          .filter(c => valor == c._id)[0]
        this.solicitud.ordenCompraObligatoria = this.cliente.ordenCompraObligatoria
        console.log("recuperando asuntos", campo, valor, this.cliente.nombre)
        let asuntos = (await Api.find('asunto', ['idCliente,eq,' + valor]))
          .sort((a, b) => a.nombre > b.nombre ? 1 : -1)
        //.filter(a => a.status == 1)
        this.asuntos = JSON.parse(JSON.stringify(asuntos))
        console.log("recuperados asuntos", this.asuntos.length, this.asuntos.map(a => a.nombre))
        // Encontrar si el cliente solicita OC
        let cliente
        for (let c of this.clientes)
          if (c._id == valor)
            cliente = c
        console.log("Cliente Requiere OC?", cliente.nombre, cliente.ordenCompraObligatoria)
        this.solicitud.ordenCompraObligatoria = (cliente.ordenCompraObligatoria == true)
        if (!this.solicitud.adjunto)
          this.solicitud.adjunto = ''
      }
      if (campo == 'completada' && valor == true && this.solicitud._id && this.permitirCambiarEstado) {
        if (this.permiso('EmitirFacturas') && true == confirm('¿Desea marcar esta factura como completada?')) {
          this.solicitud.fechaCompletada = new Date().getTime()
          this.guardar()
        } else {
          alert("No tiene permisos para marcar como completada (EmitirFacturas)")
          await Api.wait(500)
          this.set('completada', false)
        }
      }
      this.$forceUpdate()
    },
    async adjuntar() {
      this.solicitud.adjunto = await Api.upload('adjunto')
      alert("Archivo adjunto")
      this.$forceUpdate()
    },
    async adjuntarFactura() {
      this.solicitud.adjuntoFactura = await Api.upload('adjuntoFactura')
      alert("Factura adjunta")
      this.$forceUpdate()
    },
    async adjuntarOrdenCompra() {
      this.solicitud.adjuntoOrdenCompra = await Api.upload('adjuntoOrdenCompra')
      alert("OC adjunta")
      this.$forceUpdate()
    },
    async adjuntarFacturaXml() {
      this.solicitud.adjuntoFacturaXML = await Api.upload('adjuntoFacturaXML')
      alert("XML adjunto")
      this.$forceUpdate()
    },
    completablePorOrdenDeCompra() {
      if (this.solicitud.ordenCompraObligatoria)
        return (this.solicitud.adjuntoOrdenCompra && this.solicitud.adjuntoOrdenCompra != '')
      else // No es requerida
        return true
    },
    toggleSolicitudRecurrente(val) {
      this.solicitud.recurrente = val
      if (this.solicitud.recurrente == true) {
        this.solicitud.recurrenteIntervalo = this.intervaloDefaultEnDias
        this.solicitud.recurrenteMeses = 12
      }
      this.$forceUpdate()
    },
    setAreaRepartible(campo, valor) {
      this.solicitud.areasRepartibles[campo] = valor
      console.log("setAreaRepartible", campo, valor)
      let suma = 0
      for (let area in this.solicitud.areasRepartibles) {
        suma += this.solicitud.areasRepartibles[area]
      }
      if (suma > 100)
        alert("La suma de áreas pasa de 100%")
    },
    async guardar() {
      let elementosFaltantes = []
      let elementosRequeridos = ['concepto', 'idCliente', 'cantidad', 'fechaParaTimbrar', 'idAsunto']
      if (true == this.solicitud.ordenCompraObligatoria) {
        elementosRequeridos = elementosRequeridos.concat(['adjuntoOrdenCompra'])
      }
      if (this.areasRepartibles.length > 0) {
        console.log("Añadiendo adjunto a los elementos requeridos?", this.solicitud.ordenCompraObligatoria, elementosRequeridos)
        let sumaPorcentajes = 0
        for (let area in this.solicitud.areasRepartibles)
          sumaPorcentajes += parseInt(this.solicitud.areasRepartibles[area] || 0)
        if (sumaPorcentajes > 0 && sumaPorcentajes < 100)
          alertLegacy("La repartición de áreas no suma 100% (" + sumaPorcentajes + "%)")
      }
      for (let elem of elementosRequeridos) {
        console.log("elem", elem, typeof this.solicitud[elem], this.solicitud[elem])
        if (['', 0].indexOf(this.solicitud[elem]) >= 0)
          elementosFaltantes.push(elem)
      }
      if (elementosFaltantes.length == 0) {
        let solicitudGuardada = await Api.save('solicitudfactura', this.solicitud)
        alert("Guardada")
        // Enviar por correo la factura
        let vm = this
        let cliente = await Api.get('cliente', this.solicitud.idCliente)
        let autor = await Usuario.obtener(this.solicitud.idAutor)
        let email
        try {
          let cuerpo1, titulo, attachments = []
          let destinatarios = (await Api.find('usuario', ['esquemaPermisos,eq,Contabilidad']))
            .concat(await Api.find('usuario', ['esquemaPermisos,eq,contabilidad']))
            //.concat(await Api.find('usuario', ['tipo,eq,administrativo']))
            .concat([this.yo])
          if (this.yo._id != (autor || {})._id)
            destinatarios = destinatarios
            .concat(autor)
          destinatarios = [...new Set(destinatarios)]
          if (this.solicitud.adjuntoFactura)
            attachments.push({
              filename: solicitudGuardada._id + '.pdf',
              path: Api.cdn + '/' + this.solicitud.adjuntoFactura
            })
          if (this.solicitud.adjuntoFacturaXML)
            attachments.push({
              filename: solicitudGuardada._id + '.xml',
              path: Api.cdn + '/' + this.solicitud.adjuntoFacturaXML
            })
          if (!this.solicitud.completada) {
            cuerpo1 = 'Una solicitud de factura ha sido creada el ' + vm.$options.filters.moment(vm.solicitud.fecha, 'YYYY-MM-DD HH:mm') + '. Estado '
            titulo = 'Solicitud de factura para ' + cliente.nombre
            //attachments = null
          } else {
            cuerpo1 = 'Su solicitud de factura ha sido completada el ' + vm.$options.filters.moment(vm.solicitud.fechaParaTimbrar, 'YYYY-MM-DD HH:mm') + '. '
            titulo = 'Solicitud de factura para ' + cliente.nombre + ' completada'
          }
          let cuerpo2 = 'Por ' + autor.nombre + ', para cliente ' + cliente.nombre + '.<br />Concepto: ' + vm.solicitud.concepto + ', por ' + vm.$options.filters.currency(vm.solicitud.cantidad) +
            (vm.solicitud.incluyeImpuestos == true ?
              ', incluye impuestos.' :
              ', más impuestos.')
          email = {
            destino: destinatarios.map(d => d.email),
            cuerpo1,
            cuerpo2,
            titulo,
            attachments
          }
          let u = await Emailer.enviar(email)
          console.log("Correo enviado?", u)
          alert("Correo enviado a administrativos")
        } catch (e) {
          alert("No se pudo enviar el correo electrónico")
          console.error(e)
        }
        try {
          await Api.save('bitacora', {
            fecha: new Date().getTime(),
            mensaje: 'Solicitud de factura creada, para cliente ' + cliente.nombre + ', por ' + vm.$options.filters.currency(vm.solicitud.cantidad),
            idAutor: vm.yo._id
          })
        } catch (e) {
          alert("No se pudo guardar en bitácora el movimiento")
          console.error(e)
          console.error("Email", email)
        }
        // Recurrencia
        if (this.solicitud.recurrente && this.solicitud.recurrenteIntervalo) try {
          let fechaRecurrencia = new Date().getTime()
          let fechaUnAnno = fechaRecurrencia + (1000 * 60 * 60 * 24 * 365)
          let contador = 1
          //while (fechaRecurrencia < fechaUnAnno) {
          for (let i = 0; i < this.solicitud.recurrenteMeses; i++) {
            let solicitudRecurrente = JSON.parse(JSON.stringify(this.solicitud))
            solicitudRecurrente.fecha = fechaRecurrencia
            solicitudRecurrente.idSolicitudOrigen = solicitudGuardada._id
            solicitudRecurrente.fechaParaTimbrar = this.$options.filters.moment(fechaRecurrencia, 'YYYY-MM-DD')
            solicitudRecurrente.notaParaFactura = (solicitudRecurrente.notaParaFactura || '') + '(R' + contador + ')'
            delete solicitudRecurrente._id
            await Api.save('solicitudfactura', solicitudRecurrente)
            fechaRecurrencia += (1000 * 60 * 60 * 24 * parseInt(solicitudRecurrente.recurrenteIntervalo) * 1.05) // 1.1 para siempre recorrer el mes siguiente
            //
            fechaRecurrencia = new Date(fechaRecurrencia)
            fechaRecurrencia.setDate(new Date().getDay())
            fechaRecurrencia = fechaRecurrencia.getTime()
            //
            console.log("Recurrencia instalada:", contador, new Date().getDay(), solicitudRecurrente.recurrenteIntervalo, new Date(fechaRecurrencia))
            contador++
          }
        } catch (e) {
          console.error("ERECURRENCIA", e)
          alert("No se pudieron guardar las recurrencias")
        }
        //
        this.$router.push('/solicitudfactura')
      } else {
        console.error("Faltan datos", elementosFaltantes)
        alert("Faltan datos: " + elementosFaltantes.join(', '))
      }
    },
    deshabilitarSolicitudFactura() {
      let hayDocumentosAdjuntos = false
      if ((this.solicitud.adjuntoFacturaXML || '').length > 0)
        hayDocumentosAdjuntos = true
      if ((this.solicitud.adjuntoFactura || '').length > 0)
        hayDocumentosAdjuntos = true
      return !hayDocumentosAdjuntos && !this.completablePorOrdenDeCompra()
    }
  },
  async mounted() {
    console.log("SolicitudFactura mounted", this.id, this.yo)
    if (this.yo.areaDefault && this.yo.areaDefault != '')
      this.solicitud.areasRepartibles[this.yo.areaDefault] = 100
    this.clientes = await Cliente.lista()
    this.areasRepartibles = (await Api.get('area'))
      .filter(a => a.participaEnGastos)
    this.conceptossat = (await Api.get('conceptosat'))
      .sort((a, b) => a.claveProductoSat > b.claveProductoSat ? 1 : -1)
      .map(u => {
        u.nombre = u.nombre
          .replace(/%20/g, ' ')
          .replace(/Ã¡/g, 'á')
          .replace(/É/g, "é")
          .replace(/Ã©/g, "é")
          .replace(/Ã­/g, 'í')
          .replace(/Ã³/g, 'ó')
          .replace(/%2C/g, ',')
          .replace(/%24/g, "\$")
          .replace(/%28/g, "(")
          .replace(/%29/g, ")")
          .replace(/Ã±/g, 'ñ')
        return u
      })
    console.log("conceptossat", this.conceptossat.filter(u => u.claveProductoSat.indexOf('1702') >= 0))
    this.obtener()
    await Api.wait(1000)
    this.permitirCambiarEstado = true
    let vm = this
    Api.$on('clienteSeleccionado', id => {
      vm.set('idCliente', id)
      console.log("Solicitud cambiada", vm.solicitud)
    })
  },
  beforeDestroy() {
    Api.$off('clienteSeleccionado')
  },
  watch: {
    'solicitud': function(a) {
      console.log("watch solicitud", a)
    }
  }
}
</script>
<style>
.selectConceptoSat {
  display: block;
  float: right;
  max-width: 25%;
  background-color: #ffffff;
  border: 0px;
}
</style>
