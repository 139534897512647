<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Justificación de gastos ({{facturassingasto.length}})</ion-title>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" v-if="cargando"></ion-progress-bar>
  </ion-header>
  <ion-content>
    <!--ion-row>
      <ion-col class="ion-no-padding">
        <ion-item>
          <ion-checkbox slot="start" :checked="mostrarPendientes" v-on:click="toggle('mostrarPendientes')"></ion-checkbox>
          <ion-label>Pendientes</ion-label>
        </ion-item>
      </ion-col>
      <ion-col class="ion-no-padding">
        <ion-item>
          <ion-checkbox slot="start" :checked="mostrarAprobadas" v-on:click="toggle('mostrarAprobadas')"></ion-checkbox>
          <ion-label>Aprobadas</ion-label>
        </ion-item>
      </ion-col>
      <ion-col class="ion-no-padding">
        <ion-item>
          <ion-checkbox slot="start" :checked="mostrarCanceladas" v-on:click="toggle('mostrarCanceladas')"></ion-checkbox>
          <ion-label>Canceladas</ion-label>
        </ion-item>
      </ion-col>
      <ion-col class="ion-no-padding" v-if="!modoProduccion">
        <ion-item>
          <ion-checkbox slot="start" :checked="mostrarTodas" v-on:click="toggle('mostrarTodas')"></ion-checkbox>
          <ion-label>TODAS</ion-label>
        </ion-item>
      </ion-col>
    </ion-row -->
    <table class="tablaFacturasSinGasto" :class="(esMovil)?'tablaMovil':''">
      <thead>
        <tr>
          <th class="ion-text-right">CR</th>
          <th>Folio fact.</th>
          <th>Proveedor</th>
          <th>Concepto</th>
          <th>Solicitante</th>
          <th class="ion-text-right">Cantidad</th>
          <th>Programada para</th>
          <th>Estado</th>
          <th>Documentos</th>
          <th>Justificación (clic para editar)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="f in facturassingasto" @click="clog(f)" :key="f._id">
          <td class="ion-text-right">{{f.folio}} <span v-if="f.cancelada&&f.cancelada>0"> - </span></td>
          <td>{{f.metadatos.fecha}} {{f.metadatos.folio}} {{modoProduccion?'':f.idPresupuesto}}</td>
          <td><span v-if="f.proveedor">{{f.proveedor.nombre}}</span></td>
          <td>{{f.metadatos.concepto}}</td>
          <td>{{f.metadatos.solicitante}}</td>
          <td class="ion-text-right">{{f.metadatos.cantidad | currency}}</td>
          <td>
            <span v-if="f.fU">{{(f.fU) | moment('MMM DD')}}</span>
            <span v-else>-</span>
          </td>
          <td>{{estado(f)}} <span v-if="f.cancelada">{{f.cancelada | moment('MMM DD')}}</span><span v-if="f.canceladaPorUsuario">&nbsp;por proveedor</span></td>
          <td>
            <ion-button size="small" fill="outline" :href="cdn+f.archivoAdjuntoJustificacion" v-if="f.archivoAdjuntoJustificacion" target="_blank">Justificación</ion-button>
            <ion-button size="small" fill="outline" :href="cdn+f.archivoAdjunto" v-if="f.archivoAdjunto" target="_blank">Factura</ion-button>
            <!--ion-button size="small" fill="outline" :href="cdn+f.archivoAdjuntoXml" v-if="f.archivoAdjuntoXml" target="_blank">XML</ion-button-->
          </td>
          <td>
            <ion-button size="small" fill="clear" color="primary" @click="preguntarJustificacion(f)" v-if="!(f.justificacion && f.justificacion!='')">
              <i class="icon ion-ios-create icon-md"></i>
            </ion-button>
            <p v-else @click="preguntarJustificacion(f)">{{f.justificacion}} <span v-if="f.esUrgente">(Urgente)</span></p>
          </td>
        </tr>
      </tbody>
    </table>
  </ion-content>
</ion-page>
</template>
<style>
.tablaFacturasSinGasto {
  width: 100%;
  text-align: left;
  padding: 8px;
}

.tablaFacturasSinGasto td,
.tablaFacturasSinGasto th {
  padding: 12px;
  font-size: 0.8em;
}

.tablaFacturasSinGasto td {
  border-top: 1px solid #cccccc;
}

.tablaMovil {
  overflow-x: auto;
  display: block;
  border: 2px solid #333333;
}
</style>
<script>
import Api from '@/components/Api'
import FechasPagoModel from '@/models/FechasPago'
import Emailer from '@/models/Emailer'
import ModalJustificacion from '@/components/Presupuestos/ModalJustificacion'
export default {
  name: 'FacturasDeProveedoresSinGasto',
  data() {
    return {
      cargando: false,
      modoProduccion: Api.modoProduccion,
      //esMovil: Api.esMovil(),
      esMovil: window.innerWidth < window.innerHeight,
      cdn: Api.cdn + '/',
      yo: Api.obtenerLocal('elite.yo') || {},
      facturassingasto: [],
      fechaInicial: new Date().getTime() - (1000 * 60 * 60 * 24 * 365),
      mostrarPendientes: true,
      mostrarAprobadas: false,
      mostrarCanceladas: false,
      mostrarTodas: false,
      modal: null
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    nombre(str) {
      str = str || ''
      return str.substring(0, str.length - 41)
    },
    clog(obj) {
      console.log("log", obj)
    },
    async obtener() {
      this.cargando = true
      const mostrarAprobadas = this.mostrarAprobadas,
        mostrarPendientes = this.mostrarPendientes,
        mostrarCanceladas = this.mostrarCanceladas,
        mostrarTodas = this.mostrarTodas
      let facturassingasto = (await Api.find('facturasingasto', [
        'fecha,gte,' + this.fechaInicial
      ], {
        subset: [{
          coleccion: 'usuario',
          alias: 'proveedor',
          query: '_id,eq,',
          sustituir: 'idProveedor',
          unico: true
        }]
      }))
      console.log("facturassingasto sin filtrar?", facturassingasto[0], facturassingasto.length)
      this.facturassingasto = facturassingasto
        .map(f => {
          console.log("F?", f.nombre, f.fechaProgramado, f.fechaProgramadoTentativo, f)
          f.fU = (f.fechaProgramado && f.fechaProgramado.fechaProgramado) ?
            f.fechaProgramado.fechaProgramado : f.fechaProgramado
          if ((f.fU || '').toString().length == 0)
            f.fU = f.fechaProgramadoTentativo
          return f
        })
        .sort((a, b) => a.fecha < b.fecha ? 1 : -1)
        .sort((a, b) => a.fU > b.fU ? 1 : -1)
        //*
        .filter(f =>
          mostrarTodas ||
          this.estado(f) == 'Pendiente' && mostrarPendientes ||
          this.estado(f) == 'Aprobada' && mostrarAprobadas || ['Cancelada', 'Rechazada'].indexOf(this.estado(f)) >= 0 && mostrarCanceladas
        ) //*/
        .map(f => {
          f.accion = ''
          if (f.metadatos && f.metadatos.folio)
            f.metadatos.folio = f.metadatos.folio.replace('undefined', '').trim()
          return f
        })
      for (let i in this.facturassingasto) {
        if (!this.facturassingasto[i].fechaProgramadoTentativo && !this.facturassingasto[i].fechaProgramado) try {
          const fechaDePago = await FechasPagoModel.encontrarFechaDePagoMasCercana(this.facturassingasto[i].fecha || (new Date().getTime()))
          this.facturassingasto[i].fechaProgramadoTentativo = fechaDePago
        } catch (e) {
          console.error("No se pudo asignar cierta fecha de pago", e, this.facturassingasto[i])
          alert("No se pudo encontrar una fecha de pago ")
        }
      }
      console.log("facturassingasto?", this.facturassingasto)
      this.cargando = false
    },
    estado(f) {
      let edo = 'Pendiente'
      if (f.canceladaPorUsuario)
        edo = 'Cancelada'
      else if (f.cancelada && f.cancelada > 0)
        edo = 'Rechazada'
      else if (f.aprobada && f.aprobada == true)
        edo = 'Aprobada'
      return edo
    },
    descargar(ruta) {
      console.log("Descargando", ruta)
      //alert("Descargando " + ruta)
      //window.open(ruta, '_blank')
      let a = document.createElement('a')
      a.href = ruta
      a.target = '_blank'
      a.className = "hidden"
      a.class = 'hidden'
      document.querySelector('body').appendChild(a)
      a.click()
    },
    async accion(obj, accion) {
      this.preguntarJustificacion(obj)
    },
    async preguntarJustificacion(obj) {
      let vm = this
      return new Promise((resolve, reject) => {
        this.$ionic.modalController
          .create({
            component: ModalJustificacion,
            swipeToClose: true,
            componentProps: {
              parent: vm,
              propsData: {
                esModal: true,
                facturasingasto: obj
              }
            }
          })
          .then(m => {
            vm.modal = m
            vm.modal.present()
            Api.$on('cerrarJustificacion', _ => {
              vm.modal.dismiss()
              vm.obtener()
              Api.$off('cerrarJustificacion')
              //resolve(p)
              resolve(true)
            })
          })
      })
    },
    async eliminar(obj) {
      if (true == confirm('¿Está seguro?')) {
        await Api.delete('facturasingasto', obj._id)
        alert("Eliminada")
        await Api.wait(1000)
        this.obtener()
      }
    },
    async toggle(u, v) {
      for (let prop in this)
        if (prop.startsWith('mostrar') && prop != u)
          this[prop] = false
      this[u] = true
      console.log("toggle", u, this[u])
      this.$forceUpdate()
      await Api.wait(200)
      this.obtener()
    }
  },
  async mounted() {
    console.log("FacturasDeProveedoresSinGasto")
    console.log("Etapas?", await Api.find('gastoetapa', [], {
      limit: 5
    }))
    if (!this.permiso('JustificarGastos'))
      this.$router.push('/')
    this.obtener()
    if (!Api.modoProduccion)
      alert("esMovil??? " + this.esMovil)
  }
}
</script>
