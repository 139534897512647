<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-buttons slot="end">
        <ion-button @click="obtener">
          <i class="icon ion-ios-refresh"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Reportes {{tipoReporte}}</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-row>
      <ion-col :size="mostrarResultados&&!esMovil?4:12" :no-padding="esMovil">
        <ion-list>
          <ion-item>
            <!--ion-label :position="esMovil?'stacked':''">Tipo de reporte</ion-label-->
            <ion-label position="stacked">Tipo de reporte</ion-label>
            <ion-note :color="colors('primary')" slot="start">
              <i class="icon ion-ios-eye icon-lg"></i>
            </ion-note>
            <ion-select :value="tipoReporte" v-on:ionChange="seleccionarTipoReporte($event.detail.value)">
              <ion-select-option v-for="t in tiposReportes" :key="t.label" v-if="permiso(t.nombrePermiso)&&!ocultarTipoReporte(t.nombrePermiso)" :value="t.name">{{t.label}}</ion-select-option>
            </ion-select>
          </ion-item>
          <FormFiltros :filtros="filtros" :filtrosMostrables="filtrosMostrables||[]" v-on:filtrosCambiados="configurarFiltros(filtros)" :stackLabels="mostrarResultados" />
          <ion-row>
            <ion-col>
              <ion-button expand="block" :color="colors('secondary')" @click="obtener">Generar</ion-button>
            </ion-col>
          </ion-row>
        </ion-list>
      </ion-col>
      <ion-col :size="esMovil?12:8" :no-padding="esMovil" v-if="mostrarResultados">
        <ion-list no-padding>
          <ReporteDashboard v-if="tipoReporte=='dashboard'" :actividades="actividades" :asuntos="asuntos" :yo="yo" :totalMonto="totalMonto" :totalMinutos="totalMinutos" />
          <MisActividades v-if="tipoReporte=='misactividades'" :filtros="filtros" :clientes="clientes" :abogados="abogados" :areas="areas" :unSoloAbogado="true" />
          <ActividadesPorCliente v-if="tipoReporte=='cliente' || tipoReporte=='corporativo'" :filtros="filtros" :clientes="clientes" :abogados="abogados" :areas="areas" :corporativos="corporativos" :unSoloCliente="tipoReporte=='cliente'" />
          <MisActividades v-if="tipoReporte=='usuario'" :filtros="filtros" :clientes="clientes" :abogados="abogados" :areas="areas" :unSoloAbogado="false" />
          <HorasPorUsuario v-if="tipoReporte=='usuariohoras'" :filtros="filtros" :clientes="clientes" :abogados="abogados" :areas="areas" />
          <HorasPorCliente v-if="tipoReporte=='clientehoras'" :filtros="filtros" :clientes="clientes" :abogados="abogados" :areas="areas" :modoGrafico="true" />
          <HorasEfectivas v-if="tipoReporte=='usuariohorasefectivas'" :filtros="filtros" :clientes="clientes" :abogados="abogados" :areas="areas" />
          <ReporteAsuntos v-if="tipoReporte=='asuntos'" :filtros="filtros" :areas="areas" />
          <!-- Graficos -->
          <IndiceReporteo v-if="tipoReporte=='reporteo'" :filtros="filtros" :clientes="clientes" :abogados="abogados" :areas="areas" />
          <ChartActividadesPorArea v-if="tipoReporte=='actividadesporarea'" :filtros="filtros" :clientes="clientes" :abogados="abogados" :areas="areas" />
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-content>
</div>
</template>
<style>
table {
  width: 100%
}

td,
th {
  text-align: left;
  padding: 5px;
}
</style>
<style>
.pdf {
  width: 100%;
  height: 90vh;
}

#tabla {
  width: 100%;
}

#tabla th,
#tabla td {
  padding: 0px;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border: 0px;
  border-bottom: 1px solid #eeeeee;
}
</style>
<script>
import Api from '@/components/Api'
import Usuario from '@/models/Usuario'
import Cliente from '@/models/Cliente'
//import ChartLinea from '@/components/ChartLinea'
import FormFiltros from '@/components/Reportes/FormFiltros'
import ReporteAsuntos from '@/components/Reportes/Asuntos'
import ReporteDashboard from '@/components/Reportes/Dashboard'
import MisActividades from '@/components/Reportes/MisActividades'
import ActividadesPorCliente from '@/components/Reportes/ActividadesPorCliente'
import HorasPorUsuario from '@/components/Reportes/HorasPorUsuario'
import HorasPorCliente from '@/components/Reportes/HorasPorCliente'
import HorasEfectivas from '@/components/Reportes/HorasEfectivas'
import IndiceReporteo from '@/components/Reportes/IndiceReporteo'
import ChartActividadesPorArea from '@/components/Reportes/ChartActividadesPorArea'
export default {
  name: 'Reportes',
  components: {
    //ChartLinea,
    FormFiltros,
    ReporteDashboard,
    ReporteAsuntos,
    MisActividades,
    ActividadesPorCliente,
    HorasPorUsuario,
    HorasPorCliente,
    HorasEfectivas,
    IndiceReporteo,
    ChartActividadesPorArea
  },
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo'),
      abogados: [],
      clientes: [],
      corporativos: [],
      areas: [],
      filtros: {
        abogados: 'Todos',
        clientes: 'Todos',
        area: '',
        graficarPor: 'cliente',
        porRango: 'mes',
        asuntos: [],
        //fechaInicio: this.$options.filters.moment(new Date().getFullYear() + '-01-01', 'YYYY-MM-DD'),
        fechaInicio: Api.obtenerLocal('elite.reportesFechaInicio') ||
          this.$options.filters.moment(new Date().getTime() - (1000 * 60 * 60 * 24 * 7), 'YYYY-MM'),
        fechaFin: this.$options.filters.moment(new Date().getTime(), 'YYYY-MM-DD'),
        verMontos: false
      },
      filtrosMostrables: [],
      asuntos: [],
      actividades: [],
      actividadesTodas: [],
      totalMinutos: 0,
      totalMonto: 0,
      actividadPromedio: 0,
      asuntoPromedio: 0,
      tipoReporte: Api.obtenerLocal('elite.reportesTipoReporte') || 'misactividades',
      tiposReportes: [{
          name: 'misactividades',
          label: 'Mis actividades',
          filtrosMostrables: ['fechaInicio', 'fechaFin'],
          nombrePermiso: 'ReporteMisActividades'
        }, {
          name: 'asuntos',
          label: 'Asuntos',
          filtrosMostrables: ['fechaInicio', 'fechaFin'],
          nombrePermiso: 'ReporteAsuntos'
        }, {
          name: 'cliente',
          label: 'Actividades por cliente',
          filtrosMostrables: ['fechaInicio', 'fechaFin', 'idCliente'],
          nombrePermiso: 'ReporteActividadesPorCliente'
        }, {
          name: 'corporativo',
          label: 'Actividades por corporativo',
          filtrosMostrables: ['fechaInicio', 'fechaFin', 'idCorporativo'],
          nombrePermiso: 'ReporteActividadesPorCorporativo'
        }, {
          name: 'usuario',
          label: 'Actividades por usuario',
          filtrosMostrables: ['fechaInicio', 'fechaFin', 'idAbogado'],
          nombrePermiso: 'ReporteActividadesPorUsuario'
        }, {
          name: 'clientehoras',
          label: 'Horas por cliente',
          filtrosMostrables: ['fechaInicio', 'fechaFin', 'idAbogado'],
          nombrePermiso: 'ReporteHorasPorCliente'
        }, {
          name: 'usuariohoras',
          label: 'Horas por usuario',
          filtrosMostrables: ['fechaInicio', 'fechaFin'],
          nombrePermiso: 'ReporteHorasPorUsuario'
        }, {
          name: 'usuariohorasefectivas',
          label: 'Horas efectivas por usuario',
          filtrosMostrables: ['fechaInicio', 'fechaFin'],
          nombrePermiso: 'ReporteHorasEfectivasPorUsuario'
        },
        /*{
               name: 'reporteo',
               label: 'Reporteo',
               filtrosMostrables: []
             }, {
               name: 'actividadesporarea',
               label: 'Actividades por área',
               filtrosMostrables: ['fechaInicio', 'fechaFin']
             }*/
      ],
      chart: {
        data: {},
        options: {}
      },
      chart2: {
        data: {
          labels: [this.getRandomInt(), this.getRandomInt()],
          datasets: [{
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }, {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }]
        },
        options: {
          showLines: false
        }
      },
      mostrarResultados: false,
      esMovil: Api.esMovil()
    }
  },
  watch: {
    'tipoReporte': function(a) {
      console.log("tipoReporte", a)
      Api.guardarLocal('elite.reportesTipoReporte', a)
    },
    'filtros.fechaInicio': function(a) {
      console.log("fechaInicio", a)
      Api.guardarLocal('elite.reportesFechaInicio', a)
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    configurarFiltros(f) {
      this.filtros = f
      console.log("$on configurarFiltros", this.tipoReporte, f)
    },
    seleccionarTipoReporte(tipo) {
      for (let t of this.tiposReportes) {
        if (t.name == tipo) {
          this.tipoReporte = t.name
          this.filtros.clientes = ['Todos']
          this.filtrosMostrables = t.filtrosMostrables
          console.log("Seleccionado", t.name, t)
        }
      }
    },
    async obtener() {
      console.log("Obteniendo reporte de tipo", this.tipoReporte)
      await Api.save('bitacora', {
        idAutor: this.yo._id,
        fecha: new Date().getTime(),
        mensaje: 'REPORTE generado: ' + JSON.stringify(this.filtros)
      })
      this.mostrarResultados = false
      // Catalogos
      if (this.abogados.length == 0)
        //this.abogados = await Api.get('abogado')
        this.abogados = await Usuario.lista()
      if (this.clientes.length == 0)
        //this.clientes = await Api.get('cliente')
        this.clientes = await Cliente.lista()
      if (this.areas.length == 0) this.areas = await Api.get('area')
      if (this.corporativos.length == 0) this.corporativos = await Api.get('corporativo')
      //
      if (this.tipo == 'dashboard') {
        let filtros = [
          'fechaProgramada,gte,' + (new Date(this.filtros.fechaInicio).getTime()),
          'fechaProgramada,lte,' + (new Date(this.filtros.fechaFin).getTime() + (1000 * 60 * 60 * 23))
        ]
        console.log("Buscando", filtros, this.filtros)
        let actividades = await Api.find('actividad', filtros)
        //let actividades = await Api.get('actividad')
        console.log("Actividades filtrables", actividades)
        actividades = actividades
          .sort((a, b) => {
            return a.fechaProgramada > b.fechaProgramada ? 1 : -1
          })
          //*
          .filter(a => {
            return this.filtros.abogados.indexOf(a.idAbogado) >= 0 ||
              this.filtros.abogados.indexOf('Todos') >= 0
          }) //*/
          //*
          .filter(a => {
            return this.filtros.clientes.indexOf(a.idCliente) >= 0 ||
              this.filtros.clientes.indexOf('Todos') >= 0
          }) //*/
          .map(act => {
            act.monto = 0
            act.tarifa = 0
            for (let ab of this.abogados)
              if (act.idAbogado == ab._id)
                act.abogado = ab
            if (act.abogado && act.abogado._id)
              for (let c of this.clientes) {
                if (act.idCliente == c._id && c['tarifa_' + act.abogado.tipo])
                  act.tarifa = c['tarifa_' + act.abogado.tipo]
                if (act.idCliente == c._id)
                  act.cliente = c
              }
            act.monto = parseFloat(act.minutos) / 60 * act.tarifa
            return act
          })
        this.actividades = actividades
        console.log("Actividades encontrados", this.actividades)
        // Asuntos
        let asuntosIndices = []
        for (let act of this.actividades)
          if (asuntosIndices.indexOf(act.idAsunto) == -1)
            asuntosIndices.push(act.idAsunto)
        this.asuntos = []
        asuntosIndices = [...new Set(asuntosIndices)]
        console.log("Buscando asuntos", asuntosIndices)
        let asuntosTodos = await Api.get('asunto')
        let asuntosTodos2 = {}
        for (let a of asuntosTodos)
          asuntosTodos2[a._id] = a
        for (let id of asuntosIndices)
          if (asuntosTodos2[id])
            this.asuntos.push(asuntosTodos2[id])
        this.asuntos = this.asuntos
          .sort((a, b) => {
            return a.descripcion > b.descripcion ? 1 : -1
          })
          .map(a => {
            a.incluido = true
            return a
          })
        console.log("Asuntos encontrados", this.asuntos)
        //
        this.actividades = this.actividades.map(act => {
          act.asunto = asuntosTodos2[act.idAsunto] || {}
          return act
        })
        //
        this.calcularTotales()
        this.$forceUpdate()
      }
      //
      let vm = this
      setTimeout(_ => {
        vm.mostrarResultados = true
      }, 1000)
    },
    incluirAsunto(id, incluir) {
      for (let i in this.asuntos)
        if (this.asuntos[i]._id == id) {
          this.asuntos[i].incluido = incluir
          console.log("Incluyendo asunto?", this.asuntos[i]._id, incluir)
        }
      this.generarGrafico()
    },
    calcularTotales() {
      this.totalMonto = 0
      this.totalMinutos = 0
      for (let act of this.actividades) {
        //console.log("Sumando", act.monto, act.minutos)
        this.totalMonto += parseFloat(act.monto || 0)
        this.totalMinutos += parseFloat(act.minutos || 0)
      }
      this.actividadPromedio = this.totalMinutos / this.actividades.length
      this.asuntoPromedio = this.totalMinutos / this.asuntos.length
      this.$forceUpdate()
      this.generarGrafico()
    },
    randomColor() {
      var color = "#" + Math.floor(Math.random() * 16777216).toString(16)
      console.log("Unacceptaboooool!", color)
      if (color.length == 7)
        return color
      else return this.randomColor()
    },
    generarGrafico() {
      this.chart.data.labels = []
      console.log("Generando grafico por", this.filtros.graficarPor)
      this.mostrarResultados = false
      if (this.filtros.graficarPor == 'abogado') {
        this.chart.data.datasets = this.abogados.map(a => {
          let b = {
            label: a.nombre,
            backgroundColor: this.randomColor(),
            data: [],
            id: a._id
          }
          return b
        })
        for (let act of this.actividades) {
          let f = act.fechaProgramada
          f = this.$options.filters.moment(f, 'MMM-DD')
          for (let ab in this.chart.data.datasets) {
            if (this.chart.data.datasets[ab].id == act.idAbogado) {
              this.chart.data.datasets[ab].data.push(act.minutos || 0)
              this.chart.data.labels.push(f)
            } else {
              this.chart.data.datasets[ab].data.push(0)
              this.chart.data.labels.push(f)
            }
          }
        }
        console.log("Chart!", this.chart.data.datasets[3], this.chart.data)
      } else {
        this.chart.data.datasets = this.clientes.map(c => {
          let b = {
            label: c.nombre,
            backgroundColor: this.randomColor(),
            data: [],
            id: c._id
          }
          return b
        })
        for (let act of this.actividades) {
          let f = act.fechaProgramada
          f = this.$options.filters.moment(f, 'MMM-DD')
          for (let cl in this.chart.data.datasets) {
            if (this.chart.data.datasets[cl].id == act.idCliente) {
              this.chart.data.datasets[cl].data.push(act.minutos || 0)
              this.chart.data.labels.push(f)
            } else {
              this.chart.data.datasets[cl].data.push(0)
              this.chart.data.labels.push(f)
            }
          }
        }
      }
      let vm = this
      setTimeout(_ => {
        vm.mostrarResultados = true
        vm.$forceUpdate()
        console.log("Mostrando")
      }, 200)
    },
    ocultarTipoReporte(str) {
      console.log("ocultarTipoReporte?", str)
      return this.yo.rol == 'administrador' && ['Mis actividades'].indexOf(str) >= 0
    }
  },
  async mounted() {
    if (this.yo.reporta && this.yo.rol == 'administrador')
      //this.abogados = await Api.get('usuario')
      this.abogados = await Usuario.lista()
    else {
      this.abogados = [this.yo]
      this.filtros.abogados = [this.yo._id]
    }
    //this.clientes = await Api.get('cliente')
    this.clientes = await Cliente.lista()
    await Api.wait(500)
    this.seleccionarTipoReporte(this.tipoReporte)
    console.log("Reportes montado", this.tipoReporte)
    Api.$on('filtrosCambiados', this.configurarFiltros)
  },
  beforeDestroy() {
    Api.$off('filtrosCambiados')
    /*
    alertLegacy("Borrando caché. La aplicación se recargará.")
    setTimeout(_ => {
      window.location.reload()
    }, 2000)
    */
  }
}
</script>

<style>

</style>
