<template>
<ion-app main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-buttons slot="end">
        <ion-button>
          <ion-icon name="more" :color="colors('secondary')"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Centro de notificaciones</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button @click="obtener">
          <i class="icon ion-ios-refresh"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-list v-if="notificaciones.length>0">
      <ion-item-sliding v-for="n in notificaciones" :key="n._id">
        <ion-item>
          <ion-label text-wrap>
            <h2>{{n.mensaje}}</h2>
            <p>{{n.fecha | moment('MMM DD HH:mm')}}</p>
            <p v-if="n.asunto && n.asunto._id">{{n.asunto.nombre}}<span v-if="n.acuerdo && n.acuerdo._id">, cobrado por {{n.acuerdo.tipo}}</span></p>
          </ion-label>
          <ion-button slot="end" :color="colors('danger')" fill="clear" @click="eliminar(n)">
            <i class="icon ion-ios-checkmark-circle-outline icon-lg"></i>
          </ion-button>
        </ion-item>
        <ion-item-options slot="end">
          <ion-item-option v-on:click="verAsunto(n.datos.idAsunto)" v-if="n.datos && n.datos.idAsunto" :color="colors('primary')">
            <i class="icon ion-ios-arrow-forward"></i> Asunto
          </ion-item-option>
          <ion-item-option v-on:click="eliminar(n)" :color="colors('danger')">
            <i class="icon ion-ios-trash"></i>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
    <ion-item v-else>
      <ion-label text-center text-wrap :color="colors('success')">
        <h1>¡Hurra!</h1>
        <p>No hay pendientes aquí</p>
      </ion-label>
    </ion-item>
  </ion-content>
</ion-app>
</template>
<script>
import Api from './Api'
import Notificador from '@/models/Notificador'
export default {
  name: 'Notificaciones',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo'),
      fechaInicio: new Date().getTime() - (1000 * 60 * 60 * 7),
      notificaciones: [],
      modoProduccion: Api.modoProduccion
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async obtener() {
      this.notificaciones = await Notificador.encontrar()
      for (let i in this.notificaciones) {
        if (this.notificaciones[i].datos && this.notificaciones[i].datos.idAsunto) try {
          this.notificaciones[i].asunto = await Api.get('asunto', this.notificaciones[i].datos.idAsunto)
          let acuerdo = (await Api.find('acuerdo', ['idAsunto,eq,' + this.notificaciones[i].asunto._id]))
          if (acuerdo && acuerdo[0]) {
            console.log("Acuerdo?", acuerdo)
            this.notificaciones[i].acuerdo = acuerdo[0]
          }
        } catch (e) {}
      }
      this.$forceUpdate()
    },
    verAsunto(id) {
      this.$router.push('/asunto/' + id)
    },
    async eliminar(n) {
      await Api.delete('notificable', n._id)
      this.obtener()
    }
  },
  async mounted() {
    this.obtener()
  }
}
</script>
