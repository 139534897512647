<template>
  <div class="ion-page" main>
    <ion-header>
      <ion-toolbar :color="colors('secondary')">
        <ion-title>Catalogos - Empresa</ion-title>
        <ion-buttons slot="start">
          <ion-button @click="openEnd">
            <ion-icon name="menu" :color="colors('light')"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-item>
        <ion-segment :value="modo" @ionChange="modo = $event.detail.value">
          <ion-segment-button :value="'general'">General</ion-segment-button>
          <ion-segment-button :value="'web'">Web</ion-segment-button>
        </ion-segment>
      </ion-item>
      <ion-list v-if="modo == 'general'">
        <ion-item lines="none">
          <ion-label color="medium">
            <h1>General</h1>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>Nombre de la empresa</ion-label>
          <ion-input
            class="ion-text-right"
            :value="empresa.nombre"
            v-on:ionChange="empresa.nombre = $event.detail.value"
          />
        </ion-item>
        <ion-item>
          <ion-label>RFC</ion-label>
          <ion-input
            class="ion-text-right"
            :value="empresa.rfc"
            v-on:ionChange="empresa.rfc = $event.detail.value"
          />
        </ion-item>
        <ion-item>
          <ion-label>Email</ion-label>
          <ion-input
            class="ion-text-right"
            :value="empresa.email"
            type="email"
            v-on:ionChange="empresa.email = $event.detail.value"
          />
        </ion-item>
        <ion-item>
          <ion-label>Codigo postal</ion-label>
          <ion-input
            class="ion-text-right"
            :value="empresa.codigoPostal"
            type="number"
            v-on:ionChange="empresa.codigoPostal = $event.detail.value"
          />
        </ion-item>
        <label for="logo">
          <input
            class="hidden"
            type="file"
            name="logo"
            id="logo"
            v-on:change="subirLogo()"
          />
          <ion-item>
            <ion-label>
              <h2>Logo</h2>
              <p>Aparece en PDFs. Clic para cambiar.</p>
            </ion-label>
            <ion-thumbnail slot="end" v-if="empresa.logo">
              <ion-img :src="empresa.logo" />
            </ion-thumbnail>
          </ion-item>
        </label>
        <div>
          <ion-item lines="none">
            <ion-label color="medium">
              <h1>Tarifas por rol</h1>
            </ion-label>
          </ion-item>
          <ion-item
            v-for="k in [
              'global',
              'socio administrador',
              'socio',
              'abogado jr',
              'pasante',
            ]"
            :key="k"
          >
            <ion-label class="ion-text-capitalize">{{ k }}</ion-label>
            <ion-input
              class="ion-text-right"
              color="primary"
              :value="empresa.tarifas[k]"
              v-on:ionChange="
                empresa.tarifas[k] = parseFloat($event.detail.value)
              "
              type="number"
            ></ion-input>
          </ion-item>
        </div>
        <ion-item lines="none">
          <ion-label color="medium">
            <h1>Módulos activados</h1>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>Módulo de tarifas </ion-label>
          <ion-toggle
            slot="end"
            :checked="empresa.moduloTarifas == true"
            v-on:ionChange="
              empresa.moduloTarifas = $event.detail.checked == true
            "
          />
        </ion-item>
        <ion-item>
          <ion-label>Módulo de pagos a proveedores </ion-label>
          <ion-toggle
            slot="end"
            :checked="empresa.moduloPagosProveedores == true"
            v-on:ionChange="
              empresa.moduloPagosProveedores = $event.detail.checked == true
            "
          />
        </ion-item>
        <ion-item>
          <ion-label>Módulo de solicitud de facturas </ion-label>
          <ion-toggle
            slot="end"
            :checked="empresa.moduloSolicitudFacturas == true"
            v-on:ionChange="
              empresa.moduloSolicitudFacturas = $event.detail.checked == true
            "
          />
        </ion-item>
        <ion-item>
          <ion-label>Agrupar clientes por 'corporativos'</ion-label>
          <ion-toggle
            slot="end"
            :checked="empresa.agruparClientesPorCorporativos == true"
            v-on:ionChange="
              empresa.agruparClientesPorCorporativos =
                $event.detail.checked == true
            "
          />
        </ion-item>
        <ion-item>
          <ion-label
            >Usar 'tiempo repercutido al cliente' adicional al tiempo
            efectivo</ion-label
          >
          <ion-toggle
            slot="end"
            :checked="empresa.usarTiempoRepercutidoAlCliente == true"
            v-on:ionChange="
              empresa.usarTiempoRepercutidoAlCliente =
                $event.detail.checked == true
            "
          />
        </ion-item>
        <ion-item v-if="empresa.moduloPresupuestos">
          <ion-label>Usuario de presupuestos predeterminado</ion-label>
          <ion-select
            :value="empresa.idUsuarioPresupuestoDefault"
            v-on:ionChange="
              set('idUsuarioPresupuestoDefault', $event.detail.value)
            "
          >
            <ion-select-option
              v-for="u in usuarios"
              :key="u._id"
              :value="u._id"
              >{{ u.nombre }}</ion-select-option
            >
          </ion-select>
        </ion-item>
        <ion-item lines="none">
          <ion-label color="medium">
            <h1>Configuración de QR</h1>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>Dirección IP de impresora</ion-label>
          <ion-input
            class="ion-text-right"
            :value="empresa.ipImpresora"
            v-on:ionChange="empresa.ipImpresora = $event.detail.value"
          />
        </ion-item>
        <ion-item>
          <ion-label position="floating"
            >Plantilla de etiqueta QR (en ZPL)</ion-label
          >
          <ion-textarea
            rows="10"
            :value="empresa.plantillaQR"
            v-on:ionChange="empresa.plantillaQR = $event.detail.value"
          ></ion-textarea>
        </ion-item>
        <ion-row v-if="empresa.moduloPresupuestos">
          <ion-col class="ion-no-padding" size="10">
            <ion-item>
              <ion-label>Presupuesto predeterminado</ion-label>
              <ion-select
                :value="empresa.idPresupuestoDefault"
                v-on:ionChange="
                  set('idPresupuestoDefault', $event.detail.value)
                "
              >
                <ion-select-option
                  v-for="u in presupuestos"
                  :key="u._id"
                  :value="u._id"
                  >{{ u.nombre }}</ion-select-option
                >
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col class="ion-no-padding" size="2">
            <ion-button
              fill="clear"
              expand="block"
              @click="crearPresupuestoPredeterminado"
            >
              <i class="icon ion-ios-add-circle-outline"></i>&nbsp;Crear
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-item lines="none" v-if="empresa.moduloTarifas">
          <ion-label color="medium">
            <h1>Tarifas</h1>
          </ion-label>
        </ion-item>
        <ion-item v-for="(t, i) in empresa.tarifasPorRol" :key="i">
          <ion-label text-capitalize>{{ i }}</ion-label>
          <ion-input
            class="ion-text-right"
            type="number"
            :value="empresa.tarifasPorRol[i]"
            v-on:ionChange="
              empresa.tarifasPorRol[i] = parseFloat($event.detail.value)
            "
          />
        </ion-item>
        <div v-if="empresa.moduloPagosProveedores">
          <ion-item lines="none">
            <ion-label color="medium">
              <h1>Presupuestos y pagos</h1>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label text-wrap>Días de programación para pagos</ion-label>
            <ion-input
              class="ion-text-right"
              type="number"
              step="1"
              min="1"
              :value="empresa.diasPostergarPago"
              v-on:ionChange="
                empresa.diasPostergarPago = parseFloat($event.detail.value)
              "
            />
          </ion-item>
          <ion-item>
            <ion-label text-wrap>Dia máximo de recepción de gastos</ion-label>
            <ion-input
              class="ion-text-right"
              type="date"
              :value="empresa.diaMaximoRecepcionPagos"
              v-on:ionChange="
                empresa.diaMaximoRecepcionPagos = $event.detail.value
              "
            />
          </ion-item>
          <ion-item>
            <ion-label text-wrap>Dia máximo de aprobación de pagos</ion-label>
            <ion-input
              class="ion-text-right"
              type="date"
              :value="empresa.diaMaximoAprobacionPagos"
              v-on:ionChange="
                empresa.diaMaximoAprobacionPagos = $event.detail.value
              "
            />
          </ion-item>
        </div>
        <div>
          <ion-item lines="none">
            <ion-label color="medium">
              <h1>Configuración de Nodemailer</h1>
            </ion-label>
          </ion-item>
          <ion-item v-for="prop in mailconfigparams" :key="prop.nombre">
            <ion-label>{{ prop.nombre }}</ion-label>
            <ion-input
              v-if="prop.tipo != 'boolean'"
              class="ion-text-right"
              :color="colors('primary')"
              type="prop.tipo||'text'"
              debounce="500"
              :value="(empresa.mailconfig || {})[prop.nombre]"
              v-on:ionChange="setEmailProp(prop.nombre, $event.detail.value)"
            >
            </ion-input>
            <ion-toggle
              v-else
              :checked="(empresa.config || {}).secure == true ? true : false"
              v-on:ionChange="
                setEmailProp('secure', $event.detail.checked == true)
              "
            ></ion-toggle>
          </ion-item>
        </div>
      </ion-list>
      <ion-list v-if="modo == 'web'">
        <ion-item>
          <ion-label>Nombre</ion-label>
          <ion-input
            class="ion-text-right"
            :value="empresapublic.nombre"
            v-on:ionChange="empresapublic.nombre = $event.detail.value"
          />
        </ion-item>
      </ion-list>
      <pre class="ion-text-left" v-if="!modoProduccion">{{ empresa }}</pre>
      <ion-fab
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        v-if="permiso('EditarEmpresa')"
      >
        <ion-fab-button :color="colors('secondary')" @click="guardar">
          <i class="icon icon-lg ion-ios-save"></i>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </div>
</template>

<script>
import Api from "@/components/Api";
import Empresa from "@/models/Empresa";
import sha1 from "sha1";
export default {
  name: "CatalogoEmpresa",
  data() {
    return {
      yo: Api.obtenerLocal("elite.yo") || {},
      empresa: Api.obtenerLocal("elite.empresa") || {},
      empresapublic: { nombre: "" },
      modoProduccion: Api.modoProduccion,
      modo: "general",
      mailconfigparams: [
        {
          nombre: "host",
          tipo: "text",
        },
        {
          nombre: "port",
          tipo: "number",
        },
        {
          nombre: "secure",
          tipo: "boolean",
        },
        {
          nombre: "auth.user",
          tipo: "email",
        },
        {
          nombre: "auth.pass",
          tipo: "password",
        },
        {
          nombre: "tls.ciphers",
          tipo: "text",
        },
      ],
      usuarios: [],
      presupuestos: [],
    };
  },
  methods: {
    openEnd() {
      Api.$emit("open-menu");
    },
    async obtener() {
      this.empresa = await Empresa.obtenerDatos();
      if (!this.empresa.tarifas) this.empresa.tarifas = {};
      console.log("Empresa", this.empresa);
      try {
        const empresapublic = (await Api.get("empresapublic"))[0];
        if (!(empresapublic && empresapublic._id))
          throw new Error("NOENCONTRADO");
        else this.empresapublic = empresapublic;
        console.log("empresapublic?", this.empresapublic);
      } catch (e) {
        console.error("ENOEMPRESAWEB", e);
      }
    },
    async subirLogo() {
      let logo = await Api.upload("logo", "base64");
      console.log("Logo?", logo);
      this.empresa.logo = logo;
      alert("Logo subido");
      this.$forceUpdate();
    },
    setEmailProp(campo, valor) {
      if (valor == "" || valor == "null") valor = null;
      if (campo == "secure" && !valor) valor = false;
      if (!this.empresa.mailconfig) this.empresa.mailconfig = {};
      if (campo.split(".").length == 1) this.empresa.mailconfig[campo] = valor;
      else {
        let head = campo.split(".")[0];
        campo = campo.split(".")[1];
        if (!this.empresa.mailconfig[head]) this.empresa.mailconfig[head] = {};
        this.empresa.mailconfig[head][campo] = valor;
      }
      console.log(
        "setEmailProp",
        campo,
        valor,
        JSON.stringify(this.empresa.mailconfig)
      );
      this.$forceUpdate();
    },
    set(campo, valor) {
      console.log("set", campo, valor);
      this.empresa[campo] = valor;
    },
    async guardar() {
      let e = await Api.save("empresa", this.empresa);
      Api.guardarLocal("elite.empresa", this.empresa);
      if (e._id || this.empresa._id) this.empresa._id = e._id;
      alert("Todos los usuarios administrativos deben reiniciar sesión");
      if (this.empresapublic.nombre != "") {
        Api.save("empresapublic", this.empresapublic);
      } else {
        Api.delete("empresapublic", this.empresapublic._id);
      }
    },
    //
    async crearPresupuestoPredeterminado() {
      let nombre = "Facturas sin gasto";
      let cantidadAsignada = 99999999999;
      let presupuesto = {
        _id: sha1(nombre).substring(0, 24),
        nombre,
        cantidadAsignada,
        fechaCaduca: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 10
        )
          .toISOString()
          .substring(0, 10),
        fechaCreado: new Date().getTime(),
        idAutor: this.yo._id,
        idUsuarioAsignado: this.empresa.idUsuarioPresupuestoDefault || "",
        recibeFacturasSingasto: true,
        recibeFacturasSinGasto: true,
        retenerGastos: cantidadAsignada,
        rubros: [
          {
            nombre: "General",
            cantidad: cantidadAsignada,
          },
        ],
        tolerancia: 100,
      };
      let u = await Api.save("presupuesto", presupuesto);
      alert("Creado");
      await Api.wait(1000);
      this.presupuestos = await Api.get("presupuesto");
      this.empresa.idPresupuestoDefault = sha1(nombre).substring(0, 24);
      this.$forceUpdate();
    },
  },
  async mounted() {
    this.usuarios = (await Api.get("usuario"))
      .filter((u) => ["cliente", "proveedor"].indexOf(u.rol) == -1)
      //.filter(u => (u.permisos && u.permisos.AprobarGastos) || u.rol == 'administrador' || u.rol == 'director')
      .filter(
        (u) =>
          (u.permisos && u.permisos.AdministrarFacturasSinGasto) ||
          u.rol == "administrador" ||
          u.rol == "director"
      )
      .filter((u) => !(u.fechaBaja && u.fechaBaja > 0))
      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
    this.presupuestos = (await Api.get("presupuesto")).sort((a, b) =>
      a.nombre > b.nombre ? 1 : -1
    );
    console.log("P?", this.usuarios, this.presupuestos);
    await Api.wait(500);
    this.obtener();
  },
};
</script>
