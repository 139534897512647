<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Partida/Presupuesto</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-row>
      <ion-col size-md="8">
        <ion-list>
          <ion-item color="medium" lines="none">Configuración general</ion-item>
          <ion-item>
            <ion-label position="floating">Nombre</ion-label>
            <ion-input :value="presupuesto.nombre" v-on:ionChange="presupuesto.nombre=$event.detail.value" debounce="100"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Usuario asignado</ion-label>
            <ion-select :value="presupuesto.idUsuarioAsignado" v-on:ionChange="presupuesto.idUsuarioAsignado=$event.detail.value" debounce="100">
              <ion-select-option v-for="u in usuarios.filter(u=>['socio','director'].indexOf(u.tipo||u.rol)>=0)" :value="u._id" :key="u._id">{{u.nombre}}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Cantidad total asignada</ion-label>
            <ion-input type="number" step="any" :value="presupuesto.cantidadAsignada" v-on:ionChange="presupuesto.cantidadAsignada=$event.detail.value" debounce="100"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Tolerancia de sobregiro (%)</ion-label>
            <ion-input type="number" step="any" :value="presupuesto.tolerancia" v-on:ionChange="presupuesto.tolerancia=$event.detail.value" debounce="100"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Retener gastos mayores a:</ion-label>
            <ion-input type="number" step="any" :value="presupuesto.retenerGastos" v-on:ionChange="presupuesto.retenerGastos=$event.detail.value" debounce="100"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Presupuesto caduca el:</ion-label>
            <ion-input class="ion-text-right" type="date" :value="presupuesto.fechaCaduca" v-on:ionChange="presupuesto.fechaCaduca=$event.detail.value" debounce="100"></ion-input>
          </ion-item>
          <!--ion-item>
            <ion-label>Recibe facturas sin gasto</ion-label>
            <ion-toggle slot="end" :checked="presupuesto.recibeFacturasSingasto" v-on:ionChange="presupuesto.recibeFacturasSingasto=($event.detail.checked==true)" />
          </ion-item-->
        </ion-list>
      </ion-col>
      <ion-col>
        <ion-list>
          <ion-item color="medium" lines="none">Rubros
            <ion-button slot="end" :color="colors('dark')" fill="outline" @click="agregarRubro">
              <i class="icon ion-ios-add-circle"></i>
            </ion-button>
          </ion-item>
          <ion-row v-for="(r,i) in presupuesto.rubros" :key="i">
            <ion-col class="ion-no-padding">
              <ion-item>
                <ion-label position="floating">Rubro {{i+1}}</ion-label>
                <ion-input :value="r.nombre" v-on:ionChange="r.nombre=$event.detail.value" v-on:keyup.enter="agregarRubro()"></ion-input>
              </ion-item>
            </ion-col>
            <ion-col class="ion-no-padding">
              <ion-item>
                <ion-label position="floating">Monto</ion-label>
                <ion-input debounce="250" :value="r.cantidad" type="number" min="0" :mmax="limiteRubro(i)" v-on:ionChange="r.cantidad=parseFloat($event.detail.value);recalcular()" v-on:keyup.enter="agregarRubro()"></ion-input>
                <ion-button slot="end" fill="clear" :color="colors('primary')" v-on:click="eliminarRubro(i)">
                  <i class="icon ion-ios-close-circle"></i>
                </ion-button>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed" v-if="permiso('EditarPresupuestos')">
      <ion-fab-button :color="colors('danger')" @click="guardar">
        <i class="icon icon-lg ion-ios-save"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
export default {
  name: 'PresupuestosPartida',
  props: ['id'],
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      presupuesto: {
        etapas: [],
        nombre: '',
        idUsuarioAsignado: '',
        idAutor: (Api.obtenerLocal('elite.yo') || {})._id,
        cantidadAsignada: 0,
        tolerancia: 10,
        retenerGastos: 0,
        fechaCreado: new Date().getTime(),
        fechaCerrado: 0
      },
      /*
      propiedades: [{
        name: 'Nombre',
        value: 'Espadas y escudos'
      }, {
        name: 'Asignado a',
        value: 'Maximus Decimus Meridius'
      }, {
        name: 'Rubro',
        value: 'Defensa'
      }, {
        name: 'Cantidad asignada',
        type: 'number',
        value: 1500000
      }, {
        name: 'Tolerancia %',
        type: 'number',
        value: 10
      }, {
        name: 'Retener gastos mayores a:',
        value: 50000
      }],*/
      etapasDefault: ['Solicitud formal', 'Aprobación de departamento',
        'Aprobación de director', 'Primer mitad', 'Segunda mitad',
        'Factura', 'Firma cheque departamento', 'Firma cheque director', 'Entrega de cheque'
      ],
      usuarios: [],
      modoProduccion: Api.modoProduccion
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    setEtapasDefault() {
      this.presupuesto.etapas = []
      for (let e of this.etapasDefault)
        this.presupuesto.etapas.push({
          nombre: e
        })
      alertLegacy("Configuradas etapas: " + this.etapasDefault.join(', '), null, 15000)
    },
    agregarRubro() {
      if (!this.presupuesto.rubros)
        this.presupuesto.rubros = []
      this.presupuesto.rubros.push({
        nombre: ''
      })
      this.$forceUpdate()
    },
    limiteRubro(indice) {
      let limite = this.presupuesto.cantidadAsignada
      for (let i in this.presupuesto.rubros)
        if (i != indice && this.presupuesto.rubros[i].cantidad && this.presupuesto.rubros[i].cantidad > 0)
          limite -= this.presupuesto.rubros[i].cantidad
      //console.log("Limite del rubro?", indice, this.presupuesto.cantidadAsignada, limite)
      if (this.presupuesto.rubros[indice].cantidad > limite)
        this.presupuesto.rubros[indice].cantidad = limite
      return limite
    },
    eliminarRubro(indice) {
      let rubrosNuevos = []
      for (let i in this.presupuesto.rubros)
        if (i != indice)
          rubrosNuevos.push(this.presupuesto.rubros[i])
      this.presupuesto.rubros = rubrosNuevos
      this.$forceUpdate()
    },
    recalcular() {
      this.presupuesto.cantidadAsignada = this.presupuesto.rubros.map(r => parseFloat(r.cantidad) || 0).reduce((acum, r) => acum + r)
      console.log("cantidadAsignada?", this.presupuesto.cantidadAsignada, this.presupuesto.rubros)
      this.$forceUpdate()
    },
    async guardar() {
      let propiedadesFaltantes = []
      for (let prop in this.presupuesto)
        if (prop == '' || prop == 0)
          propiedadesFaltantes.push(prop)
      let faltanDatosRubros = false
      let sumaRubros = 0
      for (let rubro of this.presupuesto.rubros) {
        if (!(rubro.nombre && rubro.nombre != '') || !(rubro.cantidad && rubro.cantidad > 0))
          faltanDatosRubros = true
        sumaRubros += rubro.cantidad
        console.log("rubro", rubro.cantidad, rubro.nombre, sumaRubros)
      }
      if (propiedadesFaltantes.length > 0)
        alert("Faltan datos: " + propiedadesFaltantes.join(', '))
      else if (faltanDatosRubros)
        alert("Rubros incompletos")
      else if (parseFloat(this.presupuesto.cantidadAsignada) != sumaRubros) {
        alert("Rubros no suman correctamente")
        console.log("Suma", this.presupuesto.cantidadAsignada, sumaRubros)
      } else {
        let u = await Api.save('presupuesto', this.presupuesto)
        alert("Presupuesto guardado")
        try {
          if (this.presupuesto.recibeFacturasSingasto == true) {
            let presupuestos = await Api.get('presupuesto')
            for (let pres of presupuestos)
              if (pres._id != u._id) {
                pres.recibeFacturasSingasto = false
                await Api.save('presupuesto', pres)
              }
          }
        } catch (e) {
          console.error("EPRES", e)
        }
        await Api.wait(1000)
        window.history.go(-1)
      }
    }
  },
  async mounted() {
    let usuarios = await Api.get('usuario')
    let usuariosFiltrados = []
    console.log("PresupuestosPartidas mounted", usuarios.length, this.id)
    usuariosFiltrados = usuarios.filter(u => u.permisos && u.permisos.gestionarPresupuestos && u.permisos.gestionarPresupuestos == true)
    if (usuariosFiltrados.length == 0)
      usuariosFiltrados = usuarios
    usuariosFiltrados = usuariosFiltrados.sort((a, b) => a.nombre > b.nombre ? 1 : -1)
    this.usuarios = usuariosFiltrados
    if (this.id && this.id != '' && this.id != '0') {
      this.presupuesto = await Api.get('presupuesto', this.id)
      if (!this.presupuesto.rubros)
        this.presupuesto.rubros = []
      console.log("Recuperado presupuesto", this.id)
    }
  }
}
</script>
