<script>
import Vue from 'vue'
import axios from 'axios'
import Api from '@/components/Api'
import EstadoDeRed from '@/components/EstadoDeRed'
const prefijoIdentidad = 'eliteabogados'
let ThemeMixin = Vue.mixin({
  name: 'ThemeMixin',
  data() {
    return {
      yo: Api.obtenerLocal(prefijoIdentidad + '.yo') || {},
      defaults: {
        appName: 'ELITE intranet',
        appVersion: 1.0,
        primary: 'eliteprimary',
        secondary: 'elitesecondary',
        tertiary: 'bdlgtertiary',
        light: 'bdlglight',
        dark: 'bdlgdark',
        success: 'bdlgsuccess',
        warning: 'bdlgwarning',
        danger: 'bdlgdanger'
      },
      custom: {},
      //atributos: ['appName', 'logo', 'logo2', 'splash', 'slogan', 'primary', 'secondary', 'tertiary' ],
      estadoDeRed: {
        status: 'connected'
      },
      intervalObtenerEstadoDeRed: null
    }
  },
  methods: {
    branding(val) {
      return this.colors(val) // Esto es un alias
    },
    colors(val) {
      val = val || 'primary'
      let res = this.defaults[val]
      // Obtener el default de la instancia de la app
      if (this.custom[val] && this.custom[val] != '')
        res = this.custom[val]
      // Estado de red
      if (!this.estadoDeRed.connected && val == 'primary')
        res = 'medium'
      //
      return res
    },
    async obtenerEstadoDeRed() {
      this.estadoDeRed = await EstadoDeRed.obtener()
    },
    configurarBranding(obj) {
      let vm = this
      return new Promise(async (resolve, reject) => {
        let tema = JSON.parse(JSON.stringify(this.defaults))
        for (let prop in obj)
          if (obj[prop] && obj[prop] != '')
            tema[prop] = obj[prop]
        vm.custom = tema
        await Api.wait(100)
        resolve(true)
      })
    }
  },
  async created() {
    // Cargar el JSON que se encuentre disponible. La condicion es que el
    // archivo empiece igual que el dominio desde el cual se carga.
    // Cargar el JSON cada vez que se inicie una sesión nueva
    //this.obtenerEstadoDeRed(); this.intervalObtenerEstadoDeRed = setInterval(this.obtenerEstadoDeRed, 10000)
    if (!(Api.obtenerLocal(prefijoIdentidad + '.yo') || {})._id) {
      try {
        const brandingUrl = '../' + window.location.hostname + '.branding.json'
        const url = window.location.href.replace(window.location.hash, brandingUrl)
        let d = ((await axios.get(url)).data)
        await this.configurarBranding(d)
        console.log("Cargada identidad de branding.json", brandingUrl, this.custom.version)
        Api.guardarLocal(prefijoIdentidad + '.branding', this.custom)
      } catch (e) {
        console.error("No se pudo encontrar branding en JSON", e)
      }
    } else {
      const brandingLocal = Api.obtenerLocal(prefijoIdentidad + '.branding') || {}
      await this.configurarBranding(brandingLocal)
      console.log("Cargando localmente branding", this.custom)
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalObtenerEstadoDeRed)
  }
})
export default ThemeMixin
</script>
