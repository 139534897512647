// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router.js";
import Ionic from "@ionic/vue";
import "@/models/Permisos";
import "@ionic/core/css/ionic.bundle.css";
import "@/../public/ionicons/dist/css/ionicons.min.css";
import "./registerServiceWorker";

Vue.config.productionTip = false;
Vue.use(Ionic);
/* eslint-disable no-new */
new Vue({
  router,
  //mixins: [ThemeMixin],
  render: (h) => h(App),
}).$mount("#app");
