<template>
  <div id="app">
    <ion-alert-controller></ion-alert-controller>
    <ion-loading-controller></ion-loading-controller>
    <ion-menu-controller></ion-menu-controller>
    <ion-toast-controller></ion-toast-controller>

    <ion-app>
      <ion-nav></ion-nav>
      <ion-menu side="start" type="overlay">
        <ion-header>
          <!--ion-toolbar :color="colors('primary')">
          <ion-title @click="irA('/')">{{branding('appName')}}</ion-title>
        </ion-toolbar-->
          <ion-item>
            <ion-note slot="start">
              <i class="icon icon-sm ion-ios-search"></i>
            </ion-note>
            <ion-input
              :placeholder="'Buscar en ' + branding('appName')"
              debounce="500"
              :value="query"
              v-on:ionChange="setQuery($event.detail.value)"
            ></ion-input>
            <ion-button
              slot="end"
              fill="clear"
              @click="activarCamara"
              v-if="query == '' && esMovil"
            >
              <i class="icon ion-ios-camera"></i>
            </ion-button>
            <ion-button
              slot="end"
              fill="clear"
              v-if="query != ''"
              @click="query = ''"
            >
              <i class="icon ion-ios-close-circle-outline"></i>
            </ion-button>
          </ion-item>
        </ion-header>
        <ion-content :color="colors('light')" v-if="usuario.rol != 'proveedor'">
          <div v-if="camaraActivada">
            <QrCamara />
          </div>
          <div v-if="query == ''">
            <ion-item lines="none">
              <ion-thumbnail slot="start">
                <img :src="branding('logo')" alt="avatar" />
              </ion-thumbnail>
              <ion-label @click="quienSoy" text-wrap>
                <h2>
                  {{ usuario.iniciales || usuario.nombre || usuario.email }}
                </h2>
                <p text-capitalize>{{ usuario.rol }}</p>
                <p v-if="!modoProduccion" @click="probarNotificacion">
                  Modo Pruebas
                </p>
              </ion-label>
              <ion-button slot="end" fill="outline" @click="logout">
                <i class="icon ion-ios-log-out"></i>
              </ion-button>
            </ion-item>
            <ion-list v-if="usuario && usuario._id">
              <ion-item
                href="#/notificaciones"
                v-if="(notificaciones || []).length > 0"
              >
                <ion-label text-wrap>Notificaciones</ion-label>
                <ion-badge slot="end" color="danger">{{
                  (notificaciones || []).length
                }}</ion-badge>
              </ion-item>
              <ion-row v-if="modulosBotonGigante.length > 0">
                <ion-col
                  size="6"
                  v-for="m in modulosBotonGigante"
                  :key="m.link"
                  class="ion-no-padding"
                  v-if="m.mostrar"
                >
                  <ion-card @click="closeEnd">
                    <ion-item :href="'#/' + m.link" detail="false" lines="none">
                      <ion-label class="ion-text-center" color="secondary">
                        <h1>
                          <i :class="'icon icon-lg ion-ios-' + m.icono"></i>
                        </h1>
                        <p>{{ m.label }}</p>
                      </ion-label>
                    </ion-item>
                  </ion-card>
                </ion-col>
              </ion-row>
              <div v-else @click="closeEnd">
                <ion-item-divider
                  v-if="
                    permiso('EditarActividades') ||
                    permiso('ReporteMisActividades')
                  "
                >
                  &nbsp;
                </ion-item-divider>
                <ion-item href="#/asuntos" v-if="permiso('EditarAsuntos')">
                  <ion-label text-wrap>Asuntos</ion-label>
                </ion-item>
                <ion-item
                  href="#/misactividades"
                  v-if="permiso('EditarActividades')"
                >
                  <ion-label text-wrap>Mis actividades</ion-label>
                </ion-item>
                <ion-item
                  href="#/actividad2"
                  v-if="permiso('CrearActividades')"
                >
                  <ion-label text-wrap>Nueva actividad</ion-label>
                </ion-item>
                <ion-item
                  href="#/reportes"
                  v-if="permiso('ReporteMisActividades')"
                >
                  <ion-label text-wrap>Generador de reportes</ion-label>
                </ion-item>
                <ion-item href="#/dashboard" v-if="permiso('VerDashboard')">
                  <ion-label text-wrap>Dashboard</ion-label>
                </ion-item>
              </div>
              <div v-if="empresa.moduloPagosProveedores" @click="closeEnd">
                <ion-item-divider>&nbsp;</ion-item-divider>
                <!-- Presupuestos -->
                <!--ion-item href="#/presupuestos/partidas" v-if="permiso('EditarPresupuestos') && !ocultarTodoMenosPagosDirectos">
              <ion-label text-wrap>Presupuestos</ion-label>
            </ion-item-->
                <!--ion-item href="#/presupuestos/propuestas"><span v-if="usuario.tipo=='asociado' || usuario.tipo=='administrativo'">Mis&nbsp;</span>Solicitudes de gasto</ion-item-->
                <!-- Gastos -->
                <ion-item
                  href="#/presupuestos/gastos/0"
                  v-if="
                    permiso('SolicitarGastos') && !ocultarTodoMenosPagosDirectos
                  "
                >
                  <ion-label text-wrap>Solicitar un gasto</ion-label>
                </ion-item>
                <!--ion-item href="#/presupuestos/gastos" v-if="permiso('DividirGastos')">
              <ion-label text-wrap>Seguimiento de gastos</ion-label>
            </ion-item-->
                <!-- Aprobaciones de pago -->
                <!--ion-item href="#/presupuestos/aprobaciones" v-if="permiso('EditarGastos')">Cambio de fechas de pago</ion-item-->
                <ion-item
                  href="#/presupuestos/cambiosfechas"
                  v-if="
                    (usuario.tipo.indexOf('director') >= 0 ||
                      soyEncargadoPresupuesto() ||
                      !modoProduccion) &&
                    !ocultarTodoMenosPagosDirectos
                  "
                >
                  <ion-label text-wrap>Cambio de fechas de pago</ion-label>
                </ion-item>
                <!-- Ejercer pagos  -->
                <ion-item
                  href="#/presupuestos/pagos/normal"
                  v-if="permiso('EmitirPagos')"
                >
                  <ion-label text-wrap>Pagos a proveedores</ion-label>
                </ion-item>
                <!--ion-item href="#/presupuestos/pagos/singastopreprogramado" v-if="permiso('EmitirPagos')">
              <ion-label text-wrap>Contabilidad (pagos socio director)</ion-label>
            </ion-item-->
                <ion-item
                  href="#/presupuestos/pagosdirectos"
                  v-if="
                    permiso('EmitirPagos') && !ocultarTodoMenosPagosDirectos
                  "
                >
                  <ion-label text-wrap>Pagos directos</ion-label>
                </ion-item>
                <ion-item
                  href="#/presupuestos/facturassingasto"
                  v-if="permiso('AdministrarFacturasSinGasto')"
                >
                  <ion-label text-wrap
                    >Autorización a proveedores
                    <span v-if="!modoProduccion"
                      >(AdministrarFacturasSinGasto)</span
                    ></ion-label
                  >
                </ion-item>
                <ion-item
                  href="#/presupuestos/justificacioninterna"
                  v-if="
                    permiso('JustificarGastos') ||
                    usuario.rol == 'administrador'
                  "
                >
                  <ion-label text-wrap
                    >Justificación interna de gastos</ion-label
                  >
                </ion-item>
              </div>
              <div v-if="empresa.moduloSolicitudFacturas" @click="closeEnd">
                <ion-item-divider>&nbsp;</ion-item-divider>
                <!--ion-item-divider>Solicitud de facturas</ion-item-divider-->
                <ion-item
                  href="#/solicitudfactura/0"
                  v-if="permiso('SolicitarFacturas')"
                >
                  <ion-label text-wrap>Solicitar una factura</ion-label>
                </ion-item>
                <ion-item
                  href="#/solicitudesfactura/pendientes"
                  v-if="
                    permiso('EmitirFacturas') || permiso('SolicitarFacturas')
                  "
                >
                  <ion-label text-wrap
                    ><span v-if="usuario.esquemaPermisos == 'Contabilidad'"
                      >Solicitudes de facturación</span
                    >
                    <span v-else>Facturas pendientes</span>
                  </ion-label>
                </ion-item>
                <ion-item
                  href="#/solicitudesfactura/completadas"
                  v-if="permiso('EmitirFacturas')"
                >
                  <ion-label text-wrap>Facturas completadas</ion-label>
                </ion-item>
                <ion-item
                  href="#/catalogos/conceptossat"
                  v-if="permiso('EditarConceptosFacturables')"
                >
                  <ion-label text-wrap
                    >Catálogo de Conceptos facturables</ion-label
                  >
                </ion-item>
              </div>
              <div
                v-if="usuario.rol == 'administrador' && empresa.moduloTarifas"
                @click="closeEnd"
              >
                <ion-item-divider>&nbsp;</ion-item-divider>
                <ion-item
                  href="#/acuerdos"
                  v-if="permiso('verTarifas') || permiso('editarTarifas')"
                >
                  <ion-label text-wrap>Acuerdos</ion-label>
                </ion-item>
                <ion-item href="#/listacobranza" v-if="permiso('verTarifas')">
                  <ion-label text-wrap>Lista de cobranza</ion-label>
                </ion-item>
                <ion-item
                  href="#/prefacturas"
                  v-if="
                    permiso('verTarifas') ||
                    permiso('facturar') ||
                    permiso('consultarFacturas')
                  "
                >
                  <ion-label text-wrap
                    >Facturas y pendientes de timbrar</ion-label
                  >
                </ion-item>
              </div>
              <div @click="closeEnd" v-if="permiso('CostearActividades')">
                <ion-item lines="none">
                  <ion-label color="medium">
                    <h1>Costeo de actividad</h1>
                  </ion-label>
                </ion-item>
                <ion-item href="#/acuerdos">
                  <ion-label text-wrap>Acuerdos de cobranza</ion-label>
                </ion-item>
                <ion-item href="#/reporteacuerdos">
                  <ion-label text-wrap>Reportes de cobranza</ion-label>
                </ion-item>
                <ion-item href="#/asuntos/sinAcuerdos">
                  <ion-label text-wrap>Asuntos sin acuerdos</ion-label>
                </ion-item>
              </div>
              <div @click="closeEnd">
                <ion-item
                  lines="none"
                  v-if="
                    permiso('EditarUsuarios') ||
                    permiso('EditarClientes') ||
                    permiso('EditarEmpresa') ||
                    permiso('EditarConceptosFacturables')
                  "
                >
                  <ion-label color="medium">
                    <h1>Configuración</h1>
                  </ion-label>
                </ion-item>
                <ion-item
                  href="#/catalogos/empresa"
                  v-if="permiso('EditarEmpresa')"
                >
                  <ion-label text-wrap>Empresa</ion-label>
                </ion-item>
                <ion-item
                  href="#/catalogos/usuarios"
                  v-if="permiso('EditarUsuarios')"
                >
                  <ion-label text-wrap>Usuarios (abogados)</ion-label>
                </ion-item>
                <ion-item
                  href="#/catalogos/clientes"
                  v-if="permiso('EditarClientes')"
                >
                  <ion-label text-wrap>Clientes</ion-label>
                </ion-item>
                <ion-item
                  href="#/catalogos/corporativos"
                  v-if="
                    permiso('EditarCorporativos') &&
                    empresa.agruparClientesPorCorporativos
                  "
                >
                  <ion-label text-wrap>Corporativos</ion-label>
                </ion-item>
                <ion-item
                  href="#/catalogos/areas"
                  v-if="permiso('EditarAreasDePractica')"
                >
                  <ion-label text-wrap>Áreas de práctica</ion-label>
                </ion-item>
                <ion-item
                  href="#/catalogos/proveedores"
                  v-if="
                    permiso('EditarProveedores') &&
                    empresa.moduloPagosProveedores
                  "
                >
                  <ion-label text-wrap>Proveedores</ion-label>
                </ion-item>
                <ion-item
                  href="#/catalogos/conceptossat"
                  v-if="
                    permiso('EditarConceptosFacturables') &&
                    empresa.moduloSolicitudFacturas
                  "
                >
                  <ion-label text-wrap>Conceptos facturables</ion-label>
                </ion-item>
                <ion-item
                  href="#/catalogos/fechaspago"
                  v-if="
                    permiso('EditarCatalogoFechasDePago') &&
                    empresa.moduloPagosProveedores
                  "
                >
                  <ion-label text-wrap>Fechas de pago</ion-label>
                </ion-item>
                <ion-item lines="none" v-if="usuario.rol == 'administrador'">
                  <ion-label color="medium">
                    <h1>Herramientas</h1>
                  </ion-label>
                </ion-item>
                <ion-item href="#/bitacora" v-if="permiso('VerBitacora')">
                  <ion-label text-wrap>Bitácora</ion-label>
                </ion-item>
                <ion-item href="#/posts" v-if="permiso('Blog')">
                  <ion-label text-wrap>Publicaciones del blog</ion-label>
                </ion-item>
                <!--ion-item href="#/hooks" v-if="permiso('EditarHooks')">
                <ion-label text-wrap>Hooks</ion-label>
              </ion-item>
              <ion-item href="#/catalogos/merge" v-if="permiso('UsarHerramientaMerge')">
                <ion-label text-wrap>Herramienta Merge</ion-label>
              </ion-item-->
                <ion-item
                  @click="todasActividades"
                  v-if="permiso('ExportarExpedientesCSV')"
                >
                  <ion-label text-wrap>Exportar expedientes en CSV</ion-label>
                </ion-item>
              </div>
            </ion-list>
          </div>
          <div v-else>
            <Buscador :query="query" />
          </div>
        </ion-content>
        <ion-content :color="colors('light')" @click="closeEnd" v-else>
          <ion-item lines="none">
            <ion-avatar slot="start">
              <img
                :src="
                  './public/' + (modoProduccion ? 'icono-48.PNG' : 'logo3.png')
                "
                alt="avatar"
              />
            </ion-avatar>
            <ion-label @click="quienSoy" text-wrap>
              <h2>
                {{ usuario.iniciales || usuario.nombre || usuario.email }}
              </h2>
              <p text-capitalize>{{ usuario.rol }}</p>
              <p v-if="!modoProduccion" @click="probarNotificacion">
                Modo Pruebas
              </p>
            </ion-label>
            <ion-button slot="end" fill="outline" @click="logout">
              <i class="icon ion-ios-log-out"></i>
            </ion-button>
          </ion-item>
          <ion-list>
            <ion-item href="#/proveedor/subirsingasto">
              <ion-label>
                <h2>Subir factura</h2>
              </ion-label>
            </ion-item>
            <ion-item href="#/proveedor/misventas">
              <ion-label>
                <h2>Mis ventas</h2>
              </ion-label>
            </ion-item>
            <ion-item href="#/proveedor/misdatos">
              <ion-label>
                <h2>Mi cuenta</h2>
                <p
                  v-if="
                    usuario.opinionDeCumplimientoFecha &&
                    usuario.opinionDeCumplimientoFecha <
                      new Date().getTime() - 1000 * 60 * 60 * 24 * 30
                  "
                >
                  <i class="icon ion-ios-information-circle-outline"></i
                  >&nbsp;Opinion de cumplimiento caduca
                </p>
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-label>&nbsp;</ion-label>
            </ion-item>
            <ion-item
              href="./public/manual_proveedores.pdf"
              target="_blank"
              color="light"
            >
              <ion-label>
                <h2>Manual de instrucciones</h2>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
        <ion-footer>
          <ion-toolbar :color="colors('light')">
            <ion-title>v{{ branding("version") }}</ion-title>
          </ion-toolbar>
        </ion-footer>
      </ion-menu>
      <div class="ion-page" contentId="main">
        <router-view></router-view>
      </div>
    </ion-app>
  </div>
</template>

<style>
@media print {
  .noprint {
    display: none;
  }
}

.icon-lg {
  font-size: 28px;
}

:root {
  --ion-color-primary: rgb(70, 55, 121);
  --ion-color-secondary: rgb(70, 55, 121);
  --ion-color-warning: #d4b56a;
}

@import url("../public/global.scss");
</style>

<script>
import Api from "./components/Api";
import ThemeMixin from "@/Theme";
import Notificador from "@/models/Notificador";
import Buscador from "@/components/Buscador";
import QrCamara from "@/components/QrCamara";
import Vue from "vue";
const moment = require("moment");
require("moment/locale/es");
Vue.use(require("vue-moment"), {
  moment,
});
import VueCurrencyFilter from "vue-currency-filter";
Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: false,
});
if (Api.modoProduccion) {
  console.log = function () {};
}
console.merror = console.error;
//
const empresa = Api.obtenerLocal("elite.empresa") || {};
let usuario = Api.obtenerLocal("elite.yo") || {
  nombre: "Usuario",
  rol: "administrador",
  modoProduccion: Api.modoProduccion,
  notificaciones: [],
  permisos: {},
};
if (!usuario.permisos) usuario.permisos = {};
console.log("usuario permisos", usuario.nombre, usuario.permisos);
export default {
  name: "App",
  components: {
    Buscador,
    QrCamara,
  },
  mixins: [ThemeMixin],
  data() {
    return {
      usuario,
      query: "",
      esMovil: Api.esMovil(),
      camaraActivada: false,
      misPresupuestos: [],
      notificaciones: [],
      modoProduccion: Api.modoProduccion,
      empresa,
      ocultarTodoMenosPagosDirectos: true, // Jun 09 2021
      modulosBotonGigante: [
        {
          label: "Actividades",
          icono: "create",
          link: "misactividades",
          mostrar: usuario.permisos.EditarActividades,
        },
        {
          label: "Asuntos",
          icono: "list",
          link: "asuntos",
          mostrar: usuario.permisos.EditarAsuntos,
        },
        {
          label: "Reportes",
          icono: "stats",
          link: "reportes",
          mostrar: usuario.permisos.ReporteMisActividades,
        },
        {
          label: "Dashboard",
          icono: "speedometer",
          link: "dashboard",
          mostrar: usuario.permisos.VerDashboard,
        },
      ],
    };
  },
  methods: {
    irA(str) {
      this.$router.push(str);
      this.closeEnd();
    },
    openEnd() {
      console.log("Abriendo menú");
      document.querySelector("ion-menu-controller").open("start");
    },
    closeEnd() {
      document.querySelector("ion-menu-controller").close("start");
      this.query = "";
    },
    async logout() {
      for (let indice in localStorage)
        if (indice.startsWith("bdlg") && indice != "elite.yo")
          Api.borrarLocal(indice);
      //  Api.$emit('logged-in', {})
      try {
        Api.save("bitacora", {
          idAutor: this.usuario._id,
          mensaje: "LOGOUT " + this.usuario.rol + " " + this.usuario.email,
          fecha: new Date().getTime(),
        });
      } catch (e) {
        console.error(e);
      }
      Api.borrarLocal("elite.yo");
      Api.borrarLocal("wst.yo");
      try {
        this.closeEnd();
      } catch (e) {}
      this.$router.push("/");
      window.location.reload();
    },
    quienSoy() {
      console.log("Yo soy", this.usuario);
    },
    soyEncargadoPresupuesto() {
      return this.misPresupuestos.length > 0;
    },
    async probarNotificacion() {
      await Notificador.avisar("Esto es una prueba de notificaciones");
      alert("Notificación enviada");
      setTimeout(this.encontrarNotificaciones, 1000);
    },
    async encontrarNotificaciones() {
      this.notificaciones = await Notificador.encontrar();
      if (this.notificaciones.length > 0)
        console.log("NOTIF", this.notificaciones.length);
      this.$forceUpdate();
    },
    async todasActividades() {
      alert("Exportando");
      let clientes = await Api.get("cliente");
      let asuntos = await Api.get("asunto");
      asuntos = asuntos
        .map((a) => {
          for (let cliente of clientes)
            if (cliente._id == a.idCliente) a.nombreCliente = cliente.nombre;
          return a;
        })
        .sort((a, b) => {
          return a.fecha > b.fecha ? 1 : -1;
        })
        .sort((a, b) => {
          return a.nombreCliente > b.nombreCliente ? 1 : -1;
        })
        .sort((a, b) => {
          return a.idArea > b.idArea ? 1 : -1;
        })
        .filter((a) => {
          let nombre = (a.nombreCliente || "").toLowerCase();
          return nombre.indexOf("herrera olvera") == -1;
        });
      let charseparator = "\t";
      charseparator = ",";
      let txt =
        "Exportación de expedientes\n\nExpediente" +
        charseparator +
        "Area" +
        charseparator +
        "Cliente" +
        charseparator +
        "Fecha" +
        charseparator +
        "Activo\n";
      for (let a of asuntos) {
        txt +=
          "" +
          (a.nombre || "").replace(/,/g, "") +
          charseparator +
          a.area +
          charseparator +
          (a.nombreCliente || "").replace(/,/g, "") +
          charseparator +
          this.$options.filters.moment(a.fecha, "YYYY-MM-DD") +
          charseparator +
          (a.terminado && a.terminado == 1 ? "No" : "Si") +
          "\n";
      }
      //txt = windows1252.encode(txt)
      txt = "\ufeff" + txt; // NETA? Pinche Windows requiere esta mamada para forzar UTF8
      console.log("TXT a exportar", typeof txt, txt);

      function BlobDownload(Filename, Bytes, Mimetype, encoding) {
        encoding = encoding || "utf-8";
        window.TextEncoder = window.TextEncoder || null;
        var bytes = new TextEncoder(encoding, {
          NONSTANDARD_allowLegacyEncoding: true,
        }).encode(Bytes);
        var filData = new Blob([bytes], {
          type: Mimetype,
        });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // for IE
          window.navigator.msSaveOrOpenBlob(filData, Filename);
        } else {
          // for Non-IE (chrome, firefox etc.)
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          var filUrl = URL.createObjectURL(filData);
          a.href = filUrl;
          a.download = Filename;
          a.click();
          a.remove();
        }
      }

      function download(filename, text) {
        var element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," + encodeURIComponent(text)
        );
        element.setAttribute("download", filename);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
      let ext = "csv";
      if (charseparator == "\t") ext = "tsv";
      download("expedientes." + ext, txt);
      //BlobDownload('expedientes.tsv', txt, 'text/tsv', 'utf-8')
    },
    async comprobarSesionCerradaManualmente() {
      let peticionCerrarSesion = await Api.find(
        "peticioncerrarsesion",
        ["idUsuario,eq," + this.usuario._id],
        {
          sort: ["fechaEjecutada,1"],
          limit: 1,
        }
      );
      //console.log("comprobarSesionCerradaManualmente?", peticionCerrarSesion)
      peticionCerrarSesion = peticionCerrarSesion[0];
      if ((peticionCerrarSesion || {}).fechaEjecutada == 0) {
        // Hay que sacarnos
        alert("Por favor vuelva a iniciar sesión ");
        peticionCerrarSesion.fechaEjecutada = new Date().getTime();
        await Api.save("peticioncerrarsesion", peticionCerrarSesion);
        Api.borrarLocal("elite.yo");
        Api.borrarLocal("wst.yo");
        await Api.wait(2000);
        this.$router.push("/");
        window.location.reload();
      }
    },
    setQuery(q) {
      q = (q || "")
        .replace(window.location.origin + "/#/asunto/", "")
        .toUpperCase();
      console.log("setQuery?", q, window.location);
      this.query = q;
    },
    activarCamara() {
      this.camaraActivada = !this.camaraActivada;
      if (this.camaraActivada) alert("Escanee el QR");
    },
  },
  watch: {
    $route: function (a, b) {
      console.log("watch $route", a.path, b.path);
      this.query = "";
      let yo = Api.obtenerLocal("elite.yo") || {};
      if (!yo._id) {
        console.log("Saliendo");
        Api.borrarLocal("wst.yo");
        this.$router.push("/");
        window.location.reload();
      }
    },
  },
  async mounted() {
    let vm = this;
    Api.$on("open-menu", this.openEnd);
    Api.$on("close-menu", this.closeEnd);
    Api.$on("setQuery", this.setQuery);
    Api.$on("logged-in", (usuario) => {
      this.usuario = usuario;
      if (usuario && usuario._id)
        //Api.guardarLocal('wst.yo', usuario)
        console.log("Hola", usuario.email);
      else Api.borrarLocal("elite.yo");
    });
    // Si ya hay sesión, ir directo a ella
    let usuario = Api.obtenerLocal("elite.yo") || {};
    if (usuario && usuario._id) {
      vm.usuario = usuario;
      vm.usuario.tipo = vm.usuario.tipo || "administrador";
      vm.usuario.agregaClientes = true;
      if (vm.usuario.rol == "administrador") {
        vm.usuario.editaUsuarios = true;
      }
      Api.$emit("logged-in", usuario);
      let rutaDefault = "";
      if (usuario.rutaDefault && usuario.rutaDefault != "")
        rutaDefault = usuario.rutaDefault;
      //if (rutaDefault != '') vm.$router.push(rutaDefault)
    }
    // Sobreescribir la función alert default
    async function presentAlert(txt, opts) {
      opts = opts || {};
      try {
        const toastController = document.querySelector("ion-toast-controller");
        await toastController.componentOnReady();
        const toast = await toastController.create({
          message: txt || "",
          duration: opts.duration || 1500,
          color: opts.color || "primary",
          buttons: ["OK"],
        });
        return await toast.present();
      } catch (e) {
        console.error("No se pudo obtener la alerta secundaria");
        window.alertLegacy(txt);
      }
    }
    async function presentAlert2(txt, header) {
      header = header || "Aviso";
      try {
        const alertController = document.querySelector("ion-alert-controller");
        await alertController.componentOnReady();
        const alert = await alertController.create({
          header: header,
          subHeader: txt,
          //message: txt,
          buttons: ["OK"],
        });
        return await alert.present();
      } catch (e) {
        console.merror("ESECONDARYALERT", e);
        window.alertLegacy(txt);
      }
    }
    // Obtener mis presupuestos designados, si los hay
    this.misPresupuestos = await Api.find("presupuesto", [
      "idUsuarioAsignado,eq," + this.usuario._id,
    ]);
    // Encontrar notificaciones
    this.encontrarNotificaciones();
    setInterval(this.encontrarNotificaciones, 30000);
    //
    setInterval(this.comprobarSesionCerradaManualmente, 15000);
    //
    window.alertLegacy = window.alert;
    window.alert = presentAlert;
    window.alert2 = presentAlert2;
  },
};
</script>
