<template>
<ion-app main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-buttons slot="end">
        <ion-button @click="obtener">
          <i class="icon ion-ios-refresh"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Bitácora</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content" padding>
    <ion-list>
      <ion-item-divider>Viendo eventos de los ultimos {{(fechaActual-fechaInicio)/(1000*60*60*24) | currency('')}} dias</ion-item-divider>
      <ion-item v-for="e in eventos" :key="e._id">
        <ion-label text-wrap>
          <h2>{{e.mensaje}}</h2>
          <p>{{e.fecha | moment('MMMM DD HH:mm')}}</p>
          <p>{{quienFue(e.idAutor)}}</p>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</ion-app>
</template>
<style scoped>
.content {
  /*background-image: url('http://alherrera.tk/demos/elite.vue/dist/public/fondo_azul2.png');*/
  background-position: center;
}
</style>
<script>
import Api from '@/components/Api'
export default {
  name: 'App',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      modoProduccion: Api.modoProduccion,
      fechaInicio: new Date().getTime() - (1000 * 60 * 60 * 24 * 30),
      fechaActual: new Date().getTime(),
      usuarios: [],
      eventos: []
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    pos() {
      this.$router.push('pos')
    },
    async obtener() {
      this.eventos = await Api.find('bitacora', [
        'fecha,gte,' + this.fechaInicio
      ], {
        sort: ['fechaInicio,-1']
      })
      console.log("Eventos", this.eventos)
      return Promise.resolve(true)
    },
    quienFue(id) {
      let n = ''
      for (let u of this.usuarios)
        if (u._id == id)
          n = u.nombre
      return n
    }
  },
  async mounted() {
    this.usuarios = await Api.get('usuario')
    await this.obtener()
    if (this.eventos.length == 0)
      this.eventos.push({
        mensaje: 'No hay elementos que mostrar',
        fecha: new Date().getTime(),
        idAutor: this.yo._id
      })
  }
}
</script>
