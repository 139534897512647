<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Mis datos</ion-title>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" v-if="cargando"></ion-progress-bar>
  </ion-header>
  <ion-content>
    <ion-list>
      <ion-item v-for="f in formUsuario" :key="f.label" v-if="f.name!='rol'||!modoProduccion">
        <ion-label text-wrap>{{f.label}}</ion-label>
        <ion-input v-if="!f.type || ['select','textarea'].indexOf(f.type)==-1" debounce="100" :value="usuario[f.name]" :color="colors('primary')" class="ion-text-right" v-on:ionChange="setU(f.name,$event.detail.value)" :type="f.type||'text'">
        </ion-input>
        <ion-textarea v-if="f.type && f.type=='textarea'" debounce="100" :value="usuario[f.name]" rows="3" :color="colors('primary')" class="ion-text-right" v-on:ionChange="setU(f.name,$event.detail.value)"></ion-textarea>
        <ion-select v-if="f.type && f.type=='select'" :color="colors('primary')" v-on:ionChange="setU(f.name,$event.detail.value)" :value="usuario[f.name]" class="ion-text-right">
          <ion-select-option v-for="o in f.options" :key="o" text-capitalize :value="o">{{o | uppercase}}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-card>
        <label for="opinionDeCumplimiento">
          <input type="file" class="hidden" name="opinionDeCumplimiento" id="opinionDeCumplimiento" v-on:change="subirOpinionDeCumplimiento()" />
          <ion-item>
            <ion-note slot="start" :color="colors('danger')">
              <i class="icon ion-ios-information-circle-outline icon-lg" v-if="!opinionDeCumplimientoVigente"></i>
              <i class="icon ion-ios-checkmark-circle-outline icon-lg" v-else></i>
            </ion-note>
            <ion-label text-wrap>
              <h2>Opinión de cumplimiento</h2>
              <p v-if="opinionDeCumplimientoVigente">Subida el {{usuario.opinionDeCumplimientoFecha | moment('MMM DD')}}: {{usuario.opinionDeCumplimiento.split('.')[0]}}</p>
              <p v-else>Su opinión de cumplimiento está desactualizada. Haga clic aquí para subirla.</p>
            </ion-label>
          </ion-item>
        </label>
      </ion-card>
    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('primary')" @click="guardar">
        <i class=" icon icon-lg ion-ios-save"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
export default {
  name: 'ProveedoresMisDatos',
  data() {
    return {
      cargando: false,
      yo: Api.obtenerLocal('elite.yo') || {},
      usuario: Api.obtenerLocal('elite.yo') || {},
      opinionDeCumplimientoVigente: false,
      ventas: [],
      formUsuario: [{
        label: 'Nombre',
        name: 'nombre',
        required: true
      }, {
        label: 'RFC',
        name: 'rfc',
        required: true
      }, {
        label: 'Email (para iniciar sesión)',
        name: 'email',
        required: true
      }, {
        label: 'Email alternativo',
        name: 'emailAlternativo',
        required: false
      }, {
        label: 'Contraseña',
        name: 'password',
        type: 'password',
        required: true
      }, {
        label: 'Nombre del banco',
        name: 'bancoNombre'
      }, {
        label: 'Número de cuenta o tarjeta',
        name: 'bancoCuenta'
      }, {
        label: 'CLABE',
        name: 'bancoClabe'
      }, {
        label: 'Dirección (fiscal)',
        name: 'direccionFiscal',
        type: 'textarea'
      }],
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async obtener() {
      this.usuario = JSON.parse(JSON.stringify(this.yo))
      console.log("Obteniendo", this.usuario)
      this.calcularOpinionDeCumplimientoVigente()
    },
    calcularOpinionDeCumplimientoVigente() {
      let vigente
      let fechaMasAntiguaPermitida = new Date().getTime() - (1000 * 60 * 60 * 24 * 30)
      if (!this.usuario.opinionDeCumplimiento ||
        !this.usuario.opinionDeCumplimientoFecha ||
        this.usuario.opinionDeCumplimientoFecha < fechaMasAntiguaPermitida)
        vigente = false
      else vigente = true
      this.opinionDeCumplimientoVigente = vigente
    },
    setU(campo, valor) {
      this.usuario[campo] = valor
      console.log("setU", campo, valor)
    },
    async subirOpinionDeCumplimiento() {
      console.log("subirOpinionDeCumplimiento?")
      this.cargando = true
      try {
        this.usuario.opinionDeCumplimiento = await Api.upload('opinionDeCumplimiento')
        this.usuario.opinionDeCumplimientoFecha = new Date().getTime()
        this.opinionDeCumplimientoVigente = true
        this.$forceUpdate()
        alert("Opinión de cumplimiento subida")
        let noRecargar = true
        this.guardar(noRecargar)
        await Api.wait(1000)
      } catch (e) {
        console.error("ECUMPLIMIENTO", e)
        alert("No se pudo subir la opinión de cumplimiento")
      }
      this.cargando = false
    },
    async guardar(noRecargar) {
      noRecargar = noRecargar && true
      let guardable = true
      for (let c of this.formUsuario) {
        if (c.required && !this.usuario[c.name]) {
          alert('Faltan datos: ' + c.name)
          guardable = false
        }
      }
      // Duplicados
      let duplicado = false
      if (!this.usuario._id) {
        let usuarios = await Proveedor.lista()
        for (let u of usuarios) {
          if (u.nombre == this.usuario.nombre || u.email == this.usuario.email) {
            console.error("Usuario duplicado", this.usuario, u)
            duplicado = true
          }
        }
      }
      // Forzar el rol
      this.usuario.rol = 'proveedor'
      //
      if (duplicado) {
        alert("Usuario duplicado")
      } else if (guardable) {
        let usuario = await Api.save('usuario', this.usuario)
        console.log("guardado?", usuario)
        if (this.yo._id == this.usuario._id) {
          Api.guardarLocal('elite.yo', this.usuario)
          console.log("Guardado local?", Api.obtenerLocal('elite.yo'))
        }
        if (!this.usuario._id && usuario._id)
          this.usuario._id = usuario._id
        //this.usuario = usuario
        Api.guardarLocal('elite.usuario.' + usuario._id, this.usuario)
        alert("Guardado")
        if (!noRecargar) {
          let vm = this
          setTimeout(_ => {
            //vm.$router.push('/catalogos/proveedores')
            window.history.go(-1)
            window.location.reload()
          }, 1000)
        }
      }
    }
  },
  mounted() {
    this.obtener()
  }
}
</script>
