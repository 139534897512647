<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('danger')">
      <ion-title>Catalogos - Fechas de pago ({{fechaspago.length}})</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-card>
      <ion-item lines="none">
        <ion-input type="date" :value="fechapago.fechaPago" v-on:ionChange="fechapago.fechaPago=$event.detail.value" v-on:keyup.enter="guardar()"></ion-input>
        <ion-button :color="colors('primary')" slot="end" @click="guardar" v-if="permiso('EditarCatalogoFechasDePago')"><i class="icon ion-ios-save"></i></ion-button>
      </ion-item>
    </ion-card>
    <ion-list id="lista">
      <ion-item-sliding v-for="u in fechaspago" :key="u._id">
        <ion-item v-on:click="ver(u)">
          <ion-label text-wrap>
            <h2>{{u.fechaPago | moment('DD MMMM YYYY')}}</h2>
          </ion-label>
        </ion-item>
        <ion-item-options slot="end">
          <ion-item-option :color="colors('danger')" v-if="permiso('EditarCatalogoFechasDePago')" v-on:click="eliminar(u._id)">
            <i class="icon ion-ios-trash"></i>&nbsp;Eliminar
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
export default {
  name: 'CatalogoFechaspago',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      fechaspago: [],
      fechapago: {}
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    pos() {
      this.$router.push('pos')
    },
    async obtener() {
      this.fechaspago = (await Api.get('fechaspago'))
        .sort((a, b) => a.fechaPago > b.fechaPago ? 1 : -1)
      console.log("fechasPago", this.fechasPago)
      return Promise.resolve(true)
    },
    async guardar() {
      await Api.save('fechaspago', this.fechapago)
      alert("Guardada")
      await Api.wait(1000)
      this.fechapago = {}
      this.$forceUpdate()
      this.obtener()
    },
    ver(fecha) {
      this.fechapago = fecha
    },
    async eliminar(id) {
      if (true == confirm('Desea eliminar este elemento? Esto no se puede deshacer')) {
        await Api.delete('fechaspago', id)
        alert("Eliminada fecha")
        await Api.wait(2000)
        try {
          document.querySelector('#lista').closeSlidingItems()
        } catch (e) {}
        this.obtener()
      }
    },
  },
  mounted() {
    this.obtener()
  }
}
</script>
