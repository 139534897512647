<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Gastos</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="obtener">
          <i class="icon ion-ios-refresh"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" v-if="cargando"></ion-progress-bar>
  </ion-header>
  <ion-content>
    <ion-row>
      <ion-col size-xs="12" size-sm="6" class="ion-no-padding">
        <ion-item>
          <ion-label position="floating">Ver estado</ion-label>
          <ion-select :value="modo" v-on:ionChange="setModo($event.detail.value)" debounce="250">
            <ion-select-option>Todos</ion-select-option>
            <ion-select-option>Aprobados</ion-select-option>
            <ion-select-option>Pendientes</ion-select-option>
            <ion-select-option>Aprobables por socio</ion-select-option>
            <ion-select-option>Rechazados</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
      <ion-col size-xs="12" size-sm="6" class="ion-no-padding">
        <ion-item>
          <ion-label position="floating">De presupuesto</ion-label>
          <ion-select :value="idPresupuesto" v-on:ionChange="toggleIdPresupuesto($event.detail.value)">
            <ion-select-option :value="''">Todos</ion-select-option>
            <ion-select-option v-for="i in presupuestos" :key="i._id" :value="i._id">{{i.nombre}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-list id="listaGastos">
      <ion-item-sliding v-for="i in gastos" :key="i._id" v-if="filtrarEsteGasto(i)">
        <ion-item :href="'#/presupuestos/gastos/'+i._id">
          <ion-note slot="start" color="medium">
            <i v-if="i.estado=='aprobado'" class="icon ion-ios-checkmark-circle-outline icon-lg"></i>
            <i v-else class="icon ion-ios-more icon-lg"></i>
          </ion-note>
          <ion-label text-wrap>
            <!-- v-if="i.nombre" -->
            <h2>{{i.nombre || '- SIN NOMBRE -'}}</h2>
            <p v-if="i.autor && i.autor._id">Por {{i.autor.nombre}}</p>
            <p v-if="i.idUsuarioAprueba && i.aprueba">Aprobado por {{i.aprueba.nombre}} en {{i.fechaAprobada | moment('MMM DD')}}</p>
            <p v-if="i.idPresupuesto && i.presupuesto && i.presupuesto._id && idPresupuesto==''">{{i.presupuesto.nombre}} > {{i.rubro}}</p>
            <p v-if="modo=='Todos' && i.estado"><span>Recibida: {{i.fecha | moment('MMM DD')}}&nbsp;</span> Estado: {{i.estado}}</p>
          </ion-label>
          <!--ion-label vv-else class="ion-text-left">
            <pre>{{i}}</pre>
          </ion-label-->
          <!--ion-note slot="end">{{i.cantidadCotizada | currency}}</ion-note-->
          <ion-note slot="end" :color="parseFloat(i.cantidadCotizada)>parseFloat(i.limiteGasto)?'warning':'dark'">{{i.cantidadCotizada | currency}}</ion-note>
        </ion-item>
        <ion-item-options slot="end">
          <ion-item-option :color="colors('danger')" @click="eliminar(i)" v-if="permiso('BorrarGastos') || i.idAutor==yo._id">Eliminar</ion-item-option>
          <ion-item-option color="medium" v-else>No eliminar: <span v-if="i.progresado!=0">Ya en progreso</span><span v-else>Permisos</span></ion-item-option>
        </ion-item-options>
        <ion-progress-bar v-if="i.aprobable>0" :value="i.aprobado/i.aprobable" :color="colors('success')" />
        <ion-progress-bar v-if="i.aprobable>0" :value="i.aprobado/i.aprobable" :color="colors('success')" />
        <ion-progress-bar v-if="i.progresable>0" :value="i.progresado/i.progresable" :color="colors('tertiary')" />
        <ion-progress-bar v-if="i.progresable>0" :value="i.progresado/i.progresable" :color="colors('tertiary')" />
      </ion-item-sliding>
    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('danger')" href="#/presupuestos/gastos/0">
        <i class="icon icon-lg ion-ios-add"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
export default {
  name: 'PresupuestosGastos',
  data() {
    return {
      cargando: true,
      modoProduccion: Api.modoProduccion,
      modo: null,
      gastos: [],
      yo: Api.obtenerLocal('elite.yo') || {},
      presupuestos: [],
      idPresupuesto: '',
      idPresupuestoDefault: '',
      misPresupuestos: [] // Para usuarios con presupuesto asignado
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    setModo(val) {
      this.modo = val
      console.log("setModo", val)
      this.obtener()
    },
    async obtener() {
      this.cargando = true
      let misPresupuestos = (await Api.find('presupuesto', [
          'idUsuarioAsignado,eq,' + this.yo._id
        ]))
        .map(u => u._id)
        .filter(u => !u.recibeFacturasSingasto || !Api.modoProduccion)
      console.log("misPresupuestos?", misPresupuestos)
      let soyRoot = this.yo.rol.startsWith('admin') ||
        this.yo.tipo == 'director'
      let miId = this.yo._id
      let filtros = []
      if (this.idPresupuesto != '') {
        filtros.push('idPresupuesto,eq,' + this.idPresupuesto)
        console.log("idPresupuesto,eq," + this.idPresupuesto)
      }
      let subset = [{
          coleccion: 'presupuesto',
          query: '_id,eq,',
          sustituir: 'idPresupuesto',
          unico: true
        },
        {
          coleccion: 'directorio',
          alias: 'autor',
          query: '_id,eq,',
          sustituir: 'idAutor',
          unico: true
        }, {
          coleccion: 'directorio',
          alias: 'aprueba',
          query: '_id,eq,',
          sustituir: 'idUsuarioAprueba',
          unico: true
        }, {
          coleccion: 'gastoetapa',
          alias: 'etapas',
          query: 'idGasto,eq,',
          sustituir: '_id'
        }
      ]
      let gastos = (await Api.find('presupuestogasto', filtros, {
        sort: ['fechaLimite,-1', 'fecha,-1'],
        subset
      }))
      console.log("Gastos pre?", gastos.map(g => g.nombre), filtros)
      gastos = gastos.filter(gasto => {
        // Mis presupuestos
        let estaEnMiPresupuesto = misPresupuestos.indexOf((gasto.presupuesto || {})._id) >= 0
        // Mi autoría
        let yoLoHice = gasto.idAutor == miId
        // soyRoot
        // soyRoot = soyRoot
        console.log("Gasto?", gasto.nombre || gasto.descripcion, (gasto.presupuesto || {}).nombre || 'Sin presupuesto', estaEnMiPresupuesto, yoLoHice, soyRoot, gasto)
        return estaEnMiPresupuesto || yoLoHice || soyRoot
      })
      console.log("Gastos post?", gastos.map(g => g.nombre), filtros)
      // Filrar por presupuesto de facturas sin gastos
      let idPresupuestoDefault = this.idPresupuestoDefault
      //gastos = gastos.filter(g => g.idPresupuesto != idPresupuestoDefault)
      // Devolver
      this.gastos = gastos
      this.cargando = false
      this.$forceUpdate()
      /*/ Procesar graficos sublineales
      if (true)
        for (let id in this.gastos) try {
          this.gastos[id].progresable = this.gastos[id].etapas.length
          this.gastos[id].aprobable = this.gastos[id].etapas.length
          this.gastos[id].aprobado = 0
          this.gastos[id].progresado = 0
          for (let e of this.gastos[id].etapas) {
            if (e.terminada)
              this.gastos[id].progresado++
            if (!e.requiereAprobacionSocio || e.aprobadaPorSocio)
              this.gastos[id].aprobado++
          }
        } catch (e) {
          console.error("EETAPAS", id, this.gastos.length, e)
          console.error(this.gastos[id] || this.gastos)
        }//*/
    },
    filtrarEsteGasto(p) {
      console.log("filtrarEsteGasto?", p.nombre, p.estado, p.modo)
      let mostrar = true
      switch (this.modo) {
        case 'Pendientes':
          mostrar = (p.estado == 'pendiente')
          break
        case 'Rechazados':
          mostrar = (p.estado.toLowerCase().indexOf('rechazad') >= 0)
          break
        case 'Aprobados':
          mostrar = (p.estado.toLowerCase().indexOf('aprobad') >= 0)
          break
        case 'Aprobables por socio':
          mostrar = (p.limiteGasto < p.cantidadCotizada && p.estado == 'pendiente')
          break
        case 'Todos':
        default:
          mostrar = true
      }
      if (!this.permiso('AdministrarFacturasSinGasto')) {
        if (this.misPresupuestos.length > 0) {
          mostrar = this.misPresupuestos.indexOf(p.idPresupuesto) >= 0
          console.log("Filtrado para encargado de presupuesto?", this.misPresupuestos, mostrar)
        }
        // Presupuesto default
        if (
          this.empresa.idPresupuestoDefault &&
          this.empresa.idPresupuestoDefault != '' && Api.modoProduccion
        )
          mostrar = mostrar && p.idPresupuesto != this.empresa.idPresupuestoDefault
      }
      //*/
      //
      return mostrar
    },
    toggleIdPresupuesto(val) {
      this.idPresupuesto = val
      console.log("toggleIdPresupuesto", this.idPresupuesto, this.idPresupuestoDefault)
      this.obtener()
    },
    async eliminar(g) {
      if (true == confirm("Desea eliminar?")) {
        await Api.delete('presupuestogasto', g._id)
        try {
          let gastos = await Api.find('gastoetapa', ['idGasto,eq,' + g._id])
          for (let g of gastos.map(u => u._id))
            await Api.delete('gastoetapa', g)
        } catch (e) {
          console.error("EDELGASTOETAPA", e)
        }
        //await Api.wait(1000)
        //this.obtener()
        this.gastos = this.gastos.filter(p._id != g._id)
        try {
          document.querySelector('#listaGastos').closeSlidingItems()
        } catch (e) {}
      }
    }
  },
  watch: {
    '$route.path': function() {
      window.location.reload()
    }
  },
  async mounted() {
    console.log("PresupuestosPartidas mounted", this.$route.path, typeof Api.wait)
    this.usuarios = await Api.get('usuario')
    this.empresa = (await Api.get('empresa'))[0]
    this.idPresupuestoDefault = this.empresa.idPresupuestoDefault
    console.log('idPresupuestoDefault', this.idPresupuestoDefault)
    this.presupuestos = (await Api.get('presupuesto'))
    //.filter(u => !u.recibeFacturasSingasto || !this.modoProduccion)
    let modo = 'Pendientes'
    if (this.yo.rol != 'administrador')
      modo = 'Todos'
    if (this.yo.rol == 'socio')
      modo = 'Aprobables por socio'
    if (this.$route.path.indexOf('gastosaprobados') >= 0)
      modo = 'Aprobados'
    await Api.wait(1500)
    this.setModo(modo)
  }
}
</script>
