<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Actividades de {{yo.nombre}}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="obtener">
          <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
          <i class="icon ion-ios-refresh"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-row>
      <ion-col :no-padding="esMovil" :size-md="esMovil?12:8" size-sm="12">
        <Actividad :mostrarSelectorActividad="true" :usarHandlerSecundario="true" :idActividad="id" v-if="!ocultarActividad" />
      </ion-col>
      <ion-col size-md="4" size-sm="12" v-if="!esMovil">
        <ion-item-divider>Recientes</ion-item-divider>
        <MisActividadesHandler :usarHandlerSecundario="true" :ordenarPorFechaGuardado="true" />
      </ion-col>
    </ion-row>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
import Actividad from '@/components/Actividad'
import MisActividadesHandler from '@/components/MisActividadesHandler'
export default {
  name: 'ActividadHandler',
  props: ['id'],
  components: {
    Actividad,
    MisActividadesHandler
  },
  data() {
    return {
      yo: {},
      actividad: {},
      fechaInicio: new Date(),
      esMovil: Api.esMovil(),
      ocultarActividad: false
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async obtener() {
      if (this.id && this.id != '') {
        this.actividad = await Api.get('actividad', this.id)
      }
      this.asuntos = await Api.find('asunto', ['fecha,gte,' + this.fechaInicio.getTime()])
      this.clientes = await Api.get('cliente')
      this.abogados = await Api.get('usuario')
    }
  },
  mounted() {
    this.yo = Api.obtenerLocal('elite.yo')
    if (!(this.yo && this.yo._id))
      console.warn("No has iniciado sesión")
    this.obtener()
    let vm = this
    /*
      Api.$on('ActividadGuardada', _ => {
        vm.ocultarActividad = true
        vm.$forceUpdate()
        setTimeout(_ => {
          vm.ocultarActividad = false
          vm.$forceUpdate()
        }, 500)
      })*/
    Api.$on('ActividadGuardada', _ => {
      console.log("$on ActividadGuardada")
      Api.$emit('MisActividadesHandler.obtener', null)
    })
  },
  beforeDestroy() {
    Api.$off('ActividadGuardada')
  }
}
</script>

<style>

</style>
