import Vue from "vue";
import Router from "vue-router";
import Login from "@/components/Login";
import HelloWorld from "@/components/HelloWorld";
import Catalogos from "@/components/Catalogos/Catalogos";
import Asuntos from "@/components/Asuntos";
import VerCliente from "@/components/VerCliente";
import MisActividades from "@/components/MisActividades";
import Asunto from "@/components/Asunto";
import Facturable from "@/components/Reportes/Facturable";
import Reportes from "@/components/Reportes/Reportes";
import Dashboard from "@/components/Dashboard";
import Actividades from "@/components/Actividades";
import CosteoActividades from "@/components/CosteoActividades";
import ActividadHandler from "@/components/ActividadHandler";
import ActividadHandler2 from "@/components/ActividadHandler2";
import CatalogoEmpresa from "@/components/Catalogos/Empresa";
import CatalogoUsuarios from "@/components/Catalogos/Usuarios";
import CatalogoUsuario from "@/components/Catalogos/Usuario";
import CatalogoClientes from "@/components/Catalogos/Clientes";
import CatalogoCliente from "@/components/Catalogos/Cliente";
import CatalogoProveedores from "@/components/Catalogos/Proveedores";
import CatalogoProveedor from "@/components/Catalogos/Proveedor";
import CatalogoCorporativos from "@/components/Catalogos/Corporativos";
import CatalogoProductos from "@/components/Catalogos/Productos";
import CatalogoProducto from "@/components/Catalogos/Producto";
import CatalogoConceptossat from "@/components/Catalogos/Conceptossat";
import CatalogoConceptosat from "@/components/Catalogos/Conceptosat";
import CatalogoFechaspago from "@/components/Catalogos/Fechaspago";
import CatalogoAreas from "@/components/Catalogos/Areas";
import CatalogoImportar from "@/components/Catalogos/Importar";
import HerramientaMerge from "@/components/HerramientaMerge";
import ListaCobranza from "@/components/ListaCobranza";
import Prefacturas from "@/components/Prefacturas";
import Notificaciones from "@/components/Notificaciones";
import Webhooks from "@/components/Webhooks";
import Bitacora from "@/components/Bitacora";
import Posts from "@/components/Posts";
import Post from "@/components/Post";
//
import Presupuestos from "@/components/Presupuestos/Presupuestos";
import PresupuestosPartidas from "@/components/Presupuestos/Partidas";
import PresupuestosPartida from "@/components/Presupuestos/Partida";
import PresupuestosGastos from "@/components/Presupuestos/Gastos";
import PresupuestosGasto from "@/components/Presupuestos/Gasto";
import PresupuestosPropuestas from "@/components/Presupuestos/Propuestas";
import PresupuestosPropuesta from "@/components/Presupuestos/Propuesta";
import PresupuestosPagos from "@/components/Presupuestos/Pagos";
import PresupuestosPagosDirectos from "@/components/Presupuestos/PagosDirectos";
import PresupuestosAprobaciones from "@/components/Presupuestos/Aprobaciones";
import PresupuestosCambiosFechas from "@/components/Presupuestos/CambiosFechas";
import FacturasDeProveedoresSinGasto from "@/components/Presupuestos/FacturasDeProveedoresSinGasto";
import PresupuestosJustificacion from "@/components/Presupuestos/Justificacion";
//
import SolicitudesFactura from "@/components/SolicitudFactura/SolicitudesFactura";
import SolicitudFactura from "@/components/SolicitudFactura/SolicitudFactura";
//
import ProveedoresMisVentas from "@/components/Proveedores/MisVentas";
import ProveedoresMisDatos from "@/components/Proveedores/MisDatos";
import ProveedoresSubirSinGasto from "@/components/Proveedores/SubirSinGasto";
//
import Acuerdos from "@/components/Acuerdos/Acuerdos";
import ReporteAcuerdos from "@/components/Acuerdos/Reporte";
import Acuerdo from "@/components/Acuerdos/Acuerdo";
//
Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "Login",
      component: Login,
    },
    {
      path: "/hola",
      name: "HelloWorld",
      component: HelloWorld,
    },
    {
      path: "/bitacora",
      name: "Bitacora",
      component: Bitacora,
    },
    {
      path: "/posts",
      name: "Posts",
      component: Posts,
    },
    {
      path: "/posts/:id",
      name: "PostId",
      component: Post,
      props: true,
    },
    {
      path: "/asuntos",
      name: "Asuntos",
      component: Asuntos,
    },
    {
      path: "/asuntos/:modoRuta",
      name: "AsuntosModoRuta",
      component: Asuntos,
      props: true,
    },
    {
      path: "/reporteacuerdos",
      name: "ReporteAcuerdos",
      component: ReporteAcuerdos,
    },
    {
      path: "/acuerdos/:id",
      name: "Acuerdo",
      component: Acuerdo,
      props: true,
    },
    {
      path: "/misactividades",
      name: "MisActividades",
      component: MisActividades,
    },
    {
      path: "/actividades",
      name: "Actividades",
      component: Actividades,
    },
    {
      path: "/costeoactividades",
      name: "CosteoActividades",
      component: CosteoActividades,
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
    },
    {
      path: "/notificaciones",
      name: "Notificaciones",
      component: Notificaciones,
    },
    {
      path: "/hooks",
      name: "Webhooks",
      component: Webhooks,
    },
    {
      path: "/proveedor/misventas",
      name: "ProveedoresMisVentas",
      component: ProveedoresMisVentas,
    },
    {
      path: "/proveedor/misdatos",
      name: "ProveedoresMisDatos",
      component: ProveedoresMisDatos,
    },
    {
      path: "/proveedor/subirsingasto",
      name: "ProveedoresSubirSinGasto",
      component: ProveedoresSubirSinGasto,
    },
    {
      path: "/presupuestos",
      name: "Presupuestos",
      component: Presupuestos,
      children: [
        {
          path: "partidas",
          name: "PresupuestosPartidas",
          component: PresupuestosPartidas,
        },
        {
          path: "partidas/:id",
          name: "PresupuestosPartida",
          component: PresupuestosPartida,
          props: true,
        },
        {
          path: "gastos",
          name: "PresupuestosGastos",
          component: PresupuestosGastos,
        },
        {
          path: "gastosaprobados",
          name: "PresupuestosGastosAprobados",
          component: PresupuestosGastos,
        },
        {
          path: "gastos/:id",
          name: "PresupuestosGasto",
          component: PresupuestosGasto,
          props: true,
        },
        {
          path: "propuestas",
          name: "PresupuestosPropuestas",
          component: PresupuestosPropuestas,
        },
        {
          path: "propuestas/:id",
          name: "PresupuestosPropuesta",
          component: PresupuestosPropuesta,
          props: true,
        },
        {
          path: "pagos",
          name: "PresupuestosPagos",
          component: PresupuestosPagos,
        },
        {
          path: "justificacioninterna",
          name: "PresupuestosJustificacionInterna",
          component: PresupuestosJustificacion,
        },
        {
          path: "pagos/:tipo",
          name: "PresupuestosPagosPorTipo",
          component: PresupuestosPagos,
          props: true,
        },
        {
          path: "pagosdirectos",
          name: "PresupuestosPagosDirectos",
          component: PresupuestosPagosDirectos,
        },
        {
          path: "aprobaciones",
          name: "PresupuestosAprobaciones",
          component: PresupuestosAprobaciones,
        },
        {
          path: "cambiosfechas",
          name: "PresupuestosCambiosFechas",
          component: PresupuestosCambiosFechas,
        },
        {
          path: "facturassingasto",
          name: "FacturasDeProveedoresSinGasto",
          component: FacturasDeProveedoresSinGasto,
        },
      ],
    },
    {
      path: "/solicitudfactura",
      name: "SolicitudesFactura",
      component: SolicitudesFactura,
    },
    {
      path: "/solicitudfactura/:id",
      name: "SolicitudFacturaId",
      component: SolicitudFactura,
      props: true,
    },
    {
      path: "/solicitudesfactura/:modoprop",
      name: "SolicitudesFacturaModo",
      component: SolicitudesFactura,
      props: true,
    },
    {
      path: "/reportes",
      name: "Reportes",
      component: Reportes,
      children: [
        /*{
          path: "",
          name: "ReportesGral",
          component: Reportes,
        },//*/
        {
          path: "general",
          name: "ReportesGralLista",
          component: Reportes,
        },
        {
          path: "tipo/:tipo",
          name: "ReportesTipo",
          component: Reportes,
          props: true,
        },
      ],
    },
    {
      path: "/actividad",
      name: "ActividadHandler",
      component: ActividadHandler,
    },
    {
      path: "/actividad2",
      name: "ActividadHandler2",
      component: ActividadHandler2,
    },
    {
      path: "/actividad/:id",
      name: "ActividadHandlerId",
      component: ActividadHandler,
      props: true,
    },
    {
      path: "/actividad2/:id",
      name: "ActividadHandler2Id",
      component: ActividadHandler2,
      props: true,
    },
    {
      path: "/asunto/:id",
      name: "Asunto",
      component: Asunto,
      props: true,
    },
    {
      path: "/cliente/:idCliente",
      name: "Cliente",
      component: VerCliente,
      props: true,
    },
    {
      path: "/acuerdos",
      name: "Acuerdos",
      component: Acuerdos,
    },
    {
      path: "/listacobranza",
      name: "ListaCobranza",
      component: ListaCobranza,
    },
    {
      path: "/prefacturas/:fechaInicio/:fechaFin",
      name: "PrefacturasRango",
      component: Prefacturas,
      props: true,
    },
    {
      path: "/prefacturas",
      name: "Prefacturas",
      component: Prefacturas,
    },
    {
      path: "/acuerdos/:id",
      name: "AcuerdoHandler",
      component: Acuerdos,
      props: true,
    },
    {
      path: "/facturable/:modo/:id",
      name: "Facturable",
      component: Facturable,
      props: true,
    },
    {
      path: "/catalogos",
      name: "Catalogos",
      component: Catalogos,
      children: [
        {
          path: "empresa",
          name: "CatalogoEmpresa",
          component: CatalogoEmpresa,
        },
        {
          path: "clientes",
          name: "Clientes",
          component: CatalogoClientes,
        },
        {
          path: "corporativos",
          name: "Corporativos",
          component: CatalogoCorporativos,
        },
        {
          path: "cliente/:id",
          name: "ClienteId",
          component: CatalogoCliente,
          props: true,
        },
        {
          path: "proveedores",
          name: "CatalogoProveedores",
          component: CatalogoProveedores,
        },
        {
          path: "proveedor/:id",
          name: "CatalogoProveedor",
          component: CatalogoProveedor,
          props: true,
        },
        {
          path: "usuarios",
          name: "Usuarios",
          component: CatalogoUsuarios,
        },
        {
          path: "usuario/:id",
          name: "Usuario",
          component: CatalogoUsuario,
          props: true,
        },
        {
          path: "productos",
          name: "Productos",
          component: CatalogoProductos,
        },
        {
          path: "producto/:id",
          name: "Producto",
          component: CatalogoProducto,
          props: true,
        },
        {
          path: "conceptossat",
          name: "CatalogoConceptossat",
          component: CatalogoConceptossat,
        },
        {
          path: "conceptossat/:id",
          name: "CatalogoConceptosat",
          component: CatalogoConceptosat,
          props: true,
        },
        {
          path: "fechaspago",
          name: "CatalogoFechaspago",
          component: CatalogoFechaspago,
        },
        {
          path: "areas",
          name: "Areas",
          component: CatalogoAreas,
        },
        {
          path: "importar",
          name: "CatalogoImportar",
          component: CatalogoImportar,
        },
        {
          path: "merge",
          name: "HerramientaMerge",
          component: HerramientaMerge,
        },
      ],
    },
  ],
});
