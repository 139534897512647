<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Actividades de {{yo.nombre}}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="obtener">
          <ion-icon name="refresh" :color="colors('light')"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-item v-if="actividades.length==0">
      <ion-icon name="information-circle"></ion-icon>
      No hay actividades para tí en este momento
    </ion-item>
    <ion-row v-else>
      <ion-col>
        <ion-list>
          <ion-item-divider>Actividades próximas</ion-item-divider>
          <ion-item-sliding v-for="a in actividades" :key="a._id">
            <ion-item v-on:click="verActividad(a)">
              <ion-label text-wrap>
                <h2>{{a.descripcion}}</h2>
                <p v-if="a.asunto && a.cliente">
                  {{a.cliente.nombre}}
                </p>
                <p v-if="yo.rol!='abogado' && a.abogado">
                  Asignado a {{a.abogado.nombre}}
                </p>
              </ion-label>
              <ion-note v-if="!a.fechaFin"> para el {{a.fechaProgramada | moment('MMM DD')}}</ion-note>
              <ion-note v-else>terminada el {{a.fechaFin}}</ion-note>
            </ion-item>
          </ion-item-sliding>
        </ion-list>
      </ion-col>
      <ion-col v-if="actividad && actividad._id" size="12" size-md="7">
        <actividad v-if="actividad && actividad._id" :id-actividad="actividad._id" :id-asunto="actividad.idAsunto"></actividad>
      </ion-col>
    </ion-row>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
import Actividad from './Actividad'
export default {
  name: 'Actividades',
  components: {
    Actividad
  },
  data() {
    return {
      yo: {},
      actividades: [],
      actividad: {},
      clientes: {},
      abogados: {},
      fechaInicio: new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 30)),
      fechaFin: new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 3))
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    pos() {
      this.$router.push('pos')
    },
    async obtener() {
      this.actividad = {}
      let filtros = []
      filtros.push('fechaProgramada,gte,' + this.fechaInicio.getTime())
      filtros.push('fechaProgramada,lte,' + this.fechaFin.getTime())
      if (this.yo.rol == 'abogado')
        filtros.push('idAbogado,eq,' + this.yo._id)
      console.log("Obteniendo actividades", filtros)
      let actividades = await Api.find('actividad', filtros)
      actividades = actividades.sort((a, b) => {
        return a.fechaProgramada > b.fechaProgramada ? 1 : -1
      })
      let vm = this
      for (let i in actividades) {
        Api.get('asunto', actividades[i].idAsunto).then(a => {
          actividades[i].asunto = a
          if (a.idCliente && vm.clientes[a.idCliente])
            actividades[i].cliente = vm.clientes[a.idCliente]
        }).catch(e => {
          console.error("No se pudo recuperar asunto", actividades[i].idAsunto)
        })
        if (this.yo.rol != 'abogado' && vm.abogados[actividades[i].idAbogado])
          actividades[i].abogado = vm.abogados[actividades[i].idAbogado]

      }
      this.actividades = actividades
      console.log("Actividades", this.actividades.length, this.actividades[0])
      this.$forceUpdate()
    },
    verActividad(a) {
      let vm = this
      vm.actividad = {}
      setTimeout(_ => {
        vm.actividad = a
      }, 200)
    },
    eliminar(id) {
      let vm = this
      if (true == confirm('Desea eliminar este elemento? Esto no se puede deshacer')) {
        Api.delete('asunto', id).then(async _ => {
          let actividades = await Api.find('actividad', ['idAsunto,eq,' + id])
          actividades = actividades.map(a => {
            return a._id
          })
          Api.deleteMulti('actividad', actividades)
          vm.obtener()
        })
      }
    }
  },
  async mounted() {
    this.yo = Api.obtenerLocal('elite.yo')
    if (!(this.yo && this.yo._id)) {
      console.warn("No has iniciado sesión")
    }
    let vm = this
    let clientes = await Api.get('cliente')
    clientes.forEach(c => {
      if (!vm.clientes[c._id])
        vm.clientes[c._id] = c
    })
    if (this.yo.rol != 'abogado') {
      let abogados = await Api.get('usuario')
      abogados.forEach(a => {
        if (!vm.abogados[a._id])
          vm.abogados[a._id] = a
      })
    }
    this.obtener()
    Api.$on('ActividadGuardada', _ => {
      setTimeout(vm.obtener, 1000)
    })
  },
  beforeDestroy() {
    Api.$off('ActividadGuardada')
  }
}
</script>
