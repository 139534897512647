<script>
import Api from '@/components/Api'
import Vue from 'vue'
//import { Plugins } from '@capacitor/core';
//const { Network } = Plugins;
let EstadoDeRed = new Vue({
  name: 'EstadoDeRed',
  data() {
    return {
      estadoDeRed: {}
    }
  },
  methods: {
    async obtener() {
      if (typeof Network != 'undefined')
        try {
          this.estadoDeRed = await Network.getStatus()
        } catch (e) {
          console.merror('EESTADODERED', e)
          // Asumir que sì se està conectado
          this.estadoDeRed = {
            connected: true,
            type: '4g'
          }
        }
      Api.$emit('estadoDeRed', this.estadoDeRed)
      return Promise.resolve(this.estadoDeRed)
    },
    inicializar() {
      this.obtener()
      setInterval(this.obtener, 10000)
    }
  }
})
export default EstadoDeRed
</script>
