<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Autorización a proveedores ({{facturassingasto.length}})</ion-title>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" v-if="cargando"></ion-progress-bar>
  </ion-header>
  <ion-content>
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-item>
          <ion-checkbox slot="start" :checked="mostrarPendientes" v-on:click="toggle('mostrarPendientes')"></ion-checkbox>
          <ion-label>Pendientes</ion-label>
        </ion-item>
      </ion-col>
      <ion-col class="ion-no-padding">
        <ion-item>
          <ion-checkbox slot="start" :checked="mostrarAprobadas" v-on:click="toggle('mostrarAprobadas')"></ion-checkbox>
          <ion-label>Aprobadas</ion-label>
        </ion-item>
      </ion-col>
      <ion-col class="ion-no-padding">
        <ion-item>
          <ion-checkbox slot="start" :checked="mostrarPagadas" v-on:click="toggle('mostrarPagadas')"></ion-checkbox>
          <ion-label>Pagadas</ion-label>
        </ion-item>
      </ion-col>
      <ion-col class="ion-no-padding">
        <ion-item>
          <ion-checkbox slot="start" :checked="mostrarCanceladas" v-on:click="toggle('mostrarCanceladas')"></ion-checkbox>
          <ion-label>Canceladas</ion-label>
        </ion-item>
      </ion-col>
      <ion-col class="ion-no-padding" v-if="!modoProduccion">
        <ion-item>
          <ion-checkbox slot="start" :checked="mostrarTodas" v-on:click="toggle('mostrarTodas')"></ion-checkbox>
          <ion-label>TODAS</ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <table class="tablaFacturasSinGasto" :class="(esMovil)?'tablaMovil':''">
      <thead>
        <tr>
          <th class="ion-text-right">CR</th>
          <th>Folio fact.</th>
          <th>Proveedor</th>
          <th>Concepto</th>
          <th>Solicitud</th>
          <th class="ion-text-right">Cantidad</th>
          <th>Programada para</th>
          <th>Estado</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="f in facturassingasto" :key="f._id" @click="clog(f)">
          <td class="ion-text-right">{{f.folio}} <span v-if="f.cancelada&&f.cancelada>0"> - </span></td>
          <td>{{f.metadatos.fecha}} {{f.metadatos.folio}} {{modoProduccion?'':f.idPresupuesto}}</td>
          <td><span v-if="f.proveedor">{{f.proveedor.nombre}}</span></td>
          <td>{{f.metadatos.concepto}}</td>
          <td>{{f.metadatos.solicitante}} {{f.justificacion}}</td>
          <td class="ion-text-right">{{f.metadatos.cantidad | currency}}</td>
          <td>
            <span v-if="f.fU">{{(f.fU) | moment('MMM DD')}}</span>
            <span v-else>-</span>
          </td>
          <td>{{estado(f)}}
            <span v-if="f.esUrgente"> URGENTE</span>
            <span v-if="f.cancelada">{{f.cancelada | moment('MMM DD')}}</span>
            <span v-if="f.canceladaPorUsuario">&nbsp;por proveedor</span>
            <span v-if="f.fechaTerminado">&nbsp;{{f.fechaTerminado | moment('MMM DD')}}</span>
          </td>
          <td>
            <ion-item lines="none">
              <ion-label>&nbsp;</ion-label>
              <ion-select :value="f.accion" v-on:ionChange="accion(f,$event.detail.value)" style="height:20px;border-radius:0px;border:1px solid #dddddd;" interface="action-sheet">
                <ion-select-option v-if="(estado(f)=='Pendiente'&&estado(f)!='Pagada') || !modoProduccion">Aprobar</ion-select-option>
                <ion-select-option v-if="estado(f)!='Cancelada'&&estado(f)!='Pagada'">Cambiar fecha</ion-select-option>
                <ion-select-option v-if="estado(f)!='Cancelada'&&estado(f)!='Pagada'">Cancelar</ion-select-option>
                <ion-select-option :value="''">...</ion-select-option>
                <ion-select-option :value="'justificacion'">Descargar justificación</ion-select-option>
                <ion-select-option :value="'factura'">Descargar factura</ion-select-option>
                <ion-select-option :value="'xml'">Descargar XML</ion-select-option>
                <ion-select-option :value="'comprobantePago'" v-if="estado(f)=='Pagada'">Descargar comprobante de pago</ion-select-option>
                <ion-select-option v-if="yo.rol=='administrador' && !modoProduccion">Eliminar</ion-select-option>
                <ion-select-option v-if="yo.rol=='administrador' && !modoProduccion">Cambiar monto</ion-select-option>
              </ion-select>
            </ion-item>
            <!--select v-model="f.accion" v-on:change="accion(f,f.accion)" style="height:20px;border-radius:0px;border:1px solid #dddddd;">
              <option value="">...</option>
              <option v-if="estado(f)=='Pendiente' || !modoProduccion">Aprobar</option>
              <option v-if="estado(f)!='Cancelada'">Cambiar fecha</option>
              <option v-if="estado(f)!='Cancelada'">Cancelar</option>
              <option> - - </option>
              <option value="justificacion">Descargar justificación</option>
              <option value="factura">Descargar factura</option>
              <option value="xml">Descargar XML</option>
              <option v-if="yo.rol=='administrador' && !modoProduccion">Eliminar</option>
              <option v-if="yo.rol=='administrador' && !modoProduccion">Cambiar monto</option>
            </select-->
            <!--ion-button color="medium" size="small" target="_blank" :href="cdn+f.archivoAdjuntoJustificacion" v-if="f.archivoAdjuntoJustificacion">
              <i class="icon icon-md ion-ios-journal"></i>
            </ion-button>
            <ion-button color="medium" size="small" target="_blank" :href="cdn+f.archivoAdjunto" v-if="f.archivoAdjunto">
              <i class="icon icon-md ion-ios-document"></i>
            </ion-button>
            <ion-button color="medium" size="small" target="_blank" :href="cdn+f.archivoAdjuntoXml" v-if="f.archivoAdjuntoXml">
              <i class="icon icon-md ion-ios-code-working"></i>
            </ion-button-->
            <!--pre>{{f}}</pre-->
          </td>
        </tr>
      </tbody>
    </table>
  </ion-content>
</ion-page>
</template>
<style>
.tablaFacturasSinGasto {
  width: 100%;
  text-align: left;
  padding: 8px;
}

.tablaFacturasSinGasto td,
.tablaFacturasSinGasto th {
  padding: 12px;
  font-size: 0.8em;
}

.tablaFacturasSinGasto td {
  border-top: 1px solid #cccccc;
}

.tablaMovil {
  overflow-x: auto;
  display: block;
  border: 2px solid #333333;
}
</style>
<script>
import Api from '@/components/Api'
import FechasPagoModel from '@/models/FechasPago'
import Emailer from '@/models/Emailer'
import ModalSeleccionarFechaPago from '@/components/Presupuestos/ModalSeleccionarFechaPago'
export default {
  name: 'FacturasDeProveedoresSinGasto',
  data() {
    return {
      cargando: false,
      modoProduccion: Api.modoProduccion,
      //esMovil: Api.esMovil(),
      esMovil: window.innerWidth < window.innerHeight,
      cdn: Api.cdn + '/',
      yo: Api.obtenerLocal('elite.yo') || {},
      facturassingasto: [],
      fechaInicial: new Date().getTime() - (1000 * 60 * 60 * 24 * 365),
      mostrarPendientes: true,
      mostrarAprobadas: false,
      mostrarPagadas: false,
      mostrarCanceladas: false,
      mostrarTodas: false
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    nombre(str) {
      str = str || ''
      return str.substring(0, str.length - 41)
    },
    clog(obj) {
      console.log("log", obj)
    },
    async obtener() {
      this.cargando = true
      const mostrarAprobadas = this.mostrarAprobadas,
        mostrarPendientes = this.mostrarPendientes,
        mostrarPagadas = this.mostrarPagadas,
        mostrarCanceladas = this.mostrarCanceladas,
        mostrarTodas = this.mostrarTodas
      let facturassingasto = (await Api.find('facturasingasto', [
        'fecha,gte,' + this.fechaInicial
      ], {
        subset: [{
          coleccion: 'usuario',
          alias: 'proveedor',
          query: '_id,eq,',
          sustituir: 'idProveedor',
          unico: true
        }, {
          coleccion: 'gastoetapa',
          alias: 'pago',
          query: '_id,eq,',
          sustituir: '_id',
          unico: true
        }]
      }))
      let pagos = (await Api.get('gastoetapa'))
        .sort((a, b) => a.fecha > b.fecha ? 1 : -1)
      let pagosIndice = {}
      for (let p of pagos)
        pagosIndice[p._id] = p
      console.log("facturassingasto sin filtrar?", facturassingasto[0], facturassingasto.length, facturassingasto.filter(p => p.pago), pagos)
      this.facturassingasto = facturassingasto
        .map(f => {
          console.log("F?", f.nombre, f.fechaProgramado, f.fechaProgramadoTentativo, f.fechaTerminada, f)
          f.fU = (f.fechaProgramado && f.fechaProgramado.fechaProgramado) ?
            f.fechaProgramado.fechaProgramado : f.fechaProgramado
          if ((f.fU || '').toString().length == 0)
            f.fU = f.fechaProgramadoTentativo
          if (pagosIndice[f._id]) {
            f.pago = pagosIndice[f._id]
            f.fechaTerminado = f.pago.fechaTerminada
            f.archivoAdjuntoComprobantePago = f.pago.archivoAdjuntoComprobante
          }
          return f
        })
        .sort((a, b) => a.fecha < b.fecha ? 1 : -1)
        .sort((a, b) => a.fU > b.fU ? 1 : -1)
        .sort((a, b) => !(a.esUrgente) ? 1 : -1)
        //*
        .filter(f =>
          mostrarTodas ||
          this.estado(f) == 'Pendiente' && mostrarPendientes ||
          this.estado(f) == 'Pagada' && mostrarPagadas ||
          this.estado(f) == 'Aprobada' && mostrarAprobadas || ['Cancelada', 'Rechazada'].indexOf(this.estado(f)) >= 0 && mostrarCanceladas
        ) //*/
        .map(f => {
          f.accion = ''
          if (f.metadatos && f.metadatos.folio)
            f.metadatos.folio = f.metadatos.folio.replace('undefined', '').trim()
          return f
        })
        // Solo facturas justificadas internamente
        .filter(f => f.justificacion && f.justificacion != '' || f.aprobada == true || this.estado(f) == 'Aprobada')
      for (let i in this.facturassingasto) {
        if (!this.facturassingasto[i].fechaProgramadoTentativo && !this.facturassingasto[i].fechaProgramado) try {
          const fechaDePago = await FechasPagoModel.encontrarFechaDePagoMasCercana(this.facturassingasto[i].fecha || (new Date().getTime()))
          this.facturassingasto[i].fechaProgramadoTentativo = fechaDePago
        } catch (e) {
          console.error("No se pudo asignar cierta fecha de pago", e, this.facturassingasto[i])
          alert("No se pudo encontrar una fecha de pago ")
        }
      }
      console.log("facturassingasto?", this.facturassingasto)
      this.cargando = false
    },
    estado(f) {
      let edo = 'Pendiente'
      if (f.canceladaPorUsuario)
        edo = 'Cancelada'
      else if (f.cancelada && f.cancelada > 0)
        edo = 'Rechazada'
      else if (f.aprobada && f.aprobada == true)
        edo = 'Aprobada'
      if (f.fechaTerminado && f.fechaTerminado > 0)
        edo = 'Pagada'
      return edo
    },
    descargar(ruta) {
      console.log("Descargando", ruta)
      //alert("Descargando " + ruta)
      //window.open(ruta, '_blank')
      let a = document.createElement('a')
      a.href = ruta
      a.target = '_blank'
      a.className = "hidden"
      a.class = 'hidden'
      document.querySelector('body').appendChild(a)
      a.click()
    },
    async accion(obj, accion) {
      console.log("accion?", accion, obj.cantidad, obj)
      switch (accion) {
        case 'Cancelar':
          if (true == confirm('Desea cancelar esta factura?')) {
            obj.accion = ''
            obj.cancelada = new Date().getTime()
            obj.motivo = prompt('Introduzca motivo de rechazo (opcional). Se avisará de esto al proveedor.', '')
            await Api.save('facturasingasto', obj)
            // Enviar correo
            const destino = (await Api.get('usuario', obj.idProveedor)).email
            const cuerpo1 = 'Estimado proveedor, le informamos que la factura con folio ' +
              ((obj.metadatos || {}).folio || obj.folio) +
              ' por ' + this.$options.filters.currency((obj.metadatos || {}).cantidad) +
              ', que nos hizo llegar mediante el portal, no fue autorizada para su pago. ' +
              'Para mayor información, por favor pongase en contacto con la persona que requirió el servicio.'
            const cuerpo2 = (obj.motivo && obj.motivo != '') ? ('Motivo de rechazo: ' + obj.motivo) : ''
            const titulo = 'Rechazo de factura recibida'
            await Emailer.enviar({
              destino,
              cuerpo1,
              cuerpo2,
              titulo
            })
            alert("El proveedor fue avisado por correo")
          }
          break;
        case 'Cambiar monto':
          try {
            let c = parseFloat(prompt('Introduzca la nueva cantidad', obj.metadatos.cantidad))
            console.log("Nueva cantidad?", c)
            obj.metadatos.cantidad = c
            obj.aprobada = false
            await Api.save('facturasingasto', obj)
            alert("Precio cambiado")
            this.obtener()
          } catch (e) {
            console.error(e)
            toast("No se pudo cambiar el precio")
          }
          break
        case 'Aprobar':
          try {
            if (obj.fechaProgramadoTentativo) {
              obj.fechaProgramado = obj.fechaProgramadoTentativo
              obj.fechaProgramadoTentativo = null
            }
            obj.aprobada = true
            obj.cancelada = 0
            obj.canceladaPorUsuario = false
            await Api.save('facturasingasto', obj)
            await this.configurarGasto(obj)
            alert("Pago para el " + this.$options.filters.moment(obj.fechaProgramado, 'MMMM DD'))
            this.obtener()
          } catch (e) {
            console.error(e)
          }
          break
        case 'comprobantePago':
          this.descargar(this.cdn + obj.archivoAdjuntoComprobantePago)
          break
        case 'Cambiar fecha':
          try {
            let fechaPago = await this.preguntarFechaPago()
            //obj.fechaProgramado = fechaPago
            //obj.aprobada = true
            //obj.cancelada = null
            console.log("fechaPago?", fechaPago)
            if (obj.aprobada == true)
              obj.fechaProgramado = fechaPago
            else {
              obj.fechaProgramadoTentativo = fechaPago
              alert2('Fecha tentativa cambiada. Aún debe aprobarse esta factura.')
            }
            //
            obj.canceladaPorUsuario = false
            await Api.save('facturasingasto', obj)
            alert("Fecha de pago programada: " + fechaPago)
            await this.configurarGasto(obj)
            this.obtener()
          } catch (e) {
            console.error("EFECHAPAGO", e)
            alert("Acción cancelada ")
          }
          break;
        case 'justificacion':
          this.descargar(this.cdn + obj.archivoAdjuntoJustificacion)
          //window.open(this.cdn + obj.archivoAdjuntoJustificacion, '_blank')
          break;
        case 'factura':
          this.descargar(this.cdn + obj.archivoAdjunto)
          //window.open(this.cdn + obj.archivoAdjunto, '_blank')
          break;
        case 'xml':
          this.descargar(this.cdn + obj.archivoAdjuntoXml)
          //  window.open(this.cdn + obj.archivoAdjuntoXml, '_blank')
          break;
        case 'Eliminar':
          if (true == confirm('Está seguro?')) {
            await Api.delete('facturasingasto', obj._id)
            alert("Eliminada")
            await Api.wait(1000)
            this.obtener()
          }
          break;
        default:
          console.log("Opción inválida")
          break;
      }
      obj.accion = ''
      this.$forceUpdate()
    },
    configurarGasto(o) {
      return new Promise(async (resolve, reject) => {
        let id = o._id
        let empresa = await Api.get('empresa')
        empresa = empresa[0] || {}
        console.log("configurar gasto? (empresa)", o, empresa.idPresupuestoDefault)
        let gasto = {
          _id: id,
          nombre: o.metadatos.concepto,
          etapas: [],
          idAutor: this.yo._id,
          idProveedor: o.idProveedor,
          fecha: o.fecha,
          fechaLimite: o.fecha,
          estado: 'aprobado',
          cantidadCotizada: parseFloat(o.metadatos.cantidad),
          limiteGasto: parseFloat(o.metadatos.cantidad),
          idSocioAprueba: 'XXXXXXXXXXXXXXXXXXXXXXXX',
          archivoAdjunto: o.archivoAdjuntoJustificacion,
          //
          idPresupuesto: empresa.idPresupuestoDefault,
        }
        let etapa = {
          _id: id,
          idGasto: id,
          idPresupuesto: empresa.idPresupuestoDefault,
          archivoAdjunto: o.archivoAdjunto,
          archivoAdjuntoFecha: o.archivoAdjuntoFecha,
          archivoAdjuntoXml: o.archivoAdjuntoXml,
          aprobadaPorSocio: true,
          cantidadErogable: parseFloat(o.metadatos.cantidad),
          fecha: o.fecha,
          fechaProgramado: o.fechaProgramado || o.fechaProgramada,
          idAutor: this.yo._id,
          idProveedor: o.idProveedor,
          idSocioAprueba: 'XXXXXXXXXXXXXXXXXXXXXXXX',
          nombre: o.metadatos.concepto,
          pagoDias: o.pagoDias,
          requiereAprobacionSocio: true,
          requiereEvidenciaAdjunta: true,
          terminada: false
        }
        await Api.delete('presupuestogasto', id)
        await Api.delete('gastoetapa', id)
        await Api.wait(500)
        await Api.save('presupuestogasto', gasto)
        await Api.save('gastoetapa', etapa)
        resolve(true)
      })
    },
    async preguntarFechaPago() {
      let vm = this
      return new Promise((resolve, reject) => {
        this.$ionic.modalController
          .create({
            component: ModalSeleccionarFechaPago,
            swipeToClose: true,
            componentProps: {
              parent: vm,
              propsData: {
                esModal: true
              }
            }
          })
          .then(m => {
            vm.modal = m
            vm.modal.present()
            Api.$on('fechaPagoSeleccionada', p => {
              vm.modal.dismiss()
              resolve(p)
            })
            Api.$on('fechaPagoCancelada', _ => {
              vm.modal.dismiss()
              reject(true)
            })
          })
      })
    },
    async eliminar(obj) {
      if (true == confirm('¿Está seguro?')) {
        await Api.delete('facturasingasto', obj._id)
        alert("Eliminada")
        await Api.wait(1000)
        this.obtener()
      }
    },
    async toggle(u, v) {
      for (let prop in this)
        if (prop.startsWith('mostrar') && prop != u)
          this[prop] = false
      this[u] = true
      console.log("toggle", u, this[u])
      this.$forceUpdate()
      await Api.wait(200)
      this.obtener()
    }
  },
  async mounted() {
    console.log("FacturasDeProveedoresSinGasto")
    console.log("Etapas?", await Api.find('gastoetapa', [], {
      limit: 5
    }))
    if (!this.permiso('AdministrarFacturasSinGasto'))
      this.$router.push('/')
    this.obtener()
    if (!Api.modoProduccion)
      alert("esMovil??? " + this.esMovil)
  }
}
</script>
