<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title @click="alertarPresupuestoExcedido(true)">Gasto</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="obtener">
          <ion-icon name="refresh" :color="colors('light')"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-row>
      <ion-col size-xs="12" size-md="6" size-lg="8">
        <ion-item lines="none">
          <ion-label>
            <h2>Información de<span v-if="gasto.estado=='aprobado'">l gasto</span><span v-else>&nbsp;la propuesta</span></h2>
          </ion-label>
        </ion-item>
        <ion-card v-if="gasto.idAutor!=yo._id">
          <ion-item lines="none">
            <ion-label>
              <h2>{{autor.nombre || '- Modo pruebas -'}}</h2>
              <p>Solicitante</p>
            </ion-label>
            <ion-note slot="end" color="medium">
              <i class="icon ion-ios-person icon-lg"></i>
            </ion-note>
          </ion-item>
        </ion-card>
        <ion-card>
          <ion-list>
            <ion-item>
              <ion-label text-wrap position="stacked">Nombre de la propuesta</ion-label>
              <ion-input class="" :value="gasto.nombre" v-on:ionChange="gasto.nombre=$event.detail.value"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label text-wrap position="stacked">Presupuesto origen</ion-label>
              <ion-select class="" :value="gasto.idPresupuesto" v-on:ionChange="setIdPresupuesto($event.detail.value)">
                <ion-select-option v-for="i in presupuestos" :key="i._id" :value="i._id">{{i.nombre}}</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item>
              <ion-label text-wrap position="stacked">Rubro</ion-label>
              <ion-select :value="gasto.rubro" v-on:ionChange="setRubro($event.detail.value)">
                <ion-select-option v-for="r in rubros" :key="r.nombre" :value="r.nombre">{{r.nombre}}</ion-select-option>
              </ion-select>
            </ion-item>
            <!--ion-item>
              <ion-label text-wrap position="stacked">Cliente (para repercutirle este gasto)</ion-label>
              <ion-select :value="gasto.idCliente" v-on:ionChange="gasto.idCliente=$event.detail.value">
                <ion-select-option :value"''">(Ninguno)</ion-select-option>
                <ion-select-option v-for="c in clientes" :value="c._id">{{c.nombre}}</ion-select-option>
              </ion-select>
            </ion-item-->
            <SelectorCliente :color="colors('primary')" :ocultarIcono="true" :idCliente="gasto.idCliente" label="Cliente (para repercutirle el gasto)" />
            <SelectorProveedor :color="colors('primary')" :ocultarIcono="true" :idProveedor="gasto.idProveedor" />
            <!--ion-row>
              <ion-col class="ion-no-padding">
                <ion-item>
                  <ion-label text-wrap position="stacked">Proveedor</ion-label>
                  <ion-select :value="gasto.idProveedor" v-on:ionChange="gasto.idProveedor=$event.detail.value">
                    <ion-select-option :value="''">(Ninguno seleccionado)</ion-select-option>
                    <ion-select-option v-for="r in proveedores" :value="r._id">{{r.nombre}}</ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
              <ion-col class="ion-no-padding" size-sm="2" size-xs="3" v-if="permiso('CrearProveedores')">
                <ion-button size="large" fill="clear" @click="modalProveedor" expand="block">
                  <i class="icon ion-ios-add-circle-outline icon-lg"></i>
                </ion-button>
              </ion-col>
            </ion-row-->
            <div>
              <ion-row>
                <ion-col class="ion-no-padding" :size="(gasto.archivoAdjunto||''!='')?10:12">
                  <label for="archivoAdjunto">
                    <input type="file" class="hidden" name="archivoAdjunto" id="archivoAdjunto" v-on:change="adjuntar()" />
                    <ion-item v-if="!gasto.archivoAdjunto">
                      <ion-label text-wrap>Haga clic para adjuntar una cotización</ion-label>
                      <ion-note slot="end" :color="colors('secondary')">
                        <i class="icon ion-ios-cloud-upload"></i>
                      </ion-note>
                    </ion-item>
                    <ion-item-sliding v-else>
                      <ion-item>
                        <!--ion-note slot="end" :color="colors('primary')">
                          <i class="icon ion-ios-attach icon-lg"></i>
                        </ion-note-->
                        <ion-label>
                          <h2>Cotización adjunta</h2>
                          <p>{{gasto.archivoAdjunto}}</p>
                          <p>Clic para cambiar</p>
                        </ion-label>
                      </ion-item>
                      <ion-item-options slot="end">
                        <ion-item-option @click="descargarArchivoAdjunto">Descargar </ion-item-option>
                        <ion-item-option :color="colors('danger')" @click="gasto.archivoAdjunto=null"><i class="icon ion-ios-trash"></i></ion-item-option>
                      </ion-item-options>
                    </ion-item-sliding>
                  </label>
                </ion-col>
                <ion-col class="ion-no-padding" v-if="(gasto.archivoAdjunto||'')!=''" size="2">
                  <ion-button :href="cdn+'/'+gasto.archivoAdjunto" target="_blank" :color="colors('primary')" fill="clear" size="large" expand="block">
                    <i class="icon ion-ios-eye icon-lg"></i>
                  </ion-button>
                </ion-col>
              </ion-row>
            </div>
            <ion-card v-if="gasto.archivoAdjunto && (esImagen(gasto.archivoAdjunto) || esPdf(gasto.archivoAdjunto))">
              <a :href="cdn+'/'+gasto.archivoAdjunto" target="_blank" v-if="esImagen(gasto.archivoAdjunto)">
                <ion-img :src="cdn+'/'+gasto.archivoAdjunto"></ion-img>
              </a>
              <embed v-if="esPdf(gasto.archivoAdjunto)" :src="cdn+'/'+gasto.archivoAdjunto" type="application/pdf" style="width:100%;min-height:50vh;" />
            </ion-card>
            <ion-item>
              <ion-label text-wrap>Descripción detallada</ion-label>
              <ion-textarea class="ion-text-right" rows="3" :value="gasto.descripcion" v-on:ionChange="gasto.descripcion=$event.detail.value"></ion-textarea>
            </ion-item>
            <ion-item v-if="gasto.idPresupuesto!='' && mostrarCampoCantidadSolicitada">
              <ion-label text-wrap position="stacked">Cantidad solicitada <span v-if="!modoProduccion && presupuesto && gasto.limiteGasto && gasto.limiteGastoRubroSeleccionado">(límite: {{gasto.limiteGastoRubroSeleccionado | currency}}
                  <span v-if="gasto.limiteGastoRubroSeleccionado>presupuesto.retenerGastos">, retenida/sin preaprobar a partir de: {{presupuesto.retenerGastos | currency}}</span>)</span></ion-label>
              <ion-input debounce="250" type="number" step="any" min="0" :value="gasto.cantidadCotizada" v-on:ionChange="setCantidadCotizada($event.detail.value)"></ion-input>
            </ion-item>
            <ion-item v-if="gasto.cantidadCotizada>gasto.limiteGasto" lines="none">
              <ion-note slot="start" :color="colors('danger')">
                <i class="icon ion-ios-information-circle-outline icon-lg"></i>
              </ion-note>
              <ion-label text-wrap :color="colors('danger')">
                <p>Debe aprobarse por socio director</p>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label text-wrap position="stacked">Fecha límite para considerar</ion-label>
              <ion-input class="" type="date" :value="gasto.fechaLimite" v-on:ionChange="gasto.fechaLimite=$event.detail.value"></ion-input>
            </ion-item>
            <ion-item v-if="(yo.rol=='administrador' || yo.tipo=='director')">
              <ion-label>Aprobado por socio director </ion-label>
              <ion-checkbox slot="end" v-on:ionChange="toggleAprobarPorSocio($event.detail.checked)" :checked="gasto.limiteGasto>=gasto.cantidadCotizada" />
            </ion-item>
            <!-- DONE validar mostrar en base a cantidad, si se pasa o no (y es socio director) -->
            <ion-item v-if="mostrarSelectorEstado()" :color="colors('light')" lines="none">
              <!--ion-item v-if="(permiso('AprobarGastos') && idEncargadoPresupuesto==yo._id)"-->
              <ion-label>Estado</ion-label>
              <ion-select v-on:ionChange="toggleAprobarPropuesta($event.detail.value)" :value="gasto.estado">
                <ion-select-option v-for="estado in ['aprobado','rechazado','pendiente']" :key="estado" :value="estado">{{estado.toUpperCase()}}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>
        </ion-card>
        <pre class="ion-text-left" v-if="!modoProduccion">
        {{gasto}}
        </pre>
      </ion-col>
      <ion-col size-xs="12" size-md="6" size-lg="4">
        <ion-item lines="none">
          <ion-label>
            <h2>
              <span v-if="modoEditarInline">Etapas</span>
              <span v-else>Esquema de pagos</span>
            </h2>
          </ion-label>
          <ion-button slot="end" v-if="modo=='configurar' && false" size="small" fill="outline" @click="setEtapasDefault">
            Defaults
          </ion-button>
          <ion-button slot="end" v-if="modo=='configurar'" size="small" fill="outline" @click="agregarEtapa">
            <i class="icon ion-ios-add-circle"></i>
          </ion-button>
        </ion-item>
        <ion-list>
          <ion-segment :value="modo" v-on:ionChange="modo=$event.detail.value" v-if="modoEditarInline">
            <ion-segment-button value="configurar">Esquema de pagos</ion-segment-button>
            <ion-segment-button value="gasto">Autorización del gasto</ion-segment-button>
          </ion-segment>
          <div v-if="(gasto.etapas||[]).length>0">
            <div v-if="modo=='gasto'">
              <div v-for="(g,indice) in gasto.etapas" :key="indice">
                <GastoEtapaToggle :etapa="g" />
              </div>
            </div>
            <div v-if="modo=='configurar'">
              <ion-card v-for="e in gasto.etapas" :key="e._id">
                <GastoEtapaConfigurar :etapa="e" :idGasto="gasto._id" :gasto="gasto" />
              </ion-card>
            </div>
          </div>
          <div v-else>
            <ion-card>
              <ion-list>
                <ion-item @click="agregarEtapa" lines="none">
                  <ion-label class="ion-text-center" text-wrap :color="colors('secondary')">
                    <h2>No hay un esquema de pagos</h2>
                    <p>Haga click aquí para agregar una etapa</p>
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-card>
          </div>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('danger')" @click="guardar" v-if="permiso('EditarGastos') || gasto.idAutor==yo._id">
        <i class="icon icon-lg ion-ios-save"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
import GastoEtapaConfigurar from '@/components/Presupuestos/GastoEtapaConfigurar'
import GastoEtapaToggle from '@/components/Presupuestos/GastoEtapaToggle'
import Proveedor from '@/models/Proveedor'
import Emailer from '@/models/Emailer'
//import CatalogoProveedor from '@/components/Catalogos/Proveedor'
import SelectorCliente from '@/components/SelectorCliente'
import SelectorProveedor from '@/components/SelectorProveedor'
export default {
  name: 'PresupuestosPartida',
  components: {
    GastoEtapaConfigurar,
    GastoEtapaToggle,
    //CatalogoProveedor,
    SelectorCliente,
    SelectorProveedor
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      modoProduccion: Api.modoProduccion,
      yo: Api.obtenerLocal('elite.yo') || {},
      idEncargadoPresupuesto: '',
      mostrarCampoCantidadSolicitada: false,
      gasto: {
        _id: Api.uuid(),
        nombre: '',
        etapas: [],
        idAutor: (Api.obtenerLocal('elite.yo') || {})._id,
        idProveedor: '',
        fecha: new Date().getTime(),
        fechaLimite: (new Date(new Date().getTime() + (1000 * 60 * 6024 * 15)).toISOString().substring(0, 10)),
        estado: 'pendiente',
        cantidadCotizada: 0,
        limiteGasto: 0
      },
      gastoNuevo: true,
      proveedores: [],
      modo: 'configurar',
      modal: null,
      etapasDefault: ['Anticipo', 'Liquidación', 'Diferencia'],
      modoEditarInline: false,
      //
      presupuestos: [],
      presupuesto: {},
      limiteGasto: 9999999999,
      rubros: [],
      autor: {},
      cdn: Api.cdn + '/'
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async setEtapasDefault() {
      console.log("setEtapasDefault")
      if (this.gasto.etapas.length == 0) {
        console.log("Insertando etapas default")
        for (let e of this.etapasDefault)
          this.gasto.etapas.push({
            nombre: e
          })
        alert("Configuradas etapas: " + this.etapasDefault.join(', '), null, 15000)
      } else {
        console.log("Eliminando etapas")
        this.gasto.etapas = []
        let etapas = await Api.find('gastoetapa', ['idGasto,eq,' + this.gasto._id])
        for (let e of etapas)
          await Api.delete('gastoetapa', e._id)
      }
      this.$forceUpdate()
    },
    async obtener() {
      let gasto = await Api.get('presupuestogasto', this.id)
      if (gasto && gasto._id)
        this.gastoNuevo = false
      gasto.autor = await Api.get('usuario', gasto.idAutor)
      gasto.presupuesto = await Api.get('presupuesto', gasto.idPresupuesto)
      this.idEncargadoPresupuesto = gasto.presupuesto.idUsuarioAsignado
      if (gasto.etapas && gasto.etapas.length > 0) {
        delete gasto.etapas
        await Api.save('presupuestogasto', gasto)
      }
      this.gasto.etapas = []
      //
      try {
        gasto.etapas = await Api.find('gastoetapa', ['idGasto,eq,' + gasto._id])
        console.log("Etapas?", gasto.etapas)
      } catch (e) {
        console.error(e)
      }
      console.log("Recuperado gasto", gasto)
      await this.setIdPresupuesto(gasto.idPresupuesto)
      this.gasto = gasto
      this.rubros = this.gasto.presupuesto.rubros
      this.$forceUpdate()
      return Promise.resolve(true)
    },
    async agregarEtapa() {
      let cantidadErogable = this.gasto.cantidadCotizada
      try {
        let etapas = await Api.find('gastoetapa', ['idGasto,eq,' + this.gasto._id])
        for (let e of etapas)
          cantidadErogable -= parseFloat(e.cantidadErogable)
      } catch (e) {
        console.error(e)
      }
      let numPago = (((this.gasto.etapas || []).length) + 1)
      if (numPago == 1)
        numPago = 'en una sola exhibición'
      let etapa = {
        requiereEvidenciaAdjunta: true,
        requiereAprobacionSocio: true,
        fechaLimite: this.$options.filters.moment(new Date().getTime() + (1000 * 60 * 60 * 24 * 30), 'YYYY-MM-DD'),
        cantidadErogable,
        idAutor: this.yo._id,
        idGasto: (this.id || '').length > 12 ? this.id : this.gasto._id,
        fecha: new Date().getTime(),
        nombre: 'Pago ' + numPago
      }
      etapa = await Api.save('gastoetapa', etapa)
      this.gasto.etapas.push(etapa)
      console.log("agregarEtapa")
      this.$forceUpdate()
    },
    toggleAprobarPropuesta(val) {
      this.gasto.estado = val
      if (val == true) {
        this.gasto.idUsuarioAprueba = this.yo._id
        this.gasto.fechaaprobado = new Date().getTime()
      } else {
        this.gasto.idUsuarioAprueba = ''
        this.gasto.fechaaprobado = 0
      }
      console.log("toggleAprobarPropuesta", val)
    },
    toggleAprobarPorSocio(val) {
      if (val == true) {
        this.gasto.limiteGasto = parseFloat(this.gasto.cantidadCotizada)
      } else {
        //this.gasto.limiteGasto = parseFloat(this.limiteGasto)
        // El límite de gasto va contra la retención del presupuesto o el límite del rubro, lo que resulte menor
        this.gasto.limiteGasto =
          Math.min(parseFloat((this.presupuesto || {}).retenerGastos || 1000000000), this.gasto.limiteGastoRubroSeleccionado)
      }
      console.log("Aprobado?", val,
        this.gasto.limiteGasto, this.gasto.limiteGastoRubroSeleccionado,
        ((this.presupuesto || {}).retenerGastos || 0),
        this.gasto.cantidadCotizada, this.presupuesto);
      if (this.yo.tipo == 'director' && val == true) {
        this.gasto.estado = 'aprobado'
        alert("Estado cambiado a Aprobado")
      }
      this.$forceUpdate()
    },
    setIdPresupuesto(val) {
      this.gasto.idPresupuesto = val
      console.log("setIdPresupuesto", val)
      for (let p of this.presupuestos) {
        if (p._id == val) {
          console.log("Encontrado presupuesto", p.nombre, p.retenerGastos, p)
          this.presupuesto = p
          this.rubros = (p.rubros || [])
          if (this.rubros.length == 1) {
            this.setRubro(this.rubros[0].nombre)
            console.log("Rubro del gasto", this.gasto.rubro)
          }
          this.limiteGasto = parseFloat(p.retenerGastos)
          this.gasto.limiteGasto = parseFloat(this.limiteGasto)
          console.log("Presupuesto seleccionado", this.gasto.limiteGasto, this.presupuesto)
        }
      }
      this.$forceUpdate()
      return Promise.resolve(true)
    },
    setRubro(r) {
      this.gasto.rubro = r
      for (let rubro of this.rubros)
        if (rubro.nombre == r && rubro.cantidad && parseFloat(rubro.cantidad) > 0) {
          this.gasto.limiteGasto = parseFloat(rubro.cantidad)
          this.gasto.limiteGastoRubroSeleccionado = parseFloat(rubro.cantidad)
        }
      console.log("setRubro", r, this.gasto.rubro, this.gasto.limiteGasto, this.gasto.limiteGastoRubroSeleccionado)
    },
    setCantidadCotizada(val) {
      this.gasto.cantidadCotizada = parseFloat(val)
      console.log("cantidadCotizada", this.gasto.cantidadCotizada, this.gasto.limiteGasto, this.presupuesto.retenerGastos)
      this.toggleAprobarPorSocio(
        Math.min(parseFloat(this.gasto.limiteGasto), parseFloat(parseFloat(this.presupuesto.retenerGastos) || 1000000000)) >=
        parseFloat(this.gasto.cantidadCotizada)
      )
      this.$forceUpdate()
    },
    async adjuntar() {
      this.gasto.archivoAdjunto = await Api.upload('archivoAdjunto')
      console.log("archivoAdjunto?", this.gasto.archivoAdjunto)
      this.$forceUpdate()
    },
    descargarArchivoAdjunto() {
      let url = this.cdn + this.gasto.archivoAdjunto
      window.open(url, '_blank')
    },
    async guardar() {
      let esquemaDePagosNoCoincide = false
      // Volver a obtener las etapas
      let etapas = await Api.find('gastoetapa', ['idGasto,eq,' + this.gasto._id])
      console.log("guardar reobtenidas etapas", etapas)
      if (etapas.length == 0) { // Quizá el gasto es nuevo
        etapas = JSON.parse(JSON.stringify(this.gasto.etapas || []))
        console.log("No se pudieron recuperar etapas. Usando las de esta vista.")
      }
      if ((etapas || []).length == 0)
        esquemaDePagosNoCoincide = false // El usuario no ha hecho un esquema, no lo molestes aún
      else {
        let sumaEtapas = etapas.reduce((suma, etapa) => suma + etapa.cantidadErogable, 0)
        let cantidadCotizada = this.gasto.cantidadCotizada
        console.log("esquemaDePagosNoCoincide?", {
          cantidadCotizada,
          sumaEtapas
        })
        esquemaDePagosNoCoincide = (cantidadCotizada != sumaEtapas)
      }
      if (this.gasto.nombre == '' || !this.gasto.nombre)
        alert("Falta nombre")
      else if (this.gasto.idPresupuesto == '')
        alert("Asigne el presupuesto indicado")
      else if (this.gasto.idProveedor == '')
        alert("Asigne un proveedor")
      else if (this.gasto.estado == 'aprobado' && this.gasto.limiteGasto < this.gasto.cantidadCotizada)
        alert("Un socio debe aprobar este gasto")
      else if (esquemaDePagosNoCoincide)
        alert("El esquema de pagos no coincide con la cantidad solicitada")
      else if ((etapas || []).length == 0) {
        alert("No existe esquema de pagos")
        this.agregarEtapa()
        this.$forceUpdate()
      } else {
        await Api.save('presupuestogasto', this.gasto)
        alert("Guardado")
        if (this.gasto.estado == 'aprobado')
          await this.alertarPresupuestoExcedido()
        window.history.go(-1)
      }
    },
    async alertarPresupuestoExcedido(probarAlerta) {
      probarAlerta = probarAlerta && true
      let cantidadTope = 1000000000,
        cantidadApartada = 0
      let presupuesto = await Api.get('presupuesto', this.gasto.idPresupuesto)
      cantidadTope = parseFloat(presupuesto.cantidadAsignada)
      let gastosAprobados = await Api.find('presupuestogasto', [
        'idPresupuesto,eq,' + presupuesto._id,
        'estado,eq,aprobado'
      ])
      for (let gasto of gastosAprobados)
        cantidadApartada += parseFloat(gasto.cantidadCotizada)
      console.log("cantidad excedida?", cantidadApartada, cantidadTope, presupuesto.tolerancia)
      if (probarAlerta || (cantidadApartada > (cantidadTope *
          //  ((1 - parseFloat(presupuesto.tolerancia || 0)) / 100))
          (1 - ((presupuesto.tolerancia || 0) / 100))
        )) //
      ) {
        // La tolerancia se ha alcanzado, a enviar correos a lo bruto
        let destinos = []
        // Obtener responsable del presupuesto
        let responsable = await Api.get('usuario', presupuesto.idUsuarioAsignado)
        destinos.push(responsable.email)
        // Obtener TODOS los directores y administradores
        let directores = (await Api.find('usuario', ['rol,eq,director']))
          .concat(await Api.find('usuario', ['tipo,eq,director']))
          .concat(await Api.find('usuario', ['rol,eq,administrador']))
          .map(u => u.email)
        destinos = destinos.concat(directores)
        // Enviar
        destinos = [...new Set(destinos)]
        for (let destino of destinos) try {
          await Emailer.enviar({
            titulo: 'Un presupuesto ha sobrepasado su tolerancia',
            cuerpo1: 'El presupuesto ' + presupuesto.nombre + ', a cargo de ' +
              responsable.nombre + ', ha alcanzado un nivel de vigilancia.',
            cuerpo2: 'Alcanzado: ' +
              this.$options.filters.currency(cantidadApartada) + ' / ' + this.$options.filters.currency(cantidadTope) +
              ' (' + this.$options.filters.currency(100 * cantidadApartada / cantidadTope, '') + '%)',
            destino
          })
        } catch (e) {
          console.error(e)
        }
        alert("Advertencia: El presupuesto ha sobrepasado su tolerancia")
      }
      if (this.gastoNuevo && this.gasto.idCliente && this.gasto.idCliente != '') try {
        let responsable = await Api.get('usuario', presupuesto.idUsuarioAsignado)
        let cliente = this.clientes.filter(c => this.gasto.idCliente == c._id)[0]
        await Emailer.enviar({
          titulo: 'Gasto nuevo, asociado a ' + cliente.nombre,
          cuerpo1: 'Un gasto llamado <b>' + this.gasto.nombre + '</b> ha sido asignado al cliente ' + cliente.nombre + ', por ' + this.$options.filters.currency(this.gasto.cantidadCotizada),
          cuerpo2: '',
          destino: responsable.email
        })
      } catch (e) {
        console.error("ECLIENTE")
      }
      return Promise.resolve(true)
    },
    esImagen(img) {
      return (img || '').toLowerCase().indexOf('.jpg') >= 0 ||
        (img || '').toLowerCase().indexOf('.jpeg') >= 0 ||
        (img || '').toLowerCase().indexOf('.png') >= 0
    },
    esPdf(img) {
      return (img || '').toLowerCase().indexOf('.pdf') >= 0
    },
    async modalProveedor() {
      let vm = this
      console.log("modalProveedor", this.etapa)
      return this.$ionic.modalController
        .create({
          component: CatalogoProveedor,
          swipeToClose: true,
          componentProps: {
            parent: vm,
            propsData: {
              esModal: true
            }
          }
        })
        .then(m => {
          vm.modal = m
          vm.modal.present()
          /*vm.modal.onDidDismiss(async _ => {
            alert("Descargando proveedores")
          })*/
          Api.$on('modalProveedorDismiss', async val => {
            console.log("modalProveedorDismiss", val)
            alert("Actualizando proveedores")
            vm.modal.dismiss()
            await Api.wait(1000)
            vm.proveedores = await Proveedor.lista()
            vm.gasto.idProveedor = val
          })
        })
    },
    mostrarSelectorEstado() {
      let mostrar = (this.permiso('AprobarGastos') ||
        this.idEncargadoPresupuesto == this.yo._id ||
        this.yo.tipo == 'director')
      // Ocultar el selector si la cantidad se pasa del presupuesto
      if (this.yo.tipo != 'director') {
        mostrar = mostrar &&
          (parseFloat(this.gasto.cantidadCotizada) <= parseFloat(this.presupuesto.retenerGastos))
      }
      /*
      if (false)
        console.log("mostrarSelectorEstado", {
          encargadoPresupuesto: this.idEncargadoPresupuesto == this.yo._id,
          director: this.yo.tipo == 'director',
          dentroDeLimite: parseFloat(this.gasto.cantidadCotizada) <= parseFloat(this.presupuesto.retenerGastos),
          cantidadCotizada: parseFloat(this.gasto.cantidadCotizada),
          retenerGastos: parseFloat(this.presupuesto.retenerGastos)
        })//*/
      return mostrar
    }
  },
  async mounted() {
    console.log("PresupuestosPartidas mounted")
    this.presupuestos = (await Api.get('presupuesto'))
      .filter(p => !p.recibeFacturasSingasto)
    this.proveedores = await Proveedor.lista()
    this.clientes = (await Api.get('cliente'))
      .sort((a, b) => a.nombre > b.nombre ? 1 : -1)
    if (this.id != '' && this.id != 0 && this.id != '0') { // Impedir que la interfaz modifique la cantidad
      await this.obtener()
      await Api.wait(100)
    }
    this.mostrarCampoCantidadSolicitada = true
    this.$forceUpdate()
    if (this.gasto.idAutor != this.yo._id)
      this.autor = await Api.get('usuario', this.gasto.idAutor)
    else
      this.autor = JSON.parse(JSON.stringify(this.yo))
    await Api.wait(1000)
    //this.setEtapasDefault()
    Api.$on('gastoEtapaModificada', this.obtener)
    // Selectores
    let vm = this
    Api.$on('clienteSeleccionado', id => {
      console.log("$on clienteSeleccionado", id)
      vm.gasto.idCliente = id
    })
    Api.$on('proveedorSeleccionado', id => {
      console.log("$on proveedorSeleccionado", id)
      vm.gasto.idProveedor = id
    })
  },
  beforeDestroy() {
    Api.$off('gastoEtapaModificada')
    Api.$off('modalProveedorDismiss')
    Api.$off('clienteSeleccionado')
    Api.$off('proveedorSeleccionado')
  }
}
</script>
