<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Pagos directos {{suma | currency}}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="obtener">
          <i class="icon ion-ios-refresh"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-row>
      <!--ion-col>
        <ion-item>
          <ion-label position="floating">Mostrar </ion-label>
          <ion-select :value="filtro" v-on:ionChange="cambiarFiltro($event.detail.value)">
            <ion-select-option>Todos</ion-select-option>
            <ion-select-option>Pendientes</ion-select-option>
            <ion-select-option>Pagados</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col-->
      <ion-col>
        <ion-item>
          <ion-label position="floating">Desde fecha</ion-label>
          <ion-input type="date" :value="fechaInicio" v-on:ionChange="fechaInicio=$event.detail.value"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-list>
      <ion-item-sliding v-for="p in pagosdirectos" :key="p._id" v-if="p && p.nombre">
        <ion-item @click="verPago(p)">
          <ion-label text-wrap>
            <h2>{{p.nombre || p.descripcion}}</h2>
            <p>{{p.fecha | moment('MMMM DD YYYY')}}</p>
            <p v-if="p.idAutor && p.idAutor!='' && p.idAutor!=yo._id && p.autor">{{p.autor.nombre}}</p>
            <p v-if="p.gasto && p.gasto.nombre && p.gasto.nombre!=p.nombre">Gasto: {{p.gasto.nombre}}</p>
            <p v-if="p.presupuesto && p.presupuesto._id">Presupuesto: {{p.presupuesto.nombre}}</p>
          </ion-label>
          <ion-note slot="end">
            {{p.cantidadErogable | currency}}
          </ion-note>
        </ion-item>
        <ion-item-options slot="end" v-if="!modoProduccion">
          <ion-item-option :color="colors('danger')" @click="eliminar(p)"><i class="icon ion-ios-trash icon-lg"></i></ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed" @click="verPago({})">
      <ion-fab-button>
        <i class="icon icon-lg ion-ios-add"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
import ModalPagoDirecto from '@/components/Presupuestos/ModalPagoDirecto'
export default {
  name: 'PresupuestosPagosDirectos',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      pagosdirectos: [],
      fechaInicio: new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 60)).toISOString().substring(0, 10),
      filtro: 'Pendientes',
      suma: 0,
      modal: null
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    cambiarFiltro(val) {
      this.filtro = val
      this.obtener()
    },
    async obtener() {
      this.suma = 0
      let filtros = [
        'directo,eq,1',
        'fecha,gte,' + new Date(this.fechaInicio).getTime()
      ]
      //filtros = []
      let etapas = await Api.find('gastoetapa', filtros, {
        sort: ['fecha,-1']
      })
      console.log("Obteniendo", filtros, etapas)
      /*
      if (false)
      {
        let filtro = this.filtro
        etapas = etapas
          .filter(e => {
            switch (filtro) {
              case 'Todos':
              default:
                return true
                break
            }
          })
      }//*/
      for (let e of etapas)
        this.suma += parseFloat(e.cantidadErogable)
      let gastos = await Api.get('presupuestogasto')
      let presupuestos = await Api.get('presupuesto')
      let usuarios = (await Api.get('usuario')).concat(await Api.get('directorio'))
      for (let id in etapas) {
        for (let g of gastos) {
          for (let p of presupuestos) {
            if (p._id == g.idPresupuesto && g._id == etapas[id].idGasto) {
              etapas[id].gasto = g
              etapas[id].presupuesto = p
              if (etapas[id].idUsuarioAprueba)
                for (let u of usuarios) {
                  if (etapas[id].idUsuarioAprueba == u._id)
                    etapas[id].socioAprueba = u
                  if (etapas[id].idAutor && etapas[id].idAutor != yo._id)
                    etapas[id].autor = u
                }
            }
          }
        }
      }
      this.pagosdirectos = etapas
      this.$forceUpdate()
      console.log("pagosdirectos", this.pagosdirectos)
    },
    async verPago(gastoetapa = {}) {
      if (gastoetapa._id)
        gastoetapa = await Api.get('gastoetapa', gastoetapa._id)
      let vm = this
      return this.$ionic.modalController
        .create({
          component: ModalPagoDirecto,
          swipeToClose: true,
          componentProps: {
            parent: vm,
            propsData: {
              gastoetapa
            }
          }
        })
        .then(m => {
          vm.modal = m
          vm.modal.present()
          vm.modal.onDidDismiss(_ => {
            console.log("Modal cerrado")
            vm.wait(500)
            vm.obtener()
          })
        })
    },
    async eliminar(p) {
      if (true == confirm('Desea eliminar este pago? El gasto permanecerá en la lista')) {
        await Api.delete('gastoetapa', p._id)
        await Api.wait(1000)
        this.obtener()
      }
    }
  },
  mounted() {
    console.log("Presupuestosaprobaciones mounted")
    this.obtener()
    let vm = this
    Api.$on('cerrarModalPagoDirecto', _ => {
      vm.modal.dismiss()
      vm.obtener()
    })
  },
  beforeDestroy() {
    Api.$off('cerrarModalPagoDirecto')
  }
}
</script>
