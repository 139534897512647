<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Hooks</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item-divider>Nuevo hook</ion-item-divider>
          <ion-item>
            <ion-label>Nombre</ion-label>
            <ion-input :value="webhook.nombre" v-on:ionChange="webhook.nombre=$event.detail.value"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Ejecutar cada</ion-label>
            <ion-input type="number" :value="webhook.periodicidadMedida" v-on:ionChange="webhook.periodicidadMedida=$event.detail.value;calcularPeriodicidad()"></ion-input>
            <ion-select :value="webhook.periodicidadMultiplicador" v-on:ionChange="webhook.periodicidadMultiplicador=$event.detail.value;calcularPeriodicidad()">
              <ion-select-option :value="0">Única vez</ion-select-option>
              <ion-select-option :value="1000*60">Minutos</ion-select-option>
              <ion-select-option :value="1000*60*60">Horas</ion-select-option>
              <ion-select-option :value="1000*60*60*24">Días</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label>Código</ion-label>
            <ion-textarea rows="10" :value="webhook.codigo" v-on:ionChange="procesarCodigo($event.detail.value)"></ion-textarea>
          </ion-item>
          <ion-row>
            <ion-col>
              <ion-button @click="guardar" expand="block" :disabled="bloquearGuardado || !permiso('EditarHooks')">Guardar</ion-button>
            </ion-col>
          </ion-row>
        </ion-list>
      </ion-col>
      <ion-col size="4" v-if="webhooks.length>0">
        <ion-list>
          <ion-item-divider>Hooks actuales</ion-item-divider>
          <ion-item-sliding v-for="w in webhooks" :key="w._id">
            <ion-item @click="ver(w)">
              <ion-label>
                <h2>{{w.nombre}}</h2>
                <p>Ejecutar cada {{w.periodicidad/(1000*60) | currency('')}}mins</p>
              </ion-label>
            </ion-item>
            <ion-item-options slot="end" @click="eliminar(w)">
              <ion-item-option :color="colors('danger')">Eliminar</ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </ion-list>
      </ion-col>
      <ion-col size="12" v-if="errores.length>0">
        <ion-list>
          <ion-item-divider>Errores</ion-item-divider>
          <ion-item v-for="e in errores" :key="e._id">
            <ion-label>
              <pre>{{e}}</pre>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
export default {
  name: 'Webhooks',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      webhooks: [],
      webhook: {},
      errores: [],
      interval: null,
      bloquearGuardado: false,
      modoProduccion: Api.modoProduccion
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async obtener() {
      this.webhooks = await Api.get('webhook')
      console.log("Webhooks?", this.webhooks)
    },
    async ver(w) {
      this.webhook = w
      console.log("Ver", w)
      this.$forceUpdate()
    },
    calcularPeriodicidad() {
      this.webhook.periodicidad = (this.webhook.periodicidadMedida || 0) * (this.webhook.periodicidadMultiplicador || 0)
    },
    procesarCodigo(val) {
      this.webhook.codigo = val
    },
    inicializar() {
      this.ver({
        idAutor: this.yo._id,
        periodicidad: 0,
        periodicidadMedida: 1,
        periodicidadMultiplicador: (1000 * 60 * 60 * 24),
        inicio: 0,
        codigo: '',
        db: Api.modoProduccion ? 'rvrs' : 'rvrstest',
        fecha: new Date().getTime(),
      })
    },
    async guardar() {
      this.bloquearGuardado = true
      let u = await Api.save('webhook', this.webhook)
      if (u._id)
        this.webhook._id = u._id
      this.obtener()
      let vm = this
      setTimeout(_ => {
        this.bloquearGuardado = false
      }, 2000)
    },
    async obtenerErrores() {
      this.errores = await Api.find('webhookerror', [], {
        sort: ['fecha,-1'],
        limit: 20
      })
      console.log("Errores?", this.errores)
    },
    async eliminar(w) {
      await Api.delete('webhookerror', w._id)
      this.obtener()
    }
  },
  mounted() {
    this.inicializar()
    this.obtener()
    this.obtenerErrores()
    this.interval = setInterval(this.obtenerErrores, 10000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>
