<template>
<ion-list v-if="!eliminada">
  <ion-progress-bar v-if="cargando" type="indeterminate"></ion-progress-bar>
  <ion-item color="medium" @click="clog(etapa2)">
    <ion-label position="floating">Nombre</ion-label>
    <ion-input debounce="250" :value="etapa2.nombre" v-on:ionChange="set('nombre',$event.detail.value)" :color="colors('primary')"></ion-input>
    <ion-button slot="end" @click="eliminar" :color="colors('danger')" fill="outline">
      <i class="icon ion-ios-remove-circle"></i>
    </ion-button>
  </ion-item>
  <ion-row>
    <ion-col class="ion-no-padding">
      <ion-item>
        <ion-label position="stacked">Cantidad</ion-label>
        <ion-input debounce="250" type="number" class="ion-text-left" :value="etapa2.cantidadErogable" :max="gasto.cantidadCotizada||99999999" min="0" v-on:ionChange="set('cantidadErogable',$event.detail.value)" :color="colors('primary')"></ion-input>
      </ion-item>
    </ion-col>
    <!--ion-col class="ion-no-padding">
      <ion-item>
        <ion-label position="stacked">Antes de</ion-label>
        <ion-input debounce="250" type="date" :value="etapa2.fechaLimite" v-on:ionChange="set('fechaLimite',$event.detail.value)" class="ion-text-left" :color="colors('primary')"></ion-input>
      </ion-item>
    </ion-col-->
  </ion-row>
  <!--ion-item>
    <ion-label text-wrap>Requiere aprobación de socio</ion-label>
    <ion-toggle debounce="250" :checked="etapa2.requiereAprobacionSocio" v-on:ionChange="set('requiereAprobacionSocio',$event.detail.checked==true)" slot="end"></ion-toggle>
  </ion-item>
  <ion-item>
    <ion-label text-wrap>Requiere evidencia adjunta</ion-label>
    <ion-toggle debounce="250" :checked="etapa2.requiereEvidenciaAdjunta" v-on:ionChange="set('requiereEvidenciaAdjunta',$event.detail.checked==true)" slot="end"></ion-toggle>
  </ion-item-->
  <ion-row v-if="gasto.estado=='aprobado'">
    <ion-col class="ion-no-padding" v-if="etapa2.fechaProgramado" size="12">
      <ion-item>
        <ion-label class="ion-text-center">
          <p>Programada para {{etapa2.fechaProgramado}}</p>
        </ion-label>
      </ion-item>
    </ion-col>
    <ion-col class="ion-no-padding" size="6">
      <ion-item lines="">
        <ion-note slot="end" @click="descargarArchivoAdjunto">
          <i class="icon icon-lg" :class="(etapa2.archivoAdjunto&&etapa2.archivoAdjunto!='')?'ion-ios-download':'ion-ios-attach'"></i>
        </ion-note>
        <label :for="'adj'+etapa2._id">
          <ion-label v-if="etapa2.archivoAdjunto" text-wrap>
            <h2>Factura/PDF</h2>
            <p>{{etapa2.archivoAdjunto}}</p>
            <p v-if="etapa2.archivoAdjuntoFecha">Factura subida el {{etapa2.archivoAdjuntoFecha | moment('MMM DD')}}</p>
          </ion-label>
          <ion-label v-else text-wrap>
            <h2>Click aquí para adjuntar factura (requerida)</h2>
          </ion-label>
          <input type="file" class="hidden" :name="'adj'+etapa2._id" :id="'adj'+etapa2._id" v-on:change="subirAdjunto()" />
        </label>
      </ion-item>
    </ion-col>
    <ion-col class="ion-no-padding" size="6">
      <ion-item lines="">
        <ion-note slot="end" @click="descargarXMLAdjunto">
          <i class="icon icon-lg" :class="(etapa2.archivoAdjuntoXml&&etapa2.archivoAdjuntoXml!='')?'ion-ios-download':'ion-ios-attach'"></i>
        </ion-note>
        <label :for="'adjxml'+etapa2._id">
          <ion-label v-if="etapa2.archivoAdjuntoXml" text-wrap>
            <h2>XML</h2>
            <p>{{etapa2.archivoAdjuntoXml}}</p>
            <p v-if="etapa2.archivoAdjuntoXmlFecha">Factura subida el {{etapa2.archivoAdjuntoXmlFecha | moment('MMM DD')}}</p>
          </ion-label>
          <ion-label v-else text-wrap>
            <h2>Click aquí para adjuntar XML</h2>
          </ion-label>
          <input type="file" class="hidden" :name="'adjxml'+etapa2._id" :id="'adjxml'+etapa2._id" v-on:change="subirAdjuntoXml()" />
        </label>
      </ion-item>
    </ion-col>
    <ion-col class="ion-no-padding" v-if="etapa2.complemento">
      <ion-item :href="cdn+'/'+etapa2.complemento" detail="false" target="_blank">
        <ion-label>
          <h2>Complemento de pago</h2>
          <p>{{etapa2.complemento}}</p>
          <p>Subido el {{etapa2.complementoFecha | moment('MMM DD')}}</p>
        </ion-label>
        <ion-note slot="end">
          <i class="icon ion-ios-download icon-lg"></i>
        </ion-note>
      </ion-item>
    </ion-col>
    <ion-col class="ion-no-padding" v-if="etapa2.archivoAdjuntoPagado">
      <ion-item :href="cdn+'/'+etapa2.archivoAdjuntoPagado" detail="false" target="_blank">
        <ion-label>
          <h2>Comprobante de pago</h2>
          <p>{{etapa2.archivoAdjuntoPagado}}</p>
          <p>Subido el {{etapa2.fechaTerminada | moment('MMM DD')}}</p>
        </ion-label>
        <ion-note slot="end">
          <i class="icon ion-ios-download icon-lg"></i>
        </ion-note>
      </ion-item>
    </ion-col>
  </ion-row>
  <!--pre>{{etapa2}}</pre-->
</ion-list>
<ion-item v-else>
  <ion-label class="ion-text-center" :color="colors('danger')">
    <h2>Eliminada: {{etapa2.nombre}}</h2>
  </ion-label>
</ion-item>
</template>
<script>
import Api from '@/components/Api'
import FechasPagoModel from '@/models/FechasPago'
import Xml from '@/models/Xml'
import Emailer from '@/models/Emailer'
import axios from 'axios'
export default {
  name: 'GastoEtapaConfigurar',
  props: {
    idGasto: {
      type: String,
      default: ''
    },
    etapa: {
      type: Object,
      default: function() {
        return {}
      }
    },
    gasto: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      etapa2: {},
      yo: Api.obtenerLocal('elite.yo') || {},
      eliminada: false,
      cargando: false,
      enviarSoloSiPdfYXmlFueronSubidos: true,
      cdn: Api.cdn + '/'
    }
  },
  methods: {
    setEtapa() {
      this.etapa2 = JSON.parse(JSON.stringify(this.etapa))
      this.etapa2.idGasto = this.idGasto
    },
    set(campo, valor) {
      this.etapa2[campo] = valor
      if (!isNaN(valor))
        this.etapa2[campo] = parseFloat(valor)
      console.log("set", campo, valor)
      this.guardar()
    },
    clog(e) {
      console.log("CLOG", e)
    },
    async guardar() {
      if (!(this.etapa2.nombre && this.etapa2.nombre != ''))
        this.etapa2.nombre = 'Pago en una exhibición'
      let u = await Api.save('gastoetapa', this.etapa2)
      console.log("Guardada etapa", this.etapa2)
      alert("Guardada")
      if (!this.etapa2._id)
        this.etapa2._id = u._id
      //Api.$emit('gastoEtapaModificada', this.etapa2)
      // Correo
      try {
        let destinatarios = []
        destinatarios.push(this.yo.email)
        let socioAprueba = await Api.get('usuario', this.etapa2.idAutor)
        destinatarios.push(socioAprueba.email)
        let presupuesto = await Api.get('presupuesto', this.gasto.idPresupuesto)
        let usuarioConPresupuestoAsignado = await Api.get('usuario', presupuesto.idUsuarioAsignado)
        destinatarios.push(usuarioConPresupuestoAsignado.email)
        destinatarios = [...new Set(destinatarios)]
        for (let destinatario of destinatarios) {
          console.log("Enviando a", destinatario)
          let correo = {
            titulo: 'CFDI para procesar de ' + this.yo.nombre,
            destino: destinatario,
            cuerpo1: 'El proveedor ' + this.yo.nombre + ' ha subido el XML de una factura para procesar a pago, que ya está disponible para ser procesada por Contabilidad.',
            cuerpo2: this.etapa2.nombre +
              ' (' + this.$options.filters.moment(this.etapa2.fechaSocioAprueba, 'MMMM DD YYYY') +
              '), por ' + this.$options.filters.currency(this.etapa2.cantidadErogable)
          }
          if (this.enviarSoloSiPdfYXmlFueronSubidos &&
            this.etapa2.archivoAdjunto &&
            this.etapa2.archivoAdjuntoXml)
            await Emailer.enviar(correo)
        }
        if (!this.etapa2.pagoDias || !this.etapa2.fechaProgramado) {
          await Api.save('gastoetapa', this.etapa2)
        }
      } catch (e) {
        console.error(e);
        alert("Error enviando correos")
      }
      return Promise.resolve(true)
    },
    async configurarFechaPago() {
      if (!this.etapa2.pagoDias && !this.etapa2.fechaProgramado) {
        let fechaSubido = this.etapa2.archivoAdjuntoXmlFecha || this.etapa2.archivoAdjuntoFecha
        let fechaDePago = await FechasPagoModel.encontrarFechaDePagoMasCercana(fechaSubido)
        console.log("fechaDePago", fechaDePago, fechaSubido)
        alertLegacy("Fecha de pago tentativa: " + fechaDePago.fechaProgramado)
        this.etapa2.pagoDias = fechaDePago.pagoDias
        this.etapa2.fechaProgramado = fechaDePago.fechaProgramado
      } else
        alert("Ya había sido configurada fecha de pago: " + this.etapa2.fechaProgramado)
      await Api.wait(100)
      return Promise.resolve(true)
    },
    async subirAdjunto() {
      this.cargando = true
      console.log("subirAdjunto?", this.etapa2._id)
      try {
        let u = await Api.upload('adj' + this.etapa2._id)
        this.etapa2.archivoAdjunto = u
        this.etapa2.archivoAdjuntoFecha = new Date().getTime()
        console.log("Subido?", this.etapa2.archivoAdjunto)
        await this.configurarFechaPago()
      } catch (e) {
        alert("No se pudo subir el archivo")
        console.error(e)
      }
      this.cargando = false
      await this.guardar()
    },
    async subirAdjuntoXml() {
      this.cargando = true
      console.log("subirAdjuntoXml?", this.etapa2._id)
      let xmlComprobable, permitirAdjuntarXml = false
      try {
        let u = await Api.upload('adjxml' + this.etapa2._id)
        xmlComprobable = await Api.upload('adjxml' + this.etapa2._id, 'binario')
        this.etapa2.archivoAdjuntoXml = u
        this.etapa2.archivoAdjuntoXmlFecha = new Date().getTime()
        console.log("Subido?", this.etapa2.archivoAdjuntoXml)
      } catch (e) {
        alert2("No se pudo subir el XML")
        console.error(e)
      }
      // Validar
      if (this.etapa2.archivoAdjuntoXml)
        try {
          permitirAdjuntarXml = await this.comprobarXml(xmlComprobable)
          console.log("permitirAdjuntarXml?", permitirAdjuntarXml)
          if (!permitirAdjuntarXml)
            throw new Error('XML rechazado por el usuario')
        } catch (e) {
          console.error(e)
          alert2("No se pudo validar el XML. No se guardarán los cambios.")
          this.etapa2.archivoAdjuntoXml = null
          this.etapa2.archivoAdjuntoXmlFecha = null
        }
      //
      this.cargando = false
      if (permitirAdjuntarXml) {
        await this.configurarFechaPago()
        await this.guardar()
      }
    },
    async comprobarXml(archivoAdjuntoXml) {
      archivoAdjuntoXml = archivoAdjuntoXml || ''
      let permitirAdjuntarXml = true
      let xmlRaw
      if (archivoAdjuntoXml.length > 50)
        xmlRaw = archivoAdjuntoXml
      else
        xmlRaw = (await axios.get(Api.cdn + '/' + archivoAdjuntoXml)).data
      let empresa = Api.obtenerLocal('elite.empresa') || {}
      console.log("xmlRaw?", xmlRaw, empresa)
      let xmlConvertido = await Xml.aObjeto(xmlRaw)
      console.log("xmlConvertido?", xmlConvertido)
      let rfcOrigen = xmlConvertido['cfdi:Comprobante']['cfdi:Emisor']['@Rfc']
      let rfcDestino = xmlConvertido['cfdi:Comprobante']['cfdi:Receptor']['@Rfc']
      //
      let idProveedor = ''
      let gasto = await Api.get('presupuestogasto', this.etapa2.idGasto)
      console.log("Gasto?", this.etapa2.idGasto, gasto)
      idProveedor = gasto.idProveedor
      let proveedor = await Api.get('usuario', idProveedor)
      console.log("proveedor", proveedor.nombre)
      //
      let rfcOrigenEsperado = proveedor.rfc
      let rfcDestinoEsperado = empresa.rfc
      if (rfcOrigen != rfcOrigenEsperado)
        permitirAdjuntarXml = confirm('El RFC del proveedor del XML (' + rfcOrigen + ') es distinto al esperado (' + rfcOrigenEsperado + '). ¿Desea continuar?')
      if (rfcDestino != rfcDestinoEsperado)
        permitirAdjuntarXml = confirm('El RFC del receptor del XML (' + rfcDestino + ') es distinto al esperado (' + rfcDestinoEsperado + '). ¿Desea continuar?')
      return Promise.resolve(permitirAdjuntarXml)
    },
    descargarArchivoAdjunto() {
      if (this.etapa2.archivoAdjunto && this.etapa2.archivoAdjunto.length > 0) {
        console.log("descargarArchivoAdjunto", this.etapa2.archivoAdjunto)
        window.open(Api.cdn + '/' + this.etapa2.archivoAdjunto, '_blank')
      }
    },
    descargarXMLAdjunto() {
      if (this.etapa2.archivoAdjuntoXml && this.etapa2.archivoAdjuntoXml.length > 0) {
        console.log("descargarArchivoAdjunto", this.etapa2.archivoAdjuntoXml)
        window.open(Api.cdn + '/' + this.etapa2.archivoAdjuntoXml, '_blank')
      }
    },
    async eliminar() {
      console.log("Eliminando", this.etapa2._id)
      await Api.delete('gastoetapa', this.etapa2._id)
      alert("Eliminada")
      this.eliminada = true
      await Api.wait(500)
      this.$forceUpdate()
      Api.$emit('gastoEtapaModificada')
    }
  },
  watch: {
    'etapa': function(a) {
      this.setEtapa()
    }
  },
  async mounted() {
    console.log("GastoEtapaConfigurar", this.id)
    this.setEtapa()
  }
}
</script>
