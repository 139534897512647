<script>
import Api from '@/components/Api'
import Vue from 'vue'
let ProveedorModel = new Vue({
  name: 'ProveedorModel',
  methods: {
    lista(rol, forzar, incluirTodos) {
      rol = rol || ''
      forzar = forzar && true
      incluirTodos = incluirTodos && true
      forzar = true // Pos bueno
      return new Promise(async (resolve, reject) => {
        let usuarios
        try {
          if (forzar == true)
            throw new Error('Lista de proveedores descarga forzada')
          usuarios = []
          for (let indice in localStorage)
            if (indice.startsWith('elite.usuario'))
              usuarios.push(Api.obtenerLocal(indice))
          console.log("Proveedores recuperados de cache?", usuarios.length)
          if (usuarios.length == 0)
            throw new Error('Lista de usuarios no en cache')
        } catch (e) {
          if (typeof e == 'string')
            console.log(e)
          else
            console.error(e)
          usuarios = (await Api.find('usuario', ['rol,eq,proveedor']))
            .filter(u => {
              if (incluirTodos)
                return true
              else
                return !(u.fechaBaja && !isNaN(u.fechaBaja))
            })
          // guardar en cache
          for (let u of usuarios) {
            Api.guardarLocal('elite.usuario.' + u._id, u)
          }
        }
        usuarios = usuarios
          .filter(u => {
            //return u.rol != 'administrador'
            return rol == '' || u.rol == rol || !Api.modoProduccion
          })
          .filter(u => u.rol == 'proveedor')
        console.log("Proveedores filtrados", rol, usuarios.length)
        usuarios = usuarios.sort((a, b) => {
          return (a.nombre || '').toLowerCase() > (b.nombre || '').toLowerCase() ? 1 : -1
        })
        resolve(usuarios)
      })
    },
    obtener(id, obtenerPassword) {
      obtenerPassword = obtenerPassword && true
      return new Promise(async (resolve, reject) => {
        try {
          let usuario = Api.obtenerLocal('elite.usuario.' + id)
          if (!(usuario && usuario._id))
            throw new Error('Cliente no en caché: ' + id)
          else {
            if (!obtenerPassword) {
              if (usuario.password) delete usuario.password
              if (usuario.password2) delete usuario.password2
            }
            resolve(usuario)
          }
        } catch (e) {
          let usuario = await Api.get('usuario', id)
          if (usuario.password)
            delete usuario.password
          if (usuario.password2)
            delete usuario.password2
          resolve(usuario)
        }
      })
    }
  },
  mounted() {
    console.log("ProveedorModel", Api.modoProduccion)
  }
})
export default ProveedorModel
</script>
