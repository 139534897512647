<script>
import axios from "axios";
import Vue from "vue";
const sha1 = require("sha1");
const defaultTimeout = 1500; /* Timeout para las consultas */
let Api = new Vue({
  name: "Api",
  data() {
    return {
      servidor: "https://app.haakon.cc:8083/elite",
      cdn: "https://app.haakon.cc:8083/files/", // TODO cambiar
      yo: {},
      WSTcolecciones: ["Producto", "Etiqueta"],
      cache: {},
      usarCache: false,
      modoPersistente: false,
      defaultUser: "admin@local",
      defaultPw: "password",
      modoProduccion: window.location.href.indexOf("localhost") < 0,
      //modoProduccion: window.location.href.indexOf("elite-abogados.mx") >= 0 || window.location.href.indexOf("elite.ittoni") >= 0,
    };
  },
  methods: {
    auth(entidad) {
      console.log("Autentificando", entidad.nombreLargo || entidad.email);
      this.yo = entidad;
      return null;
    },
    errorHandler(e) {
      let vm = this;
      let text = "";
      if (e && e.text) text = ": " + e.text;
      vm.error = "Error" + text;
      setTimeout(function () {
        delete vm.error;
      }, 1000);
      //    reject(e || {});
    },
    guardarLocal(lugar, obj) {
      //console.log("guardarLocal", lugar, obj)
      if (typeof obj == "object")
        localStorage.setItem(lugar, JSON.stringify(obj));
      else localStorage.setItem(lugar, obj);
    },
    obtenerLocal(lugar) {
      try {
        return JSON.parse(localStorage.getItem(lugar));
      } catch (e) {
        console.error("ELOCALSTORAGE", e);
        return localStorage.getItem(lugar);
      }
    },
    borrarLocal(lugar) {
      localStorage.removeItem(lugar);
    },
    esMovil() {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    ping(str) {
      str = str || vm.servidor;
      let vm = this;
      return new Promise((resolve, reject) => {
        try {
          axios
            .get(str, {
              headers: {
                "wst-appkey": "eliteabogados",
                token: vm.token(),
              },
            })
            .then(
              function (o) {
                if (o.data && typeof o.data.serialNumber)
                  vm.uuid = o.data.serialNumber;
                resolve(o);
              },
              function (e) {
                reject(e);
              }
            );
        } catch (e) {
          console.error(e);
          reject(e);
        }
      });
    },
    login(user, pass) {
      user = user || "";
      pass = sha1(pass || "");
      let vm = this;
      console.info("Login", user, pass);
      return new Promise((resolve, reject) => {
        let promises = [];
        let qp1 = axios.post(vm.servidor + "/login", {
          email: user,
          password: pass,
        });
        let qp2 = vm.find(
          "Directorio",
          ["email,eq," + user, "password,eq," + pass],
          {
            limit: 1,
          }
        );
        qp1
          .then(function (rJWT) {
            console.info(rJWT);
            if (rJWT && rJWT.data && rJWT.data._id && rJWT.data.jwt) {
              console.info("AUTH+JWT", rJWT.data);
              vm.guardarLocal("wst.yo", rJWT.data);
              resolve([rJWT.data]);
            } else console.error("Credenciales incorrectas JWT");
          })
          .catch(function (e) {
            qp2.then(function (rLeg) {
              if (rLeg && rLeg[0] && rLeg[0]._id) {
                console.info("AUTH+Legacy", rLeg);
                localStorage.setItem("wst.yo", rLeg[0]);
                resolve(rLeg);
              } else {
                console.error("EAUTH");
                reject({
                  error: "Error de autentificación",
                });
              }
            });
          });
      });
    },
    uuid() {
      return "xxxxxxxxxxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    dump() {
      console.error("Solicitado volcado de BD", new Date());
      let vm = this;
      return new Promise((resolve, reject) => {
        let ruta = vm.servidor + "/dump";
        axios
          .post(ruta, null, {
            headers: {
              "wst-appkey": "eliteabogados",
              token: vm.token(),
            },
          })
          .then(function (p) {
            let o = p.data || {};
            if (!o.error) resolve(o);
            else reject(o);
          });
      });
    },
    token() {
      let t = "";
      try {
        let k = JSON.parse(localStorage.getItem("wst.yo"));
        t = k.jwt || "";
      } catch (e) {
        // No se pudo recuperar el token
      }
      return t;
    },
    get(modelo, id, anularTimeout, subset) {
      console.log("GET", modelo, id);
      modelo = modelo || "";
      id = id || "";
      anularTimeout = anularTimeout && true;
      if (!this.modoOffline) {
        let vm = this;
        return new Promise(async (resolve, reject) => {
          let desdeCache = false;
          let ruta = vm.servidor + "/get/" + modelo;
          if (id != "") {
            ruta += "/" + id;
          }
          ruta += "?ts=" + new Date().getTime();
          if (subset && typeof subset == "object")
            ruta += "&subset=" + btoa(JSON.stringify(subset));
          //else {
          let options = {
            headers: {
              "wst-appkey": "eliteabogados",
              token: vm.token(),
            },
          };
          if (id != "" && !anularTimeout && defaultTimeout != 0) {
            options.timeout = defaultTimeout;
            //console.log("Timeout", id, options)
          }
          try {
            let o = await axios.get(ruta, options); //.then(function(o) {
            if (o && o.data) {
              if (!desdeCache) resolve(o.data);
              if (o.data._id) {
                // Guardar en cache el objeto individual
                if (!vm.cache[modelo]) vm.cache[modelo] = {};
                vm.cache[modelo][id] = o.data;
                //console.log("Cache app", modelo, o.data._id);
              } else if (
                (o.data.length > 0 &&
                  vm.WSTcolecciones.indexOf(modelo) != -1) || // Colección cacheable
                vm.tiempoCache != 0 // Cache habilitada desde servidor
              ) {
                if (!vm.cache[modelo]) vm.cache[modelo] = {};
                o.data.forEach(function (obj) {
                  if (obj._id) vm.cache[modelo][obj._id] = obj;
                });
                if (vm.usarCache) {
                  console.log(
                    "Actualizando cache en dispositivo",
                    modelo,
                    o.data.length
                  );
                  localStorage.setItem("hb3." + modelo, JSON.stringify(o.data));
                }
              }
            } else {
              //reject(o);
              throw new Error("No hay datos para mostrar");
            }
            /*}, function(e) {
              vm.errorHandler(e);
              reject(e);
            });*/
            //}
          } catch (e) {
            console.merror("EAPIGET " + modelo, e);
            reject(e);
          }
        });
      } else return ApiOffline.get(modelo, id);
    },
    find(modelo, params, adicional) {
      modelo = modelo || "";
      params = params || Array();
      adicional = adicional || Object();
      if (!this.modoOffline) {
        let vm = this;
        return new Promise((resolve, reject) => {
          let resultado = [];
          let enCache = false;
          let ruta = vm.servidor + "/get/" + modelo + "?";
          if (typeof params == "string") params = [params];
          if (params.length > 0) {
            params.forEach(function (param) {
              if (typeof param == "string") ruta += "filters[]=" + param + "&";
            });
          }
          if (typeof adicional.limit != "undefined")
            ruta += "limit=" + adicional.limit + "&";
          if (typeof adicional.skip != "undefined")
            ruta += "skip=" + adicional.skip + "&";
          if (typeof adicional.math != "undefined")
            ruta += "math=" + adicional.math + "&";
          if (typeof adicional.sort != "undefined") {
            for (let s in adicional.sort) {
              ruta += "sort=" + adicional.sort[s] + "&";
            }
          }
          if (typeof adicional.subset != "undefined")
            ruta += "subset=" + btoa(JSON.stringify(adicional.subset)) + "&";
          // Determinar si la consulta fue hecha previamente
          let candidato = "";
          candidato = localStorage.getItem("wstRuta." + btoa(ruta));
          if (
            candidato &&
            candidato.startsWith("[") &&
            candidato.length > 2 &&
            vm.tiempoCache > 0
          ) {
            enCache = true;
            let listaCache = JSON.parse(candidato);
            console.log("Consulta en caché. Rescatando.", listaCache.length);
            console.log(ruta);
            for (let c in listaCache) {
              let id = listaCache[c];
              if (vm.cache[modelo] && vm.cache[modelo][id])
                resultado.push(vm.cache[modelo][id]);
              else enCache = false;
            }
            console.warn("Cache", resultado);
            if (enCache) resolve(resultado);
          }
          // Hacer la consulta real
          try {
            axios
              .get(ruta, {
                headers: {
                  "wst-appkey": "eliteabogados",
                  token: vm.token(),
                },
              })
              .then(function (o) {
                if (typeof o.data != "undefined") {
                  resultado = o.data;
                  // Guardar en dispositivo
                  if (vm.tiempoCache && vm.tiempoCache > 0) {
                    // Respondo a tu llamado, joven elfo
                    let res = [];
                    // Cache de objetos
                    resultado.forEach(function (obj) {
                      res.push(obj._id);
                      vm.saveCache(modelo, obj);
                    });
                    // Cache de peticion
                    let encRuta = btoa(ruta);
                    //localStorage.setItem('wstRuta.' + encRuta, JSON.stringify(res));
                  }
                  // Ya
                  if (!enCache) resolve(resultado);
                } else reject(o);
              })
              .catch(function (e) {
                reject(e);
              });
          } catch (e) {
            console.merror("EAPIFIND", e);
            reject(e);
          }
        });
      } else {
        return ApiOffline.find(modelo, params, adicional);
      }
    },
    save(modelo, objeto) {
      console.log("Save", modelo, objeto);
      modelo = modelo || "";
      objeto = objeto || null;
      if (typeof objeto.__v != "undefined") delete objeto.__v;
      let vm = this;
      return new Promise((resolve, reject) => {
        if (objeto == null || modelo == "")
          reject("Debes especificar un modelo y un objeto a actualizar");
        let ruta = vm.servidor + "/upsert/" + modelo;
        if (!vm.modoPersistente) {
          // Guardado normal
          if (!objeto._id) {
            // Insertar
            axios
              .post(ruta, objeto, {
                headers: {
                  "wst-appkey": "eliteabogados",
                  token: vm.token(),
                },
              })
              .then(
                function (o) {
                  console.log("Insertado", o.data.objeto);
                  vm.saveCache(modelo, o.data.objeto);
                  resolve(o.data.objeto);
                },
                function (e) {
                  vm.errorHandler(e);
                  reject(e);
                }
              );
          } else {
            // Actualizar
            ruta += "/" + objeto._id;
            axios
              .post(ruta, objeto, {
                headers: {
                  "wst-appkey": "eliteabogados",
                  token: vm.token(),
                },
              })
              .then(
                function (o) {
                  console.log("Actualizado", o.data);
                  vm.saveCache(modelo, o.data.objeto);
                  resolve(o.data.objeto);
                },
                function (e) {
                  vm.errorHandler(e);
                  reject(e);
                }
              );
          }
        } else {
          // Guardado persistente
          console.log("Guardado persistente", modelo);
          if (!vm.cache[modelo]) {
            console.warn("Creando cache de objetos", modelo);
            vm.cache[modelo] = {};
          }
          if (!objeto._id) {
            // Insertar
            objeto._id = vm.uuid();
            console.warn("Asignando uuid", objeto._id);
            vm.cache[modelo][objeto._id] = objeto;
            resolve(objeto);
            axios
              .post(ruta, objeto, {
                headers: {
                  "wst-appkey": "eliteabogados",
                  token: vm.token(),
                },
              })
              .then(
                function (o) {
                  console.log("Insertado", o.data.objeto);
                  //resolve(o.data.objeto);
                },
                function (e) {
                  //reject(e);
                  setTimeout(function () {
                    vm.save(modelo, o.data.objeto); // Hazlo eternamente hasta que lo PINCHES GUARDES
                  }, 2000);
                }
              );
          } else {
            // Actualizar
            ruta += "/" + objeto._id;
            vm.cache[modelo][objeto._id] = objeto;
            resolve(objeto);
            axios
              .post(ruta, objeto, {
                headers: {
                  "wst-appkey": "eliteabogados",
                  token: vm.token(),
                },
              })
              .then(
                function (o) {
                  console.log("Actualizado", o.data);
                  //resolve(o.data.objeto);
                },
                function (e) {
                  //reject(e);
                  setTimeout(function () {
                    vm.save(modelo, objeto); // Hazlo eternamente hasta que lo PINCHES GUARDES
                  }, 2000);
                }
              );
          }
        }
      });
    },
    delete(modelo, id) {
      modelo = modelo || "";
      id = id || null;
      let vm = this;
      //let vm = this; return new Promise((resolve,reject)=>{
      let ruta = vm.servidor + "/" + modelo + "/" + id;
      if (vm.cache && vm.cache[modelo] && vm.cache[modelo][id])
        delete vm.cache[modelo][id];
      vm.deleteCache(modelo, id);
      return axios.delete(ruta, {
        headers: {
          "wst-appkey": "eliteabogados",
          token: vm.token(),
        },
      });
    },
    deleteMulti(modelo, lista) {
      lista = lista || [];
      modelo = modelo || "";
      let vm = this;
      let ruta = vm.servidor + "/multi/" + modelo + "/" + JSON.stringify(lista);
      lista.forEach(function (id) {
        vm.deleteCache(modelo, id);
      });
      return axios.delete(ruta, {
        headers: {
          "wst-appkey": "eliteabogados",
          token: vm.token(),
        },
      });
    },
    wait(t = 1000) {
      return new Promise((resolve) => {
        setTimeout((_) => {
          resolve(true);
        }, t);
      });
    },

    // Upload y download
    upload(handler, devolver, nombreAlternativo, omitirHash) {
      let vm = this;
      nombreAlternativo = nombreAlternativo || null;
      omitirHash = omitirHash && true;
      devolver = devolver || "";
      return new Promise((resolve, reject) => {
        if (["", "binario", "base64"].indexOf(devolver) == -1)
          reject('Valor de devolución no valido ("", binario o base64)');
        var identificador = "#" + handler;
        let h = document.querySelector(identificador).files[0];
        console.log("Cargando archivo del identificador", identificador);
        var fr = new FileReader();
        if (devolver == "base64") fr.readAsDataURL(h);
        // if(devolver=='' || devolver=='binario')
        else fr.readAsBinaryString(h);
        fr.onloadend = function () {
          let binario = fr.result;
          console.log("Bin", handler, binario.length);
          let ruta = vm.servidor + "/upload";
          if (devolver != "") resolve(binario);
          else
            axios
              .post(
                ruta,
                {
                  archivo: btoa(binario),
                  tipo: h.type,
                  nombre: h.name,
                  nombreAlternativo,
                  omitirHash,
                },
                {
                  headers: {
                    "wst-appkey": "eliteabogados",
                    token: vm.token(),
                  },
                }
              )
              .then(
                (o) => {
                  resolve(o.data.filename);
                },
                (e) => {
                  reject(e);
                }
              );
        };
        console.info("FileReader", h);
      });
    },
    download(str) {
      let ruta =
        str.indexOf("http") >= 0 ? this.servidor + "/download/" + str : str;
      return axios.get(ruta, {
        headers: {
          "wst-appkey": "eliteabogados",
          token: vm.token(),
        },
      });
    },
    downloadBase64(ruta) {
      let vm = this;
      return new Promise((resolve, reject) => {
        vm.download(ruta).then(
          (obj) => {
            let header = "data:application/binary;base64,";
            resolve(header + obj.data.data);
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
    async downloadForce(ruta, nombreArchivo) {
      ruta = ruta || "";
      nombreArchivo = nombreArchivo || ruta;
      let vm = this;
      let blob = ruta || "";
      if (ruta.indexOf("http") >= 0) blob = await vm.downloadBase64(ruta);
      else blob = "data:text/csv;base64," + btoa(blob);
      let a = document.createElement("a");
      a.href = blob;
      a.download = nombreArchivo;
      a.style.display = "none";
      a.style.visibility = "hidden";
      document.querySelector("body").appendChild(a);
      a.click();
    },
    // Rotator
    rotator(foto) {
      let vm = this;
      return new Promise((resolve, reject) => {
        if (foto.indexOf(vm.cdn) >= 0) foto = foto.replace(vm.cdn + "/", "");
        console.log("Pidiendo rotación de", foto);
        if (!foto || foto == "")
          reject({
            error: "No fue especificada la foto a girar",
          });
        axios
          .post(
            vm.servidor + "/rotator",
            {
              img: foto,
            },
            {
              headers: {
                "wst-appkey": "eliteabogados",
                token: vm.token(),
              },
            }
          )
          .then((p) => {
            if (p && p.data && p.data.nombre) resolve(p.data.nombre);
            else
              reject({
                error: "No se pudo obtener la nueva imagen rotada",
                raw: p,
              });
          })
          .catch((e) => {
            reject({
              error: "No se pudo recuperar la imagen rotada",
              raw: e,
            });
          });
      });
    },

    // Thumbnailer
    thumbnailer(handler, ratio, nombre, tamano) {
      let vm = this;
      //devolver = devolver || ''
      let devolver = "";
      ratio = ratio || 1;
      nombre = nombre || "";
      tamano = tamano || 500;
      return new Promise((resolve, reject) => {
        if (["", "binario", "base64"].indexOf(devolver) == -1)
          reject('Valor de devolución no valido ("", binario o base64)');
        var identificador = "#" + handler;
        let h = document.querySelector(identificador).files[0];
        console.log("Cargando archivo del identificador", identificador);
        var fr = new FileReader();
        if (devolver == "base64") fr.readAsDataURL(h);
        // if(devolver=='' || devolver=='binario')
        else fr.readAsBinaryString(h);
        fr.onloadend = function () {
          let binario = fr.result;
          console.log("Bin", handler, binario.length);
          let ruta = vm.servidor + "/thumbnailer";
          if (devolver != "") resolve(binario);
          else
            axios
              .post(
                ruta,
                {
                  imagen: btoa(binario),
                  tipo: h.type,
                  nombre: nombre || h.name,
                  tamano: tamano,
                  ratio: ratio,
                },
                {
                  headers: {
                    "wst-appkey": "eliteabogados",
                    token: vm.token(),
                  },
                }
              )
              .then(
                (o) => {
                  console.log("Thumbnailer?", o.data);
                  resolve(o.data.url);
                },
                (e) => {
                  reject(e);
                }
              );
        };
        console.info("FileReader", h);
      });
    },

    // Email
    email(obj) {
      console.log("Enviando correo", obj);
      let vm = this;
      return new Promise((resolve, reject) => {
        if (
          !obj.emailOrigen ||
          !obj.nombreOrigen ||
          !obj.mensaje ||
          !obj.emailDestino
        )
          reject({
            error: "Faltan datos",
            raw: "emailOrigen,nombreOrigen,mensaje,emailDestino",
          });
        else
          try {
            let ruta = this.servidor + "/email";
            axios
              .post(ruta, obj, {
                headers: {
                  "wst-appkey": "eliteabogados",
                  token: vm.token(),
                },
              })
              .then((r) => {
                resolve(r);
              });
          } catch (e) {
            console.error("ECORREO", e);
            reject({
              error: "No se pudo enviar el correo",
              raw: e,
            });
          }
      });
    },

    // Funciones de cache
    saveCache(modelo, obj) {
      let vm = this;
      if (modelo && obj) {
        console.log("Save cache", modelo, obj._id || "");
        if (vm.tiempoCache != 0 && modelo && obj._id && obj.fecha) {
          if (
            obj.fecha >= new Date().getTime() - vm.tiempoCache || // Está en rango de tiempo
            vm.WSTcolecciones.indexOf(modelo) != -1 // La app requiere que sea cacheable
          ) {
            if (!vm.cache[modelo]) vm.cache[modelo] = {};
            vm.cache[modelo][obj._id] = obj;
          }
        }
      }
    },
    deleteCache(modelo, id) {
      let vm = this;
      if (modelo && id && vm.cache[modelo] && vm.cache[modelo][id])
        delete vm.cache[modelo][id];
    },

    // Funciones especificas de HB2
    proyecto(id) {
      id = id || 0;
      let vm = this;
      return new Promise((resolve, reject) => {
        let ruta = vm.servidor + "/hummingbird2/proyecto/" + id;
        axios
          .post(ruta, null, {
            headers: {
              "wst-appkey": "eliteabogados",
              token: vm.token(),
            },
          })
          .then(
            function (o) {
              resolve(o.data);
              let cacheable = angular.copy(o.data);
              delete cacheable.autor;
              delete cacheable.empresa;
              delete cacheable.cliente;
              cacheable.partidas.forEach(function (partida) {
                delete partida.producto;
                vm.saveCache("Partida", partida);
              });
              cacheable.pagos.forEach(function (pago) {
                vm.saveCache("Pago", pago);
              });
              delete cacheable.partidas;
              delete cacheable.pagos;
              //localStorage.setItem('wstRuta.' + btoa(ruta), JSON.stringify(cacheable));
            },
            function (e) {
              let pp = localStorage.getItem("wstRuta." + btoa(ruta));
              if (pp && pp.startsWith("{")) {
                let proy = JSON.parse(pp);
                console.warn("Proyecto en cache", proy._id);
                proy.partidas = [];
                proy.pagos = [];
                for (let p in vm.cache.Partida) {
                  let partida = vm.cache.Partida[p];
                  if (partida.idProyecto == proy._id) {
                    partida.producto = {};
                    if (vm.cache.Producto[partida.idProducto])
                      partida.producto = vm.cache.Producto[partida.idProducto];
                    proy.partidas.push(partida);
                  }
                }
                for (let p in vm.cache.Pago) {
                  let pago = vm.cache.Pago[p];
                  if (pago.idProyecto == proy._id) proy.pagos.push(pago);
                }
                if (vm.cache.Directorio[proy.idAutor])
                  proy.autor = vm.cache.Directorio[proy.idAutor];
                if (vm.cache.Empresa[proy.idEmpresa])
                  proy.empresa = vm.cache.Empresa[proy.idEmpresa];
                if (vm.cache.Directorio[proy.idCliente])
                  proy.cliente = vm.cache.Directorio[proy.idCliente];
                resolve(proy);
              } else reject(e);
            }
          );
      });
    },
  },
  created() {
    // Modo produccion implica conectarse a otra BD
    this.servidor = "https://app.haakon.cc:8083/elite"; // TODO
    let estoyEnLaApp =
      window.location.href.indexOf("elite.ittoni") >= 0 ||
      window.location.href.indexOf("elite-abogados") >= 0;
    let forzarModoProduccion =
      this.obtenerLocal("elite.forzarModoProduccion") == 1 || estoyEnLaApp;
    console.log("forzarModoProduccion?", forzarModoProduccion);
    if (!this.modoProduccion && !forzarModoProduccion) {
      //this.servidor += 'test'
      this.servidor = "https://app.haakon.cc:8083/elitetest";
      console.log("Modo pruebas!", this.servidor);
    }
    if (estoyEnLaApp) this.modoProduccion = true;
    let vm = this;
    if (
      window.location.href.indexOf("/login") >= 0 ||
      window.location.href.endsWith("/")
    ) {
      console.log("Sesión temporal...", this.defaultUser);
      this.login(this.defaultUser, this.defaultPw)
        .then((yo) => {
          vm.guardarLocal("wst.yo", yo[0]);
          console.log("Encontrada sesión temporal", yo[0].jwt);
        })
        .catch((e) => {
          alert("No se pudo autentificar la aplicación");
          console.error(e);
        });
    } else {
      let u = this.obtenerLocal("elite.yo") || {};
      if (!u._id) {
        console.log("Borrando sesión temporal");
        this.borrarLocal("wst.yo");
      }
    }
  },
});
export default Api;
</script>
