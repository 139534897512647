<script>
import Api from '@/components/Api'
import Vue from 'vue'
let CobranzaModel = new Vue({
  name: 'CobranzaModel',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      empresa: Api.obtenerLocal('elite.empresa') || {},
      usuarios: []
    }
  },
  methods: {
    async calcularParaAsunto(asunto, guardar = false) {
      let actividades = [],
        saldo = 0,
        tiempoReal = 0,
        tiempoRepercutido = 0,
        esquemaCobranza = []
      try {
        // Obtener el asunto
        if (typeof asunto == 'string')
          asunto = (await Api.get('asunto', asunto))
        console.log("CobranzaModel.calcularParaAsunto", asunto.nombre)
        // Obtener las actividades
        actividades = (await Api.find('actividad', ['idAsunto,eq,' + asunto._id], {
            sort: ['fecha,1']
          }))
          .map(a => {
            if (a.tipo == 'horario')
              a.tipo = 'hora'
            return a
          })
        console.log("calcularParaAsunto Actividades", asunto.nombre, actividades)
        // Mapear el esquema de cobranza
        esquemaCobranza = (asunto.histogramaCobranza || [])
        if (esquemaCobranza.length == 0) {
          //throw new Error('No hay esquema de cobranza definido')
          esquemaCobranza = [{
            nombre: '(Sin esquema de cobranza)',
            tipo: 'gratuito',
            desdeFecha: this.$options.filters.moment(new Date().getTime() - (1000 * 60 * 60 * 24 * 365 * 10), 'YYYY-MM-DD'),
            hastaFecha: this.$options.filters.moment(new Date().getTime() + (1000 * 60 * 60 * 24 * 365 * 10), 'YYYY-MM-DD'),
            horas: {
              global: this.empresa.tarifas.global || 0
            }
          }]
          console.error("Esquema de cobranza ficticio", esquemaCobranza)
        }
        for (let e of esquemaCobranza) {
          if (!e.desdeFecha || !e.hastaFecha)
            throw new Error('Esquema de cobranza sin fechas definidas')
        }
        let poolActividadesConCobranza = []
        for (let indiceEsquema in esquemaCobranza) {
          esquemaCobranza[indiceEsquema].actividades = []
          for (let act of actividades) {
            const fechaActividad = (act.fechaProgramada || act.fecha)
            const fechaInicioEsquema = new Date(esquemaCobranza[indiceEsquema].desdeFecha).getTime()
            const fechaFinEsquema = new Date(esquemaCobranza[indiceEsquema].hastaFecha).getTime()
            console.log("Colocando", fechaActividad, fechaInicioEsquema, fechaFinEsquema)
            if (fechaInicioEsquema < fechaActividad &&
              fechaActividad < fechaFinEsquema &&
              poolActividadesConCobranza.indexOf(act._id) < 0) {
              console.log("Match de fecha!", fechaActividad, act.nombre, esquemaCobranza[indiceEsquema].tipo)
              poolActividadesConCobranza.push(act._id)
              act.tipo = esquemaCobranza[indiceEsquema].tipo
              esquemaCobranza[indiceEsquema].actividades.push(act)
            } else {
              console.log("Omitido", fechaActividad, fechaInicioEsquema, fechaFinEsquema, poolActividadesConCobranza)
            }
          }
        }
        console.log("esquemaCobranza pool?", esquemaCobranza, poolActividadesConCobranza)
        // Asignar las tarifas a cada actividad
        let vm = this
        esquemaCobranza = esquemaCobranza.map(e => {
          //e.esquemaCobranza = vm[e.tipo](e.esquemaCobranza)
          if (e.tipo == 'horario')
            e.tipo = 'hora'
          let str = 'e = vm.' + e.tipo + '(e);'
          eval(str)
          return e
        })
        // Calcular el saldo
        for (let e of esquemaCobranza)
          for (let a of e.actividades) {
            tiempoReal += parseFloat(a.minutos || 0)
            tiempoRepercutido += parseFloat(a.minutosCliente || a.minutos || 0)
            saldo += (parseFloat(a.importe || 0) || 0)
          }
        // Si hay que guardar, es el momento
        console.log("Actualizar actividades?", guardar, esquemaCobranza)
        if (guardar == true) {
          let actividadesGuardables = []
          for (let e of esquemaCobranza)
            actividadesGuardables = actividadesGuardables.concat(e.actividades || [])
          console.log("actividadesGuardables", actividadesGuardables)
          for (let a of actividadesGuardables) {
            let k = await Api.save('actividad', a)
            console.log("Actualizada actividad", k, a.descripcion || a.nombre)
          }
        }
        // Devolver
        return {
          actividades,
          saldo,
          tiempoReal,
          tiempoRepercutido,
          esquemaCobranza
        }
      } catch (e) {
        console.error("CobranzaModel.calcularParaAsunto", e)
        return e
      }
    },
    async calcularEquivalenteEnHoras(asunto) {
      if (typeof asunto == 'string')
        asunto = (await Api.get('asunto', asunto))
      asunto.esquemaCobranza = asunto.esquemaCobranza.map(e => {
        e.tipo = 'hora'
        return e
      })
      return this.calcularParaAsunto(asunto)
    },
    iguala(esquemaCobranza) {
      console.log("CobranzaModel.iguala", esquemaCobranza)
      // Sólo la primer actividad del mes se va a cobrar
      let mesCalculo = new Date(esquemaCobranza.desdeFecha).getTime()
      let mesesCalculados = []
      let fechaLimite = new Date(esquemaCobranza.hastaFecha).getTime()
      console.log("Calculando iguala", new Date(mesCalculo), new Date(fechaLimite), esquemaCobranza)
      while (mesCalculo < fechaLimite) {
        for (let indiceActividad in esquemaCobranza.actividades) {
          console.log("Mapeando actividad en iguala", indiceActividad, esquemaCobranza.actividades)
          let labelMesCalculo = new Date(mesCalculo).toISOString().substring(0, 7)
          let labelMesActividad = new Date(esquemaCobranza.actividades[indiceActividad].fechaProgramada).toISOString().substring(0, 7)
          if (labelMesCalculo == labelMesActividad && mesesCalculados.indexOf(labelMesCalculo) < 0) {
            mesesCalculados.push(labelMesCalculo) // Asegurando sólo la primer actividad
            esquemaCobranza.actividades[indiceActividad].importe = parseFloat(esquemaCobranza.monto)
            esquemaCobranza.actividades[indiceActividad].monto = parseFloat(esquemaCobranza.monto)
          }
        }
        let siguienteMes = new Date(mesCalculo + (1000 * 60 * 60 * 24 * 35))
        siguienteMes.setDate(1)
        siguienteMes.setHours(8)
        siguienteMes.setMinutes(0)
        siguienteMes.setSeconds(0)
        siguienteMes.setMilliseconds(0)
        console.log("siguienteMes?", new Date(siguienteMes), new Date(mesCalculo))
        mesCalculo = siguienteMes.getTime()
        if (mesCalculo == siguienteMes) {
          alert("NO se sumó la fecha del mesCalculo")
          mesCalculo = fechaLimite
        }
      }
      console.log("esquemaCobranza iguala", esquemaCobranza, esquemaCobranza.monto)
      return esquemaCobranza
    },
    asunto(esquemaCobranza) {
      // Asignar el importe a la primer actividad, el resto es 0
      if (esquemaCobranza.actividades.length == 0)
        esquemaCobranza.actividades = [{
          nombre: '(Actividades del asunto)',
          fechaProgramada: new Date().getTime()
        }]
      for (let i in esquemaCobranza.actividades) {
        esquemaCobranza.actividades[i].precioUnitario = 0
        esquemaCobranza.actividades[i].importe = 0
        esquemaCobranza.actividades[i].monto = 0
      }
      esquemaCobranza.actividades[0].precioUnitario = parseFloat(esquemaCobranza.monto)
      esquemaCobranza.actividades[0].importe = parseFloat(esquemaCobranza.monto)
      esquemaCobranza.actividades[0].monto = parseFloat(esquemaCobranza.monto)
      console.log("esquemaCobranza asunto", esquemaCobranza, esquemaCobranza.monto)
      return esquemaCobranza
    },
    hora(esquemaCobranza) {
      console.log("CobranzaModel.hora", esquemaCobranza.desdeFecha, (esquemaCobranza.actividades || []).length)
      const tarifas = this.empresa.tarifas
      // Hacer un mapa de usuarios con tarifa por rol
      let mapaUsuarios = {}
      for (let u of this.usuarios)
        mapaUsuarios[u._id] = u
      // Asignar a cada tarea su tarifa, o la global en su defecto
      esquemaCobranza.actividades = esquemaCobranza.actividades
        .map(a => {
          a.autor = (mapaUsuarios[a.idAutor] || {})
          a.nombreAutor = a.autor.nombre
          let tipoUsuario = a.autor.tipo || 'global'
          if (!this.empresa.tarifas[tipoUsuario])
            tipoUsuario = 'global'
          a.importe = 0
          let fraccionDeHora = parseFloat(a.minutosCliente || a.minutos) / 60
          console.log("map hora?", fraccionDeHora, tipoUsuario,
            esquemaCobranza.horas[tipoUsuario], tarifas[tipoUsuario])
          a.precioUnitario = parseFloat(esquemaCobranza.horas[tipoUsuario]) ||
            parseFloat(tarifas[tipoUsuario] || 0) || 0
          a.importe = fraccionDeHora * (a.precioUnitario || 0)
          a.monto = a.importe
          console.log("map hora", fraccionDeHora, a.precioUnitario, a.importe)
          return a
        })
      // Devolver
      console.log("esquemaCobranza hora", esquemaCobranza)
      return esquemaCobranza
    },
    gratuito(esquemaCobranza) {
      esquemaCobranza.actividades = (esquemaCobranza.actividades || [])
        .map(a => {
          a.importe = 0
          a.monto = 0
          a.precioUnitario = 0
          return a
        })
      console.log("esquemaCobranza gratuito", esquemaCobranza)
      return esquemaCobranza
    },
    probono(esquemaCobranza) {
      return this.gratuito(esquemaCobranza)
    }
  },
  async created() {
    console.log("CobranzaModel created")
    this.usuarios = (await Api.get('usuario'))
      .sort((a, b) => a.nombre > b.nombre ? 1 : -1)
  }
})
export default CobranzaModel
</script>
