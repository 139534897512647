<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-title>Pago del gasto</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="cerrar">
          <i class="icon ion-ios-close-circle"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" v-if="cargando"></ion-progress-bar>
  </ion-header>
  <ion-content>
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-item :color="colors('secondary')">
          <ion-label>
            <h2>Etapa: {{etapa2.nombre}}</h2>
            <p>Creado por {{(autor||{}).nombre}}</p>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="12" :size-sm="((etapa2.archivoAdjuntoPagado && etapa2.archivoAdjuntoPagado.length>0)||(etapa2.archivoAdjunto && etapa2.archivoAdjunto.length>0))?6:12">
        <ion-list>
          <ion-item-divider>Configurar</ion-item-divider>
          <ion-item>
            <ion-label>Cantidad programada</ion-label>
            <ion-input type="number" class="ion-text-right" :readonly="yo.rol!='administrador'" :value="etapa2.cantidadErogable" v-on:ionChange="etapa2.cantidadErogable=parseFloat($event.detail.value)"></ion-input>
          </ion-item>
          <!--ion-item>
            <ion-label>Cantidad entregada</ion-label>
            <ion-input class="ion-text-right" :value="etapa2.cantidadErogada" v-on:ionChange="etapa2.cantidadErogada=parseFloat($event.detail.value)"></ion-input>
          </ion-item-->
          <!--ion-item v-if="etapa2.requiereAprobacionSocio">
            <ion-label>Aprobada por Socio</ion-label>
            <ion-checkbox :checked="etapa2.aprobadaPorSocio==true" v-on:ionChange="aprobarPorSocio($event.detail.checked==true)" slot="end"></ion-checkbox>
          </ion-item-->
          <ion-item v-if="typeof etapa2.terminada != 'undefined'">
            <ion-label>Terminada</ion-label>
            <ion-checkbox :checked="etapa2.terminada==true" v-on:ionChange="etapa2.terminada=($event.detail.checked==true)" slot="end"></ion-checkbox>
          </ion-item>
          <!-- Tarjeta del proveedor -->
          <ion-card v-if="proveedor && proveedor.nombre">
            <ion-item lines="none">
              <ion-note slot="start">
                <i class="icon ion-ios-person icon-lg"></i>
              </ion-note>
              <ion-label text-wrap>
                <h2>{{proveedor.nombre}}</h2>
                <p>Banco: {{proveedor.bancoNombre}} {{proveedor.bancoCuenta}}<span v-if="proveedor.bancoClabe">, CLABE {{proveedor.bancoClabe}}</span></p>
                <p>
                  <span v-if="proveedor.opinionDeCumplimientoValida"><i class="icon ion-ios-checkmark-circle"></i>&nbsp;Opinión de cumplimiento</span>
                  <span v-else><i class="icon ion-ios-remove-circle-outline"></i>&nbsp;Falta opinión de cumplimiento</span>
                  <span v-if="(proveedor.debeComplementosDePago||[]).length>0"><i class="icon ion-ios-remove-circle-outline"></i>&nbsp;Debe complementos ({{proveedor.debeComplementosDePago.length}})</span>
                  <span v-else></span>
                </p>
              </ion-label>
              <ion-button slot="end" :href="'mailto:'+proveedor.email" fill="solid" :color="colors('warning')">
                <i class="icon ion-ios-mail"></i>
              </ion-button>
            </ion-item>
            <ion-item v-for="etapa in proveedor.debeComplementosDePago" v-if="proveedor.debeComplementosDePago" :key="etapa._id">
              <ion-note slot="start">
                <i class="icon ion-ios-arrow-forward icon-lg"></i>
              </ion-note>
              <ion-label>
                <h2>{{etapa.nombre}}</h2>
                <p>{{etapa.fecha | moment('MMM DD')}}</p>
                <p>Falta complemento</p>
              </ion-label>
              <ion-note slot="end">{{etapa.cantidadErogable | currency}}</ion-note>
              <ion-button slot="end" v-if="etapa.archivoAdjunto" :color="colors('primary')" target="_blank" :href="cdn+'/'+etapa.archivoAdjunto">
                <i class="icon ion-ios-cloud-download"></i>
              </ion-button>
            </ion-item>
          </ion-card>
          <!-- -->
          <label for="adj" v-if="etapa2.requiereEvidenciaAdjunta">
            <ion-card>
              <ion-item-divider>Comprobante de pago</ion-item-divider>
              <ion-item>
                <ion-note slot="start"><i class="icon icon-lg" :class="(etapa2.archivoAdjuntoPagado&&etapa2.archivoAdjuntoPagado!='')?'ion-ios-document':'ion-ios-attach'"></i></ion-note>
                <ion-label v-if="etapa2.archivoAdjuntoPagado" text-wrap>{{etapa2.archivoAdjuntoPagado}}</ion-label>
                <ion-label v-else text-wrap>Click aquí para adjuntar comprobante de pago</ion-label>
              </ion-item>
              <input type="file" class="hidden" name="adj" id="adj" v-on:change="subirAdjunto()" />
            </ion-card>
          </label>

        </ion-list>
        <ion-card v-if="etapa2.archivoAdjunto">
          <ion-item-divider>Factura adjunta</ion-item-divider>
          <ion-item :href="cdn+'/'+etapa2.archivoAdjunto" target="_blank">
            <ion-label text-wrap>
              <h2>{{etapa2.archivoAdjunto}}</h2>
              <p v-if="etapa2.archivoAdjuntoFecha">Subida el {{etapa2.archivoAdjuntoFecha | moment('MMM DD HH:mm')}}</p>
              <p>Clic para ver factura</p>
            </ion-label>
          </ion-item>
        </ion-card>
        <ion-card v-if="etapa2.archivoAdjuntoXml">
          <ion-item-divider>XML de factura adjunto</ion-item-divider>
          <ion-item :href="cdn+'/'+etapa2.archivoAdjuntoXml" target="_blank">
            <ion-label text-wrap>
              <h2>{{etapa2.archivoAdjuntoXml}}</h2>
              <p v-if="etapa2.archivoAdjuntoXmlFecha">Subida el {{etapa2.archivoAdjuntoXmlFecha | moment('MMM DD HH:mm')}}</p>
              <p>Clic para descargar XML</p>
            </ion-label>
          </ion-item>
        </ion-card>
        <ion-card v-if="etapa2.complemento && etapa2.complementoFecha">
          <ion-item-divider>Complemento de pago</ion-item-divider>
          <ion-item :href="cdn+'/'+etapa2.complemento" target="_blank">
            <ion-label text-wrap>
              <h2>
                {{etapa2.complemento}}
              </h2>
              <p v-if="etapa2.complementoFecha">Subida el {{etapa2.complementoFecha | moment('MMM DD HH:mm')}}</p>
              <p>Clic para descargar</p>
            </ion-label>
          </ion-item>
        </ion-card>
        <ion-list v-if="yo.rol=='administrador' && historial.length>0">
          <ion-item-divider>Modificaciones</ion-item-divider>
          <ion-item v-for="m in historial" :key="m._id">
            <ion-label text-wrap>{{m.mensaje.replace('Ggasto modificado por ','')}}</ion-label>
            <ion-note slot="end">{{m.fecha | moment('MM-DD HH:mm')}}</ion-note>
          </ion-item>
        </ion-list>
      </ion-col>
      <ion-col size-xs="12" v-if="etapa2.archivoAdjuntoPagado" :size-sm="((etapa2.archivoAdjuntoPagado && etapa2.archivoAdjuntoPagado.length>0)||(etapa2.archivoAdjunto && etapa2.archivoAdjunto.length>0))?6:12">
        <a :href="cdn+'/'+etapa2.archivoAdjuntoPagado" target="_blank" v-if="esImagen(etapa2.archivoAdjuntoPagado)">
          <ion-img :src="cdn+'/'+etapa2.archivoAdjuntoPagado"></ion-img>
        </a>
        <embed v-if="esPdf(etapa2.archivoAdjuntoPagado)" :src="cdn+'/'+etapa2.archivoAdjuntoPagado" type="application/pdf" width="100%" height="100%" />
      </ion-col>
      <ion-col v-if="etapa2.archivoAdjunto" size="6">
        <a :href="cdn+'/'+etapa2.archivoAdjuntoPagado" target="_blank" v-if="esImagen(etapa2.archivoAdjuntoPagado)">
          <ion-img :src="cdn+'/'+etapa2.archivoAdjunto"></ion-img>
        </a>
        <embed v-if="esPdf(etapa2.archivoAdjunto)" :src="cdn+'/'+etapa2.archivoAdjunto" type="application/pdf" width="100%" height="100%" />
      </ion-col>
    </ion-row>
  </ion-content>
  <ion-footer>
    <ion-row>
      <ion-col size="4" v-if="this.etapa2.terminada" @click="enviarCorreos">
        <ion-button size="small" expand="block" :color="colors('tertiary')" @click="enviarCorreos">Enviar</ion-button>
      </ion-col>
      <ion-col>
        <ion-button size="small" expand="block" :color="colors('success')" @click="terminar" :disabled="bloquearMarcarComoPagado || !permiso('EmitirPagos')">
          <span v-if="bloquearMarcarComoPagado">Adjunte comprobante para completar</span>
          <span v-else>
            <span v-if="permiso('EmitirPagos')">Marcar como pagado</span>
            <span v-else>No tiene permisos: EmitirPagos</span>
          </span>
        </ion-button>
      </ion-col>
    </ion-row>
    <!--ion-item v-if="bloquearMarcarComoPagado">
      <ion-label :color="colors('warning')" class="ion-text-center">
        <h2><i class="icon ion-ios-information-circle"></i>&nbsp;El gasto no está listo para ser pagado</h2>
      </ion-label>
    </ion-item-->
  </ion-footer>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
import Emailer from '@/models/Emailer'
export default {
  name: 'ModalGastoEtapa',
  props: {
    etapa: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      etapa2: {},
      autor: {},
      proveedor: {},
      modoProduccion: Api.modoProduccion,
      yo: Api.obtenerLocal('elite.yo'),
      cdn: Api.cdn,
      historial: [],
      cargando: false,
      bloquearMarcarComoPagado: false
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async guardar() {
      await Api.save('gastoetapa', this.etapa2)
      await Api.save('bitacora', {
        mensaje: 'Gasto modificado por ' + this.yo.email,
        fecha: new Date().getTime(),
        idGastoetapa: this.etapa2._id
      })
      alert("Guardado")
      Api.$emit('cerrarModalGastoEtapa', true)
    },
    terminar() {
      this.etapa2.terminada = true
      this.etapa2.fechaTerminada = new Date().getTime()
      this.guardar()
      this.enviarCorreos()
    },
    async enviarCorreos() {
      console.log("Enviando correos")
      try {
        let destinatarios = []
        let pago = this.etapa2
        let proveedor, gasto, autor, presupuesto, socioResponsable, correoProveedor
        console.log("Pago?", pago)
        gasto = await Api.get('presupuestogasto', pago.idGasto)
        autor = await Api.get('usuario', gasto.idAutor)
        destinatarios.push(autor.email)
        presupuesto = await Api.get('presupuesto', gasto.idPresupuesto)
        if (gasto.idProveedor) {
          proveedor = await Api.get('usuario', gasto.idProveedor)
          correoProveedor = proveedor.email
          destinatarios.push(proveedor.email)
          if (proveedor.emailAlternativo)
            destinatarios.push(proveedor.emailAlternativo)
        }
        if (presupuesto.idUsuarioAsignado) {
          socioResponsable = await Api.get('usuario', presupuesto.idUsuarioAsignado)
          destinatarios.push(socioResponsable.email)
        }
        destinatarios = [...new Set(destinatarios)]
        console.log("Destinatarios ", destinatarios)
        for (let destino of destinatarios) try {
          let correo = {
            titulo: 'Pago realizado' + ((proveedor && proveedor._id) ? (', para ' + proveedor.nombre) : ''),
            cuerpo1: 'El pago  <i>' + pago.nombre + '</i>, del gasto ' + gasto.nombre + ', ha sido realizado.',
            cuerpo2: '' + this.$options.filters.moment(new Date(), 'MMMM DD, YYYY') + ' por ' + this.$options.filters.currency(pago.cantidadErogable),
            destino
          }
          if (correoProveedor && destino == correoProveedor)
            correo.cuerpo2 += '<br />RECUERDE: Es necesario que suba su complemento de pago para esta factura. De lo contrario no podremos realizarle pagos posteriores. '
          await Emailer.enviar(correo)
        } catch (e) {
          console.error(e)
        }
        alert("Correos enviados")
      } catch (e) {
        console.error(e)
        alert("No se pudieron enviar algunos correos ")
      }
    },
    async subirAdjunto() {
      this.cargando = true
      let u = await Api.upload('adj')
      console.log("Subido?", u)
      this.etapa2.archivoAdjuntoPagado = u
      this.cargando = false
      //this.calcularBloquearMarcarComoPagado()
      this.bloquearMarcarComoPagado = false
      this.$forceUpdate()
    },
    async aprobarPorSocio(val) {
      this.etapa2.aprobadaPorSocio = val
      console.log("aprobarPorSocio?", val)
      if (val) {
        this.etapa2.idSocioAprueba = this.yo._id
        this.etapa2.fechaSocioAprueba = new Date().getTime()
      } else {
        delete this.etapa2.idSocioAprueba
        delete this.etapa2.fechaSocioAprueba
      }
    },
    calcularBloquearMarcarComoPagado() {
      let aprobadaPorSocio = (!this.etapa2.requiereAprobacionSocio ||
        (this.etapa2.idSocioAprueba && this.etapa2.idSocioAprueba != '')) == true
      let tieneEvidenciaAdjunta = (!this.etapa2.requiereEvidenciaAdjunta ||
        (this.etapa2.archivoAdjuntoPagado && this.etapa2.archivoAdjuntoPagado != '')) == true
      this.bloquearMarcarComoPagado = !(aprobadaPorSocio && tieneEvidenciaAdjunta)
      console.log("bloquearMarcarComoPagado", {
        etapa: this.etapa2,
        bloquearMarcarComoPagado: this.bloquearMarcarComoPagado,
        aprobadaPorSocio,
        tieneEvidenciaAdjunta
      })
      return this.bloquearMarcarComoPagado
    },
    esPdf(img) {
      return (img || '').toLowerCase().indexOf('.pdf') >= 0
    },
    esImagen(img) {
      return (img || '').toLowerCase().indexOf('.jpg') >= 0 ||
        (img || '').toLowerCase().indexOf('.jpeg') >= 0 ||
        (img || '').toLowerCase().indexOf('.png') >= 0
    },
    cerrar() {
      Api.$emit('cerrarModalGastoEtapa')
    },
    calcularOpinionDeCumplimientoVigente() {
      let vigente = true
      let fechaMasAntiguaPermitida = new Date().getTime() - (1000 * 60 * 60 * 24 * 30)
      if (!this.proveedor.opinionDeCumplimiento ||
        !this.proveedor.opinionDeCumplimientoFecha ||
        this.proveedor.opinionDeCumplimientoFecha < fechaMasAntiguaPermitida)
        vigente = false
      console.log("opinionDeCumplimientoValida?", vigente)
      this.proveedor.opinionDeCumplimientoValida = vigente
      return
    },
    async calcularComplementosFaltantes() {
      let etapas = await Api.find('gastoetapa', ['idProveedor,eq,' + this.proveedor._id])
      let complementosFaltantes = []
      for (let etapa of etapas)
        if (etapa.archivoAdjuntoXml &&
          etapa.fechaTerminada &&
          etapa.fechaTerminada > 0 &&
          !etapa.complemento)
          //complementosFaltantes++
          complementosFaltantes.push(etapa)
      //return faltaComplemento
      this.proveedor.debeComplementosDePago = complementosFaltantes
      console.log("complementosFaltantes?", complementosFaltantes)
      return Promise.resolve(true)
    }
  },
  async mounted() {
    console.log("ModalGastoEtapa mounted", this.etapa)
    this.etapa2 = JSON.parse(JSON.stringify(this.etapa || {}))
    if (!this.etapa2.cantidadErogada)
      this.etapa2.cantidadErogada = 0
    this.autor = await Api.get('usuario', this.etapa2.idAutor || this.yo._id)
    if (this.etapa2 && this.etapa2.gasto && this.etapa2.gasto.idProveedor) {
      this.proveedor = await Api.get('usuario', this.etapa2.gasto.idProveedor)
      console.log("Detectado proveedor", this.proveedor.nombre)
    }
    console.log("Autor?", this.autor)
    if (this.etapa2.idProveedor) {
      try {
        this.proveedor = await Api.get('usuario', this.etapa2.idProveedor)
        this.calcularOpinionDeCumplimientoVigente()
        await this.calcularComplementosFaltantes()
      } catch (e) {
        console.error("EPROVEEDOR", e)
        toast("Error en datos de proveedor")
      }
      console.log("Proveedor?", this.proveedor.nombre)
    }
    this.historial = await Api.find('bitacora', ['idGastoetapa,eq,' + this.etapa2._id], {
      sort: ['fecha,-1']
    })
    console.log("Historial", this.historial)
    //
    this.calcularBloquearMarcarComoPagado()
  }
}
</script>
