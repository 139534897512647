<template>
<div>
  <ion-item :color="colors('light')">
    <ion-label>Reporteo <span v-if="adscritoMexico">(Adscritos a México)</span></ion-label>
  </ion-item>
  <ion-progress-bar type="indeterminate" v-if="descargando"></ion-progress-bar>
  <ion-item :color="colors('light')">
    <ion-label>({{filtros.fechaInicio | moment('DD-MM-YYYY')}} @ {{filtros.fechaFin | moment('DD-MM-YYYY')}})</ion-label>
    <ion-button slot="end" :color="colors('dark')" fill="outline">
      <i class="icon ion-ios-copy"></i>
    </ion-button>
  </ion-item>
  <div class="scrollable">
    <!--ChartLinea v-if="mostrarGrafico" :chartdata="chartdata" /-->
    <ChartBarras v-if="mostrarGrafico" :chart-data="chartdata" :options="chartoptions" />
  </div>
</div>
</template>
<style>
.scrollable {
  width: 100%;
  display: block;
  overflow-x: scroll;
}

table td {
  border: 1px solid #eee;
}

table td,
table th {
  font-size: 14px;
}

.divisor {
  background-color: #eee;
}
</style>
<script>
import Api from '@/components/Api'
//import ChartLinea from '@/components/ChartLinea'
import ChartBarras from '@/components/ChartBarras'
import Cliente from '@/models/Cliente'
import Usuario from '@/models/Usuario'
import Colores from '@/models/Colores'
import Reporteable from '@/models/Reporteable'
export default {
  name: 'ReporteIndiceReporteo',
  props: ['filtros', 'abogados', 'clientes', 'areas', 'corporativos',
    'unSoloAbogado', 'unSoloCliente', 'adscritoMexico'
  ],
  components: {
    //ChartLinea
    ChartBarras
  },
  filters: {
    filtroTiempo: function(cant) {
      cant = parseFloat(cant)
      let parteEntera = Math.floor(cant / 60)
      let parteDecimal = (cant % 60).toFixed(0)
      if (parteDecimal.length == 1)
        parteDecimal = '0' + parteDecimal
      return parteEntera + ':' + parteDecimal
    }
  },
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo'),
      descargando: false,
      chartdata: {},
      chartoptions: { // El unico cambio con el modulo ChartBarras es el que no se apilan las barras
        showLines: true,
        cubicInterpolationMode: 'monotone',
        fill: true,
        lineTension: 0,
        scales: {
          xAxes: [{
            stacked: false,
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90
            }
          }],
          yAxes: [{
            stacked: false
          }]
        }
      },
      incluirBDLG: true,
      clientesBDLG: [],
      clientesTodos: [],
      abogadosTodos: [],
      mostrarGrafico: false,
      tiempoReportable: (60 * 8 * 5) * 0.8, // Reporte del 80% requerido,
      factorTolerancia: 0.8,
      anosAtras: 2,
      modoProduccion: Api.modoProduccion,
    }
  },
  methods: {
    async toggleIncluirBDLG(val) {
      this.incluirBDLG = val
      this.clientesTodos = await Cliente.lista()
      this.abogadosTodos = await Usuario.lista()
      if (this.adscritoMexico) {
        this.abogadosTodos = this.abogadosTodos.filter(a => {
          return a.mexico && a.mexico == true
        })
      }
      this.clientesBDLG = []
      if (!this.incluirBDLG)
        for (let c of this.clientesTodos) {
          if (c.nombre.toLowerCase().indexOf('bufete de la garza') >= 0 ||
            (c.omitirDeReportes && c.omitirDeReportes == true)) {
            console.log("Omitiendo", c.nombre)
            this.clienteselite.push(c._id)
          }
        }
      console.log("Consultando clientes. Omitiendo?", this.clientesBDLG)
      this.obtener()
    },
    randomColor() {
      var color = "#" + Math.floor(Math.random() * 16777216).toString(16)
      console.log("Unacceptaboooool!", color)
      if (color.length == 7)
        return color
      else return this.randomColor()
    },
    async obtener() {
      console.log("Obteniendo", this.filtros)
      this.mostrarGrafico = false
      this.descargando = true
      //let fI = new Date(this.filtros.fechaInicio).getTime()
      //let fF = new Date(this.filtros.fechaFin).getTime()
      let fF = new Date()
      let fI = new Date(new Date().getFullYear() - this.anosAtras, 0, 1)
      console.log("fI", fI)
      console.log("fF", fF)
      let filtros = [
        'fechaProgramada,gte,' + fI.getTime().toString(),
        'fechaProgramada,lt,' + fF.getTime().toString()
      ]
      filtros = []
      if (this.filtros.idCliente && this.filtros.idCliente.length == 24)
        filtros.push('idCliente,eq,' + this.filtros.idCliente)
      //let actividades = await Api.find('actividad', filtros)
      let resumen = await Reporteable.obtenerResumenActividades(fI, fF, null, 'dia', filtros)
      let actividades = resumen.actividades
      const filtrosf = this.filtros
      actividades = actividades
        .filter(a => !filtrosf.area || filtrosf.area == '' || filtrosf.area == a.area)
      let usuariosIndices = this.abogadosTodos.map(u => u._id)
      let clientesIndices = this.clientesTodos.map(c => c._id);
      actividades = actividades
        .filter(a => {
          return a.idAsunto && a.idAbogado && a.idCliente &&
            clientesIndices.indexOf(a.idCliente) >= 0 &&
            usuariosIndices.indexOf(a.idAbogado) >= 0
        })
        // Multiplicar por 2 las horas capturadas por pasantes, para que su cuota diaria sólo represente 4 horas
        .map(a => {
          let factor = 1
          for (let abogado of this.abogadosTodos)
            if (a.idAbogado == abogado._id && abogado.tipo == 'pasante') {
              factor = 2
              //console.log("Encontrado peasant. Digo, pasante. ",abogado.nombre)
            }
          a.minutos = a.minutos * factor
          return a
        })
      console.log("Acts?", actividades.length, this.clientesBDLG)
      // Preparar grafico
      let chartdata = {
        labels: [],
        datasets: []
      }
      //let mesesActual = new Date().getMonth() + 1
      let mesesActual = 12
      for (let i = 0; i < mesesActual; i++)
        chartdata.labels.push(this.$options.filters.moment('2019-' + (i + 1), 'MMM'))
      console.log("Labels?", chartdata.labels)
      let mapaLaboral = {}
      for (let i = 0; i < this.anosAtras; i++) {
        let year = new Date().getFullYear() - i
        console.log("Comprobando año", year, i)
        if (!chartdata.datasets[i]) {
          let u = {
            label: year,
            data: [],
            backgroundColor: Colores.get(i),
            lineTension: 0,
            fill: i % 2 == 0
          }
          for (let j = 0; j < 12; j++) {
            // Agregar el dataset al gráfico
            u.data[j] = 0
            // Crear un mapa de horas esperadas
            let mesTexto = (j + 1).toString()
            if (mesTexto.length == 1)
              mesTexto = '0' + mesTexto
            let etiquetaMes = year + '-' + mesTexto
            mapaLaboral[etiquetaMes] = {}
            let adscritoMexico = this.adscritoMexico
            mapaLaboral[etiquetaMes].horasLaborablesEsperadas = Reporteable.horasLaborablesEsperadas(etiquetaMes)
            mapaLaboral[etiquetaMes].abogadosActivosEnElMes = (await Usuario.activosALaFecha(etiquetaMes))
              .filter(a => {
                return a.mexico || !adscritoMexico
              })
            mapaLaboral[etiquetaMes].horasLaborablesEsperadasEnElMes = 0
            mapaLaboral[etiquetaMes].horasLaboralesEjercidas = 0
            mapaLaboral[etiquetaMes].abogadosOchoHoras = 0
            mapaLaboral[etiquetaMes].abogadosCuatroHoras = 0
            for (let a of mapaLaboral[etiquetaMes].abogadosActivosEnElMes) {
              let horasEsperadasDeEsteAbogado = mapaLaboral[etiquetaMes].horasLaborablesEsperadas
              if (a.tipo == 'pasante' || a.rol == 'pasante') {
                mapaLaboral[etiquetaMes].abogadosCuatroHoras++
                horasEsperadasDeEsteAbogado /= 2
              } else {
                mapaLaboral[etiquetaMes].abogadosOchoHoras++
              }
              mapaLaboral[etiquetaMes].horasLaborablesEsperadasEnElMes += (horasEsperadasDeEsteAbogado * this.factorTolerancia)
            }
          }
          chartdata.datasets[i] = u
        }
        for (let act of actividades) {
          let yearActividad = new Date(act.fechaProgramada).getFullYear()
          if (year == yearActividad) {
            let mesActividad = new Date(act.fechaProgramada).getMonth()
            // Obtener cuantos abogados hay y cuantas horas laborables debio haber habido
            let mesTexto = (mesActividad + 1).toString()
            if (mesTexto.length == 1)
              mesTexto = '0' + mesTexto
            let etiquetaMes = yearActividad + '-' + mesTexto
            let horasLaborablesEsperadasEnElMes = (mapaLaboral[etiquetaMes].horasLaborablesEsperadasEnElMes)
            // Sumar su parte proporcional
            mapaLaboral[etiquetaMes].horasLaboralesEjercidas += (parseFloat(act.minutos) / 60)
            chartdata.datasets[i].data[mesActividad] += (parseFloat(act.minutos) * 100 / 60 / horasLaborablesEsperadasEnElMes)
            //chartdata.datasets[i].data[mesActividad] += (parseFloat(act.minutos) / this.tiempoReportable)
            //console.log("Sumando", year, yearActividad, mesActividad, chartdata.datasets[i].data[mesActividad])
          }
        }
        console.info("mapaLaboral?", this.adscritoMexico, mapaLaboral)
      }
      //
      this.chartdata = chartdata
      this.mostrarGrafico = true
      this.descargando = false
      console.log("ReporteIndiceReporteo Chartdata?", this.chartdata)
      this.$forceUpdate()
    }
  },
  watch: {
    filtros: function() {
      this.obtener()
    }
  },
  async mounted() {
    console.log("ReporteIndiceReporteo mounted")
    this.toggleIncluirBDLG(false)
  }
}
</script>
