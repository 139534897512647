<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-title>Aprobación del gasto</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="cerrar">
          <i class="icon ion-ios-close-circle-outline"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" v-if="cargando"></ion-progress-bar>
  </ion-header>
  <ion-content>
    <ion-item :color="colors('secondary')">
      <ion-label>
        <h2>Etapa: {{etapa2.nombre}}</h2>
        <p>Creado por {{(autor||{}).nombre}}</p>
      </ion-label>
    </ion-item>
    <ion-row>
      <ion-col size-xs="12" :size-sm="(etapa2.archivoAdjunto && etapa2.archivoAdjunto.length>0)?6:12">
        <ion-list>
          <ion-item-divider>Configurar</ion-item-divider>
          <ion-item>
            <ion-label>Cantidad programada</ion-label>
            <ion-input class="ion-text-right" :value="etapa2.cantidadErogable" v-on:ionChange="etapa2.cantidadErogable=parseFloat($event.detail.value)"></ion-input>
          </ion-item>
          <!--ion-item>
            <ion-label>Cantidad entregada</ion-label>
            <ion-input class="ion-text-right" :value="etapa2.cantidadErogada" v-on:ionChange="etapa2.cantidadErogada=parseFloat($event.detail.value)"></ion-input>
          </ion-item-->
          <ion-item v-if="etapa2.requiereAprobacionSocio">
            <ion-label>Aprobada por Socio</ion-label>
            <ion-checkbox :checked="etapa2.aprobadaPorSocio==true" v-on:ionChange="aprobarPorSocio($event.detail.checked==true)" slot="end"></ion-checkbox>
          </ion-item>
          <ion-item @click="alertaSociodirector">
            <ion-label>Pago a # dias</ion-label>
            <ion-input type="number" :readonly="!yo.rol.startsWith('director')" class="ion-text-right" :value="etapa2.pagoDias" v-on:ionChange="etapa2.pagoDias=$event.detail.value;$forceUpdate()" />
          </ion-item>
          <ion-item @click="alertaSociodirector">
            <ion-label text-wrap>Fecha compromiso (tentativa) de pago</ion-label>
            <ion-input type="date" class="ion-text-right" color="medium" readonly :value="(new Date().getTime()+(1000*60*60*24*etapa2.pagoDias)) | moment('YYYY-MM-DD')" />
          </ion-item>
          <!--ion-item v-if="typeof etapa2.terminada != 'undefined'">
            <ion-label>Terminada</ion-label>
            <ion-checkbox :checked="etapa2.terminada==true" v-on:ionChange="etapa2.terminada=($event.detail.checked==true)" slot="end"></ion-checkbox>
          </ion-item-->
          <!--label for="adj" v-if="etapa2.requiereEvidenciaAdjunta">
            <ion-item>
              <ion-note slot="start"><i class="icon icon-lg" :class="(archivoAdjunto&&archivoAdjunto!='')?'ion-ios-document':'ion-ios-attach'"></i></ion-note>
              <ion-label v-if="etapa2.archivoAdjunto" text-wrap>{{etapa2.archivoAdjunto}}</ion-label>
              <ion-label v-else text-wrap>Click aquí para adjuntar archivo de evidencia</ion-label>
            </ion-item>
            <input type="file" class="hidden" name="adj" id="adj" v-on:change="subirAdjunto()" />
          </label-->
          <div v-if="fechaspago.length>0 && !etapa2.aprobadaPorSocio">
            <ion-item-divider>Fechas de pago recomendadas</ion-item-divider>
            <ion-item v-for="fecha in fechaspago" :key="fecha.fechaPago" @click="seleccionarFechaPago(fecha)">
              <ion-label :color="fecha.tentativo?'primary':'medium'">{{fecha.fechaPago | moment('DD MMMM YYYY')}}</ion-label>
            </ion-item>
          </div>
        </ion-list>
      </ion-col>
      <ion-col size-xs="12" :size-sm="(etapa2.archivoAdjunto && etapa2.archivoAdjunto.length>0)?6:12">
        <a :href="cdn+'/'+etapa2.archivoAdjunto" target="_blank" v-if="esImagen(etapa2.archivoAdjunto)">
          <ion-img :src="cdn+'/'+etapa2.archivoAdjunto"></ion-img>
        </a>
        <embed v-if="esPdf(etapa2.archivoAdjunto)" :src="cdn+'/'+etapa2.archivoAdjunto" type="application/pdf" style="width:100%;min-height:50vh;" />
        <ion-item v-if="!esImagen(etapa2.archivoAdjunto) && !esPdf(etapa2.archivoAdjunto)" :href="cdn+'/'+etapa2.archivoAdjunto" target="_blank">
          <ion-note slot="start" color="medium">
            <i class="icon ion-ios-document icon-lg"></i>
          </ion-note>
          <ion-label text-wrap>
            <h2>{{etapa2.archivoAdjunto}}</h2>
            <p>Clic para descargar </p>
          </ion-label>
        </ion-item>
        <ion-item v-if="etapa2.archivoAdjuntoFecha">
          <ion-label>
            <p>{{etapa2.archivoAdjunto}}</p>
            <p>Subido {{etapa2.archivoAdjuntoFecha | moment('MMM DD HH:mm')}}</p>
          </ion-label>
        </ion-item>
        <ion-item v-if="etapa2.archivoAdjuntoXml" :href="cdn+'/'+etapa2.archivoAdjuntoXml" target="_blank">
          <ion-label text-wrap>
            <h2>Descagar XML</h2>
            <p>{{etapa.archivoAdjuntoXml}}</p>
          </ion-label>
        </ion-item>
        <ion-list v-if="yo.rol=='administrador' && historial.length>0">
          <ion-item-divider>Modificaciones</ion-item-divider>
          <ion-item v-for="m in historial" :key="m._id">
            <ion-label text-wrap>{{m.mensaje.replace('Ggasto modificado por ','')}}</ion-label>
            <ion-note slot="end">{{m.fecha | moment('MM-DD HH:mm')}}</ion-note>
          </ion-item>
        </ion-list>
        <ion-list v-if="yo.rol=='administrador' && historial.length>0">
          <ion-item-divider>Modificaciones</ion-item-divider>
          <ion-item v-for="m in historial" :key="m._id">
            <ion-label text-wrap>{{m.mensaje.replace('Gasto aprobado por ','')}}</ion-label>
            <ion-note slot="end">{{m.fecha | moment('MM-DD HH:mm')}}</ion-note>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-content>
  <ion-footer>
    <ion-row>
      <ion-col size="4" v-if="modoProduccion">
        <ion-button size="small" expand="block" :color="colors('tertiary')" @click="guardar">Guardar</ion-button>
      </ion-col>
      <ion-col size="4" v-else>
        <ion-button size="small" expand="block" :color="colors('tertiary')" @click="enviarCorreos">Enviar</ion-button>
      </ion-col>
      <ion-col size="8">
        <ion-button v-if="(idEncargadoPresupuesto==yo._id) || !modoProduccion" size="small" expand="block" :color="colors('success')" @click="aprobar">Aprobar pago</ion-button>
        <ion-button v-else disabled expand="block" :color="colors('danger')">No tiene permiso: AprobarFacturas</ion-button>
      </ion-col>
    </ion-row>
  </ion-footer>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
import Empresa from '@/models/Empresa'
import Emailer from '@/models/Emailer'
export default {
  name: 'ModalGastoAprobacion',
  props: {
    etapa: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      etapa2: {},
      autor: {},
      modoProduccion: Api.modoProduccion,
      idEncargadoPresupuesto: null,
      yo: Api.obtenerLocal('elite.yo'),
      historial: [],
      cargando: false,
      cdn: Api.cdn,
      fechaspago: []
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async guardar() {
      await Api.save('gastoetapa', this.etapa2)
      await Api.save('bitacora', {
        mensaje: 'Gasto aprobado por ' + this.yo.email,
        fecha: new Date().getTime(),
        idGastoetapa: this.etapa2._id
      })
      alert("Guardado")
      Api.$emit('cerrarModalGastoEtapa', true)
    },
    aprobar() {
      this.aprobarPorSocio(true)
      this.guardar()
      this.enviarCorreos()
    },
    async enviarCorreos() {
      try {
        let destinatarios = []
        // Proveedor
        let proveedor, gasto, autorGasto
        gasto = await Api.get('presupuestogasto', this.etapa2.idGasto)
        if (gasto.idProveedor) {
          proveedor = await Api.get('usuario', gasto.idProveedor)
          destinatarios.push(proveedor.email)
          if (proveedor.emailAlternativo)
            destinatarios.push(proveedor.emailAlternativo)
        }
        // Gestor del gasto
        if (gasto.idAutor) {
          autorGasto = await Api.get('usuario', gasto.idAutor)
          destinatarios.push(autorGasto.email)
        }
        //
        let titulo = 'Pago aprobado para el proveedor ' + proveedor.nombre
        if (this.etapa2.pagoDias < 60) {
          titulo = titulo.replace('Pago', 'PAGO EXTRAORDINARIO')
          let sociosdirectores = await Api.find('usuario', ['rol,eq,director'])
          destinatarios = destinatarios.concat(sociosdirectores.map(s => s.email))
        }
        destinatarios = [...new Set(destinatarios)]
        //
        for (let destinatario of destinatarios) {
          console.log("Enviando a", destinatario)
          try {
            await Emailer.enviar({
              titulo,
              destino: destinatario,
              cuerpo1: 'El pago ha sido aprobado' + ((proveedor && proveedor._id) ?
                (', para el proveedor ' + proveedor.nombre) : '. '),
              cuerpo2: 'Día tentativo de pago: ' +
                this.$options.filters.moment(
                  ((this.etapa2.fechaSocioAprueba || new Date().getTime()) + (1000 * 60 * 60 * 24 * this.etapa2.pagoDias)),
                  'MMMM DD, YYYY') +
                ', por ' +
                this.$options.filters.currency(this.etapa2.cantidadErogable),
            })
          } catch (e) {
            console.error(e)
          }
        }
        alert("Correos enviados")
      } catch (e) {
        console.error(e);
        alert("Hubo un error al enviar los correos")
      }
      return Promise.resolve(true)
    },
    seleccionarFechaPago(fecha) {
      if (this.yo.rol == 'director') {
        let dias = (new Date(fecha.fechaPago).getTime() - new Date().getTime()) /
          (1000 * 60 * 60 * 24)
        this.etapa2.pagoDias = Math.floor(dias) + 1
        console.log("Seleccionando fecha de pago", fecha.fechaPago, dias)
        this.$forceUpdate()
      } else
        this.alertaSociodirector()
    },
    alertaSociodirector() {
      if (this.yo.rol != 'director') {
        alert("Sólo un socio director o director puede cambiar la fecha de pago")
        Api.save('bitacora', {
          idAutor: this.yo._id,
          fecha: new Date().getTime(),
          mensaje: 'El usuario intentó cambiar una fecha de pago',
          idGasto: this.etapa2.idGasto
        })
      }
    },
    async subirAdjunto() {
      this.cargando = true
      let u = await Api.upload('adj')
      console.log("Subido?", u)
      this.etapa2.archivoAdjunto = u
      this.cargando = false
      this.$forceUpdate()
    },
    async aprobarPorSocio(val) {
      this.etapa2.aprobadaPorSocio = val
      console.log("aprobarPorSocio?", val)
      if (val) {
        this.etapa2.idSocioAprueba = this.yo._id
        this.etapa2.fechaSocioAprueba = new Date().getTime()
      } else {
        delete this.etapa2.idSocioAprueba
        delete this.etapa2.fechaSocioAprueba
      }
    },
    esPdf(img) {
      return (img || '').toLowerCase().indexOf('.pdf') >= 0
    },
    esImagen(img) {
      return (img || '').toLowerCase().indexOf('.jpg') >= 0 ||
        (img || '').toLowerCase().indexOf('.jpeg') >= 0 ||
        (img || '').toLowerCase().indexOf('.png') >= 0
    },
    cerrar() {
      Api.$emit('cerrarModalGastoEtapa')
    },
    encontrarEncargadoPresupuesto() {
      let vm = this
      return new Promise(async (resolve, reject) => {
        try {
          let gasto = await Api.get('presupuestogasto', vm.etapa2.idGasto)
          console.log("Gasto", vm.etapa2.idGasto, gasto)
          let presupuesto = await Api.get('presupuesto', gasto.idPresupuesto)
          console.log("Presupuesto", presupuesto.idUsuarioAsignado, presupuesto)
          vm.idEncargadoPresupuesto = presupuesto.idUsuarioAsignado
          let encargadoPresupuesto = await Api.get('usuario', vm.idEncargadoPresupuesto)
          if (!vm.modoProduccion)
            alert("Encargado del presupuesto: " + encargadoPresupuesto.nombre)
          console.log("encargado del presupuesto", vm.idEncargadoPresupuesto, encargadoPresupuesto)
          resolve(true)
        } catch (e) {
          alert("No se pudo encontrar al encargado del presupuesto")
          console.error(e)
        }
      })
    }
  },
  async mounted() {
    this.etapa2 = JSON.parse(JSON.stringify(this.etapa || {}))
    await this.encontrarEncargadoPresupuesto()
    let pagoDias = (Empresa.obtenerDatos() || {}).diasPostergarPago || 30
    console.log("ModalGastoEtapa mounted", this.etapa2, pagoDias)
    if (!this.etapa2.pagoDias)
      this.etapa2.pagoDias = pagoDias
    if (!this.etapa2.cantidadErogada)
      this.etapa2.cantidadErogada = 0
    this.autor = await Api.get('usuario', this.etapa2.idAutor || this.yo._id)
    console.log("Autor?", this.autor)
    this.historial = await Api.find('bitacora', ['idGastoetapa,eq,' + this.etapa2._id], {
      sort: ['fecha,-1']
    })
    console.log("Historial", this.historial)
    let fechaActual = new Date().toISOString().substring(0, 10)
    this.fechaspago = (await Api.get('fechaspago'))
      .filter(u => fechaActual <= u.fechaPago)
      .sort((a, b) => a.fechaPago > b.fechaPago ? 1 : -1)
      .map(f => {
        let dias = (new Date(f.fechaPago).getTime() - new Date().getTime()) /
          (1000 * 60 * 60 * 24)
        f.tentativo = (dias >= 55)
        return f
      })
      .splice(0, 10)
    console.log("Fechas de pago propuestas", this.fechaspago)
    this.$forceUpdate()
  }
}
</script>
