<template>
<div>
  <ion-item>
    <ion-note slot="start" :color="color" v-if="!ocultarIcono">
      <i class="icon ion-ios-contact"></i>
    </ion-note>
    <ion-label :color="color" text-wrap>
      {{label}}
    </ion-label>
    <ion-input type="text" placeholder="Buscar por nombre" class="ion-text-right" :value="nombre" :debounce="debounce" v-on:ionChange="buscar($event.detail.value)" :clearInput="true" />
  </ion-item>
  <ion-item v-for="cl in proveedoresFiltrados" :key="cl._id" v-on:click="buscar(cl.nombre)" v-if="!devolverTodos">
    <ion-label>
      <h2>{{cl.nombre}}</h2>
      <p v-if="cl.rfc">{{cl.rfc}}</p>
    </ion-label>
  </ion-item>
</div>
</template>
<script>
import Api from '@/components/Api'
import Proveedor from '@/models/Proveedor'
export default {
  name: 'SelectorProveedor',
  props: {
    idProveedor: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Proveedor'
    },
    color: {
      type: String,
      default: 'success'
    },
    ocultarIcono: {
      type: Boolean,
      default: false
    },
    devolverTodos: {
      type: Boolean,
      default: false
    },
    debounce: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo'),
      proveedores: [],
      proveedoresFiltrados: [],
      nombre: '',
      proveedor: {}
    }
  },
  methods: {
    configurarProveedor(cl) {
      console.log("$emit proveedorSeleccionado", cl)
      Api.$emit('proveedorSeleccionado', cl)
    },
    buscar(nombre) {
      console.log("buscar", nombre)
      nombre = (nombre || '').toUpperCase()
      if (nombre == '') {
        this.nombre = ''
        this.configurarCliente('')
      } else {
        let hayCoincidenciaExacta = false
        this.proveedoresFiltrados = this.proveedores.filter(c => {
          if ((c.nombre || '').toUpperCase() == nombre)
            hayCoincidenciaExacta = true
          return (c.nombre || '').toUpperCase().indexOf(nombre) >= 0
        })
        if (hayCoincidenciaExacta) {
          console.log("Potencialmente hay una coincidencia exacta. Comprobando.")
          this.proveedoresFiltrados = this.proveedores.filter(c => {
            return (c.nombre || '').toUpperCase() == nombre
          })
        }
        console.log("Proveedores encontrados", this.proveedoresFiltrados)
        if (this.proveedoresFiltrados.length == 1) {
          if (nombre != this.nombre) {
            this.nombre = (this.proveedoresFiltrados[0].nombre || '').toUpperCase()
            this.proveedor = this.proveedoresFiltrados[0]
            console.log("Cambiando campo 'nombre'", this.nombre)
          }
          if (this.idProveedor != this.proveedoresFiltrados[0]._id)
            this.configurarProveedor(this.proveedoresFiltrados[0]._id)
          console.log("Proveedor unico asignado", this.nombre, nombre)
          this.$forceUpdate()
          this.proveedoresFiltrados = []
        } else if (true == this.devolverTodos)
          this.configurarProveedor(this.proveedoresFiltrados)
      }
    },
    obtenerNombreInicial() {
      console.log("obtenerNombreInicialDeCliente", this.idProveedor)
      if (this.idProveedor && this.idProveedor != '') {
        for (let cliente of this.proveedores)
          if (cliente._id == this.idProveedor) {
            this.nombre = cliente.nombre
            this.proveedor = cliente
            console.log("Convertido ID a nombre", this.idProveedor, this.nombre)
            this.$forceUpdate()
          }
      }
    }
  },
  watch: {
    idProveedor: function(a) {
      let vm = this
      setTimeout(_ => {
        if (vm.nombre == '' && vm.idProveedor != '')
          vm.obtenerNombreInicial()
      }, 500)
    }
  },
  async mounted() {
    console.log("SelectorProveedor mounted", this.idProveedor)
    this.proveedores = await Proveedor.lista()
    this.obtenerNombreInicial()
    this.proveedoresFiltrados = []
  }
}
</script>
