<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>Cambiar fechas de pagos ({{pagos.length}}) </ion-title>
      <ion-buttons slot="end">
        <!--ion-button @click="mostrarTodos=!mostrarTodos;obtener()">
          {{mostrarTodos?'Solo directos':'Mostrar todos'}}
        </ion-button-->
        <ion-button @click="obtener">
          <i class="icon ion-ios-refresh"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar v-if="cargando" type="indeterminate"></ion-progress-bar>
  </ion-header>
  <ion-content>
    <ion-item lines="none">
      <ion-label class="ion-text-center">
        <p>Viendo todos los pagos pendientes </p>
      </ion-label>
    </ion-item>
    <ion-card v-for="p in pagos" :key="p._id">
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label text-wrap>
              <h2 v-if="p.gasto && p.gasto.nombre">{{p.gasto.nombre}}</h2>
              <h2>{{(p.proveedor||'').nombre}} {{p.nombre}}</h2>
              <p>Programado para {{p.fechaProgramado | moment('MMM DD')}}</p>
              <p>
                <ion-button fill="outline" target="_blank" :href="cdn+p.archivoAdjunto" v-if="p.archivoAdjunto">Factura</ion-button>
                <ion-button fill="outline" target="_blank" :href="cdn+p.gasto.archivoAdjunto" v-if="p.gasto && p.gasto.archivoAdjunto">Cotización</ion-button>
                <ion-button fill="outline" target="_blank" :href="cdn+p.archivoAdjuntoPagado" v-if="p.archivoAdjuntoPagado">Comprobante</ion-button>
              </p>
            </ion-label>
            <ion-note slot="end">
              {{p.cantidadErogable | currency}}
            </ion-note>
          </ion-item>
        </ion-col>
        <ion-col>
          <ion-item>
            <ion-label>
              Pagar día (de calendario de pagos)
            </ion-label>
            <ion-select :value="p.fechaProgramado" v-on:ionChange="p.fechaProgramado=$event.detail.value">
              <ion-select-option v-for="f in fechasPago" :key="f">{{f}}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label>
              O manualmente
            </ion-label>
            <ion-input type="date" class="ion-text-right" :value="p.fechaProgramado" v-on:ionChange="p.fechaProgramado=$event.detail.value" />
            <ion-button slot="end" :color="colors('primary')" fill="solid" @click="cambiarFechaDePago(p)">
              <i class="icon ion-ios-save"></i>&nbsp;Guardar
            </ion-button>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-card>
  </ion-content>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
export default {
  name: 'PresupuestosCambiosFechas',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      fechasPago: [],
      pagos: [],
      cdn: Api.cdn + '/',
      mostrarTodos: false,
      cargando: false
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async obtener() {
      this.cargando = true
      let fechaActual = new Date()
      let mostrarTodos = this.mostrarTodos
      let pagos = (await Api.find('gastoetapa', ['cantidadErogable,gt,0'], {
            subset: [{
              coleccion: 'presupuestogasto',
              nombre: 'gasto',
              query: '_id,eq,',
              sustituir: 'idGasto',
              unico: true
            }, {
              coleccion: 'presupuesto',
              query: '_id,eq,',
              sustituir: 'idPresupuesto',
              unico: true
            }]
          } //*/
        ))
        //.filter(u => !u.directo || mostrarTodos)
        .filter(u => new Date(u.fechaProgramado).getTime() > fechaActual.getTime())
        .filter(u => Api.modoProduccion ? !u.archivoAdjuntoPagado : true)
      for (let id in pagos) {
        console.log("gasto?", (pagos[id].gasto || {}).nombre)
        try {
          if (!pagos[id].gasto)
            pagos[id].gasto = await Api.get('presupuestogasto', pagos[id].idGasto)
          if (pagos[id].gasto.idProveedor)
            pagos[id].proveedor = await Api.get('usuario', pagos[id].gasto.idProveedor)
          if (!pagos[id].presupuesto)
            pagos[id].presupuesto = await Api.get('presupuesto', pagos[id].gasto.idPresupuesto)
        } catch (e) {
          console.error(e)
        }
      }
      if (['director', 'administrador'].indexOf(this.yo.tipo) >= 0 ||
        this.yo.rol == 'administrador') {
        alert("Mostrando todos los pagos")
      } else {
        // Solo mostrar los gatos que me atañen
        alert("Mostrando solo pagos de este usuario")
        pagos = pagos.filter(p =>
          (p.presupuesto || {}).idUsuarioAsignado == this.yo._id
        )
      }
      this.pagos = pagos
      this.cargando = false
      this.$forceUpdate()
      console.log("Pagos?", this.pagos)
    },
    async cambiarFechaDePago(pago) {
      this.fechaSocioAprueba = new Date().getTime()
      //pago.fechaProgramado = this.$options.filters.moment(new Date(pago.fechaProgramado), 'YYYY-MM-DD')
      await Api.save('gastoetapa', pago)
      alert("Fecha cambiada")
      console.log("cambiarFechaDePago", pago.fechaProgramado, pago)
    }
  },
  async mounted() {
    this.fechasPago = (await Api.get('fechaspago'))
      .map(f => f.fechaPago)
      .sort((a, b) => a > b ? 1 : -1)
      .filter(f => f > (new Date().toISOString().substring(0, 10)))
    this.obtener()
  }
}
</script>
