<template>
<ion-item @click="modalEtapaGasto()">
  <ion-label text-wrap>
    <h2>{{etapa.nombre}}</h2>
    <p>{{etapa.cantidadErogable|currency}}&nbsp;<i class="icon ion-ios-attach" v-if="etapa.archivoAdjunto"></i>
      &nbsp;<i class="icon ion-ios-unlock" v-if="etapa.aprobadaPorSocio"></i>
    </p>
  </ion-label>
  <ion-note slot="end" :color="colors('primary')">
    <i class="icon icon-lg" :class="'ion-ios-'+((etapa.terminada==true)?'checkmark-circle-outline':'radio-button-off')"></i>
  </ion-note>
  <!--ion-checkbox :checked="etapa.terminada==true" v-on:ionChange="setEtapaTerminada($event.detail.checked==true)"></ion-checkbox-->
</ion-item>
</template>
  <script>
  import Api from '@/components/Api'
  import ModalGastoEtapa from '@/components/Presupuestos/ModalGastoEtapa'
export default {
    name:'GastoEtapaToggle',
    props:{
      etapa:{
        type:Object,
        default:function(){
          return{}}
      }
    },
    methods:{
      async setEtapaTerminada(val){
        this.etapa.terminada =val
        if(val==true){
        this.etapa.idUsuarioTermina = this.yo._id
        this.etapa.fechaTerminada  =new Date().getTime()}
        else {
          delete this.etapa.idUsuarioTermina
          delete this.etapa.fechaTerminada
        }
        await Api.save('gastoetapa',this.gasto)
        alert("Gasto terminado")
      },
      async modalEtapaGasto() {
        let vm = this
        console.log("modalEtapaGasto",this.etapa)
        return this.$ionic.modalController
          .create({
            component: ModalGastoEtapa,
            swipeToClose: true,
            componentProps:{
              parent:vm,
               propsData:{
              etapa:vm.etapa}
            }
          })
          .then(m => {
            vm.modal = m
            m.present()
            m.onDidDismiss(_ => {
              console.log("Modal cerrado")
            })
          })
      },
      cerrarModalGastoEtapa(){
        try{
          console.log("$on cerrarModalGastoEtapa")
          this.modal.dismiss()
        } catch (e) {
          console.error("EDISMISS", e)
        }
      }
    },
    mounted(){
      console.log("GastoEtapaToggle",this.etapa._id)
      Api.$on('cerrarModalGastoEtapa',this.cerrarModalGastoEtapa)
    },beforeDestroy(){
      Api.$off('cerrarModalGastoEtapa')
    }
  }
  </script>
