<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-title>Catalogos - Importar</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <ion-icon name="menu" :color="colors('light')"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-list>
      <label for="jsonfile">
        <ion-item>
          <ion-label>
            <h2>Importar desde JSON</h2>
            <p>Exportado desde PHPMyAdmin</p>
            <p>Click para seleccionar archivo</p>
          </ion-label>
        </ion-item>
        <input class="hidden" name="jsonfile" id="jsonfile" type="file" v-on:change="subirArchivo($event)" />
      </label>
      <ion-item v-if="objetos.length>0">
        <ion-label>
          <p>Importar {{objetos.length}} objetos en '{{coleccion}}'</p>
        </ion-label>
      </ion-item>
      <ion-progress-bar v-if="guardables>0" :value="guardados/guardables" />
    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('secondary')" @click="guardar">
        <ion-icon name="save"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</div>
</template>
<style>
.hidden {
  display: none;
}
</style>
<script>
import Api from '@/components/Api'
import Cliente from '@/models/Cliente'
const sha1 = require('sha1')
export default {
  name: 'CatalogoImportar',
  data() {
    return {
      yo: Api.obtenerLocal('wst.yo'),
      coleccion: '',
      objetos: [],
      guardables: 0,
      guardados: 0
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    async obtener() {
      console.log("Obteniendo")
    },
    async subirArchivo(evt) {
      console.log("Subiendo archivo", evt)
      let fr = new FileReader()
      let fileName = evt.target.files[0].name.replace('.json', '')
      let vm = this
      fr.onload = function(file) {
        console.log("File?", file.target.result.length)
        vm.procesarJson(file.target.result, fileName)
      }
      fr.readAsText(evt.target.files[0])
    },
    sanitizeJSON(unsanitized) {
      //return unsanitized.replace(/\\/g, "\\\\").replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t").replace(/\f/g, "\\f").replace(/"/g, "\\\"").replace(/'/g, "\\\'").replace(/\&/g, "\\&");
      return decodeURIComponent((unsanitized || '').replace(/\\/g, '\\\\'))
        .replace(/\\/g, "\\\\")
        //.replace(/\n/g, "\\n")
        .replace(/\n/g, "")
        .replace(/\r/g, "\\r")
        .replace(/\t/g, "\\t")
        .replace(/\f/g, "\\f")
        .replace(/"/g, "\"")
        .replace(/'/g, "\'")
        .replace(/\&/g, "\\&")
        .replace(/&/g, "\\&")
        .replace()
        .trim()
    },
    U2A(str) {
      var reserved = '';
      var code = str.match(/&#(d+);/g);
      if (code === null) {
        return str;
      }
      for (var i = 0; i < code.length; i++) {
        reserved += String.fromCharCode(code[i].replace(/[&#;]/g, ''));
      }
      return reserved;
    },
    sanitizeString(str) {
      return (str || '')
        .replace(/%20/g, ' ').replace(/%E1/g, 'á').replace(/%F3/g, 'ó')
        .replace(/%ED/g, 'í').replace(/%F3/g, 'ó').replace(/%2C/g, ',')
        .replace(/%24/g, "\$").replace(/%28/g, "(").replace(/%29/g, ")").replace(/%E9/g, "é")
    },
    async procesarJson(blob, coleccion) {
      let objetos = []
      let iniciaJSON = (blob || '').indexOf('[')
      blob = (blob || '').substring(iniciaJSON, blob.length)
      blob = this.sanitizeJSON(blob)
      try {
        objetos = JSON.parse(blob)
      } catch (e) {
        console.error("EJSON", e)
        console.log('blob', blob)
      }
      if (coleccion == 'abogados') {
        coleccion = 'usuario'
        let muestra = await Api.get('usuario')
        console.log("Muestra?", muestra)
        objetos = objetos.map(o => {
          let p = {
            _id: sha1('usuario_' + o.Ab_id).substring(0, 24),
            nombre: this.sanitizeString(o.Ab_nombre),
            iniciales: this.sanitizeString(o.Ab_corto),
            email: o.Ab_mail,
            //password: sha1(o.Ab_password),
            password: (o.Ab_password),
            rutaDefault: '/actividad',
            reporta: true,
            rol: 'usuario',
            tipo: 'abogado'
          }
          if (o.Ta_id)
            switch ((o.Ta_id).toString()) {
              case '1':
                p.tipo = 'socio'
                break
              case '2':
                p.tipo = 'asociado'
                break
              case '3':
                p.tipo = 'pasante'
                break
              case '4':
                p.tipo = 'administrativo'
                break
            }
          console.log("P?", p, o)
          return p
        })
      }
      if (coleccion == 'actividades') {
        coleccion = 'actividad'
        let muestra = await Api.find('actividad', [], {
          limit: 10
        })
        let expedientes = await Api.get('asunto')
        console.log("Muestra?", muestra)
        let indice = 0
        objetos = objetos.map(o => {
          let p = {
            _id: sha1('actividad_' + o.Ac_id).substring(0, 24),
            idAbogado: sha1('usuario_' + o.Ab_id).substring(0, 24),
            //idCliente: sha1('cliente_' + o.Cli_id).substring(0, 24),
            idAsunto: sha1('asunto_' + o.Ex_id).substring(0, 24),
            idAutor: this.yo._id,
            fechaProgramada: new Date(o.Ac_fecha).getTime() + (1000 * 60 * 60 * 24),
            fecha: new Date().getTime(),
            descripcion: (o.Ac_descripcion || '')
              .replace(/%20/g, ' ').replace(/%E1/g, 'á').replace(/%F3/g, 'ó')
              .replace(/%ED/g, 'í').replace(/%F3/g, 'ó').replace(/%2C/g, ',')
              .replace(/%24/g, "\$").replace(/%28/g, "(").replace(/%29/g, ")").replace(/%E9/g, "é"),
            minutos: parseFloat(o.Ac_tiempo1)
          }
          // Asignar el cliente a la actividad, ya que se omite
          for (let exp of expedientes) {
            if (exp._id == p.idAsunto) {
              //console.log("Asignando cliente desde expediente", exp._id, exp.idCliente)
              p.idCliente = exp.idCliente
            }
          }
          //
          if (indice < 10)
            console.log("P?", p, o)
          indice++
          return p
        })
      }
      if (coleccion == 'areaspractica') {
        coleccion = 'area'
        objetos = objetos.map(o => {
          let p = {
            _id: sha1('area_' + o.Ap_id).substring(0, 24),
            nombre: o.Ap_nombre,
            nombreCorto: o.Ap_corto
          }
          return p
        })
        console.log("Areas practicas", coleccion, objetos)
      }
      if (coleccion == 'expedientes') {
        coleccion = 'asunto'
        let muestra = await Api.find('asunto', [], {
          limit: 10
        })
        let idx = 0
        console.log("Muestra?", muestra)
        let areas = await Api.get('area')
        objetos = objetos.map(o => {
          let p = {
            _id: sha1('asunto_' + o.Ex_id).substring(0, 24),
            idAbogado: sha1('usuario_' + o.Ab_id).substring(0, 24),
            fecha: new Date(o.Ex_fecha1).getTime(),
            fecha2: new Date(o.Ex_fecha2).getTime(),
            nombre: o.Ex_nombre,
            idCliente: sha1('cliente_' + o.Cli_id).substring(0, 24),
            status: o.Ex_status,
            idArea: sha1('area_' + o.Ap_id).substring(0, 24)
          }
          for (let area of areas) {
            if (area._id == p.idArea)
              p.area = area.nombre
          }
          if (idx < 10)
            console.log("P?", p, o)
          idx++
          return p
        })
      }
      if (coleccion == 'clientes') {
        coleccion = 'cliente'
        let muestra = await Api.find('cliente', [], {
          limit: 10
        })
        let idx = 0
        console.log("Muestra?", muestra)
        objetos = objetos.map(o => {
          let p = {
            _id: sha1('cliente_' + o.Cli_id).substring(0, 24),
            nombre: o.Cli_nombre,
            tipoDefault: 'horario'
          }
          if (idx < 10)
            console.log("P?", p, o)
          idx++
          return p
        })
      }
      if (coleccion == 'corporativos') {
        coleccion = 'corporativo'
        objetos = objetos.map(o => {
          let p = {
            _id: sha1('corporativo_' + o.Crp_id).substring(0, 24),
            nombre: o.Crp_nombre,
            miembros: {}
          }
          return p
        })
      }
      if (coleccion == 'empresascorporativo') {
        coleccion = 'corporativo'
        let objs2 = await Api.get('corporativo')
        let clientes2 = await Cliente.lista()
        let clientes = {}
        for (let cl of clientes2)
          clientes[cl._id] = cl
        objs2 = objs2.map(o => {
          o.miembros = {}
          o.miembrosArray = []
          for (let enlace of objetos) {
            let idCliente = sha1('cliente_' + enlace.Cli_id).substring(0, 24)
            let idCorporativo = sha1('corporativo_' + enlace.Crp_id).substring(0, 24)
            let cliente = clientes[idCliente]
            if (idCorporativo == o._id) {
              console.log("Miebro de corporativo?", enlace.Cli_id, enlace.Crp_id, idCorporativo, o._id, idCliente, idCorporativo == o._id, cliente.nombre, cliente._id)
              o.miembros[idCliente] = true
              o.miembrosArray.push(idCliente)
            }
          }
          return o
        })
        console.log("Enlaces?", objs2)
        objetos = objs2
      }
      console.log("Objs?", objetos)
      this.objetos = objetos
      this.coleccion = coleccion
      console.log("Procesando", blob.length, coleccion)
    },
    async guardar() {
      this.guardables = this.objetos.length
      this.$forceUpdate()
      console.log("Por guardar", this.guardables)
      this.guardados = 0
      for (let obj of this.objetos) try {
        await Api.save(this.coleccion, obj)
        this.guardados++
        console.log("Guardado?", this.guardados, this.guardables)
        this.$forceUpdate()
      } catch (e) {
        console.error("No se pudo guardar el objeto", e)
      }
      alert("Guardados")
      //window.location.reload()
    }
  },
  mounted() {
    this.obtener()
  }
}
</script><style>
</style>
