<template>
<ion-page main>
  <ion-header>
    <ion-toolbar :color="colors('primary')">
      <ion-title>Justificación de la factura</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="cerrar">
          <i class="icon ion-ios-close-circle"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" v-if="cargando"></ion-progress-bar>
  </ion-header>
  <ion-content>
    <ion-item>
      <ion-label text-wrap>
        <h2>{{(facturasingasto2.metadatos||{}).concepto}}</h2>
        <p>{{(facturasingasto2.proveedor||{}).nombre}}</p>
      </ion-label>
      <ion-note slot="end">{{((facturasingasto2.metadatos||{}).cantidad || 0) |currency}}</ion-note>
    </ion-item>
    <ion-item lines="none">
      <ion-label>
        <p>Subida el {{facturasingasto2.fecha | moment('MMM DD')}}, programada para {{(facturasingasto2.fechaProgramado || facturasingasto2.fechaProgramadoTentativo) | moment('MMM DD')}}</p>
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-label>Es urgente</ion-label>
      <ion-checkbox slot="start" :checked="facturasingasto2.esUrgente" v-on:ionChange="facturasingasto2.esUrgente=($event.detail.checked==true)"></ion-checkbox>
    </ion-item>
    <ion-item>
      <ion-label text-wrap position="floating">Escriba aquí la justificación de este gasto</ion-label>
      <ion-textarea rows="6" :value="facturasingasto2.justificacion" v-on:ionChange="facturasingasto2.justificacion=$event.detail.value"></ion-textarea>
    </ion-item>
    <ion-item v-if="!modoProduccion">
      <ion-label text-wrap>{{facturasingasto2}}</ion-label>
    </ion-item>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('primary')" @click="terminar" :disabled="cargando">
        <i class=" icon icon-lg ion-ios-checkmark"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</ion-page>
</template>
<script>
import Api from '@/components/Api'
import Empresa from '@/models/Empresa'
import Emailer from '@/models/Emailer'
import FechasPago from '@/models/FechasPago'
export default {
  name: 'ModalSeleccionarFechaPago',
  props: {
    etapa: {
      type: Object,
      default: function() {
        return {}
      }
    },
    facturasingasto: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      cargando: false,
      yo: Api.obtenerLocal('elite.yo') || {},
      facturasingasto2: {},
      fechasPago: [],
      fechaPago: null,
      esMovil: window.innerWidth < window.innerHeight,
      modoProduccion: Api.modoProduccion
    }
  },
  async mounted() {
    this.facturasingasto2 = this.facturasingasto
    console.log("ModalSeleccionarFechaPago", this.fechasPago, this.fechaPago)
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    seleccionarFecha(f) {
      this.fechaPago = f
      this.$forceUpdate()
    },
    async terminar() {
      this.facturasingasto2.fechaJustificacionInterna = new Date().getTime()
      this.facturasingasto2.idUsuarioJustifica = this.yo._id
      await Api.save('facturasingasto', this.facturasingasto2)
      Api.$emit('cerrarJustificacion', this.fechaPago)
    },
    cerrar() {
      Api.$emit('cerrarJustificacion')
    }
  }
}
</script>
