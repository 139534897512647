<script>
import Api from '../components/Api'
export default {
  lista: () => {
    return new Promise((resolve, reject) => {
      let prodsCache = Api.obtenerLocal('pt.productos')
      Api.get('producto').then(p => {
        Api.guardarLocal('pt.productos', p)
        resolve(p)
      }, e => {
        console.error("ELISTA", e)
        if (prodsCache && prodsCache.length > 0)
          resolve(prodsCache)
        else resolve([])
      })
    })
  },
  ver: (id) => {
    return new Promise((resolve, reject) => {
      let prom = null
      if (id && id.length == 24)
        prom = Api.get('producto', id)
      else
        prom = Api.find('producto', ['clave,cs,' + id])
      prom.then(s => {
        let producto = null
        if (id && id.length == 24)
          producto = s
        else
          producto = s[0]
        if (producto && producto._id)
          resolve(producto)
        else reject({
          error: 'No se pudo encontrar el producto'
        })
      })
    })
  },
  obtenerPrecio: (producto, set, variante) => {
    set = set || ''
    variante = variante || ''
    if (variante && variante.length > 0)
      variante = variante.split('_')[1]
    switch (set) {
      case 1:
      case '1':
      case 2:
      case '2':
      case '3':
      case 4:
      case '4':
      case 5:
      case '5':
        set = 'precio' + set
        break;
      case 'costo':
        break;
      default:
        set = 'precio1'
        break;
    }
    if (typeof producto != 'object') {
      return 0
    } else {
      let pr = producto[set]
      if (
        set != '' &&
        variante != '' &&
        producto &&
        producto.setCostos &&
        producto.setCostos[set] &&
        producto.setCostos[set][variante]
      ) {
        pr = producto.setCostos[set][variante]
      }
      /*/ Iva
      if (producto.iva && producto.iva != 0) {
        let factor = 1 + (producto.iva / 100)
        pr *= factor
      }
      //*/
      console.log("Obtenido precio", set, variante, producto.nombre, producto.iva, pr)
      return pr
    }
  },
  obtenerIva: (precioUnitario, producto) => {
    precioUnitario = precioUnitario || 0
    producto = producto || {}
    let iva = producto.iva || 0
    let pr = precioUnitario * (iva / 100)
    console.log("Obtenido IVA", producto.nombre, precioUnitario, iva, pr)
    return pr
  }
}
</script>
