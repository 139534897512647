<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('secondary')">
      <ion-title>Proporciónanos tus datos</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="cerrar">
          <i class="icon ion-ios-close-circle icon-lg"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-list class="ion-padding">
      <ion-item v-for="f in formUsuario" :key="f.label" v-if="f.name!='rol'||!modoProduccion">
        <ion-label text-wrap position="floating">{{f.label}}</ion-label>
        <ion-input v-if="!f.type || f.type!='select'" debounce="200" :value="usuario[f.name]" :color="colors('primary')" v-on:ionChange="setU(f.name,$event.detail.value)" :type="f.type||'text'"></ion-input>
        <ion-select v-if="f.type && f.type=='select'" :color="colors('primary')" v-on:ionChange="setU(f.name,$event.detail.value)" :value="usuario[f.name]" class="ion-text-right">
          <ion-select-option v-for="o in f.options" :key="o" text-capitalize :value="o">{{o | uppercase}}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item lines="none">&nbsp;</ion-item>
    </ion-list>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('primary')" @click="guardar">
        <i class="icon ion-ios-save icon-lg"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
import Proveedor from '@/models/Proveedor'
export default {
  name: 'ModalRegistrarProveedor',
  filters: {
    uppercase: function(u) {
      return (u || '').toUpperCase()
    }
  },
  data() {
    return {
      usuario: {
        rol: 'proveedor',
        nombre: '',
        rfc: '',
        password: null,
        permisos: this.permisos(),
        desactivado: false,
        fechaAlta: new Date().getTime()
      },
      opinionDeCumplimientoVigente: false,
      modoProduccion: Api.modoProduccion,
      socios: [],
      formUsuario: [{
        label: 'Nombre',
        name: 'nombre',
        required: true
      }, {
        label: 'RFC',
        name: 'rfc',
        required: true,
        minLength: 12
      }, {
        label: 'Email',
        name: 'email',
        required: true
      }, {
        label: 'Contraseña',
        name: 'password',
        type: 'password',
        required: true,
        minLength: 6
      }]
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    setU(campo, valor) {
      this.usuario[campo] = valor
      if (campo == 'rfc')
        this.usuario[campo] = valor.toUpperCase()
      console.log("Configurado", campo, valor)
    },
    async guardar() {
      let guardable = true
      for (let c of this.formUsuario) {
        if (c.required && !this.usuario[c.name]) {
          alert('Faltan datos: ' + c.name)
          guardable = false
        }
        let minLength = c.minLength || 5
        if (this.usuario[c.name].length < minLength) {
          alert("El campo " + c.label + " debe tener al menos " + minLength + " caracteres", 3000)
          guardable = false
        }
      }
      // Sesion temporal
      let loginTemporal = (await Api.login(Api.defaultUser, Api.defaultPw))[0]
      console.log("loginTemporal", loginTemporal)
      Api.guardarLocal('elite.yo', loginTemporal)
      Api.guardarLocal('wst.yo', loginTemporal)
      // Duplicados
      let duplicado = ''
      if (!this.usuario._id) {
        let usuarios = await Proveedor.lista()
        for (let u of usuarios) {
          for (let prop of ['nombre', 'email', 'rfc'])
            if (u[prop] == this.usuario[prop]) {
              console.error("Usuario duplicado en " + prop, this.usuario, u)
              duplicado = prop
            }
        }
      }
      //
      let usuario
      if (duplicado != '') {
        alert("Usuario duplicado (" + duplicado + ")")
      } else
      if (guardable) {
        usuario = await Api.save('usuario', this.usuario)
        this.usuario._id = usuario._id
        usuario.jwt = loginTemporal.jwt
        Api.guardarLocal('elite.yo', usuario)
        alert("¡Gracias! A continuación capture sus datos bancarios")
        let vm = this
        setTimeout(_ => {
          vm.$router.push('/proveedor/misdatos')
          window.location.reload()
        }, 2000)
      } else {
        Api.$emit('modalProveedorDismiss', this.usuario._id)
        console.log("No guardable", this.usuario)
      }
      if (!(usuario && usuario._id)) {
        Api.borrarLocal('wst.yo')
        Api.borrarLocal('elite.yo')
      }
    },
    cerrar() {
      Api.$emit('cerrarModalRegistrarProveedor')
    }
  },
  async mounted() {
    console.log('ModalRegistrarProveedor mounted')
  }
}
</script>
