<template>
<div class="ion-page" main>
  <router-view></router-view>
</div>
</template>

<script>
import Api from '@/components/Api'
export default {
  name: 'Catalogos',
  data() {
    return {
      yo: {}
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    pos() {
      this.$router.push('pos')
    }
  },
  mounted() {
    this.yo = Api.obtenerLocal('wst.yo')
    if (!(this.yo && this.yo._id))
      console.warn("No has iniciado sesión")
  }
}
</script>

<style>

</style>
