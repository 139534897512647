<script>
import Vue from "vue";
import Api from "@/components/Api";
import Empresa from "@/models/Empresa";
let Permisos = Vue.mixin({
  name: "PermisosModel",
  methods: {
    permiso: function (val) {
      let yo = Api.obtenerLocal("elite.yo") || {};
      if (yo.rol == "administrador") return true;
      return (yo.permisos || {})[val] == true;
    },
    permisosDefault(rol) {
      let permisosDefault = JSON.parse(
        JSON.stringify(Empresa.datosEmpresa.permisosDefault)
      );
      return permisosDefault[rol];
    },
    permisos: function () {
      let p = {};
      let yo = Api.obtenerLocal("elite.yo") || {};
      let permisosDefault = JSON.parse(
        JSON.stringify(Empresa.datosEmpresa.permisosDefault)
      );
      if (!yo.permisos) {
        console.log("Fallback permisos de usuario");
        p = permisosDefault.Usuario;
      }
      if (yo.tipo && permisosDefault[yo.tipo]) p = permisosDefault[yo.tipo];
      return p;
    },
  },
});
export default Permisos;
</script>
