<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('danger')">
      <ion-title>Catalogos - Proveedores ({{usuariosTodos.length}})</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <i class="icon ion-ios-menu"></i>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button @click="csv" v-if="yo.rol=='administrador'">
          <i class="icon ion-ios-download"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar v-if="cargando" type="indeterminate" :color="colors('danger')"></ion-progress-bar>
  </ion-header>
  <ion-content class="content">
    <ion-searchbar placeholder="Filtrar por nombre o correo" debounce="500" :value="filtro" v-on:ionChange="filtrar($event.detail.value)"></ion-searchbar>
    <ion-list id="lista">
      <ion-item-divider>Proveedores
        <ion-icon slot="end" name="refresh" v-on:click="obtener()"></ion-icon>
      </ion-item-divider>
      <ion-item-sliding v-for="u in usuarios" :key="u._id">
        <ion-item v-on:click="ver(u._id)">
          <ion-label text-wrap :color="u.desactivado?'warning':''">
            <h2>{{u.nombre}}</h2>
            <p text-capitalize>
              {{u.tipo}} {{u.rol}} {{u.areaDefault}}
            </p>
            <p v-if="!opinionDeCumplimientoVigente(u)">Opinión de cumplimiento caduca</p>
            <p v-if="faltaComplementoDePago(u)">Faltan complementos de pago</p>
          </ion-label>
          <ion-note slot="end">{{u.email}}</ion-note>
          <ion-note slot="end" v-if="!opinionDeCumplimientoVigente(u) || faltaComplementoDePago(u)"><i class="icon icon-lg ion-ios-information-circle-outline"></i></ion-note>
        </ion-item>
        <ion-item-options slot="end">
          <ion-item-option :color="colors('light')" v-on:click="entrarComo(u)" v-if="yo && yo.rol && yo.rol=='administrador'">
            <ion-icon name="log-in"></ion-icon> Entrar como
          </ion-item-option>
          <ion-item-option :color="colors('danger')" v-on:click="eliminar(u._id)" v-if="permiso('BorrarProveedores')">
            <ion-icon name="trash"></ion-icon> Eliminar
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed" v-if="permiso('CrearProveedores')">
      <ion-fab-button :color="colors('danger')" v-on:click="ver(0)">
        <i class="icon icon-lg ion-ios-add"></i>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
import Proveedor from '@/models/Proveedor'
export default {
  name: 'CatalogoProveedores',
  data() {
    return {
      yo: Api.obtenerLocal('elite.yo') || {},
      cargando: false,
      usuarios: [],
      usuariosTodos: [],
      filtro: ''
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    pos() {
      this.$router.push('pos')
    },
    async obtener() {
      this.usuariosTodos = await Proveedor.lista(null, true, true)
      console.log("Objetos", this.usuariosTodos.length)
      this.filtrar(this.filtro)
    },
    opinionDeCumplimientoVigente(usuario) {
      let vigente
      let fechaMasAntiguaPermitida = new Date().getTime() - (1000 * 60 * 60 * 24 * 30)
      if (!usuario.opinionDeCumplimiento ||
        !usuario.opinionDeCumplimientoFecha ||
        usuario.opinionDeCumplimientoFecha < fechaMasAntiguaPermitida)
        vigente = false
      else vigente = true
      return vigente
    },
    async faltaComplementoDePago(usuario) {
      let faltaComplemento = false
      for (let etapa of usuario.etapas)
        if (etapa.archivoAdjuntoXml &&
          etapa.fechaTerminada &&
          etapa.fechaTerminada > 0 &&
          !etapa.complemento)
          faltaComplemento = true
      return faltaComplemento
    },
    filtrar(val) {
      console.log("Filtrando", this.usuariosTodos.length, val, typeof val)
      val = (val || '').toLowerCase()
      let vm = this
      this.filtro = val
      if (val == '') {
        this.usuarios = JSON.parse(JSON.stringify(this.usuariosTodos))
        console.log("Mostrando todos los usuarios", this.usuarios.length, this.usuariosTodos.length)
      } else {
        this.usuarios = this.usuariosTodos.filter(cl => {
          return (cl.nombre || '').toLowerCase().indexOf(val) >= 0 ||
            (cl.email || '').toLowerCase().indexOf(val) >= 0 ||
            vm.tienePermiso(cl, val)
        })
      }
      this.obtenerEtapasDePagos()
      console.log("Filtrados", val, this.usuarios.length)
    },
    async obtenerEtapasDePagos() {
      this.cargando = true
      let vm = this
      for (let i in this.usuarios) {
        Api.find('gastoetapa', ['idProveedor,eq,' + this.usuarios[i].idProveedor])
          .then(etapas => {
            vm.usuarios[i].etapas = etapas
            vm.$forceUpdate()
          })

      }
      this.cargando = false
      return Promise.resolve(true)
    },
    tienePermiso(cliente, permiso) {
      if (cliente.rol == 'administrador') {
        console.log("Es administrador", cliente.nonbre)
        return true
      } else {
        let clienteTienePermiso = false
        let permisosPosibles = []
        for (let prop in (cliente.permisos || {}))
          if (typeof cliente.permisos[prop] == 'boolean')
            permisosPosibles.push(prop)
        console.log("Permisos posibles?", cliente.nombre, permisosPosibles)
        for (let permisoPosible of permisosPosibles)
          if (permisoPosible.toLowerCase().indexOf(permiso.toLowerCase()) >= 0 && (cliente.permisos || {})[permisoPosible] == true) {
            console.log("Permiso encontrado", cliente.nombre, permisoPosible)
            clienteTienePermiso = true
          }
        return clienteTienePermiso
      }
    },
    ver(id) {
      this.$router.push('/catalogos/proveedor/' + id)
    },
    async eliminar(id) {
      if (true == confirm('Desea eliminar este elemento? Esto no se puede deshacer')) {
        let u = await Api.delete('usuario', id)
        Api.borrarLocal('elite.usuario.' + id)
        this.obtener()
        let lista = document.querySelector('#lista')
        if (lista)
          await lista.closeSlidingItems()
      }
    },
    entrarComo(u) {
      if (true == confirm('¿Desea entrar como ' + u.nombre + '?')) {
        Api.guardarLocal('elite.yo', u)
        this.$router.push('/')
        window.location.reload()
      }
    },
    csv() {
      let txt = ''
      for (let u of this.usuarios) {
        let datos = [u.nombre, u.email, u.password, u.fechaAlta ? this.$options.filters.moment(new Date(u.fechaAlta), 'YYYY-MM-DD') : '', u.fechaBaja ? this.$options.filters.moment(new Date(u.fechaBaja), 'YYYY-MM-DD') : '']
        //console.log("D?", datos)
        let renglon = datos.join(',') + '\n\r'
        txt += renglon
      }
      console.log("Txt?", txt.length)
      Api.downloadForce(txt, 'elite.proveedores.csv')
    }
  },
  mounted() {
    this.obtener()
    let vm = this
    setTimeout(_ => {
      vm.obtener()
    }, 1000)
  }
}
</script>

<style>

</style>
