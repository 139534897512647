<template>
<div class="ion-page" main>
  <ion-header>
    <ion-toolbar :color="colors('danger')">
      <ion-title>Catalogos - Producto</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="openEnd">
          <ion-icon name="menu" :color="colors('light')"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content">
    <ion-grid v-if="producto">
      <ion-row>
        <ion-col></ion-col>
        <ion-col size="12" size-md="10" size-lg="8">
          <form v-on:submit="guardar()">
            <ion-row>
              <ion-col size="12" size-md="8">
                <ion-card>
                  <ion-item :color="colors('light')">Producto {{producto._id || 'Nuevo'}}
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Nombre</ion-label>
                    <ion-input required v-on:ionChange="producto.nombre=$event.detail.value" :value="producto.nombre"></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Código (interno)</ion-label>
                    <ion-input :value="producto.clave" v-on:ionChange="producto.clave=$event.detail.value"></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Código de barras o proveedor</ion-label>
                    <ion-input v-on:ionChange="producto.codigo=$event.detail.value" :value="producto.codigo"></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Detalles</ion-label>
                    <ion-textarea v-on:ionChange="producto.detalles=$event.detail.value" :value="producto.detalles"></ion-textarea>
                  </ion-item>
                  <ion-item>
                    <ion-label>Aplica IVA</ion-label>
                    <ion-toggle :checked="producto.aplicaIva" v-on:ionChange="configurarIva($event.detail.checked)"></ion-toggle>
                  </ion-item>
                  <ion-item>
                    <ion-label>Familias</ion-label>
                    <ion-select multiple v-on:ionChange="configurarFamilias($event)" :value="producto.familias">
                      <ion-select-option v-for="f in familias" :key="f._id" :value="f._id">{{f.nombre}}</ion-select-option>
                    </ion-select>
                  </ion-item>
                  <ion-item>
                    <ion-label>Proveedor default</ion-label>
                    <ion-select v-on:ionChange="producto.idProveedorDefault=$event.detail.value" :value="producto.idProveedorDefault || ''">
                      <ion-select-option v-for="proveedor in proveedores" :key="proveedor._id" :value="proveedor._id">{{proveedor.nombre}} {{proveedor.apellidoPaterno}}</ion-select-option>
                    </ion-select>
                  </ion-item>
                </ion-card>
                <ion-card>
                  <ion-item>
                    <ion-label position="stacked">Fecha Alta</ion-label>
                    <ion-input type="text" :value="producto.fechaAlta | moment('MMM DD YYYY, HH:mm')" readonly></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="stacked">Fecha Modificacion</ion-label>
                    <ion-input type="text" :value="producto.fechaModificacion | moment('MMM DD YYYY, HH:mm')" readonly></ion-input>
                  </ion-item>
                  <ion-item v-if="ultimoModificador && ultimoModificador._id">
                    <ion-label position="stacked">Ultima persona que modificó</ion-label>
                    <ion-input type="text" :value="ultimoModificador.nombre" disabled></ion-input>
                  </ion-item>
                </ion-card>
              </ion-col>
              <ion-col size="12" size-md="4">
                <ion-card>
                  <ion-item :color="colors('light')">Monetización</ion-item>
                  <ion-item>
                    <ion-label position="floating">Costo (general)</ion-label>
                    <ion-input type="number" step="any" v-on:ionChange="producto.costo=$event.detail.value" :value="producto.costo"></ion-input>
                  </ion-item>
                  <ion-item v-for="i in [1,2,3,4,5]" :key="i">
                    <ion-label position="floating">Precio {{i}}</ion-label>
                    <ion-input type="number" step="any" v-on:ionChange="producto['precio'+i]=$event.detail.value" :value="producto['precio'+i]"></ion-input>
                  </ion-item>
                </ion-card>
              </ion-col>
            </ion-row>
            <button class="hidden"></button>
            <ion-row>
              <ion-col>
                <ion-card>
                  <ion-item :color="colors('light')">Unidad SAT
                    <ion-note side="end">&nbsp;Los resultados están hasta arriba de la búsqueda</ion-note>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Buscar Clave Unidad <span v-if="catalogosSAT.medidasFiltradas && catalogosSAT.medidasFiltradas.length">({{catalogosSAT.medidasFiltradas.length}} encontradas)</span></ion-label>
                    <ion-input v-on:ionChange="filtrarMedidasSat($event.detail.value)" :value="producto.queryMedidaSat" debounce="1000"></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="stacked">Clave unidad {{producto.unidadMedidaSat}}</ion-label>
                    <ion-select v-if="catalogosSAT.medidasFiltradas && (catalogosSAT.medidasFiltradas.length>0 || catalogosSAT.medidasFiltradas.length<20)" interface="" v-on:ionChange="producto.unidadMedidaSat=$event.detail.vaule">
                      <ion-select-option v-for="p in catalogosSAT.medidasFiltradas" :key="p.Value" :value="p.Value">{{p.Value}} {{p.Name}}</ion-select-option>
                    </ion-select>
                    <ion-input :value="producto.unidadMedidaSat" v-if="catalogosSAT.medidasFiltradas && catalogosSAT.medidasFiltradas.length>20"></ion-input>
                  </ion-item>
                </ion-card>
              </ion-col>
              <ion-col>
                <ion-card>
                  <ion-item :color="colors('light')">Producto SAT
                    <ion-note side="end">&nbsp;Los resultados están hasta arriba de la búsqueda</ion-note>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Buscar Prod/Serv <span v-if="catalogosSAT.productosFiltrados && catalogosSAT.productosFiltrados.length">({{catalogosSAT.productosFiltrados.length}} encontrados)</span></ion-label>
                    <ion-input v-on:ionChange="filtrarProductoSat($event.detail.value)" :value="producto.queryProductoSat" debounce="1000"></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="stacked">Clave Prod/Serv {{producto.productoSat}}</ion-label>
                    <ion-select v-if="catalogosSAT.productosFiltrados && (catalogosSAT.productosFiltrados.length>0)" interface="" v-on:ionChange="producto.productoSat=$event.detail.value">
                      <ion-select-option v-for="p in catalogosSAT.productosFiltrados" :key="p.Value" :value="p.Value">{{p.Value}} {{p.Name}}</ion-select-option>
                    </ion-select>
                  </ion-item>
                  <ion-item>
                    <ion-input :value="producto.productoSat" v-if="!catalogosSAT.productosFiltrados || catalogosSAT.productosFiltrados.length==0"></ion-input>
                  </ion-item>
                </ion-card>
              </ion-col>
            </ion-row>
            <ion-row v-if="producto">
              <ion-col>
                <ion-card>
                  <ion-item :color="colors('light')">
                    <ion-label>Editar set de costos</ion-label>
                    <ion-select v-on:ionChange="verSet($event)">
                      <ion-select-option>Costo</ion-select-option>
                      <ion-select-option v-for="i in [1,2,3,4,5]" :key="i">Precio {{i}}</ion-select-option>
                    </ion-select>
                  </ion-item>
                  <div v-if="setCostos!=''">
                    <ion-row>
                      <!--ion-col></ion-col-->
                      <!--ion-col v-for="(w,j) in producto.variante2">
                        {{w}}
                      </ion-col-->
                    </ion-row>
                    <ion-row v-for="(v,i) in producto.variante1" :key="i">
                      <!--ion-col>{{v}}</ion-col-->
                      <ion-col v-for="(w,j) in producto.variante2" :key="j">
                        <!--
                        <input type="number" step="any" :placeholder="w+','+v" v-model="producto.setCostos[setCostos][w+','+v]" v-on:change="mostrarVarianteCosto(w,v)" v-on:focus="configurarVarianteCosto(v,w)" />
                      -->
                        <ion-item :color="colors('light')">
                          <ion-label position="floating">{{w}},{{v}}</ion-label>
                          <ion-input type="number" step="any" :pplaceholder="w+','+v" v-on:ionChange="producto.setCostos[setCostos][w+','+v]=$event.detail.value" :value="producto.setCostos[setCostos][w+','+v]"
                            v-on:ionFocus="configurarVarianteCosto(v,w)"></ion-input>
                        </ion-item>
                      </ion-col>
                    </ion-row>
                  </div>
                </ion-card>
              </ion-col>
            </ion-row>
          </form>
          <ion-row>
            <ion-col>
              <form v-on:submit.prevent="agregarVariante(1)">
                <ion-card>
                  <ion-item-divider :color="colors('light')">Tallas
                    <ion-badge :color="colors('light')" slot="end" v-on:click="defaultsVariante(1)">Defaults</ion-badge>
                    <ion-icon slot="end" name="add-circle" v-on:click="agregarVariante(1)"></ion-icon>
                  </ion-item-divider>
                  <ion-item v-for="(v,i) in producto.variante1" :key="i">
                    <input type="text" v-model="producto.variante1[i]" />
                  </ion-item>
                </ion-card>
              </form>
            </ion-col>
            <ion-col>
              <form v-on:submit.prevent="agregarVariante(2)">
                <ion-card>
                  <ion-item-divider :color="colors('light')">Colores
                    <ion-badge :color="colors('light')" slot="end" v-on:click="defaultsVariante(2)">Defaults</ion-badge>
                    <ion-icon slot="end" name="add-circle" v-on:click="agregarVariante(2)"></ion-icon>
                  </ion-item-divider>
                  <ion-item v-for="(v,i) in producto.variante2" :key="i">
                    <input type="text" v-model="producto.variante2[i]" />
                  </ion-item>
                </ion-card>
              </form>
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-col></ion-col>
      </ion-row>
    </ion-grid>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :color="colors('danger')" v-on:click="guardar()">
        <ion-icon name="save"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</div>
</template>

<script>
import Api from '@/components/Api'
import Facturama from '@/thirdparty/Facturama'
export default {
  name: 'CatalogoProducto',
  props: ['id'],
  data() {
    return {
      yo: {},
      producto: {
        nombre: '',
        detalles: '',
        costo: 0,
        precio1: 0,
        precio2: 0,
        precio3: 0,
        precio4: 0,
        precio5: 0,
        iva: 0,
        variante1: [],
        variante2: [],
        setCostos: {
          costo: {},
          precio1: {},
          precio2: {},
          precio3: {},
          precio4: {},
          precio5: {},
        },
        familias: [],
        aplicaIva: true
      },
      familias: [],
      proveedores: [],
      setCostos: '',
      defaultsVariante1: ['XS', 'S', 'M', 'L', 'XL'],
      defaultsVariante2: ['Rojo', 'Azul', 'Verde', 'Amarillo', 'Negro', 'Blanco'],
      ultimoModificador: {},
      catalogosSAT: {}
    }
  },
  methods: {
    openEnd() {
      Api.$emit('open-menu')
    },
    agregarVariante(i) {
      if (!this.producto['variante' + i])
        this.producto['variante' + i] = []
      let ultimoElem = this.producto['variante' + i].length - 1
      if (this.producto['variante' + i][ultimoElem] != '')
        this.producto['variante' + i].push('')
      this.$forceUpdate()
    },
    defaultsVariante(i) {
      this.producto['variante' + i] = (i == 1) ? this.defaultsVariante1 : this.defaultsVariante2
      this.$forceUpdate()
    },
    mostrarVarianteCosto(uno, dos) {
      console.log("Variante", this.producto.setCostos[this.setCostos])
    },
    configurarVarianteCosto(uno, dos) {
      this.mostrarVarianteCosto(uno, dos)
      console.log("?", this.producto.setCostos[this.setCostos][dos + "," + uno])
      if (!this.producto.setCostos[this.setCostos][dos + "," + uno] && this.producto[this.setCostos] && this.producto[this.setCostos] != 0) {
        this.producto.setCostos[this.setCostos][dos + "," + uno] = this.producto[this.setCostos]
        this.$forceUpdate()
      }
    },
    configurarIva(e) {
      console.log("Configurando iva", e)
      this.producto.iva = e ? 16 : 0
    },
    configurarFamilias(e) {
      console.log("Configurando Familias", e)
      this.producto.familias = e.detail.value
    },
    hayValor(campo) {
      return (this.producto[campo] && this.producto[campo] != '' && this.producto[campo] != 0)
    },
    verSet(e) {
      this.setCostos = e.target.value.toLowerCase().replace(' ', '')
      console.log("Viendo set", this.setCostos)
      this.$forceUpdate()
    },
    log(obj) {
      console.log("LOG", obj, this.producto)
    },
    guardar() {
      console.log("Guardando", this.producto)
      let guardable = true
      for (let i of ['nombre', 'costo', 'precio1', 'clave']) {
        if (!this.hayValor(i)) {
          guardable = false
          alert("Faltan datos: " + i)
        }
      }
      if (!this.producto.variante1 || this.producto.variante1.length == 0)
        this.producto.variante1 = ['Unisex']
      if (!this.producto.variante2 || this.producto.variante2.length == 0)
        this.producto.variante2 = ['Default']
      // Configurar las fechas
      if (!this.producto.fechaAlta)
        this.producto.fechaAlta = new Date().getTime()
      this.producto.fechaModificacion = new Date().getTime()
      this.producto.idUltimoModificador = this.yo._id
      // TODO Guardar el mensaje de bitácora
      if (guardable)
        Api.save('producto', this.producto).then(u => {
          this.producto = u
          alert("Guardado")
          setTimeout(_ => {
            this.$router.push('/catalogos/productos')
          }, 1000)
        })
    },
    // SAT
    filtrarMedidasSat(str) {
      str = str || ''
      this.producto.queryMedidaSat = str
      if (!this.catalogosSAT.medidas)
        alert("No se ha encontrado el catálogo de medidas. Vuelva a cargar esta página.")
      let medidasFiltradas = this.catalogosSAT.medidas.filter(m => {
        let nombre = m.Name.toLowerCase()
        let query = str.toLowerCase().trim()
        return nombre.indexOf(query) >= 0 || str == m.Value
      })
      let vm = this
      vm.catalogosSAT.medidasFiltradas = []
      setTimeout(function() {
        vm.catalogosSAT.medidasFiltradas = medidasFiltradas
        console.log("filtrados", str, vm.catalogosSAT.medidasFiltradas)
        if (vm.catalogosSAT.medidasFiltradas.length == 1) {
          vm.producto.unidadMedidaSat = vm.catalogosSAT.medidasFiltradas[0].Value
          console.log("Asignada coincidencia", vm.producto.unidadMedidaSat)
          alert("Asignada coincidencia única")
        }
        vm.$forceUpdate()
      }, 1000)
    },
    filtrarProductoSat(str) {
      str = str || ''
      let vm = this
      if (str.length <= 3)
        alert("La búsqueda debe ser de 3 caracteres o más")
      else {
        vm.producto.queryProductoSat = str
        Facturama.Catalogs.ProductsOrServices(str).then(s => {
          console.log("Productos SAT", s.data[0])
          vm.catalogosSAT.productosFiltrados = s.data
          console.log("filtrados", str, vm.catalogosSAT.productosFiltrados)
          if (vm.catalogosSAT.productosFiltrados.length == 1) {
            vm.producto.productoSat = vm.catalogosSAT.productosFiltrados[0].Value
            console.log("Asignada coincidencia", vm.producto.productoSat)
            alert("Asignada coincidencia única")
          }
          vm.$forceUpdate()
        }).catch(e => {
          alert("Ha ocurrido un error al buscar el producto. Intenta más adelante. ")
        })
      }
    }
  },
  async beforeMount() {
    this.yo = Api.obtenerLocal('wst.yo')
    console.log("Catalogo producto", this.id)
    if (this.id && this.id.length == 24) {
      this.producto = await Api.get('producto', this.id)
      if (this.producto.idUltimoModificador)
        this.ultimoModificador = await Api.get('usuario', this.producto.idUltimoModificador)
      if (!this.producto.familias)
        this.producto.familias = []
      if (!this.producto.setCostos) this.producto.setCostos = {
        costo: {},
        precio1: {},
        precio2: {},
        precio3: {},
        precio4: {},
        precio5: {}
      }
    }
    this.familias = await Api.get('familia')
    this.proveedores = await Api.get('proveedor')
    // SAT! pero estas están pesadas, que no interrumpan la carga
    let vm = this
    if (!(this.catalogosSAT.medidas && this.catalogosSAT.medidas.length > 0))
      Facturama.Catalogs.Units().then(s => {
        console.log("Unidades de medida SAT", s.data[0])
        vm.catalogosSAT.medidas = s.data
      })
  }
}
</script>
